import * as _ from "lodash"
import * as React from "react"
import CashierDialog from "./CashierDialog"
import CashierSelectionModeConfiguration from "./CashierSelectionModeConfiguration"
import { ascendingByCashierFullName } from "./helpers"
import {
    Button,
    Col,
    Grid,
    Image,
    Panel,
    Row
} from "react-bootstrap"
import { Cashier } from "../../../models/Cashier"
import {
    CashierSelection,
    CashierSelectionMode
} from "../../../models/CashierSelection"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router"
import { StripedTable } from "../../StripedTable"
import { ToggleButton } from "../../ToggleButton"
import { CashierRole } from "../Configuration/CashierRoles"
import { L10nString } from "../../../helpers/L10n"

interface CashierListProps extends RouteComponentProps<any> {
    role: Role
    shop: string
}

interface CashierListState {
    showCashier: boolean
    loaded: boolean
    cashiers: Cashier[]
    cashier: Cashier | null
    cashierSelection: CashierSelection
    cashierRoles: CashierRole[]
}

class CashierList extends React.Component<CashierListProps, CashierListState> {

    private get shopKey(): string {
        return this.props.shop
    }

    private get accountKey(): string {
        return this.props.role.account_id
    }

    private get cashiersPath(): string {
        return `v1/accounts/${this.accountKey}/shops/${this.shopKey}/cashiers`
    }

    private cashierPath(cashier: Cashier): string {
        return `${this.cashiersPath}/${cashier.id}`
    }

    private get cashierSelectionPath(): string {
        return `v1/accounts/${this.accountKey}/shops/${this.shopKey}/configuration/cashier_selection`
    }
    private get cashierRolesPath(): string {
        return `v1/accounts/${this.accountKey}/inventory/cashier_roles`
    }

    constructor(props: CashierListProps) {
        super(props)

        this.state = ({
            cashiers: [],
            cashier: null,
            showCashier: false,
            loaded: false,
            cashierSelection: CashierSelection.default,
            cashierRoles: []
        })
    }

    async componentDidMount() {
        this.setState({ cashiers: [], loaded: false })

        this.setupObservers()
    }

    componentWillUnmount() {
        this.teardownObservers()
    }

    setupObservers() {
        ref().child(this.cashierSelectionPath).on("value", snapshot => {
            if (!snapshot || !snapshot.exists()) {
                this.setState({ loaded: true })
                return
            }

            const cashierSelection = CashierSelection.fromJSON(snapshot.val())

            this.setState({ cashierSelection: cashierSelection, loaded: true })
        })

        ref().child(this.cashierRolesPath).on("value", snapshot => {
            if (!snapshot || !snapshot.exists()) {
                this.setState({ loaded: true })
                return
            }

            const roles: CashierRole[] = []
            if (snapshot && snapshot.exists()) {
                const rolesDict = snapshot.val()
                for (const key in rolesDict) {
                    const roleJson = rolesDict[key]
                    if (roleJson.id && roleJson.name) {
                        roles.push({
                            id: roleJson.id,
                            name: new L10nString(roleJson.name),
                            functionalityBlocklist: roleJson.functionality_blocklist ?? []
                        })
                    }
                }
            }
            this.setState({ cashierRoles: roles, loaded: true })
        })

        ref().child(this.cashiersPath).on("value", snapshot => {
            if (!snapshot || !snapshot.exists()) {
                return
            }

            const cashiersMap = snapshot.val()

            let cashiers: Cashier[] = []
            for (const key in cashiersMap) {
                const cashier = Cashier.fromJSON(cashiersMap[key])
                if (!_.isNull(cashier)) {
                    cashiers.push(cashier)
                }
            }

            cashiers = cashiers.sort(ascendingByCashierFullName)

            this.setState({ cashiers: cashiers })
        })
    }

    teardownObservers() {
        ref().child(this.cashierSelectionPath).off()
        ref().child(this.cashiersPath).off()
        ref().child(this.cashierRolesPath).off()
    }

    // Actions

    editCashier(cashier: Cashier | null) {
        this.setState({
            cashier: cashier,
            showCashier: true
        })
    }

    async deleteCashier(cashier: Cashier) {
        if (this.state.cashiers.length === 1) {
            alert("A shop must always have minimum 1 cashier")
            return
        }

        const reply = window.confirm(`Really delete "${cashier.fullName ? cashier.fullName : cashier.displayName}" ?`)
        if (reply === true) {
            const cashierRef = ref().child(this.cashierPath(cashier))

            await cashierRef.set(null)
        }
    }

    async resetPIN(cashier: Cashier) {
        const cashierPINCodeRef = ref().child(`${this.cashierPath(cashier)}/pin_code`)

        await cashierPINCodeRef.remove()
    }

    async toggleUsePINCode() {
        const cashierSelection = new CashierSelection(!this.state.cashierSelection.usePINCode, this.state.cashierSelection.selectionPoint)

        await this.persistCashierSelection(cashierSelection)

        this.setState({ cashierSelection })
    }

    async updateSelectionPoint(mode: CashierSelectionMode) {
        const cashierSelection = new CashierSelection(this.state.cashierSelection.usePINCode, mode)

        await this.persistCashierSelection(cashierSelection)

        this.setState({ cashierSelection })
    }

    async persistCashierSelection(cashierSelection: CashierSelection) {
        await ref().child(this.cashierSelectionPath).set(cashierSelection.toJSON())
    }

    // View

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="cashiers">

                <CashierSelectionModeConfiguration
                    key="cashier_configuration"
                    didSelectMode={async (mode) => { await this.updateSelectionPoint(mode) }}
                    cashierSelectionMode={this.state.cashierSelection.selectionPoint}
                />

                <Panel key="panel1">
                    <Panel.Heading>
                        <Panel.Title>PIN code</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <Grid>
                            <Row>
                                <Col>
                                    <ToggleButton isEnabled={this.state.cashierSelection.usePINCode} performToggle={async () => { await this.toggleUsePINCode() }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5>With this enabled the user will need to enter a personalized PIN code when switching cashiers. <br />If the PIN code is forgotten, it can be reset here and entered anew the next time the cashier is switched to.</h5>
                                </Col>
                            </Row>
                        </Grid>
                    </Panel.Body>
                </Panel>

                <Panel key="panel3">
                    <Panel.Heading>
                        <Panel.Title>Cashiers</Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <Grid>
                            <Row>
                                <Col xs={12} sm={2} md={2}>
                                    <Button bsStyle="success" onClick={() => { this.editCashier(null) }}>New cashier</Button>
                                </Col>
                            </Row>
                        </Grid>
                    </Panel.Body>

                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Full name</th>
                                <th>Initials</th>
                                {this.state.cashierSelection.usePINCode ? <th>PIN code</th> : null}
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.cashiers.map(cashier => {
                                    return (
                                        <tr
                                            key={cashier.id}
                                            onClick={(event) => { this.editCashier(cashier); event.stopPropagation() }}
                                        >
                                            <td className="narrow"><div style={{ height: "50px", width: "50px" }}><Image src={cashier.imageURL || undefined} style={{ maxWidth: "100%", maxHeight: "100%" }} /> </div></td>
                                            <td>{cashier.fullName}</td>
                                            <td>{cashier.displayName}</td>
                                            {this.state.cashierSelection.usePINCode ? <td className="narrow"><Button bsStyle="info" disabled={cashier.pinCode === null} onClick={async (event) => { event.stopPropagation(); await this.resetPIN(cashier) }}>Reset</Button></td> : null}
                                            <td className="narrow"><Button bsStyle="danger" onClick={async (event) => { event.stopPropagation(); await this.deleteCashier(cashier) }}>X</Button></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </StripedTable>

                </Panel>
                {
                    this.state.showCashier ? (
                        <CashierDialog
                            key="dialog"
                            account={this.props.role.account_id}
                            shop={this.props.shop}
                            cashier={this.state.cashier}
                            cashierRoles={this.state.cashierRoles}
                            onDone={() => { this.setState({ showCashier: false }) }}
                        />
                    ) : null
                }
            </PageState>
        )
    }
}

export default withRouter(CashierList)
