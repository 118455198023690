import * as React from "react"

import { Button, Panel } from "react-bootstrap"
import { ref } from "../../../config/constants"
import { PageState } from "../../PageState"
import { Role } from "../../../config/role"
import { DecimalValueFormControl } from "../../DecimalValueFormControl"
import { FormattedNumber } from "react-intl"

interface ExchangeRateMarginConfigurationProps {
    role: Role
}

interface ExchangeRateMarginConfigurationState {
    dirty: boolean
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
    exchange_rate_margin: number
}

export default class ExchangeRateMarginConfiguration extends React.Component<ExchangeRateMarginConfigurationProps, ExchangeRateMarginConfigurationState> {
    constructor(props: ExchangeRateMarginConfigurationProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            showSuccess: false,
            exchange_rate_margin: 1.0
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/exchange_rate_margin`)
        const configSnap = await configRef.once("value")
        let exchange_rate_margin = 1.0
        if (configSnap.exists()) {
            exchange_rate_margin = configSnap.val()
        }
        this.setState({ exchange_rate_margin: exchange_rate_margin, loaded: true })
    }

    displayMargin(): number {
        const margin = this.state.exchange_rate_margin ? (this.state.exchange_rate_margin - 1) * 100 : 0
        return margin
    }

    displayMarginInPct(): number {        
        return this.displayMargin() + 100
    }

    exampleResult(): number {        
        return (this.state.exchange_rate_margin || 0) * 13.41
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="exchange rate margin">
                <Panel key="upper_panel" bsStyle={this.state.showSuccess ? "success" : "default"}>
                    <Panel.Heading>
                        <Panel.Title>{this.state.showSuccess ? "Exchange Rate Margin published successsfully" : "Exchange Rate Margin"}</Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <p>The <i>Exchange Rate Margin</i> is a factor that you can apply to exchange rates when accepting cash in foreign currencies.</p>
                        <p>This fee is intended to cover the handling of the cash for your business.</p>
                        <br/>
                        <h4>Please enter the desired <i>Exchange Rate Margin</i> in percentages.</h4>
                        <DecimalValueFormControl
                            value={this.state.exchange_rate_margin ? Math.round((this.state.exchange_rate_margin - 1) * 100 * 100) / 100 : 0}
                            onValueChanged={this.handleChange}
                            decimals={1}
                            prefixSymbol="%"
                            placeholder="Enter percentage"
                        />
                        <br/>
                        <h5>Example</h5>
                        <p>Let us assume that your base currency is <b>DKK</b> and you create a sale totalling <b>DKK 100</b>, and wish to sell by cash in <b>EUR</b>.</p>
                        <p>Using the current exchange rate between <b>DKK</b> and <b>EUR</b>, the total will amount to <b>EUR 13.41</b>.</p>
                        <p>With the configured <i>Exchange Rate Margin</i> of&nbsp;<b>
                            <FormattedNumber
                                useGrouping={true}
                                minimumFractionDigits={0}
                                maximumFractionDigits={1}
                                value={this.displayMargin()}
                            />%</b>,
                            the customer will need to pay (<b>13.41 *&nbsp;
                            <FormattedNumber
                                useGrouping={true}
                                minimumFractionDigits={0}
                                maximumFractionDigits={1}
                                value={this.displayMarginInPct()}
                            />%</b>) = <b>EUR&nbsp;
                            <FormattedNumber
                                useGrouping={true}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                value={this.exampleResult()}
                            /></b>&nbsp;
                            for the purchase.</p>

                    </Panel.Body>

                    <Panel.Footer>
                        <Button onClick={this.publish} disabled={!this.state.dirty}>Publish</Button>
                    </Panel.Footer>
                </Panel >
            </PageState>
        )
    }

    handleChange = (value: number | null) => {
        const numberValue = Number(value)
        this.setState({ exchange_rate_margin: (numberValue / 100) + 1, dirty: true })
    }

    publish = async () => {
        const account = this.props.role.account_id
        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/exchange_rate_margin`)

        this.setState({ publishing: true })
        await configRef
            .set(this.state.exchange_rate_margin)
        this.setState({ showSuccess: true, publishing: false, dirty: false })
    }
}
