import * as _ from "lodash"
import firebase from "firebase/compat"
import { ref } from "../config/constants"
import { sortLikeFirebaseOrderByKey } from "../helpers/sorting"

export enum StockCountFilterType {
    ALL = "all",
    DIFF_ONLY = "diff_only",
    NOT_COUNTED = "not_counted"
}

export async function fetchPage(path: string, limit: number, type: StockCountFilterType, fromLineItemId?: string): Promise<any> {
    let query = ref().child(path).limitToFirst(limit + 1)
    switch (type) {
    case StockCountFilterType.ALL:
        query = query.orderByKey()
        if (fromLineItemId) {
            query = query.startAt(fromLineItemId)
        }
        return (await query.once("value")).val()
    case StockCountFilterType.DIFF_ONLY:
        const over = (await query.orderByChild("diff").startAt(-2147483648, fromLineItemId).endAt(-1).once("value")).val() || {}
        const under = (await query.orderByChild("diff").startAt(1, fromLineItemId).endAt(2147483648).once("value")).val() || {}
        const allKeys = Object.keys(over).concat(Object.keys(under))
        const sortedToLimit = _.take(sortLikeFirebaseOrderByKey(allKeys), limit)
        const result = {}
        for (const key of sortedToLimit) {
            const value = over[key] || under[key] 
            if (!_.isNil(value)) {
                result[key] = value
            }
        }
        return result
    case StockCountFilterType.NOT_COUNTED:
        return (await query.orderByChild("has_count").startAt(false, fromLineItemId).endAt(false).once("value")).val()
    }
}

class QueryAndValue {
    query: firebase.database.Query
    values: _.Dictionary<any> | undefined

    constructor(query: firebase.database.Query) {
        this.query = query
        this.values = undefined
    }
}

class QuerySet {
    private queries: QueryAndValue[] = []

    valuesChangedCallback: () => void = () => {}

    addQuery(query: firebase.database.Query) {
        this.queries.push(new QueryAndValue(query))
    }

    start() {
        this.queries.forEach(q => {
            q.query.on("value", (snapshot: firebase.database.DataSnapshot) => {
                q.values = snapshot.val() || {}
                this.valuesChangedCallback()
            })
        })
    }

    stop() {
        this.queries.forEach(q => {
            q.query.off()
        })
    }

    values(): _.Dictionary<any> | undefined {
        const empty = this.queries.filter(v => { return _.isNil(v.values) })
        if (empty.length > 0) {
            return undefined
        }
        let result: _.Dictionary<any> = {}
        this.queries.forEach(v => {
            result = {...result, ...v.values }
        })
        return result
    }
}

export class StockCountLinesQueryService {

    private pageLimit: number
    private filter: StockCountFilterType
    private accountId: string
    private shopId: string
    private stockCountId: string
    private queries = new QuerySet()

    linesChangedCallback: (values: _.Dictionary<any>) => void = () => {}

    constructor(pageLimit: number, filterType: StockCountFilterType, accountId: string, shopId: string, stockCountId: string) {
        this.pageLimit = pageLimit
        this.filter = filterType
        this.accountId = accountId
        this.shopId = shopId
        this.stockCountId = stockCountId
    }

    start(id: string | undefined) {
        // turn off current queries if present
        this.stop()

        // prepare new queries set
        this.queries = new QuerySet()

        // set callback - only return something if values from
        this.queries.valuesChangedCallback = () => {
            const values = this.queries.values()
            if (_.isNil(values)) {
                return
            }

            const sortedKeysAtLimit = _.take(sortLikeFirebaseOrderByKey(Object.keys(values)), this.pageLimit + 1)
            const result: _.Dictionary<any> = {}
            for (const key of sortedKeysAtLimit) {
                result[key] = values[key]
            }

            this.linesChangedCallback(result)
        }

        // configure query set
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock_counts/counts/${this.stockCountId}/lines`
        switch (this.filter) {
            case StockCountFilterType.ALL:
                let allQuery = ref().child(path).limitToFirst(this.pageLimit + 1)
                allQuery = allQuery.orderByKey()
                if (id) {
                    allQuery = allQuery.startAt(id)
                }
                this.queries.addQuery(allQuery)
                break

            case StockCountFilterType.DIFF_ONLY:
                const over = ref().child(path).limitToFirst(this.pageLimit + 1).orderByChild("diff").startAt(-2147483648, id).endAt(-1)
                const under = ref().child(path).limitToFirst(this.pageLimit + 1).orderByChild("diff").startAt(1, id).endAt(2147483648)
                this.queries.addQuery(over)
                this.queries.addQuery(under)
                break

            case StockCountFilterType.NOT_COUNTED:
                const ncQuery = ref().child(path).limitToFirst(this.pageLimit + 1).orderByChild("has_count").startAt(false, id).endAt(false)
                this.queries.addQuery(ncQuery)
                break
        }

        // observe queries
        this.queries.start()
    }

    stop() {
        this.queries.stop()
        this.queries = new QuerySet()
    }
}