import * as React from "react"
import { Button } from "react-bootstrap"

interface DeleteButtonProps {
    onDelete(): void
    disabled?: boolean
}

export class DeleteButton extends React.Component<DeleteButtonProps, {}> {
    render() {
        return <Button disabled={this.props.disabled ?? false} bsStyle="danger" onClick={(event) => { this.props.onDelete(); event.stopPropagation() }}>X</Button>
    }
}