import _ from "lodash"
import React, { useState, useEffect } from "react"
import { Button, FormGroup, HelpBlock, Panel } from "react-bootstrap"
import { AttributeObserver } from "../../../helpers/attributeObserver"
import { ProductObserver } from "../../../helpers/productObserver"
import { TagObserver } from "../../../helpers/tagObserver"
import { Market } from "../../../models/MarketModels"
import { StripedTable } from "../../StripedTable"
// import { DiscountType } from "./DiscountSelector"
// import { RequirementsType } from "./MinimumRequirementsSelector"
import { BundleRowData, BundleStairStep } from "./BundleStairStep"

interface BundleStairProps {
    // discountType: DiscountType
    // requirementsType: RequirementsType
    market: Market | null
    validation: boolean
    rows: BundleRowData[]
    rowsChanged: (rows: BundleRowData[]) => void
    productObserver: ProductObserver
    tagsObserver: TagObserver
    attributesObserver: AttributeObserver
}

export function BundleStair(props: BundleStairProps) {
    const [rows, setRows] = useState<BundleRowData[]>(props.rows);
    const [rowId, setRowId] = useState<number>(1);
    useEffect(() => {
        props.rowsChanged(rows)
    }, [rows, props])
    const getRowId = () => {
        const id = rowId
        setRowId(id + 1)
        return id
    }

    return <Panel bsStyle="primary">
        <Panel.Heading>
            <Panel.Title>Bundle</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
            <HelpBlock>Please select groups of items that are not overlapping</HelpBlock>
            <FormGroup
                style={{ marginLeft: "0px", marginRight: "0px" }}
                validationState={props.validation ? null : "error"}
            >
                <StripedTable>
                    <tbody>
                        {
                            rows.map((row, index) => {
                                return <BundleStairStep productObserver={props.productObserver} attributesObserver={props.attributesObserver} tagsObserver={props.tagsObserver} key={row.id} market={props.market} row={row} onRowDeleted={(row) => {
                                    const clone = [...rows]
                                    clone.splice(index, 1)
                                    setRows(clone)
                                }} rowChanged={(row) => {
                                    const clone = [...rows]
                                    const existing = clone[index]
                                    if (!_.isEqual(row, existing)) {
                                        clone[index] = row
                                        setRows(clone)
                                    }
                                }} />
                            })
                        }
                    </tbody>
                </StripedTable>
                <Button onClick={() => { setRows(rows.concat([{ id: getRowId(), appliesTo: { type: "products", products: [], tags: [], attributes: [] } }])) }}>Add bundle condition</Button>
            </FormGroup>
        </Panel.Body>
    </Panel>
}

