//
//  ModalPicker.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 28/05/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { Button, Modal, Table } from "react-bootstrap"

export interface ModalPickerElement {
    id: string
    name: string
}

export interface ModalPickerProps<T> {
    elements: T[]
    onCancel(): void
    onSelect(element: T): void
    title: string
    alternativeButtonTitle?: string
    alternativeButtonAction?: () => void
}

export class ModalPicker<T extends ModalPickerElement> extends React.Component<ModalPickerProps<T>, {}> {
    render() {
        return (
            <Modal
                show={true}
                onHide={this.props.onCancel}
            >

                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Table bordered={true} condensed={true} hover={true} style={{ cursor: "pointer" }} fill={("true" as any) as boolean} >
                        <tbody>
                            {this.props.elements.map((element) => {
                                return (
                                    <tr key={element.id} onClick={() => { this.props.onSelect(element) }}>
                                        <td>{element.name}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>

                <Modal.Footer>
                    {this.props.alternativeButtonTitle && <Button onClick={() => { this.props.alternativeButtonAction?.() }}>{this.props.alternativeButtonTitle}</Button>}
                    <Button onClick={this.props.onCancel}>Cancel</Button>
                </Modal.Footer>

            </Modal>
        )
    }
}
