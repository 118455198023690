import * as React from "react"
import { Button, ButtonGroup } from "react-bootstrap"

interface ToggleButtonProps {
    performToggle: () => void
    isEnabled: boolean
    enabledTitle?: string
    disabledTitle?: string
}

export class ToggleButton extends React.Component<ToggleButtonProps, any> {
    render() {
        return (
            <ButtonGroup>
                <Button
                    bsStyle={this.props.isEnabled ? "success" : "default"}
                    active={this.props.isEnabled}
                    onClick={() => { if (!this.props.isEnabled) { this.props.performToggle() } }}
                >
                    { this.props.enabledTitle ?? "Enabled" }
                </Button>
                <Button
                    bsStyle={!this.props.isEnabled ? "success" : "default"}
                    active={!this.props.isEnabled}
                    onClick={() => { if (this.props.isEnabled) { this.props.performToggle() } }}
                >
                    { this.props.disabledTitle ?? "Disabled" }
                </Button>
            </ButtonGroup>
        )
    }
}
