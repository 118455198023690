import firebase from "firebase/compat"
import { Panel, Pager } from "react-bootstrap"
import { StripedTable } from "./StripedTable"
import * as React from "react"
import * as _ from "lodash"

export interface PagerProps {
    queueFetchLimit?: number
    queueRef: firebase.database.Reference
    renderTitle: () => JSX.Element
    renderHeader: () => JSX.Element
    renderElement: (key: string, element: any) => JSX.Element[]
    didUpdateElements: (elements: any, firstPage: boolean) => void
    setRefreshFunction: (refresh: () => Promise<void>) => void
    defaultExpanded: boolean
}

export interface PagerState {
    loading: boolean
    lastPage: boolean
    lastKeyStack: string[]
    queueFetchLimit: number
    firstPage: boolean
    elements?: any
}

export class LivePager extends React.Component<PagerProps, PagerState> {

    refresh = async () => {
        const keyStack = this.state.lastKeyStack
        keyStack.pop()
        this.setState({ lastKeyStack: keyStack })
        let lastKey = null
        if (keyStack.length > 0) {
            lastKey = keyStack[keyStack.length - 1]
        }
        await this.loadElements(lastKey)
    }

    constructor(props: PagerProps) {
        super(props)
        props.setRefreshFunction(async () => { await this.refresh() })

        this.state = {
            loading: false,
            lastPage: false,
            lastKeyStack: [],
            queueFetchLimit: props.queueFetchLimit || 50,
            firstPage: true
        }
    }

    async componentDidMount() {
        await this.loadElements(null)
    }

    elementsFinishedLoading = (snapshot: firebase.database.DataSnapshot, firstPage: boolean) => {
        this.setState({ loading: false })
        const elementsDict = snapshot.val()

        if (!elementsDict) {
            this.props.didUpdateElements({}, this.state.firstPage)
            this.setState({ lastPage: true, elements: {} })
            return
        }

        // Don't you just love that key value pairs in objects actually retain their order?
        const ordered = {}
        let lastKey: string | undefined
        Object.keys(elementsDict).sort().reverse().forEach((key) => {
            if (Object.keys(ordered).length < this.state.queueFetchLimit) {
                ordered[key] = elementsDict[key]
            }
            lastKey = key
        })

        let keyStack = this.state.lastKeyStack

        if (firstPage && !_.isNil(lastKey)) {
            keyStack = [lastKey]
        } else {
            if (Object.keys(ordered).length > 0) {
                let lastStackKey: string | undefined
                if (keyStack.length > 0) {
                    lastStackKey = keyStack[keyStack.length - 1]
                }
                if (lastKey !== lastStackKey) {
                    keyStack.push(lastKey as string)
                }
            }
        }
        this.props.didUpdateElements(ordered, this.state.firstPage)
        this.setState({ elements: ordered, lastKeyStack: keyStack, lastPage: Object.keys(ordered).length < this.state.queueFetchLimit })
    }

    loadElements = async (fromKey: string | null) => {
        this.props.queueRef.off()

        const firstPage = fromKey === null
        this.setState({ loading: true, firstPage: firstPage })
        let queryRef = this.props.queueRef.orderByKey().limitToLast(this.state.queueFetchLimit + 1)
        if (fromKey) {
            queryRef = queryRef.endAt(fromKey)
        }

        if (firstPage) {
            queryRef.on("value", snapshot => {
                this.elementsFinishedLoading(snapshot, firstPage)
            })
        } else {
            const snapshot = await queryRef.once("value")
            this.elementsFinishedLoading(snapshot, firstPage)
        }
    }

    loadNext = async () => {
        const keyStack = this.state.lastKeyStack
        let lastKey = null
        if (keyStack.length > 0) {
            lastKey = keyStack[keyStack.length - 1]
        }
        await this.loadElements(lastKey)
    }

    loadPrevious = async () => {
        const keyStack = this.state.lastKeyStack
        keyStack.pop()
        keyStack.pop()
        this.setState({ lastKeyStack: keyStack })
        let lastKey = null
        if (keyStack.length > 0) {
            lastKey = keyStack[keyStack.length - 1]
        }
        await this.loadElements(lastKey)
    }

    render() {
        return (
            <Panel defaultExpanded={this.props.defaultExpanded}>
                <Panel.Heading>
                    <Panel.Title toggle={true}>{this.props.renderTitle()}</Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <StripedTable>
                            <thead>
                                {this.props.renderHeader()}
                            </thead>
                            <tbody>
                                {
                                    Object.keys(this.state.elements || {}).map((key: string) => {
                                        return this.props.renderElement(key, this.state.elements[key])
                                    })
                                }
                            </tbody>
                        </StripedTable>
                        <Pager>
                            <Pager.Item previous={true} disabled={this.state.firstPage || this.state.loading} onClick={this.loadPrevious}>&larr; Previous Page</Pager.Item>
                            <Pager.Item next={true} disabled={this.state.lastPage || this.state.loading} onClick={this.loadNext}>Next Page &rarr;</Pager.Item>
                        </Pager>
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        )
    }
}
