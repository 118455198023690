import * as React from "react"

import { Button, Col, ControlLabel, Panel, Form, FormControl, FormGroup, Modal, Checkbox, ButtonGroup, ButtonToolbar } from "react-bootstrap"
import { EmailValidation } from "../../../helpers/validation"
import { ref } from "../../../config/constants"
import { PageState } from "../../PageState"
import { Role } from "../../../config/role"
import * as _ from "lodash"

interface SMTPConfigurationProps {
    role: Role
}

interface SMTPConfigurationState {
    dirty: boolean
    loaded: boolean
    publishing: boolean

    sendEmailButtonEnabled: boolean
    sendTestEmailButtonEnabled: boolean
    showModal: boolean
    showSuccess: boolean

    fromTestEmailAddress?: string
    toTestEmailAddress?: string

    host?: string
    port?: number
    user?: string
    password?: string
    secure?: boolean
    useProxy?: boolean
}

export default class SMTPConfigurationComponent extends React.Component<SMTPConfigurationProps, SMTPConfigurationState> {
    constructor(props: SMTPConfigurationProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            sendEmailButtonEnabled: false,
            sendTestEmailButtonEnabled: false,
            showModal: false,
            showSuccess: false
        }
    }

    // Component

    async componentDidMount() {
        const account = this.props.role.account_id
        const smtpRef = ref().child(`v1/accounts/${account}/configuration/smtp`)
        const smtpSnap = await smtpRef.once("value")
        if (smtpSnap.exists()) {
            const smtp: any = smtpSnap.val()
            this.setState({
                host: smtp.config.host,
                port: smtp.config.port,
                password: smtp.config.auth.pass,
                user: smtp.config.auth.user,
                secure: smtp.config.secure,
                useProxy: smtp.useProxy,
                loaded: true,
                sendTestEmailButtonEnabled: true
            })
        } else {
            this.setState({ loaded: true })
        }

    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="smtp configuration">
                <Panel key="upper_panel" bsStyle={this.state.showSuccess ? "success" : "default"}>
                    <Panel.Heading>
                        <Panel.Title>{this.state.showSuccess ? "SMTP configuration published successsfully" : "SMTP configuration"}</Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <Form horizontal={true} onSubmit={e => e.preventDefault()}>
                            <p>All configuration data is stored in plain text, so please use a dedicated service account if possible.</p>
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Host</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="host"
                                        value={this.state.host || ""}
                                        placeholder="Enter host/ip"
                                        onChange={(e: any) => { this.hostChanged(e.target.value) }}
                                        autoComplete="off"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Port</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="number"
                                        name="port"
                                        value={this.state.port || ""}
                                        placeholder="Enter port number"
                                        onChange={(e: any) => { this.portNumberChanged(e.target.value) }}
                                        autoComplete="off"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>User name</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="username"
                                        value={this.state.user || ""}
                                        placeholder="Enter username"
                                        onChange={(e: any) => { this.usernameChanged(e.target.value) }}
                                        autoComplete="off"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Password</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="password"
                                        value={this.state.password || ""}
                                        placeholder="Enter password"
                                        onChange={(e: any) => { this.passwordChanged(e.target.value) }}
                                        autoComplete="off"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Secure</Col>
                                <Col sm={10}>
                                    <Checkbox
                                        name="secure"
                                        checked={this.state.secure || false}
                                        onChange={(e: any) => { this.secureChanged(e.target.checked) }}
                                    >
                                        <p>If true the connection will use TLS when connecting to server. If false then TLS is used if server supports the STARTTLS extension.</p>
                                        <p>In most cases set this value to true if you are connecting to port 465. For port 587 or 25 keep it false.</p>
                                    </Checkbox>

                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Use proxy</Col>
                                <Col sm={10}>
                                    <Checkbox
                                        name="useProxy"
                                        checked={this.state.useProxy || false}
                                        onChange={(e: any) => { this.useProxyChanged(e.target.checked) }}
                                    >
                                        <p>If true all connections to the SMTP server will go through a SOCKS5 proxy. All connections to the SMTP server will originate from the IP address <b>34.90.50.208</b>. </p>
                                        <p>Use this if your SMTP server uses an IP whitelist for incoming connections.</p>
                                    </Checkbox>

                                </Col>
                            </FormGroup>
                        </Form>
                    </Panel.Body>

                    <Panel.Footer>
                        <ButtonToolbar>
                            <ButtonGroup>
                                <Button onClick={this.publish} disabled={!this.isSMTPConfigValid()}>Publish</Button>
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button onClick={this.showModal} disabled={!this.state.sendTestEmailButtonEnabled || this.state.dirty}>Send test mail</Button>
                            </ButtonGroup>
                            <ButtonGroup>
                                <p>Remember to publish configuration before trying to send a test email.</p>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Panel.Footer>
                </Panel>
                {
                    this.state.showModal
                        ?
                        this.testMailDialog()
                        :
                        null
                }
            </PageState>
        )
    }

    // Methods

    testMailDialog() {
        return (
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>Send test mail</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form horizontal={true} onSubmit={e => e.preventDefault()}>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>From email address</Col>
                            <Col sm={10}>
                                <FormControl
                                    type="text"
                                    name="from_test_email"
                                    value={this.state.fromTestEmailAddress || ""}
                                    placeholder="Enter from email"
                                    onChange={(e: any) => { this.fromTestEmailAddressChanged(e.target.value) }}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>To email address</Col>
                            <Col sm={10}>
                                <FormControl
                                    type="text"
                                    name="to_test_email"
                                    value={this.state.toTestEmailAddress || ""}
                                    placeholder="Enter to email"
                                    onChange={(e: any) => { this.toTestEmailAddressChanged(e.target.value) }}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={this.closeModal}>Cancel</Button>
                    <Button bsStyle="primary" onClick={this.sendTestMail} disabled={!this.state.sendEmailButtonEnabled}>Send</Button>
                </Modal.Footer>

            </Modal.Dialog>
        )
    }

    isSMTPConfigValid(): boolean {
        if (
            _.isNil(this.state.host) ||
            _.isNil(this.state.port) ||
            _.isNil(this.state.user) ||
            _.isNil(this.state.password)
        ) {
            return false
        }

        if (
            this.state.host.length === 0 ||
            this.state.user.length === 0 ||
            this.state.password.length === 0 ||
            isNaN(this.state.port)
        ) {
            return false
        }

        return true
    }

    hostChanged = (value: string) => {
        this.setState({ host: value, dirty: true })
    }

    portNumberChanged = (value: string) => {
        const numberValue = (value || "").length > 0 ? Number(value) : undefined
        this.setState({ port: numberValue, dirty: true })
    }

    usernameChanged = (value: string) => {
        this.setState({ user: value, dirty: true })
    }

    passwordChanged = (value: string) => {
        this.setState({ password: value, dirty: true })
    }

    secureChanged = (value: any) => {
        this.setState({ secure: value, dirty: true })
    }

    useProxyChanged = (value: any) => {
        this.setState({ useProxy: value, dirty: true })
    }

    sendTestMail = () => {
        if (!this.state.fromTestEmailAddress || !this.state.toTestEmailAddress) {
            return
        }

        const mailQueueRef = ref().child(`v1/accounts/${this.props.role.account_id}/mail/queue`)
        mailQueueRef.push({
            from_mail: this.state.fromTestEmailAddress,
            from_name: "Ka-ching",
            subject: "Ka-ching mail test",
            text: "Seems like your mail configuration is working!",
            to: this.state.toTestEmailAddress
        })

        this.setState({ showModal: false })
    }

    fromTestEmailAddressChanged = (value: string) => {
        let buttonEnabled = false
        if (EmailValidation.valid(value) && this.state.toTestEmailAddress && EmailValidation.valid(this.state.toTestEmailAddress)) {
            buttonEnabled = true
        }
        this.setState({ fromTestEmailAddress: value, sendEmailButtonEnabled: buttonEnabled })
    }

    toTestEmailAddressChanged = (value: string) => {
        let buttonEnabled = false
        if (EmailValidation.valid(value) && this.state.fromTestEmailAddress && EmailValidation.valid(this.state.fromTestEmailAddress)) {
            buttonEnabled = true
        }
        this.setState({ toTestEmailAddress: value, sendEmailButtonEnabled: buttonEnabled })
    }

    showModal = () => {
        this.setState({ showModal: true })
    }

    closeModal = () => {
        this.setState({ showModal: false })
    }

    publish = async () => {
        const account = this.props.role.account_id
        const smtpConfigRef = ref().child(`v1/accounts/${account}/configuration/smtp`)

        this.setState({ publishing: true })

        const smtpConfig = {
            config: {
                auth: {
                    pass: this.state.password,
                    user: this.state.user,
                },
                host: this.state.host,
                port: this.state.port,
                secure: this.state.secure || false,
            },
            useProxy: this.state.useProxy || false
        }

        await smtpConfigRef.set(smtpConfig)

        this.setState({
            showSuccess: true,
            publishing: false,
            dirty: false,
            sendTestEmailButtonEnabled: true
        })
    }
}
