import React from "react";
import { Panel } from "react-bootstrap";
import { Market } from "../../../../models/MarketModels";
import { AppliesTo } from "../AppliesToSelector";
import { CustomerCondition } from "../CustomerConditionSelector";
import { DiscountType } from "../DiscountSelector";
import Numeral from "numeral";
import { MarketAmount } from "../../../../models/MarketAmount";
import { LanguageCode } from "../../../../helpers/L10n";
import { BuyXGetYFormProps } from "./BuyXGetYForm";
import * as _ from "lodash"
import { momentFromDateComponents } from "../RuleTemplateForm";
import moment from "moment";

interface DescriptionProps {
    discountType: DiscountType;
    // requirementsType: RequirementsType;
    // steps: BundleRowData[];
    appliesTo: AppliesTo;
    formProps: BuyXGetYFormProps;
    customerCondition: CustomerCondition;
    market: Market | null;
    validation: boolean
}
export function Description(props: DescriptionProps) {
    return <Panel bsStyle={props.validation ? "success" : "danger"}>
        <Panel.Heading>
            <Panel.Title>Discount description</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
            {formattedDescription(props)}
        </Panel.Body>
    </Panel>;
}

function formattedDescription(props: DescriptionProps) {
    const marketId: string | null = props.market?.id ?? null;

    const tagNameForId = (id: string): string | null => {
        if (!props.formProps.tagObserver.tagsDict) {
            return id;
        }
        const tag = props.formProps.tagObserver.tagsDict[id];
        let result = id;
        if (tag) {
            result = tag.name.localized(LanguageCode.da);
        }
        return result;
    };
    const productNameForId = (id: string): string | null => {
        if (!props.formProps.productObserver.productsDict) {
            return null;
        }
        const product = props.formProps.productObserver.productsDict[id];
        let result = null;
        if (product) {
            result = product.localizedName(LanguageCode.da);
        }
        return result;
    };

    let matchString: string = "";
    if (props.appliesTo.type === "all") {
        matchString = "of any kind";
    } else if (props.appliesTo.type === "tags") {
        const list = props.appliesTo.tags.map(tagId => {
            return tagNameForId(tagId);
        });

        if (list.length === 1) {
            matchString = `that have the tag '${list[0]}'`;
        } else {
            matchString = `that have any of the tags '${list.join(", ")}'`;
        }

    } else if (props.appliesTo.type === "products") {

        const productNames = props.appliesTo.products.map(productId => {
            return productNameForId(productId) || productId;
        });
        if (productNames.length === 0) {
            matchString = "(please select one or more products)";
        }
        else if (productNames.length === 1) {
            matchString = `of type ${productNames[0]}`;
        } else {
            matchString = `in the list: [${productNames.join(", ")}]`;
        }
    } else if (props.appliesTo.type === "attributes") {
        const attributes = props.appliesTo.attributes;
        const attributeDescriptions = attributes.map((attributeSelection) => {
            const attribute = props.formProps.attributeObserver.attributesDict?.[attributeSelection.attributeId];
            if (attribute === undefined) {
                return "";
            }
            const option = attribute.type.options?.[attributeSelection.optionId];
            if (option === undefined) {
                return "";
            }

            return `${attribute.name.localized(LanguageCode.da)}: ${option.name.localized(LanguageCode.da)}`;
        });
        if (attributeDescriptions.length === 1) {
            matchString = `with the attribute value: ${attributeDescriptions[0]}`;
        } else {
            matchString = `with any of the attribute values in the list: [${attributeDescriptions.join(", ")}]`;
        }
    }

    const conditionString: string = `When ${props.formProps.template.triggerCount ?? "X"} items ${matchString} are present, the cheapest ${props.formProps.template.applicationCount ?? "Y"} receive`;

    let discountString = "";
    if (props.discountType === "percentage") {
        const value = props.formProps.template.discountPercentage ?? 0;
        const percentage = Numeral(value).multiply(100).format("0.0");
        discountString = `a discount of ${percentage} percent.`;
    } else if (props.discountType === "amount_off") {
        const value = props.formProps.template.discountAmount ?? new MarketAmount(0);
        const formatted = Numeral(value.amount(marketId)).format("0,0.00");
        discountString = `a discount of ${formatted}.`;
    } else if (props.discountType === "new_price") {
        const value = props.formProps.template.discountAmount ?? new MarketAmount(0);
        const formatted = Numeral(value.amount(marketId)).format("0,0.00");
        discountString = `a new price of ${formatted}.`;
    }

    let customerString = "";
    if (props.customerCondition.type === "all") {
        // No description when it applies on all sales
    } else if (props.customerCondition.type === "members") {
        customerString = "Only applies when a customer is added to the sale.";
    } else if (props.customerCondition.type === "customer_selection") {
        customerString = `Only applies for the following customers: ${props.customerCondition.customers.join(", ")}`;
    } else if (props.customerCondition.type === "attributes") {
        const attributes = props.customerCondition.attributes;
        const attributeDescriptions = attributes.map((attributeSelection) => {
            const attribute = props.formProps.customerAttributeObserver.attributesDict?.[attributeSelection.attributeId];
            if (attribute === undefined) {
                return "";
            }
            const option = attribute.type.options?.[attributeSelection.optionId];
            if (option === undefined) {
                return "";
            }

            return `${attribute.name.localized(LanguageCode.da)}: ${option.name.localized(LanguageCode.da)}`;
        });
        if (attributeDescriptions.length === 1) {
            customerString = `Applies to customers with the attribute value: ${attributeDescriptions[0]}`;
        } else {
            customerString = `Applies to customers with any of the attribute values in the list: [${attributeDescriptions.join(", ")}]`;
        }
    }
    const mainDescription = [conditionString, discountString].join(" ")
    const descriptionStrings: string[] = [mainDescription, customerString]

    if (!_.isNil(props.formProps.template.start_date) && !_.isNil(props.formProps.template.end_date)) {
        const from = momentFromDateComponents(props.formProps.template.start_date).format("MMMM Do, YYYY")
        const to = momentFromDateComponents(props.formProps.template.end_date).format("MMMM Do, YYYY")
        descriptionStrings.push(`The discount runs from ${from} to ${to} - including both dates`)
    } else if (!_.isNil(props.formProps.template.start_date)) {
        const from = momentFromDateComponents(props.formProps.template.start_date).format("MMMM Do, YYYY")
        descriptionStrings.push(`The discount runs from ${from} and continues to run until it is deleted`)
    } else if (!_.isNil(props.formProps.template.end_date)) {
        const to = momentFromDateComponents(props.formProps.template.end_date).format("MMMM Do, YYYY")
        descriptionStrings.push(`The discount runs to the end of ${to}`)
    } else {
        descriptionStrings.push(`The discount has no time limits and runs until it is deleted.`)
    }

    const today = moment().startOf("day").format("YYYY-MM-DD")
    let expired = false
    let upcoming = false
    if (!_.isNil(props.formProps.template.end_date)) {
        const endDate = momentFromDateComponents(props.formProps.template.end_date).format("YYYY-MM-DD")
        if (today > endDate) {
            expired = true
        }
    }
    if (!_.isNil(props.formProps.template.start_date)) {
        const startDate = momentFromDateComponents(props.formProps.template.start_date).format("YYYY-MM-DD")
        if (today < startDate) {
            upcoming = true
        }
    }

    const lines = descriptionStrings.map((value, index) => { return <p key={index}>{value}</p> })
    if (upcoming) {
        lines.push(<p key="a"><b>NOTE: This discount is not yet active!</b></p>)
    }
    if (expired) {
        lines.push(<p key="b"><b>NOTE: This discount has expired!</b></p>)
    }
    const continueEvaluation = props.formProps.template.continue_evaluation || false
    if (continueEvaluation) {
        lines.push(<p key="c"><b>NOTE: After this discount has applied, the line items receiving discounts are legible for other discounts.</b></p>)
    }

    return lines
}
