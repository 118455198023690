import React from "react"
import { FormGroup, Panel, Radio } from "react-bootstrap"
import { MarketAmount } from "../../../models/MarketAmount"
import { Market } from "../../../models/MarketModels"
import { DecimalValueFormControl } from "../../DecimalValueFormControl"
import { MarketAmountFormControl } from "../../MarketAmountFormControl"

interface DiscountSelectorProps {
    type: DiscountType
    discountPercentage?: number
    discountAmount?: MarketAmount
    typeChanged: (type: DiscountType, percentage?: number, amount?: MarketAmount) => void
    showDiscountValue?: Boolean
    market?: Market
    markets: string[]
    validation: boolean
}

export type DiscountType = "percentage" | "amount_off" | "new_price"

export function DiscountSelector(props: DiscountSelectorProps) {
    return <Panel bsStyle="primary">
        <Panel.Heading>
            <Panel.Title>Discount type</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
            <FormGroup style={{ marginLeft: "0px", marginRight: "0px" }}
                validationState={props.validation ? null : "error"}
            >
                <Radio
                    name="discountSelector"
                    checked={props.type === "percentage"}
                    onChange={() => { props.typeChanged("percentage", props.discountPercentage, props.discountAmount) }}
                >
                    Percentage
                </Radio>
                <Radio
                    name="discountSelector"
                    checked={props.type === "amount_off"}
                    onChange={() => { props.typeChanged("amount_off", props.discountPercentage, props.discountAmount) }}
                >
                    Amount off
                </Radio>
                <Radio
                    name="discountSelector"
                    checked={props.type === "new_price"}
                    onChange={() => { props.typeChanged("new_price", props.discountPercentage, props.discountAmount) }}
                >
                    New price
                </Radio>
                {
                    props.showDiscountValue === true && (
                        props.type === "percentage" ? <div>
                            <br />
                            Reduce price by
                            <DecimalValueFormControl
                                value={props.discountPercentage !== undefined ? Math.round(props.discountPercentage * 1000) / 10 : null}
                                onValueChanged={value => {
                                    let scaled: number | undefined = undefined
                                    if (value !== null) {
                                        scaled = value / 100
                                    }
                                    props.typeChanged(props.type, scaled, undefined)
                                }}
                                decimals={1}
                                prefixSymbol="%"
                                placeholder="Enter discount percentage"
                            />

                        </div>
                            :
                            <div>
                                <br />
                                {props.type === "amount_off" ? <span>Reduce price by</span> : <span>Set price to</span>}
                                <MarketAmountFormControl
                                    allMarkets={props.markets}
                                    amount={props.discountAmount ?? null}
                                    market={props.market ?? null}
                                    onAmountChanged={amount => {
                                        props.typeChanged(props.type, undefined, amount ?? undefined)
                                    }}
                                    placeholder="Enter discount amount"
                                    currency={props.market?.currency ?? ""}
                                />
                            </div>
                    )
                }
            </FormGroup>
        </Panel.Body >
    </Panel >
}

