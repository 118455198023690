import * as moment from "moment"
import * as React from "react"
import {
    Button,
    Col,
    Grid,
    Panel,
    Row
    } from "react-bootstrap"
import { DateRangePicker } from "react-dates"
import { ReportBuilder } from "../services/ReportBuilder"
import { ReportType } from "../models/ReportModels"
import { Role } from "../config/role"

const FileDownload = require("js-file-download")

interface ReportComponentProps {
    shop: string
    role: Role
    report: ReportType
}

interface ReportComponentState {
    startAt: moment.Moment | null
    endAt: moment.Moment | null
    focusedInput?: any
}

export class ReportComponent extends React.Component<ReportComponentProps, ReportComponentState> {

    constructor(props: ReportComponentProps) {
        super(props)

        this.state = {
            startAt: null,
            endAt: null
        }
    }

    render() {
        return (
            <Panel>
                <Panel.Heading>{this.props.report.title()}</Panel.Heading>
                <Panel.Body>
                    <Grid>
                        <Row className="show-grid">
                            <Col sm={6}>{this.props.report.description()}</Col>
                        </Row>
                        <br />
                        <br />
                        <Row className="show-grid">
                            <Col sm={6}>
                                <DateRangePicker
                                    startDateId="startDate"
                                    endDateId="endDate"
                                    minimumNights={0}
                                    isOutsideRange={() => { return false }}
                                    startDate={this.state.startAt}
                                    endDate={this.state.endAt}
                                    onDatesChange={( { startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null; }) => { 
                                        this.setState({ startAt: startDate, endAt: endDate }) 
                                    }}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={(focusedInput: any) => this.setState({ focusedInput })}
                                />
                            </Col>
                            <Col sm={6}>
                                <div className="btn-toolbar">
                                    <Button onClick={this.generateAndDownloadCSV} disabled={!this.canGenerateReport()}>Download CSV</Button>
                                    <Button onClick={this.generateAndDownloadPDF} disabled={!this.canGenerateReport()}>Download PDF</Button>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </Panel.Body>
            </Panel>
        )
    }

    canGenerateReport(): boolean {
        return (this.state.startAt !== null) && (this.state.endAt !== null)
    }

    generateAndDownloadCSV = async () => {
        if (!this.state.startAt || !this.state.endAt) {
            return
        }

        const reportBuilder = new ReportBuilder(this.props.role.account_id, this.props.shop)
        const fieldDelimiter = ";"
        const decimalSeparator = ","
        const report = this.props.report
        const csv = await reportBuilder.buildCSVReport(report, fieldDelimiter, decimalSeparator, this.state.startAt, this.state.endAt)

        const filename = await report.buildDocumentName(this.props.role.account_id, this.props.shop, true, report.title())

        FileDownload(csv, `${filename}.csv`)
    }

    generateAndDownloadPDF = async () => {
        if (!this.state.startAt || !this.state.endAt) {
            return
        }

        const reportBuilder = new ReportBuilder(this.props.role.account_id, this.props.shop)
        const report = this.props.report
        const pdf = await reportBuilder.buildPDFReport(report, this.state.startAt, this.state.endAt)
        const filename = await report.buildDocumentName(this.props.role.account_id, this.props.shop, true, report.title())

        pdf.download(`${filename}.pdf`)
    }
}