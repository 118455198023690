import "react-dropzone-component/styles/filepicker.css" //tslint:disable-line
import "dropzone/dist/min/dropzone.min.css" //tslint:disable-line
import DropzoneComponent, { DropzoneComponentProps } from "react-dropzone-component"
import * as ReactDOM from "react-dom"
import { storage } from "../config/constants"

interface FirebaseStorageDropzoneComponentProps extends DropzoneComponentProps {
    isPublic?: boolean
}

// NOTE:
// Based on https://raw.githubusercontent.com/enyo/dropzone/master/dist/dropzone.js
// and https://github.com/felixrieseberg/React-Dropzone-Component/blob/master/src/react-dropzone.js
// I have tampered with Dropzone + Component in order to make it upload to firebase storage instead of simply post'ing.
// More fiddling may be necessary in the future.

// TODO: This is still basically javascript - 'any' is used to silence typescript-compiler
export class FirebaseStorageDropzoneComponent extends DropzoneComponent<FirebaseStorageDropzoneComponentProps> {
    componentDidMount() {
        const anyThis: any = this

        const options = anyThis.getDjsConfig()

        options.postPath = anyThis.props.config.postPath

        const Dropzone = require("dropzone")
        Dropzone.autoDiscover = false

        const dropzoneNode = anyThis.props.config.dropzoneSelector || ReactDOM.findDOMNode(anyThis)
        anyThis.dropzone = new Dropzone(dropzoneNode, options)
        anyThis.dropzone.uploadFiles = function (files: any) {
            const file = files[0]
            const filename = file.nameForUpload ? file.nameForUpload : file.name
            const storageRef = storage.ref()
            const fileRef = storageRef.child(this.options.postPath + filename.split(".")[0])
            const uploadTask = fileRef.put(file)

            // Register three observers:
            // 1. 'state_changed' observer, called any time the state changes
            // 2. Error observer, called on failure
            // 3. Completion observer, called on successful completion
            uploadTask.on("state_changed", (snapshot: any) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                this.emit("uploadprogress", file, progress, file.upload.bytesSent)
            }, (error: any): any => {
                // Handle unsuccessful uploads
                return ""
            }, async () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                let downloadURL: string = await fileRef.getDownloadURL()

                // Remove 'token' query parameter from URL if public
                const isPublic = anyThis.props.isPublic || false
                if (isPublic) {
                    const comps = downloadURL.split("?")
                    if (comps.length === 2) {
                        const params = comps[1].split("&")
                        const newParams: string[] = []
                        for (const param of params) {
                            if (!param.startsWith("token=")) {
                                newParams.push(param)
                            }
                        }
                        comps[1] = newParams.join("&")
                        downloadURL = comps.join("?")
                    }
                }

                // console.log('Upload is complete with dl URL ' + downloadURL);
                this._finished(files, "Upload complete", downloadURL)
                return undefined as any
            })

            uploadTask.then(function (snapshot: any) {
                // console.log('Uploaded a blob or file!');
            }, function (error: any) {
                console.log(error)
            })
        }

        anyThis.setupEvents()
    }

}
