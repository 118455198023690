import React, { useState, useEffect } from "react"
import { Button, DropdownButton, FormControl, FormGroup, InputGroup, MenuItem, Panel, Radio } from "react-bootstrap"
import { LanguageCode } from "../../../helpers/L10n"
import { AttributeObserver } from "../../../helpers/attributeObserver"
import { Attribute } from "../../../models/Product"
import { StripedTable } from "../../../components/StripedTable"
import * as _ from "lodash"

interface CustomerElementProps {
    customer: string
    remove: (key: string) => void
}

function CustomerElement(props: CustomerElementProps) {
    return <tr><td>{props.customer}<Button className="pull-right" bsStyle="link" onClick={() => { props.remove(props.customer) }}><DeleteButton /></Button></td></tr>
}

export function DeleteButton() {
    return <span role="img" aria-label="delete button">✖️</span>
}

interface AttributeElementProps {
    attribute?: Attribute
    option: string
    remove: (key: string) => void
}
function AttributeElement(props: AttributeElementProps) {
    if (!props.attribute) {
        return <span />
    }
    const option = (props.attribute!.type.options ?? {})[props.option]
    return <tr><td>{props.attribute.name.localized(LanguageCode.da)}: {option.name.localized(LanguageCode.da)}<Button style={{ float: "right" }} bsStyle="link" onClick={() => { props.remove(props.attribute!.id) }}><DeleteButton /></Button></td></tr>
}

interface SelectAttributeOptionProps {
    attribute?: Attribute
    optionSelected: (attributeId: string, optionId: string) => void
}

function SelectAttributeOption(props: SelectAttributeOptionProps) {
    const options = (props.attribute!.type.options ?? {})
    return <DropdownButton title={`Select option for ${props.attribute!.name.localized(LanguageCode.da)}`} id="a">
        {Object.keys(options).map(key => {
            const option = options[key]

            return <MenuItem key={option.id} onSelect={() => {
                props.optionSelected(props.attribute!.id, key)
            }} eventKey={option.id}>{option.name.localized(LanguageCode.da)}</MenuItem>
        })}
    </DropdownButton>
}

interface CustomerConditionSelectorProps {
    condition: CustomerCondition
    conditionChanged: (condition: CustomerCondition) => void
    attributesObserver: AttributeObserver
    validation: boolean
}

interface AttributeSelection {
    attributeId: string
    optionId: string
}

export interface CustomerCondition {
    type: CustomerConditionType
    customers: string[]
    attributes: AttributeSelection[]
}

export type CustomerConditionType = "all" | "members" | "customer_selection" | "attributes"

export function CustomerConditionSelector(props: CustomerConditionSelectorProps) {
    const [selectedCustomers, setSelectedCustomers] = useState<string[]>(props.condition.customers)
    const [selectedAttributes, setSelectedAttributes] = useState<AttributeSelection[]>(props.condition.attributes)
    const [selectAttributeOption, setSelectAttributeOption] = useState<string | undefined>(undefined)
    const [customerId, setCustomerId] = useState<string>("")
    const [type, setType] = useState<CustomerConditionType>(props.condition.type);

    const addCustomerId = () => {
        const clone = [...selectedCustomers]
        clone.push(customerId)
        setSelectedCustomers(clone)
        setCustomerId("")
    }

    useEffect(() => {
        const existing = props.condition
        const updated: CustomerCondition = {
            type: type,
            customers: selectedCustomers,
            attributes: selectedAttributes
        }
        if (_.isEqual(existing, updated)) {
            return
        }
        props.conditionChanged(updated)
    }, [type, selectedAttributes, selectedCustomers, props])

    return <Panel bsStyle="primary">
        <Panel.Heading>
            <Panel.Title>Customer conditions</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
            <FormGroup
                style={{ marginLeft: "0px", marginRight: "0px" }}
                validationState={props.validation ? null : "error"}
            >
                <Radio
                    name="customerConditionSelector"
                    checked={type === "all"}
                    onChange={() => { setType("all") }}
                >
                    All sales
                </Radio>
                <Radio
                    name="customerConditionSelector"
                    checked={type === "members"}
                    onChange={() => { setType("members") }}
                >
                    Members only
                </Radio>
                <Radio
                    name="customerConditionSelector"
                    checked={type === "customer_selection"}
                    onChange={() => { setType("customer_selection") }}
                >
                    Specific customers
                </Radio>
                <Radio
                    name="customerConditionSelector"
                    checked={type === "attributes"}
                    onChange={() => { setType("attributes") }}
                >
                    Customers with specific attribute options
                </Radio>
                {type !== "all" && type !== "members" && <hr />}
                {type === "customer_selection" &&
                    <div>
                        {selectedCustomers.length > 0 &&
                            <StripedTable>
                                <thead><tr><td colSpan={2}>Apply discount for any of the customers below</td></tr></thead>
                                <tbody>
                                    {selectedCustomers.map(key => {
                                        return <CustomerElement key={key} remove={key => {
                                            const clone = selectedCustomers.filter(element => { return element !== key })
                                            setSelectedCustomers(clone)
                                        }} customer={key} />
                                    })}
                                </tbody>
                            </StripedTable>
                        }

                        <InputGroup>
                            <InputGroup.Button>
                                <Button onClick={() => {
                                    addCustomerId()
                                }}>Add</Button>
                            </InputGroup.Button>

                            <FormControl
                                // onBlur={() => { this.onBlur() }}
                                // onFocus={() => { this.setState({ focus: true }) }}
                                type="text"
                                value={customerId}
                                placeholder="Enter customer identifier"
                                onChange={e => { setCustomerId((e.target as any).value as string) }}
                                onKeyPress={e => {
                                    if (e.key === "Enter") {
                                        addCustomerId()
                                    }
                                }}
                                autoComplete="off"
                            />
                        </InputGroup>
                    </div>
                }

                {type === "attributes" &&
                    <div>
                        {selectedAttributes.length > 0 &&
                            <StripedTable>
                                <thead><tr><td>Apply discount if the product has the specified value for any of the attributes below</td></tr></thead>
                                <tbody>
                                    {selectedAttributes.map(key => {
                                        return <AttributeElement key={`${key.attributeId}-${key.optionId}`} remove={key => {
                                            const clone = selectedAttributes.filter(element => { return element.attributeId !== key })
                                            setSelectedAttributes(clone)
                                        }} attribute={(props.attributesObserver.attributesDict ?? {})[key.attributeId]} option={key.optionId} />
                                    })}
                                </tbody>
                            </StripedTable>
                        }
                        {selectAttributeOption !== undefined ?
                            <SelectAttributeOption attribute={(props.attributesObserver.attributesDict ?? [])[selectAttributeOption]} optionSelected={(attributeId, optionId) => {
                                const clone = [...selectedAttributes]
                                clone.push({ attributeId: attributeId, optionId: optionId })
                                setSelectedAttributes(clone)
                                setSelectAttributeOption(undefined)
                            }} /> :
                            <DropdownButton title="Select one or more option attributes" id="a">
                                {(props.attributesObserver.attributesArray ?? []).map(attribute => {
                                    // if (selectedAttributes.find(entry => { return entry.attributeId === attribute.id }) !== undefined) {
                                    //     return undefined
                                    // }
                                    if (attribute.type.options === undefined) {
                                        return undefined
                                    }

                                    return <MenuItem key={attribute.id} onSelect={() => {
                                        setSelectAttributeOption(attribute.id)
                                    }} eventKey={attribute.id}>{attribute.name.localized(LanguageCode.da)}</MenuItem>
                                })}
                            </DropdownButton>
                        }
                    </div>
                }
            </FormGroup>
        </Panel.Body>
    </Panel >
}

