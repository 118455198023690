import * as _ from "lodash"
import * as React from "react"

import { Button, Col, ControlLabel, FormGroup, Glyphicon, Panel, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { shopifyAPIVersion, ShopifyConfiguration } from "./Models"

enum TaxType {
    VAT = "vat",
    SALES_TAX = "sales_tax"
}

interface ShopSalesExportPanelProps {
    accountId: string
    configuration: ShopifyConfiguration
    enabled: boolean
    openShopifyPrivateApps: () => void
    updateDatabaseUpdates: (updates: _.Dictionary<any>) => void
    updateEnabled: (enabled: boolean) => void
    updateTaxType: (taxType: string) => void
    requestHeaders: () => any
}

interface ShopSalesExportPanelState {
    valid: boolean
}

export class ShopSalesExportPanel extends React.Component<ShopSalesExportPanelProps, ShopSalesExportPanelState> {

    constructor(props: ShopSalesExportPanelProps) {
        super(props)
        this.state = {
            valid: this.isConfigurationValid(props.configuration)
        }
    }

    // Helpers

    isConfigurationValid(configuration: ShopifyConfiguration) {
        return (
            configuration.taxType.length > 0 &&
            Object.keys(configuration.locationIdMap).length > 0
        )
    }

    shopSalesExportHelpText = (
        <div key="shop_sales_export_help_text">
            Follow these steps to configure export of shop sales from Ka-ching to Shopify
            <ol>
                <li>
                    Click the "Open Shopify Configuration" button above to open Shopify settings. Click the Ka-ching private app in that page.
                </li>
                <li>
                    Make sure that Ka-ching has access to create orders in Shopify. The shop sales export requires 'Read and write' to 'Orders, transactions and fulfillments'.
                </li>
                <li>
                    Remember to save any changes in Shopify.
                </li>
                <li>
                    Choose tax type.
                </li>
                <li>
                    Toggle the Disabled/Enabled button below to enable the integration in Ka-ching.
                </li>
                <li>
                    Publish the Shopify configuration changes
                </li>
            </ol>
        </div>
    )

    updateTaxSelection = (data: any) => {
        const taxTypeIds: string[] = data
        _.remove(taxTypeIds, (val) => { return val === this.props.configuration.taxType })
        if (taxTypeIds.length > 0) {
            this.props.updateTaxType(taxTypeIds[0])
        }
    }

    enableButton = (enabled: boolean) => {
        return (
            <Button bsStyle={enabled ? "success" : "default"} active={enabled} onClick={() => { this.toggleEnabled() }}>
                {enabled ? "Enabled" : "Disabled"}
            </Button>
        )
    }

    toggleEnabled = () => {
        const enabled = !this.props.enabled
        this.props.updateEnabled(enabled)

        // update customer lookup config based on enabled status
        if (enabled) {
            const config = {
                delivery : {
                    parameters : {
                        headers : this.props.requestHeaders(),
                        method : "POST",
                        url : `https://${this.props.configuration.shopifyShopName}.myshopify.com/admin/api/${shopifyAPIVersion}/orders.json`
                    },
                    type : "web_hook"
                },
                filters : {
                    skip_return : false,
                    skip_voided : true
                },
                name : "Shopify Shop Sales Export",
                show_in_portal : false,
                transformation : {
                    parameters : {
                        api_key : this.props.configuration.shopifyAPIKey ?? null,
                        default_country_code : "dk", // TODO: Make it configurable somehow? Or somehow make the shops country code available to the export? It might not make sense to pick a default country code if across several markets the shipping address in POS doesn't ask for country.
                        ecom_id : this.props.configuration.ecomIntegrationId,
                        location_id_map : this.props.configuration.locationIdMap,
                        password : this.props.configuration.shopifyPassword ?? null,
                        access_token : this.props.configuration.shopifyAccessToken ?? null,
                        shopify_id : this.props.configuration.shopifyShopName,
                        tax_type : this.props.configuration.taxType,
                        shop_sales : true
                    },
                    type : "shopify"
                },
                trigger : {
                    type : "realtime"
                }
              }
            
            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/export_integrations/sales/shopify_shop`] = config
            this.props.updateDatabaseUpdates(dbUpdates)
        } else {
            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/export_integrations/sales/shopify_shop`] = null
            this.props.updateDatabaseUpdates(dbUpdates)
        }
    } 

    // Component

    componentWillReceiveProps(newProps: ShopSalesExportPanelProps) {
        this.setState({ valid: this.isConfigurationValid(newProps.configuration) })
    }

    render() {
        return (
            <div>
                <Panel key="shop_sales_export" defaultExpanded={false}>
                    <Panel.Heading>
                        <Panel.Title toggle={true}>Shop sales</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <Button onClick={() => { this.props.openShopifyPrivateApps() }}>Open Shopify Configuration <Glyphicon glyph="new-window"/></Button>
                            <br/> <br/>
                            {this.shopSalesExportHelpText}
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Tax type</Col>
                                <Col sm={10}>
                                    <ToggleButtonGroup
                                        type="checkbox"
                                        value={[this.props.configuration.taxType]}
                                        onChange={(data: any) => { this.updateTaxSelection(data) }}
                                    >
                                        {
                                            [TaxType.VAT, TaxType.SALES_TAX].map((taxTypeId) => {
                                                const name = taxTypeId === TaxType.VAT ? "VAT" : "Sales Tax"
                                                return <ToggleButton key={taxTypeId} value={taxTypeId}>{name}</ToggleButton>
                                            })
                                        }
                                    </ToggleButtonGroup>
                                </Col>
                            </FormGroup>
                            <br/> <br/>
                            {
                                this.state.valid
                                ?
                                this.enableButton(this.state.valid && this.props.enabled)
                                :
                                <Button bsStyle="danger" disabled={true}>Invalid</Button>
                            }
                            <br/> <br/>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            </div>
        )
    }
}