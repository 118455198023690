import * as React from "react"
import { Globals } from "../helpers/globals"
import { Market } from "../models/MarketModels"
import { Button, FormGroup, Col, ControlLabel, ToggleButtonGroup, ToggleButton, Modal } from "react-bootstrap"

interface MarketSelectorProps {
    removeMarket: (market: string) => void
    addMarket: (market: string) => void
    selectedMarkets: string[]
    typeName: string
    showDeleteWarning?: boolean
}

interface MarketSelectorState {
    markets: Market[],
    showAlertForMarket: string | null
}

export class MarketSelector extends React.Component<MarketSelectorProps, MarketSelectorState> {
    constructor(props: MarketSelectorProps) {
        super(props)

        this.state = {
            markets: [],
            showAlertForMarket: null
        }
    }

    onChange(event: any) {
        const selected = event as string[]
        const existing = this.props.selectedMarkets || []
        for (const key of existing) {
            if (!selected.includes(key)) {
                if (this.props.showDeleteWarning === false) {
                    this.props.removeMarket(key)
                } else {
                    this.setState({ showAlertForMarket: key })
                }
                break
            }
        }
        for (const key of selected) {
            if (!existing.includes(key)) {
                this.props.addMarket(key)
                break
            }
        }
    }

    removeMarket() {
        const key = this.state.showAlertForMarket
        if (key) {
            this.props.removeMarket(key)
        }
    }

    async componentDidMount() {
        const markets = await Globals.shared.getMarkets()
        this.setState({ markets: markets })
    }

    render() {
        return [
            (this.state.markets.length > 1) ? (
                <FormGroup key="a">

                    <Col componentClass={ControlLabel} sm={2}>Markets</Col>
                    <Col sm={10}>
                        <ToggleButtonGroup
                            type="checkbox"
                            value={this.props.selectedMarkets}
                            onChange={(event: any) => { this.onChange(event) }}
                        >
                            {
                                this.state.markets.map((market) => {
                                    return <ToggleButton key={market.id} value={market.id}>{market.name}</ToggleButton>
                                })
                            }
                        </ToggleButtonGroup>
                    </Col>

                </FormGroup>
            ) : [],
            (
                this.state.showAlertForMarket !== null ?
                    (
                        <Modal.Dialog key="b">
                            <Modal.Header>
                                <Modal.Title>Remove Market Values</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>The amounts for the deselected market will be deleted for this {this.props.typeName} only.</Modal.Body>

                            <Modal.Footer>
                                <Button onClick={() => { this.setState({ showAlertForMarket: null }) }}>Cancel</Button>
                                <Button bsStyle="danger" onClick={() => { this.removeMarket(); this.setState({ showAlertForMarket: null }) }}>Remove Market</Button>
                            </Modal.Footer>
                        </Modal.Dialog>
                    ) : null
            )

        ]
    }
}
