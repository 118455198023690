import * as React from "react"
import { Image, Button, Glyphicon } from "react-bootstrap"
import { FirebaseStorageDropzoneComponent } from "./FirebaseStorageDropzoneComponent"

interface ImageDropControlProps {
    filePath: string
    fileName: string
    maxFileSize?: number
    validateSize?: (width: number, height: number) => boolean
    deleteAction: () => void
    uploadAction: (url: string, file?: any) => void
    dropTitle: string
    imageURL?: string
    isPublic?: boolean
}

export default class ImageDropControl extends React.Component<ImageDropControlProps, {}> {
    dropzoneComponentConfig = () => {
        const filePath = this.props.filePath
        return {
            iconFiletypes: [".jpg", ".png"],
            showFiletypeIcon: true,
            postUrl: "dummy",
            postPath: filePath
        }
    }

    dropzoneConfig = () => {
        const fileName = this.props.fileName
        return {
            autoProcessQueue: true,
            maxFiles: 1,
            maxFilesize: this.props.maxFileSize ? this.props.maxFileSize : 2,
            acceptedFiles: "image/png,image/jpeg",
            accept: (file: any, done: (result: any) => void) => {
                if (file.name.split(".").length < 1) {
                    alert("Invalid file name: missing extension")
                    done("Invalid file name")
                    return
                } else {
                    const nameForUpload = fileName + "." + file.name.split(".").pop()
                    file.nameForUpload = nameForUpload
                }
                file.acceptDimensions = done
                file.rejectDimensions = function () {
                    alert("Invalid dimensions")
                    done("Invalid dimension.")
                }
            },
        }
    }

    dropzoneEventHandlers = () => {
        let dropzoneComponent: any
        return {
            init: (component: any) => {
                dropzoneComponent = component
            },
            success: (file: any, message: any, e: any) => {
                this.uploadAction(e, file)
            },
            thumbnail: (file: any) => {
                if (file.accepted !== false) {
                    const validated = this.validateSize(file.width, file.height)

                    if (validated !== true) {
                        dropzoneComponent.removeFile(file)
                        file.rejectDimensions()
                    } else {
                        file.acceptDimensions()
                    }
                }
            }
        }
    }

    validateSize(width: number, height: number): boolean {
        if (this.props.validateSize === undefined || this.props.validateSize === null) {
            return true
        }

        return this.props.validateSize(width, height)
    }

    deleteAction() {
        if (this.props.deleteAction === undefined || this.props.deleteAction === null) {
            return
        }

        this.props.deleteAction()
    }

    uploadAction(url: string, file: any) {
        this.props.uploadAction(url, file)
    }

    render() {
        const dropTitle = this.props.dropTitle
        const imageURL = this.props.imageURL

        return (
            <div style={{ width: "100%" }}>
                {
                    imageURL === null || imageURL === undefined || imageURL === "" ?
                        <div>
                            <strong>{dropTitle}</strong>

                            <FirebaseStorageDropzoneComponent
                                config={this.dropzoneComponentConfig()}
                                eventHandlers={this.dropzoneEventHandlers()}
                                djsConfig={this.dropzoneConfig()}
                                isPublic={this.props.isPublic}
                            />
                        </div> : null}
                {
                    imageURL !== null && imageURL !== undefined && imageURL !== "" ?
                        <div>
                            <div style={{ position: "relative", width: "100%" }}>
                                <div style={{ position: "relative", display: "inline-block" }}>
                                    <Image style={{ maxHeight: "300px" }} src={imageURL} responsive={true} />
                                    <Button style={{ width: "30px", height: "30px", fontSize: "14px", padding: "5px 0px", borderRadius: "15px", position: "absolute", top: "-10px", right: "-10px" }} bsStyle="danger" onClick={(event) => { this.deleteAction(); event.stopPropagation() }}><Glyphicon glyph="remove" /></Button>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        )
    }
}
