
import * as React from "react"
import { RouteComponentProps, withRouter } from "react-router"
import { Button, Col, ControlLabel, FormControl, FormGroup, InputGroup, Label, Modal, Pager, Row } from "react-bootstrap"
import * as _ from "lodash"
import { Role } from "../../../config/role"
import { LanguageCode } from "../../../helpers/L10n"
import { ProductListViewModel, ProductListViewModelType } from "./ProductListViewModel"
import { PageState } from "../../PageState"
import { StripedTable } from "../../StripedTable"
import { Product, RepoMetadata } from "../../../models/Product"
import { ValidatingIdEntryControl } from "../../ValidatingIdEntryControl"
import { productName } from "../../../helpers/productName"
import { CurrentMarketPicker } from "../../CurrentMarketPicker"
import { Market } from "../../../models/MarketModels"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { Thumbnail } from "../../Thumbnail"
const Barcode = require("react-barcode")

interface BigCheckboxProps {
    checked?: boolean
    onClick?: () => void
}

function BigCheckbox(props: BigCheckboxProps) {
    return (
        <div style={{ width: "21px", height: "21px", border: "1px solid rgb(180,180,180)", backgroundColor: "rgb(245,245,245)", borderRadius: "4px" }} onClick={() => props.onClick?.()} >
            {props.checked === true && <FontAwesomeIcon style={{ width: "21px", height: "21px", paddingTop: "3px", paddingBottom: "5px" }} icon={faCheck} />}
        </div>
    )
}

interface ProductListProps extends RouteComponentProps<any> {
    role: Role
}

interface ProductListState {
    isLoaded: boolean
    showIdModal: boolean
    showSearchModal: boolean
    products: Product[]
    metadata: RepoMetadata[]
    isNewProductIdValid: boolean
    newProductId: string
    searchTerm?: string
    expandProduct?: string
    currentMarket?: Market
    selectedProducts?: string[]
}

class ProductList extends React.Component<ProductListProps, ProductListState> {
    private viewModel: ProductListViewModelType

    constructor(props: ProductListProps) {
        super(props)

        this.state = {
            isLoaded: false,
            showIdModal: false,
            showSearchModal: false,
            products: [],
            metadata: [],
            isNewProductIdValid: false,
            newProductId: "",
            searchTerm: this.searchTerm,
        }

        this.viewModel = new ProductListViewModel(props.role)
        this.bindViewModel()
    }

    bindViewModel() {
        this.viewModel.outputs.didLoadProducts = (products, startKey) => {
            this.startKey = startKey
            this.setState({
                products: products.map(repo => { return repo.product }),
                metadata: products.map(repo => { return repo.metadata }),
                isLoaded: true,
                selectedProducts: undefined
            })
        }

        this.viewModel.outputs.didPerformSearch = (products) => {
            this.setState({
                products: products,
                metadata: [],
                isLoaded: true,
                selectedProducts: undefined
            })
        }
    }

    shouldComponentUpdate(nextProps: ProductListProps, nextState: ProductListState) {
        if (nextProps.match.path !== this.props.match.path) {
            this.setState({
                isLoaded: false,
                showIdModal: false,
                showSearchModal: false,
                products: [],
                isNewProductIdValid: false,
                newProductId: "",
                searchTerm: this.searchTerm
            })

            this.viewModel = new ProductListViewModel(nextProps.role)
            this.bindViewModel()

            const searchTerm = this.searchTerm
            if (!searchTerm) {
                this.viewModel.inputs.loadInitialProducts(this.startKey)
            } else {
                this.viewModel.inputs.performSearch(searchTerm, this.state.currentMarket?.id)
            }
        }

        return true
    }

    async componentDidMount() {
        await this.viewModel.inputs.start()

        const searchTerm = this.searchTerm
        if (!searchTerm) {
            this.viewModel.inputs.loadInitialProducts(this.startKey)
        } else {
            this.viewModel.inputs.performSearch(searchTerm, this.state.currentMarket?.id)
        }
    }

    componentWillUnmount() {
        this.viewModel.teardown()
    }

    async deleteSelectedProducts() {
        if (this.state.selectedProducts === undefined || this.state.selectedProducts.length === 0) {
            return
        }
        if (window.confirm("Really delete the selected products?") === true) {
            for (const key of this.state.selectedProducts) {
                const success = await this.viewModel.inputs.removeProduct(key)
                if (success) {
                    const index = this.state.products.findIndex(p => { return p.id === key })
                    if (index >= 0) {
                        const newProds = _.cloneDeep(this.state.products)
                        const newMeta = _.cloneDeep(this.state.metadata)
                        newProds.splice(index, 1)
                        newMeta.splice(index, 1)
                        this.setState({ products: newProds, metadata: newMeta })
                    }
                }
            }
        }
        this.setState({ selectedProducts: undefined })
    }

    allSelected(): boolean {
        if ((this.state.selectedProducts ?? []).length !== this.state.products.length) {
            return false
        }
        const selected = new Set(this.state.selectedProducts ?? [])
        for (const p of this.state.products) {
            selected.delete(p.id)
        }

        return selected.size === 0
    }

    toggleSelectAll() {
        if (this.allSelected()) {
            this.setState({ selectedProducts: undefined })
        } else {
            this.setState({ selectedProducts: this.state.products.map(p => { return p.id }) })
        }
    }

    render() {
        return (
            <div>
                {/* <Panel>
                    <Panel.Heading>Products</Panel.Heading>
                    <Panel.Body> */}
                <Row>

                    <Col sm={7} className="text-right">
                        <InputGroup>
                            <FormControl
                                type="text"
                                name="search_term"
                                value={this.state.searchTerm || ""}
                                placeholder="Search products"
                                onChange={(event: any) => { this.handleSearchTermChange(event) }}
                                onKeyPress={(value) => { this.handleSearchKeyPress(value) }}
                            />
                            <InputGroup.Button>
                                <Button bsStyle="primary" onClick={() => { this.performSearch() }} >Search</Button>
                                {!this.viewModel.outputs.isShowingSearch ? null :
                                    <Button bsStyle="warning" onClick={() => { this.closeSearch() }}>Close</Button>
                                }
                            </InputGroup.Button>
                        </InputGroup>
                    </Col>
                    <Col sm={5}>
                        {
                            this.viewModel.outputs.isShowingSearch ? null : (
                                <span style={{ float: "right" }}>
                                    {this.viewModel.outputs.isReadOnly || <Button onClick={this.showIdModal}>Add new Product</Button>}
                                    <span style={{ marginLeft: "10px" }}>
                                        <CurrentMarketPicker
                                            currentMarket={this.state.currentMarket ?? null}
                                            autoselect={true}
                                            role={this.props.role}
                                            onChange={market => { this.setState({ currentMarket: market }) }}
                                        />
                                    </span>
                                </span>
                            )
                        }
                    </Col>
                </Row>
                <Row>
                    {
                        this.viewModel.outputs.isShowingSearch ? (
                            <Col sm={12}>
                                <Label bsStyle="info">Note</Label> Only the <b>first 25</b> matches will be shown.
                            </Col>
                        ) : null
                    }
                </Row>
                <PageState loading={!(this.state.isLoaded)} typeName="Products" dirty={(this.state.selectedProducts ?? []).length > 0} title={`${(this.state.selectedProducts ?? []).length} product(s) selected`} submit_title="Delete?" submit_action={async () => { await this.deleteSelectedProducts() }}>
                    {
                        this.viewModel.outputs.isShowingSearch ? <div><br /><br /></div> : (
                            <Pager>
                                <Pager.Item previous={true} onClick={async () => { await this.previousAction() }} disabled={this.viewModel.outputs.isPreviousDisabled}>&larr; Previous Page</Pager.Item>
                                <Pager.Item next={true} onClick={async () => { await this.nextAction() }} disabled={this.viewModel.outputs.isNextDisabled}>Next Page &rarr;</Pager.Item>
                            </Pager>
                        )
                    }
                    <StripedTable>
                        <thead>
                            <tr>
                                {this.viewModel.outputs.isReadOnly ||
                                    <th className="narrow"><BigCheckbox checked={this.allSelected()} onClick={() => this.toggleSelectAll()} /></th>
                                }
                                <th>Image</th>
                                <th>Name</th>
                                <th>Id</th>
                                <th>Barcode</th>
                                <th style={{ textAlign: "right" }}>Retail price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.products.map((product, productIndex) => {
                                const metadata = this.state.metadata.length > productIndex ? this.state.metadata[productIndex] : undefined
                                const presentInSelectedMarket = metadata === undefined || this.state.currentMarket === undefined || metadata?.markets?.[this.state.currentMarket?.id ?? ""] === true
                                const productRow = (
                                    <tr
                                        key={product.id}
                                        onClick={() => {
                                            if (this.viewModel.outputs.isReadOnly) { return }
                                            this.props.history.push(`${this.viewModel.outputs.productPath}${product.id}`)
                                        }}
                                        style={product.id === this.state.expandProduct ? { borderTop: "1px solid rgb(180,180,180)" } : {}}
                                    >
                                        {this.viewModel.outputs.isReadOnly ||
                                            <td onClick={e => { e.stopPropagation() }}><BigCheckbox onClick={() => { this.state.selectedProducts?.includes(product.id) === true ? this.setState({ selectedProducts: (this.state.selectedProducts ?? []).filter(id => { return id !== product.id }) }) : this.setState({ selectedProducts: (this.state.selectedProducts ?? []).concat([product.id]) }) }} checked={this.state.selectedProducts?.includes(product.id)} /></td>
                                        }
                                        <td className="narrow"><Thumbnail src={product.image_url} /></td>
                                        <td>{product.name?.localized(LanguageCode.da)}{(product.variants?.length ?? 0) > 0 && <Button onClick={(e) => { e.stopPropagation(); this.setState({ expandProduct: this.state.expandProduct !== product.id ? product.id : undefined }) }} bsStyle="link">{(product.variants?.length ?? 0)} variants</Button>} </td>
                                        <td>{product.id}</td>
                                        <td>{product.barcode && <Barcode fontSize={12} background="rgba(0,0,0,0)" height={30} value={product.barcode} />}</td>
                                        <td style={{ textAlign: "right" }}>{presentInSelectedMarket ? product.formattedPrice(this.state.currentMarket) : <Label bsStyle="info">Unavailable in {this.state.currentMarket?.name ?? "-"}</Label>}</td>
                                    </tr>
                                )
                                if (product.id === this.state.expandProduct && (product.variants?.length ?? 0) > 0) {
                                    return [productRow].concat(product.variants!.map((variant, idx) => {
                                        const isLast = idx === product.variants!.length - 1
                                        const css: React.CSSProperties = { color: "rgb(180,180,180)", backgroundColor: "rgb(250,250,250)" }
                                        if (isLast) {
                                            css.borderBottom = "1px solid rgb(180,180,180)"
                                        }
                                        return (
                                            <tr
                                                key={product.id + "_" + variant.id}
                                                style={css}
                                            >
                                                {this.viewModel.outputs.isReadOnly ||
                                                    <td />
                                                }
                                                <td className="narrow">{variant.image_url && <Thumbnail src={variant.image_url} />}</td>
                                                <td>{productName(product, variant, LanguageCode.da)}</td>
                                                <td>{variant.id}</td>
                                                <td>{variant.barcode && <Barcode fontSize={12} background="rgba(0,0,0,0)" height={30} value={variant.barcode} />}</td>
                                                <td style={{ textAlign: "right" }}>{product.formattedVariantPrice(variant, this.state.currentMarket)}</td>
                                            </tr>
                                        )
                                    }))
                                } else {
                                    return [productRow]
                                }
                            })}

                        </tbody>
                    </StripedTable>
                    {
                        this.viewModel.outputs.isShowingSearch ? null : (
                            <Pager>
                                <Pager.Item previous={true} onClick={async () => { await this.previousAction() }} disabled={this.viewModel.outputs.isPreviousDisabled}>&larr; Previous Page</Pager.Item>
                                <Pager.Item next={true} onClick={async () => { await this.nextAction() }} disabled={this.viewModel.outputs.isNextDisabled}>Next Page &rarr;</Pager.Item>
                            </Pager>
                        )
                    }
                </PageState>
                {
                    this.state.showIdModal && this.viewModel.outputs.productsRef ? (
                        <Modal.Dialog>
                            <Modal.Header>
                                <Modal.Title>
                                    Id for new product
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={12}><Label bsStyle="info">Info</Label> Leaving this empty will autogenerate a product id.</Col>
                                </FormGroup>
                                <br />
                                <ValidatingIdEntryControl
                                    collectionRef={this.viewModel.outputs.productsRef}
                                    isNew={true}
                                    typeName="product"
                                    identifierSource={null}
                                    existingIdentifier={this.state.newProductId}
                                    handleIdChange={(id, valid) => {
                                        this.setState({ newProductId: id, isNewProductIdValid: valid })
                                    }}
                                />
                                <br />
                                <br />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => { this.newProduct() }} disabled={!this.state.isNewProductIdValid}>Create</Button>
                                <Button onClick={() => { this.cancelNewProduct() }}>Cancel</Button>
                            </Modal.Footer>
                        </Modal.Dialog>
                    ) : null
                }</div >
        )
    }

    // Actions

    private handleSearchTermChange = (event: any) => {
        const value: string = event.target.value

        this.setState({ searchTerm: value })
    }

    private handleSearchKeyPress(value: any) {
        if (value.charCode === 13) {
            this.performSearch()
        }
    }

    private async nextAction() {
        const fromKey = this.startKey
        if (!fromKey) { return }

        this.viewModel.inputs.loadNextProducts()
    }

    private async previousAction() {
        const fromKey = this.startKey
        if (!fromKey) { return }

        this.viewModel.inputs.loadPreviousProducts(fromKey)
    }

    private newProduct() {
        if (this.state.newProductId) {
            const path = this.viewModel.outputs.productPath + `${this.state.newProductId}/new`
            this.props.history.push(path)
        } else {
            const productId = this.viewModel.outputs.generateNewKey()
            const path = this.viewModel.outputs.productPath + `${productId}/new`
            this.props.history.push(path)
        }
    }

    private cancelNewProduct() {
        this.setState({ newProductId: "", showIdModal: false })
    }

    private showIdModal = () => {
        if (this.viewModel.outputs.isReadOnly) { return }

        this.setState({ isNewProductIdValid: true, showIdModal: true })
    }

    private performSearch() {
        const searchTerm = this.state.searchTerm
        if (!searchTerm) {
            return
        }

        this.searchTerm = searchTerm

        this.setState({ isLoaded: false })

        this.viewModel.inputs.performSearch(searchTerm, this.state.currentMarket?.id)
    }

    private closeSearch() {
        this.setState({ searchTerm: undefined, isLoaded: false })
        this.searchTerm = undefined
        this.viewModel.inputs.loadInitialProducts(this.startKey)
    }

    // Helpers

    private set startKey(key: string | undefined) {
        const params = new URLSearchParams(this.props.location.search)

        if (key) {
            params.set("startKey", key)
        } else {
            params.delete("startKey")
        }

        this.props.history.push(`?${params.toString()}`)
    }

    private get startKey(): string | undefined {
        const params = new URLSearchParams(this.props.location.search)
        const key = params.get("startKey")

        return key === null ? undefined : key
    }

    private set searchTerm(searchTerm: string | undefined) {

        const params = new URLSearchParams(this.props.location.search)

        if (searchTerm) {
            const encodedSearchTerm = encodeURIComponent(searchTerm)
            params.set("searchTerm", encodedSearchTerm)
        } else {
            params.delete("searchTerm")
        }

        this.props.history.push(`?${params.toString()}`)
    }

    private get searchTerm(): string | undefined {
        const params = new URLSearchParams(this.props.location.search)
        const encodedSearchTerm = params.get("searchTerm")

        if (!encodedSearchTerm) {
            return undefined
        }

        const searchTerm = decodeURIComponent(encodedSearchTerm)

        return searchTerm
    }
}

export default withRouter(ProductList)
