import React from "react";
import { ControlLabel, FormControl, FormGroup, HelpBlock, Panel } from "react-bootstrap";
import * as _ from "lodash";
import { L10nString, LanguageCode } from "../../../helpers/L10n";
import { L10nFormControl } from "../../L10nFormControl";

interface SharedPropertiesProps {
    displayName?: L10nString;
    priority?: number;
    currentLanguage: () => LanguageCode | null;
    updateSharedProps: (displayName: L10nString | undefined, priority: number | undefined) => void;
    validation: boolean;
}
export function SharedPropertiesSelector(props: SharedPropertiesProps) {
    return <Panel bsStyle="primary">
        <Panel.Heading>
            <Panel.Title>General</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
            <FormGroup style={{ marginLeft: "0px", marginRight: "0px" }}
                validationState={_.isNil(props.displayName) ? "error" : null}
            >
                <ControlLabel>Name</ControlLabel>
                <L10nFormControl
                    l10n={props.displayName ?? null}
                    type="text"
                    language={props.currentLanguage()}
                    placeholder="Enter a name for the applied discounts"
                    style={{ resize: "vertical" }}
                    onLocalizationChanged={(l10n) => { props.updateSharedProps(l10n ?? undefined, props.priority); }} />
                <HelpBlock>This name will be shown in the basket when the discount is applied - as well as on the receipt</HelpBlock>
            </FormGroup>
            <FormGroup style={{ marginLeft: "0px", marginRight: "0px" }}
                validationState={_.isNil(props.priority) ? "error" : null}
            >
                <ControlLabel>Priority</ControlLabel>
                <FormControl
                    type="number"
                    name="priority"
                    min={0}
                    step={10}
                    value={props.priority ?? ""}
                    placeholder="Enter priority"
                    onChange={(e: any) => { props.updateSharedProps(props.displayName, e.target.value === "" ? undefined : Number(e.target.value)); }} />
                <HelpBlock>Discount rules are evaluated from highest to lowest priority. This means that a higher priority rule will take precedence over a lower priority rule.</HelpBlock>
            </FormGroup>
        </Panel.Body>
    </Panel>;
}
