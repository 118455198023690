import { ref } from "../config/constants"
import firebase from "firebase/compat"
import { Tag } from "../models/Product"
import * as _ from "lodash"

export class TagObserver {

    // Properties

    accountId: string
    tagsArray?: Tag[]
    tagsDict?: _.Dictionary<Tag>
    tagsChangedCallback: () => void = () => {}

    // Lifecycle

    constructor(accountId: string) {
        this.accountId = accountId
        this.tagsArray = undefined
        this.tagsDict = undefined
    }

    // Public methods
    
    start() {
        this.observeTags()
    }
    
    stop() {
        this.tagsRef().off()
    }

    // Private methods

    private tagsRef() {
        return ref().child(`v1/accounts/${this.accountId}/inventory/tags`)
    }
    
    private observeTags() {
        const tagsRef = this.tagsRef()
        if (!tagsRef) {
            return
        }
        tagsRef.on("value", (snapshot: firebase.database.DataSnapshot) => {
            const tagsDict = snapshot.val()
            if (!tagsDict) { 
                // Set tags state to empty array in case we've just deleted the last product
                this.tagsArray = []
                this.tagsDict = {}

                if (this.tagsChangedCallback) {
                    this.tagsChangedCallback()
                }
                return
            }
    
            const keys = Object.keys(tagsDict)
            const values: Tag[] = keys.map(v => {
                return new Tag(tagsDict[v])
            })
    
            const sorted = values.sort((a: Tag, b: Tag) => {
                return a.tag < b.tag ? -1 : 1
            })

            this.tagsArray = sorted
            this.tagsDict = _.reduce(values, (aggregated, value: Tag, key) => {
                aggregated[value.tag] = value
                return aggregated
            }, {})

            if (this.tagsChangedCallback) {
                this.tagsChangedCallback()
            }
        })
    }
}