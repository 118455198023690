import * as _ from "lodash"
import * as React from "react"

import { Button, DropdownButton, MenuItem, Panel } from "react-bootstrap"
import { ref } from "../../../config/constants"
import { PageState } from "../../PageState"
import { Role } from "../../../config/role"
import { StripedTable } from "../../StripedTable"

const availableMap: any = {
    "cash": "Cash payment",
    "card.verifone": "Verifone Credit Card Terminal",
    "card.netsbaxi": "Lane/3000 Credit Card Terminal (nets)",
    "card.payex": "PayEx Credit Card Terminal",
    "card.izettle": "iZettle Credit Card Terminal",
    "mobilepay.integration": "MobilePay (integration)",
    "mobilepay.external": "MobilePay (external, manual handling)",
    "giftcard.external": "Gift card (external, manual handling)",
    "giftcard.integration": "Gift card (integration required)",
    "card.external": "Credit card (external, manual handling)",
    "invoice.external": "Invoice (external, manual handling)",
    "invoice.erp": "Invoice (ERP integration required)",
    "customer_account.integration": "Customer Account (integration required)",
    "voucher.integration": "Voucher (integration required)"
}

interface PaymentTypesConfigurationProps {
    role: Role
}

interface PaymentTypesConfigurationState {
    available: string[]
    dirty: boolean
    loaded: boolean
    modules: _.Dictionary<string>
    publishing: boolean
    showSuccess: boolean
}

export default class PaymentTypesConfiguration extends React.Component<PaymentTypesConfigurationProps, PaymentTypesConfigurationState> {
    constructor(props: PaymentTypesConfigurationProps) {
        super(props)

        this.state = {
            available: [],
            dirty: false,
            loaded: false,
            modules: {},
            publishing: false,
            showSuccess: false,
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const modulesRef = ref().child(`v1/accounts/${account}/configuration/modules`)
        const moduleSnap = await modulesRef.once("value")
        if (moduleSnap && moduleSnap.exists() && moduleSnap.val().giftcard && moduleSnap.val().giftcard.enabled === true) {
            availableMap["giftcard.integration"] = "Giftcard payment (via integration)"
        } else {
            delete availableMap["giftcard.integration"]
        }
        if (moduleSnap && moduleSnap.exists() && moduleSnap.val().voucher && moduleSnap.val().voucher.enabled === true) {
            availableMap["voucher.integration"] = "Voucher payment (via integration)"
        } else {
            delete availableMap["voucher.integration"]
        }
        if (moduleSnap && moduleSnap.exists() && moduleSnap.val().mobile_pay && moduleSnap.val().mobile_pay.enabled === true) {
            availableMap["mobilepay.integration"] = "MobilePay (via integration)"
        } else {
            delete availableMap["mobilepay.integration"]
        }
        const availableRef = ref().child(`v1/accounts/${account}/configuration/payment_types/available`)
        const availableSnap = await availableRef.once("value")
        this.setState({ available: availableSnap.exists() ? availableSnap.val() : [], loaded: true })
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="payment types">
                <Panel key="upper_panel" bsStyle={this.state.showSuccess ? "success" : "default"}>
                    <Panel.Heading>
                        <Panel.Title>{this.state.showSuccess ? "Payment types published successsfully" : "Payment types"}</Panel.Title>
                    </Panel.Heading>

                    <Panel>
                        <Panel.Heading>
                            <Button bsStyle="primary" onClick={(event) => { this.addPaymentType() }}>Add new payment type</Button>
                        </Panel.Heading>
                    </Panel>

                    <Panel.Body>
                        <StripedTable>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.available.map((paymentType, paymentIndex) => {
                                        return (
                                            <tr key={paymentIndex} >
                                                <td>
                                                    <DropdownButton
                                                        title={availableMap[paymentType]}
                                                        key={paymentType}
                                                        id={`dropdown-basic-${paymentType}`}
                                                    >
                                                        {
                                                            Object.keys(availableMap).map((key, availableIndex) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={availableIndex}
                                                                        onClick={(event) => { this.handleChange(key, paymentIndex); event.stopPropagation() }}
                                                                    >
                                                                        {availableMap[key]}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </DropdownButton>
                                                </td>
                                                <td className="narrow">
                                                    <Button bsStyle="danger" onClick={(event) => { this.removePaymentType(paymentIndex); event.stopPropagation() }}>X</Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </StripedTable>
                    </Panel.Body>

                    <Panel.Footer>
                        <Button onClick={this.publish} disabled={!this.state.dirty}>Publish</Button>
                    </Panel.Footer>
                </Panel >
            </PageState>
        )
    }

    handleChange = (value: string, index: number) => {
        const newAvailable = this.state.available
        newAvailable[index] = value

        this.setState({ available: newAvailable, dirty: true })
    }

    removePaymentType = (index: number) => {
        const available = this.state.available
        delete available[index]

        const newAvailable = Object.values(available)
        this.setState({ available: newAvailable, dirty: true })
    }

    addPaymentType = () => {
        const newAvailable = this.state.available
        newAvailable.push(Object.keys(availableMap)[0])

        this.setState({ available: newAvailable, dirty: true })
    }

    publish = async () => {
        const account = this.props.role.account_id
        const availableRef = ref().child(`v1/accounts/${account}/configuration/payment_types/available`)

        this.setState({ publishing: true })
        await availableRef
            .set(this.state.available)
            .then(() => {
                this.setState({ showSuccess: true, publishing: false, dirty: false })
            })
    }
}
