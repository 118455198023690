import * as _ from "lodash"
import * as React from "react"

import { Button, Col, ControlLabel, FormGroup, Panel, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import { withRouter, RouteComponentProps } from "react-router"

interface GiftcardTaxationConfigurationProps extends RouteComponentProps<any> {
    role: Role
}
enum GiftcardTaxationMode {
    atSale = "at_giftcard_sale",
    atUse = "at_giftcard_use"
}

interface GiftcardTaxationConfigurationState {
    dirty: boolean
    loaded: boolean
    publishing: boolean
    selectedGiftcardTaxationMode: GiftcardTaxationMode
}

class GiftcardTaxationConfiguration extends React.Component<GiftcardTaxationConfigurationProps, GiftcardTaxationConfigurationState> {

    constructor(props: GiftcardTaxationConfigurationProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            selectedGiftcardTaxationMode: GiftcardTaxationMode.atSale
        }
    }

    // Helpers

    publish = async () => {
        this.setState({
            publishing: true
        })
        const account = this.props.role.account_id
        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/giftcard_taxation`)
        await configRef.set(this.state.selectedGiftcardTaxationMode)
        this.setState({
            dirty: false,
            publishing: false
        })
    }

    onChange = (modes: GiftcardTaxationMode[]) => {
        _.remove(modes, (val: GiftcardTaxationMode) => { return val === this.state.selectedGiftcardTaxationMode })

        if (modes.length === 0) {
            return
        }

        const mode = modes[0]

        this.setState({
            dirty: true,
            selectedGiftcardTaxationMode: mode
        })
    }

    // Component

    async componentDidMount() {
        const account = this.props.role.account_id

        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/giftcard_taxation`)
        const configSnap = await configRef.once("value")

        if (configSnap.exists()) {
            const value = configSnap.val()
            this.setState({
                loaded: true,
                selectedGiftcardTaxationMode: value
            })
        } else {
            this.setState({
                loaded: true
            })
        }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="Giftcard taxation configuration">
                <Panel key="gift_card_taxation" defaultExpanded={false}>
                    <Panel.Heading>
                        <Panel.Title>Giftcard taxation configuration</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <p>The <i>giftcard taxation</i> configuration describes when giftcards should be taxed.</p>
                        <p>By July, 2019 Denmark changed their taxation rules for issuing gift cards. The new rules require gift cards to be taxed when they are sold as opposed to when they are used.</p>
                        <p>Certain shops are allowed to run with the old taxation rules. Please refer to the tax rules in the markets you wish to operate and configure the taxation mode accordingly.</p>
                        <br />
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>Giftcard taxation</Col>
                            <Col sm={10}>
                                <ToggleButtonGroup
                                    type="checkbox"
                                    value={this.state.selectedGiftcardTaxationMode}
                                    onChange={this.onChange}
                                >
                                    <ToggleButton key="at_giftcard_sale" value={GiftcardTaxationMode.atSale}>At giftcard sale</ToggleButton>
                                    <ToggleButton key="at_giftcard_use" value={GiftcardTaxationMode.atUse}>At giftcard use</ToggleButton>
                                </ToggleButtonGroup>
                            </Col>
                        </FormGroup>
                    </Panel.Body>
                    <Panel.Footer>
                        <Button onClick={this.publish} disabled={!this.state.dirty}>Publish</Button>
                    </Panel.Footer>
                </Panel>
            </PageState>
        )
    }
}

export default withRouter(GiftcardTaxationConfiguration)
