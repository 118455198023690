import * as _ from "lodash"
import * as React from "react"

import { Button, Col, ControlLabel, FormGroup, Panel, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import { withRouter, RouteComponentProps } from "react-router"

interface OpenRegisterModeConfigurationProps extends RouteComponentProps<any> {
    role: Role
}

interface OpenRegisterModeConfigurationState {
    dirty: boolean
    loaded: boolean
    publishing: boolean
    selectedOpenRegisterMode: string
}

class OpenRegisterModeConfiguration extends React.Component<OpenRegisterModeConfigurationProps, OpenRegisterModeConfigurationState> {

    constructor(props: OpenRegisterModeConfigurationProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            selectedOpenRegisterMode: "manual"
        }
    }

    // Helpers

    publish = async () => {
        this.setState({
            publishing: true
        })
        const account = this.props.role.account_id
        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/open_register_mode`)
        await configRef.set(this.state.selectedOpenRegisterMode)
        this.setState({
            dirty: false,
            publishing: false
        })
    }

    onChange = (modes: any) => {
        const strings: string[] = modes
        _.remove(strings, (val: string) => { return val === this.state.selectedOpenRegisterMode })

        if (strings.length === 0) {
            return
        }

        const mode = _.head(strings) as string

        this.setState({
            dirty: true,
            selectedOpenRegisterMode: mode
        })
    }

    // Component

    async componentDidMount() {
        const account = this.props.role.account_id

        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/open_register_mode`)
        const configSnap = await configRef.once("value")

        if (configSnap.exists()) {
            const value = configSnap.val()
            this.setState({
                loaded: true,
                selectedOpenRegisterMode: value
            })
        } else {
            this.setState({
                loaded: true
            })
        }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="Open register mode configuration">
                <Panel key="open_register_mode" defaultExpanded={false}>
                    <Panel.Heading>
                        <Panel.Title>Open register mode configuration</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <p>The <i>open register mode</i> configuration describes whether the contents of the cash register should be counted manually - or if the 'opening float' from the previous closing of the register should be used automatically.</p>
                        <br />
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>Open register mode</Col>
                            <Col sm={10}>
                                <ToggleButtonGroup
                                    type="checkbox"
                                    value={this.state.selectedOpenRegisterMode}
                                    onChange={this.onChange}
                                >
                                    <ToggleButton key="manual" value="manual">Manual</ToggleButton>
                                    <ToggleButton key="automatic" value="automatic">Automatic</ToggleButton>
                                </ToggleButtonGroup>
                            </Col>
                        </FormGroup>
                    </Panel.Body>
                    <Panel.Footer>
                        <Button onClick={this.publish} disabled={!this.state.dirty}>Publish</Button>
                    </Panel.Footer>
                </Panel>
            </PageState>
        )
    }
}

export default withRouter(OpenRegisterModeConfiguration)