import * as _ from "lodash"
import * as React from "react"
import {
    Button,
    Col,
    Form,
    FormGroup,
    Panel
    } from "react-bootstrap"
import { ChannelSelector } from "../../ChannelSelector"
import {
    ContainerDepositRule,
    Metadata,
    ProductRule,
    ProductRuleType,
    typeToNameMap
    } from "./Models"
import { ContainerDepositRuleEdit } from "./ContainerProductRuleEdit"
import { CurrentMarketPicker } from "../../CurrentMarketPicker"
import { Globals } from "../../../helpers/globals"
import { LanguageCode } from "../../../helpers/L10n"
import { LanguagePicker } from "../../LanguagePicker"
import { Market } from "../../../models/MarketModels"
import { MarketSelector } from "../../MarketSelector"
import { PageState } from "../../PageState"
import { ProductGroupObserver } from "../../../helpers/productGroupObserver"
import { ProductObserver } from "../../../helpers/productObserver"
import { ref } from "../../../config/constants"
import {
    RouteComponentProps,
    withRouter
    } from "react-router"
import { TagObserver } from "../../../helpers/tagObserver"

interface ProductRuleEditProps extends RouteComponentProps<any> {
    role: any
}

interface ProductRuleEditState {
    currentLanguage?: LanguageCode
    currentMarket: Market | null
    dirty: boolean
    key?: string
    markets: Market[]
    metadata: Metadata
    productGroupsLoaded: boolean
    productsLoaded: boolean
    publishing: boolean
    rule: ProductRule
    ruleAndMetadataLoaded: boolean
    selectedMarkets: string[]
    tagsLoaded: boolean
    type: ProductRuleType
}

class ProductRuleEdit extends React.Component<ProductRuleEditProps, ProductRuleEditState> {

    // Properties

    productObserver: ProductObserver
    productGroupObserver: ProductGroupObserver
    tagObserver: TagObserver

    // Lifecycle

    constructor(props: ProductRuleEditProps) {
        super(props)

        this.productObserver = new ProductObserver(this.props.role.account_id)
        this.productObserver.productsChangedCallback = () => {
            this.setState({ productsLoaded: true })
        }
        this.tagObserver = new TagObserver(this.props.role.account_id)
        this.tagObserver.tagsChangedCallback = () => {
            this.setState({ tagsLoaded: true })
        }

        this.productGroupObserver = new ProductGroupObserver(this.props.role.account_id)
        this.productGroupObserver.productGroupsChangedCallback= () => {
            this.setState({ productGroupsLoaded: true })
        }

        const key = this.props.match.params.ruleKey !== "new" ? this.props.match.params.ruleKey : undefined

        this.state = {
            currentLanguage: undefined,
            currentMarket: null,
            dirty: false,
            key: key,
            markets: [],
            metadata: new Metadata(),
            productGroupsLoaded: false,
            productsLoaded: false,
            publishing: false,
            rule: new ProductRule(),
            ruleAndMetadataLoaded: false,
            selectedMarkets: [],
            tagsLoaded: false,
            type: ProductRuleType.CONTAINER_DEPOSIT
        }
    }

    // Component

    async componentDidMount() {
        this.productObserver.start()
        this.tagObserver.start()
        this.productGroupObserver.start()
        await this.load()
    }

    componentWillUnmount() {
        this.productObserver.stop()
        this.tagObserver.stop()
        this.productGroupObserver.stop()
    }

    renderContainerDeposit(rule: ContainerDepositRule): any {
        return (
        <ContainerDepositRuleEdit 
            currency={this.resolvedCurrency()}
            currentLanguage={this.state.currentLanguage}
            currentMarket={this.resolvedMarket()}
            markets={Object.keys(this.state.metadata.markets)}
            role={this.props.role} 
            rule={rule}
            productGroupsArray={this.productGroupObserver.productGroupsArray ? this.productGroupObserver.productGroupsArray: []}
            productGroupsDict={this.productGroupObserver.productGroupsDict ? this.productGroupObserver.productGroupsDict: {}}
            tagsArray={this.tagObserver.tagsArray ? this.tagObserver.tagsArray : []}
            tagsDict={this.tagObserver.tagsDict ? this.tagObserver.tagsDict : {}}
            updateRule={this.updateRule} 
        />
        )
    }

    renderProductRule(rule: ProductRule | undefined): JSX.Element | React.Component {
        if (_.isNil(rule)) {
            return <br />
        }
        if (!_.isNil(rule.containerDeposit)) {
            return this.renderContainerDeposit(rule.containerDeposit)
        }
        return <br />
    }

    render() {
        const loading = !this.state.productsLoaded || !this.state.ruleAndMetadataLoaded || !this.state.tagsLoaded || !this.state.productGroupsLoaded
        return (
            <PageState loading={loading} typeName="product rule data" publishing={this.state.publishing} dirty={this.state.dirty}>

                <Form onSubmit={e => e.preventDefault()} horizontal={true}>
                    <FormGroup>
                        <Col smOffset={6} sm={6} className="text-right">
                            <CurrentMarketPicker
                                role={this.props.role}
                                currentMarket={this.state.currentMarket}
                                resolveMarkets={() => { return Object.keys(this.state.metadata.markets) }}
                                onChange={market => { this.handleCurrentMarketChange(market) }}
                            />
                            <LanguagePicker
                                typeName="rule"
                                initialLanguage={null}
                                resolveLanguages={() => { return this.resolveLanguages() }}
                                onChange={(language) => { this.setLanguage(language) }}
                                onRemove={(language) => { this.removeLanguage(language) }}
                            />
                        </Col>
                    </FormGroup>
                </Form>

                <Panel>
                    <Panel.Heading>{!this.state.key ? `New rule - ${typeToNameMap[ProductRuleType.CONTAINER_DEPOSIT]}` : `Editing rule - ${typeToNameMap[ProductRuleType.CONTAINER_DEPOSIT]}`}</Panel.Heading>
                    <Panel.Body>
                        {this.renderProductRule(this.state.rule)}

                        <br />

                        <Form onSubmit={e => e.preventDefault()} horizontal={true}>

                            <ChannelSelector
                                selectedChannels={Object.keys(this.state.metadata.channels || {})}
                                onChange={this.handleChannelsChange}
                            />
                            <MarketSelector
                                typeName="rule"
                                selectedMarkets={Object.keys(this.state.metadata.markets || {})}
                                addMarket={this.addMarket}
                                removeMarket={this.removeMarket}
                                showDeleteWarning={false}
                            />
                        </Form>
                    </Panel.Body>
                    <Panel.Footer>
                        <Button onClick={this.publish} disabled={!this.state.dirty || !this.state.rule || !this.state.rule.valid()}>Publish</Button>
                    </Panel.Footer>
                </Panel>
            </PageState>
        )
    }

    // Methods

    async load() {
        const accountId = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${accountId}`)

        let ruleSnapshot: any
        if (this.state.key) {
            ruleSnapshot = await accountRef.child(`/inventory/product_rule_repo/${this.state.key}`).once("value")
        }

        const markets = await Globals.shared.getMarkets()
        const channels = await Globals.shared.getChannels()
        this.setState({markets: markets})

        const stateChange: any = { ruleAndMetadataLoaded: true, metadata: new Metadata() }
        if (markets.length === 1) {
            stateChange.metadata.markets = { [markets[0].id]: true }
        }
        if (channels.length === 1) {
            stateChange.metadata.channels = { [channels[0].id]: true }
        }

        if (ruleSnapshot && ruleSnapshot.exists()) {
            const repoRule = ruleSnapshot.val()
            const type = this.extractTypeFromRuleRepoObject(repoRule)
            const metadata = new Metadata()

            if (!_.isNil(repoRule.metadata) && !_.isNil(repoRule.metadata.markets)) {
                metadata.markets = repoRule.metadata.markets
            }
            if (!_.isNil(repoRule.metadata) && !_.isNil(repoRule.metadata.channels)) {
                metadata.channels = repoRule.metadata.channels
            }
            let rule: ProductRule | null = null
            switch (type) {
                case ProductRuleType.CONTAINER_DEPOSIT:
                    rule = ProductRule.fromJSON(repoRule)
                    break
            }

            if (markets.length === 1) {
                metadata.markets = { [markets[0].id]: true }
            }

            if (type && rule) {
                stateChange.metadata = metadata
                stateChange.rule = rule
                stateChange.type = this.extractTypeFromRuleRepoObject(repoRule)
            }
        } else {
            stateChange.rule = new ProductRule()
            const aref = ref()
                .child(`v1/accounts/${accountId}/inventory/product_rule_repo`)
                .push()
            if (this.state.type === ProductRuleType.CONTAINER_DEPOSIT) {
                const json = {
                    display_name: "",
                    id: aref.key,
                    name: ""
                }
                stateChange.rule.containerDeposit = ContainerDepositRule.fromJSON(json)
            }
        }

        const selectedMarkets: string[] = Object.keys(stateChange.metadata.markets)
        stateChange.selectedMarkets = selectedMarkets
        stateChange.currentMarket = this.updateCurrentMarket(selectedMarkets)
        this.setState(stateChange)
    }

    updateRule = (closure: ((rule: ContainerDepositRule) => void)) => {
        const rule = _.cloneDeep(this.state.rule.containerDeposit)
        if (_.isNil(rule)) {
            return
        }
        closure(rule)
        const productRule = _.cloneDeep(this.state.rule)
        productRule.containerDeposit = rule
        this.setState({ rule: productRule, dirty: true })
    }

    extractTypeFromRuleRepoObject(object: Object): ProductRuleType | null {
        if (object.hasOwnProperty(ProductRuleType.CONTAINER_DEPOSIT)) {
            return ProductRuleType.CONTAINER_DEPOSIT
        }
        return null
    }

    handleChannelsChange = (data: any) => {
        const channels: _.Dictionary<boolean> = {}

        for (const channel of data) {
            channels[channel] = true
        }

        const metadata = _.cloneDeep(this.state.metadata)
        metadata.channels = channels

        this.setState({ metadata: metadata, dirty: true })
    }

    addMarket = (market: string) => {
        const metadata = _.cloneDeep(this.state.metadata)
        const markets = metadata.markets || {}
        markets[market] = true
        metadata.markets = markets

        const selectedMarkets = Object.keys(metadata.markets)
        const currentMarket = this.updateCurrentMarket(selectedMarkets)
        this.setState({ metadata: metadata, currentMarket: currentMarket, selectedMarkets: selectedMarkets, dirty: true })
    }

    removeMarket = (market: string) => {
        const metadata = _.cloneDeep(this.state.metadata)
        const markets = metadata.markets || {}
        delete markets[market]
        metadata.markets = markets
        const selectedMarkets = Object.keys(metadata.markets)
        const currentMarket = this.updateCurrentMarket(selectedMarkets)
        this.setState({ metadata: metadata, currentMarket: currentMarket, selectedMarkets: selectedMarkets, dirty: true })
    }

    publish = async () => {
        const type = this.state.type
        const rule = this.state.rule
        if (!type || !rule) {
            return
        }

        rule.metadata = this.state.metadata

        const repoRule = rule.json()

        this.setState({ publishing: true })

        const accountId = this.props.role.account_id
        const key = this.state.rule.id
        if (key !== undefined) {
            await ref()
                .child(`v1/accounts/${accountId}/inventory/product_rule_repo/${key}`)
                .set(repoRule)
        }

        this.props.history.push("/product_rules")
    }

    resolveLanguages(): LanguageCode[] {
        if (!this.state || !this.state.rule) {
            return []
        }
        const localizations = new Set<LanguageCode>()
        if (this.state.rule.displayName) {
            this.state.rule.displayName.localizations().forEach(language => {
                localizations.add(language)
            })
        }
        return Array.from(localizations).sort() 
    }

    setLanguage(language: LanguageCode | null) {
        this.setState({ currentLanguage: language || undefined })

        if (_.isNil(language)) { return }
        if (_.isNil(this.state.rule)) { return }

        const languages = this.resolveLanguages()
        if (!languages.includes(language)) {
            const rule = _.cloneDeep(this.state.rule)
            if (!_.isNil(rule.displayName)) {
                rule.displayName.localizeTo(language)
                this.setState({ rule: rule })
            }
        }
    }

    removeLanguage(language: LanguageCode | null) {
        if (!language) { return }
        const rule = this.state.rule
        if (rule && rule.displayName) {
            rule.displayName.removeLocalization(language)
        }
        this.setState({ rule: rule, currentLanguage: undefined, dirty: true })
    }

    updateCurrentMarket(selectedMarkets: string[]): Market | null {
        let currentMarket = this.state.currentMarket
        let currentMarketKey = currentMarket ? currentMarket.id : null

        // Don't set an explicit market when only one (or none) is available
        if (selectedMarkets.length <= 1) {
            return null
        }

        if (currentMarketKey && !selectedMarkets.includes[currentMarketKey]) {
            currentMarketKey = null
        }

        if (currentMarketKey === null) {
            currentMarketKey = selectedMarkets[0]
        }
        currentMarket = this.state.markets.find(market => { return (market.id === currentMarketKey) }) || null
        return currentMarket
    }

    resolvedCurrency(): string {
        const market = this.resolvedMarket()
        return market?.currency ?? ""
    }

    resolvedMarket(): Market | null {
        if (this.state.currentMarket) {
            return this.state.currentMarket
        }
        if (this.state.markets.length === 1) {
            return this.state.markets[0]
        }
        const selectedMarketKeys = Object.keys(this.state.metadata.markets)
        if (selectedMarketKeys.length === 1) {
            return this.state.markets.find(market => { return market.id === selectedMarketKeys[0] }) || null
        }
        return null
    }

    handleCurrentMarketChange(market: Market | null) {
        this.setState({ currentMarket: market })
    }
}

export default withRouter(ProductRuleEdit)
