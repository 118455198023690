import * as React from "react"
import { FormGroup, Col, ControlLabel, ToggleButtonGroup, ToggleButton } from "react-bootstrap"
import { Channel } from "../models/MarketModels"
import { Globals } from "../helpers/globals"

interface ChannelSelectorProps {
    onChange: (value: any) => void
    selectedChannels: string[]
}

interface ChannelSelectorState {
    channels: Channel[]
}

export class ChannelSelector extends React.Component<ChannelSelectorProps, ChannelSelectorState> {
    constructor(props: ChannelSelectorProps) {
        super(props)

        this.state = {
            channels: []
        }
    }

    async componentDidMount() {
        const channels = await Globals.shared.getChannels()
        this.setState({ channels: channels })
    }

    render() {
        return (
            (this.state.channels.length > 1) ? (
                <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>Channels</Col>
                    <Col sm={10}>
                        <ToggleButtonGroup
                            type="checkbox"
                            value={this.props.selectedChannels}
                            onChange={this.props.onChange}
                        >
                            {
                                this.state.channels.map((channel) => {
                                    return <ToggleButton key={channel.id} value={channel.id}>{channel.name}</ToggleButton>
                                })
                            }
                        </ToggleButtonGroup>
                    </Col>

                </FormGroup>) : []
        )
    }
}
