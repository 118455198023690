import DropzoneComponent, { DropzoneComponentProps } from "react-dropzone-component"

import firebase from "firebase/compat"
import * as ReactDOM from "react-dom"

interface CallableDropzoneComponentProps extends DropzoneComponentProps {
    submitRequest?: (xhr: any, formdata: any, files: any) => void
    account: string
    shop?: string
}

// NOTE:
// Based on https://raw.githubusercontent.com/enyo/dropzone/master/dist/dropzone.js
// and https://github.com/felixrieseberg/React-Dropzone-Component/blob/master/src/react-dropzone.js
// I have tampered with Dropzone + Component in order to make it upload to firebase storage instead of simply post"ing.
// More fiddling may be necessary in the future.

// TODO: This is still basically javascript - 'any' is used to silence typescript-compiler
export class CallableDropzoneComponent extends DropzoneComponent<CallableDropzoneComponentProps> {
    componentDidMount() {
        const anyThis: any = this

        const options = anyThis.getDjsConfig()

        const Dropzone = require("dropzone")

        Dropzone.autoDiscover = false

        const dropzoneNode = anyThis.props.config.dropzoneSelector || ReactDOM.findDOMNode(anyThis)
        anyThis.dropzone = new Dropzone(dropzoneNode, options)
        anyThis.dropzone.uploadFiles = function (files: any) {
            const file = files[0]
            const fileReader = new FileReader()
            fileReader.onload = () => {
                const buffer = Buffer.from(fileReader.result as string)
                const doImport = firebase.functions().httpsCallable("Stock-stockCSVImporter")
                const args = {
                    account: anyThis.props.account,
                    csv: buffer.toString("base64")
                }
                if (anyThis.props.shop) {
                    args["shop"] = anyThis.props.shop
                }
                doImport(args).then((response) => {
                    this.emit("uploadprogress", file, 100, file.upload.bytesSent)
                    this._finished([file], "Import started", null)
                }).catch((error) => {
                    console.error(`Stock CSV import error: ${error}`)
                })
            }
            fileReader.readAsText(file)
        }

        anyThis.setupEvents()
    }
}