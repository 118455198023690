import * as React from "react"
import ImageDropControl from "../../ImageDropControl"
import {
    Alert,
    Button,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Panel
    } from "react-bootstrap"
import { EmailConfigurationType } from "../../../models/EmailConfigurationType"
import { EmailValidation } from "../../../helpers/validation"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import {
    RouteComponentProps,
    withRouter
    } from "react-router"
import { stripEmptyValues } from "../../../helpers/stripEmptyValues"

interface ReceiptConfigurationProps extends RouteComponentProps<any> {
    role: Role
}

interface ReceiptConfigurationState {
    logo_url: string | null
    email_from_address: string
    email_from_name: string
    email_subject: string
    footer: string
    header: string
    emailConfigType: EmailConfigurationType
    dirty: boolean
    loaded: boolean
    publishing: boolean
    sales_quote_email_subject: string
    sales_quote_admin_email?: string
    showSuccess: boolean
    valid: boolean
}

class ReceiptConfiguration extends React.Component<ReceiptConfigurationProps, ReceiptConfigurationState> {

    constructor(props: ReceiptConfigurationProps) {
        super(props)

        this.state = {
            logo_url: "",
            email_from_address: "",
            email_from_name: "",
            email_subject: "",
            sales_quote_email_subject: "",
            footer: "",
            header: "",
            emailConfigType: EmailConfigurationType.none,
            dirty: false,
            loaded: false,
            publishing: false,
            showSuccess: false,
            valid: true,
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const shop = this.props.match.params.shopKey

        const emailConfigTypeRef = ref().child(`v1/accounts/${account}/configuration/email/config_type`)
        const emailConfigTypeSnapshot = await emailConfigTypeRef.once("value")
        const emailConfigType = emailConfigTypeSnapshot.exists() ? emailConfigTypeSnapshot.val() : EmailConfigurationType.none

        const receiptRef = ref().child(`v1/accounts/${account}/shops/${shop}/configuration/receipt`)
        const receiptSnapshot = await receiptRef.once("value")

        if (!receiptSnapshot || !receiptSnapshot.exists()) {
            this.setState({
                emailConfigType: emailConfigType,
                loaded: true
            })
            return
        }

        const value = receiptSnapshot.val()

        this.setState({
            logo_url: value.logo_url || "",
            email_from_address: value.email_from_address || "",
            email_from_name: value.email_from_name || "",
            email_subject: value.email_subject || "",
            sales_quote_email_subject: value.sales_quote_email_subject || "",
            emailConfigType: emailConfigType,
            footer: value.footer || "",
            header: value.header || "",
            loaded: true
        })
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="Receipt configuration">
                <Panel key="upper_panel" bsStyle={this.state.showSuccess ? "success" : "default"}>
                    <Panel.Heading>
                        <Panel.Title>{this.state.showSuccess ? "Receipt configuration updated successfully" : "Receipt configuration"}</Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <Panel.Heading><h4>Printed</h4></Panel.Heading>
                        <Panel.Body>
                            <Form horizontal={true}>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>Receipt logo (Maximum resolution 512×512)</Col>
                                    <Col sm={10}>
                                        <ImageDropControl
                                            fileName="receipt_logo"
                                            filePath={`${this.props.role.account_id}/shops/${this.props.match.params.shopKey}/`}
                                            imageURL={this.state.logo_url ? this.state.logo_url : ""}
                                            dropTitle=""
                                            validateSize={(width: number, height: number) => {
                                                return width <= 512 && height <= 512
                                            }}
                                            deleteAction={() => { this.setReceiptLogo(null) }}
                                            uploadAction={(url) => { this.setReceiptLogo(url) }}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>Header</Col>
                                    <Col sm={10}>
                                        <FormControl
                                            type="text"
                                            name="header"
                                            value={this.state.header}
                                            placeholder="Header shown on the printed receipt"
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>Footer</Col>
                                    <Col sm={10}>
                                        <FormControl
                                            type="text"
                                            name="footer"
                                            value={this.state.footer}
                                            placeholder="Footer shown on the printed receipt"
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Panel.Body>

                        <Panel.Heading><h4>Email</h4></Panel.Heading>
                        <Panel.Body>
                            <Form horizontal={true}>
                                {
                                    this.state.emailConfigType === EmailConfigurationType.ownSMTP || this.state.emailConfigType === EmailConfigurationType.validatedDomain ? (
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2}>Sender address</Col>
                                            <Col sm={10}>
                                                <FormControl
                                                    type="text"
                                                    name="email_from_address"
                                                    value={this.state.email_from_address}
                                                    placeholder="Enter address that receipt should be sent from"
                                                    onChange={this.handleInputChange}
                                                />
                                            </Col>
                                        </FormGroup>
                                    ) : (
                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2} />
                                                <Col sm={10}>
                                                    <Alert bsStyle="warning">In order to send receipt emails originating from a domain other than ka-ching.dk, please go to the Email configuration found under advanced options.</Alert>
                                                </Col>
                                            </FormGroup>
                                        )
                                }
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>Sender name</Col>
                                    <Col sm={10}>
                                        <FormControl
                                            type="text"
                                            name="email_from_name"
                                            value={this.state.email_from_name}
                                            placeholder="Enter name seen as sender when sending email receipts"
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>Receipt email subject</Col>
                                    <Col sm={10}>
                                        <FormControl
                                            type="text"
                                            name="email_subject"
                                            value={this.state.email_subject}
                                            placeholder="Enter subject shown when sending email receipts"
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>Sales quote email subject</Col>
                                    <Col sm={10}>
                                        <FormControl
                                            type="text"
                                            name="sales_quote_email_subject"
                                            value={this.state.sales_quote_email_subject}
                                            placeholder="Enter subject shown when sending email sales quotes"
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>Sales quote admin email</Col>
                                    <Col sm={10}>
                                        <FormControl
                                            type="text"
                                            name="sales_quote_admin_email"
                                            value={this.state.sales_quote_admin_email || ""}
                                            placeholder="Optionally enter an email address that will receive a copy of every sales quote generated in POS"
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Panel.Body>
                    </Panel.Body>
                    <Panel.Footer>
                        <Button onClick={this.publish} disabled={!this.state.dirty}>Publish</Button>
                    </Panel.Footer>
                </Panel >

            </PageState>
        )
    }

    handleInputChange = (event: any) => {
        const target = event.target
        let value = target.type === "checkbox" ? target.checked : target.value
        const name = target.name

        if (target.type === "number") {
            value = Number(value)
        }

        let valid = this.state.valid
        if (target.name === "email_from_address") {
            valid = !value || EmailValidation.valid(value)
        }

        const newState: any = {
            [name]: value,
            dirty: true,
            valid: valid,
        }
        this.setState(newState)
    }

    receiptLogoValidate = (width: number, height: number) => {
        return width <= 1024 && height <= 1024
    }

    setReceiptLogo = (url: string | null) => {
        this.setState({ logo_url: url, dirty: true })
    }

    publish = async () => {
        const account = this.props.role.account_id
        const shop = this.props.match.params.shopKey
        const shopConfigPath = `v1/accounts/${account}/shops/${shop}/configuration`
        const receiptRef = ref().child(`${shopConfigPath}/receipt`)

        this.setState({ publishing: true })

        const receiptConfig = {
            logo_url: this.state.logo_url,
            email_from_address: this.state.email_from_address,
            email_from_name: this.state.email_from_name,
            email_subject: this.state.email_subject,
            sales_quote_email_subject: this.state.sales_quote_email_subject,
            footer: this.state.footer,
            header: this.state.header
        }

        stripEmptyValues(receiptConfig)

        await receiptRef.set(receiptConfig)

        const salesQuoteAdminEmail = this.state.sales_quote_admin_email === undefined || this.state.sales_quote_admin_email === "" ? null : this.state.sales_quote_admin_email
        await ref().child(`${shopConfigPath}/sales_quote_email_address`).set(salesQuoteAdminEmail)

        this.setState({ showSuccess: true, publishing: false, dirty: false })
    }
}

export default withRouter(ReceiptConfiguration)
