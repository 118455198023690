import "react-dates/initialize" // tslint:disable-line
import * as React from "react"
import { FormattedTime, FormattedRelative } from "react-intl"
import { ref } from "../../config/constants"
import { Panel, Pager, Button } from "react-bootstrap"
import { withRouter, RouteComponentProps } from "react-router"
import { DateRangePicker } from "react-dates"
import { PageState } from "../PageState"
import { StripedTable } from "../StripedTable"
import { Role } from "../../config/role"
import * as moment from "moment"
import * as _ from "lodash"
import ExportsJS from "exportsjs"

const FileDownload = require("js-file-download")

interface RegisterCloseStatementListProps extends RouteComponentProps<any> {
    role: Role
    shop: string
}

interface RegisterCloseStatementListState {
    statements: any[]
    lastStatementIdStack: string[]
    startDate: moment.Moment | null
    endDate: moment.Moment | null
    exports: any
    loaded: boolean
    focusedInput?: any
    firstPage?: boolean
    lastPage?: boolean
}

class RegisterCloseStatementList extends React.Component<RegisterCloseStatementListProps, RegisterCloseStatementListState> {
    constructor(props: RegisterCloseStatementListProps) {
        super(props)

        this.state = {
            statements: [],
            lastStatementIdStack: [],
            startDate: null,
            endDate: null,
            exports: {},
            loaded: false
        }
    }

    async componentDidMount() {
        await this.loadStatements(null)
        await this.loadExports()
    }

    loadExports = async () => {
        this.setState({ loaded: false })
        const account = this.props.role.account_id
        const exportsRef = ref().child(`v1/accounts/${account}/configuration/export_integrations/register_close_statements`).orderByKey().limitToLast(50)
        const snapshot = await exportsRef.once("value")
        if (!snapshot.exists()) {
            this.setState({ loaded: true })
            return
        }
        let exportsDict = snapshot.val()
        exportsDict = _.filter(exportsDict, (value, key: string, collection) => {
            if (value.hasOwnProperty("show_in_portal")) {
                return value.show_in_portal
            }
            return true
        })
        this.setState({ exports: exportsDict, loaded: true })
    }

    loadNext = async () => {
        const statementIdStack = this.state.lastStatementIdStack
        let lastStatementId = null
        if (statementIdStack.length > 0) {
            lastStatementId = statementIdStack[statementIdStack.length - 1]
        }
        await this.loadStatements(lastStatementId)
    }

    loadPrevious = async () => {
        const statementIdStack = this.state.lastStatementIdStack
        statementIdStack.pop()
        statementIdStack.pop()
        this.setState({ lastStatementIdStack: statementIdStack })
        let lastStatementId = null
        if (statementIdStack.length > 0) {
            lastStatementId = statementIdStack[statementIdStack.length - 1]
        }
        await this.loadStatements(lastStatementId)
    }

    statementsRef() {
        const account = this.props.role.account_id
        const shop = this.props.shop
        return ref().child(`v1/accounts/${account}/shops/${shop}/register_close_statements`)
    }

    loadStatements = async (fromKey: string | null) => {
        const limit = 50
        this.setState({ loaded: false, firstPage: fromKey === null })
        let statementsRef = this.statementsRef().orderByKey().limitToLast(limit)
        if (fromKey) {
            statementsRef = statementsRef.endAt(fromKey)
        }
        const snapshot = await statementsRef.once("value")
        const statementsDict = snapshot.val()

        if (!statementsDict) {
            this.setState({ loaded: true, lastPage: true })
            return
        }

        const keys = Object.keys(statementsDict)
        const values = keys.map(v => {
            const statement = statementsDict[v]
            statement.key = v
            return statement
        })
        const sorted = values.sort(function (a, b) {
            if (a.key < b.key) { return 1 }
            if (a.key > b.key) { return -1 }
            return 0
        })
        const statementIdStack = this.state.lastStatementIdStack
        if (sorted.length > 0) {
            const lastKey = sorted[sorted.length - 1].key
            let lastStatementId = null
            if (statementIdStack.length > 0) {
                lastStatementId = statementIdStack[statementIdStack.length - 1]
            }
            if (lastKey !== lastStatementId) {
                statementIdStack.push(lastKey)
            }
        }
        this.setState({ statements: sorted, lastStatementIdStack: statementIdStack, loaded: true, lastPage: values.length < limit })
    }

    performExport = async (exportKey: string) => {
        const exportMethod = this.state.exports[exportKey]

        if (this.state.startDate === null || !this.state.startDate.isValid()) {
            return
        }
        if (this.state.endDate === null || !this.state.endDate.isValid()) {
            return
        }
        const start = this.state.startDate.startOf("day").unix()
        const end = this.state.endDate.endOf("day").unix()
        const statementsRef = this.statementsRef().orderByChild("reconciliation_time").startAt(start).endAt(end)
        const snapshot = await statementsRef.once("value")
        if (!snapshot.exists()) {
            // TODO: Warn that no data is available?
            return
        }
        const statementsDict = snapshot.val()

        const statements = Object.values(statementsDict)
        const configuration = exportMethod.transformation.parameters
        let exportFunction: (input: any) => any
        let fileExtension: string = "json"
        let singleItemExport: boolean = false

        switch (exportMethod.transformation.type) {
            case "csv": {
                exportFunction = input => {
                    const csvExport = new ExportsJS.csv(configuration, input)
                    return csvExport.export()
                }
                fileExtension = "csv"

                break
            }
            case "simplejson": {
                exportFunction = input => {
                    const jsonExport = new ExportsJS.simpleJSON(configuration, input)
                    return jsonExport.export()
                }
                singleItemExport = true
                break
            }
            case "identity": {
                exportFunction = input => { return input }
                break
            }
            case "economic": {
                exportFunction = input => {
                    const jsonExport = new ExportsJS.economic(configuration, input)
                    return jsonExport.registerCloseStatementExport()
                }
                singleItemExport = true
                break
            }
            default: {
                return
            }
        }

        const filename = "statements." + fileExtension
        if (singleItemExport) {
            const output = []
            for (const statement of statements) {
                try {
                    // Expecting export functions to return objects
                    output.push(exportFunction(statement))
                } catch (error) {
                    if (error instanceof ExportsJS.SkipExport) {
                        console.log("Skipping export", error.message)
                    } else {
                        throw error
                    }
                }
            }
            FileDownload(JSON.stringify(output), filename)

        } else {
            let output = exportFunction(statementsDict)
            if (_.isObject(output)) {
                output = JSON.stringify(output)
            }
            FileDownload(output, filename)
        }
    }

    showStatement = (statementKey: string) => {
        const path = "register_close_statement/" + statementKey
        this.props.history.push(path)
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="statements">
                <Panel>
                    <Panel.Heading>Register Close Statements</Panel.Heading>

                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Date/Time</th>
                                <th>Register</th>
                                <th>Cashier</th>
                                <th>Sales</th>
                                <th>Returns</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.statements.map(listValue => {
                                const date = new Date(listValue.reconciliation_time * 1000)
                                return (
                                    <tr key={listValue.key} onClick={() => this.showStatement(listValue.key)} >
                                        <td>
                                            <FormattedTime
                                                value={date}
                                                day="numeric"
                                                month="long"
                                                year="numeric"
                                                hour="numeric"
                                                minute="numeric"
                                            />
                                            &nbsp;(<FormattedRelative value={date} />)
                                        </td>
                                        <td>{listValue.source.register_name}</td>
                                        <td>{listValue.source.cashier_name || listValue.source.cashier_display_name}</td>
                                        <td>{(listValue.register_summary && listValue.register_summary.sales.count) ? listValue.register_summary.sales.count : 0}</td>
                                        <td>{(listValue.register_summary && listValue.register_summary.returns.count) ? listValue.register_summary.returns.count : 0}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </StripedTable>
                </Panel>
                <Pager>
                    <Pager.Item previous={true} disabled={this.state.firstPage} onClick={this.loadPrevious}>&larr; Previous Page</Pager.Item>
                    <Pager.Item next={true} disabled={this.state.lastPage} onClick={this.loadNext}>Next Page &rarr;</Pager.Item>
                </Pager>

                {Object.keys(this.state.exports).length > 0 ? (
                    <Panel>
                        <Panel.Heading>Export</Panel.Heading>
                        <Panel.Body>
                            <div>
                                <DateRangePicker
                                    startDateId="startDate"
                                    endDateId="endDate"
                                    minimumNights={0}
                                    isOutsideRange={() => { return false }}
                                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                    onDatesChange={({ startDate, endDate }) => { this.setState({ startDate, endDate }) }} // PropTypes.func.isRequired,
                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={(focusedInput: any) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                />
                                <br />
                                <br />
                            </div>

                            <div>
                                {
                                    Object.keys(this.state.exports).map(key => {
                                        const exportMethod = this.state.exports[key]
                                        const enabled = this.state.startDate && this.state.startDate.isValid() && this.state.endDate && this.state.endDate.isValid()
                                        return <div key={key}><Button onClick={() => this.performExport(key)} disabled={!enabled}>{exportMethod.name}</Button><br /><br /></div>
                                    })
                                }
                            </div>
                        </Panel.Body>
                    </Panel>
                ) : <div />}
            </PageState>
        )
    }
}

export default withRouter(RegisterCloseStatementList)
