import * as _ from "lodash"
import firebase from "firebase/compat"

import { ref } from "../../../config/constants"
import { mapValuesToModelClass } from "../../../helpers/jsMapValue"
import { StockCountIndex } from "../../../models/StockCountModels"
import { StockCountListItem } from "./StockCountListModels"

const pageLimit = 25

export class StockCountListViewModel {

    // Props

    private accountId: string
    private currentStockCountId?: string
    private shopId: string
    private stockCountIdPagingStack: string[]
    private nextDisabled: boolean
    private page: number
    private previousDisabled: boolean

    // Outputs

    didCompleteInitialLoad?: (past: StockCountListItem[], current?: StockCountListItem) => void
    didLoadPastStockCounts?: (past: StockCountListItem[]) => void
    didCreateNewStockCount?: (current?: StockCountIndex, errorMessage?: string) => void

    // Constructor

    constructor(accountId: string, shopId: string) {
        this.accountId = accountId
        this.shopId = shopId
        this.stockCountIdPagingStack = []
        this.nextDisabled = true
        this.previousDisabled = true
        this.page = 0
    }

    // Pubic methods

    startInitialLoad() {
        this.page = 0
        this.stockCountIdPagingStack = []

        this.loadCurrentStockCount()
            .then((current) => {
                if (current) {
                    this.currentStockCountId = current.id
                }
                return Promise.all([current, this.loadPastStockCounts()])
            })
            .then(([current, past]) => {
                if (this.didCompleteInitialLoad) {
                    this.didCompleteInitialLoad(past, current)
                }
            })
            .catch((error) => { console.log(error) })
    }

    startLoadOfNextPastStockCounts() {
        this.loadPastStockCounts()
            .then((stockCounts) => {
                if (this.didLoadPastStockCounts) {
                    this.didLoadPastStockCounts(stockCounts)
                }
            })
            .catch((error) => { console.log(error) })
    }

    startLoadOfPreviousPastStockCounts() {
        this.loadPreviousPastStockCounts()
            .then((stockCounts) => {
                if (this.didLoadPastStockCounts) {
                    this.didLoadPastStockCounts(stockCounts)
                }
            })
            .catch((error) => { console.log(error) })
    }

    isNextDisabled(): boolean {
        return this.nextDisabled
    }

    isPreviousDisabled(): boolean {
        return this.previousDisabled
    }

    createNewStockCount(name: string) {
        const open = firebase.functions().httpsCallable("StockCount-client")
        const args = {
            account_id: this.accountId,
            shop_id: this.shopId,
            stock_location_id: this.shopId,
            action: "open",
            name: name
        }

        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory`

        const subscription = ref().child(`${path}/stock_counts/current_count`).on("value", snapshot => {
            if (snapshot === null) { return }
            if (!snapshot.exists()) { return }
            if (this.didCreateNewStockCount) {
                this.didCreateNewStockCount(snapshot.val(), undefined)
            }
            ref().child(`${path}/stock_counts/current_count`).off("value", subscription)
        })

        open(args)
            .then((value) => {
                const success = value.data.success
                const current_count_key = value.data.current_count_key
                if (!success || !current_count_key) {
                    throw new Error("Could not create new stock")
                }
                // if (this.didCreateNewStockCount) {
                //     this.didCreateNewStockCount(current_count_key, undefined)
                // }
            })
            .catch((error: Error) => {
                ref().child(`${path}/stock_counts/current_count`).off("value", subscription)
                if (this.didCreateNewStockCount) {
                    this.didCreateNewStockCount(undefined, error.message)
                }
            })
    }

    // Private methods

    private async loadPastStockCounts(): Promise<StockCountListItem[]> {
        const fromStockCountId = _.last(this.stockCountIdPagingStack)
        const stockCountListItems = await this.loadBatchOfPastStockCounts(fromStockCountId)
        return stockCountListItems
    }

    private async loadPreviousPastStockCounts(): Promise<StockCountListItem[]> {
        this.stockCountIdPagingStack.pop()
        this.stockCountIdPagingStack.pop()
        const stockCountListItems = await this.loadBatchOfPastStockCounts(_.last(this.stockCountIdPagingStack))
        return stockCountListItems
    }

    private async loadCurrentStockCount(): Promise<StockCountListItem | undefined> {
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory`

        const currentSnapshot = await ref().child(`${path}/stock_counts/current_count`).once("value")
        if (!currentSnapshot.exists()) {
            return undefined
        }

        const currentId: string = currentSnapshot.val()

        const snapshot = await ref().child(`${path}/stock_counts/count_index/${currentId}`).once("value")
        if (!snapshot.exists()) {
            return undefined
        }

        const stockCount = new StockCountIndex(snapshot.val())
        return new StockCountListItem(undefined, undefined, currentId, stockCount.name, stockCount.openedAt, stockCount.openedByEmail, undefined)
    }

    private async loadBatchOfPastStockCounts(fromStockCountId?: string): Promise<StockCountListItem[]> {
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock_counts/count_index`
        let refToFilteredPast = ref().child(path).orderByKey().limitToLast(pageLimit + 1)
        if (fromStockCountId) {
            refToFilteredPast = refToFilteredPast.endAt(fromStockCountId)
        }

        let stockCountListItems: StockCountListItem[] = []
        const snapshot = await refToFilteredPast.once("value")
        if (snapshot.exists()) {
            const stockCountsDict = mapValuesToModelClass(snapshot.val(), StockCountIndex)
            const stockCounts = Object.values(stockCountsDict || {})
            _.reverse(stockCounts)
            stockCountListItems = this.buildStockCountListItems(stockCounts)
        }

        // side effects - updating page state
        this.updateStockCountIdPagingStack(stockCountListItems, true)

        // filter out current
        if (this.currentStockCountId) {
            _.remove(stockCountListItems, (item: StockCountListItem) => {
                return item.id === this.currentStockCountId
            })
        }

        return _.take(stockCountListItems, pageLimit)
    }

    private buildStockCountListItems(stockCounts: StockCountIndex[]): StockCountListItem[] {
        return stockCounts.map((stockCount: StockCountIndex) => {
            return new StockCountListItem(stockCount.closedAt, stockCount.closedByEmail, stockCount.id, stockCount.name, stockCount.openedAt, stockCount.openedByEmail, stockCount.cancelled)
        })
    }

    private updateStockCountIdPagingStack(stockCountListItems: StockCountListItem[], isNext: boolean) {
        this.page += isNext ? 1 : -1
        this.nextDisabled = stockCountListItems.length < pageLimit + 1
        if (!this.nextDisabled) {
            const lastStockCount = _.head(_.takeRight(stockCountListItems, 2))
            if (lastStockCount) {
                this.stockCountIdPagingStack.push(lastStockCount.id)
            }
        }
        this.previousDisabled = this.page === 1
    }
}
