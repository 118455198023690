import * as React from "react"
import { Table } from "react-bootstrap"

export class StripedTable extends React.Component {
    render() {
        return (
            /* 'fill' contains workaround for weird React bootstrap warning in the console */
            <Table className="table-borderless" striped={true} bordered={false} condensed={false} hover={true} style={{ cursor: "pointer" }} fill={("true" as any) as boolean} >
                {this.props.children}
            </Table>
        )
    }
}