import _ from "lodash"
import * as React from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Form, FormGroup, Col, ControlLabel, Button } from "react-bootstrap"
import { Role } from "../../../config/role"
import { LanguageCode, L10nString } from "../../../helpers/L10n"
import { ProductObserver } from "../../../helpers/productObserver"
import { Product } from "../../../models/Product"
import { L10nFormControl } from "../../L10nFormControl"
import { ModalPicker, ModalPickerElement } from "../../ModalPicker"
import { StripedTable } from "../../StripedTable"
import { Thumbnail } from "../../Thumbnail"
import { ProductEntry, ProductsSection } from "./FrontPageSectionModels"

class ProductElement implements ModalPickerElement {
    id: string
    imageUrl?: string
    name: string

    constructor(product: Product) {
        this.id = product.id
        this.imageUrl = product.image_url
        this.name = product.localizedName(LanguageCode.da) || ""
    }
}

interface ProductsSectionEditProps {
    role: Role,
    section: ProductsSection
    currentLanguage: LanguageCode | null
    updateSection: (subSection: ProductsSection) => void
}

interface ProductsSectionEditState {
    section: ProductsSection
    addProduct: boolean
    productsLoaded: boolean
}

// these css styles are a feeble attempt to make the table rows not look too bad while being dragged....

const narrowStyle = {
    width: "10%",
    textAlign: "center" as "center"
}

const nameStyle = {
    width: "80%",
    textAlign: "left" as "left"
}

export class ProductsSectionEdit extends React.Component<ProductsSectionEditProps, ProductsSectionEditState> {

    // Properties

    productObserver: ProductObserver

    constructor(props: ProductsSectionEditProps) {
        super(props)

        this.state = {
            section: props.section,
            addProduct: false,
            productsLoaded: false
        }

        this.productObserver = new ProductObserver(this.props.role.account_id)
        this.productObserver.productsChangedCallback = () => {
            this.setState({ productsLoaded: true })
        }

    }

    // Component

    async componentDidMount() {
        this.productObserver.start()
    }

    componentWillUnmount() {
        this.productObserver.stop()
    }

    onLocalizationChanged = (name: string, l10n: L10nString | null) => {
        const section = _.cloneDeep(this.state.section)

        if (l10n === null || l10n.value === "") {
            delete section[name]
        } else {
            section[name] = l10n
        }
        this.props.updateSection(section)
        this.setState({ section: section })
    }

    // Signals to vm

    onRemove = (index: number) => {
        const name = this.productTitle(this.state.section.products[index])
        if (window.confirm(`Really remove the product "${name}"?`) === true) {
            const section = _.cloneDeep(this.state.section)
            section.products.splice(index, 1)
            this.props.updateSection(section)
            this.setState({ section: section })
        }
    }

    onDragEnd = (reorder: any) => {
        // dropped outside the list
        if (!reorder.destination || reorder.destination.index === reorder.source.index) {
            return
        }

        // no movement
        if (reorder.destination.index === reorder.source.index) {
            return
        }

        const section = _.cloneDeep(this.state.section)
        const [removed] = section.products.splice(reorder.source.index, 1)
        section.products.splice(reorder.destination.index, 0, removed)

        this.props.updateSection(section)
        this.setState({ section: section })
    }

    addProduct = (product: ProductEntry) => {
        const section = _.cloneDeep(this.state.section)
        section.products.push(product)

        this.props.updateSection(section)
        this.setState({ section: section, addProduct: false })
    }

    productTitle = (productEntry: ProductEntry): string => {
        if (_.isNil(this.productObserver.productsDict)) {
            return productEntry.id
        }
        const product = this.productObserver.productsDict[productEntry.id]
        if (_.isNil(product) || _.isNil(product.name)) {
            return productEntry.id
        }

        return product.name.localized(this.props.currentLanguage ?? LanguageCode.da) + ` (${product.id})`
    }

    productImageURL = (productEntry: ProductEntry): string | undefined => {
        if (_.isNil(this.productObserver.productsDict)) {
            return
        }
        const product = this.productObserver.productsDict[productEntry.id]
        if (_.isNil(product)) {
            return
        }

        return product.image_url ?? undefined
    }

    pickerSelectedElement = (element: ProductElement) => {
        this.addProduct(new ProductEntry(element.id))
    }

    elementsForPicker = (): ModalPickerElement[] => {
        const products: ProductElement[] = []
        for (const product of this.productObserver.productsArray || []) {
            products.push(new ProductElement(product))
        }
        return products
    }

    render() {
        const showPicker = () => { this.setState({ addProduct: true }) }
        const cancelPicker = () => { this.setState({ addProduct: false }) }
        return [
            (
                <Form key="form" horizontal={true} onSubmit={e => e.preventDefault()}>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>Name</Col>
                        <Col sm={10}>
                            <L10nFormControl
                                l10n={this.state.section.title || null}
                                language={this.props.currentLanguage || null}
                                onLocalizationChanged={l10n => {
                                    this.onLocalizationChanged("title", l10n)
                                }
                                }
                            />
                        </Col>
                    </FormGroup>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(droppableProvided) => (
                                <div ref={droppableProvided.innerRef}>
                                    <StripedTable>
                                        <thead>
                                            <tr>
                                                <th style={narrowStyle}>Order</th>
                                                <th style={narrowStyle}>Image</th>
                                                <th style={nameStyle}>Name</th>
                                                <th style={narrowStyle}>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.section.products.map((product, index) => (
                                                <Draggable key={`${product.uuid}`} draggableId={`${product.uuid}`} index={index}>
                                                    {(draggableProvided: any) => (
                                                        <tr
                                                            ref={draggableProvided.innerRef}
                                                            key={`${product.uuid}`}
                                                            {...draggableProvided.draggableProps}
                                                            {...draggableProvided.dragHandleProps}
                                                        >
                                                            <td style={narrowStyle}>{index + 1}</td>
                                                            <td style={narrowStyle}>
                                                                <Thumbnail src={this.productImageURL(product)}/>
                                                            </td>
                                                            <td style={nameStyle}>
                                                                {this.productTitle(product)}
                                                            </td>
                                                            <td style={narrowStyle}>
                                                                <Button
                                                                    bsStyle="danger"
                                                                    onClick={(event) => {
                                                                        event.stopPropagation()
                                                                        this.onRemove(index)
                                                                    }}
                                                                >
                                                                    X
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </tbody>
                                    </StripedTable>
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Button onClick={showPicker}>Add</Button>
                </Form>
            ),
            this.state.addProduct
                ? (
                    <ModalPicker
                        elements={this.elementsForPicker()}
                        onCancel={cancelPicker}
                        onSelect={(element) => {
                            const productElement: any = element
                            this.pickerSelectedElement(productElement)
                            cancelPicker()
                        }}
                        title="Add product"
                    />
                ) : null
        ]
    }
}