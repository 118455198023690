import * as React from "react"
import { FirebaseStorageDropzoneComponent } from "./FirebaseStorageDropzoneComponent"
import { isNil } from "lodash"

interface DocumentDropControlProps {
    filePath: string
    fileName: string
    maxFileSize?: number
    deleteAction: () => void
    uploadAction: (url: string, file?: any) => void
    dropTitle: string
    isPublic?: boolean
}

export default class DocumentDropControl extends React.Component<DocumentDropControlProps, {}> {
    dropzoneComponentConfig = () => {
        const filePath = this.props.filePath
        return {
            iconFiletypes: [".pdf"],
            showFiletypeIcon: true,
            postUrl: "dummy",
            postPath: filePath
        }
    }

    dropzoneConfig = () => {
        const fileName = this.props.fileName
        return {
            autoProcessQueue: true,
            maxFiles: 1,
            maxFilesize: this.props.maxFileSize ? this.props.maxFileSize : 10,
            acceptedFiles: "application/pdf",
            accept: (file: any, done: (result: any | null) => void) => {
                if (file.name.split(".").length < 1) {
                    alert("Invalid file name: missing extension")
                    done("Invalid file name")
                    return
                } else {
                    const nameForUpload = fileName + "." + file.name.split(".").pop()
                    file.nameForUpload = nameForUpload
                    done(null)
                }
            },
        }
    }

    dropzoneEventHandlers = () => {
        return {
            success: (file: any, message: any, e: any) => {
                this.props.uploadAction(e, file)
            },
        }
    }

    deleteAction() {
        if (isNil(this.props.deleteAction)) {
            return
        }

        this.props.deleteAction()
    }

    render() {
        const dropTitle = this.props.dropTitle

        return (
            <div style={{ width: "100%" }}>
                <strong>{dropTitle}</strong>
                <FirebaseStorageDropzoneComponent
                    config={this.dropzoneComponentConfig()}
                    eventHandlers={this.dropzoneEventHandlers()}
                    djsConfig={this.dropzoneConfig()}
                    isPublic={this.props.isPublic}
                />
            </div>
        )
    }
}
