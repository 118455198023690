import * as React from "react"
import {
    Button,
    Panel,
} from "react-bootstrap"
import {
    cloneDeep,
} from "lodash"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import { ToggleButton } from "../../ToggleButton"
import { ModuleDefinition } from "./ModulesConfiguration"

import { CustomerLookupModule } from "../Modules/CustomerLookupModule"
import { FiscalRulesModule } from "../Modules/FiscalRulesModule"
import { MobilePayModule } from "../Modules/MobilePayModule"
import { NewsletterModule } from "../Modules/NewsletterModule"
import { RecommendationModule } from "../Modules/RecommendationModule"
import { SecondScreenBasketModule } from "../Modules/SecondScreenBasketModule"
import { TASLandingPageModule } from "../Modules/TASLandingPageModule"
import { TASNotificationsModule } from "../Modules/TASNotificationsModule"
import { GiftcardModule } from "../Modules/GiftcardModule"
import { ProductLabelModule } from "../Modules/ProductLabelModule"
import { GiftReceiptsModule } from "../Modules/GiftReceiptModule"

interface ModuleConfigurationProps {
    role: Role
    moduleDefinition: ModuleDefinition,
    moduleData: any
}

export interface ModuleProps {
    role: Role
    moduleData: any
    updateConfiguration: (configure: (data: any) => void) => void
}

interface ModuleConfigurationState {
    loaded: boolean
    publishing: boolean
    dirty: boolean
    moduleData: any
}

// TODO: Bundle this with the configurable front page module somehow
const defaultConfigurableFrontPage = {
    sections: [
        {
            shortcuts: {
                title: { da: "Genveje", en: "Shortcuts" },
                shortcuts: [
                    { shortcut_item: "customer_lookup" },
                    { shortcut_item: "cashier_dashboard" },
                    { shortcut_item: "basket_discount" },
                    { shortcut_item: "open_cash_drawer" },
                ]
            }
        },
        {
            most_sold_products: {
                title: { da: "Mest solgte varer", en: "Most sold products" },
                number_of_products: 8,
                time_interval_days: 14
            }
        }
    ]
}

const defaultProductLabelConfiguration = {
    default: {
        id: "default",
        properties: ["name", "barcode", "retail_price", "sale_price", "attributes/alternative_price", "dimensions", "dimension_values", "id"]
    }
}

export class ModuleConfiguration extends React.Component<ModuleConfigurationProps, ModuleConfigurationState> {

    constructor(props: ModuleConfigurationProps) {
        super(props)
        this.state = {
            moduleData: cloneDeep(props.moduleData ?? {}),
            loaded: true,
            publishing: false,
            dirty: false
        }
    }

    toggleModuleEnabled = () => {
        this.updateConfiguration(data => {
            if (data["enabled"]) {
                data["enabled"] = false
            } else {
                data["enabled"] = true
            }
        })
    }

    publishModuleConfiguration = async () => {
        this.setState({ publishing: true })

        // update module configurations
        const account = this.props.role.account_id
        const moduleConfigRef = ref().child(`v1/accounts/${account}/configuration/modules/${this.props.moduleDefinition.id}`)
        // Usually we just write the whole configuration, but if the 'update_properties' is set, we only update those
        if (this.props.moduleDefinition.update_properties) {
            const update: any = {}
            for (const property of this.props.moduleDefinition.update_properties) {
                update[property] = this.state.moduleData[property]
            }
            await moduleConfigRef.update(update)
        } else {
            await moduleConfigRef.set(this.state.moduleData)
        }

        // TODO: Bundle this with the configurable front page module somehow
        if (this.props.moduleDefinition.id === "configurable_front_page" && this.isEnabled()) {
            const frontPageRef = ref().child(`v1/accounts/${account}/app_data/pos/configurable_front_page`)
            const snap = await frontPageRef.once("value")
            if (!snap.exists()) {
                await frontPageRef.set(defaultConfigurableFrontPage)
            }
        }

        // Whenever we save the 'orders' module - no matter if we are enabling or disabling - remove legacy order config (c&c and r&c)
        if (this.props.moduleDefinition.id === "orders") {
            const ccModuleConfigRef = ref().child(`v1/accounts/${account}/configuration/modules/click_and_collect`)
            const rcModuleConfigRef = ref().child(`v1/accounts/${account}/configuration/modules/reserve_and_collect`)
            await ccModuleConfigRef.remove()
            await rcModuleConfigRef.remove()
        }

        await this.setDefaultProductLabelConfiguration(account)

        this.setState({ publishing: false, dirty: false })
    }

    private async setDefaultProductLabelConfiguration(account: String) {
        if (!(this.props.moduleDefinition.id === "product_labels")) { return }

        if (this.isEnabled()) {
            const frontPageRef = ref().child(`v1/accounts/${account}/configuration/product_labels`)
            await frontPageRef.set(defaultProductLabelConfiguration)
        }

        else {
            const frontPageRef = ref().child(`v1/accounts/${account}/configuration/product_labels`)
            await frontPageRef.set(null)
        }
    }

    isEnabled(): boolean {
        const data = this.state.moduleData ?? {}
        return this.props.moduleDefinition.always_enabled ?? data["enabled"] ?? false
    }

    updateConfiguration = (update: (data: any) => void) => {
        const data = cloneDeep(this.state.moduleData ?? {})
        update(data)
        this.setState({ moduleData: data, dirty: true })
    }

    moduleSpecific() {
        switch (this.props.moduleDefinition.id) {
            case "second_screen_basket":
                return (<SecondScreenBasketModule role={this.props.role} moduleData={this.state.moduleData} updateConfiguration={this.updateConfiguration} />)
            case "tas_landing_page":
                return (<TASLandingPageModule role={this.props.role} moduleData={this.state.moduleData} updateConfiguration={this.updateConfiguration} />)
            case "customer_lookup":
                return (<CustomerLookupModule role={this.props.role} moduleData={this.state.moduleData} updateConfiguration={this.updateConfiguration} />)
            case "recommendations":
                return (<RecommendationModule role={this.props.role} moduleData={this.state.moduleData} updateConfiguration={this.updateConfiguration} />)
            case "newsletter":
                return (<NewsletterModule role={this.props.role} moduleData={this.state.moduleData} updateConfiguration={this.updateConfiguration} />)
            case "fiscal_rules":
                return (<FiscalRulesModule role={this.props.role} moduleData={this.state.moduleData} updateConfiguration={this.updateConfiguration} />)
            case "mobile_pay":
                return (<MobilePayModule role={this.props.role} moduleData={this.state.moduleData} updateConfiguration={this.updateConfiguration} />)
            case "tas_notifications":
                return (<TASNotificationsModule role={this.props.role} moduleData={this.state.moduleData} updateConfiguration={this.updateConfiguration} />)
            case "giftcard":
                return (<GiftcardModule type={"giftcard"} role={this.props.role} moduleData={this.state.moduleData} updateConfiguration={this.updateConfiguration} />)
            case "voucher":
                return (<GiftcardModule type={"voucher"} role={this.props.role} moduleData={this.state.moduleData} updateConfiguration={this.updateConfiguration} />)
            case "product_labels":
                return (<ProductLabelModule role={this.props.role} moduleData={this.state.moduleData} updateConfiguration={this.updateConfiguration} />)
            case "gift_receipts":
                return (<GiftReceiptsModule role={this.props.role} moduleData={this.state.moduleData} updateConfiguration={this.updateConfiguration} />)

            default:
                return null
        }
    }

    render() {
        const m = this.props.moduleDefinition
        const enabled = this.isEnabled()

        return (
            <Panel key={m.id} defaultExpanded={false}>
                <Panel.Heading>
                    <Panel.Title style={{ color: enabled ? "#000000" : "#999999" }} toggle={true}>{m.name}</Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        {this.props.moduleDefinition.always_enabled === true ? null : (
                            <div>
                                <ToggleButton isEnabled={enabled} performToggle={async () => { this.toggleModuleEnabled() }} />
                                <br /><br />
                            </div>
                        )
                        }
                        <div>{m.description}</div>
                        <br />
                        {this.moduleSpecific()}
                    </Panel.Body>
                    <Panel.Footer>
                        <Panel.Toggle>
                            <Button onClick={this.publishModuleConfiguration} disabled={!this.state.dirty}>Publish</Button>
                        </Panel.Toggle>
                    </Panel.Footer>
                </Panel.Collapse>
            </Panel>
        )
    }
}
