import { firebaseAuth } from "../config/constants"
import firebase from "firebase/compat"

export function auth(email: string, pw: string): Promise<firebase.auth.UserCredential> {
    return firebaseAuth().createUserWithEmailAndPassword(email, pw)
}

export async function token(): Promise<string | null> {
    const user = firebaseAuth().currentUser
    if (user) {
        return await user.getIdToken()
    }
    return null
}

export function userId(): string | null {
    const user = firebaseAuth().currentUser
    if (user) {
        return user.uid
    }
    return null
}

export function currentUser(): firebase.User | null {
    return firebaseAuth().currentUser || null
}

export function logout() {
    return firebaseAuth().signOut()
}

export function login(email: string, pw: string): Promise<firebase.auth.UserCredential> {
    return firebaseAuth().signInWithEmailAndPassword(email, pw)
}

export function resetPassword(email: string): Promise<void> {
    return firebaseAuth().sendPasswordResetEmail(email)
}

export function signInWithMicrosoft(): Promise<firebase.auth.UserCredential> {
    const provider = new firebaseAuth.OAuthProvider("microsoft.com")
    return firebaseAuth().signInWithPopup(provider)
}

export function signInWithApple(): Promise<firebase.auth.UserCredential> {
    const provider = new firebaseAuth.OAuthProvider("apple.com")
    return firebaseAuth().signInWithPopup(provider)
}
