//
//  MarketList.tsx
//  POSFirebaseHosting
//
//  Created by Morten Bek Ditlevsen on 04/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Button, Panel, Alert } from "react-bootstrap"
import { ref } from "../../config/constants"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { PageState } from "../PageState"
import { StripedTable } from "../StripedTable"
import { Market, Tax, TaxType } from "../../models/MarketModels"
import { Globals } from "../../helpers/globals"
import { Dictionary, zip } from "lodash"
import { Role } from "../../config/role"
import firebase from "firebase/compat"

interface MarketListProps extends RouteComponentProps<any> {
    role: Role
}

interface MarketListState {
    error: string | null
    loaded: boolean
    markets: Market[]
    publishing: boolean
    taxes: Tax[]
}

class MarketList extends React.Component<MarketListProps, MarketListState> {

    // Properties

    initialState: MarketListState = {
        error: null,
        loaded: false,
        markets: [],
        publishing: false,
        taxes: [],
    }

    // Component

    constructor(props: MarketListProps) {
        super(props)

        this.state = this.initialState
    }

    async componentDidMount() {
        await this.loadState()
        this.subscribe()
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    async loadState() {
        this.setState({ loaded: false })

        const promises: Promise<any>[] = [
            Globals.shared.getMarkets(),
            Globals.shared.getTaxes()
        ]
        const [markets, taxes] = await Promise.all(promises)

        this.setState({ markets: markets, taxes: taxes, loaded: true, error: null })
    }

    marketsSubscription: ((a: firebase.database.DataSnapshot, b?: string | undefined | null) => any) | undefined
    taxesSubscription: ((a: firebase.database.DataSnapshot, b?: string | undefined | null) => any) | undefined

    subscribe() {
        this.marketsSubscription = ref().child(`v1/accounts/${this.props.role.account_id}/markets`).on("value", snapshot => {
            if (!snapshot) {
                return
            }
            const markets: Market[] = []
            const marketsDict = snapshot.val() || {}
            for (const marketKey in marketsDict) {
                const value = marketsDict[marketKey]
                markets.push({
                    id: marketKey,
                    name: value.name,
                    currency: value.currency,
                    taxes: new Set(Object.keys(value.taxes || {}))
                })
            }
            this.setState({ markets: markets })
        })

        this.taxesSubscription = ref().child(`v1/accounts/${this.props.role.account_id}/taxes`).on("value", snapshot => {
            if (!snapshot) {
                return
            }
            let taxes: Tax[] = []
            const taxesDict = snapshot.val() || {}
            for (const taxKey in taxesDict) {
                const value = taxesDict[taxKey]
                taxes.push(new Tax({
                    id: taxKey,
                    rate: value.rate,
                    name: value.name,
                    type: (value.type === "sales_tax") ? TaxType.salesTax : TaxType.vat
                }))
            }

            taxes = taxes.sort((a: Tax, b: Tax) => {
                return a.name.localeCompare(b.name)
            })
            this.setState({ taxes: taxes })
        })
    }

    unsubscribe() {
        if (this.marketsSubscription) {
            ref().child(`v1/accounts/${this.props.role.account_id}/markets`).off("value", this.marketsSubscription)
            this.marketsSubscription = undefined
        }
        if (this.taxesSubscription) {
            ref().child(`v1/accounts/${this.props.role.account_id}/taxes`).off("value", this.taxesSubscription)
            this.taxesSubscription = undefined
        }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="markets" publishing={this.state.publishing}>
                <div>
                    <Panel>
                        <Panel.Heading>
                            Markets
                        </Panel.Heading>
                        <StripedTable>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.markets.map((market) => {
                                    return (
                                        <tr key={market.id} onClick={() => { this.editMarket(market.id) }} >
                                            <td>{market.name}</td>
                                            <td className="narrow">
                                                <ConfirmDeleteButton
                                                    message={`Are you sure you wish to delete the market '${market.name}'?`}
                                                    onDelete={async () => { await this.removeMarket(market.id) }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </StripedTable>
                        <Panel.Footer>
                            <Button onClick={() => { this.createMarket() }}>New market</Button>
                        </Panel.Footer>
                    </Panel>

                    {this.state.error ? (
                        <Alert bsStyle="danger">
                            {this.state.error}
                        </Alert>
                    ) : []}

                    <Panel>
                        <Panel.Heading>
                            Taxes
                        </Panel.Heading>
                        <StripedTable>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.taxes.map((tax) => {
                                    return (
                                        <tr key={tax.id} onClick={() => { this.editTax(tax.id) }} >
                                            <td>{tax.name}</td>
                                            <td className="narrow">
                                                <ConfirmDeleteButton
                                                    message={`Are you sure you wish to delete the tax definition '${tax.name}'?`}
                                                    onDelete={async () => { await this.removeTax(tax.id) }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </StripedTable>
                        <Panel.Footer>
                            <Button onClick={() => { this.createTax() }}>New tax definition</Button>
                        </Panel.Footer>
                    </Panel>
                </div>
            </PageState>
        )
    }

    // Methods

    // edit market /market/market_key
    editMarket(key: string) {
        this.props.history.push(`/market/${key}`)
    }

    //new market /market/new
    createMarket() {
        const path = "/market/new"

        this.props.history.push({
            pathname: path
        })
    }

    async removeMarket(key: string) {
        this.setState({ error: null })

        if (this.state.markets.length === 1) {
            this.setState({ error: "You must always have at least one market defined." })
            return
        }

        this.setState({ publishing: true })

        const accountId = this.props.role.account_id

        const shopsSnap = await ref()
            .child(`v1/accounts/${accountId}/shop_index`)
            .once("value")

        if (!shopsSnap || !shopsSnap.exists()) {
            console.log("No shops found. Internal error.")
            this.setState({ publishing: false })
            return
        }

        const shopKeys: string[] = []
        const shopNames: Dictionary<string> = {}
        const shopsDict = shopsSnap.val()
        for (const shopKey in shopsDict) {
            const shopMeta = shopsDict[shopKey]
            if (shopMeta.deactivated) {
                continue
            }
            shopKeys.push(shopKey)
            shopNames[shopKey] = shopMeta.name || "-"
        }

        const refs = shopKeys.map(shopKey => {
            return ref()
                .child(`v1/accounts/${accountId}/shops/${shopKey}/market`)
        })

        const promises = refs.map(aref => { return aref.once("value") })
        const marketsInUseSnaps = await Promise.all(promises)
        const marketsInUse: Dictionary<string[]> = {}
        for (const [snap, shopKey] of zip(marketsInUseSnaps, shopKeys)) {
            if (!shopKey) { continue }
            if (snap && snap.exists() && typeof snap.val() === "string") {
                const market = snap.val() as string
                const existing = (marketsInUse[market] || [])
                existing.push(shopKey)
                marketsInUse[market] = existing
            }
        }
        if (marketsInUse[key]) {
            const names = marketsInUse[key].map(shopKey => { return `'${shopNames[shopKey] || "-"}'` })
            this.setState({ error: `This market is in use by the following shops: ${names.join(", ")}, and must not be deleted.`, publishing: false })
            return
        }

        await ref()
            .child(`v1/accounts/${accountId}/markets/${key}`)
            .set(null)

        this.setState({ publishing: false })
    }

    // edit market /market/market_key
    editTax(key: string) {
        this.props.history.push(`/tax/${key}`)
    }

    //new market /market/new
    createTax() {
        const path = "/tax/new"

        this.props.history.push({
            pathname: path
        })
    }

    async removeTax(key: string) {
        this.setState({ publishing: true })

        const accountId = this.props.role.account_id

        await ref()
            .child(`v1/accounts/${accountId}/taxes/${key}`)
            .set(null)

        this.setState({ publishing: false })
    }

}

export default withRouter(MarketList)
