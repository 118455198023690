import * as React from "react"
import { ProductFoldersPanel } from "./Folders/ProductFoldersPanel"
import { ProductTabsPanel } from "./Tabs/ProductTabsPanel"
import { Role } from "../../../config/role"
import {
    RouteComponentProps,
    withRouter
    } from "react-router"

interface ProductOrganizationProps extends RouteComponentProps<any> {
    role: Role
}

export class ProductOrganization extends React.Component<ProductOrganizationProps> {
    
    constructor(props: ProductOrganizationProps) {
        super(props)
        this.state = {}
    }

    // Component

    render() {
        return (
            <div>
                <ProductTabsPanel role={this.props.role}/>
                <ProductFoldersPanel role={this.props.role}/>
            </div>
        )
    }
}

export default withRouter(ProductOrganization)