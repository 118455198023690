//
//  GroupList.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 12/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Button, Panel } from "react-bootstrap"
import { forEach } from "lodash"
import { ref } from "../../config/constants"
import { PageState } from "../PageState"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { StripedTable } from "../StripedTable"
import { Role } from "../../config/role"
import { ProductGroup } from "../../models/Product"
import { LanguageCode } from "../../helpers/L10n"

interface GroupListProps extends RouteComponentProps<any> {
    role: Role
}

interface GroupListState {
    groups: ProductGroup[]
    currentLanguage: LanguageCode | null
    loaded: boolean
}

class GroupList extends React.Component<GroupListProps, GroupListState> {

    // Properties

    initialState: GroupListState = {
        groups: [],
        currentLanguage: LanguageCode.en,
        loaded: false
    }

    // Component

    constructor(props: GroupListProps) {
        super(props)

        this.state = this.initialState
    }

    componentDidMount() {
        this.setState({ loaded: false })

        this.loadState()
    }

    componentWillUnmount() {
        this.groupsRef().off()
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="groups">
                <Panel>
                    <Panel.Heading>
                        <Button onClick={this.newGroup}>New group</Button>
                    </Panel.Heading>
                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.groups.map(group => {
                                return (
                                    <tr key={group.group} onClick={() => { this.editGroup(group.group) }}>
                                        <td>{group.name.localized(this.state.currentLanguage) || group.name.value}</td>
                                        <td className="narrow">
                                            <ConfirmDeleteButton
                                                message={`Really delete ${group.name.localized(this.state.currentLanguage)} and remove it from all the products it is associated with?`}
                                                onDelete={async () => { await this.removeGroup(group.group) }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </StripedTable>
                    <Panel.Footer>
                        <Button onClick={this.newGroup}>New group</Button>
                    </Panel.Footer>
                </Panel>
            </PageState>
        )
    }

    // State

    loadState() {
        this.groupsRef()
            .on("value", snapshot => {
                if (!snapshot || (snapshot && !snapshot.exists())) {
                    this.setState({ groups: [], loaded: true })
                    return
                }

                const groups: ProductGroup[] = []
                forEach(snapshot.val(), (value) => {
                    groups.push(new ProductGroup(value))
                })

                const sorted = groups.sort((lhs, rhs) => {
                    return lhs.name.localized(this.state.currentLanguage) < rhs.name.localized(this.state.currentLanguage) ? -1 : 1
                })

                this.setState({
                    groups: sorted,
                    loaded: true
                })
            })
    }

    // Group

    newGroup = () => {
        this.props.history.push("/group/new")
    }

    editGroup = (key: string) => {
        this.props.history.push(`/group/${key}`)
    }

    async removeGroup(key: string) {
        await this.groupsRef().child(key).set(null)
    }

    // Helpers

    groupsRef() {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/product_groups")
    }
}

export default withRouter(GroupList)
