import * as _ from "lodash"
import * as React from "react"
import {
    DropdownButton,
    MenuItem
} from "react-bootstrap"
import { ModuleProps } from "../Configuration/ModuleConfiguration"

interface ProductLabelModuleState {
    modes: _.Dictionary<string>
}

export class ProductLabelModule extends React.Component<ModuleProps, ProductLabelModuleState> {

    constructor(props: ModuleProps) {
        super(props)
        this.state = {
            modes: {
                product_label: "Product Label",
                shelf_label: "Shelf Label"
            }
        }
    }

    modeSelected(mode: string) {
        this.props.updateConfiguration(data => {
            data.enabled = this.props.moduleData.enabled
            data.mode = mode
        })
    }

    isEnabled(): boolean {
        return this.props.moduleData["enabled"] ?? false
    }

    render() {
        const modeKey = this.props.moduleData.mode
        return (
            <div>
                {
                    this.isEnabled()
                        ?
                        (
                            <div>
                                <DropdownButton
                                    bsStyle="default"
                                    title={_.isNil(modeKey) ? "Choose product label mode" : this.state.modes[modeKey]}
                                    id="dropdown-mode"
                                    onSelect={(mode: any) => {
                                        this.modeSelected(mode)
                                    }}
                                >
                                    {
                                        Object.keys(this.state.modes).sort().map((modeKey) => {
                                            const modeName = this.state.modes[modeKey]
                                            return <MenuItem key={modeKey} eventKey={modeKey}>{modeName}</MenuItem>
                                        })
                                    }
                                </DropdownButton>
                            </div>
                        )
                        :
                        null
                }
            </div>
        )
    }
}
