import * as _ from "lodash"
import * as React from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import {
    Alert,
    Button,
    Glyphicon,
    Modal,
    Panel
} from "react-bootstrap"
import firebase from "firebase/compat"
import { ExportIntegrationType } from "./ExportIntegrations"
import { LivePager } from "../../LivePager"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router"
import { StripedTable } from "../../../components/StripedTable"

interface ExportIntegrationQueueProps extends RouteComponentProps<any> {
    role: Role
}

// The 'queue' type is for serial queues
type QueueType = "success" | "failure" | "queue"
type MetadataKey = "success_count" | "failure_count" | "last_success" | "last_failure"

interface WarningRequest {
    queueType: QueueType
    deleteAll?: boolean
    retryAll?: boolean
    retryKey?: string
    retryElement?: any
    deleteKey?: string
}

interface ExportIntegrationQueueState {
    configuration?: any
    metadata?: any
    errorDescription?: string
    integrationKey: string
    integrationType: ExportIntegrationType
    showElement?: any
    warningRequest?: WarningRequest
    copied?: boolean
    successQueueRef?: firebase.database.Reference
    failureQueueRef?: firebase.database.Reference
    queueQueueRef?: firebase.database.Reference
    successElements?: any
    failureElements?: any
    queueElements?: any
    successFirstPage: boolean
    failureFirstPage: boolean
    queueFirstPage: boolean
}

class ExportIntegrationQueue extends React.Component<ExportIntegrationQueueProps, ExportIntegrationQueueState> {
    queueFetchLimit = 50
    async refreshSuccess?(): Promise<void>
    async refreshFailure?(): Promise<void>

    constructor(props: ExportIntegrationQueueProps) {
        super(props)

        const key: string = props.match.params.integrationKey
        const type: ExportIntegrationType = props.match.params.integrationType

        this.state = {
            integrationKey: key,
            integrationType: type,
            configuration: undefined,
            errorDescription: undefined,
            successFirstPage: false,
            failureFirstPage: false,
            queueFirstPage: false
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const integrationConfigurationRef = accountRef.child("configuration/export_integrations").child(this.state.integrationType).child(this.state.integrationKey)
        const integrationConfiguration = (await integrationConfigurationRef.once("value")).val()
        this.setState({ configuration: integrationConfiguration })

        const triggerType = (integrationConfiguration.trigger || {}).type
        if (triggerType) {
            const queueRef = accountRef.child(`export_integrations/${this.state.integrationType}/${triggerType}/${this.state.integrationKey}`)
            const metadataRef = queueRef.child("metadata")

            metadataRef.on("value", snap => {
                this.setState({ metadata: snap.val() })
            })
            this.setState({ successQueueRef: queueRef.child("success"), failureQueueRef: queueRef.child("failure"), queueQueueRef: queueRef.child("queue") })
        }
    }

    componentWillUnmount() {
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const triggerType = (this.state.configuration.trigger || {}).type
        if (triggerType) {
            const queueRef = accountRef.child(`export_integrations/${this.state.integrationType}/${triggerType}/${this.state.integrationKey}`)
            const metadataRef = queueRef.child("metadata")
            metadataRef.off()
            queueRef.child("failure").off()
            queueRef.child("success").off()
            queueRef.child("queue").off()
        }
    }

    renderConfiguration() {
        const config = this.state.configuration
        if (_.isNil(config)) { return }
        return (
            <h1>{config.name}</h1>
        )
    }

    nameForMetadataKey(key: MetadataKey): string {
        switch (key) {

            case "success_count":
                return "Successfully exported item count"
            case "failure_count":
                return "Failed export item count"
            case "last_success":
                return "Last successful export"
            case "last_failure":
                return "Last failed export"

            default:
                return "-"
        }
    }

    formattedValueForMetadata(item: string[]): string {
        const key = item[0]
        const data = item[2]
        switch (key) {
            case "success_count":
            case "failure_count":
                return data
            case "last_success":
            case "last_failure":
                const date = new Date(Number(data))
                return date.toLocaleString()

            default:
                return "-"
        }
    }

    renderQueueMetadata() {
        const metadata = _.cloneDeep(this.state.metadata)
        if (metadata === undefined) { return }
        if (metadata === null) {
            return <h2>No data has yet been exported for this integration.</h2>
        }

        const successBatchCount = Object.keys(this.state.successElements || {}).length
        let successCount = 0
        for (const key of Object.keys(this.state.successElements || {})) {
            const entry = this.state.successElements[key]
            successCount += entry.batch_count || 1
        }
        let successCountString = `${successCount}`
        if (successBatchCount >= this.queueFetchLimit || successCount >= this.queueFetchLimit || !this.state.successFirstPage) {
            successCountString = `${this.queueFetchLimit}+`
        }
        const failureBatchCount = Object.keys(this.state.failureElements || {}).length
        let failureCount = 0
        for (const key of Object.keys(this.state.failureElements || {})) {
            const entry = this.state.failureElements[key]
            failureCount += entry.batch_count || 1
        }

        let failureCountString = `${failureCount}`
        if (failureBatchCount >= this.queueFetchLimit || failureCount >= this.queueFetchLimit || !this.state.failureFirstPage) {
            failureCountString = `${this.queueFetchLimit}+`
        }
        const keys: MetadataKey[] = ["success_count", "failure_count", "last_success", "last_failure"]
        const tableData: string[][] = []
        for (const key of keys) {
            let value = ""
            switch (key) {
                case "success_count": {
                    value = successCountString
                    break
                }
                case "failure_count": {
                    value = failureCountString
                    break
                }
                default: {
                    if (metadata[key] === undefined) {
                        continue
                    }
                    value = `${metadata[key]}`
                }
            }
            tableData.push([key, this.nameForMetadataKey(key), value])
        }
        if (tableData.length === 0) {
            return
        }
        return (
            <Panel>
                <Panel.Heading>
                    <Panel.Title>Queue metadata</Panel.Title>
                </Panel.Heading>

                <Panel.Body>
                    <StripedTable>
                        <thead>
                            <tr>
                                {tableData.map(array => {
                                    return (
                                        <th key={array[0]}>{array[1]}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {tableData.map(array => {
                                    return (
                                        <td key={array[0]}>{this.formattedValueForMetadata(array)}</td>
                                    )
                                })}
                            </tr>
                        </tbody>
                    </StripedTable>
                </Panel.Body>
            </Panel>
        )
    }

    renderTitle(queueType: QueueType) {
        let title: string = ""
        const retryRequest: WarningRequest = { queueType: queueType, retryAll: true }
        const deleteRequest: WarningRequest = { queueType: queueType, deleteAll: true }
        const disabled = Object.keys(this.getElements(queueType) || {}).length === 0
        switch (queueType) {
            case "success":
                title = "Successful exports"
                break
            case "failure":
                title = "Failed exports"
                break
            case "queue":
                title = "Currently exporting items"
                break
        }
        return (
            <span>
                {title}
                {queueType !== "queue" && <div style={{ float: "right", marginTop: "-5px" }}>
                    <Button
                        bsStyle="warning"
                        disabled={disabled}
                        onClick={(event) => {
                            event.stopPropagation()
                            this.setState({ warningRequest: retryRequest })
                        }}
                    >
                        Retry all
                    </Button>
                    &nbsp;
                    <Button
                        bsStyle="danger"
                        disabled={disabled}
                        onClick={(event) => {
                            event.stopPropagation()
                            this.setState({ warningRequest: deleteRequest })
                        }}
                    >
                        Delete all
                    </Button>
                </div>}
            </span>
        )
    }

    renderHeader(queueType: QueueType) {
        return (
            <tr>
                {queueType !== "queue" && <th>Date</th>}
                <th>Contents</th>
                {queueType === "failure" && <th>Error</th>}
                {queueType !== "queue" && <th>Retry</th>}
                {queueType !== "queue" && <th>Delete</th>}
            </tr>
        )
    }

    renderPagerElement(queueType: QueueType, key: string, element: any) {
        const data = queueType === "queue" ? element : element.element
        return [(
            <tr key={key}>
                {queueType !== "queue" && <td>{new Date(element.timestamp).toLocaleString()}</td>}
                <td
                    style={{ wordBreak: "break-all" }}
                    onClick={() => {
                        this.setState({ showElement: { element: data, timestamp: element.timestamp } })
                    }}
                >{JSON.stringify(data, null, 2).substr(0, 100)}...
                </td>
                {queueType === "failure" && <td style={{ wordBreak: "break-all" }}>{element.error}</td>}
                {queueType !== "queue" && <td><Button bsStyle="warning" onClick={() => { this.setState({ warningRequest: { queueType: queueType, retryElement: element, retryKey: key } }) }}>Retry</Button></td>}
                {queueType !== "queue" && <td><Button bsStyle="danger" onClick={() => { this.setState({ warningRequest: { queueType: queueType, deleteKey: key } }) }}>Delete</Button></td>}
            </tr>
        )]
    }

    queueRef(queueType: QueueType) {
        switch (queueType) {
            case "success":
                return this.state.successQueueRef
            case "failure":
                return this.state.failureQueueRef
            case "queue":
                return this.state.queueQueueRef
        }
    }

    renderQueue(queueType: QueueType) {
        const queueRef = this.queueRef(queueType)
        if (queueRef === undefined || ref === null) { return }
        const defaultExpanded = queueType === "failure"
        return (
            <LivePager
                queueFetchLimit={this.queueFetchLimit}
                defaultExpanded={defaultExpanded}
                queueRef={queueRef}
                renderTitle={() => this.renderTitle(queueType)}
                renderHeader={() => this.renderHeader(queueType)}
                renderElement={(key: string, element: any) => this.renderPagerElement(queueType, key, element)}
                didUpdateElements={(elements, firstPage) => {
                    switch (queueType) {
                        case "success":
                            this.setState({ successElements: elements, successFirstPage: firstPage })
                            break
                        case "failure":
                            this.setState({ failureElements: elements, failureFirstPage: firstPage })
                            break
                        case "queue":
                            this.setState({ queueElements: elements, queueFirstPage: firstPage })
                            break
                    }
                }}
                setRefreshFunction={func => {
                    switch (queueType) {
                        case "success":
                            this.refreshSuccess = func
                            break
                        case "failure":
                            this.refreshFailure = func
                            break
                        case "queue":
                            // For now, there's no need for refreshing this queue
                            break
                    }
                }}
            />
        )
    }

    renderFullElement() {
        const element = this.state.showElement
        if (_.isNil(element)) { return }
        return (
            <Modal show={true} onHide={() => { this.setState({ showElement: undefined }) }} >
                <Modal.Header closeButton={true}>
                    {_.isNil(element.timestamp) ? <Modal.Title>Full item</Modal.Title> :
                        <Modal.Title>Export from {new Date(element.timestamp).toLocaleString()}</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    <CopyToClipboard
                        text={JSON.stringify(element.element, null, 2)}
                        onCopy={() => { this.setState({ copied: true }); setTimeout(() => { this.setState({ copied: false }) }, 5000) }}
                    >
                        <Button>
                            <Glyphicon glyph="copy" />
                        </Button>
                    </CopyToClipboard>
                    <br /><br />
                    {this.state.copied ? <Alert bsStyle="success"> JSON copied to clipboard.</Alert> : null}
                    <pre>{JSON.stringify(element.element, null, 2)}</pre>
                </Modal.Body>
            </Modal>
        )
    }

    async refresh(queueType: QueueType) {
        switch (queueType) {
            case "success":
                if (this.refreshSuccess) {
                    await this.refreshSuccess()
                }
                break
            case "failure":
                if (this.refreshFailure) {
                    await this.refreshFailure()
                }
                break
            case "queue":
                // For now, we don't need to refresh the queue since there's no retry or delete on these elements
                break
        }
    }

    async retryElement(queueType: QueueType, key: string, element: any) {
        if (element === undefined) { return }
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const triggerType = (this.state.configuration.trigger || {}).type
        if (triggerType) {
            const queueRef = accountRef.child(`export_integrations/${this.state.integrationType}/${triggerType}/${this.state.integrationKey}`)
            const updates: any = {}
            updates[`${queueType}/${key}`] = null
            updates[`queue/${key}`] = element.element

            // Serial queues need to be triggered in order to start
            if (triggerType === "serial") {
                updates["trigger"] = true
            }
            await queueRef.update(updates)
        }
        await this.refresh(queueType)
    }

    async deleteElement(queueType: QueueType, key: string) {
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const triggerType = (this.state.configuration.trigger || {}).type
        if (triggerType) {
            const queueRef = accountRef.child(`export_integrations/${this.state.integrationType}/${triggerType}/${this.state.integrationKey}`)
            await queueRef.child(queueType).child(key).remove()
        }
        await this.refresh(queueType)
    }

    getElements(queueType: QueueType) {
        switch (queueType) {
            case "failure":
                return this.state.failureElements
            case "success":
                return this.state.successElements
        }
    }

    async retryAllElements(queueType: QueueType) {
        const elements = this.getElements(queueType)
        if (elements === undefined) { return }
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const triggerType = (this.state.configuration.trigger || {}).type
        if (triggerType) {
            const queueRef = accountRef.child(`export_integrations/${this.state.integrationType}/${triggerType}/${this.state.integrationKey}`)
            const updates: any = {}
            for (const key in elements) {
                const element = elements[key]
                updates[`${queueType}/${key}`] = null
                updates[`queue/${key}`] = element.element
            }

            // Serial queues need to be triggered in order to start
            if (triggerType === "serial") {
                updates["trigger"] = true
            }

            await queueRef.update(updates)
        }
        await this.refresh(queueType)
    }

    async deleteAllElements(queueType: QueueType) {
        const elements = this.getElements(queueType)
        if (elements === undefined) { return }
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const triggerType = (this.state.configuration.trigger || {}).type
        if (triggerType) {
            const queueRef = accountRef.child(`export_integrations/${this.state.integrationType}/${triggerType}/${this.state.integrationKey}`)
            const updates: any = {}
            for (const key in elements) {
                updates[`${queueType}/${key}`] = null
            }
            await queueRef.update(updates)
        }
        await this.refresh(queueType)
    }

    renderWarningElement() {
        const warning = this.state.warningRequest
        if (warning === undefined) { return }

        let action: () => Promise<void>
        let title: string
        let description: string
        let buttonTitle: string
        if (warning.retryKey !== undefined) {
            action = async () => { await this.retryElement(warning.queueType, warning.retryKey!, warning.retryElement) }
            title = "Retry export of element"
            description = "Are you certain that you wish to retry the export?"
            buttonTitle = "Retry export"
        } else if (warning.deleteKey !== undefined) {
            action = async () => { await this.deleteElement(warning.queueType, warning.deleteKey!) }
            title = `Delete element from ${warning.queueType} list`
            description = "Are you certain that you wish to delete the element?"
            buttonTitle = "Delete element"
        } else if (warning.retryAll !== undefined) {
            action = async () => { await this.retryAllElements(warning.queueType) }
            title = "Retry exports"
            description = "Are you certain that you wish to retry the export of all visible elements?"
            buttonTitle = "Retry export"
        } else if (warning.deleteAll !== undefined) {
            action = async () => { await this.deleteAllElements(warning.queueType) }
            title = "Delete failures"
            description = "Are you certain that you wish to delete all visible elements?"
            buttonTitle = "Delete elements"
        } else {
            return
        }

        return (
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>{description}</Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => { this.setState({ warningRequest: undefined }) }}>Cancel</Button>
                    <Button bsStyle="danger" onClick={async () => { this.setState({ warningRequest: undefined }); await action() }}>{buttonTitle}</Button>
                </Modal.Footer>
            </Modal.Dialog>
        )
    }

    render() {
        return (
            <div>
                {this.state.errorDescription ? <Alert bsStyle="warning">{this.state.errorDescription}</Alert> : null}
                {this.renderConfiguration()}
                {this.renderQueueMetadata()}
                {this.renderQueue("success")}
                {this.renderQueue("failure")}
                {this.state.configuration?.trigger?.type === "serial" && this.renderQueue("queue")}
                {this.renderFullElement()}
                {this.renderWarningElement()}
            </div>
        )
    }
}

export default withRouter(ExportIntegrationQueue)
