import * as React from "react"
import { firestore, ref } from "../../config/constants"
import { Col, ControlLabel, Button, Form, FormControl, FormGroup, Panel } from "react-bootstrap"
import { withRouter, RouteComponentProps } from "react-router"
import { PageState } from "../PageState"
import { Role } from "../../config/role"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"

interface StockLocationEditProps extends RouteComponentProps<any> {
    role: Role
}

interface StockLocationEditState {
    market: string
    available_markets: string[]
    loaded: boolean
    publishing: boolean
    dirty: boolean
    name: string
    identifier: string
    identifierInvalid: boolean
    isNew: boolean
}

class StockLocationEdit extends React.Component<StockLocationEditProps, StockLocationEditState> {
    constructor(props: StockLocationEditProps) {
        super(props)
        this.state = {
            name: "",
            identifier: "",
            market: "",
            available_markets: [],
            loaded: false,
            publishing: false,
            dirty: false,
            identifierInvalid: false,
            isNew: true
        }
    }

    stockLocationKey() {
        return this.props.match.params.stockLocationKey
    }

    isNewLocation() {
        return this.stockLocationKey() === "new"
    }

    async componentDidMount() {
        const key = this.stockLocationKey()
        const account = this.props.role.account_id

        this.setState({ loaded: false, isNew: this.isNewLocation(), identifier: key === "new" ? "" : key })

        const promises = []
        const accountMarketsRef = ref().child(`v1/accounts/${account}/markets`)
        promises.push(accountMarketsRef.once("value"))
        if (!this.isNewLocation()) {
            const marketRef = ref().child(`v1/accounts/${account}/stock_locations/${key}/market`)
            promises.push(marketRef.once("value"))
            const locationRef = ref().child(`v1/accounts/${account}/stock_location_index/${key}`)
            promises.push(locationRef.once("value"))
        }

        const [accountMarketsSnap, locationMarketSnap, locationIndexSnap] = await Promise.all(promises)
        const accountMarketsDict = accountMarketsSnap.val()

        this.setState({ loaded: true })

        if (this.isNewLocation()) {
            this.setState({
                available_markets: accountMarketsDict,
                market: Object.keys(accountMarketsDict)[0]
            })
        } else {
            const newState = {}

            if (accountMarketsDict) {
                newState["available_markets"] = accountMarketsDict
            }

            if (locationMarketSnap.exists()) {
                const market = locationMarketSnap.val()
                newState["market"] = market
            }
            if (locationIndexSnap.exists()) {
                const name = locationIndexSnap.val()?.["name"] ?? ""
                newState["name"] = name
            }

            this.setState(newState)
        }
    }

    handleInputChange = (event: any) => {
        const target = event.target
        let value = target.type === "checkbox" ? target.checked : target.value
        const name = target.name

        if (target.type === "number") {
            value = Number(value)
        }

        const newState: any = {
            [name]: value,
            dirty: true
        }

        this.setState(newState)
    }

    handleUpdateResponse = (error: any) => {
        this.setState({ publishing: false, dirty: false })
        if (error) {
            alert("An error occurred while saving changes.")
        }
    }

    validate() {
        if (!this.state.name || !this.state.market || this.state.identifierInvalid || !this.state.identifier) {
            return false
        }

        return true
    }

    create = async () => {
        if (!this.validate()) {
            alert("A new stock location must have a name, an identifier and a market.")
            return
        }
        const accountKey = this.props.role.account_id
        const locationKey = this.state.identifier

        const newIndex = { name: this.state.name, type: "ecom" }

        const updates = {}
        updates[`v1/accounts/${accountKey}/stock_locations/${locationKey}/market`] = this.state.market
        updates[`v1/accounts/${accountKey}/stock_location_index/${locationKey}`] = newIndex
        await firestore.collection(`accounts/${accountKey}/stock_locations`).doc(locationKey).set(newIndex)

        this.setState({ publishing: true })

        await ref().update(updates, this.handleUpdateResponse)

        const path = `/stock_location/${locationKey}/edit`
        this.props.history.push(path)
    }

    publish = async () => {
        if (!this.validate()) {
            alert("A stock location must have a name, an identifier and a market.")
            return
        }

        const accountKey = this.props.role.account_id
        const locationKey = this.stockLocationKey()

        const newIndex = { name: this.state.name, type: "ecom" }

        const updates = {}
        updates[`v1/accounts/${accountKey}/stock_locations/${locationKey}/market`] = this.state.market
        updates[`v1/accounts/${accountKey}/stock_location_index/${locationKey}`] = newIndex

        await firestore.collection(`accounts/${accountKey}/stock_locations`).doc(locationKey).set(newIndex)

        this.setState({ publishing: true })

        try {
            await ref().update(updates)
            this.handleUpdateResponse(null)
        } catch (error) {
            this.handleUpdateResponse(error)
        }
    }

    handleIdChange(identifier: string, valid: boolean) {
        if (identifier !== this.state.identifier) {
            this.setState({ dirty: true, identifierInvalid: !valid, identifier: identifier })
        }
    }

    stockLocationsRef() {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("stock_location_index")
    }

    render() {
        const publishDisabled = !this.state.dirty
        return (
            <PageState loading={!this.state.loaded} publishing={this.state.publishing} dirty={this.state.dirty} typeName="stock location">
                <Panel key="beefdead">
                    <Panel.Heading>
                        {this.isNewLocation() ?
                            <Button onClick={this.create} disabled={publishDisabled}>Create</Button>
                            :
                            <Button onClick={this.publish} disabled={publishDisabled}>Publish</Button>
                        }
                    </Panel.Heading>
                </Panel>
                <Panel>
                    <Panel.Heading>General</Panel.Heading>
                    <Panel.Body>
                        <Form horizontal={true}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Name</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        placeholder="Enter location name"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <ValidatingIdEntryControl
                                collectionRef={this.stockLocationsRef()}
                                isNew={this.state.isNew}
                                typeName="stock location"
                                identifierSource={this.state.name}
                                existingIdentifier={this.state.identifier}
                                handleIdChange={(id, valid) => { this.handleIdChange(id, valid) }}
                                invalidIdentifiers={["new"]}
                                showExistingIdentifier={true}
                            />                            
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Market</Col>
                                <Col sm={10}>
                                    <FormControl
                                        componentClass="select"
                                        name="market"
                                        placeholder="Select market"
                                        value={this.state.market}
                                        onChange={this.handleInputChange}
                                    >
                                        {
                                            Object.keys(this.state.available_markets).map((key, index) => {
                                                return <option key={index} value={key}>{this.state.available_markets[key].name}</option>
                                            })
                                        }
                                    </FormControl>
                                </Col>
                            </FormGroup>
                        </Form>
                    </Panel.Body>
                </Panel>

                <Panel key="deadbeef">
                    <Panel.Heading>
                        {this.isNewLocation() ?
                            <Button onClick={this.create} disabled={publishDisabled}>Create</Button>
                            :
                            <Button onClick={this.publish} disabled={publishDisabled}>Publish</Button>
                        }
                    </Panel.Heading>
                </Panel>
            </PageState>
        )
    }
}

export default withRouter(StockLocationEdit)
