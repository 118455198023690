import * as _ from "lodash"
import * as React from "react"

import { Button, Glyphicon, Panel } from "react-bootstrap"
import { shopifyAPIVersion, ShopifyConfiguration } from "./Models"
import { ToggleButton } from "../../../ToggleButton"

const apiVersion = "2021-04"

interface CustomerLookupPanelProps {
    accountId: string
    configuration: ShopifyConfiguration
    enabled: boolean
    signupEnabled: boolean
    requestHeaders: () => any
    openShopifyPrivateApps: () => void
    updateDatabaseUpdates: (updates: _.Dictionary<any>) => void
    updateEnabled: (enabled: boolean) => void
    updateSignupEnabled: (enabled: boolean) => void
}

export class CustomerLookupPanel extends React.Component<CustomerLookupPanelProps> {

    // Helpers

    customerLookupHelpText = (
        <div key="customer_lookup_help_text">
            Follow these steps to configure customer lookup from Ka-ching to Shopify
            <ol>
                <li>
                    Click the "Open Shopify Configuration" button above to open Shopify settings. Click the Ka-ching private app in that page.
                </li>
                <li>
                    Make sure that Ka-ching has access to read the customers in Shopify. The customer lookup integration requires 'Read access' to 'Customer details and customer groups'.
                </li>
                <li>
                    Remember to save any changes in Shopify.
                </li>
                <li>
                    Click below to enable the integration in Ka-ching.
                </li>
                <li>
                    Remember to activate the customer lookup feature in POS in the "Customer Lookup" module.
                </li>
            </ol>
        </div>
    )

    toggleEnabled = () => {
        const enabled = !this.props.enabled
        this.props.updateEnabled(enabled)

        // update customer lookup config based on enabled status
        if (enabled) {
            const config = {
                id: "shopify",
                name: "Shopify Customer Lookup",
                priority: 1,
                request: {
                    headers: this.props.requestHeaders(),
                    parameters: {
                        query: "{search_term}"
                    },
                    url: `https://${this.props.configuration.shopifyShopName}.myshopify.com/admin/api/${shopifyAPIVersion}/customers/search.json`
                },
                transformation: {
                    parameters: {
                        template: "[   {{#each customers}}     {{#if @index}}     ,     {{/if}}     {     \"identifier\": \"{{id}}\"     {{#with email}}       , \"email\": \"{{.}}\"     {{/with}} {{#with accepts_marketing}}       , \"accepts_marketing\": {{.}}     {{/with}}           {{#if phone}}       , \"phone\": \"{{phone}}\"     {{else}}     {{#if default_address.phone}}     , \"phone\": \"{{default_address.phone}}\"     {{/if}}     {{/if}}     {{#if first_name}}       {{#if last_name}}       , \"name\": \"{{first_name}} {{last_name}}\"       {{else}}       , \"name\": \"{{first_name}}\"       {{/if}}     {{else}}       {{#if last_name}}         , \"name\": \"{{last_name}}\"       {{/if}}     {{/if}}   {{#with default_address}}  {{#if address1}}       {{#if address2}}         , \"street\": \"{{address1}} {{address2}}\"       {{else}}         , \"street\": \"{{address1}}\"       {{/if}}     {{/if}}     {{#with city}}         , \"city\": \"{{.}}\"     {{/with}}     {{#with zip}}         , \"postal_code\": \"{{.}}\"     {{/with}}     {{#with country_code}}         , \"country_code\": \"{{.}}\"     {{/with}}     {{#with country}}         , \"country\": \"{{.}}\"     {{/with}}  {{/with}}   }   {{/each}} ]"
                    },
                    type: "handlebars"
                }
            }

            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/customer_lookup_integrations/shopify`] = config
            this.props.updateDatabaseUpdates(dbUpdates)
        } else {
            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/customer_lookup_integrations/shopify`] = null
            this.props.updateDatabaseUpdates(dbUpdates)
        }
    }

    customerSignupHelpText = (
        <div key="customer_signup_help_text">
            Follow these steps to configure customer signup from Ka-ching to Shopify
            <ol>
                <li>
                    Click the "Open Shopify Configuration" button above to open Shopify settings. Click the Ka-ching private app in that page.
                </li>
                <li>
                    Make sure that Ka-ching has access to read and write the customers in Shopify. The customer lookup integration requires 'Read and write access' to 'Customers'.
                </li>
                <li>
                    Remember to save any changes in Shopify.
                </li>
                <li>
                    Click below to enable the integration in Ka-ching.
                </li>
                <li>
                    Remember to activate the customer signup feature in POS in the "Customer Lookup" module.
                </li>
            </ol>
        </div>
    )

    toggleSignupEnabled = () => {
        const enabled = !this.props.signupEnabled
        this.props.updateSignupEnabled(enabled)

        // update customer lookup config based on enabled status
        if (enabled) {
            const headers: any = {}
            if (!_.isNil(this.props.configuration.shopifyAccessToken)) {
                headers["X-Shopify-Access-Token"] = this.props.configuration.shopifyAccessToken
            } else {
                const base64 = Buffer.from(`${this.props.configuration.shopifyAPIKey}:${this.props.configuration.shopifyPassword}`).toString("base64")
                const basicAuthValue = `Basic ${base64}`
                headers["Authorization"] = basicAuthValue
            }
            const config = {
                id: "shopify",
                name: "Shopify Customer Signup",
                priority: 1,
                request: {
                    headers: headers,
                    body: {
                        "customer": {
                            "accepts_marketing": "{accepts_marketing}",
                            "addresses": [{
                                "address1": "{street}",
                                "city": "{city}",
                                "country": "{country}",
                                "country_code": "{country_code}",
                                "name": "{name}",
                                "phone": "{phone}",
                                "zip": "{postal_code}"
                            }],
                            "email": "{email}",
                            "first_name": "{first_name}",
                            "last_name": "{last_name}",
                            "name": "{name}",
                            "phone": "{phone}",
                            "tags": "ka-ching"
                        }
                    },
                    method: "POST",
                    url: `https://${this.props.configuration.shopifyShopName}.myshopify.com/admin/api/${apiVersion}/customers.json`
                },
                transformation: {
                    parameters: {
                        template: "  { {{#with customer}}     \"identifier\": \"{{id}}\"     {{#with email}}       , \"email\": \"{{.}}\"     {{/with}} {{#with accepts_marketing}}       , \"accepts_marketing\": {{.}}     {{/with}}           {{#if phone}}       , \"phone\": \"{{phone}}\"     {{else}}     {{#if default_address.phone}}     , \"phone\": \"{{default_address.phone}}\"     {{/if}}     {{/if}}     {{#if first_name}}       {{#if last_name}}       , \"name\": \"{{first_name}} {{last_name}}\"       {{else}}       , \"name\": \"{{first_name}}\"       {{/if}}     {{else}}       {{#if last_name}}         , \"name\": \"{{last_name}}\"       {{/if}}     {{/if}}   {{#with default_address}}  {{#if address1}}       {{#if address2}}         , \"street\": \"{{address1}} {{address2}}\"       {{else}}         , \"street\": \"{{address1}}\"       {{/if}}     {{/if}}     {{#with city}}         , \"city\": \"{{.}}\"     {{/with}}     {{#with zip}}         , \"postal_code\": \"{{.}}\"     {{/with}}     {{#with country_code}}         , \"country_code\": \"{{.}}\"     {{/with}}     {{#with country}}         , \"country\": \"{{.}}\"     {{/with}}  {{/with}} {{/with}}   }",
                    },
                    type: "handlebars"
                }
            }

            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/customer_signup_integrations/shopify`] = config
            this.props.updateDatabaseUpdates(dbUpdates)
        } else {
            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/customer_signup_integrations/shopify`] = null
            this.props.updateDatabaseUpdates(dbUpdates)
        }
    }

    // Component

    render() {
        return (
            <Panel key="customer_lookup" defaultExpanded={false}>
                <Panel.Heading>
                    <Panel.Title toggle={true}>Customer lookup</Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <Button onClick={() => { this.props.openShopifyPrivateApps() }}>Open Shopify Configuration <Glyphicon glyph="new-window" /></Button>
                        <br /> <br />
                        {this.customerLookupHelpText}
                        <ToggleButton isEnabled={this.props.enabled} performToggle={() => { this.toggleEnabled() }} />
                        <br /> <br />
                        {this.customerSignupHelpText}
                        <ToggleButton isEnabled={this.props.signupEnabled} performToggle={() => { this.toggleSignupEnabled() }} />
                        <br /> <br />
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        )
    }
}
