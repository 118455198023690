import * as React from "react"
import { Route, Redirect } from "react-router-dom"
import { Role, RoleAccessRequirement } from "./config/role"
import { RouteProps, RouteComponentProps, Omit } from "react-router"

export interface Resolver {
    authenticated: boolean
    role?: Role
}

interface AccessRouteProps extends RouteProps {
    resolver: Resolver
}

// This is a hack in order to let Omit know that it is ok to omit "role" from generic P.
interface Roleable {
    role: Role
}

// BG: This is just to satisfy the compiler. I don't really understand all of that type jazz but it seems to work
type ReducedRouteComponentProps = Omit<RouteComponentProps<{}>, "history" | "match" | "location">

interface RoleRouteComponentProps extends ReducedRouteComponentProps {
    role?: Role
}

type RouteComponent = React.FunctionComponent<RoleRouteComponentProps> | React.ComponentClass<any>

export const accessRouted = <P extends object>(component: React.ComponentType<P>, roleRequirement: RoleAccessRequirement): React.FunctionComponent<Omit<P & Roleable, "role"> & AccessRouteProps> => ({ path, resolver, ...rest }: AccessRouteProps) => {
    const renderFn = (MyComponent?: RouteComponent) => (props: RouteProps) => {
        if (!MyComponent) {
            return null
        }
        const role = resolver.role
        const authenticated = resolver.authenticated
        let redirectPath: string | undefined

        // BG: not liking this at all but I don"t have a prettier solution ready
        if (!role) {
            if (authenticated) {
                redirectPath = path === "/roles" ? undefined : "/roles"
            } else {
                redirectPath = path === "/login" ? undefined : "/login"
            }
        } else if (!roleRequirement.isSatisfied(role)) {
            redirectPath = path === "/no_access" ? undefined : "/no_access"
        } else if (path === "/roles" || path === "/login") {
            redirectPath = "/"
        }

        if (!redirectPath) {
            return <MyComponent {...props} {...rest} role={role}/>
        }

        const redirectProps = {
            to: {
                pathname: redirectPath,
                state: { from: props.location }
            }
        }

        return <Redirect {...redirectProps} />
    }
    return <Route path={path} render={renderFn(component as any)} />
}
