import * as Models from "../../../models/RuleModels"
import * as React from "react"
import moment from "moment"
import {
    Button,
    Panel,
    Table
} from "react-bootstrap"
import { ConfirmDeleteButton } from "../../ConfirmDeleteButton"
import { ModalPicker } from "../../ModalPicker"
import { momentFromDateComponents } from "./RuleTemplateForm"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom"

interface RuleElement {
    id: string
    name: string
    priority: number
    startDate?: string
    endDate?: string
    expired: boolean
    upcoming: boolean
    type: string
}

interface AvailableElement {
    id: string
    name: string
}

interface RuleListProps extends RouteComponentProps<any> {
    role: Role
}

interface RuleListState {
    rules: RuleElement[]
    availableRules: AvailableElement[]
    isAvailableRulesPickerVisible: boolean
    loaded: boolean
    showLegacyTemplates: boolean
}

const availableRules: AvailableElement[] = Models.availableTemplateTypes.map(type => {
    return {
        id: type,
        name: Models.typeToNameMap[type]
    }
})

const allRules: AvailableElement[] = Models.allTemplateTypes.map(type => {
    return {
        id: type,
        name: Models.typeToNameMap[type]
    }
})

class RuleList extends React.Component<RuleListProps, RuleListState> {

    // Properties

    initialState: RuleListState = {
        rules: [],
        availableRules: availableRules,
        isAvailableRulesPickerVisible: false,
        loaded: false,
        showLegacyTemplates: false
    }

    // Component

    constructor(props: RuleListProps) {
        super(props)

        this.state = this.initialState
    }

    componentDidMount() {
        this.templateRepoRef()
            .on("value", snapshot => {
                if (!snapshot || !snapshot.exists()) {
                    this.setState({ rules: [], loaded: true })
                    return
                }

                const dict = snapshot.val() ?? {}
                let rules: RuleElement[] = []
                const today = moment().startOf("day").format("YYYY-MM-DD")

                Object.keys(dict).forEach(key => {
                    const val = dict[key]
                    for (const type of Models.allTemplateTypes) {
                        const t = val[type]
                        if (t != null && t["name"] != null && t["priority"] != null) { //tslint:disable-line
                            let expired = false
                            let upcoming = false
                            let startDate: string | undefined = undefined
                            if (t["start_date"]) {
                                startDate = momentFromDateComponents(t["start_date"]).format("YYYY-MM-DD")
                                if (today < startDate) {
                                    upcoming = true
                                }
                            }
                            let endDate: string | undefined = undefined
                            if (t["end_date"]) {
                                endDate = momentFromDateComponents(t["end_date"]).format("YYYY-MM-DD")
                                if (today > endDate) {
                                    expired = true
                                }
                            }
                            rules.push({
                                id: key,
                                name: t.name,
                                priority: t.priority,
                                endDate: endDate,
                                startDate: startDate,
                                expired: expired,
                                upcoming: upcoming,
                                type: Models.typeToNameMapShort[type]
                            })
                            break
                        }
                    }
                })
                rules = rules.sort((a: RuleElement, b: RuleElement) => {
                    return b.priority - a.priority
                })
                this.setState({ rules: rules, loaded: true })
            })
    }

    componentWillUnmount() {
        this.templateRepoRef().off()
    }

    showLegacyTemplates() {
        this.setState({ availableRules: allRules, showLegacyTemplates: true })
    }

    hideLegacyTemplates() {
        this.setState({ availableRules: availableRules, showLegacyTemplates: false })
    }


    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="discounts">
                <div>
                    {this.state.isAvailableRulesPickerVisible && <ModalPicker elements={this.state.availableRules} alternativeButtonTitle={this.state.showLegacyTemplates ? "Hide legacy templates" : "Show legacy templates"} alternativeButtonAction={() => { this.state.showLegacyTemplates ? this.hideLegacyTemplates() : this.showLegacyTemplates() }} onCancel={this.cancelAvailableRulesPicker} onSelect={(element: AvailableElement) => { this.createRule(element.id) }} title="New discount rule" />}

                    <Panel>
                        <Panel.Heading>
                            <Button onClick={this.showAvailableRulesPicker}>New discount rule</Button>
                        </Panel.Heading>
                        <Table striped={true} bordered={true} condensed={true} hover={true} style={{ cursor: "pointer" }} fill={("true" as any) as boolean} >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Priority</th>
                                    <th>Status</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.rules.map((rule) => {
                                    return (
                                        <tr style={rule.expired ? { backgroundColor: "#FFF0F0" } : (rule.upcoming ? { backgroundColor: "#FFF8E8" } : {})} key={rule.id} onClick={() => { this.editRule(rule.id) }} >
                                            <td>{rule.name}</td>
                                            <td>{rule.type}</td>
                                            <td>{rule.priority}</td>
                                            <td>{rule.expired ? `Expired (${rule.endDate})` : (rule.upcoming ? `Upcoming (${rule.startDate})` : "Active")}</td>
                                            <td className="narrow">
                                                <ConfirmDeleteButton
                                                    message={`Really delete ${rule.name}???`}
                                                    onDelete={() => { this.removeRule(rule.id) }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <Panel.Footer>
                            <Button onClick={this.showAvailableRulesPicker}>New rule</Button>
                        </Panel.Footer>
                    </Panel>
                </div>
            </PageState>
        )
    }

    // Methods

    templateRepoRef() {
        const accountId = this.props.role.account_id
        return ref().child(`v1/accounts/${accountId}/inventory/campaign_template_repo`)
    }

    // edit rule /rules/rule_key
    editRule(key: string) {
        this.props.history.push(`/rule/${key}`)
    }

    //new rule /rules/new?new=ruletype
    createRule(type: string) {
        const path = "/rule/new"

        this.props.history.push({
            pathname: path,
            search: `?new=${type}`
        })
    }

    removeRule(key: string) {
        const accountId = this.props.role.account_id

        void ref()
            .child(`v1/accounts/${accountId}/inventory/campaign_template_repo/${key}`)
            .set(null)
    }

    showAvailableRulesPicker = () => {
        this.setState({ isAvailableRulesPickerVisible: true })
    }

    cancelAvailableRulesPicker = () => {
        this.setState({ isAvailableRulesPickerVisible: false })
    }
}

export default withRouter(RuleList)
