import * as React from "react"
import {
    Button,
    ButtonGroup,
    Form,
    Panel
} from "react-bootstrap"
import { ChromePicker } from "react-color"
import { default as reactCSS } from "reactcss"
import { PageState } from "../PageState"
import { ref } from "../../config/constants"
import { Role } from "../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router"

interface Color {
    hex: string
}

interface ThemeConfigurationProps extends RouteComponentProps<any> {
    role: Role
}

class ThemeConfiguration extends React.Component<ThemeConfigurationProps, any> {
    constructor(props: ThemeConfigurationProps) {
        super(props)
        this.state = {
            displayBackgroundColorPicker: false,
            displayKeyColorPicker: false,
            displaySecondScreenKeyColorPicker: false,
            displaySecondScreenForegroundColorPicker: false,
            displaySecondScreenBackgroundColorPicker: false,
            backgroundColor: "#000000",
            keyColor: "#000000",
            secondScreenBackgroundColor: "",
            secondScreenForegroundColor: "",
            secondScreenKeyColor: "",
            selectedSecondScreenFontFamilyOption: 0,
            loaded: false,
            publishing: false,
            dirty: false
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const themeRef = ref().child(`v1/accounts/${account}/configuration/theme`)
        const secondScreenThemeRef = ref().child(`v1/accounts/${account}/configuration/second_screen_theme`)

        const promises = []
        promises.push(themeRef.once("value"))
        promises.push(secondScreenThemeRef.once("value"))

        const [themeSnap, secondScreenThemeSnap] = await Promise.all(promises)
        this.setState({ loaded: true })
        const keyColor = themeSnap.val().key_color
        const backgroundColor = themeSnap.val().background_color

        this.setState({
            backgroundColor: backgroundColor,
            keyColor: keyColor,
        })

        let secondScreenBackgroundColor = ""
        let secondScreenForegroundColor = ""
        let secondScreenKeyColor = ""
        const snapshotValue = secondScreenThemeSnap.val()
        if (snapshotValue) {
            secondScreenBackgroundColor = snapshotValue.background_color || ""
            secondScreenForegroundColor = snapshotValue.foreground_color || ""
            secondScreenKeyColor = snapshotValue.key_color || ""
        }

        this.setState({
            secondScreenBackgroundColor: secondScreenBackgroundColor,
            secondScreenForegroundColor: secondScreenForegroundColor,
            secondScreenKeyColor: secondScreenKeyColor
        })
    }

    handleKeyColorClick = () => {
        this.setColorPickerState(!this.state.displayKeyColorPicker)
    }

    handleBackgroundColorClick = () => {
        this.setColorPickerState(false, !this.state.displayBackgroundColorPicker)
    }

    handleSecondScreenForegroundColorClick = () => {
        this.setColorPickerState(false, false, !this.state.displaySecondScreenForegroundColorPicker)
    }

    handleSecondScreenBackgroundColorClick = () => {
        this.setColorPickerState(false, false, false, !this.state.displaySecondScreenForegroundColorPicker)
    }

    handleSecondScreenKeyColorClick = () => {
        this.setColorPickerState(false, false, false, false, !this.state.displaySecondScreenKeyColorPicker)
    }

    handleColorPickerClose = () => {
        this.setColorPickerState()
    }

    handleKeyColorChange = (color: Color) => {
        this.setState({ keyColor: color.hex, dirty: true })
    }

    handleBackgroundColorChange = (color: Color) => {
        this.setState({ backgroundColor: color.hex, dirty: true })
    }

    handleSecondScreenForegroundColorChange = (color: Color) => {
        this.setState({ secondScreenForegroundColor: color.hex, dirty: true })
    }

    handleSecondScreenBackgroundColorChange = (color: Color) => {
        this.setState({ secondScreenBackgroundColor: color.hex, dirty: true })
    }

    handleSecondScreenKeyColorChange = (color: Color) => {
        this.setState({ secondScreenKeyColor: color.hex, dirty: true })
    }

    setColorPickerState = (keyColor = false, backgroundColor = false, secondScreenForegroundColor = false, secondScreenBackgroundColor = false, secondScreenKeyColor = false) => {
        this.setState({
            displayKeyColorPicker: keyColor,
            displayBackgroundColorPicker: backgroundColor,
            displaySecondScreenForegroundColorPicker: secondScreenForegroundColor,
            displaySecondScreenBackgroundColorPicker: secondScreenBackgroundColor,
            displaySecondScreenKeyColorPicker: secondScreenKeyColor,
            dirty: true
        })
    }

    setDefaultTheme = () => {
        const keyColor: Color = { hex: "#00E89D" }
        const backgroundColor: Color = { hex: "#111111" }
        this.setTheme(keyColor, backgroundColor)
    }

    setTheme = (keyColor: Color, backgroundColor: Color) => {
        this.handleKeyColorChange(keyColor)
        this.handleBackgroundColorChange(backgroundColor)
    }

    publishThemeConfiguration = async () => {
        const account = this.props.role.account_id
        const updates = {}
        updates[`v1/accounts/${account}/configuration/theme/key_color`] = this.state.keyColor
        updates[`v1/accounts/${account}/configuration/theme/background_color`] = this.state.backgroundColor

        this.setState({ publishing: true })
        await ref().update(updates)
        this.setState({ publishing: false, dirty: false })
    }

    publishSecondScreenConfiguration = async () => {
        if (!this.state.secondScreenBackgroundColor) {
            alert("Please choose a background color")
            return
        }
        if (!this.state.secondScreenForegroundColor) {
            alert("Please choose a foreground color")
            return
        }
        if (!this.state.secondScreenKeyColor) {
            alert("Please choose a key color")
            return
        }

        const newConfiguration: any = {}
        if (this.state.secondScreenForegroundColor) {
            newConfiguration.foreground_color = this.state.secondScreenForegroundColor
        }
        if (this.state.secondScreenBackgroundColor) {
            newConfiguration.background_color = this.state.secondScreenBackgroundColor
        }
        if (this.state.secondScreenKeyColor) {
            newConfiguration.key_color = this.state.secondScreenKeyColor
        }
        if (this.state.secondScreenFontFamilyName) {
            newConfiguration.font_family_name = this.state.secondScreenFontFamilyName
        }

        const account = this.props.role.account_id
        this.setState({ publishing: true })
        await ref().child(`v1/accounts/${account}/configuration/second_screen_theme`)
            .set(newConfiguration)

        this.setState({ publishing: false, dirty: false })
    }

    render() {
        const styles = reactCSS({
            "default": {
                keyColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: `${this.state.keyColor}`,
                },
                backgroundColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: `${this.state.backgroundColor}`,
                },
                secondScreenForegroundColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: `${this.state.secondScreenForegroundColor}`,
                },
                secondScreenBackgroundColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: `${this.state.secondScreenBackgroundColor}`,
                },
                secondScreenKeyColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: `${this.state.secondScreenKeyColor}`,
                },
                swatch: {
                    padding: "5px",
                    background: "#fff",
                    borderRadius: "1px",
                    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                    display: "inline-block",
                    cursor: "pointer",
                },
                popover: {
                    position: "absolute",
                    zIndex: "2",
                },
                cover: {
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                }
            },
        })

        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="themes"
                submit_action={async () => {
                    await this.publishThemeConfiguration()
                    await this.publishSecondScreenConfiguration()
                }}
            >
                <Panel>
                    <Panel.Heading>General</Panel.Heading>
                    <Panel.Body>
                        Key color
                        <div>
                            <div style={styles.swatch} onClick={this.handleKeyColorClick}>
                                <div style={styles.keyColor} />
                            </div>
                            {this.state.displayKeyColorPicker ? (
                                <div style={styles.popover as any}>
                                    <div style={styles.cover as any} onClick={this.handleColorPickerClose} />
                                    <ChromePicker disableAlpha={true} color={this.state.keyColor} onChange={this.handleKeyColorChange} />
                                </div>
                            ) : null}

                        </div>

                        Background color
                        <div>
                            <div style={styles.swatch} onClick={this.handleBackgroundColorClick}>
                                <div style={styles.backgroundColor} />
                            </div>
                            {this.state.displayBackgroundColorPicker ? (
                                <div style={styles.popover as any}>
                                    <div style={styles.cover as any} onClick={this.handleColorPickerClose} />
                                    <ChromePicker disableAlpha={true} color={this.state.backgroundColor} onChange={this.handleBackgroundColorChange} />
                                </div>
                            ) : null}
                        </div>
                        <br />
                        <br />
                        <ButtonGroup vertical={true}>
                            <Button onClick={this.setDefaultTheme}>Reset</Button>
                        </ButtonGroup>
                    </Panel.Body>
                </Panel>
                <Panel>
                    <Panel.Heading>Second Screen</Panel.Heading>
                    <Panel.Body>
                        <Form horizontal={true}>
                            Foreground color
                            <div>
                                <div style={styles.swatch} onClick={this.handleSecondScreenForegroundColorClick}>
                                    <div style={styles.secondScreenForegroundColor} />
                                </div>
                                {this.state.displaySecondScreenForegroundColorPicker ? (
                                    <div style={styles.popover as any}>
                                        <div style={styles.cover as any} onClick={this.handleColorPickerClose} />
                                        <ChromePicker disableAlpha={true} color={this.state.secondScreenForegroundColor} onChange={this.handleSecondScreenForegroundColorChange} />
                                    </div>
                                ) : null}
                            </div>
                            Background color
                            <div>
                                <div style={styles.swatch} onClick={this.handleSecondScreenBackgroundColorClick}>
                                    <div style={styles.secondScreenBackgroundColor} />
                                </div>
                                {this.state.displaySecondScreenBackgroundColorPicker ? (
                                    <div style={styles.popover as any}>
                                        <div style={styles.cover as any} onClick={this.handleColorPickerClose} />
                                        <ChromePicker disableAlpha={true} color={this.state.secondScreenBackgroundColor} onChange={this.handleSecondScreenBackgroundColorChange} />
                                    </div>
                                ) : null}
                            </div>
                            Key color
                            <div>
                                <div style={styles.swatch} onClick={this.handleSecondScreenKeyColorClick}>
                                    <div style={styles.secondScreenKeyColor} />
                                </div>
                                {this.state.displaySecondScreenKeyColorPicker ? (
                                    <div style={styles.popover as any}>
                                        <div style={styles.cover as any} onClick={this.handleColorPickerClose} />
                                        <ChromePicker disableAlpha={true} color={this.state.secondScreenKeyColor} onChange={this.handleSecondScreenKeyColorChange} />
                                    </div>
                                ) : null}
                            </div>
                        </Form>
                    </Panel.Body>
                </Panel>
            </PageState>
        )
    }
}

export default withRouter(ThemeConfiguration)
