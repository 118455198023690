import * as React from "react"
import ProductTabsViewModel from "./ProductTabsViewModel"
import {
    Button,
    ButtonToolbar,
    FormGroup,
    Panel
} from "react-bootstrap"
import {
    DragDropContext,
    Draggable,
    Droppable
} from "react-beautiful-dnd"
import { LanguageCode } from "../../../../helpers/L10n"
import { PageState } from "../../../PageState"
import { Role } from "../../../../config/role"
import { StripedTable } from "../../../StripedTable"
import { Tab } from "./ProductTabsModels"
import { Tag } from "../../../../models/Product"

// these css styles are a feeble attempt to make the table rows not look too bad while being dragged....

const narrowStyle = {
    width: "10%",
    textAlign: "center" as "center"
}

const nameStyle = {
    width: "80%",
    textAlign: "left" as "left"
}

interface ProductTabsPanelProps {
    role: Role
}

interface ProductTabsPanelState {
    dirty: boolean
    loaded: boolean
    panes: Tab[]
    publishing: boolean
    tags: Tag[]
}

export class ProductTabsPanel extends React.Component<ProductTabsPanelProps, ProductTabsPanelState> {

    private viewModel: ProductTabsViewModel

    constructor(props: ProductTabsPanelProps) {
        super(props)
        this.viewModel = new ProductTabsViewModel(props.role.account_id)
        this.viewModel.paneAndTagListsUpdated = this.paneAndTagListsUpdated.bind(this)
        this.state = {
            dirty: false,
            loaded: false,
            panes: [],
            publishing: false,
            tags: []
        }
    }

    // Signals from vm

    paneAndTagListsUpdated = (panes: Tab[], tags: Tag[]) => {
        this.setState({
            loaded: true,
            panes: panes,
            tags: tags
        })
    }

    // Signals to vm

    onRemove = (index: number) => {
        const name = this.viewModel.nameOfPaneAtIndex(index)
        if (window.confirm(`Really remove the tab "${name}"?`) === true) {
            this.viewModel.handleRemovePane(index)
            this.setState({ dirty: true })
        }
    }

    onDragEnd = (result: any) => {
        const didChange = this.viewModel.handleSingleReordering(result)
        if (didChange) {
            this.setState({ dirty: true })
        }
    }

    onTagSelect = (index: number) => {
        this.viewModel.handleTagSelect(index)
        this.setState({ dirty: true })
    }

    onPublish = async () => {
        this.setState({ publishing: true })
        await this.viewModel.publish()
        this.setState({ dirty: false, publishing: false })
    }

    // Component

    componentDidMount() {
        this.viewModel.start()
    }

    componentWillUnmount() {
        this.viewModel.stop()
    }

    render() {
        return (
            <PageState dirty={this.state.dirty} loading={!this.state.loaded} typeName="tabs" publishing={this.state.publishing}>
                <Panel key="product_tabs">
                    <Panel.Heading>
                        <Panel.Title>Tabs</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <h4>Concept</h4>
                        <p>Tabs are an optional filtering method used in POS where they are shown above the product grid to enable quick filtering.</p>
                        <p>A tab is created by selecting a tag in the list below. A tag can only be used as a tab once. Selecting the corresponding tab in POS will shown all products with that tag in the given folder context.</p>
                        <p>The tab order can be changed by dragging and dropping the tabs in the list</p>
                        <p>For more info take a look at our <a href="https://ka-ching.zendesk.com/hc/en-us/search?utf8=✓&query=faner" target="_blank" rel="noopener noreferrer">guides in Zendesk</a></p>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(droppableProvided) => (
                                    <div ref={droppableProvided.innerRef}>
                                        <StripedTable>
                                            <thead>
                                                <tr>
                                                    <th style={narrowStyle}>Order</th>
                                                    <th style={nameStyle}>Name</th>
                                                    <th style={narrowStyle}>Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.panes.map((pane, index) => (
                                                    <Draggable key={`${pane.filter.type}-${pane.filter.id}`} draggableId={`${pane.filter.type}-${pane.filter.id}`} index={index}>
                                                        {(draggableProvided: any) => (
                                                            <tr
                                                                ref={draggableProvided.innerRef}
                                                                key={`${pane.filter.type}-${pane.filter.id}`}
                                                                {...draggableProvided.draggableProps}
                                                                {...draggableProvided.dragHandleProps}
                                                            >
                                                                <td style={narrowStyle}>{pane.order}</td>
                                                                <td style={nameStyle}>
                                                                    {pane.name.localized(LanguageCode.da)}
                                                                </td>
                                                                <td style={narrowStyle}>
                                                                    <Button
                                                                        bsStyle="danger"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation()
                                                                            this.onRemove(index)
                                                                        }}
                                                                    >
                                                                        X
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </tbody>
                                        </StripedTable>
                                        {droppableProvided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <FormGroup>
                            {
                                this.state.tags.length > 0 ? (
                                    <ButtonToolbar>
                                        {
                                            this.state.tags.map((tag, index) => {
                                                return (
                                                    <Button
                                                        style={{ marginBottom: "6px" }}
                                                        key={tag.tag}
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            this.onTagSelect(index)
                                                        }}
                                                    >
                                                        {tag.name.localized(LanguageCode.da)}
                                                    </Button>
                                                )
                                            })
                                        }
                                    </ButtonToolbar>
                                ) : (
                                        <div>
                                            {
                                                this.state.panes.length > 0
                                                    ?
                                                    "All tags are used"
                                                    :
                                                    "No tags defined"
                                            }
                                        </div>
                                    )
                            }
                        </FormGroup>
                    </Panel.Body>
                    <Panel.Footer>
                        <Button onClick={this.onPublish} disabled={!this.state.dirty}>Publish</Button>
                    </Panel.Footer>
                </Panel>
            </PageState>
        )
    }
}
