import * as React from "react"
import { ref } from "../../config/constants"
import { Button, Col, ControlLabel, Form, FormGroup, Panel, Alert, Row, FormControl } from "react-bootstrap"
import { withRouter, RouteComponentProps } from "react-router"
import { PageState } from "../PageState"
import { Tag } from "../../models/Product"
import { L10nFormControl } from "../L10nFormControl"
import { LanguageCode, L10nString } from "../../helpers/L10n"
import { Role } from "../../config/role"
import { LanguagePicker } from "../LanguagePicker"
import { cloneDeep } from "lodash"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { publish } from "../../helpers/ModelPublisher"

interface TagEditProps extends RouteComponentProps<any> {
    currentLanguage?: LanguageCode
    role: Role
}

interface TagEditState {
    tag: Tag
    identifier: string
    loaded: boolean
    dirty: boolean
    publishing: boolean
    currentLanguage: LanguageCode | null
    error: string | null
}

class TagEdit extends React.Component<TagEditProps, TagEditState> {
    constructor(props: TagEditProps) {
        super(props)
        this.state = {
            tag: new Tag({
                tag: "",
                name: ""
            }),
            identifier: "",
            currentLanguage: props.currentLanguage || null,
            loaded: false,
            dirty: false,
            publishing: false,
            error: null
        }
    }

    pop() {
        const path = `/tags`
        this.props.history.push(path)
    }

    tagKey() {
        return this.props.match.params.tagKey
    }

    isNewTag() {
        return this.tagKey() === "new"
    }

    isPublishEnabled() {
        if (!this.state.dirty) {
            return false
        }
        if (this.state.tag.name.hasEmptyLocalizations()) {
            return false
        }

        return true
    }

    tagsRef() {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/tags")
    }

    async publish() {
        const json = this.state.tag.json()
        this.setState({ publishing: true })

        try {
            await publish(json, "tag", this.state.identifier, this.isNewTag(), this.tagsRef())
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.pop()
    }

    componentWillUnmount() {
        const key = this.tagKey()
        const account = this.props.role.account_id
        ref().child(`v1/accounts/${account}/inventory/tags/${key}`)
            .off()
    }

    async componentDidMount() {
        this.setState({ loaded: false })

        if (!this.isNewTag()) {
            const snapshot = await this.tagsRef()
                .child(this.tagKey())
                .once("value")
            const tag = new Tag(snapshot.val())
            this.setState({ tag: tag, identifier: tag.tag, loaded: true })
        } else {
            this.setState({ loaded: true })
        }
    }

    resolveLanguages = (): LanguageCode[] => {
        return this.state.tag.name.localizations()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })
    }

    removeLanguage = (language: LanguageCode) => {
        const tag = cloneDeep(this.state.tag)
        tag.name.removeLocalization(language)
        this.setState({ tag: tag, dirty: true, currentLanguage: null })
    }

    handleInputChange = (l10n: L10nString | null) => {
        const tag = cloneDeep(this.state.tag)
        tag.name = l10n || new L10nString("")
        this.setState({ tag: tag, dirty: true })
    }

    handleIdChange(identifier: string) {
        this.setState({ dirty: true, error: null, identifier: identifier })
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="tag"
            >
                <Form horizontal={true}>

                    <FormGroup>
                        <Col smOffset={8} sm={4} className="text-right">
                            <LanguagePicker
                                typeName="tag"
                                initialLanguage={this.state.currentLanguage}
                                resolveLanguages={this.resolveLanguages}
                                onChange={this.setLanguage}
                                onRemove={this.removeLanguage}
                            />
                        </Col>
                    </FormGroup>

                    <Panel>
                        <Panel.Heading>{this.isNewTag() ? "Create new tag" : `Edit tag '${this.state.tag.name.localized(this.state.currentLanguage)}'`}</Panel.Heading>
                        <Panel.Body>
                            <span key="a">
                                <FormGroup>
                                    <Row>
                                        <Col componentClass={ControlLabel} sm={2}>Name</Col>
                                        <Col sm={10}>
                                            <L10nFormControl
                                                l10n={this.state.tag.name}
                                                placeholder="Enter localized name"
                                                language={this.state.currentLanguage}
                                                onLocalizationChanged={l10n => { this.handleInputChange(l10n) }}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        !this.isNewTag()
                                            ? (
                                                <Row>
                                                    <Col componentClass={ControlLabel} sm={2}>Identifier</Col>
                                                    <Col sm={10}>
                                                        <FormControl.Static>{this.state.tag.tag}</FormControl.Static>
                                                    </Col>
                                                </Row>
                                            ) : null
                                    }
                                </FormGroup>
                            </span>
                            <ValidatingIdEntryControl
                                collectionRef={this.tagsRef()}
                                isNew={this.isNewTag()}
                                typeName="tag"
                                identifierSource={this.state.tag.name.localized(this.state.currentLanguage)}
                                existingIdentifier={this.state.identifier}
                                handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                            />
                        </Panel.Body>
                        <Panel.Footer><Button onClick={() => this.publish()} disabled={!this.isPublishEnabled()}>Publish</Button></Panel.Footer>
                    </Panel>
                </Form>
                {this.state.error ? (
                    <Alert bsStyle="danger">
                        <strong>Error publishing tag:</strong> {this.state.error}
                    </Alert>
                ) : []}
            </PageState>
        )
    }
}

export default withRouter(TagEdit)
