import * as React from "react"
import { Route, BrowserRouter, Link, Switch, Redirect } from "react-router-dom"
import { LinkContainer } from "react-router-bootstrap"
// import logo from "./logo.svg"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/css/bootstrap-theme.css"
import "react-dates/lib/css/_datepicker.css"
import "./assets/css/app.css"
import "react-sortable-tree/style.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBoxes, faCartArrowDown, faCashRegister, faChartBar, faChevronDown, faChevronLeft, faChevronRight, faClipboardCheck, faCog, faDollarSign, faFile, faFileImport, faGift, faGlobeEurope, faHome, faListUl, faPalette, faStore, faTag, faThLarge, faUsers, IconDefinition } from "@fortawesome/free-solid-svg-icons"

import { Role, NoRoleRequirement, AccountOwnerRequirement, ShopOwnerRequirement } from "./config/role"
import { accessRouted, Resolver } from "./routes"
import { ref, firebaseAuth, firestore, defaultRef, selectRTDBInstance } from "./config/constants"
import { currentUser, logout } from "./helpers/auth"
import { Globals } from "./helpers/globals"
import { Nav, NavItem, NavDropdown, MenuItem, Col, Glyphicon, Grid, Row, Button, Modal, Navbar } from "react-bootstrap"
import { IntlProvider, addLocaleData } from "react-intl"
import AccessDenied from "./components/protected/AccessDenied"
import AttributeEdit from "./components/protected/AttributeEdit"
import AttributeList from "./components/protected/AttributeList"
import AttributeGroupList from "./components/protected/AttributeGroupList"
import AttributeGroupEdit from "./components/protected/AttributeGroupEdit"
import BudgetEdit from "./components/protected/BudgetEdit"
import CashierRoles from "./components/protected/Configuration/CashierRoles"
import CashierList from "./components/protected/CashierList/CashierList"
import Configuration from "./components/protected/Configuration"
import ConfigurableFrontPage from "./components/protected/ConfigurableFrontPage/ConfigurableFrontPage"
import EmailConfiguration from "./components/protected/Advanced/EmailConfiguration"
import ExpenseEdit from "./components/protected/ExpenseEdit"
import ExpenseList from "./components/protected/ExpenseList"
import ExportIntegrationQueue from "./components/protected/Advanced/ExportIntegrationQueue"
import ExportIntegrations from "./components/protected/Advanced/ExportIntegrations"
import FacetList from "./components/protected/Facets/FacetList"
import FacetEdit from "./components/protected/Facets/FacetEdit"
import GiftcardList from "./components/protected/GiftcardList"
import GroupEdit from "./components/protected/GroupEdit"
import GroupList from "./components/protected/GroupList"
import ImportIntegrationQueue from "./components/protected/Advanced/ImportIntegrationQueue"
import ImportIntegrations from "./components/protected/Advanced/ImportIntegrations"
import IntegrationNotificationConfiguration from "./components/protected/Advanced/IntegrationNotificationConfiguration"
import Login from "./components/Login"
import MarketEdit from "./components/protected/MarketEdit"
import MarketList from "./components/protected/MarketList"
import ModulesConfiguration from "./components/protected/Configuration/ModulesConfiguration"
import Overview from "./components/protected/Overview"
import ProductEdit from "./components/protected/ProductEdit"
import ProductImport from "./components/protected/ProductImport"
import ProductList from "./components/protected/ProductList/ProductList"
import ProductOrganization from "./components/protected/ProductOrganization/ProductOrganization"
import ProductRuleList from "./components/protected/ProductRule/ProductRuleList"
import ProductRuleEdit from "./components/protected/ProductRule/ProductRuleEdit"
import RecommendationCategoryEdit from "./components/protected/RecommendationCategoryEdit"
import RecommendationsMaintainance from "./components/protected/RecommendationsMaintainance"
import Register from "./components/Register"
import RegisterCloseStatementList from "./components/protected/RegisterCloseStatementList"
import RegisterCloseStatementView from "./components/protected/RegisterCloseStatementView"
import Reports from "./components/protected/Reports"
import ReserveAndCollectDemo from "./components/protected/ReserverAndCollect/ReserveAndCollectDemo"
import RoleSelection from "./components/protected/RoleSelection"
import RuleEdit from "./components/protected/DiscountRules/RuleEdit"
import RuleList from "./components/protected/DiscountRules/RuleList"
import RuntimeIntegrations from "./components/protected/Advanced/RuntimeIntegrations"
import SalesList from "./components/protected/SalesList"
import SaleView from "./components/protected/SaleView"
import ShopConfiguration from "./components/protected/ShopConfiguration"
import ShopEdit from "./components/protected/ShopEdit"
import ShopifyModule from "./components/protected/Advanced/Shopify/ShopifyModule"
import ShopList from "./components/protected/ShopList"
import StockCountCurrent from "./components/protected/StockCountCurrentView/StockCountCurrent"
import StockCountList from "./components/protected/StockCountList/StockCountList"
import StockCountPast from "./components/protected/StockCountPast/StockCountPast"
import StockImport from "./components/protected/StockImport"
import StockList from "./components/protected/StockList/StockList"
import StockLocationEdit from "./components/protected/StockLocationEdit"
import TagEdit from "./components/protected/TagEdit"
import TagList from "./components/protected/TagList"
import TaxEdit from "./components/protected/TaxEdit"
import ThemeConfiguration from "./components/protected/ThemeConfiguration"
import UserManagement from "./components/protected/UserManagement"
import * as en from "react-intl/locale-data/en"
import * as fr from "react-intl/locale-data/fr"
import * as es from "react-intl/locale-data/es"
import * as da from "react-intl/locale-data/da"
import * as de from "react-intl/locale-data/de"
import firebase from "firebase/compat"
import { detect } from "detect-browser"
import _ from "lodash"
import StockRemovalReasonCodeEdit from "./components/protected/Configuration/StockRemovalReasonCodeEdit"

const version = require("./version.json")

addLocaleData([...en, ...fr, ...es, ...da, ...de])

interface DropDownModel {
    title: string
    id: string
    icon?: IconDefinition
    items: (MenuItemModel | null)[]
}

interface MenuItemModel {
    title: string
    icon?: IconDefinition
    path: string
    stockLocationId?: string
    isShop?: boolean
}

function isDropDown(item: DropDownModel | MenuItemModel): item is DropDownModel {
    return (item as DropDownModel).items !== undefined
}

interface AppState {
    role?: Role
    authenticated: boolean
    loading: boolean
    account_name: string | null
    shop_id: string | null
    stock_location_id: string | null
    shop_name: string | null
    uid: string | null
    expense_module_enabled: boolean
    stock_module_enabled: boolean
    advanced_ui_enabled: boolean
    versionExpired: boolean
    orders_module_enabled: boolean
    configurable_front_page_module_enabled: boolean
    no_access?: boolean
    selectedDropDownId?: string
    shops: any[]
    stockLocations: any[]
    giftcards_enabled: boolean
    giftcard_config_exists: boolean
}

const CashierListRoute = accessRouted(CashierList, ShopOwnerRequirement())
const CashierRoleRoute = accessRouted(CashierRoles, AccountOwnerRequirement())
const LoginRoute = accessRouted(Login, NoRoleRequirement())
const RuntimeIntegrationsRoute = accessRouted(RuntimeIntegrations, AccountOwnerRequirement())
const ExpenseListRoute = accessRouted(ExpenseList, AccountOwnerRequirement())
const ExpenseEditRoute = accessRouted(ExpenseEdit, AccountOwnerRequirement())
const ExportIntegrationsRoute = accessRouted(ExportIntegrations, AccountOwnerRequirement())
const ExportIntegrationQueueRoute = accessRouted(ExportIntegrationQueue, AccountOwnerRequirement())
const ImportIntegrationQueueRoute = accessRouted(ImportIntegrationQueue, AccountOwnerRequirement())
const ImportIntegrationsRoute = accessRouted(ImportIntegrations, AccountOwnerRequirement())
const EmailConfigurationRoute = accessRouted(EmailConfiguration, AccountOwnerRequirement())
const ShopifyConfigurationRoute = accessRouted(ShopifyModule, AccountOwnerRequirement())
const IntegrationNotificationConfigurationRoute = accessRouted(IntegrationNotificationConfiguration, AccountOwnerRequirement())
const ConfigurationRoute = accessRouted(Configuration, AccountOwnerRequirement())
const StockRemovalReasonCodeEditRoute = accessRouted(StockRemovalReasonCodeEdit, AccountOwnerRequirement())
const MarketListRoute = accessRouted(MarketList, AccountOwnerRequirement())
const MarketEditRoute = accessRouted(MarketEdit, AccountOwnerRequirement())
const TaxEditRoute = accessRouted(TaxEdit, AccountOwnerRequirement())
const ProductImportRoute = accessRouted(ProductImport, AccountOwnerRequirement())
const ThemeConfigurationRoute = accessRouted(ThemeConfiguration, AccountOwnerRequirement())
const ShopListRoute = accessRouted(ShopList, AccountOwnerRequirement())
const GroupListRoute = accessRouted(GroupList, AccountOwnerRequirement())
const GroupEditRoute = accessRouted(GroupEdit, AccountOwnerRequirement())
const TagListRoute = accessRouted(TagList, AccountOwnerRequirement())
const TagEditRoute = accessRouted(TagEdit, AccountOwnerRequirement())
const AttributeListRoute = accessRouted(AttributeList, AccountOwnerRequirement())
const AttributeEditRoute = accessRouted(AttributeEdit, AccountOwnerRequirement())
const AttributeGroupListRoute = accessRouted(AttributeGroupList, AccountOwnerRequirement())
const AttributeGroupEditRoute = accessRouted(AttributeGroupEdit, AccountOwnerRequirement())
const FacetListRoute = accessRouted(FacetList, AccountOwnerRequirement())
const FacetEditRoute = accessRouted(FacetEdit, AccountOwnerRequirement())
const UserManagementRoute = accessRouted(UserManagement, AccountOwnerRequirement())
const RuleListRoute = accessRouted(RuleList, AccountOwnerRequirement())
const RuleEditRoute = accessRouted(RuleEdit, AccountOwnerRequirement())
const ModulesConfigurationRoute = accessRouted(ModulesConfiguration, AccountOwnerRequirement())
const RecommendationsRoute = accessRouted(RecommendationsMaintainance, AccountOwnerRequirement())
const RecommendationCategoryRoute = accessRouted(RecommendationCategoryEdit, AccountOwnerRequirement())
const ProductOrganizationRoute = accessRouted(ProductOrganization, AccountOwnerRequirement())
const ConfigurableFrontPageRoute = accessRouted(ConfigurableFrontPage, AccountOwnerRequirement())
const GiftcardListRoute = accessRouted(GiftcardList, AccountOwnerRequirement())

const ProductRuleListRoute = accessRouted(ProductRuleList, AccountOwnerRequirement())
const ProductRuleEditRoute = accessRouted(ProductRuleEdit, AccountOwnerRequirement())

const OverviewShopRoute = accessRouted(Overview, ShopOwnerRequirement())
const OverviewAccountRoute = accessRouted(Overview, AccountOwnerRequirement())

const ShopBudgetEditRoute = accessRouted(BudgetEdit, ShopOwnerRequirement())
const ProductListRoute = accessRouted(ProductList, ShopOwnerRequirement())
const ProductEditRoute = accessRouted(ProductEdit, ShopOwnerRequirement())
const ShopEditRoute = accessRouted(ShopEdit, ShopOwnerRequirement())
const StockLocationEditRoute = accessRouted(StockLocationEdit, ShopOwnerRequirement())
const ReportsRoute = accessRouted(Reports, ShopOwnerRequirement())
const StockListRoute = accessRouted(StockList, ShopOwnerRequirement())
const StockCountListRoute = accessRouted(StockCountList, ShopOwnerRequirement())
const StockCountPastRoute = accessRouted(StockCountPast, ShopOwnerRequirement())
const StockCountCurrentRoute = accessRouted(StockCountCurrent, ShopOwnerRequirement())
const RegisterCloseStatementListRoute = accessRouted(RegisterCloseStatementList, ShopOwnerRequirement())
const RegisterCloseStatementViewRoute = accessRouted(RegisterCloseStatementView, ShopOwnerRequirement())
const ShopConfigurationRoute = accessRouted(ShopConfiguration, ShopOwnerRequirement())
const StockImportRoute = accessRouted(StockImport, ShopOwnerRequirement())
const ReserveAndCollectDemoRoute = accessRouted(ReserveAndCollectDemo, AccountOwnerRequirement())

const SaleViewRoute = accessRouted(SaleView, ShopOwnerRequirement())
const SalesListAccountRoute = accessRouted(SalesList, AccountOwnerRequirement())
const SalesListShopRoute = accessRouted(SalesList, ShopOwnerRequirement())
const SaleViewShopRoute = accessRouted(SaleView, ShopOwnerRequirement())

const AccessDeniedRoute = accessRouted(AccessDenied, NoRoleRequirement())
const RoleSelectionRoute = accessRouted(RoleSelection, NoRoleRequirement())

const browser = detect()

interface KxMenuItemProps {
    // icon: IconDefinition
    // title: string
    item: MenuItemModel | DropDownModel
    isSelected?: boolean
}

function KxMenuItem(props: KxMenuItemProps) {
    const dropDown = isDropDown(props.item)
    if (props.item.icon) {
        return <div className="kxmenuitem"><span><span><FontAwesomeIcon icon={props.item.icon} /></span></span>{props.item.title}{dropDown && <div style={{ float: "right" }}><FontAwesomeIcon icon={props.isSelected ? faChevronDown : faChevronRight} /></div>}</div>
    } else {
        return <div className="kxmenuitem">{props.item.title}</div>
    }
}

function KxSubMenuItem(props: KxMenuItemProps) {
    if (props.item.icon) {
        return <div style={{ display: "inline-block" }}><span style={{ width: "30px", display: "inline-block" }}>&nbsp;<FontAwesomeIcon icon={props.item.icon} /></span>{props.item.title}</div>
    } else {
        return <div style={{ display: "inline-block" }}><span style={{ width: "30px", display: "inline-block" }}>&nbsp;</span>{props.item.title}</div>
    }
}

export class App extends React.Component<any, AppState> implements Resolver {
    get role(): Role | undefined {
        return this.state.role
    }
    get authenticated(): boolean {
        return this.state.authenticated
    }

    constructor(props: any) {
        super(props)
        this.state = {
            authenticated: false,
            loading: true,
            // account_id: null,
            account_name: null,
            shop_id: null,
            stock_location_id: null,
            shop_name: null,
            uid: null,
            role: undefined,
            stock_module_enabled: false,
            expense_module_enabled: false,
            versionExpired: false,
            advanced_ui_enabled: false,
            orders_module_enabled: false,
            configurable_front_page_module_enabled: false,
            shops: [],
            stockLocations: [],
            giftcard_config_exists: false,
            giftcards_enabled: false
        }
    }

    unsubscribeAuthStateListener: firebase.Unsubscribe | null = null
    listeningToModulesRef: firebase.database.Reference | null = null
    listeningToGiftcardConfigRef: firebase.database.Reference | null = null

    async componentDidMount() {
        window.addEventListener("dragover", function (e) {
            e.preventDefault()
        }, false)
        window.addEventListener("drop", function (e) {
            e.preventDefault()
        }, false)

        this.unsubscribeAuthStateListener = firebaseAuth().onAuthStateChanged(async (user) => {
            if (user) {
                await this.resolveRole(user.uid)
                this.setState({
                    authenticated: true,
                    loading: false,
                    uid: user.uid
                })
            } else {
                // localStorage.removeItem("account")
                localStorage.removeItem("shop")
                localStorage.removeItem("role")
                localStorage.removeItem("advanced_ui_enabled")

                if (this.listeningToModulesRef) {
                    this.listeningToModulesRef.off()
                    this.listeningToModulesRef = null
                }
                if (this.listeningToGiftcardConfigRef) {
                    this.listeningToGiftcardConfigRef.off()
                    this.listeningToGiftcardConfigRef = null
                }

                Globals.shared.setAccount(null)
                this.setState({
                    authenticated: false,
                    loading: false,
                    account_name: null,
                    shop_id: null,
                    shop_name: null,
                    uid: null,
                    role: undefined,
                    stock_module_enabled: false,
                    advanced_ui_enabled: false,
                    expense_module_enabled: false,
                    orders_module_enabled: false,
                    configurable_front_page_module_enabled: false
                })
            }
        })

        const exactExpiredPath = `v1/versions/Portal/${version.major}_${version.minor}_${version.patch}/expired`
        defaultRef.child(exactExpiredPath).on("value", this.handleExpiredVersionValueChange.bind(this))
        const majorExpiredPath = `v1/versions/Portal/${version.major}/expired`
        defaultRef.child(majorExpiredPath).on("value", this.handleExpiredVersionValueChange.bind(this))
    }

    componentWillUnmount() {
        const expiredPath = `v1/versions/Portal/${version.major}_${version.minor}_${version.patch}/expired`
        defaultRef.child(expiredPath).off()
        const majorExpiredPath = `v1/versions/Portal/${version.major}/expired`
        defaultRef.child(majorExpiredPath).off()

        if (this.unsubscribeAuthStateListener) {
            this.unsubscribeAuthStateListener()
        }
    }

    handleExpiredVersionValueChange(snapshot: firebase.database.DataSnapshot | null) {
        if (!snapshot) {
            return
        }
        if (!snapshot.exists()) {
            return
        }
        if (snapshot.val() === true) {
            this.setState({ versionExpired: true })
        } else {
            this.setState({ versionExpired: false })
        }
    }

    modulesRef(accountId: string) {
        return ref().child(`v1/accounts/${accountId}/configuration/modules`)
    }

    giftcardConfigRef(accountId: string) {
        return ref().child(`v1/accounts/${accountId}/configuration/giftcard_service_config`)
    }

    shopsRef(accountId: string) {
        const shopsRef = ref().child(`v1/accounts/${accountId}/shop_index`)
        return shopsRef
    }

    stockLocationsRef(accountId: string) {
        const shopsRef = ref().child(`v1/accounts/${accountId}/stock_location_index`)
        return shopsRef
    }

    observeShopsAndStockLocations(accountId: string) {
        this.shopsRef(accountId).on("value", (snapshot) => {
            if (!snapshot || !snapshot.exists()) { return }
            const shopsDict = snapshot.val()
            const keys = Object.keys(shopsDict)
            const shops = keys
                .map(v => {
                    const shop = shopsDict[v]
                    shop.key = v
                    return shop
                })
                .filter(v => { return v.deactivated !== true })
                .sort((lhs: any, rhs: any) => {
                    const lhsName: string = lhs.name || ""
                    const rhsName: string = rhs.name || ""
                    return lhsName.localeCompare(rhsName)
                })

            this.setState({ shops: shops })
        })

        this.stockLocationsRef(accountId).on("value", (snapshot) => {
            if (!snapshot || !snapshot.exists()) { return }
            const locationsDict = snapshot.val()
            const keys = Object.keys(locationsDict)
            const locations = keys
                .map(v => {
                    const location = locationsDict[v]
                    location.key = v
                    return location
                })
                .filter(v => { return v.deactivated !== true })
                .sort((lhs: any, rhs: any) => {
                    const lhsName: string = lhs.name || ""
                    const rhsName: string = rhs.name || ""
                    return lhsName.localeCompare(rhsName)
                })

            this.setState({ stockLocations: locations })
        })
    }

    observeGiftcardConfigPresent(accountId: string) {
        if (this.listeningToGiftcardConfigRef) {
            return
        }
        this.listeningToGiftcardConfigRef = this.giftcardConfigRef(accountId)

        this.listeningToGiftcardConfigRef?.on("value", (snapshot) => {
            if (snapshot && snapshot.exists()) {
                this.setState({
                    giftcard_config_exists: true
                })
            } else {
                this.setState({
                    giftcard_config_exists: false
                })
            }
        })
    }

    observeModulesEnabled(accountId: string) {
        if (this.listeningToModulesRef) {
            return
        }
        this.listeningToModulesRef = this.modulesRef(accountId)

        this.listeningToModulesRef?.on("value", (snapshot) => {
            if (snapshot && snapshot.exists()) {
                const val = snapshot.val()
                this.setState({
                    stock_module_enabled: val.stock?.enabled ?? false,
                    expense_module_enabled: val.expenses?.enabled ?? false,
                    orders_module_enabled: val.orders?.enabled ?? false,
                    configurable_front_page_module_enabled: val.configurable_front_page?.enabled ?? false,
                    giftcards_enabled: val.giftcard?.enabled ?? false
                })
            } else {
                this.setState({
                    stock_module_enabled: false,
                    expense_module_enabled: false,
                    orders_module_enabled: false,
                    configurable_front_page_module_enabled: false,
                    giftcards_enabled: false
                })
            }
        })
    }

    // Does not change any state - only determines the role of the current user.
    // The determination is done as follows:
    // If only one account or shop access is present, return the corresponding role.
    // If several are present, try loading previously selected role from browser storage.
    // Otherwise return 'undefined' - meaning that the role selection UI must be presented
    // in order to resolve a role.
    async determineRole(user_id: string): Promise<Role | undefined> {
        const profileRef = firestore.doc(`profiles/${user_id}`)

        const snapshot = await profileRef.get()
        if (!snapshot.exists) {
            return
        }
        const profile = snapshot.data() ?? {}
        const accounts = Object.keys(profile.accounts ?? [])
        const shops = []
        for (const shopAccount in profile.shops) {
            for (const shop in profile.shops[shopAccount]) {
                shops.push({ account_id: shopAccount, shop_id: shop })
            }
        }
        if (accounts.length + shops.length === 1) {
            const accountSnap: firebase.firestore.DocumentSnapshot | undefined = await firestore.doc(`accounts/${accounts[0]}`).get().catch(() => { return undefined })
            const accountData = accountSnap?.data() ?? {}
            if (accounts.length === 1 && accountData.name !== undefined) {
                const account = accounts[0]
                return new Role(account, undefined, accountData.instance_id, accountData.region)
            } else if (shops.length === 1 && accountData.name !== undefined) {
                const info = shops[0]
                return new Role(info.account_id, info.shop_id, accountData.instance_id, accountData.region)
            }
        }

        let roleFromStorageString: string = localStorage.getItem("role") || "{}"
        if (typeof roleFromStorageString !== "string") {
            roleFromStorageString = "{}"
        }
        const roleFromStorage = JSON.parse(roleFromStorageString)
        const roleAccountId: string | undefined = roleFromStorage.account_id
        const roleShopId: string | undefined = roleFromStorage.shop_id

        if (roleAccountId) {
            return new Role(roleAccountId, roleShopId, roleFromStorage.rtdb_instance, roleFromStorage.region)
        }
        return undefined
    }

    resolveRole = async (user_id: string) => {
        const role = await this.determineRole(user_id)
        const selectedShopId = localStorage.getItem("shop") || undefined
        const selectedStockLocationId = localStorage.getItem("stock_location") || undefined

        await this.selectRole(user_id, role, selectedShopId, selectedStockLocationId)
    }

    selectRole = async (uid: string, role: Role | undefined, selectedShop: string | undefined = undefined, selectedStockLocation: string | undefined = undefined) => {
        if (role) {
            const permissionsRef = firestore.doc(`accounts/${role.account_id}/permissions/${uid}`)
            const permissions = (await permissionsRef.get()).data() ?? {}
            if (permissions.disallow_backoffice_access) {
                this.setState({ no_access: true })
                return
            }
        }

        let stockLocationId: string | null = null
        if (role) {
            selectRTDBInstance(role.rtdb_instance, role.region)
            Globals.shared.setAccount(role.account_id)
            stockLocationId = role.shop_id || null
        }

        let isShop = true

        // It's a shop if the id comes from the role.
        // Otherwise it's a shop if 'selectedShop' is non-null

        if (stockLocationId === null) {
            stockLocationId = selectedShop ?? selectedStockLocation ?? null
            if (_.isNil(selectedShop)) {
                isShop = false
            }
        }

        this.setState({ role: role, shop_id: isShop ? stockLocationId : null, stock_location_id: stockLocationId })

        if (!role) {
            localStorage.removeItem("role")
            return
        }
        localStorage.setItem("role", JSON.stringify(role.json()))
        if (role.account_id) {
            await this.didSelectAccount(role.account_id)
        }
        if (stockLocationId) {
            await this.didSelectStockLocation(stockLocationId, role.account_id, isShop)
        }
        if (role.isAccountOwner()) {
            const advancedUI = localStorage.getItem("advanced_ui_enabled") === "true"
            this.setState({ advanced_ui_enabled: advancedUI })
        }
    }

    didSelectAccount = async (account_id: string) => {
        this.observeModulesEnabled(account_id)
        this.observeGiftcardConfigPresent(account_id)
        this.observeShopsAndStockLocations(account_id)

        const snapshot = await ref().child(`v1/account_index/${account_id}/name`).once("value")
        this.setState({
            account_name: snapshot.val()
        })
    }

    deselectStockLocation = (stock_location_id: string) => {
        if (this.state.stock_location_id === stock_location_id) {
            localStorage.removeItem("shop")
            localStorage.removeItem("stock_location")
            this.setState({
                shop_id: null,
                shop_name: null,
                stock_location_id: null
            })
        }
    }

    selectStockLocation = async (stock_location_id: string, account_id: string, is_shop: boolean) => {
        this.setState({ shop_id: is_shop ? stock_location_id : null, stock_location_id: stock_location_id })
        await this.didSelectStockLocation(stock_location_id, account_id, is_shop)
    }

    async didSelectStockLocation(stock_location_id: string, account_id: string, is_shop: boolean) {
        if (is_shop) {
            localStorage.setItem("shop", stock_location_id)
        } else {
            localStorage.removeItem("shop")
        }
        localStorage.setItem("stock_location", stock_location_id)

        if (is_shop) {
            const snapshot = await ref()
                .child(`v1/accounts/${account_id}/shop_index/${stock_location_id}/name`)
                .once("value")
            this.setState({
                shop_name: snapshot.val()
            })
        } else {
            this.setState({
                shop_name: "Online"
            })
        }
    }

    isAccountOwner() {
        if (this.state.role) {
            return this.state.role.isAccountOwner()
        }
        return false
    }

    isShopOwner() {
        if (this.state.role) {
            return this.state.role.isShopOwner()
        }
        return false
    }

    get shopId(): string {
        return this.state.shop_id || "_"
    }

    get stockLocationId(): string {
        return this.state.stock_location_id || "_"
    }

    get showGiftcards(): boolean {
        return this.state.giftcards_enabled && this.state.giftcard_config_exists        
    }

    // Navigation to the overview is the same as clearing out the
    // stock location id and shop id, both from state and from storage.
    // Also expand shops menu item again
    navigateToOverview() {
        if (this.state.stock_location_id) {
            this.deselectStockLocation(this.state.stock_location_id)
        }
        this.setState({ selectedDropDownId: "shops" })
    }

    accountMenuItems(): (MenuItemModel | DropDownModel)[] {
        const shops: MenuItemModel[] = this.state.shops.map(shop => {
            return {
                title: shop.name ?? "(Unnamed shop)",
                icon: faStore,
                path: "/shop/" + shop.key,
                stockLocationId: shop.key,
                isShop: true
            }
        })

        const stockLocations: MenuItemModel[] = (this.state.stock_module_enabled ?
            this.state.stockLocations.map(location => {
                return {
                    title: location.name ?? location.key,
                    icon: faBoxes,
                    path: "/stock_location/" + location.key + "/edit",
                    stockLocationId: location.key,
                    isShop: false
                }
            }) : [])

        for (const shop of this.state.shops) {
            _.remove(stockLocations, location => { return location.stockLocationId === shop.key })
        }
        const allLocations = [{
            title: "Add / remove shops",
            icon: faListUl,
            path: "/shops/"
        } as MenuItemModel].concat(shops).concat(stockLocations)

        const items: (MenuItemModel | DropDownModel | null)[] = [
            // {
            //     title: "Accounting", items: [
            //         { title: "Sales", path: "/sales" },

            //     ]
            // },
            // {
            //     title: "Inventory", items: [
            //         { title: "Products", path: "/products" },
            //         { title: "Product Import", path: "/product_import" },
            //         { title: "Product Recommendations", path: "/product_recommendations" },
            //         { title: "Groups", path: "/groups" },
            //         { title: "Tags", path: "/tags" },
            //         { title: "Discount Rules", path: "/rules" }
            //     ]
            // },
            // {
            //     title: "Setup", items: [
            //         { title: "Theme", path: "/theme" },
            //         { title: "Users", path: "/users" },
            //         { title: "Modules", path: "/modules" },
            //         { title: "Configuration", path: "/configuration" },
            //         { title: "Markets", path: "/markets" },
            //     ]
            // },
            // { title: "Shops", path: "/shops" },
            { title: `Home`, icon: faHome, path: "/overview" },
            {
                title: "Inventory", icon: faTag, id: "inventory", items: [
                    { title: "Products", path: "/products" },
                    { title: "Product Import", path: "/product_import" },
                    { title: "Recommendations", path: "/product_recommendations" },
                    { title: "Discounts", path: "/rules" },
                    { title: "Product Rules", path: "/product_rules" },
                    this.state.expense_module_enabled ? { title: "Expenses", path: "/expenses" } : null,
                    { title: "Groups", path: "/groups" },
                    { title: "Tags", path: "/tags" },
                    { title: "Facets", path: "/facets" },
                    { title: "Product Attributes", path: "/attributes" },
                    { title: "Attribute Groups", path: "/attribute_groups" },
                    { title: "Product Organization", path: "/product_organization" },
                    { title: "Customer Attributes", path: "/customer_attributes" },
                    { title: "Cashier roles", path: "/cashier_roles" },
                    this.state.configurable_front_page_module_enabled ? { title: "Configurable front page", path: "/configurable_front_page" } : null,
                ]
            },
            { title: "Sales", icon: faDollarSign, path: "/sales" },
            this.showGiftcards ? { title: "Giftcards", icon: faGift, path: "/giftcards" } : null,
            {
                title: "Shops", icon: faStore, id: "shops", items: allLocations
            },
            { title: "Users", icon: faUsers, path: "/users" },
            {
                title: "Configuration", icon: faCog, id: "configuration", items: [
                    { title: "General", icon: faCog, path: "/configuration" },
                    { title: "Modules", icon: faThLarge, path: "/modules" },
                    { title: "Markets", icon: faGlobeEurope, path: "/markets" },
                    { title: "Theme", icon: faPalette, path: "/theme" },
                ]
            }
        ]
        function notEmpty<TValue>(value: TValue | null): value is TValue {
            return value !== null
        }

        const filtered: (MenuItemModel | DropDownModel)[] = items.filter(notEmpty)
        return filtered
    }

    advancedMenuItems(): (MenuItemModel | DropDownModel)[] {
        if (!this.state.advanced_ui_enabled) { return [] }
        const items: (MenuItemModel | DropDownModel)[] = [
            { title: "Import Integrations", path: `/import_integrations` },
            { title: "Export Integrations", path: `/export_integrations` },
            { title: "Runtime Integrations", path: `/runtime_integrations` },
            { title: "Shopify Integration", path: "/shopify_configuration" },
            { title: "Email Configuration", path: "/email_configuration" },
            { title: "Notification Configuration", path: "/notification_configuration" }
        ]
        return items
    }

    shopMenuItems(): (MenuItemModel | DropDownModel)[] {
        if (!this.state.shop_id) { return [] }
        // const inventory: MenuItemModel[] = [
        //     { title: "Shop Specific Products", path: `/shop_products` },
        // ]
        // if (this.isShopOwner()) {
        //     inventory.push({ title: "Account Products (read only)", path: "/products" })
        // }
        // const items: (MenuItemModel | DropDownModel)[] = [
        //     {
        //         title: "Accounting", items: [
        //             { title: "Sales", path: `/shop/${this.state.shop_id}/sales` },
        //             { title: "Register Close Statements", path: `/shop/${this.state.shop_id}/register_close_statements` },
        //             { title: "Reports", path: `/shop/${this.state.shop_id}/reports` },
        //         ]
        //     },
        //     { title: "Inventory", items: inventory },
        //     {
        //         title: "Setup", items: [
        //             { title: "Shop Details", path: `/shop_edit/${this.state.shop_id}` },
        //             { title: "Shop Configuration", path: `/shop_configuration/${this.state.shop_id}` },
        //             { title: "Cashiers", path: `/shop/${this.state.shop_id}/cashiers` },
        //         ]
        //     }
        // ]

        // if (this.state.stock_module_enabled === true) {
        //     items.push({
        //         title: "Stock", items: [
        //             { title: "Current Stock", path: `/shop/${this.state.shop_id}/stock` },
        //             { title: "Stock Counts", path: `/shop/${this.state.shop_id}/stock_counts` },
        //             { title: "Stock Import", path: `/shop/${this.state.shop_id}/stock_import` }
        //         ]
        //     })
        // }

        const items: (MenuItemModel | DropDownModel)[] = [
            {
                title: "Configuration", icon: faCog, id: "shop_configuration", items: [
                    { title: "Edit Shop", icon: faStore, path: `/shop/${this.state.shop_id}/edit` },
                    { title: "Shop Configuration", icon: faCog, path: `/shop/${this.state.shop_id}/configuration` },
                    { title: "Budgets", icon: faChartBar, path: `/shop/${this.state.shop_id}/budget` },
                    { title: "Cashiers", icon: faUsers, path: `/shop/${this.state.shop_id}/cashiers` }
                ]
            }
        ]

        if (this.isShopOwner()) {
            items.push(
                // {title: "Inventory", icon: faTag, id: "shop_inventory", items: [
                { title: "Account Products", icon: faTag, path: "/products" }
                // ]}
            )
        }
        items.push(
            { title: "Sales", icon: faDollarSign, path: `/shop/${this.state.shop_id}/sales` },
            { title: "Register Close Statements", icon: faCashRegister, path: `/shop/${this.state.shop_id}/register_close_statements` },
            { title: "Reports", icon: faFile, path: `/shop/${this.state.shop_id}/reports` },
        )

        if (this.isAccountOwner() && (this.state.orders_module_enabled === true)) {
            items.push({ title: "Order handling Demo", icon: faCartArrowDown, path: `/shop/${this.state.shop_id}/reserve_and_collect_demo` })
        }

        return items
    }

    stockLocationMenuItems(): (MenuItemModel | DropDownModel)[] {
        if (!this.state.stock_location_id) { return [] }

        if (this.state.stock_module_enabled !== true) { return [] }

        const items: (MenuItemModel | DropDownModel)[] = []

        if (_.isNil(this.state.shop_id)) {
            items.push({ title: "Edit Stock Location", path: `/stock_location/${this.state.stock_location_id}/edit` })
        }

        items.push(
            { title: "Current Stock", icon: faBoxes, path: `/stock_location/${this.state.stock_location_id}/stock` },
            { title: "Stock Counts", icon: faClipboardCheck, path: `/stock_location/${this.state.stock_location_id}/stock_counts` },
            { title: "Stock Import", icon: faFileImport, path: `/stock_location/${this.state.stock_location_id}/stock_import` }
        )

        return items
    }

    userMenuItems() {
        return [
            (
                <MenuItem key="account" disabled={true}>
                    {this.state.account_name}
                </MenuItem>
            ),
            <MenuItem key="account-divider" divider={true} />,
            this.isAccountOwner() ?
                [
                    (
                        <MenuItem
                            key="first"
                            onSelect={async () => {
                                const newState = !this.state.advanced_ui_enabled
                                this.setState({ advanced_ui_enabled: newState })
                                localStorage.setItem("advanced_ui_enabled", `${newState}`)
                            }}
                        >
                            {this.state.advanced_ui_enabled ? <Glyphicon glyph="ok" /> : ""} Advanced Options
                        </MenuItem>
                    ),
                    <MenuItem key="second" divider={true} />
                ]
                : null,
            (
                <MenuItem key="logout" onSelect={async () => { await logout() }}>
                    Log out
                </MenuItem >
            ),
            <MenuItem key="bottom-divider" divider={true} />,
            (
                <MenuItem key="version" disabled={true}>
                    v{version.major}.{version.minor}.{version.patch}
                </MenuItem>
            )
        ]
    }

    renderAccountMenuItems() {
        return this.renderMenuItems(this.accountMenuItems())
    }

    renderMenuItems(models: (MenuItemModel | DropDownModel)[], isSubItem: boolean = false) {
        const items: JSX.Element[] = []
        for (const index in models) {
            const model = models[index]
            if (isDropDown(model)) {
                const title = <KxMenuItem item={model} isSelected={this.state.selectedDropDownId === model.id} />
                if (this.state.selectedDropDownId === model.id) {
                    items.push(<NavItem key={model.id} onSelect={() => { this.setState({ selectedDropDownId: undefined }) }}>{title}</NavItem>)
                    for (const subIndex in model.items) {
                        const subItem = model.items[subIndex]
                        if (subItem === null) { continue }
                        const subKey = `${model.id}_${parseInt(index) * 100 + parseInt(subIndex)}`
                        const subItemTitle = <KxSubMenuItem item={subItem} />
                        items.push(<LinkContainer key={subKey} onClick={() => { if (subItem.stockLocationId) { this.selectStockLocation(subItem.stockLocationId, this.role?.account_id ?? "", subItem.isShop ?? false) } }} to={subItem.path}><NavItem className="subitem">{subItemTitle}</NavItem></LinkContainer>)
                    }
                } else {
                    const item = <NavItem key={model.id} className={isSubItem ? "subitem" : ""} onSelect={() => { this.setState({ selectedDropDownId: model.id }) }}>{title}</NavItem>
                    items.push(item)
                }

            } else {
                const title = <KxMenuItem item={model} />
                const navItem = <NavItem className={isSubItem ? "subitem" : ""}>{title}</NavItem>
                items.push(<LinkContainer onSelect={() => { this.setState({ selectedDropDownId: undefined }) }} key={index} to={model.path}>{navItem}</LinkContainer>)
            }
        }

        return items
    }

    renderNavBar() {
        return (
            <Navbar id="bootstrap-override" fixedTop={true} style={{ paddingLeft: "10px", paddingRight: "30px" }}>
                <Navbar.Header>
                    <Navbar.Brand>
                        <Link to="/"><img src="/backoffice/assets/ka-ching-logo.png" height="55" alt="Ka-ching logo" /></Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav pullRight={true}>
                        {
                            this.state.authenticated
                                ?
                                [
                                    (
                                        <NavItem key="b" eventKey={2} href="https://ka-ching.zendesk.com">
                                            Support
                                        </NavItem>
                                    ),
                                    (
                                        <NavDropdown
                                            noCaret={true}
                                            title={currentUser()?.email?.substring(0, 1).toUpperCase()}
                                            id="a"
                                            key="a"
                                            style={{
                                                color: "rgb(57,52,90)",
                                                textAlign: "center",
                                                fontWeight: 500,
                                                fontSize: "16px"
                                            }}
                                        >
                                            {this.userMenuItems()}
                                        </NavDropdown>
                                    ),
                                    (
                                        <NavDropdown key="c" id="b" title={currentUser()?.email}>{this.userMenuItems()}</NavDropdown>
                                    )
                                ]
                                :
                                (
                                    <NavItem key="login" eventKey={2} href="/login">
                                        Login
                                    </NavItem>
                                )
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }

    render() {

        const showUpdate = this.state.versionExpired
        const updateContainerStyle: any = {
            border: "2px solid black",
            borderRadius: "10px",
            background: "white",
            position: "fixed",
            bottom: "5%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
        }
        const updateStyle: any = {
            textColor: "gray",
            textAlign: "center",
            margin: "10px"
        }

        if (isBrowserSupported() === false) {
            return this.renderUnsupportedBrowser()
        }

        if (this.state.no_access === true) {
            return (
                <Modal.Dialog key="b">
                    <Modal.Header>
                        <Modal.Title>No access</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>The currently signed in user has no access rights to Ka-ching Backoffice on the selected account. Please contact your account administrator.</Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => { this.setState({ no_access: undefined }) }}>OK</Button>
                    </Modal.Footer>
                </Modal.Dialog>
            )
        }

        return this.state.loading === true
            ?
            (
                <IntlProvider defaultLocale="en" locale={navigator.language}>
                    <BrowserRouter>
                        <Navbar id="bootstrap-override" fixedTop={true} style={{ paddingLeft: "10px", paddingRight: "30px" }}>
                            <Navbar.Header>
                                <Navbar.Brand>
                                    <Link to="/"><img src="/backoffice/assets/ka-ching-logo.png" height="55" alt="Ka-ching logo" /></Link>
                                </Navbar.Brand>
                            </Navbar.Header>
                            <Nav>
                                <NavItem>Loading...</NavItem>
                            </Nav>
                            <Nav pullRight={true}>
                                <NavItem key="b" eventKey={2} href="https://ka-ching.zendesk.com">
                                    Support
                                </NavItem>
                            </Nav>
                        </Navbar>
                    </BrowserRouter>
                </IntlProvider>
            )
            :
            (
                <IntlProvider defaultLocale="en" locale={navigator.language}>
                    <BrowserRouter><div>
                        {this.renderNavBar()}

                        <div>
                            {
                                showUpdate
                                    ?
                                    (
                                        <div style={updateContainerStyle}>
                                            <p style={updateStyle}><h4>A new version of Ka-ching is available</h4><button onClick={() => { window.location.reload() }}>Click here to refresh</button></p>
                                        </div>
                                    )
                                    :
                                    null
                            }
                            <div className="colrow">
                                {this.state.role && (
                                    <Col xs={12} sm={4} md={3} lg={2} style={{ fontWeight: 500, fontSize: "14px" }}>
                                        <div style={{ paddingTop: "75px" }}>
                                            {
                                                (_.isNil(this.state.shop_id) && _.isNil(this.state.stock_location_id) && this.isAccountOwner())
                                                &&
                                                (
                                                    <div>
                                                        <Nav bsStyle="pills" stacked={true}>
                                                            {this.renderAccountMenuItems()}
                                                        </Nav>
                                                        <br />
                                                    </div>
                                                )
                                            }
                                            {
                                                (!_.isNil(this.state.shop_id) || !_.isNil(this.state.stock_location_id)) && this.isAccountOwner() && (
                                                    <Button bsStyle="link" onClick={() => { this.navigateToOverview() }}><FontAwesomeIcon icon={faChevronLeft} /> Back</Button>
                                                )
                                            }
                                            {
                                                (!_.isNil(this.state.shop_id) && (this.isAccountOwner() || this.isShopOwner()))
                                                &&
                                                (
                                                    <div>
                                                        <Nav bsStyle="pills" stacked={true}>
                                                            <NavItem key="navitem" disabled={true}>SHOP</NavItem>
                                                            <LinkContainer key="linkcontainer" to={"/shop/" + this.state.shop_id}><NavItem>{this.state.shop_name || ""}</NavItem></LinkContainer>
                                                            {this.renderMenuItems(this.shopMenuItems())}
                                                        </Nav>
                                                        <br />
                                                    </div>
                                                )
                                            }
                                            {
                                                this.state.stock_location_id && this.state.stock_module_enabled === true && (this.isAccountOwner() || this.isShopOwner())
                                                    ?
                                                    (
                                                        <div>
                                                            <Nav bsStyle="pills" stacked={true}>
                                                                <NavItem disabled={true}>STOCK</NavItem>
                                                                {this.renderMenuItems(this.stockLocationMenuItems())}
                                                            </Nav>
                                                            <br />
                                                        </div>
                                                    )
                                                    : null
                                            }
                                            {
                                                this.state.advanced_ui_enabled
                                                    ?
                                                    (
                                                        <div>
                                                            <Nav bsStyle="pills" stacked={true}>
                                                                <NavItem disabled={true}>ADVANCED</NavItem>
                                                                {this.renderMenuItems(this.advancedMenuItems(), true)}
                                                            </Nav>
                                                            <br />
                                                        </div>
                                                    )
                                                    :
                                                    <div />
                                            }
                                            {/* Make it possible to scroll a bit further than to the exact bottom of the menu */}
                                            <br /><br /><br />
                                        </div>
                                    </Col>
                                )}
                                <Col xs={12} sm={this.state.role ? 8 : 12} md={this.state.role ? 9 : 12} lg={this.state.role ? 10 : 12} style={{ backgroundColor: "rgb(245, 245, 245)" }}>
                                    <div style={{ paddingLeft: "30px", paddingRight: "30px", paddingTop: "75px" }}>
                                        <Switch>

                                            <Route
                                                exact={true}
                                                path="/"
                                                render={() => (
                                                    (this.role && this.role.isShopOwner()) ?
                                                        <Redirect to={"/shop/" + this.role.shop_id} /> :
                                                        <Redirect to="/overview" />
                                                )}
                                            />

                                            <CashierListRoute path="/shop/:shopKey/cashiers" shop={this.shopId} resolver={this} />
                                            <LoginRoute path="/login" resolver={this} />

                                            <Route path="/register" render={() => { return <Register resolveRole={this.resolveRole} /> }} />
                                            <GiftcardListRoute path="/giftcards" resolver={this} />
                                            <CashierRoleRoute path="/cashier_roles" resolver={this} />
                                            <ImportIntegrationsRoute path="/import_integrations" resolver={this} />
                                            <ImportIntegrationQueueRoute path="/import_integration/:integrationType/:integrationKey" resolver={this} />
                                            <RuntimeIntegrationsRoute path="/runtime_integrations" resolver={this} />
                                            <ExportIntegrationsRoute path="/export_integrations" resolver={this} />
                                            <ExportIntegrationQueueRoute path="/export_integration/:integrationType/:integrationKey" resolver={this} />
                                            <EmailConfigurationRoute path="/email_configuration" resolver={this} />
                                            <ShopifyConfigurationRoute path="/shopify_configuration" resolver={this} />
                                            <IntegrationNotificationConfigurationRoute path="/notification_configuration" resolver={this} />
                                            <ConfigurationRoute path="/configuration" resolver={this} />
                                            <StockRemovalReasonCodeEditRoute path="/stock_removal_reason_code/:codeKey" resolver={this} />
                                            <MarketListRoute path="/markets" resolver={this} />
                                            <MarketEditRoute path="/market/:marketKey" resolver={this} />
                                            <TaxEditRoute path="/tax/:taxKey" resolver={this} />
                                            <ModulesConfigurationRoute path="/modules" resolver={this} />
                                            <ProductImportRoute path="/product_import" resolver={this} />
                                            <ThemeConfigurationRoute path="/theme" resolver={this} />
                                            <ProductEditRoute path="/products/:productKey/new" resolver={this} />
                                            <ProductEditRoute path="/shop_product/:productKey/new" shop={this.shopId} resolver={this} />
                                            <ProductEditRoute path="/products/:productKey" resolver={this} />
                                            <ProductEditRoute path="/shop_product/:productKey" shop={this.shopId} resolver={this} />
                                            <ProductListRoute path="/products" resolver={this} />
                                            <RecommendationsRoute path="/product_recommendations" resolver={this} />
                                            <RecommendationCategoryRoute path="/recommendation_category/:categoryKey" resolver={this} />
                                            <SaleViewRoute path="/sales/:saleKey" resolver={this} />
                                            <SalesListAccountRoute path="/sales" resolver={this} />
                                            <SaleViewShopRoute path="/shop/:shopKey/sales/:saleKey" shop={this.shopId} resolver={this} />
                                            <SalesListShopRoute path="/shop/:shopKey/sales" shop={this.shopId} resolver={this} />
                                            <RegisterCloseStatementViewRoute path="/shop/:shopKey/register_close_statement/:statementKey" shop={this.shopId} resolver={this} />
                                            <RegisterCloseStatementListRoute path="/shop/:shopKey/register_close_statements" shop={this.shopId} resolver={this} />
                                            <ShopListRoute path="/shops" selectShop={this.selectStockLocation} deselectShop={this.deselectStockLocation} resolver={this} />
                                            <ShopEditRoute path="/shop/:shopKey/edit" resolver={this} />
                                            <StockLocationEditRoute path="/stock_location/:stockLocationKey/edit" resolver={this} />
                                            <ShopBudgetEditRoute path="/shop/:shopKey/budget" shop={this.shopId} resolver={this} />
                                            <ShopConfigurationRoute path="/shop/:shopKey/configuration" resolver={this} />
                                            <ReportsRoute path="/shop/:shopKey/reports" shop={this.shopId} resolver={this} />
                                            <StockListRoute path="/stock_location/:stockLocationKey/stock" stockLocation={this.stockLocationId} resolver={this} />
                                            <StockCountListRoute path="/stock_location/:stockLocationKey/stock_counts" stockLocation={this.stockLocationId} resolver={this} />
                                            <StockCountPastRoute path="/stock_location/:stockLocationKey/stock_count/past/:stockCountId" stockLocation={this.stockLocationId} resolver={this} />
                                            <StockCountCurrentRoute path="/stock_location/:stockLocationKey/stock_count/current/:stockCountId" stockLocation={this.stockLocationId} resolver={this} />
                                            <StockImportRoute path="/stock_location/:stockLocationKey/stock_import" stockLocation={this.stockLocationId} resolver={this} />
                                            <ReserveAndCollectDemoRoute path="/shop/:stockLocationKey/reserve_and_collect_demo" shop={this.shopId} resolver={this} />
                                            <GroupListRoute path="/groups" resolver={this} />
                                            <GroupEditRoute path="/group/:groupKey" resolver={this} />
                                            <TagListRoute path="/tags" resolver={this} />
                                            <TagEditRoute path="/tag/:tagKey" resolver={this} />
                                            <ExpenseListRoute path="/expenses" resolver={this} />
                                            <ExpenseEditRoute path="/expense/:expenseKey" resolver={this} />
                                            <AttributeListRoute path="/customer_attributes" resolver={this} mode="customer" />
                                            <AttributeListRoute path="/attributes" resolver={this} mode="product" />
                                            <AttributeGroupListRoute path="/attribute_groups" resolver={this} />
                                            <AttributeEditRoute path="/customer_attribute/:attributeKey" resolver={this} mode="customer" />
                                            <AttributeEditRoute path="/attribute/:attributeKey" resolver={this} mode="product" />
                                            <AttributeGroupEditRoute path="/attribute_group/:attributeGroupKey" resolver={this} />
                                            <FacetListRoute path="/facets" resolver={this} />
                                            <FacetEditRoute path="/facet/:facetKey" resolver={this} />
                                            <UserManagementRoute path="/users" resolver={this} />
                                            <RuleListRoute path="/rules" resolver={this} />
                                            <RuleEditRoute path="/rule/:ruleKey" resolver={this} />
                                            <ProductRuleListRoute path="/product_rules" resolver={this} />
                                            <ProductRuleEditRoute path="/product_rule/:ruleKey" resolver={this} />
                                            <ProductOrganizationRoute path="/product_organization" resolver={this} />
                                            <ConfigurableFrontPageRoute path="/configurable_front_page" resolver={this} />
                                            <AccessDeniedRoute path="/no_access" resolver={this} />
                                            <RoleSelectionRoute path="/roles" uid={this.state.uid!} selectRole={this.selectRole} resolver={this} />
                                            <OverviewAccountRoute path="/overview" resolver={this} />
                                            <OverviewShopRoute path="/shop/:shopKey" shop={this.shopId} resolver={this} />
                                            <Route render={() => <h3>No Match</h3>} />
                                        </Switch>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                    </BrowserRouter>
                </IntlProvider >
            )
    }

    renderUnsupportedBrowser() {
        const browserName = browser !== null ? browser.name.charAt(0).toUpperCase() + browser.name.slice(1) : "an unknown browser"

        return (
            <Grid>
                <Row className="text-center"><h1>Unsupported browser</h1></Row>
                <Row className="text-center"><p>You seem to be running <strong>{browserName}</strong> which is currently unsupported.</p></Row>
                <Row className="text-center"><p>The Ka-ching backoffice only supports using <strong>Chrome</strong> or <strong>Safari</strong>.</p></Row>
            </Grid>
        )
    }
}

function isBrowserSupported(): boolean {
    switch (browser && browser.name) {
        case "edge-chromium":
        case "chrome":
        case "ios":
        case "safari":
            return true

        default:
            return false
    }
}
