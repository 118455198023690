//
//  BudgetEdit.tsx
//  POSFirebaseHosting
//
//  Created by Morten Bek Ditlevsen on 08/11/2021.
//  Copyright © 2021 Ka-ching. All rights reserved.
//

import * as React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { DropdownButton, MenuItem, FormGroup, Row, Col, ControlLabel, InputGroup } from "react-bootstrap"
import { ref } from "../../config/constants"
import { PageState } from "../PageState"
import { Role } from "../../config/role"
import { SingleDatePicker } from "react-dates"
import moment from "moment"
import { Cashier } from "../../models/Cashier"
import { CashierRole } from "./Configuration/CashierRoles"
import { L10nString } from "../../helpers/L10n"
import { ascendingByCashierFullName } from "./CashierList/helpers"
import * as _ from "lodash"
import firebase from "firebase/compat"
import Numeral from "numeral"
/* tslint:disable */
import "firebase/database"
import { MarketAmountFormControl } from "../MarketAmountFormControl"
import { Globals } from "../../helpers/globals"
import { Market } from "../../models/MarketModels"
import { MarketAmount } from "../../models/MarketAmount"
import { StripedTable } from "../StripedTable"
import { DeleteButton } from "../DeleteButton"
/* tslint:enable */

require("numeral/locales/da-dk") // register da-dk locale settings

function CenteredBlock(props: any) {
    return (
        <Row>
            <Col sm={0} md={1} />
            <Col sm={12} md={10}>{props.children}</Col>
            <Col sm={0} md={1} />
        </Row>
    )
}

interface BudgetEditProps extends RouteComponentProps<any> {
    role: Role
    shop: string
}

interface BudgetEditState {
    loaded: boolean
    dirty: boolean
    publishing: boolean
    date: moment.Moment | null
    focus: boolean | null
    cashiers: Cashier[]
    cashierRoles: CashierRole[]
    selectedName?: string
    selectedCashierId?: string
    selectedRoleId?: string
    currentMonth: string
    currentBudget: _.Dictionary<any>
    newBudget: _.Dictionary<any>
    currentMarket: Market | null
}

class BudgetEdit extends React.Component<BudgetEditProps, BudgetEditState> {

    // Component

    constructor(props: BudgetEditProps) {
        super(props)

        const now = moment()

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            focus: null,
            date: null,
            cashiers: [],
            cashierRoles: [],
            currentMonth: now.format("YYYY-MM"),
            currentBudget: {},
            newBudget: {},
            currentMarket: null
        }
    }

    async componentDidMount() {
        await this.loadState()
        this.setupObservers()
    }

    componentWillUnmount() {
        this.teardownObservers()
    }

    setupObservers() {

        ref().child(this.cashierRolesPath).on("value", snapshot => {
            if (!snapshot || !snapshot.exists()) {
                this.setState({ loaded: true })
                return
            }

            const roles: CashierRole[] = []
            if (snapshot && snapshot.exists()) {
                const rolesDict = snapshot.val()
                for (const key in rolesDict) {
                    const roleJson = rolesDict[key]
                    if (roleJson.id && roleJson.name) {
                        roles.push({
                            id: roleJson.id,
                            name: new L10nString(roleJson.name),
                            functionalityBlocklist: roleJson.functionality_blocklist ?? []
                        })
                    }
                }
            }
            this.setState({ cashierRoles: roles, loaded: true })
        })

        ref().child(this.cashiersPath).on("value", snapshot => {
            if (!snapshot || !snapshot.exists()) {
                return
            }

            const cashiersMap = snapshot.val()

            let cashiers: Cashier[] = []
            for (const key in cashiersMap) {
                const cashier = Cashier.fromJSON(cashiersMap[key])
                if (!_.isNull(cashier)) {
                    cashiers.push(cashier)
                }
            }

            cashiers = cashiers.sort(ascendingByCashierFullName)

            this.setState({ cashiers: cashiers })
        })
    }

    private get cashierRolesPath(): string {
        return `v1/accounts/${this.accountKey}/inventory/cashier_roles`
    }
    teardownObservers() {
        ref().child(this.cashiersPath).off()
        ref().child(this.cashierRolesPath).off()
    }
    private get shopKey(): string {
        return this.props.shop
    }

    private get accountKey(): string {
        return this.props.role.account_id
    }

    private get cashiersPath(): string {
        return `v1/accounts/${this.accountKey}/shops/${this.shopKey}/cashiers`
    }

    async loadState() {
        this.setState({ loaded: false })
        const markets = await Globals.shared.getMarkets()
        const shopMarketRef = ref().child(`v1/accounts/${this.accountKey}/shops/${this.shopKey}/market`)
        const shopMarket = await shopMarketRef.get()
        if (shopMarket.exists()) {
            const market = markets.find(m => { return m.id === shopMarket.val() })
            if (market) {
                this.setState({ currentMarket: market })
            }
        }
    }

    async selectSubject(subject: string) {
        const comps = subject.split(".")
        const type = comps[0]
        const id = comps[1]
        if (type === "cashier") {
            const selected = this.state.cashiers.find(c => { return c.id === id })
            if (selected !== undefined) {
                await this.loadBudget(this.state.currentMonth, id, undefined)
                this.setState({
                    selectedName: "Cashier: " + (selected.displayName ?? selected.fullName ?? selected.id),
                    selectedCashierId: id,
                    selectedRoleId: undefined
                })
            }
        } else {
            const selected = this.state.cashierRoles.find(c => { return c.id === id })
            if (selected !== undefined) {
                await this.loadBudget(this.state.currentMonth, undefined, id)
                this.setState({
                    selectedName: "Cashier role: " + selected.name.localized(null),
                    selectedRoleId: id,
                    selectedCashierId: undefined
                })
            }
        }
    }

    cashierBudgetRef(cashierId: string) {
        return ref().child(`v1/accounts/${this.accountKey}/budgets/summary/shop/${this.shopKey}/cashier/${cashierId}/day`)
    }
    cashierRoleBudgetRef(cashierRoleId: string) {
        return ref().child(`v1/accounts/${this.accountKey}/budgets/summary/shop/${this.shopKey}/cashier_role/${cashierRoleId}/day`)
    }

    async updateCurrentMonth(newMonth: moment.Moment) {
        const currentMonth = newMonth.format("YYYY-MM")
        this.setState({ currentMonth: currentMonth })
        await this.loadBudget(currentMonth, this.state.selectedCashierId, this.state.selectedRoleId)
    }

    async loadBudget(currentMonth: string, cashierId?: string, roleId?: string) {
        // Get dates of visible months

        const current = moment(currentMonth, "YYYY-MM")
        const startOfCurrentMonth = current.startOf("month")
        const endOfNextMonth = startOfCurrentMonth.clone().add(1, "months").endOf("month")
        const firstDay = startOfCurrentMonth.format("YYYY-MM-DD")
        const lastDay = endOfNextMonth.format("YYYY-MM-DD")

        let budgetRef: firebase.database.Reference | undefined = undefined
        if (cashierId !== undefined) {
            budgetRef = this.cashierBudgetRef(cashierId)
        } else if (roleId) {
            budgetRef = this.cashierRoleBudgetRef(roleId)
        }

        let budget: _.Dictionary<any> = {}
        if (budgetRef !== undefined) {
            console.log("firstday", firstDay, "lastday", lastDay)
            const q = budgetRef.orderByKey().startAt(firstDay).endAt(lastDay)
            const snapshot = await q.get()
            if (snapshot.exists()) {
                budget = snapshot.val()
            }
        }
        this.setState({ currentBudget: budget })
    }

    async submit() {
        this.setState({ publishing: true })
        let budgetRef: firebase.database.Reference | undefined = undefined
        if (this.state.selectedCashierId !== undefined) {
            budgetRef = this.cashierBudgetRef(this.state.selectedCashierId)
        } else if (this.state.selectedRoleId) {
            budgetRef = this.cashierRoleBudgetRef(this.state.selectedRoleId)
        } else {
            return
        }

        for (const key in this.state.newBudget) {
            const budgetDayRef = budgetRef.child(key)
            if (this.state.newBudget[key] === "KX_DELETE") {
                await budgetDayRef.remove()
            } else {
                await budgetDayRef.set(this.state.newBudget[key])
            }
        }
        this.setState({ dirty: false, publishing: false, newBudget: {} })
        await this.loadBudget(this.state.currentMonth, this.state.selectedCashierId, this.state.selectedRoleId)
    }

    renderUnsaved() {
        if (Object.keys(this.state.newBudget).length === 0) { return }
        return (
            <div>
                <br /><br />
                Unsaved values
                <StripedTable>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th style={{ textAlign: "right" }}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(this.state.newBudget).sort().map(key => {
                            return (
                                <tr key={key}>
                                    <td>
                                        {key}
                                    </td>
                                    <td style={{ textAlign: "right" }}>{this.state.newBudget[key] === "KX_DELETE" ? "Deleted" : Numeral(this.state.newBudget[key]?.sales?.total ?? 0).format("0,0")}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </StripedTable>
            </div>
        )
    }

    renderAmountInput(date: moment.Moment) {
        const key = date.format("YYYY-MM-DD")
        let entry = this.state.newBudget[key] ?? this.state.currentBudget[key]
        if (entry === "KX_DELETE") { entry = undefined }
        const total = _.isNil(entry?.sales?.total) ? null : new MarketAmount(entry?.sales?.total)
        return (
            <FormGroup>
                <Row>
                    <Col componentClass={ControlLabel} sm={2}>Budget value</Col>
                    <Col sm={10}>
                        <MarketAmountFormControl
                            amount={total}
                            market={this.state.currentMarket}
                            allMarkets={this.state.currentMarket ? [this.state.currentMarket.id] : []}
                            onAmountChanged={amount => {
                                const clone = _.cloneDeep(this.state.newBudget)
                                clone[key] = { sales: { total: amount?.amount(this.state.currentMarket?.id ?? null) ?? 0 } }
                                this.setState({ newBudget: clone, dirty: true })
                            }}
                            placeholder="Enter budget value"
                            currency={this.state.currentMarket?.currency ?? ""}
                        >
                            <InputGroup.Button>
                                <DeleteButton
                                    onDelete={() => {
                                        const clone = _.cloneDeep(this.state.newBudget)
                                        clone[key] = "KX_DELETE"
                                        this.setState({ newBudget: clone, dirty: true })
                                    }}
                                    disabled={entry === undefined}
                                />

                            </InputGroup.Button>

                        </MarketAmountFormControl>
                    </Col>
                </Row>
            </FormGroup>
        )
    }

    render() {
        return (
            <CenteredBlock>
                <PageState
                    loading={!this.state.loaded}
                    dirty={this.state.dirty}
                    typeName="budgets"
                    publishing={this.state.publishing}
                    title="Unsaved changes"
                    submit_title="Save"
                    submit_action={async () => { this.submit() }}
                >
                    <div>
                        <p>
                            Create a budget by selecting a cashier or a cashier role below, and start editing the relevant dates.
                        </p><br /><br />
                        <FormGroup>
                            <Row>
                                <Col componentClass={ControlLabel} sm={2}>Select subject</Col>
                                <Col sm={10}>
                                    <DropdownButton
                                        bsStyle="default"
                                        title={this.state.selectedName ?? "Cashier or role"}
                                        id="dropdown-attribute-value"
                                        onSelect={async (selectedValue: any) => {
                                            await this.selectSubject(selectedValue)
                                        }}
                                    >
                                        <MenuItem key=".cashiersHeader" header={true}>Cashiers</MenuItem>

                                        {Object.keys(this.state.cashiers).map((optionKey) => {
                                            const option: Cashier = this.state.cashiers[optionKey]
                                            return <MenuItem key={".cashier_" + optionKey} eventKey={"cashier." + option.id}>{option.displayName}</MenuItem>
                                        })}
                                        {Object.keys(this.state.cashierRoles).length > 0 && [
                                            <MenuItem key=".divider" divider={true} />,
                                            <MenuItem key=".cashierRolesHeader" header={true}>Cashier roles</MenuItem>,
                                            Object.keys(this.state.cashierRoles).map((optionKey) => {
                                                const option: CashierRole = this.state.cashierRoles[optionKey]
                                                return <MenuItem key={".role_" + optionKey} eventKey={"role." + option.id}>{option.name.localized(null)}</MenuItem>
                                            })
                                        ]}
                                    </DropdownButton>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col componentClass={ControlLabel} sm={2}>Select date</Col>
                                <Col sm={10}>
                                    <SingleDatePicker
                                        id="date"
                                        date={this.state.date}
                                        onDateChange={date => { this.setState({ date: date }) }}
                                        focused={this.state.focus ?? false}
                                        onFocusChange={focus => { this.setState({ focus: focus.focused }) }}
                                        renderDayContents={day => {
                                            const key = day.format("YYYY-MM-DD")
                                            const budgetEntry = this.state.newBudget[key] ?? this.state.currentBudget[key]
                                            if (budgetEntry !== undefined) {
                                                let total: Numeral | undefined = undefined
                                                if (budgetEntry.sales?.total !== undefined) {
                                                    total = Numeral(budgetEntry.sales?.total)
                                                }
                                                return <span style={{ fontSize: "12px" }}><b>{day.format("D")}</b> {total?.format("0,0")}</span>
                                            } else {
                                                return day.format("D")
                                            }
                                        }}
                                        isOutsideRange={a => { return false }}
                                        onNextMonthClick={async newMonth => { await this.updateCurrentMonth(newMonth) }}
                                        onPrevMonthClick={async newMonth => { await this.updateCurrentMonth(newMonth) }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        {this.state.date !== null && this.renderAmountInput(this.state.date)}
                        {this.renderUnsaved()}
                    </div>
                </PageState>
            </CenteredBlock>
        )
    }

}

export default withRouter(BudgetEdit)
