import firebase from "firebase/compat"
import {
    Filter,
    Filters,
    FilterType
    } from "../ProductOrganizationModels"
import {
    FilterTreeItem,
    Folder
    } from "./ProductFoldersModels"
import { Tag } from "../../../../models/Product"
import { TagObserver } from "../../../../helpers/tagObserver"
import { ref } from "../../../../config/constants"

export default class ProductFoldersViewModel {

    // Properties

    private _accountId: string
    private _folders?: Folder[]
    private _tagObserver: TagObserver

    // Constructor

    constructor(accountId: string) {
        this._accountId = accountId
        this._tagObserver = new TagObserver(accountId)
        this._tagObserver.tagsChangedCallback = this.handleTagsChanged.bind(this)
    }

    // Signals In

    start() {
        this._tagObserver.start()
    }

    stop() {
        this._tagObserver.stop()
    }

    handleTreeDataChanged(treeData: FilterTreeItem[]) {
        if (!this._folders || !this._tagObserver.tagsArray) {
            return
        }

        this._folders = treeData.map(item => Folder.fromTreeItem(item))

        if (this.foldersAndTagsUpdated) {
            const filters = new Filters(this._tagObserver.tagsDict!)
            const newTreeData = this._folders.map(f => { return f.treeData(filters) })
            this.foldersAndTagsUpdated(newTreeData, this._tagObserver.tagsArray!)
        }
    }

    handleTagSelect(index: number) {
        if (!this._folders || !this._tagObserver.tagsArray) {
            return
        }

        const tag = this._tagObserver.tagsArray[index]
        if (!tag) {
            return
        }

        const newFolders = Array.from(this._folders)
        newFolders.push(new Folder(new Filter(FilterType.tag, tag.tag)))
        this._folders = newFolders

        if (this.foldersAndTagsUpdated) {
            const filters = new Filters(this._tagObserver.tagsDict!)
            const treeData = this._folders.map(f => { return f.treeData(filters) })
            this.foldersAndTagsUpdated(treeData, this._tagObserver.tagsArray!)
        }
    }

    async publish() {
        if (!this._folders) {
            return
        }

        await this.foldersRef().set(this._folders.map(folder => folder.toJson()))
    }

    // Signals Out   

    public foldersAndTagsUpdated?: (treeItems: FilterTreeItem[], tags: Tag[]) => void

    // Helpers

    private foldersRef(): firebase.database.Reference {
        return ref().child(`v1/accounts/${this._accountId}/app_data/pos/product_organization/folders`)
    }

    private async handleTagsChanged() {
        if (!this._folders) {
            this._folders = await this.loadFoldersFromDB()
        }

        if (this.foldersAndTagsUpdated) {
            const filters = new Filters(this._tagObserver.tagsDict!)
            const treeData = this._folders.map(f => { return f.treeData(filters) })
            this.foldersAndTagsUpdated(treeData, this._tagObserver.tagsArray!)
        }
    }

    private async loadFoldersFromDB(): Promise<Folder[]> {
        const snapshot = await this.foldersRef().once("value")
        if (!snapshot.exists()) {
            return []
        } else {
            return this.foldersFromJson(snapshot.val())
        }
    }

    private foldersFromJson(json: any): Folder[] {
        return Object.keys(json).map(key => { return Folder.fromJson(json[key]) })
    }

}