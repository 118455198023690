import * as React from "react"
import {
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
} from "react-bootstrap"
import { ModuleProps } from "../Configuration/ModuleConfiguration"

export class MobilePayModule extends React.Component<ModuleProps, {}> {

    // Helpers

    isEnabled(): boolean {
        return this.props.moduleData["enabled"] || false
    }

    handleInputChange = (event: any) => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.props.updateConfiguration(config => {
            if (value === undefined || value === null || value.length === 0) {
                delete config[name]
            } else {
                config[name] = value
            }
        })
    }

    // Component

    render() {
        return (
            <Form horizontal={true}>
            <FormGroup>
                <Col componentClass={ControlLabel} sm={2}>Merchant brand ID</Col>
                <Col sm={10}>
                    <FormControl
                        type="text"
                        name="merchant_brand_id"
                        value={this.props.moduleData["merchant_brand_id"] ?? ""}
                        placeholder="Please enter your MobilePay merchant brand ID"
                        onChange={this.handleInputChange}
                    />
                </Col>
            </FormGroup>
            <FormGroup>
                <Col componentClass={ControlLabel} sm={2}>Merchant VAT number</Col>
                <Col sm={10}>
                    <FormControl
                        type="text"
                        name="merchant_vat_number"
                        value={this.props.moduleData["merchant_vat_number"] ?? ""}
                        placeholder="Please enter your MobilePay merchant VAT number"
                        onChange={this.handleInputChange}
                    />
                </Col>
            </FormGroup>
        </Form>
        )
    }
}
