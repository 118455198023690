import * as React from "react"
import SMTPConfigurationComponent from "./SMTPConfiguration"
import {
    ButtonGroup,
    DropdownButton,
    MenuItem,
    Panel
} from "react-bootstrap"
import { EmailConfigurationType } from "../../../models/EmailConfigurationType"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"

function titleForConfigType(configType: EmailConfigurationType): string {
    switch (configType) {
        case EmailConfigurationType.none:
            return "Use Ka-ching for sending mail"

        case EmailConfigurationType.ownSMTP:
            return "Use own SMTP server for sending mail"

        case EmailConfigurationType.validatedDomain:
            return "Use validated domain for sending mail"
    }
}

function helpTextForConfigType(configType: EmailConfigurationType) {
    switch (configType) {
        case EmailConfigurationType.none:
            return <p>All emails sent from Ka-ching on behalf of this account will originate from the ka-ching.dk domain eg. receipts@ka-ching.dk.</p>

        case EmailConfigurationType.ownSMTP:
            return <p>All emails will be sent through the configured SMTP server.</p>

        case EmailConfigurationType.validatedDomain:
            return (
                <div>
                    <p>Your domain needs to be validated with the Ka-ching mail setup. This requires that you have access to the DNS config for the domain that you wish to validate.</p>
                    <p>Contact Ka-ching support <a href="mailto:support@ka-ching.dk">support@ka-ching.dk</a> for more information</p>
                </div>
            )
    }
}

interface EmailConfigurationProps {
    role: Role
}

interface EmailConfigurationState {
    dirty: boolean
    loaded: boolean
    publishing: boolean

    configType: EmailConfigurationType
}

export default class EmailConfigurationComponent extends React.Component<EmailConfigurationProps, EmailConfigurationState> {
    constructor(props: EmailConfigurationProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,

            configType: EmailConfigurationType.none,
        }
    }

    // Component

    async componentDidMount() {
        const account = this.props.role.account_id
        const configTypeRef = ref().child(`v1/accounts/${account}/configuration/email/config_type`)
        const configTypeSnap = await configTypeRef.once("value")
        if (configTypeSnap.exists()) {
            const value = configTypeSnap.val()
            const configType = Object.values(EmailConfigurationType).includes(value) ? value : EmailConfigurationType.none

            this.setState({
                configType: configType,
                loaded: true
            })
        } else {
            await this.handleConfigTypeSelect(EmailConfigurationType.none)
            this.setState({ loaded: true })
        }
    }

    async handleConfigTypeSelect(type: any) {
        this.setState({ publishing: true })
        const account = this.props.role.account_id
        const configTypeRef = ref().child(`v1/accounts/${account}/configuration/email/config_type`)
        await configTypeRef.set(type)

        if (type !== EmailConfigurationType.ownSMTP) {
            // TODO: The functions mailer daemon should probably have knowledge of these config types. It assumes any data in the smtp node to be a custom
            // SMTP configuration and just uses that. So if we do no clear the SMTP configuration when the user selects anything other than own_smtp, the
            // system will not do as expected.
            const smtpRef = ref().child(`v1/accounts/${account}/configuration/smtp`)
            await smtpRef.set(null)
        }

        this.setState({ configType: type, publishing: false })
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="email configuration"
                submit_action={async () => { }}
                discard_action={() => { }}
            >
                <Panel>
                    <Panel.Heading>
                        Email configuration
                    </Panel.Heading>

                    <Panel.Body>
                        <ButtonGroup key="config_types">
                            <DropdownButton
                                title={titleForConfigType(this.state.configType)}
                                name="config_type_selector"
                                id="config_types"
                                onSelect={async (event: any) => { await this.handleConfigTypeSelect(event) }}
                            >
                                <MenuItem key={EmailConfigurationType.none} eventKey={EmailConfigurationType.none}>{titleForConfigType(EmailConfigurationType.none)}</MenuItem>
                                <MenuItem key={EmailConfigurationType.ownSMTP} eventKey={EmailConfigurationType.ownSMTP}>{titleForConfigType(EmailConfigurationType.ownSMTP)}</MenuItem>
                                <MenuItem key={EmailConfigurationType.validatedDomain} eventKey={EmailConfigurationType.validatedDomain}>{titleForConfigType(EmailConfigurationType.validatedDomain)}</MenuItem>
                            </DropdownButton>
                        </ButtonGroup>
                        <br />
                        <br />
                        {helpTextForConfigType(this.state.configType)}
                    </Panel.Body>
                </Panel>
                {this.state.configType === EmailConfigurationType.ownSMTP ? <SMTPConfigurationComponent role={this.props.role} /> : <div />}

            </PageState>
        )
    }

}
