import * as _ from "lodash"
import * as React from "react"

import { Button, Col, ControlLabel, FormGroup, Glyphicon, Panel, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { EcommerceIntegrationConfiguration, shopifyAPIVersion, ShopifyConfiguration } from "./Models"
import { ModalPicker, ModalPickerElement } from "../../../ModalPicker"

enum TaxType {
    VAT = "vat",
    SALES_TAX = "sales_tax"
}

interface EcommerceSalesExportPanelProps {
    accountId: string
    configuration: ShopifyConfiguration
    ecommerceIntegrations: _.Dictionary<EcommerceIntegrationConfiguration>
    enabled: boolean
    openShopifyPrivateApps: () => void
    updateDatabaseUpdates: (updates: _.Dictionary<any>) => void
    updateEcommerceIntegrationId: (id: string) => void
    updateEnabled: (enabled: boolean) => void
    updateTaxType: (taxType: string) => void
    requestHeaders: () => any
}

interface EcommerceSalesExportPanelState {
    valid: boolean
    showSelectEcommerceIntegrationModal: boolean
}

export class EcommerceSalesExportPanel extends React.Component<EcommerceSalesExportPanelProps, EcommerceSalesExportPanelState> {

    constructor(props: EcommerceSalesExportPanelProps) {
        super(props)
        this.state = {
            valid: this.isConfigurationValid(props.configuration),
            showSelectEcommerceIntegrationModal: false
        }
    }

    // Helpers

    isConfigurationValid(configuration: ShopifyConfiguration) {
        return (
            configuration.taxType.length > 0 &&
            Object.keys(configuration.locationIdMap).length > 0 &&
            configuration.ecomIntegrationId.length > 0
        )
    }

    ecommerceSalesExportHelpText = (
        <div key="ecommerce_sales_export_help_text">
            Follow these steps to configure export of ecommerce sales from Ka-ching to Shopify
            <ol>
                <li>
                    Click the "Open Shopify Configuration" button above to open Shopify settings. Click the Ka-ching private app in that page.
                </li>
                <li>
                    Make sure that Ka-ching has access to create orders in Shopify. The ecommerce sales export requires 'Read and write' to 'Orders, transactions and fulfillments'.
                </li>
                <li>
                    Remember to save any changes in Shopify.
                </li>
                <li>
                    Choose tax type.
                </li>
                <li>
                    Choose ecommerce integration.
                </li>
                <li>
                    Toggle the Disabled/Enabled button below to enable the integration in Ka-ching.
                </li>
                <li>
                    Publish the Shopify configuration changes
                </li>
            </ol>
        </div>
    )

    elementsForPicker = (): ModalPickerElement[] => {
        return Object.keys(this.props.ecommerceIntegrations).map((key: string) => {
            const integration: EcommerceIntegrationConfiguration = this.props.ecommerceIntegrations[key]
            return {
                id: integration.id,
                name: integration.name
            }
        })
    }

    updateTaxSelection = (data: any) => {
        const taxTypeIds: string[] = data
        _.remove(taxTypeIds, (val) => { return val === this.props.configuration.taxType })
        if (taxTypeIds.length > 0) {
            this.props.updateTaxType(taxTypeIds[0])
        }
    }

    showSelectEcommerceIntegrationModal = () => {
        this.setState({ showSelectEcommerceIntegrationModal: true })
    }

    hideSelectEcommerceIntegrationModal = () => {
        this.setState({ showSelectEcommerceIntegrationModal: false })
    }

    ecommerceIntegrationSelected = (element: ModalPickerElement) => {
        this.props.updateEcommerceIntegrationId(element.id)
        this.hideSelectEcommerceIntegrationModal()
    }

    ecommerceIntegrationNameForId = (id: string): string => {
        const integration = this.props.ecommerceIntegrations[this.props.configuration.ecomIntegrationId]
        if (!integration || _.isNil(integration.name)) {
            return "Unknown"
        }
        return integration.name
    }

    enableButton = (enabled: boolean) => {
        return (
            <Button bsStyle={enabled ? "success" : "default"} active={enabled} onClick={() => { this.toggleEnabled() }}>
                {enabled ? "Enabled" : "Disabled"}
            </Button>
        )
    }

    toggleEnabled = () => {
        const enabled = !this.props.enabled
        this.props.updateEnabled(enabled)

        // update customer lookup config based on enabled status
        if (enabled) {
            const config = {
                delivery : {
                    parameters : {
                        headers : this.props.requestHeaders(),
                        method : "POST",
                        url : `https://${this.props.configuration.shopifyShopName}.myshopify.com/admin/api/${shopifyAPIVersion}/orders.json`
                    },
                    type : "web_hook"
                },
                filters : {
                    ecom_only : true,
                    skip_return : true,
                    skip_voided : true
                },
                name : "Shopify Ecommerce Sales Export",
                show_in_portal : false,
                transformation : {
                    parameters : {
                        api_key : this.props.configuration.shopifyAPIKey ?? null,
                        default_country_code : "dk", // TODO: Make it configurable somehow? Or somehow make the shops country code available to the export? It might not make sense to pick a default country code if across several markets the shipping address in POS doesn't ask for country.
                        ecom_id : this.props.configuration.ecomIntegrationId,
                        location_id_map : this.props.configuration.locationIdMap,
                        password : this.props.configuration.shopifyPassword ?? null,
                        shopify_id : this.props.configuration.shopifyShopName,
                        tax_type : this.props.configuration.taxType,
                        access_token : this.props.configuration.shopifyAccessToken ?? null
                    },
                    type : "shopify"
                },
                trigger : {
                    type : "realtime"
                }
              }
            
            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/export_integrations/sales/shopify`] = config
            this.props.updateDatabaseUpdates(dbUpdates)
        } else {
            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/export_integrations/sales/shopify`] = null
            this.props.updateDatabaseUpdates(dbUpdates)
        }
    } 

    // Component

    componentWillReceiveProps(newProps: EcommerceSalesExportPanelProps) {
        this.setState({ valid: this.isConfigurationValid(newProps.configuration) })
    }

    render() {
        return (
            <div>
                {
                    this.state.showSelectEcommerceIntegrationModal
                    ?
                    (
                        <ModalPicker
                            elements={this.elementsForPicker()}
                            onCancel={this.hideSelectEcommerceIntegrationModal}
                            onSelect={(element) => {
                                this.ecommerceIntegrationSelected(element)
                                this.hideSelectEcommerceIntegrationModal()
                            }}
                            title="Select e-commerce integration"
                        />
                    )
                    :
                    null
                }
                <Panel key="ecommerce_sales_export" defaultExpanded={false}>
                    <Panel.Heading>
                        <Panel.Title toggle={true}>Ecommerce sales</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <Button onClick={() => { this.props.openShopifyPrivateApps() }}>Open Shopify Configuration <Glyphicon glyph="new-window"/></Button>
                            <br/> <br/>
                            {this.ecommerceSalesExportHelpText}
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Tax type</Col>
                                <Col sm={10}>
                                    <ToggleButtonGroup
                                        type="checkbox"
                                        value={[this.props.configuration.taxType]}
                                        onChange={(data: any) => { this.updateTaxSelection(data) }}
                                    >
                                        {
                                            [TaxType.VAT, TaxType.SALES_TAX].map((taxTypeId) => {
                                                const name = taxTypeId === TaxType.VAT ? "VAT" : "Sales Tax"
                                                return <ToggleButton key={taxTypeId} value={taxTypeId}>{name}</ToggleButton>
                                            })
                                        }
                                    </ToggleButtonGroup>
                                </Col>
                            </FormGroup>
                            <br/> <br/>
                            {
                                this.props.configuration.ecomIntegrationId.length > 0
                                ?
                                (
                                    <div>
                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2}>Ecommerce integration</Col>
                                                <Col sm={10}>
                                                    {this.ecommerceIntegrationNameForId(this.props.configuration.ecomIntegrationId)}
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2} />
                                                <Col sm={10}>
                                                    <Button onClick={this.showSelectEcommerceIntegrationModal}>Change ecommerce integration</Button>
                                                </Col>
                                            </FormGroup>
                                    </div>
                                )
                                :
                                (
                                    <FormGroup>
                                        <Col componentClass={ControlLabel} sm={2}>Ecommerce integration</Col>
                                        <Col sm={10}>
                                            <Button onClick={this.showSelectEcommerceIntegrationModal}>Select ecommerce integration</Button>
                                        </Col>
                                    </FormGroup>
                                )
                            }
                            <br/> <br/>
                            {
                                this.state.valid
                                ?
                                this.enableButton(this.state.valid && this.props.enabled)
                                :
                                <Button bsStyle="danger" disabled={true}>Invalid</Button>
                            }
                            <br/> <br/>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            </div>
        )
    }
}