import * as React from "react"
import {
    Button,
    Grid,
    Panel,
    Row
} from "react-bootstrap"
import { LineItemSalesReport } from "../../reports/LineItemSalesReport"
import { PageState } from "../PageState"
import { PaymentTypeSalesReport } from "../../reports/PaymentTypeSalesReport"
import { ProductCatalogService } from "../../services/ProductCatalogService"
import { ProductStatInventoryReportBuilder } from "../../services/ProductStatInventoryReportBuilder"
import { ref } from "../../config/constants"
import { ReportComponent } from "../ReportComponent"
import { Role } from "../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom"
import { StockReportBuilder } from "../../services/StockReportBuilder"

const FileDownload = require("js-file-download")

interface ReportsProps extends RouteComponentProps<any> {
    shop: string
    role: Role
}

interface ReportsState {
    account_name?: string
    dirty: boolean
    loaded: boolean
    publishing: boolean
    shop_name?: string,
    focusedInput?: any
    reportMessage?: string
}

class Reports extends React.Component<ReportsProps, ReportsState> {

    // Props

    private productCatalogService = new ProductCatalogService()

    // Lifecycle

    constructor(props: ReportsProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false
        }
    }

    // Component

    async componentDidMount() {
        this.setState({ loaded: false })

        const accountNameSnapshot = await ref().child(`v1/account_index/${this.props.role.account_id}/name`).once("value")
        const shopNameSnapshot = await ref().child(`v1/accounts/${this.props.role.account_id}/shop_index/${this.props.shop}/name`).once("value")

        const update: any = { loaded: true }
        if (accountNameSnapshot.exists()) {
            update.account_name = accountNameSnapshot.val()
        }
        if (shopNameSnapshot.exists()) {
            update.shop_name = shopNameSnapshot.val()
        }

        this.setState(update)
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} publishing={this.state.publishing} dirty={this.state.dirty} customMessage={this.state.reportMessage} typeName="reports">
                <Panel>
                    <Panel.Heading>
                        Sales history and current inventory per product
                    </Panel.Heading>
                    <Panel.Body>
                        <Grid>
                            <Row>
                                Choose between CSV or PDF document.
                            </Row>
                            <Row>
                                The column "Est. days until out of stock" is the current stock count divided by the average daily number of items sold during the last 7 days.
                            </Row>
                            <Row>

                                <Button onClick={this.buildCSVStatsAndInventoryReport}>CSV</Button>
                                <Button onClick={this.buildPDFStatsAndInventoryReport}>PDF</Button>
                            </Row>
                        </Grid>
                    </Panel.Body>
                </Panel>

                <ReportComponent shop={this.props.shop} role={this.props.role} report={new PaymentTypeSalesReport()} />

                <ReportComponent shop={this.props.shop} role={this.props.role} report={new LineItemSalesReport()} />

                <Panel>
                    <Panel.Heading>
                        Stock report - this report extracts all your current stock values
                    </Panel.Heading>
                    <Panel.Body>
                        <Grid>
                            <Row>
                                <Button onClick={this.buildStockReport}>CSV</Button>
                            </Row>
                        </Grid>
                    </Panel.Body>
                </Panel>

                <Panel>
                    <Panel.Heading>
                        Stock event report - this report extracts the stock events for today
                    </Panel.Heading>
                    <Panel.Body>
                        <Grid>
                            <Row>
                                <Button onClick={() => this.buildStockMoveReport("all")}>All</Button>&nbsp;
                                <Button onClick={() => this.buildStockMoveReport("received")}>Received</Button>&nbsp;
                                <Button onClick={() => this.buildStockMoveReport("removal")}>Removals</Button>&nbsp;
                                <Button onClick={() => this.buildStockMoveReport("sale")}>Sales</Button>&nbsp;
                                <Button onClick={() => this.buildStockMoveReport("return")}>Returns</Button>&nbsp;
                            </Row>
                        </Grid>
                    </Panel.Body>
                </Panel>

            </PageState>
        )
    }

    // Methods

    buildCSVStatsAndInventoryReport = async () => {
        this.setState({ publishing: true })

        const service = new ProductStatInventoryReportBuilder(this.props.role.account_id, this.props.shop, this.state.account_name, this.state.shop_name)
        const report = await service.buildCSVStatsAndInventoryReport(";", ",")
        FileDownload(report, `${this.buildStatsAndInventoryReportFileName()}.csv`)

        this.setState({ publishing: false })
    }

    buildPDFStatsAndInventoryReport = async () => {
        this.setState({ publishing: true })

        const service = new ProductStatInventoryReportBuilder(this.props.role.account_id, this.props.shop, this.state.account_name, this.state.shop_name)
        await service.buildPDFStatsAndInventoryReport()

        this.setState({ publishing: false })
    }

    buildStatsAndInventoryReportFileName = (): string => {
        // just using the same string as pdf header
        const service = new ProductStatInventoryReportBuilder(this.props.role.account_id, this.props.shop, this.state.account_name, this.state.shop_name)
        return service.buildDocumentName(true)
    }

    buildStockReport = async () => {
        const message = this.stockReportMessage(0, 0)
        this.setState({ reportMessage: message })
        const builder = new StockReportBuilder(this.props.role.account_id, this.props.shop, this.productCatalogService)
        const report = await builder.buildStockReport((loaded: number, aggregated: number) => {
            this.setState({ reportMessage: this.stockReportMessage(loaded, aggregated) })
        })
        const fileName = report[1]
        FileDownload(report[0], `${fileName}.csv`)
        this.setState({ reportMessage: undefined })
    }

    buildStockMoveReport = async (type: "all" | "removal" | "received" | "sale" | "return") => {
        const message = this.stockReportMessage(0, 0)
        this.setState({ reportMessage: message })
        const builder = new StockReportBuilder(this.props.role.account_id, this.props.shop, this.productCatalogService)
        const report = await builder.buildStockMoveReport(type, (loaded: number, aggregated: number) => {
            this.setState({ reportMessage: this.stockReportMessage(loaded, aggregated) })
        })
        const fileName = report[1]
        FileDownload(report[0], `${fileName}.csv`)
        this.setState({ reportMessage: undefined })
    }

    stockReportMessage(loaded: number, aggregated: number): string {
        let result = "Generating report - "
        if (aggregated) {
            result += `created ${aggregated} lines`
        } else {
            result += `loaded ${loaded} products`
        }
        return result
    }
}

export default withRouter(Reports)
