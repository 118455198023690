import * as _ from "lodash"
import * as Actions from "./StockListActions"
import firebase from "firebase/compat"
import * as Models from "./StockListModels"
import {
    Product
    } from "../../../models/Product"
import { LanguageCode } from "../../../helpers/L10n"

export class StockListState {
    accountProducts: _.Dictionary<Product> | null = null
    loaded?: boolean
    marketId: string | null = null
    shopProducts: _.Dictionary<Product> | null = null
    stockEntries: _.Dictionary<Models.StockDBEntry> | null = null
    stockListItems?: Models.StockListItem[]
}

const initialState: StockListState = {
    accountProducts: null,
    loaded: false,
    marketId: null,
    shopProducts: null,
    stockEntries: null,
    stockListItems: undefined
}

function buildStockListItems(accountProducts: _.Dictionary<Product>, shopProducts: _.Dictionary<Product>, stockEntries: _.Dictionary<Models.StockDBEntry>): Models.StockListItem[] {
    let mergedProducts = _.merge(accountProducts, shopProducts) || {}
    // Filter products without any valid stock values out
    mergedProducts = _.pickBy(mergedProducts, (product) => {
        const productEntry = stockEntries[product.id]
        if (!productEntry) {
            return false
        }
        if (typeof productEntry === "number") {
            return true
        }
        const numberOfValidStock = _.reduce(productEntry, (prev, stock, key) => {
            const variant = product.variant(key)
            if (!variant) {
                return prev
            } else {
                return prev + 1
            }
        }, 0)
        return numberOfValidStock > 0
    })

    let nestedItems: Models.StockListItem[][] = []
    for (const productId in stockEntries) {
        const productEntry = stockEntries[productId]
        const product: Product = mergedProducts[productId]
        if (!product) {
            continue
        }

        if (typeof productEntry === "number") {
            const productName = product ? product.localizedName(LanguageCode.da) || "" : ""
            nestedItems.push([new Models.StockListItem(productId, null, productName, productEntry)])
        } else {
            const productAndVariantListItems: Models.StockListItem[] = []

            const productName = product ? product.localizedName(LanguageCode.da) || "" : ""
            productAndVariantListItems.push(new Models.StockListItem(productId, null, productName, null))

            const variantListItems = _.map(productEntry, (variantEntry, variantId) => {
                const variant = product.variant(variantId)
                let variantName = variant ? variant.localizedName(LanguageCode.da) || "" : ""
                variantName += variant && product.localizedDimensionValues(LanguageCode.da, variant) ? ", " + product.localizedDimensionValues(LanguageCode.da, variant) || "" : ""
                return new Models.StockListItem(productId, variantId, variantName, variantEntry)
            }).filter((item) => {
                // Filter items that reference non existing variants out
                if (!item.variantId) {
                    return true
                }
                return product.variant(item.variantId) !== null
            }).sort((a: Models.StockListItem, b: Models.StockListItem) => {
                return a.name < b.name ? -1 : 1
            })
            nestedItems.push(productAndVariantListItems.concat(variantListItems))
        } 
    }
    nestedItems = nestedItems.sort((a: Models.StockListItem[], b: Models.StockListItem[]) => {
        const aKeys = Object.keys(a)
        const bKeys = Object.keys(b)
        const aFirst = a[aKeys[0]]
        const bFirst = b[bKeys[0]]
        return aFirst.name < bFirst.name ? -1 : 1
    })
    return _.flatMap(nestedItems)
}

export const stockReducer = (state: StockListState = initialState, action: {type: string, payload: any}) => {
    const result = _.cloneDeep(state)
    switch (action.type) {
        case Actions.StockActionTypes.STOCK_DATA_CHANGED:
            result.stockEntries = action.payload
            break

        case Actions.StockActionTypes.FETCH_SHOP_PRODUCTS_FULFILLED:
            const shopProductsSnapshot: firebase.database.DataSnapshot = action.payload
            if (shopProductsSnapshot && shopProductsSnapshot.exists()) {
                const shopProductEntries = shopProductsSnapshot.val()
                const shopProducts = _.reduce(shopProductEntries, (aggregated, value, key) => {
                    aggregated[key] = new Product(value)
                    return aggregated
                }, {})
                result.shopProducts = shopProducts
            } else {
                result.shopProducts = {}
            }
            break

        case Actions.StockActionTypes.FETCH_ACCOUNT_PRODUCTS_FULFILLED:
            const accountProductsSnapshot: firebase.database.DataSnapshot = action.payload
            if (accountProductsSnapshot && accountProductsSnapshot.exists()) {
                const accountProductEntries = accountProductsSnapshot.val()
                const accountProducts = _.reduce(accountProductEntries, (aggregated, value, key) => {
                    aggregated[key] = new Product(value,)
                    return aggregated
                }, {})
                result.accountProducts = accountProducts
            } else {
                result.accountProducts = {}
            }
            break

        case Actions.StockActionTypes.FETCH_MARKET_FULFILLED:
            const marketSnapshot: firebase.database.DataSnapshot = action.payload
            if (marketSnapshot && marketSnapshot.exists()) {
                result.marketId = marketSnapshot.val()
            }
            break

        default:
            break
    }
    if (result.accountProducts !== null && result.shopProducts !== null && result.stockEntries !== null) {
        result.loaded = true
        result.stockListItems = buildStockListItems(result.accountProducts, result.shopProducts, result.stockEntries)
    }
    return result
}