import * as React from "react"
import { Button, Col, Form, FormGroup, Modal, Table } from "react-bootstrap"
import * as _ from "lodash"
import { Role } from "../../config/role"
import { ProductAsset } from "../../models/ProductAsset"
import { DocumentAssetEditComponent } from "../DocumentAssetEditComponent"
import { LanguageCode, L10nString } from "../../helpers/L10n"
import { LanguagePicker } from "../LanguagePicker"
import { L10nFormControl } from "../L10nFormControl"

interface ProductDocumentAssetsEditProps {
    assets: () => ProductAsset[]
    role: Role
    productId: string,
    setLanguage: (language: LanguageCode | null) => void,
    currentLanguage: () => LanguageCode | null,
    resolveLanguages: () => LanguageCode[]
    closeDialog: () => any
    updateAssets: (assets: ProductAsset[]) => void
}

export class ProductDocumentAssetsEdit extends React.Component<ProductDocumentAssetsEditProps, any> {

    closeDialog = () => {
        this.props.closeDialog()
    }

    addDocumentAsset(asset: ProductAsset) {
        const assets = _.cloneDeep(this.props.assets())
        assets.push(asset)

        this.props.updateAssets(assets)
    }

    removeDocumentAsset(index: number) {
        const assets = _.cloneDeep(this.props.assets())
        assets.splice(index, 1)
        this.props.updateAssets(assets)
    }

    updateDocumentAsset(name: L10nString, index: number) {
        const assets = _.cloneDeep(this.props.assets())
        const asset = assets[index]
        asset.name = name

        assets[index] = asset
        this.props.updateAssets(assets)
    }

    // React component

    render() {
        return (
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>
                        <span>Edit document assets</span>
                        <span style={{ float: "right" }}>
                            <LanguagePicker
                                typeName="variant"
                                resolveLanguages={() => { return this.props.resolveLanguages() }}
                                onChange={this.props.setLanguage}
                                initialLanguage={this.props.currentLanguage()}
                                hideRemove={true}
                            />
                        </span>
                    </Modal.Title>
                </Modal.Header>

                <Form horizontal={true} onSubmit={(e: any) => e.preventDefault()}>
                    <FormGroup>
                        <Col sm={12}>
                            <Table striped={true} bordered={true} condensed={true} style={{ cursor: "pointer" }} hover={true}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Document</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.assets().filter((asset) => { return asset.isDocumentAsset() }).map((asset: ProductAsset, index: any) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ "verticalAlign": "middle" }}>
                                                        <L10nFormControl
                                                            l10n={asset.name || null}
                                                            type="text"
                                                            language={this.props.currentLanguage()}
                                                            style={{ resize: "vertical" }}
                                                            onLocalizationChanged={(l10n: any) => {
                                                                this.updateDocumentAsset(l10n, index)
                                                            }}
                                                        />
                                                    </td>
                                                    <td style={{ "verticalAlign": "middle", textAlign: "center"}}>
                                                        <img key="img" src={asset.url} alt="" height="100" onClick={(event) => {event.preventDefault(); window.open(asset.url)}}/>
                                                    </td>
                                                    <td style={{ "verticalAlign": "middle", textAlign: "center", "width": "1%" }}>
                                                        <Button
                                                            bsStyle="danger"
                                                            key={index}
                                                            onClick={(event: any) => {
                                                                this.removeDocumentAsset(index)
                                                                event.stopPropagation()
                                                            }}
                                                        >
                                                            X
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    <tr>
                                        <td style={{ "verticalAlign": "middle" }}>
                                            <DocumentAssetEditComponent
                                                productId={this.props.productId}
                                                role={this.props.role}
                                                setLanguage={this.props.setLanguage}
                                                currentLanguage={this.props.currentLanguage}
                                                resolveLanguages={this.props.resolveLanguages}
                                                addAsset={(asset) => { this.addDocumentAsset(asset) }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </FormGroup>
                </Form>

                <Modal.Footer>
                    <Button onClick={this.closeDialog}>Done</Button>
                </Modal.Footer>

            </Modal.Dialog>
        )
    }
}
