import { AppliesTo } from "../AppliesToSelector";
import { CustomerCondition } from "../CustomerConditionSelector";
import { DiscountType } from "../DiscountSelector";
import { BundleRowData } from "../BundleStairStep";
import * as _ from "lodash";
import { DateComponents } from "../../../../models/RuleModels";
import { L10nString } from "../../../../helpers/L10n";
import { MarketAmount } from "../../../../models/MarketAmount";

interface Discount {
    type: DiscountType;
    value?: number | MarketAmount;
}
function parseDiscountType(value: any): Discount {
    if (value === undefined) {
        return { type: "percentage" };
    }
    if (!_.isNil(value.percentage)) {
        return { type: "percentage", value: value.percentage };
    } else if (!_.isNil(value.amount_off)) {
        return { type: "amount_off", value: new MarketAmount(value.amount_off) };
    } else if (!_.isNil(value.new_price)) {
        return { type: "new_price", value: new MarketAmount(value.new_price) };
    }
    return { type: "percentage" };
}
function parseAppliesTo(value: any): AppliesTo {
    if (value === undefined) {
        return { type: "products", products: [], tags: [], attributes: [] };
    }
    if (!_.isNil(value.products)) {
        return { type: "products", products: value.products, tags: [], attributes: [] };
    } else if (!_.isNil(value.tags)) {
        return { type: "tags", products: [], tags: value.tags, attributes: [] };
    } else if (!_.isNil(value.attributes)) {
        return {
            type: "attributes", products: [], tags: [], attributes: value.attributes.map((json: any) => {
                return {
                    attributeId: json.attribute,
                    optionId: json.option
                };
            })
        };
    }
    return { type: "products", products: [], tags: [], attributes: [] };
}
function parseCustomerCondition(value: any): CustomerCondition {
    if (value === undefined) {
        return { type: "all", customers: [], attributes: [] };
    }
    if (!_.isNil(value.all)) {
        return { type: "all", customers: [], attributes: [] };
    } else if (!_.isNil(value.members)) {
        return { type: "members", customers: [], attributes: [] };
    } else if (!_.isNil(value.customers)) {
        return { type: "customer_selection", customers: value.customers, attributes: [] };
    } else if (!_.isNil(value.attributes)) {
        return {
            type: "attributes", customers: [], attributes: value.attributes.map((json: any) => {
                return {
                    attributeId: json.attribute,
                    optionId: json.option
                };
            })
        };
    }

    return { type: "all", customers: [], attributes: [] };
}

function parseSteps(value: any): BundleRowData[] {
    if (value === undefined) {
        return [{ id: 0, appliesTo: { type: "products", products: [], tags: [], attributes: [] } }]
    }

    if (!_.isNil(value.steps)) {
        if (value.steps.length > 0) {
            let index = 0;
            const steps: BundleRowData[] = value.steps.map((step: any) => {
                const appliesTo = parseAppliesTo(step.applies_to);
                const row: BundleRowData = {
                    id: index++,
                    appliesTo: appliesTo
                };
                if (!_.isNil(step.count)) {
                    row.count = step.count
                }
                return row;
            });

            return steps
        }
    }

    return [{ id: 0, appliesTo: { type: "products", products: [], tags: [], attributes: [] } }]
}

export class BundleTemplate {
    discountType: DiscountType;
    discountAmount?: MarketAmount;
    discountPercentage?: number
    customerCondition: CustomerCondition;
    steps: BundleRowData[];
    priority?: number;
    continue_evaluation?: boolean;
    start_date?: DateComponents;
    end_date?: DateComponents;
    display_name?: L10nString;

    constructor(json: any) {
        if (!_.isNil(json.display_name)) {
            this.display_name = new L10nString(json.display_name);
        }
        // this.appliesTo = parseAppliesTo(json.applies_to);
        this.customerCondition = parseCustomerCondition(json.customer_condition);
        const steps = parseSteps(json);
        const discount = parseDiscountType(json.discount)
        this.discountType = discount.type
        if (discount.type === "percentage") {
            this.discountPercentage = discount.value as number
        } else {
            this.discountAmount = discount.value as MarketAmount
        }
        this.steps = steps;
        this.priority = !_.isNil(json.priority) ? json.priority : undefined;
        if (json.continue_evaluation === true) {
            this.continue_evaluation = true;
        }
        if (!_.isNil(json.start_date)) {
            this.start_date = json.start_date;
        }
        if (!_.isNil(json.end_date)) {
            this.end_date = json.end_date;
        }
    }

    setMarkets(allMarkets: string[]): void {
        // XXX TODO
        // for (const index in this.steps) {
        //     let step = this.steps[index];
        //     step.amount?.setMarkets(allMarkets);
        //     step.discountAmount?.setMarkets(allMarkets);
        // }
    }

    json(): any {
        const value: any = {
            display_name: this.display_name?.json() ?? new L10nString("-"),
            name: this.display_name?.localized(null) ?? "-",
            priority: this.priority ?? 0,
            customer_condition: this.customerConditionsJson()
        };
        const discount = {
            [this.discountType]: (this.discountType === "percentage") ? this.discountPercentage ?? 0 : (this.discountAmount ?? new MarketAmount(0)).json()
        };

        value.discount = discount;
        value.steps = this.stepsJson();

        if (this.continue_evaluation === true) {
            value.continue_evaluation = true;
        }
        if (!_.isNil(this.start_date)) {
            value.start_date = this.start_date;
        }
        if (!_.isNil(this.end_date)) {
            value.end_date = this.end_date;
        }

        return value;
    }

    appliesToJson(appliesTo: AppliesTo): any {
        if (appliesTo.type === "all") {
            return { all: true };
        } else if (appliesTo.type === "products") {
            return { products: appliesTo.products };
        } else if (appliesTo.type === "tags") {
            return { tags: appliesTo.tags };
        } else if (appliesTo.type === "attributes") {
            return { attributes: appliesTo.attributes.map(selection => { return { attribute: selection.attributeId, option: selection.optionId }; }) };
        }
    }

    stepsJson(): any {
        return this.steps
            .sort((a, b) => {
                return (a.count ?? 0) - (b.count ?? 0);
            })
            .map((step) => {
                return {
                    count: step.count ?? 0,
                    applies_to: this.appliesToJson(step.appliesTo)
                };
            });
    }

    customerConditionsJson(): any {
        if (this.customerCondition.type === "all") {
            return { all: true };
        } else if (this.customerCondition.type === "members") {
            return { members: true };
        } else if (this.customerCondition.type === "customer_selection") {
            return { customers: this.customerCondition.customers };
        } else if (this.customerCondition.type === "attributes") {
            return { attributes: this.customerCondition.attributes.map(selection => { return { attribute: selection.attributeId, option: selection.optionId }; }) };
        }
    }

    valid(selectedMarkets: string[]): boolean {
        return this.validateCustomerCondition() &&
            this.validateSteps() &&
            this.validateShared() &&
            this.validateDiscount()
    }

    validateAppliesTo(appliesTo: AppliesTo): boolean {
        if (appliesTo.type === "products") {
            return appliesTo.products.length !== 0;
        } else if (appliesTo.type === "tags") {
            return appliesTo.tags.length !== 0;
        } else if (appliesTo.type === "attributes") {
            return appliesTo.attributes.length !== 0;
        }
        return true;
    }

    validateCustomerCondition(): boolean {
        if (this.customerCondition.type === "attributes") {
            return this.customerCondition.attributes.length !== 0;
        } else if (this.customerCondition.type === "customer_selection") {
            return this.customerCondition.customers.length !== 0;
        }
        return true;
    }

    validateShared(): boolean {
        if (_.isNil(this.display_name)) {
            return false;
        }
        if (_.isNil(this.priority)) {
            return false;
        }

        return true;
    }

    validateDiscount(): boolean {
        if (this.discountType === "percentage") {
            if (_.isNil(this.discountPercentage)) {
                return false
            }
        } else {
            if (_.isNil(this.discountAmount)) {
                return false
            }    
        }
        return true
    }

    validateSteps(): boolean {
        if (this.steps.length === 0) {
            return false;
        }
        for (const step of this.steps) {
            if (_.isNil(step.count)) {
                return false;
            }
            if (!this.validateAppliesTo(step.appliesTo)) {
                return false
            }
        }
        return true;
    }
}
