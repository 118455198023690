import { ref } from "../config/constants"
import firebase from "firebase/compat"
import * as _ from "lodash"

type StockDBEntry = number | _.Dictionary<number>

export class StockObserver {

    // Properties

    accountId: string
    stockLocationId: string
    stockDict?: _.Dictionary<StockDBEntry>
    stockChangedCallback: () => void = () => {}

    // Lifecycle

    constructor(accountId: string, stockLocationId: string) {
        this.accountId = accountId
        this.stockLocationId = stockLocationId
        this.stockDict = undefined
    }

    // Public methods
    
    start() {
        this.observeStock()
    }
    
    stop() {
        this.stockRef().off()
    }

    // Private methods

    private stockRef() {
        return ref().child(`v1/accounts/${this.accountId}/stock_locations/${this.stockLocationId}/inventory/stock`)
    }
    
    private observeStock() {
        const stockRef = this.stockRef()
        if (!stockRef) {
            return
        }
        stockRef.on("value", (snapshot: firebase.database.DataSnapshot) => {
            if (!snapshot.exists()) { 
                this.stockDict = {}
            } else {
                this.stockDict = snapshot.val()
            }

            if (this.stockChangedCallback) {
                this.stockChangedCallback()
            }
        })
    }
}