import * as React from "react"
import { FormControl, InputGroup } from "react-bootstrap"
import { injectIntl, InjectedIntlProps } from "react-intl"

interface DecimalValueFormControlProps {
    value: number | null
    onValueChanged: (value: number | null) => void
    type?: any
    decimals: number
    componentClass?: any
    placeholder?: string
    prefixSymbol: string
}

interface DecimalValueFormControlState {
    value: number | null
    focus: boolean
    textValue: string
}

class DecimalValueFormControlWrapped extends React.Component<DecimalValueFormControlProps & InjectedIntlProps, DecimalValueFormControlState> {
    constructor(props: DecimalValueFormControlProps & InjectedIntlProps) {
        super(props)
        this.state = {
            value: props.value,
            focus: false,
            textValue: props.value ? `${props.value}` : ""
        }
    }

    componentWillReceiveProps(nextProps: DecimalValueFormControlProps) {
        const state: any = { value: nextProps.value }
        // Override textValue when value changes
        if (nextProps.value !== this.state.value) {
            state.textValue = nextProps.value ? `${nextProps.value}` : ""
        }

        this.setState(state)
    }

    handleInputChange = (event: any) => {
        const target = event.target
        let value = target.value as string
        value = value.replace(/[^0-9.,]/g, "")
        value = value.replace(/^([^.,]*[.,])(.*)$/, function (a, b, c) {
            return b + c.replace(/[.,]/g, "")
        })

        this.setState({ textValue: value })

        value = value.replace(/,/g, ".")

        this.updateValue(value)
    }

    private updateValue(value: string) {
        const numberValue = (value === "") ? null : Number(value)
        this.setState({ value: numberValue }, () => {
            this.props.onValueChanged(numberValue)
        })
    }

    placeholder(): string {
        if (this.props.placeholder) {
            return this.props.placeholder
        }
        return ""
    }

    valueString(): string {
        if (this.state.focus) {
            return this.state.textValue
        }
        if (this.state.value) {
            return this.props.intl.formatNumber(this.state.value, { useGrouping: true, minimumFractionDigits: this.props.decimals, maximumFractionDigits: this.props.decimals})
        }
        return ""
    }

    onBlur() {
        this.setState({ focus: false })
    }

    render() {
        return (
            <InputGroup>
                <InputGroup.Addon>{this.props.prefixSymbol}</InputGroup.Addon>
                <FormControl
                    onBlur={() => { this.onBlur() }}
                    onFocus={() => { this.setState({ focus: true }) }}
                    type={this.props.type || "text"}
                    componentClass={this.props.componentClass}
                    className="currency"
                    value={this.valueString()}
                    placeholder={this.placeholder()}
                    onChange={this.handleInputChange}
                    autoComplete="off"
                />
            </InputGroup>
        )
    }
}

const DecimalValueFormControl = injectIntl(DecimalValueFormControlWrapped)

export { DecimalValueFormControl }