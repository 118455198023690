import * as React from "react"
import { FirebaseStorageDropzoneComponent } from "../FirebaseStorageDropzoneComponent"
import { Button, Col, ControlLabel, FormGroup, ToggleButton, ToggleButtonGroup, Row, Modal } from "react-bootstrap"
import { token } from "../../helpers/auth"
import { ref } from "../../config/constants"
import { Role } from "../../config/role"
import { PageState } from "../PageState"
import { Globals } from "../../helpers/globals"
import { Market, Channel } from "../../models/MarketModels"
import { CSVDropzoneComponent } from "../CSVDropzoneComponent"

const pushid = require("pushid")

interface ProductImportProps {
    role: Role
}

interface ProductImportState {
    channels: Channel[]
    sendFile?: () => void
    loaded: boolean
    markets: Market[]
    myDropzoneComponentKey: number
    selectedChannels: string[]
    selectedMarkets: string[]
    showEmptyMetadataWarning: boolean
    token: string
    errorMessage?: string
    successMessage?: string
}

export default class ProductImport extends React.Component<ProductImportProps, ProductImportState> {

    constructor(props: ProductImportProps) {
        super(props)
        this.state = {
            channels: [],
            sendFile: undefined,
            loaded: false,
            markets: [],
            myDropzoneComponentKey: 1,
            selectedChannels: [],
            selectedMarkets: [],
            showEmptyMetadataWarning: false,
            token: ""
        }
    }

    async componentDidMount() {
        const tokenValue: string | null = await token()
        if (tokenValue !== null) {
            this.setState({ token: tokenValue })
        }
        const [markets, channels] = await Promise.all([Globals.shared.getMarkets(), Globals.shared.getChannels()])
        const selectedMarkets: string[] = []
        if (markets.length === 1) {
            selectedMarkets.push(markets[0].id)
        } else {
            const indexOfDK = markets.findIndex((market: Market) => {
                return market.id === "dk"
            })
            if (indexOfDK >= 0) {
                selectedMarkets.push(markets[indexOfDK].id)
            }
        }
        const selectedChannels = []
        if (channels.length === 1) {
            selectedChannels.push(channels[0].id)
        } else {
            const indexOfPOS = channels.findIndex((channel: Channel) => {
                return channel.id === "pos"
            })
            if (indexOfPOS >= 0) {
                selectedChannels.push(channels[indexOfPOS].id)
            }
        }
        this.setState({ channels: channels, markets: markets, loaded: true, selectedMarkets: selectedMarkets, selectedChannels: selectedChannels })
    }

    djsConfig = () => {
        return {
            autoProcessQueue: true,
            headers: {
                "Content-Type": "application/octet-stream",
                "Authorization": "Bearer " + this.state.token
            },
            dictDefaultMessage: "Add CSV file containing products here."
        }
    }

    componentConfig = () => {
        let queryString = "?"
        if (this.state.selectedChannels.length > 0) {
            queryString += this.state.selectedChannels.map(this.channelQueryParameter).join("&")
        }
        if (this.state.selectedMarkets.length > 0) {
            queryString += queryString.length > 1 ? "&" : ""
            queryString += this.state.selectedMarkets.map(this.marketQueryParameter).join("&")
        }
        let url = process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE + "/app/csv_importer/" + this.props.role.account_id
        if (queryString.length > 1) {
            url += queryString
        } else {
            url += "/"
        }
        return {
            iconFiletypes: [".csv"],
            showFiletypeIcon: true,
            postUrl: url
        }
    }

    eventHandlers = () => {
        return {
            addedfile: (file: any) => {
                console.log(file)
                this.setState({ errorMessage: undefined, successMessage: undefined })
            },
            success: async (file: any, message: any, e: any) => {
                const response = JSON.parse(file.xhr.response)
                const state: any = { errorMessage: undefined, successMessage: undefined }

                if (response.failure_count !== undefined && response.failure_count > 0) {
                    const totalCount = (response.success_count || 0) + (response.failure_count || 0)
                    const messageLines: string[] = []
                    messageLines.push(`Import failed for ${response.failure_count} out of ${totalCount} products`)
                    messageLines.push("")
                    for (const failure of response.failures || []) {
                        const product = failure.product
                        const errorMessage = failure.error
                        messageLines.push("Failed import:")
                        messageLines.push(JSON.stringify(product, null, 2))
                        messageLines.push(errorMessage)
                        messageLines.push("")
                    }

                    state.errorMessage = messageLines.join("\n")
                }

                if ((response.success_count ?? 0) > 0) {
                    state.successMessage = `Successfully imported ${response.success_count} products`
                }
                this.setState(state)

            },
            error: (file: any, message: any) => {
                const state: any = { errorMessage: undefined, successMessage: undefined }
                if (typeof message !== "string" && message.error) {
                    message = message.error;
                }
                if (file.previewElement) {
                    file.previewElement.classList.add("dz-error");
                    for (let node of file.previewElement.querySelectorAll(
                        "[data-dz-errormessage]"
                    )) {
                        node.textContent = message
                    }
                }
                const messageLines: string[] = []
                messageLines.push(`Import failed:`)
                messageLines.push(message)
                state.errorMessage = messageLines.join("\n")

                this.setState(state)
            },
        }
    }

    imgComponentConfig = () => {
        const account = this.props.role.account_id
        return {
            iconFiletypes: [".jpg", ".png"],
            showFiletypeIcon: true,
            postUrl: "dummy",
            postPath: `${account}/public/products/`
        }
    }

    imgDjsConfig = {
        autoProcessQueue: true,
        maxFiles: 10,
        parallelUploads: 1,
        dictDefaultMessage: "Add product images here. Filename must be same as product id/key.",
        accept: (file: any, done: (result: any) => void) => {
            console.log("ACCEPT", file)

            file.acceptDimensions = done
            file.rejectDimensions = function (f: any) {
                alert("Invalid dimensions: " + f.width + "x" + f.height + ". Max size is 2048x2048.")
                done("Invalid dimension: " + f.width + "x" + f.height + ". Max size is 2048x2048.")
            }
        }
    }

    imgEventHandlers = () => {
        let dropzoneComponent: any
        return {
            init: (component: any) => {
                dropzoneComponent = component
            },
            addedfile: (file: any) => console.log(file),

            success: async (file: any, message: any, e: any) => {
                const key = file.name.split(".")[0]

                const account = this.props.role.account_id
                const urlRef = ref().child(`v1/accounts/${account}/inventory/product_repo/${key}`)
                const snapshot = await urlRef.once("value")
                if (snapshot.exists()) {
                    await urlRef.child(`product/image_url`).set(e)
                } else {
                    console.log("Product does not exist: " + urlRef)
                }
            },
            thumbnail: async (file: any) => {
                if (file.accepted !== false) {
                    const validated = file.width <= 2048 && file.height <= 2048
                    if (validated !== true) {
                        dropzoneComponent.removeFile(file)
                        file.rejectDimensions(file)
                    } else {
                        const account = this.props.role.account_id
                        const key = file.name.split(".")[0]
                        const urlRef = ref().child(`v1/accounts/${account}/inventory/product_repo/${key}`)
                        const snapshot = await urlRef.once("value")
                        if (snapshot.exists()) {
                            file.nameForUpload = `${key}/images/${pushid()}`
                            file.acceptDimensions()
                        } else {
                            file.acceptDimensions("Product does not exist: " + key)
                        }
                    }
                }
            }
        }
    }

    submitRequest = (xhr: any, formData: any, files: any) => {
        if (this.state.selectedChannels.length === 0 || this.state.selectedMarkets.length === 0) {
            const sendFile = () => {
                xhr.send(files[0])
            }
            this.setState({ showEmptyMetadataWarning: true, sendFile: sendFile })
        } else {
            xhr.send(files[0])
        }
    }

    onWarningCancel = () => {
        this.setState({ showEmptyMetadataWarning: false, myDropzoneComponentKey: this.state.myDropzoneComponentKey + 1 })
    }

    onWarningOK = () => {
        if (this.state.sendFile) {
            this.state.sendFile()
        }
        this.setState({ showEmptyMetadataWarning: false })
    }

    marketsUpdated = (data: any) => {
        const markets: string[] = Object.values(data)
        this.setState({ selectedMarkets: markets })
    }

    channelsUpdated = (data: any) => {
        const channels: string[] = Object.values(data)
        this.setState({ selectedChannels: channels })
    }

    channelQueryParameter = (channelId: string) => {
        return "channels=" + channelId
    }

    marketQueryParameter = (marketId: string) => {
        return "markets=" + marketId
    }

    render() {
        return (
            <PageState dirty={false} loading={!this.state.loaded} typeName="product import">
                <div>
                    <h1>Product import</h1>
                    <p>Products may be imported to the Ka-ching system using the CSV format.</p>
                    <p>Here are a few samples ranging from simple products without variants to products with variants and dimensions</p>
                    <p>
                        <a href="https://developers.ka-ching.dk/csv-import-resources/simple.csv" download="simple.csv">simple.csv</a><br />
                        <a href="https://developers.ka-ching.dk/csv-import-resources/advanced.csv" download="advanced.csv">advanced.csv</a><br />
                        <a href="https://developers.ka-ching.dk/csv-import-resources/variants_and_dimensions.csv" download="variants_and_dimensions.csv">variants_and_dimensions.csv</a><br />
                    </p>
                    <p>For further reference, please see: <a href="https://developers.ka-ching.dk/products-csv-import/">https://developers.ka-ching.dk/products-csv-import/</a></p><br />
                    <div>
                        {
                            this.state.markets.length > 1 ? (
                                <FormGroup>
                                    <Row>
                                        <Col componentClass={ControlLabel} sm={2}>Markets</Col>
                                        <Col sm={10}>
                                            <ToggleButtonGroup
                                                type="checkbox"
                                                value={Object.values(this.state.selectedMarkets)}
                                                onChange={(data: any) => { this.marketsUpdated(data) }}
                                            >
                                                {
                                                    this.state.markets.map((market) => {
                                                        return <ToggleButton key={market.id} value={market.id}>{market.name}</ToggleButton>
                                                    })
                                                }
                                            </ToggleButtonGroup>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            ) : null
                        }

                        {
                            this.state.channels.length > 1 ? (
                                <FormGroup>
                                    <Row>
                                        <Col componentClass={ControlLabel} sm={2}>Channels</Col>
                                        <Col sm={10}>
                                            <ToggleButtonGroup
                                                type="checkbox"
                                                value={Object.values(this.state.selectedChannels)}
                                                onChange={(data: any) => { this.channelsUpdated(data) }}
                                            >
                                                {
                                                    this.state.channels.map((channel) => {
                                                        return <ToggleButton key={channel.id} value={channel.id}>{channel.name}</ToggleButton>
                                                    })
                                                }
                                            </ToggleButtonGroup>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            ) : null
                        }

                        <br />
                        <strong>Upload products CSV</strong>
                        <CSVDropzoneComponent
                            key={this.state.myDropzoneComponentKey}
                            config={this.componentConfig()}
                            eventHandlers={this.eventHandlers()}
                            djsConfig={this.djsConfig()}
                            submitRequest={this.submitRequest}
                        />
                        <br />
                        {this.state.errorMessage ? <pre style={{ color: "#990000" }}>{this.state.errorMessage}</pre> : null}
                        {this.state.successMessage ? <pre style={{ color: "#009900" }}>{this.state.successMessage}</pre> : null}
                        <br />
                        <strong>Batch upload images</strong>
                        <FirebaseStorageDropzoneComponent
                            config={this.imgComponentConfig()}
                            eventHandlers={this.imgEventHandlers()}
                            djsConfig={this.imgDjsConfig}
                            isPublic={true}
                        />

                    </div>
                </div>
                {
                    this.state.showEmptyMetadataWarning ? (
                        <Modal.Dialog key="showEmptyMetadataWarning">
                            <Modal.Header>Warning</Modal.Header>
                            <Modal.Body>You haven't selected markets or channels for the products to be published. Are you sure you want proceed with the import?</Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => { this.onWarningCancel() }}>Cancel</Button>
                                <Button bsStyle="success" onClick={async () => { this.onWarningOK() }}>OK</Button>
                            </Modal.Footer>
                        </Modal.Dialog>
                    ) : null
                }
            </PageState>
        )
    }
}
