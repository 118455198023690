import * as React from "react"
import { Image } from "react-bootstrap"

export interface ThumbnailProps {
    src?: string
}

export function Thumbnail(props: ThumbnailProps) {
    if (props.src === undefined) {
        return <div className="thumbnailMissing" />
    } else {
        return <div className="thumbnail"><Image onError={ev => { (ev.target as any).style.display = "none" }} src={props.src} /> </div>
    }
}