import React from "react"
import { Form } from "react-bootstrap"
import { AttributeObserver } from "../../../../helpers/attributeObserver";
import { ProductObserver } from "../../../../helpers/productObserver";
import { TagObserver } from "../../../../helpers/tagObserver";
import { Market } from "../../../../models/MarketModels";
import { AppliesTo, AppliesToSelectorPanel } from "../AppliesToSelector";
import { CustomerCondition, CustomerConditionSelector } from "../CustomerConditionSelector";
import { DiscountSelector, DiscountType } from "../DiscountSelector";
import { MinimumRequirementsSelector, RequirementsType } from "../MinimumRequirementsSelector";
import { Stair } from "../Stair";
import { RowData } from "../StairStep";
import { Description } from "./CustomizableFormDescription";
import * as _ from "lodash"
import { DateComponents, RuleModel } from "../../../../models/RuleModels";
import { L10nString, LanguageCode } from "../../../../helpers/L10n";
import { CustomizableTemplate } from "./Model";
import { SharedPropertiesSelector } from "../SharedProperties";
import { AdvancedPropertiesSelector } from "../AdvancedProperties";

export interface CustomizableFormProps {
    market: Market | null
    markets: string[]
    currentLanguage: () => LanguageCode | null
    productObserver: ProductObserver
    tagObserver: TagObserver
    attributeObserver: AttributeObserver
    customerAttributeObserver: AttributeObserver
    template: CustomizableTemplate
    onTemplateChanged: (template: RuleModel) => void
}

export function CustomizableForm(props: CustomizableFormProps) {

    function setDisplayName(name?: L10nString) {
        if (_.isEqual(props.template.display_name, name)) {
            return
        }
        const clone = _.clone(props.template)
        clone.display_name = name
        props.onTemplateChanged(clone)
    }

    function setDiscountType(type: DiscountType) {
        if (props.template.discountType === type) {
            return
        }
        const clone = _.clone(props.template)
        clone.discountType = type
        props.onTemplateChanged(clone)
    }

    function setRequirementsType(type: RequirementsType) {
        if (props.template.requirementsType === type) {
            return
        }
        const clone = _.clone(props.template)
        clone.requirementsType = type
        props.onTemplateChanged(clone)
    }

    function setAppliesTo(appliesTo: AppliesTo) {
        if (_.isEqual(props.template.appliesTo, appliesTo)) {
            return
        }
        const clone = _.clone(props.template)
        clone.appliesTo = appliesTo
        props.onTemplateChanged(clone)
    }

    function setSteps(steps: RowData[]) {
        if (_.isEqual(props.template.steps, steps)) {
            return
        }
        const clone = _.clone(props.template)
        clone.steps = steps
        props.onTemplateChanged(clone)
    }

    function setCustomerCondition(condition: CustomerCondition) {
        if (_.isEqual(props.template.customerCondition, condition)) {
            return
        }
        const clone = _.clone(props.template)
        clone.customerCondition = condition
        props.onTemplateChanged(clone)
    }

    function setPriority(priority: number | undefined) {
        if (props.template.priority === priority) {
            return
        }
        const clone = _.clone(props.template)
        clone.priority = priority
        props.onTemplateChanged(clone)
    }

    function setStartDate(date?: DateComponents) {
        if (_.isEqual(props.template.start_date, date)) {
            return
        }
        const clone = _.clone(props.template)
        clone.start_date = date
        props.onTemplateChanged(clone)
    }

    function setEndDate(date?: DateComponents) {
        if (_.isEqual(props.template.end_date, date)) {
            return
        }
        const clone = _.clone(props.template)
        clone.end_date = date
        props.onTemplateChanged(clone)
    }

    function setContinueEvaluation(continueEvaluation?: boolean) {
        if (props.template.continue_evaluation === continueEvaluation) {
            return
        }
        const clone = _.clone(props.template)
        clone.continue_evaluation = continueEvaluation
        props.onTemplateChanged(clone)
    }

    return <Form horizontal={true} onSubmit={e => e.preventDefault()}>
        <SharedPropertiesSelector validation={props.template.validateShared()} currentLanguage={props.currentLanguage} displayName={props.template.display_name} priority={props.template.priority} updateSharedProps={(displayName, priority) => { setDisplayName(displayName); setPriority(priority) }} />
        <DiscountSelector markets={props.markets} validation={true} type={props.template.discountType} typeChanged={(type) => { setDiscountType(type) }} />
        <MinimumRequirementsSelector type={props.template.requirementsType} typeChanged={(type) => { setRequirementsType(type) }} />
        <Stair markets={props.markets} validation={props.template.validateSteps()} discountType={props.template.discountType} requirementsType={props.template.requirementsType} market={props.market} rows={props.template.steps} rowsChanged={(rows) => { setSteps(rows) }} />
        <AppliesToSelectorPanel validation={props.template.validateAppliesTo()} productObserver={props.productObserver} tagsObserver={props.tagObserver} attributesObserver={props.attributeObserver} appliesTo={props.template.appliesTo} appliesToChanged={(appliesTo) => { setAppliesTo(appliesTo) }} />
        <CustomerConditionSelector validation={props.template.validateCustomerCondition()} attributesObserver={props.customerAttributeObserver} condition={props.template.customerCondition} conditionChanged={(condition) => { setCustomerCondition(condition) }} />
        <AdvancedPropertiesSelector continueEvaluation={props.template.continue_evaluation} startDate={props.template.start_date} endDate={props.template.end_date} updateAdvancedProps={(startDate, endDate, continueEvaluation) => { setStartDate(startDate); setEndDate(endDate); setContinueEvaluation(continueEvaluation) }} />
        <Description validation={props.template.valid([])} formProps={props} customerCondition={props.template.customerCondition} market={props.market} discountType={props.template.discountType} requirementsType={props.template.requirementsType} steps={props.template.steps} appliesTo={props.template.appliesTo} />
    </Form>
}


