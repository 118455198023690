import * as React from "react"
import { isNil } from "lodash"
import { Button, Col, Row } from "react-bootstrap"
import DocumentDropControl from "./DocumentDropControl"
import { Role } from "../config/role"
import { ProductAsset, documentAssetMimeTypeFromFile, ProductAssetMimeType } from "../models/ProductAsset"
import { L10nFormControl } from "./L10nFormControl"
import { L10nString, LanguageCode } from "../helpers/L10n"

interface DocumentAssetEditComponentProps {
    productId: string,
    role: Role,
    setLanguage: (language: LanguageCode | null) => void,
    currentLanguage: () => LanguageCode | null,
    resolveLanguages: () => LanguageCode[]
    addAsset: (asset: ProductAsset) => void
}

interface DocumentAssetEditComponentState {
    name: L10nString | null
    url: string | null
    mimeType: ProductAssetMimeType | null
}

const pushid = require("pushid")

export class DocumentAssetEditComponent extends React.Component<DocumentAssetEditComponentProps, DocumentAssetEditComponentState> {

    initialState: DocumentAssetEditComponentState = {
        name: null,
        url: null,
        mimeType: null,
    }

    // BG: Couldn't find a way to reset the dropzone component.
    // Incremented on succesfull image upload to make react think that we have a new dropzone component.
    dropzoneComponentKey = 1

    constructor(props: DocumentAssetEditComponentProps) {
        super(props)

        this.state = this.initialState
    }

    isAddDisabled(): boolean {
        return isNil(this.state.name) || isNil(this.state.url) || isNil(this.state.mimeType)
    }

    handleUpload(url: string, file: any) {
        const mimeType = documentAssetMimeTypeFromFile(file)
        if (isNil(url) || isNil(mimeType)) {
            this.setState({ url: null, mimeType: null })
        } else {
            this.setState({
                url: url,
                mimeType: mimeType
            })
        }
    }

    handleNameChange(name: L10nString | null) {
        this.setState({ name: name })
    }

    addAsset() {
        if (isNil(this.state.name) || isNil(this.state.url) || isNil(this.state.mimeType)) {
            return
        }

        this.dropzoneComponentKey += 1
        this.setState(this.initialState)

        const asset = new ProductAsset(this.state.url, this.state.mimeType)
        asset.name = this.state.name

        this.props.addAsset(asset)
    }

    render() {
        return (
            <div>
                <Row>
                    <Col sm={10}>
                        <DocumentDropControl
                            key={this.dropzoneComponentKey}
                            fileName={pushid()}
                            filePath={this.props.role.account_id + "/public/products/" + this.props.productId + "/assets/"}
                            dropTitle="Upload document"
                            isPublic={true}
                            deleteAction={() => { /* dummy */ }}
                            uploadAction={(url, file) => { this.handleUpload(url, file) }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={10}>
                        <L10nFormControl
                            l10n={this.state.name}
                            type="text"
                            language={this.props.currentLanguage()}
                            style={{ resize: "vertical" }}
                            onLocalizationChanged={(l10n) => { this.handleNameChange(l10n) }}
                        />
                    </Col>
                    <Col sm={2}>
                        <Button
                            bsStyle="success"
                            disabled={this.isAddDisabled()}
                            onClick={() => { this.addAsset() }}
                        >Add
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }
}
