import * as _ from "lodash"
import * as React from "react"

import { Button, Col, ControlLabel, DropdownButton, FormControl, FormGroup, MenuItem, Modal, Panel, Row } from "react-bootstrap"
import { ref } from "../../../config/constants"
import { PageState } from "../../PageState"
import { Role } from "../../../config/role"
import { StripedTable } from "../../StripedTable"
import { allShortcuts, ShortcutItem, shortcutName } from "../ConfigurableFrontPage/FrontPageSectionModels"
import { L10nString, LanguageCode } from "../../../helpers/L10n"
import { L10nFormControl } from "../../L10nFormControl"
import { LanguagePicker } from "../../LanguagePicker"

export interface CashierRole {
    id: string
    name: L10nString
    functionalityBlocklist: ShortcutItem[]
}

interface CashierRoleEditProps {
    cashierRole: CashierRole
    save: (role: CashierRole) => void
    close: () => void
    currentLanguage?: LanguageCode
}

interface CashierRoleEditState {
    name: L10nString
    id: string
    blocklist: ShortcutItem[]
    itemMap: any
    currentLanguage: LanguageCode | null
}

class CashierRoleEdit extends React.Component<CashierRoleEditProps, CashierRoleEditState> {
    constructor(props: CashierRoleEditProps) {
        super(props)

        this.state = {
            name: props.cashierRole.name,
            id: props.cashierRole.id,
            blocklist: _.cloneDeep(props.cashierRole.functionalityBlocklist),
            itemMap: {},
            currentLanguage: props.currentLanguage ?? null
        }
    }

    resolveLanguages = (): LanguageCode[] => {
        return this.state.name.localizations()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })
    }

    removeLanguage = (language: LanguageCode) => {
        const name = _.cloneDeep(this.state.name)
        name.removeLocalization(language)
        this.setState({ name: name, currentLanguage: null })
    }
    async componentDidMount() {
        const itemMap: any = {}
        for (const item of allShortcuts) {
            itemMap[item] = this.shortcutName(item)
        }
        this.setState({ itemMap: itemMap })
    }

    handleInputChange = (l10n: L10nString | null) => {
        this.setState({ name: l10n ?? new L10nString("") })
    }

    isNew(): boolean {
        return this.props.cashierRole.id === ""
    }

    shortcutName(item: ShortcutItem) {
        if (item === "basket_comment") {
            return "Add comments"
        } else if (item === "basket_discount") {
            return "Add discounts"
        } else {
            return shortcutName(item)
        }
    }

    available(): any {
        const availableMap: any = {}
        for (const item in this.state.itemMap) {
            if (!this.state.blocklist.includes(item as ShortcutItem)) {
                availableMap[item] = this.shortcutName(item as ShortcutItem)
            }
        }
        return availableMap
    }

    handleChange = (value: ShortcutItem, index: number) => {
        const newAvailable = this.state.blocklist
        newAvailable[index] = value

        this.setState({ blocklist: newAvailable })
    }

    removeBlockedItem = (index: number) => {
        const blocklist = this.state.blocklist
        blocklist.splice(index, 1)

        this.setState({ blocklist: blocklist })
    }

    addBlockedItem = () => {
        const available = this.available()
        if (Object.keys(available).length === 0) {
            return
        }
        const blocklist = this.state.blocklist
        blocklist.push(Object.keys(available)[0] as ShortcutItem)

        this.setState({ blocklist: blocklist })
    }

    render() {
        return (
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>
                        <span>Edit role</span>
                        <span style={{ float: "right" }}>
                            {/* <LanguagePicker
                                typeName="variant"
                                resolveLanguages={() => { return this.resolveLanguages() }}
                                onChange={this.setLanguage}
                                initialLanguage={this.currentLanguage()}
                                hideRemove={true}
                            /> */}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <Col smOffset={8} sm={4} className="text-right">
                            <LanguagePicker
                                typeName="cashier role"
                                initialLanguage={this.state.currentLanguage}
                                resolveLanguages={this.resolveLanguages}
                                onChange={this.setLanguage}
                                onRemove={this.removeLanguage}
                            />
                        </Col>
                    </FormGroup>
                    <br /><br />
                    <FormGroup>
                        <Row>
                            <Col componentClass={ControlLabel} sm={2}>Id</Col>
                            <Col sm={10}>
                                <FormControl
                                    value={this.state.id}
                                    placeholder="Enter id"
                                    onChange={value => { this.setState({ id: (value.target as any).value }) }}
                                    disabled={!this.isNew()}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col componentClass={ControlLabel} sm={2}>Name</Col>
                            <Col sm={10}>
                                <L10nFormControl
                                    l10n={this.state.name}
                                    placeholder="Enter localized name"
                                    language={this.state.currentLanguage}
                                    onLocalizationChanged={l10n => { this.handleInputChange(l10n) }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col componentClass={ControlLabel} sm={2}>Blocked commands</Col>
                            <Col sm={10}>

                                <StripedTable>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.blocklist.map((item, itemIndex) => {
                                                return (
                                                    <tr key={itemIndex} >
                                                        <td>
                                                            <DropdownButton
                                                                title={this.state.itemMap[item] ?? "-"}
                                                                key={item}
                                                                id={`dropdown-basic-${item}`}
                                                            >
                                                                {
                                                                    Object.keys(this.available()).map((key, allItemsIndex) => {
                                                                        return (
                                                                            <MenuItem
                                                                                key={allItemsIndex}
                                                                                onClick={(event) => { this.handleChange(key as any, itemIndex); event.stopPropagation() }}
                                                                            >
                                                                                {this.state.itemMap[key]}
                                                                            </MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </DropdownButton>
                                                        </td>
                                                        <td className="narrow">
                                                            <Button bsStyle="danger" onClick={(event) => { this.removeBlockedItem(itemIndex); event.stopPropagation() }}>X</Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </StripedTable>
                                <Button onClick={() => { this.addBlockedItem() }}>Add command to block</Button>
                            </Col>
                        </Row>

                    </FormGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { this.props.close() }} disabled={false}>Cancel</Button>
                    <Button onClick={() => { this.props.save({ id: this.state.id, name: this.state.name, functionalityBlocklist: this.state.blocklist }) }} disabled={false}>Done</Button>
                </Modal.Footer>
            </Modal.Dialog>
        )
    }
}

interface CashierRolesProps {
    role: Role
}

interface CashierRolesState {
    roles: CashierRole[]
    dirty: boolean
    loaded: boolean
    publishing: boolean
    editedRoleId?: string
    currentLanguage: LanguageCode | null
}

export default class CashierRoles extends React.Component<CashierRolesProps, CashierRolesState> {
    constructor(props: CashierRolesProps) {
        super(props)

        this.state = {
            roles: [],
            dirty: false,
            loaded: false,
            publishing: false,
            currentLanguage: null
        }
    }

    closeModal() {
        this.setState({ editedRoleId: undefined })
    }

    saveRole(role: CashierRole) {
        const roles = _.cloneDeep(this.state.roles)
        const index = roles.findIndex(entry => { return entry.id === role.id })
        if (index < 0) {
            roles.push(role)
        } else {
            roles[index] = role
        }
        this.setState({ editedRoleId: undefined, roles: roles, dirty: true })
    }

    async componentDidMount() {
        const cashierRolesRef = this.cashierRolesRef()
        const rolesSnap = await cashierRolesRef.once("value")
        const roles: CashierRole[] = []
        if (rolesSnap && rolesSnap.exists()) {
            const rolesDict = rolesSnap.val()
            for (const key in rolesDict) {
                const roleJson = rolesDict[key]
                if (roleJson.id && roleJson.name) {
                    roles.push({
                        id: roleJson.id,
                        name: new L10nString(roleJson.name),
                        functionalityBlocklist: roleJson.functionality_blocklist ?? []
                    })
                }
            }
        }
        this.setState({ roles: roles, loaded: true })
    }

    editedRole(roleId: string): CashierRole {
        const role = this.state.roles.find(roleEntry => { return roleEntry.id === roleId })
        return role ?? { id: roleId, name: new L10nString(""), functionalityBlocklist: [] }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="cashier roles" submit_action={() => { this.publish() }}>
                <Panel key="upper_panel">
                    <Panel.Heading>
                        <Panel.Title>Cashier roles</Panel.Title>
                    </Panel.Heading>

                    <Panel>
                        <Panel.Heading>
                            <Button bsStyle="primary" onClick={(event) => { this.addCashierRole() }}>Add new cashier role</Button>
                        </Panel.Heading>
                    </Panel>

                    <Panel.Body>
                        <StripedTable>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.roles.map((role) => {
                                        return (
                                            <tr key={role.id} onClick={() => { this.setState({ editedRoleId: role.id }) }}>
                                                <td>{role.id}</td>
                                                <td>{role.name.localized(LanguageCode.da)}</td>
                                                <td className="narrow">
                                                    <Button bsStyle="danger" onClick={(event) => { this.removeCashierRole(role.id); event.stopPropagation() }}>X</Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </StripedTable>
                    </Panel.Body>
                </Panel >
                {!_.isNil(this.state.editedRoleId) && <CashierRoleEdit cashierRole={this.editedRole(this.state.editedRoleId)} save={role => { this.saveRole(role) }} close={() => { this.closeModal() }} />}
            </PageState>
        )
    }

    removeCashierRole = (id: string) => {
        const roles = _.cloneDeep(this.state.roles)
        _.remove(roles, role => { return role.id === id })

        this.setState({ roles: roles, dirty: true })
    }

    addCashierRole = () => {
        this.setState({ editedRoleId: "" })
    }

    cashierRolesRef() {
        const account = this.props.role.account_id
        return ref().child(`v1/accounts/${account}/inventory/cashier_roles`)
    }

    publish = async () => {
        const cashierRolesRef = this.cashierRolesRef()

        this.setState({ publishing: true })
        const json: any = {}
        for (const role of this.state.roles) {
            json[role.id] = { id: role.id, name: role.name.json(), functionality_blocklist: role.functionalityBlocklist }
        }
        await cashierRolesRef
            .set(json)
            .then(() => {
                this.setState({ publishing: false, dirty: false })
            })
    }
}
