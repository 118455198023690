import * as React from "react"
import { ref } from "../../config/constants"
import { Col, ControlLabel, FormGroup, DropdownButton, MenuItem } from "react-bootstrap"
import { Tag } from "../../models/Product"
import { Dictionary, isNil, cloneDeep } from "lodash"
import { LanguageCode } from "../../helpers/L10n"
import { StripedTable } from "../StripedTable"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"

interface TagSelectionProps {
    account: string
    selectedTags: Dictionary<boolean>
    currentLanguage: LanguageCode | null
    onChange: (tags: Dictionary<boolean>) => void
}

interface TagSelectionState {
    tags: Dictionary<Tag>
    selectedTags: Dictionary<boolean>
}

export class TagSelection extends React.Component<TagSelectionProps, TagSelectionState> {
    constructor(props: TagSelectionProps) {
        super(props)

        this.state = {
            selectedTags: {},
            tags: {}
        }
    }

    onChange(data: any) {
        const tags = {}
        for (const key in data) {
            tags[data[key]] = true
        }

        this.props.onChange(tags)
    }

    componentWillReceiveProps(nextProps: TagSelectionProps) {
        this.setState({ selectedTags: nextProps.selectedTags || [] })
    }

    async componentDidMount() {
        this.setState({ selectedTags: this.props.selectedTags || {} })

        const account = this.props.account
        const tagRef = ref().child(`v1/accounts/${account}/inventory/tags`)
        const snapshot = await tagRef.once("value")
        if (!snapshot.exists()) {
            this.setState({ tags: {} })
            return
        }

        const rawTags = snapshot.val()
        const tags: Dictionary<Tag> = {}
        for (const key in rawTags) {
            if (rawTags.hasOwnProperty(key)) {
                tags[key] = new Tag(rawTags[key])
            }
        }

        this.setState({ tags: tags })
    }

    componentWillUnmount() {
        const account = this.props.account
        const tagRef = ref().child(`v1/accounts/${account}/inventory/tags`)
        tagRef.off()
    }

    removeTag(tagKey: string) {
        const selected = cloneDeep(this.state.selectedTags)
        delete selected[tagKey]
        this.props.onChange(selected)
    }

    addTag(tagKey: string) {
        const selected = cloneDeep(this.state.selectedTags)
        selected[tagKey] = true
        this.props.onChange(selected)
    }

    unusedTags() {
        const tagKeys = Object.keys(this.state.tags)
        const selected = Object.keys(this.state.selectedTags)
        const diff = tagKeys.filter(x => !selected.includes(x))
        return diff
    }

    render() {
        return (
            <section>
                {
                    this.state.tags
                        ? (
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Tags</Col>
                                <Col sm={10}>
                                    {Object.keys(this.state.selectedTags).length > 0 ? (
                                        <StripedTable>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Object.keys(this.state.selectedTags).map((selectedTag) => {
                                                        const tag = this.state.tags[selectedTag]
                                                        let tagName = selectedTag
                                                        if (!isNil(tag)) {
                                                            tagName = tag.name.localized(this.props.currentLanguage || null)
                                                        }

                                                        return (
                                                            <tr key={selectedTag}>
                                                                <td>{tagName}</td>
                                                                <td className="narrow">
                                                                    <ConfirmDeleteButton
                                                                        message={`Really delete tag: ${tagName}?`}
                                                                        onDelete={() => {
                                                                            this.removeTag(selectedTag)
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </StripedTable>
                                    ) : null}

                                    {
                                        this.unusedTags().length > 0 ? (
                                            <DropdownButton
                                                bsStyle="default"
                                                title="Add tag"
                                                id="dropdown-add-tag"
                                                onSelect={(selectedTag: any) => {
                                                    this.addTag(selectedTag)
                                                }}
                                            >
                                                {
                                                    this.unusedTags().map((tagKey) => {
                                                        const tag = this.state.tags[tagKey]
                                                        return <MenuItem key={tagKey} eventKey={tagKey}>{tag.name.localized(this.props.currentLanguage || null)}</MenuItem>
                                                    })
                                                }
                                            </DropdownButton>
                                        ) : null
                                    }

                                </Col>

                            </FormGroup>
                        ) : <div>Loading..</div>
                }
            </section>
        )
    }
}
