import DropzoneComponent, { DropzoneComponentProps } from "react-dropzone-component"

import * as ReactDOM from "react-dom"

interface CSVDropzoneComponentProps extends DropzoneComponentProps {
    submitRequest?: (xhr: any, formdata: any, files: any) => void
}

// NOTE:
// Based on https://raw.githubusercontent.com/enyo/dropzone/master/dist/dropzone.js
// and https://github.com/felixrieseberg/React-Dropzone-Component/blob/master/src/react-dropzone.js
// I have tampered with Dropzone + Component in order to make it upload to firebase storage instead of simply post"ing.
// More fiddling may be necessary in the future.

// TODO: This is still basically javascript - 'any' is used to silence typescript-compiler
export class CSVDropzoneComponent extends DropzoneComponent<CSVDropzoneComponentProps> {
    componentDidMount() {
        const anyThis: any = this

        const options = anyThis.getDjsConfig()

        const Dropzone = require("dropzone")

        Dropzone.autoDiscover = false

        const dropzoneNode = this.props.config.dropzoneSelector || ReactDOM.findDOMNode(this)
        anyThis.dropzone = new Dropzone(dropzoneNode, options)

        if (this.props.submitRequest) {
            anyThis.dropzone.submitRequest = this.props.submitRequest
        } else {
            anyThis.dropzone.submitRequest = function (xhr: any, formData: any, files: any) {
                xhr.send(files[0])
            }
        }

        anyThis.setupEvents()
    }
}