import * as React from "react"
import { PageHeader } from "react-bootstrap"
import { withRouter, RouteComponentProps } from "react-router"

interface AccessDeniedProps extends RouteComponentProps<any> {}

class AccessDenied extends React.Component<AccessDeniedProps> {
    render() {
        return (
            <section>
                <PageHeader>
                    You do not have access to this page
        </PageHeader>
            </section>
        )
    }
}

export default withRouter(AccessDenied)