import * as React from "react"
import {
    cloneDeep,
    zip
} from "lodash"
import firebase from "firebase/compat"
import { firestore } from "../../config/constants"
import { Role } from "../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router"
import { StripedTable } from "../StripedTable"
import { Col, Row } from "react-bootstrap"

const potentialRoles = function (profile: any): Role[] {
    const roles: Role[] = []
    for (const accountId of profile.accounts) {
        roles.push(new Role(accountId))
    }
    for (const shopAccountId in profile.shops) {
        for (const shopId of profile.shops[shopAccountId]) {
            roles.push(new Role(shopAccountId, shopId))
        }
    }
    return roles
}

interface RoleSelectionProps extends RouteComponentProps<any> {
    selectRole(uid: string, role: Role): void
    uid: string
}

interface RoleSelectionState {
    account_roles: any[]
    shop_roles: any[]
    loading: boolean
}

class RoleSelection extends React.Component<RoleSelectionProps, RoleSelectionState> {
    constructor(props: RoleSelectionProps) {
        super(props)
        this.state = {
            account_roles: [],
            shop_roles: [],
            loading: true
        }
    }

    profileRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | null = null
    observer: () => void = () => { }

    componentDidMount() {
        const uid = this.props.uid
        this.profileRef = firestore.doc(`profiles/${uid}`)
        this.observer = this.profileRef
            .onSnapshot(async snapshot => {
                if (!snapshot || !snapshot.exists) {
                    return
                }
                const profile = snapshot.data()
                const roles = potentialRoles(profile)
                const shopPromises: Promise<firebase.firestore.DocumentSnapshot | undefined>[] = []
                const shopAccountPromises: Promise<firebase.firestore.DocumentSnapshot | undefined>[] = []
                const accountPromises: Promise<firebase.firestore.DocumentSnapshot | undefined>[] = []
                const rolesForShop: Role[] = []
                const rolesForAccount: Role[] = []
                for (const role of roles) {
                    if (role.shop_id) {
                        shopPromises.push(firestore.doc(`accounts/${role.account_id}/shops/${role.shop_id}`).get().catch(() => { return undefined }))
                        shopAccountPromises.push(firestore.doc(`accounts/${role.account_id}`).get().catch(() => { return undefined }))
                        rolesForShop.push(role)
                    } else {
                        accountPromises.push(firestore.doc(`accounts/${role.account_id}`).get().catch(() => { return undefined }))
                        rolesForAccount.push(role)
                    }
                }

                const accountSnaps = await Promise.all(accountPromises)
                const accountRoles: any[] = cloneDeep(this.state.account_roles)
                for (const [accountSnapshot, role] of zip(accountSnaps, rolesForAccount)) {
                    if (!accountSnapshot || !role) { continue }
                    const accountRole: any = {}
                    accountRole.account_id = role.account_id
                    const account = accountSnapshot.data() ?? {}
                    if (account.deactivated === true) { continue }
                    accountRole.account_name = account.name
                    accountRole.region = account.region
                    accountRole.rtdb_instance = account.instance_id
                    accountRoles.push(accountRole)
                }
                this.setState({ account_roles: accountRoles })

                const shopSnaps = await Promise.all(shopPromises)
                const shopAccountSnaps = await Promise.all(shopAccountPromises)

                const shopRoles: any[] = cloneDeep(this.state.shop_roles)
                for (const [shopSnapshot, shopAccountSnapshot, role] of zip(shopSnaps, shopAccountSnaps, rolesForShop)) {
                    if (!shopSnapshot || !role || !shopAccountSnapshot) { continue }
                    const shop = shopSnapshot.data() ?? {}

                    if (shop.deactivated === true) {
                        continue
                    }
                    const account = shopAccountSnapshot.data() ?? {}
                    if (account.deactivated === true) { continue }
                    const shopRole: any = {}
                    shopRole.account_id = role.account_id
                    shopRole.shop_id = role.shop_id
                    shopRole.shop_name = shop.name
                    shopRole.account_name = account.name
                    shopRole.region = account.region
                    shopRole.rtdb_instance = account.instance_id
                    shopRoles.push(shopRole)
                }

                const orderedAccountRoles = accountRoles.sort((a, b) => {
                    return a.account_name < b.account_name ? -1 : 1
                })
                const orderedShopRoles = shopRoles.sort((a, b) => {
                    if (a.account_name === b.account_name) {
                        return a.shop_name < b.shop_name ? -1 : 1
                    }
                    return a.account_name < b.account_name ? -1 : 1
                })

                this.setState({ shop_roles: orderedShopRoles, account_roles: orderedAccountRoles, loading: false })
            })
    }

    componentWillUnmount() {
        this.observer()
    }

    selectRole(role: Role) {
        this.props.selectRole(this.props.uid, role)
    }

    render() {
        return (
            <Row>
                <Col sm={2} />
                <Col sm={8}>
                    <section>
                        {this.state.loading ? "Loading user profile..." : (
                            (this.state.account_roles.length > 0 || this.state.shop_roles.length > 0) ?
                                "Please select the account/shop you wish to access." :
                                "You don't appear to have access to any accounts/shops. Please ask an account owner to send you an invitation.")}
                        <br />
                        <br />

                        {this.state.account_roles.length > 0 && (
                            <div>
                                <p><b>Accounts</b></p>
                                <StripedTable>
                                    <tbody>
                                        {this.state.account_roles.map(accountRole => {
                                            return (
                                                <tr key={accountRole.account_id} onClick={() => this.selectRole(new Role(accountRole.account_id, undefined, accountRole.rtdb_instance, accountRole.region))}>
                                                    <td>{accountRole.account_name || "..."}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </StripedTable>
                            </div>
                        )}
                        {this.state.shop_roles.length > 0 && (
                            <div>
                                <p><b>Shops</b></p>
                                <StripedTable>
                                    <tbody>
                                        {this.state.shop_roles.map(shopRole => {
                                            return (
                                                <tr key={shopRole.shop_id} onClick={() => this.selectRole(new Role(shopRole.account_id, shopRole.shop_id, shopRole.rtdb_instance, shopRole.region))}>
                                                    <td>{shopRole.account_name || "..."}: {shopRole.shop_name || "..."}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </StripedTable>
                            </div>
                        )}
                    </section >
                </Col>
                <Col sm={2} />
            </Row>
        )
    }
}

export default withRouter(RoleSelection)
