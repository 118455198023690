import * as React from "react"

import { withRouter, RouteComponentProps } from "react-router"
import { Button, Panel, Form, FormGroup, Col, ControlLabel, FormControl } from "react-bootstrap"
import { ref } from "../../../config/constants"
import { PageState } from "../../PageState"
import { Role } from "../../../config/role"

interface MobilePayConfigurationProps extends RouteComponentProps<any> {
    role: Role
}

interface MobilePayConfigurationState {
    api_key: string
    location_id: string
    merchant_id: string
    dirty: boolean
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
}

class MobilePayConfiguration extends React.Component<MobilePayConfigurationProps, MobilePayConfigurationState> {
    constructor(props: MobilePayConfigurationProps) {
        super(props)

        this.state = {
            api_key: "",
            location_id: "",
            merchant_id: "",
            dirty: false,
            loaded: false,
            publishing: false,
            showSuccess: false
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const shop = this.props.match.params.shopKey

        const configRef = ref().child(`v1/accounts/${account}/shops/${shop}/configuration/mobile_pay`)
        const configSnap = await configRef.once("value")

        if (configSnap.exists()) {
            const value = configSnap.val()
            this.setState({
                merchant_id: value.merchant_id || "",
                location_id: value.location_id || "",
                api_key: value.api_key || "",
                loaded: true
            })
        } else {
            this.setState({
                loaded: true
            })
        }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="MobilePay configuration">
                <Panel key="upper_panel" bsStyle={this.state.showSuccess ? "success" : "default"}>
                    <Panel.Heading>
                        <Panel.Title>{this.state.showSuccess ? "MobilePay configuration updated successfully" : "MobilePay configuration"}</Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <div>MobilePay released a new version (v10) of their internal APIs that require slight configuration changes. From Ka-ching release 16 we are supporting the new APIs, but we keep supporting the old configuration until you have updated to the latest version of Ka-ching on all your devices.</div>
                        <Form horizontal={true}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Merchant Location ID</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="location_id"
                                        value={this.state.location_id}
                                        placeholder="Enter Merchant Location ID"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Merchant Brand ID (deprecated)</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="merchant_id"
                                        value={this.state.merchant_id}
                                        placeholder="Enter Merchant Brand ID"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>API Key (deprecated)</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="api_key"
                                        value={this.state.api_key}
                                        placeholder="Enter API key"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </Panel.Body>

                    <Panel.Footer>
                        <Button onClick={this.publish} disabled={!this.state.dirty}>Publish</Button>
                    </Panel.Footer>
                </Panel >
            </PageState>
        )
    }

    handleInputChange = (event: any) => {
        const target = event.target
        const value = target.value

        if (target.name === "location_id") {
            this.setState({ location_id: value, dirty: true })
        } else if (target.name === "merchant_id") {
            this.setState({ merchant_id: value, dirty: true })
        } else if (target.name === "api_key") {
            this.setState({ api_key: value, dirty: true })
        }
    }

    publish = async () => {
        const account = this.props.role.account_id
        const shop = this.props.match.params.shopKey

        const configRef = ref().child(`v1/accounts/${account}/shops/${shop}/configuration/mobile_pay`)
        this.setState({ publishing: true })
        if (this.state.api_key !== "" || this.state.location_id !== "" || this.state.merchant_id !== "") {
            await configRef.set({ location_id: this.state.location_id, merchant_id: this.state.merchant_id, api_key: this.state.api_key })
        } else {
            await configRef.set(null)
        }

        this.setState({ showSuccess: true, publishing: false, dirty: false })
    }
}

export default withRouter(MobilePayConfiguration)