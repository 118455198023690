import * as React from "react"
import { ref } from "../../config/constants"
import { Button, Col, ControlLabel, Form, FormGroup, Panel, Alert, FormControl, ToggleButtonGroup, ToggleButton, Label } from "react-bootstrap"
import { withRouter, RouteComponentProps } from "react-router"
import { PageState } from "../PageState"
import { L10nFormControl } from "../L10nFormControl"
import { LanguageCode, L10nString } from "../../helpers/L10n"
import { Role } from "../../config/role"
import { LanguagePicker } from "../LanguagePicker"
import { cloneDeep, isNil } from "lodash"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { Expense } from "../../models/Expense"
import { Metadata } from "../../models/RuleModels"
import { Market, Tax } from "../../models/MarketModels"
import { Globals } from "../../helpers/globals"
import { MarketTaxes } from "../../models/Product"
import { CurrentMarketPicker } from "../CurrentMarketPicker"
import { ChannelSelector } from "../ChannelSelector"
import { MarketSelector } from "../MarketSelector"

interface ExpenseEditProps extends RouteComponentProps<any> {
    currentLanguage?: LanguageCode
    role: Role
}

interface ExpenseEditState {
    expense: Expense
    metadata: Metadata
    identifier: string
    loaded: boolean
    dirty: boolean
    publishing: boolean
    currentMarket: Market | null
    currentLanguage: LanguageCode | null
    markets: Market[]
    taxes: Tax[]
    error: string | null
}

class ExpenseEdit extends React.Component<ExpenseEditProps, ExpenseEditState> {
    constructor(props: ExpenseEditProps) {
        super(props)
        this.state = {
            expense: new Expense({
                id: "",
                name: ""
            }),
            metadata: {
                channels: {},
                markets: {}
            },
            identifier: "",
            currentMarket: null,
            currentLanguage: props.currentLanguage || null,
            markets: [],
            taxes: [],
            loaded: false,
            dirty: false,
            publishing: false,
            error: null
        }
    }

    updateCurrentMarket(selectedMarkets: string[]): Market | null {
        let currentMarket = this.state.currentMarket
        let currentMarketKey = currentMarket ? currentMarket.id : null

        // Don't set an explicit market when only one (or none) is available
        if (selectedMarkets.length <= 1) {
            return null
        }

        if (currentMarketKey && !selectedMarkets.includes[currentMarketKey]) {
            currentMarketKey = null
        }

        if (currentMarketKey === null) {
            currentMarketKey = selectedMarkets[0]
        }
        currentMarket = this.state.markets.find(market => { return (market.id === currentMarketKey) }) || null
        return currentMarket
    }

    resolvedMarket(): Market | null {
        if (this.state.currentMarket) {
            return this.state.currentMarket
        }
        if (this.state.markets.length === 1) {
            return this.state.markets[0]
        }
        const selectedMarketKeys = Object.keys(this.state.metadata.markets)
        if (selectedMarketKeys.length === 1) {
            return this.state.markets.find(market => { return market.id === selectedMarketKeys[0] }) || null
        }
        return null
    }

    pop() {
        const path = `/expenses`
        this.props.history.push(path)
    }

    expenseKey() {
        return this.props.match.params.expenseKey
    }

    isNewExpense() {
        return this.expenseKey() === "new"
    }

    isPublishEnabled() {
        if (!this.state.dirty) {
            return false
        }
        if (this.state.expense.name.hasEmptyLocalizations()) {
            return false
        }

        return true
    }

    expenseRepoRef() {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/expense_repo")
    }

    async publish() {
        const expenseJson = this.state.expense.json()
        const expenseData = {
            expense: expenseJson,
            metadata: this.state.metadata
        }
        this.setState({ publishing: true })

        const identifier = this.state.identifier

        try {
            if (identifier.length === 0) {
                throw new Error("Identifier may not be empty")
            }
            if (identifier === "new") {
                throw new Error("Identifier may not be 'new'")
            }
            for (const character of [".", " ", "*", "[", "]"]) {
                if (identifier.includes(character)) {
                    throw new Error(`Identifier must not contain the character: '${character}'`)
                }
            }

            const itemRef = this.expenseRepoRef().child(identifier)

            if (this.isNewExpense()) {
                let success = true
                await itemRef.transaction(existing => {
                    if (existing === null) {
                        return expenseData
                    } else {
                        success = false
                        return existing
                    }
                })
                if (!success) {
                    throw new Error("Please choose a new identifier")
                }
            } else {
                await itemRef.set(expenseData)
            }
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.pop()
    }

    componentWillUnmount() {
        const key = this.expenseKey()
        const account = this.props.role.account_id
        ref().child(`v1/accounts/${account}/inventory/expense_repo/${key}`)
            .off()
    }

    async componentDidMount() {
        const markets = await Globals.shared.getMarkets()
        const channels = await Globals.shared.getChannels()
        const taxes = await Globals.shared.getTaxes()

        this.setState({ loaded: false, markets: markets, taxes: taxes })

        if (!this.isNewExpense()) {
            const snapshot = await this.expenseRepoRef()
                .child(this.expenseKey())
                .once("value")
            const expenseData = snapshot.val()
            const metadata = { channels: {}, markets: {} }
            if (expenseData.metadata) {
                if (expenseData.metadata.channels) {
                    metadata.channels = expenseData.metadata.channels
                }
                if (expenseData.metadata.markets) {
                    metadata.markets = expenseData.metadata.markets
                }
            }
            if (markets.length === 1) {
                metadata.markets = { [markets[0].id]: true }
            }
            if (channels.length === 1) {
                metadata.channels = { [channels[0].id]: true }
            }
            const expense = new Expense(snapshot.val().expense)

            const selectedMarkets: string[] = Object.keys(metadata.markets)
            expense.setMarkets(selectedMarkets)

            const currentMarket = this.updateCurrentMarket(selectedMarkets)

            this.setState({
                expense: expense,
                metadata: metadata,
                identifier: expense.id,
                currentMarket: currentMarket,
                loaded: true
            })
        } else {
            const metadata = { channels: {}, markets: {} }
            if (markets.length === 1) {
                metadata.markets = { [markets[0].id]: true }
            }
            if (channels.length === 1) {
                metadata.channels = { [channels[0].id]: true }
            }
            this.setState({ loaded: true, metadata: metadata })
        }
    }

    resolveLanguages = (): LanguageCode[] => {
        return this.state.expense.name.localizations()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })

        if (isNil(language)) { return }
        const languages = this.resolveLanguages()
        if (!languages.includes(language)) {
            this.expenseEdit(expense => {
                expense.localizeTo(language)
            })
        }
    }

    expenseEdit(closure: ((expense: Expense) => any | null | undefined)) {
        const expense = cloneDeep(this.state.expense)
        const state = closure(expense) || {}
        state["expense"] = expense
        state["dirty"] = true
        this.setState(state)
    }

    removeLanguage = (language: LanguageCode) => {
        this.expenseEdit(expense => {
            expense.removeLocalization(language)
            return {
                currentLanguage: null
            }
        })
    }

    handleChannelsChange = (data: any) => {
        const channels = {}
        for (const channel in data) {
            channels[data[channel]] = true
        }

        const metadata = this.state.metadata
        metadata.channels = channels

        this.setState({ metadata: metadata, dirty: true })
    }

    handleCurrentMarketChange(market: Market | null) {
        this.setState({ currentMarket: market })
    }

    addMarket = (market: string) => {
        const metadata = cloneDeep(this.state.metadata)
        const expense = cloneDeep(this.state.expense)
        metadata.markets[market] = true
        expense.setMarkets(Object.keys(metadata.markets))
        const currentMarket = this.updateCurrentMarket(Object.keys(metadata.markets))
        this.setState({ metadata: metadata, expense: expense, currentMarket: currentMarket, dirty: true })
    }

    removeMarket = (market: string) => {
        const metadata = cloneDeep(this.state.metadata)
        const expense = cloneDeep(this.state.expense)
        delete metadata.markets[market]
        const remaining = Object.keys(metadata.markets)
        expense.removeMarket(market, remaining)
        const currentMarket = this.updateCurrentMarket(Object.keys(metadata.markets))
        this.setState({ metadata: metadata, expense: expense, currentMarket: currentMarket, dirty: true })
    }

    handleTaxesChange = (values: any) => {
        this.expenseEdit(expense => {
            const marketId = this.state.currentMarket ? this.state.currentMarket.id : null
            const taxKeys = values as string[]
            const taxes = new Set<Tax>()
            for (const taxKey of taxKeys) {
                const candidate = this.state.taxes.find(tax => { return tax.id === taxKey })
                if (candidate) {
                    taxes.add(candidate)
                }
            }
            if (expense.taxes) {
                expense.taxes = expense.taxes.setValues(taxes, marketId) || undefined
            } else {
                expense.taxes = MarketTaxes.create(taxes, marketId)
            }
        })
    }

    selectedTaxes(): string[] {
        if (!this.state.expense.taxes) {
            return []
        }
        const marketId = this.state.currentMarket ? this.state.currentMarket.id : null
        if (!this.state.expense.taxes.hasValuesFor(marketId)) {
            return []
        }
        const taxSet = this.state.expense.taxes.values(marketId) || new Set<Tax>()

        return Array.from(taxSet).map(tax => { return tax.id })
    }

    taxesLabel(): string {
        const marketId = this.state.currentMarket ? this.state.currentMarket.id : null
        if (this.state.expense.taxes && this.state.expense.taxes.hasValuesFor(marketId)) {
            const market = this.resolvedMarket()
            if (!market) {
                return "Using custom taxes for market"
            }
            return `Using custom taxes for market '${market.name}'`
        } else {
            const market = this.resolvedMarket()
            if (!market) {
                return "Using default taxes for market"
            }
            const taxes: Tax[] = []
            for (const tax of this.state.taxes) {
                if (market.taxes.has(tax.id)) {
                    taxes.push(tax)
                }
            }
            return `Using default taxes for market '${market.name}' : ` + taxes.map(tax => { return `'${tax.name} (${tax.rate * 100}%)'` }).join(", ")
        }
    }

    handleInputChange = (l10n: L10nString | null) => {
        this.expenseEdit(expense => {
            if (l10n === null || l10n.value === "") {
                expense.name = new L10nString("")
            } else {
                expense.name = l10n
            }
        })
    }

    handleIdChange(identifier: string) {
        this.expenseEdit(expense => {
            expense.id = identifier
            return { identifier: identifier }
        })
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="expense"
            >
                <Form horizontal={true}>

                    <FormGroup>
                        <Col smOffset={6} sm={6} className="text-right">
                            <CurrentMarketPicker
                                role={this.props.role}
                                currentMarket={this.state.currentMarket}
                                resolveMarkets={() => { return Object.keys(this.state.metadata.markets) }}
                                onChange={market => { this.handleCurrentMarketChange(market) }}
                            />
                            <LanguagePicker
                                typeName="expense"
                                initialLanguage={this.state.currentLanguage}
                                resolveLanguages={() => { return this.resolveLanguages() }}
                                onChange={this.setLanguage}
                                onRemove={this.removeLanguage}
                            />
                        </Col>
                    </FormGroup>

                    <Panel>
                        <Panel.Heading>{this.isNewExpense() ? "Create new expense" : `Edit expense '${this.state.expense.name.localized(this.state.currentLanguage)}'`}</Panel.Heading>
                        <Panel.Body>
                            <span key="a">
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>Name</Col>
                                    <Col sm={10}>
                                        <L10nFormControl
                                            l10n={this.state.expense.name}
                                            placeholder="Enter localized name"
                                            language={this.state.currentLanguage}
                                            onLocalizationChanged={l10n => { this.handleInputChange(l10n) }}
                                        />
                                    </Col>
                                </FormGroup>
                                {
                                    !this.isNewExpense()
                                        ? (
                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2}>Identifier</Col>
                                                <Col sm={10}>
                                                    <FormControl.Static>{this.state.expense.id}</FormControl.Static>
                                                </Col>
                                            </FormGroup>
                                        ) : null
                                }
                            </span>
                            <ValidatingIdEntryControl
                                collectionRef={this.expenseRepoRef()}
                                isNew={this.isNewExpense()}
                                typeName="expense"
                                identifierSource={this.state.expense.name.localized(this.state.currentLanguage)}
                                existingIdentifier={this.state.identifier}
                                handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                            />

                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Taxes</Col>
                                <Col sm={10}>
                                    <ToggleButtonGroup
                                        type="checkbox"
                                        value={this.selectedTaxes()}
                                        onChange={this.handleTaxesChange}
                                    >
                                        {
                                            this.state.taxes.map((tax) => {
                                                return <ToggleButton key={tax.id} value={tax.id}>{tax.name} ({tax.rate * 100}%)</ToggleButton>
                                            })
                                        }
                                    </ToggleButtonGroup>
                                    <br />
                                    <h4><Label bsStyle="info">{this.taxesLabel()}</Label></h4>
                                </Col>
                            </FormGroup>

                            <ChannelSelector
                                selectedChannels={Object.keys(this.state.metadata.channels)}
                                onChange={channels => { this.handleChannelsChange(channels) }}
                            />

                            <MarketSelector
                                typeName="expense"
                                selectedMarkets={Object.keys(this.state.metadata.markets)}
                                addMarket={this.addMarket}
                                removeMarket={this.removeMarket}
                            />

                        </Panel.Body>
                        <Panel.Footer><Button onClick={() => this.publish()} disabled={!this.isPublishEnabled()}>Publish</Button></Panel.Footer>
                    </Panel>
                </Form>
                {this.state.error ? (
                    <Alert bsStyle="danger">
                        <strong>Error publishing expense:</strong> {this.state.error}
                    </Alert>
                ) : []}
            </PageState>
        )
    }
}

export default withRouter(ExpenseEdit)
