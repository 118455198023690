import * as React from "react"
import { FormattedTime } from "react-intl"
import { ref } from "../../config/constants"
import { Col, ControlLabel, Row, Grid } from "react-bootstrap"
import { withRouter, RouteComponentProps } from "react-router"
import { FormattedCurrency } from "../FormattedCurrency"
import { PageState } from "../PageState"
import { Role } from "../../config/role"
import { paymentTypeNameFromId } from "../../helpers/PaymentTypeNames"
import { isNil } from "lodash"

interface RegisterCloseStatementViewProps extends RouteComponentProps<any> {
    role: Role
    shop: string
}

class RegisterCloseStatementView extends React.Component<RegisterCloseStatementViewProps, any> {
    constructor(props: RegisterCloseStatementViewProps) {
        super(props)
        this.state = {
            loaded: false
        }
    }

    statementsRef() {
        const account = this.props.role.account_id
        const shop = this.props.shop
        return ref().child(`v1/accounts/${account}/shops/${shop}/register_close_statements`)
    }

    async componentDidMount() {
        const key = this.props.match.params.statementKey
        const statementRef = this.statementsRef().child(key)
        const snapshot = await statementRef.once("value")
        this.setState(snapshot.val())
        this.setState({ loaded: true })
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="statement">
                {
                    this.state.loaded ? this.renderRegisterCloseStatement() : null
                }
            </PageState>
        )
    }

    renderRegisterCloseStatement() {        
        const zero = 0 // to get rid of annoying warning about comparing to self
        return (
            <Grid>
                <Row>
                    <Col componentClass={ControlLabel} sm={2}>
                        Date
                        </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        <FormattedTime
                            value={new Date(this.state.reconciliation_time * 1000)}
                            day="numeric"
                            month="long"
                            year="numeric"
                            hour="numeric"
                            minute="numeric"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col componentClass={ControlLabel} sm={2}>
                        Register
                        </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        {this.state.source.register_name}
                    </Col>
                </Row>

                <Row>
                    <Col componentClass={ControlLabel} sm={2}>
                        Cashier
                        </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        {this.state.source.cashier_name || this.state.source.cashier_display_name}
                    </Col>
                </Row>

                <Row>
                    <Col componentClass={ControlLabel} sm={2}>
                        Sequence number
                        </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        {this.state.sequence_number}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col componentClass={ControlLabel} sm={2}>
                        Cash drawer open count
                        </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        {this.state.register_summary.cash_drawer_open_count || "0"}
                    </Col>
                </Row>
                <Row>
                    <Col componentClass={ControlLabel} sm={3}>
                        Number of aborted sales
                        </Col>
                    <Col sm={4} style={{ textAlign: "right" }}>
                        {this.state.register_summary.number_of_aborted_sale || "0"}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col componentClass={ControlLabel} sm={2}>
                        Sales
                        </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        <FormattedCurrency value={this.state.register_summary.sales.total} currency={this.state.base_currency_code || "DKK"} />
                    </Col>
                </Row>
                <Row>
                    <Col componentClass={ControlLabel} sm={2}>
                        Returns
                        </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        <FormattedCurrency value={this.state.register_summary.returns.total} currency={this.state.base_currency_code || "DKK"} />
                    </Col>
                </Row>
                {
                    (this.state.register_summary.expenses || {}).count || zero > 0 ?
                        (
                            <Row>
                                <Col componentClass={ControlLabel} sm={2}>
                                    Expenses
                                 </Col>
                                <Col sm={5} style={{ textAlign: "right" }}>
                                    <FormattedCurrency value={this.state.register_summary.expenses.total} currency={this.state.base_currency_code || "DKK"} />
                                </Col>
                            </Row>
                        )
                        : null
                }
                <Row>
                    <Col componentClass={ControlLabel} sm={2}>
                        Total
                        </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        <FormattedCurrency value={this.state.register_summary.all.total} currency={this.state.base_currency_code || "DKK"} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col componentClass={ControlLabel} sm={2}>
                        Output VAT
                        </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        <FormattedCurrency value={this.state.register_summary.sales.total_tax_amount + this.state.register_summary.returns.total_tax_amount} currency={this.state.base_currency_code || "DKK"} />
                    </Col>
                </Row>
                {
                    (this.state.register_summary.expenses || {}).count || zero > 0 ?
                        (
                            <Row>
                                <Col componentClass={ControlLabel} sm={2}>
                                    Input VAT
                                </Col>
                                <Col sm={5} style={{ textAlign: "right" }}>
                                    {/* The value is negated since the sign is implicit in the label: 'input vat' */}
                                    <FormattedCurrency value={-(this.state.register_summary.expenses.total_tax_amount)} currency={this.state.base_currency_code || "DKK"} />
                                </Col>
                            </Row>
                        ) : null
                }
                <br />
                < Row >
                    <Col componentClass={ControlLabel} sm={2}>
                        Total without taxes
                </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        <FormattedCurrency value={this.state.register_summary.all.base_price} currency={this.state.base_currency_code || "DKK"} />
                    </Col>
                </Row>
                <br />
                < Row >
                    <Col componentClass={ControlLabel} sm={3}>
                        Cash register opening float
                </Col>
                    <Col sm={4} style={{ textAlign: "right" }}>
                        <FormattedCurrency value={this.state.register_summary.cash_total_at_open} currency={this.state.base_currency_code || "DKK"} />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col componentClass={ControlLabel} sm={2}>
                        Reconciliations
                        </Col>
                </Row>

                <Row>
                    <Col sm={1} />
                    <Col sm={2} style={{ textAlign: "right" }}>
                        <b>Counted</b>
                    </Col>
                    <Col sm={2} style={{ textAlign: "right" }}>
                        <b>Expected</b>
                    </Col>
                    <Col sm={2} style={{ textAlign: "right" }}>
                        <b>Difference</b>
                    </Col>
                </Row>
                {
                    this.state.reconciliations.map((x: any, i: number) => {
                        return (
                            <Row key={i.toString()}>
                                <Col componentClass={ControlLabel} sm={1}>
                                    {paymentTypeNameFromId(x.payment_type_identifier)}
                                </Col>
                                <Col sm={2} style={{ textAlign: "right" }}>
                                    <FormattedCurrency value={x.counted} currency={x.currency_code} />
                                </Col>
                                <Col sm={2} style={{ textAlign: "right" }}>
                                    <FormattedCurrency value={x.total} currency={x.currency_code} />
                                </Col>
                                <Col sm={2} style={{ textAlign: "right" }}>
                                    <FormattedCurrency value={x.counted - x.total} currency={x.currency_code} />
                                </Col>
                            </Row>
                        )
                    })
                }

                <br />
                <Row>
                    <Col componentClass={ControlLabel} sm={2}>
                        Deposits
                        </Col>
                </Row>

                <Row>
                    <Col sm={1} />
                    <Col sm={2} style={{ textAlign: "right" }}>
                        <b>Deposited</b>
                    </Col>
                    <Col sm={2} style={{ textAlign: "right" }}>
                        <b>Remaining</b>
                    </Col>
                </Row>
                {
                    this.state
                        .reconciliations
                        .filter((x: any) => {
                            return !isNil(x.deposited_amount)
                        })
                        .map((x: any, i: number) => {
                            return (
                                <Row key={i.toString()}>
                                    <Col componentClass={ControlLabel} sm={1}>
                                        {paymentTypeNameFromId(x.payment_type_identifier)}
                                    </Col>
                                    <Col sm={2} style={{ textAlign: "right" }}>
                                        <FormattedCurrency value={x.deposited_amount} currency={x.currency_code} />
                                    </Col>
                                    <Col sm={2} style={{ textAlign: "right" }}>
                                        <FormattedCurrency value={x.counted - x.deposited_amount} currency={x.currency_code} />
                                    </Col>
                                </Row>
                            )
                        })
                }

            </Grid >
        )
    }
}

export default withRouter(RegisterCloseStatementView)
