import * as React from "react"
import { Panel, Grid, Row, Col, FormControl } from "react-bootstrap"
import { CashierSelectionMode } from "../../../models/CashierSelection"

interface CashierSelectionModeConfigurationProps {
    didSelectMode: (mode: CashierSelectionMode) => void
    cashierSelectionMode: CashierSelectionMode
}

interface CashierSelectionModeConfigurationState {
    cashier_selection_mode: CashierSelectionMode
}

export default class CashierSelectionModeConfiguration extends React.Component<CashierSelectionModeConfigurationProps, CashierSelectionModeConfigurationState> {
    constructor(props: CashierSelectionModeConfigurationProps) {
        super(props)

        this.state = {
            cashier_selection_mode: this.props.cashierSelectionMode
        }
    }

    render() {
        return (
            <Panel key="panel1">
                <Panel.Heading>
                    <Panel.Title>Cashier selection mode</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    <Grid>
                        <Row>
                            <Col xs={12} sm={8} md={8}>
                                <FormControl
                                    componentClass="select"
                                    name="cashier_selection_mode"
                                    placeholder="Select mode"
                                    value={this.state.cashier_selection_mode}
                                    onChange={this.handleInputChange}
                                >
                                    <option value={CashierSelectionMode.manual}>Manual cashier selection</option>
                                    <option value={CashierSelectionMode.beforeEachSale}>Re-select before each sale</option>
                                </FormControl>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={8}>
                                <h5><b>Manual cashier selection</b> allows the users of the POS to manually switch between cashiers.</h5>
                                <h5><b>Re-select before each sale</b> forces the users of the POS to select a cashier before each sale.</h5>
                            </Col>
                        </Row>
                    </Grid>
                </Panel.Body>
            </Panel>
        )
    }

    handleInputChange = async (event: any) => {
        const newValue = event.target.value

        this.props.didSelectMode(newValue)
        this.setState({ cashier_selection_mode: newValue })
    }
}
