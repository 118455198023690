import * as _ from "lodash"
import * as moment from "moment"
import * as React from "react"
import firebase from "firebase/compat"
import ExportsJS from "exportsjs"
import {
    Alert,
    Button,
    FormControl,
    InputGroup,
    Pager,
    Panel
} from "react-bootstrap"
import { DateRangePicker } from "react-dates"
import {
    FormattedRelative,
    FormattedTime
} from "react-intl"
import { PageState } from "../PageState"
import { firestore, ref } from "../../config/constants"
import { Role } from "../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router"
import { StripedTable } from "../StripedTable"
import "react-dates/initialize" // tslint:disable-line

const FileDownload = require("js-file-download")

interface SalesListProps extends RouteComponentProps<any> {
    role: Role
    shop?: string
}

interface SalesListState {
    sales: any[]
    lastCheckoutTimestamp?: string
    firstCheckoutTimestamp?: string
    newestCheckoutTimestamp?: string
    startDate: moment.Moment | null
    endDate: moment.Moment | null
    exports: any
    loaded: boolean
    focusedInput?: any
    lastPage?: boolean
    errorMessage?: string
    giftcardModuleEnabled: boolean
    voucherModuleEnabled: boolean
    searchTerm?: string
    searching: boolean
    giftcardRecipients?: _.Dictionary<string>
}

function allSalesTotal(checkout: any): string {
    const sales = checkout.sales ?? []
    const total = sales.reduce((sum: number, value: any) => { return sum + value.basket.total }, 0)
    return `${total}`
}

function lineItemCounts(checkout: any): string {
    const sales = checkout.sales ?? []
    const counts: string[] = []
    for (const sale of sales) {
        if (sale.basket?.line_items) {
            const count = sale.basket.line_items.reduce((sum: number, value: any) => { return sum + value.quantity }, 0)
            counts.push(`${count}`)
        } else {
            counts.push("0")
        }
    }
    return counts.join(", ")
}

export function summaryType(checkout: any): string {
    const sales = checkout.sales ?? []
    const types: string[] = []
    for (const sale of sales) {
        types.push(saleSummaryType(sale.basket ?? {}))
    }
    return types.join(", ")
}

export function saleSummaryType(summary: any): string {
    if (!_.isNil(summary.return_reference)) {
        return "Return"
    } else if (!_.isNil(summary.external_order_reference)) {
        return "Click & collect"
    } else if (!_.isNil(summary.sales_quote_reference)) {
        return "Sale of sales quote"
    } else if (!_.isNil(summary.expense_reference)) {
        return "Expense"
    }
    return "Sale"
}

class SalesList extends React.Component<SalesListProps, SalesListState> {
    constructor(props: SalesListProps) {
        super(props)

        this.state = {
            sales: [],
            lastCheckoutTimestamp: undefined,
            startDate: null,
            endDate: null,
            exports: {},
            loaded: false,
            giftcardModuleEnabled: false,
            voucherModuleEnabled: false,
            searching: false
        }
    }

    async componentDidMount() {
        this.setupObservers()
        const params = new URLSearchParams(this.props.location.search)
        const key = params.get("giftcard_code")

        if (key !== null) {
            this.setState({ searchTerm: key })
            await this.loadGiftcardSales(key)
        } else {
            await this.loadSales(this.earlierThan)
        }
        await this.loadExports()
    }

    async componentWillReceiveProps(nextProps: SalesListProps) {
        const prevShop = this.props.role.shop_id ?? this.props.shop
        const nextShop = nextProps.role.shop_id ?? this.props.shop
        if (prevShop !== nextShop) {
            await this.loadSales(this.earlierThan, nextProps)
        }
    }

    loadExports = async () => {
        const account = this.props.role.account_id
        const exportsRef = ref().child(`v1/accounts/${account}/configuration/export_integrations/sales`).orderByKey().limitToLast(50)
        const snapshot = await exportsRef.once("value")
        let exportsDict = snapshot.val() || {}
        exportsDict = _.filter(exportsDict, (value, key: string, collection) => {
            if (value.hasOwnProperty("show_in_portal")) {
                return value.show_in_portal
            }
            return true
        })
        exportsDict["line_items"] = {
            name: "All line items as CSV file",
            transformation: {
                type: "csv",
                parameters: {
                    configuration: { csv_separator: ";", decimal_separator: "," },
                    columns: [
                        { header: "sale_id", value: "sale_id" },
                        { header: "type", value: "type" },
                        { header: "sequence_number", value: "sequence_number" },
                        { header: "timestamp", value: "timestamp" },
                        { header: "timezone", value: "timezone" },
                        { header: "cashier_id", value: "cashier_id" },
                        { header: "cashier_name", value: "cashier_name" },
                        { header: "register_id", value: "register_id" },
                        { header: "register_name", value: "register_name" },
                        { header: "shop_id", value: "shop_id" },
                        { header: "shop_name", value: "shop_name" },
                        { header: "market_id", value: "market_id" },
                        { header: "market_name", value: "market_name" },
                        { header: "product_id", value: "id" },
                        { header: "product_group", value: "product_group" },
                        { header: "name", value: "name" },
                        { header: "variant_id", value: "variant_id" },
                        { header: "variant_name", value: "variant_name" },
                        { header: "quantity", value: "quantity" },
                        { header: "barcode", value: "barcode" },
                        { header: "total", value: "total" },
                        { header: "total_tax_amount", value: "total_tax_amount" },
                        { header: "vat_amount", value: "vat_amount" },
                        { header: "sales_tax_amount", value: "sales_tax_amount" },
                        { header: "sub_total", value: "sub_total" },
                        { header: "retail_price", value: "retail_price" },
                        { header: "base_price", value: "base_price" },
                        { header: "cost_price", value: "cost_price" },
                        { header: "margin", value: "margin" },
                        { header: "margin_total", value: "margin_total" },
                        { header: "discount_amount", value: "discount_amount" },
                        { header: "image_url", value: "image_url" },
                        { header: "customer_id", value: "customer_id" }
                    ],
                    rows: [
                        {
                            type: { id: "line_items_each" }
                        }
                    ]
                }
            }
        }

        this.setState({ exports: exportsDict })
    }

    // Helpers

    private set earlierThan(key: string | undefined) {
        const params = new URLSearchParams(this.props.location.search)
        if (params.get("earlierThan") === key) {
            return
        }
        if (key) {
            params.set("earlierThan", key)
        } else {
            params.delete("earlierThan")
        }

        this.props.history.push(`?${params.toString()}`)
    }

    private get earlierThan(): string | undefined {
        const params = new URLSearchParams(this.props.location.search)
        const key = params.get("earlierThan")

        return key === null ? undefined : key
    }

    private renderGiftcardDetails(checkout: any) {
        const giftcards: any[] = []
        for (const sale of checkout.sales ?? []) {
            for (const lineItem of sale.basket?.line_items ?? []) {
                if (lineItem.behavior?.giftcard?.code) {
                    const amount = lineItem.total
                    giftcards.push([lineItem.behavior?.giftcard?.code, true, "giftcard", amount])
                } else if (lineItem.behavior?.giftcard_use?.code) {
                    const amount = -lineItem.total
                    giftcards.push([lineItem.behavior?.giftcard_use?.code, false, "giftcard", amount])
                } else if (lineItem.behavior?.voucher?.code) {
                    const amount = lineItem.total
                    giftcards.push([lineItem.behavior?.voucher?.code, true, "voucher", amount])
                } else if (lineItem.behavior?.voucher_use?.code) {
                    const amount = -lineItem.total
                    giftcards.push([lineItem.behavior?.voucher_use?.code, false, "voucher", amount])
                }
            }
        }
        for (const payment of checkout.payments ?? []) {
            if (!payment.success) { continue }
            const amount = payment.amount
            if (payment.payment_type === "giftcard.integration") {
                if (payment.metadata?.status === "used") {
                    giftcards.push([payment.metadata?.code, false, "giftcard", amount])
                } else {
                    giftcards.push([payment.metadata?.code, true, "giftcard", -amount])
                }
            } else if (payment.payment_type === "voucher.integration") {
                if (payment.metadata?.status === "used") {
                    giftcards.push([payment.metadata?.code, false, "voucher", amount])
                } else {
                    giftcards.push([payment.metadata?.code, true, "voucher", -amount])
                }
            }
        }
        return giftcards.map(a => {
            const [code, isIssued, type, amount] = a
            const email = this.state.giftcardRecipients?.[code]
            return <p key={code}>{isIssued ? "Issued" : "Used"} {type} of <b>{amount} {checkout.base_currency_code}</b> with code: <i>{code}</i>{email && <div>Sent to: <b>{email}</b></div>}</p>
        })
    }

    private get showGiftcardDetails(): boolean {
        return this.state.searching
    }

    unregisterSales?: () => void
    unregisterGiftcardModule?: (a: any) => void
    unregisterVoucherModule?: (a: any) => void

    componentWillUnmount(): void {
        if (this.unregisterSales) {
            this.unregisterSales()
            this.unregisterSales = undefined
        }
        if (this.unregisterGiftcardModule) {
            ref().child(`v1/accounts/${this.props.role.account_id}/configuration/modules/giftcard/enabled`).off("value", this.unregisterGiftcardModule)
            this.unregisterGiftcardModule = undefined
        }
        if (this.unregisterVoucherModule) {
            ref().child(`v1/accounts/${this.props.role.account_id}/configuration/modules/voucher/enabled`).off("value", this.unregisterVoucherModule)
            this.unregisterGiftcardModule = undefined
        }
    }

    private setupObservers() {
        if (this.unregisterSales) { this.unregisterSales() }
        this.unregisterSales = this.checkoutsRef().orderBy("timing.timestamp_date_string").limitToLast(1).onSnapshot(snapshot => {
            if (!snapshot || snapshot.empty) {
                return
            }

            if (snapshot.docs.length > 0) {
                const timestamp = snapshot.docs[0].data()?.timing?.timestamp_date_string
                this.setState({ newestCheckoutTimestamp: timestamp })
            } else {
                this.setState({ newestCheckoutTimestamp: undefined })
            }
        })
        this.unregisterGiftcardModule = ref().child(`v1/accounts/${this.props.role.account_id}/configuration/modules/giftcard/enabled`).on("value", snapshot => {
            this.setState({ giftcardModuleEnabled: snapshot.val() ?? false })
        })
        this.unregisterVoucherModule = ref().child(`v1/accounts/${this.props.role.account_id}/configuration/modules/voucher/enabled`).on("value", snapshot => {
            this.setState({ voucherModuleEnabled: snapshot.val() ?? false })
        })
    }

    loadNext = async (props = this.props) => {
        const limit = 50
        const fromKey = this.state.lastCheckoutTimestamp
        let salesRef = this.checkoutsRef(props).orderBy("timing.timestamp_date_string").limitToLast(limit)
        if (!_.isNil(fromKey)) {
            salesRef = salesRef.endAt(fromKey)
        }

        await this.loadSalesFromRef(salesRef)
    }

    loadPrevious = async (props = this.props) => {
        const limit = 50
        const fromKey = this.earlierThan
        let salesRef = this.checkoutsRef(props).orderBy("timing.timestamp_date_string").limit(limit)
        if (!_.isNil(fromKey)) {
            salesRef = salesRef.startAt(fromKey)
        }

        await this.loadSalesFromRef(salesRef)
    }

    checkoutsRef(props = this.props) {
        const account = props.role.account_id
        const shop = props.role.shop_id ?? props.shop
        const checkoutsRef = firestore.collection(`accounts/${account}/checkouts`)

        if (!_.isNil(shop)) {
            return checkoutsRef.where("source.shop_id", "==", shop)
        } else {
            return checkoutsRef
        }
    }

    fbSalesRef(props = this.props) {
        const account = props.role.account_id
        if (props.role.shop_id || props.shop) {
            const shop = props.role.shop_id ? props.role.shop_id : props.shop
            return ref().child(`v1/accounts/${account}/shops/${shop}/sales`)
        } else {
            return ref().child(`v1/accounts/${account}/sales`)
        }
    }

    loadSalesFromRef = async (salesRef: firebase.firestore.Query<firebase.firestore.DocumentData>) => {
        const limit = 50
        const snapshot = await salesRef.get()

        if (snapshot.empty) {
            this.setState({ loaded: true, lastPage: true })
            return
        }

        const values: any[] = []
        for (const checkoutDoc of snapshot.docs) {
            const checkout = checkoutDoc.data()
            checkout.key = checkoutDoc.id
            values.push(checkout)
        }

        let lastSaleId: string | undefined = undefined
        let firstSaleId: string | undefined = undefined
        const reverseOrder = values.reverse()
        if (reverseOrder.length > 0) {
            firstSaleId = reverseOrder[0].timing.timestamp_date_string
            this.earlierThan = firstSaleId
            lastSaleId = reverseOrder[reverseOrder.length - 1].timing.timestamp_date_string
        }
        this.setState({ loaded: true, sales: reverseOrder, firstCheckoutTimestamp: firstSaleId, lastCheckoutTimestamp: lastSaleId, lastPage: values.length < limit })
    }

    loadSales = async (fromKey: string | undefined, props: SalesListProps = this.props) => {
        const limit = 50
        let salesRef = this.checkoutsRef(props).orderBy("timing.timestamp_date_string").limitToLast(limit)
        if (!_.isNil(fromKey)) {
            salesRef = salesRef.endAt(fromKey)
        }
        this.loadSalesFromRef(salesRef)
    }

    async fetchGiftcard(code: string) {
        const args: any = {
            action: "giftcard-read",
            account: this.props.role.account_id,
            code: code,
        }

        const client = firebase.functions().httpsCallable("clientApi")
        try {
            return await client(args)
        } catch {
            return
        }
    }

    loadGiftcardSales = async (code: string, props: SalesListProps = this.props) => {
        this.setState({ sales: [], loaded: false, searching: true })
        const values: _.Dictionary<any> = {}
        const toProcess: Set<string> = new Set()
        toProcess.add(code)
        const seen: Set<string> = new Set()
        while (toProcess.size > 0) {
            const first = toProcess.values().next().value
            toProcess.delete(first)
            if (seen.has(first)) {
                continue
            }
            seen.add(first)
            const salesRef = this.checkoutsRef(props).where("index.giftcard_codes", "array-contains", first)
            const snapshot = await salesRef.get()

            if (snapshot.empty) {
                continue
            }

            for (const checkoutDoc of snapshot.docs) {
                const checkout = checkoutDoc.data()
                checkout.key = checkoutDoc.id
                for (const code of checkout.index?.giftcard_codes ?? []) {
                    if (seen.has(code)) {
                        continue
                    }
                    toProcess.add(code)
                }
                values[checkoutDoc.id] = checkout
            }
        }

        const sorted = Object.values(values).sort((a: any, b: any) => {
            return a.timing.timestamp - b.timing.timestamp
        })
        this.setState({ sales: sorted, lastPage: true, loaded: true, firstCheckoutTimestamp: undefined, giftcardRecipients: undefined })

        const giftcardCodeEmails: any = {}
        for (const code of seen) {
            const giftcard = await this.fetchGiftcard(code)
            if (giftcard?.data?.email) {
                giftcardCodeEmails[code] = giftcard.data.email
            }
            this.setState({ giftcardRecipients: giftcardCodeEmails })
            console.log(giftcard)
        }
    }

    performExport = async (exportKey: string) => {
        const exportMethod = this.state.exports[exportKey]

        if (this.state.startDate === null || !this.state.startDate.isValid()) {
            return
        }
        if (this.state.endDate === null || !this.state.endDate.isValid()) {
            return
        }

        const start = this.state.startDate.startOf("day").utc(false).format("YYYY-MM-DDTHH:mm:ss")
        const end = this.state.endDate.endOf("day").utc(false).format("YYYY-MM-DDTHH:mm:ss")
        const salesRef = this.fbSalesRef().orderByChild("timing/timestamp_string").startAt(start).endAt(end)
        const snapshot = await salesRef.once("value")
        if (!snapshot.exists()) {
            // TODO: Warn that no data is available?
            this.setState({ errorMessage: "No sales are present in the selected time interval." })
            return
        }
        const salesDict = snapshot.val()

        const sales = Object.values(salesDict)
        const configuration = exportMethod.transformation.parameters

        let exportFunction: (input: any) => any
        let fileExtension: string = "json"
        let singleItemExport: boolean = false

        switch (exportMethod.transformation.type) {
            case "csv": {
                exportFunction = input => {
                    const csvExport = new ExportsJS.csv(configuration, input)
                    return csvExport.export()
                }
                fileExtension = "csv"
                break
            }
            case "simplejson": {
                exportFunction = input => {
                    const jsonExport = new ExportsJS.simpleJSON(configuration, input)
                    return jsonExport.export()
                }
                singleItemExport = true
                break
            }
            case "identity": {
                exportFunction = input => { return input }
                break
            }
            case "economic": {
                exportFunction = input => {
                    const jsonExport = new ExportsJS.economic(configuration, input)
                    return jsonExport.saleExport()
                }
                singleItemExport = true
                break
            }
            default: {
                return
            }
        }

        const filename = "sales." + fileExtension
        if (singleItemExport) {
            const output = []
            for (const index in sales) {
                const sale = sales[index]
                try {
                    // Expecting export functions to return objects
                    output.push(exportFunction(sale))
                } catch (error) {
                    if (error instanceof ExportsJS.SkipExport) {
                        console.log("Skipping export", error.message)
                    } else {
                        throw error
                    }
                }
            }
            FileDownload(JSON.stringify(output), filename)

        } else {
            let output = exportFunction(salesDict)
            if (_.isObject(output)) {
                output = JSON.stringify(output)
            }
            FileDownload(output, filename)
        }
    }

    private get resolvedShop() {
        return this.props.shop ?? this.props.role.shop_id
    }

    showSale = (saleKey: string) => {
        let path = `/sales/${saleKey}`
        if (!_.isNil(this.resolvedShop)) {
            path = `/shop/${this.resolvedShop}/sales/${saleKey}`
        }
        this.props.history.push(path)
    }

    handleAlertDismiss = () => {
        this.setState({ errorMessage: undefined })
    }

    private get usesGiftcards() {
        return this.state.giftcardModuleEnabled || this.state.voucherModuleEnabled
    }

    private handleSearchTermChange = (event: any) => {
        const value: string = event.target.value

        this.setState({ searchTerm: value })
    }

    private handleSearchKeyPress(value: any) {
        if (value.charCode === 13) {
            this.performSearch()
        }
    }

    performSearch() {
        this.searchTerm = this.state.searchTerm
        this.loadGiftcardSales(this.state.searchTerm ?? "")
    }

    closeSearch() {
        this.setState({ searchTerm: undefined, loaded: false, sales: [], searching: false })
        this.searchTerm = undefined
        this.loadSales(undefined)
    }

    private set searchTerm(searchTerm: string | undefined) {

        // We always overwrite all query params, so we don't start out from the current params
        const params = new URLSearchParams()

        if (searchTerm) {
            const encodedSearchTerm = encodeURIComponent(searchTerm)
            params.set("giftcard_code", encodedSearchTerm)
        } else {
            params.delete("giftcard_code")
        }
        console.log(params.toString())

        this.props.history.push(`?${params.toString()}`)
    }

    private get searchTerm(): string | undefined {
        const params = new URLSearchParams(this.props.location.search)
        const encodedSearchTerm = params.get("giftcard_code")

        if (!encodedSearchTerm) {
            return undefined
        }

        const searchTerm = decodeURIComponent(encodedSearchTerm)

        return searchTerm
    }

    renderGiftcardSearchField() {
        // Show search field
        return <InputGroup>
            <FormControl
                type="text"
                name="search_term"
                value={this.state.searchTerm ?? ""}
                placeholder="Search sales by gift card code"
                onChange={(event: any) => { this.handleSearchTermChange(event) }}
                onKeyPress={(value) => { this.handleSearchKeyPress(value) }}
            />
            <InputGroup.Button>
                <Button bsStyle="primary" onClick={() => { this.performSearch() }} >Search</Button>
                {this.showGiftcardDetails &&
                    <Button bsStyle="warning" onClick={() => { this.closeSearch() }}>Close</Button>
                }
            </InputGroup.Button>
        </InputGroup>
    }

    private get onFirstPage() {
        return this.state.firstCheckoutTimestamp === this.state.newestCheckoutTimestamp
    }

    renderPager() {
        return <Pager>
            <Pager.Item previous={true} disabled={this.showGiftcardDetails || this.onFirstPage} onClick={async () => await this.loadPrevious()}>&larr; Newer</Pager.Item>
            <Pager.Item next={true} disabled={this.showGiftcardDetails || this.state.lastPage} onClick={async () => { this.loadNext() }}>Older &rarr;</Pager.Item>
        </Pager>
    }

    render() {
        return (
            <PageState loading={false} typeName="sales">
                {this.usesGiftcards && this.renderGiftcardSearchField()}
                {this.renderPager()}
                <Panel>
                    <Panel.Heading>Sales</Panel.Heading>
                    <Panel.Body>
                        {this.state.loaded ?
                            <StripedTable>
                                <thead>
                                    <tr>
                                        <th>Date/Time</th>
                                        {
                                            this.showGiftcardDetails &&
                                            <th>Giftcard details</th>
                                        }
                                        <th>Type</th>
                                        <th>Source</th>
                                        <th style={{ textAlign: "right" }}>Line Items</th>
                                        <th style={{ textAlign: "right" }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.sales.map(listValue => {
                                        return (
                                            <tr style={{ color: listValue.voided ? "#cccccc" : "black" }} key={listValue.key} onClick={() => this.showSale(listValue.key)} >
                                                <td>
                                                    <FormattedTime
                                                        value={new Date(listValue.timing.timestamp_string)}
                                                        day="numeric"
                                                        month="long"
                                                        year="numeric"
                                                        hour="numeric"
                                                        minute="numeric"
                                                    />&nbsp;(<FormattedRelative value={new Date(listValue.timing.timestamp_string)} />)
                                                </td>
                                                {
                                                    this.showGiftcardDetails &&
                                                    <td>{this.renderGiftcardDetails(listValue)}</td>
                                                }
                                                <td>{summaryType(listValue)}{
                                                    listValue.voided
                                                        ?
                                                        " (voided)"
                                                        :
                                                        ""
                                                }</td>
                                                <td>{_.isNil(this.resolvedShop) ? `${listValue.source.shop_name} / ` : ""} {listValue.source.cashier_name ?? listValue.source.cashier_display_name}</td>
                                                <td style={{ textAlign: "right" }}>{lineItemCounts(listValue)}</td>
                                                <td style={{ textAlign: "right" }}>
                                                    {
                                                        listValue.voided
                                                            ?
                                                            `0 ${listValue.base_currency_code}`
                                                            :
                                                            `${allSalesTotal(listValue)} ${listValue.base_currency_code}`
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </StripedTable>
                            : <div>Loading...</div>}
                    </Panel.Body>
                </Panel>
                {this.renderPager()}

                {Object.keys(this.state.exports).length > 0 ? (
                    <Panel>
                        <Panel.Heading>Export</Panel.Heading>
                        <Panel.Body>
                            <div>
                                <DateRangePicker
                                    startDateId="startDate"
                                    endDateId="endDate"
                                    minimumNights={0}
                                    isOutsideRange={() => { return false }}
                                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                    onDatesChange={({ startDate, endDate }: any) => { this.setState({ startDate, endDate }) }} // PropTypes.func.isRequired,
                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={(focusedInput: any) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                />
                                <br />
                                <br />
                            </div>
                            {
                                this.state.errorMessage && <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                                    <h4>Nothing to export</h4>
                                    <p>
                                        {this.state.errorMessage}
                                    </p>
                                    <p>
                                        <Button onClick={this.handleAlertDismiss}>Ok</Button>
                                    </p>
                                </Alert>
                            }
                            <div>
                                {
                                    Object.keys(this.state.exports).map(key => {
                                        const exportMethod = this.state.exports[key]
                                        const enabled = this.state.startDate && this.state.startDate.isValid() && this.state.endDate && this.state.endDate.isValid()
                                        return <div key={key}><Button onClick={() => this.performExport(key)} disabled={!enabled}>{exportMethod.name}</Button><br /><br /></div>
                                    })
                                }
                            </div>
                        </Panel.Body>
                    </Panel>
                ) : <div />
                }
            </PageState>
        )
    }
}

export default withRouter(SalesList)
