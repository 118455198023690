import * as _ from "lodash"
import * as React from "react"

import { Alert, Button, Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock, Panel } from "react-bootstrap"
import { LanguageCode, L10nString } from "../../helpers/L10n"
import { PageState } from "../PageState"
import { RecommendationCategory } from "../../models/RecommendationCategory"
import { ref } from "../../config/constants"
import { Role } from "../../config/role"
import { withRouter, RouteComponentProps } from "react-router"

interface RecommendationCategoryEditProps extends RouteComponentProps<any> {
    role: Role
}

interface RecommendationCategoryEditState {
    categories: RecommendationCategory[]
    dirty: boolean
    editingCategory: RecommendationCategory
    error: string | null
    loading: boolean
    new: boolean
    publishing: boolean,
    validId: boolean,
    validName: boolean
}

class RecommendationCategoryEdit extends React.Component<RecommendationCategoryEditProps, RecommendationCategoryEditState> {

    // Lifecycle

    constructor(props: RecommendationCategoryEditProps) {
        super(props)
        this.state = {
            categories: [],
            dirty: false,
            editingCategory: new RecommendationCategory({ id: "", name: "" }, 0),
            error: null,
            loading: true,
            new: true,
            publishing: false,
            validId: false,
            validName: false
        }
    }

    // Component

    async componentDidMount() {
        await this.load()
    }

    render() {
        return (
            <PageState loading={this.state.loading} typeName="recommendation category" publishing={this.state.publishing} dirty={this.state.dirty}>
                <Panel>
                    <Panel.Heading>{this.state.new ? `New category` : `Editing category: ${this.state.editingCategory.name.localized(LanguageCode.da)}`}</Panel.Heading>
                    <Panel.Body>
                        <Form onSubmit={e => e.preventDefault()} horizontal={true}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Name</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="name"
                                        value={this.state.editingCategory.name.localized(LanguageCode.da)}
                                        placeholder="Enter name"
                                        onChange={this.handleNameChange}
                                        autoComplete="off"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup validationState={!this.state.validId ? "error" : null}>
                                <Col componentClass={ControlLabel} sm={2}>Id</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="id"
                                        value={this.state.editingCategory.id}
                                        placeholder={`Enter category identifier`}
                                        onChange={(event: any) => {
                                            const generatedId = this.generatedIdentifier(event.target.value)
                                            const newEvent = _.cloneDeep(event)
                                            newEvent.target.value = generatedId
                                            this.handleIdChange(newEvent)
                                        }}
                                        autoComplete="off"
                                    />
                                    {
                                        !this.state.validId
                                            ?
                                            <HelpBlock>Identifier is not valid</HelpBlock>
                                            :
                                            []
                                    }
                                </Col>
                            </FormGroup>
                        </Form>
                    </Panel.Body>
                    <Panel.Footer>
                        <Button onClick={this.publish} disabled={this.publishDisabled()}>Publish</Button>
                    </Panel.Footer>
                </Panel>
                {
                    this.state.error ? (
                        <Alert bsStyle="danger">
                            <strong>Error publishing recommendation category</strong> {this.state.error}
                        </Alert>
                    ) : []
                }
            </PageState>
        )
    }

    // Methods

    publishDisabled(): boolean {
        return !this.state.dirty || !this.state.validId || !this.state.validName
    }

    generatedIdentifier(input: string): string {
        const trimmedInput = input.trim()
        if (!trimmedInput) {
            return ""
        }
        return trimmedInput
            .toLowerCase()
            .replace(/ø/g, "oe")
            .replace(/æ/g, "ae")
            .replace(/å/g, "aa")
            .replace(/\W/g, "_") || ""
    }

    isValidIdentifier(identifier: string): boolean {
        const index = _.find((this.state.categories || []), category => {
            return category.id === identifier
        })
        return index === undefined && identifier !== ""
    }

    isValidName(name: L10nString): boolean {
        return !name.hasEmptyLocalizations()
    }

    handleNameChange = (event: any) => {
        const target = event.target
        const value = target.value

        const category = this.state.editingCategory
        category.name = new L10nString(value)

        this.setState({ dirty: true, error: null, validName: this.isValidName(category.name) })
    }

    handleIdChange = (event: any) => {
        const target = event.target
        const value = target.value

        const valid = this.isValidIdentifier(value)
        const category = this.state.editingCategory
        category.id = value

        this.setState({ dirty: true, error: null, validId: valid })
    }

    async load() {
        const accountId = this.props.role.account_id

        const categoryKey = this.props.match.params.categoryKey !== "new" ? this.props.match.params.categoryKey : null
        let editingCategory = this.state.editingCategory
        const categories: RecommendationCategory[] = []

        const recommendationCategoriesSnap = await ref().child(`/v1/accounts/${accountId}/inventory/recommendation_categories`).once("value")
        if (recommendationCategoriesSnap && recommendationCategoriesSnap.exists()) {
            const value = recommendationCategoriesSnap.val()
            let index = 0
            for (const key in value) {
                const categoryJson = value[key]
                const category = new RecommendationCategory(categoryJson, index + 1)
                if (categoryJson.id === categoryKey) {
                    editingCategory = category
                }
                index += 1
                categories.push(category)
            }
        }

        const state = {
            categories: categories,
            loading: false,
            editingCategory: editingCategory,
            validId: this.isValidIdentifier(editingCategory.id),
            validName: this.isValidName(editingCategory.name)
        }

        this.setState(state)
    }

    recommendationCategoriesRef() {
        return ref().child(`v1/accounts/${this.props.role.account_id}/inventory/recommendation_categories`)
    }

    publish = async () => {
        const category = this.state.editingCategory
        let rank = category.rank
        if (rank === 0) {
            rank = this.state.categories.length + 1
        }

        const categoryJson = category.json()

        this.setState({ publishing: true })

        try {
            await this.recommendationCategoriesRef().child(`${rank - 1}`).set(categoryJson)
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.props.history.push("/product_recommendations")
    }
}

export default withRouter(RecommendationCategoryEdit)
