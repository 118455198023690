import * as React from "react"
import { ToggleButton } from "../../ToggleButton"
import {
    Button,
    Col,
    ControlLabel,
    Form,
    FormGroup,
    Panel,
} from "react-bootstrap"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router"

interface CapabilitiesConfigurationProps extends RouteComponentProps<any> {
    role: Role
}

interface CapabilitiesConfigurationState {
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
    supported: string[]
    selectedSupported: string[]
}

class CapabilitiesConfiguration extends React.Component<CapabilitiesConfigurationProps, CapabilitiesConfigurationState> {

    constructor(props: CapabilitiesConfigurationProps) {
        super(props)

        this.state = {
            loaded: false,
            publishing: false,
            showSuccess: false,
            supported: ["click_and_collect", "reserve_and_collect", "ship_from_store", "inventory_transfer"],
            selectedSupported: []
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const shop = this.props.match.params.shopKey

        const supportedHandlingRef = ref().child(`v1/accounts/${account}/shops/${shop}/configuration/order_handling/supported`)
        const supportedSnapshot = await supportedHandlingRef.once("value")

        if (!supportedSnapshot || !supportedSnapshot.exists()) {
            this.setState({
                loaded: true
            })
            return
        } else {
            const selectedSupported = supportedSnapshot.val()
            this.setState({
                loaded: true,
                selectedSupported: selectedSupported
            })
        }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} publishing={this.state.publishing} typeName="Order handling configuration">
                <Panel key="upper_panel" bsStyle={this.state.showSuccess ? "success" : "default"}>
                    <Panel.Heading>
                        <Panel.Title>{this.state.showSuccess ? "Order handling configuration updated successfully" : "Order handling configuration"}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <div />
                        <Form horizontal={true}>
                            <FormGroup>
                                {this.state.supported.map((value) => {
                                    const currentEnabledState = this.setEnabledState(value)
                                    return (
                                        <div key={value}>
                                            <Col componentClass={ControlLabel} sm={2}>{this.setTitle(value)}</Col>
                                            <Col sm={10}>
                                                <ToggleButton isEnabled={currentEnabledState} performToggle={async () => { this.updateSelectedSupported(value, currentEnabledState) }} />
                                                <br /><br />
                                            </Col>
                                        </div>
                                    )
                                }
                                )}
                            </FormGroup>
                        </Form>
                    </Panel.Body>
                    <Panel.Footer>
                        <Button onClick={this.publish} >Publish</Button>
                    </Panel.Footer>
                </Panel >
            </PageState>
        )
    }

    private setEnabledState(value: string): boolean {
        return this.state.selectedSupported.includes(value)
    }

    private updateSelectedSupported(value: string, currentEnabledState: boolean) {
        var selectedSupported = this.state.selectedSupported

        if (currentEnabledState) {
            const index = selectedSupported.findIndex((element: string) => element === value)
            if (index !== -1) {
                selectedSupported.splice(index, 1)
            }
        } else {
            selectedSupported.push(value)
        }

        this.setState({
            selectedSupported: selectedSupported
        })
    }

    private setTitle(value: string): string {
        switch (value) {
            case "click_and_collect": return "Click & Collect"
            case "reserve_and_collect": return "Reserve & Collect"
            case "ship_from_store": return "Ship from Store"
            case "inventory_transfer": return "Inventory transfer"
            default: return ""
        }
    }

    supportedUpdate = (data: any) => {
        const supported: string[] = Object.values(data)
        this.setState({ selectedSupported: supported })
    }

    publish = async () => {
        const account = this.props.role.account_id
        const shop = this.props.match.params.shopKey
        const shopConfigPath = `v1/accounts/${account}/shops/${shop}/configuration`
        const capabilitiesRef = ref().child(`${shopConfigPath}/order_handling/supported`)

        this.setState({ publishing: true })

        await capabilitiesRef.set(this.state.selectedSupported)

        this.setState({ showSuccess: true, publishing: false })
    }
}

export default withRouter(CapabilitiesConfiguration)
