import * as React from "react"
import { Globals } from "../helpers/globals"
import { Market } from "../models/MarketModels"
import { ButtonGroup, DropdownButton, MenuItem } from "react-bootstrap"
import _, { isNil } from "lodash"
import { Role } from "../config/role"
import { ref } from "../config/constants"

interface CurrentMarketPickerProps {
    resolveMarkets?: () => string[]
    onChange: (market: Market) => void
    currentMarket: Market | null
    autoselect?: boolean
    role: Role
}

interface CurrentMarketPickerState {
    markets: Market[]
    shopMarket?: string
}

export class CurrentMarketPicker extends React.Component<CurrentMarketPickerProps, CurrentMarketPickerState> {
    constructor(props: CurrentMarketPickerProps) {
        super(props)

        this.state = {
            markets: []
        }
    }

    async componentDidMount() {
        const markets = await Globals.shared.getMarkets()
        const shopMarketId = await this.getShopMarketId()

        this.setState({ shopMarket: shopMarketId, markets: markets })

        if (this.props.autoselect === true && this.props.currentMarket === null && markets.length > 0) {
            if (this.props.role.isShopOwner()) {
                if (!_.isNil(shopMarketId)) {
                    const found = markets.find(market => { return market.id === shopMarketId })
                    if (!_.isNil(found)) {
                        this.props.onChange(found)
                    }
                }
            } else {
                this.props.onChange(markets[0])
            }
        }
    }

    selectMarket = (marketKey: string | null) => {
        const market = this.state.markets.find(candidate => { return candidate.id === marketKey })
        if (!market) {
            return
        }
        this.props.onChange(market)
    }

    private async getShopMarketId(): Promise<string | undefined> {
        if (!this.props.role.isShopOwner()) {
            return
        }
        const marketRef = ref().child(`v1/accounts/${this.props.role.account_id}/shops/${this.props.role.shop_id}/market`)
        const snapshot = await marketRef.once("value")
        return snapshot.val()
    }

    availableMarkets(props: CurrentMarketPickerProps = this.props): string[] {
        if (this.state.shopMarket) {
            return [this.state.shopMarket]
        } else if (props.resolveMarkets) {
            return props.resolveMarkets()
        } else {
            return this.state.markets.map(market => { return market.id })
        }
    }

    title(): string {
        const defaultTitle = "Markets"
        if (!this.state.markets) {
            return defaultTitle
        }
        if (isNil(this.props.currentMarket)) {
            return defaultTitle
        }
        const market = this.props.currentMarket
        return "Market: " + market.name
    }

    market(key: string): Market | null {
        return this.state.markets.find(m => { return m.id === key }) || null
    }

    marketName(key: string): string | null {
        const market = this.market(key)
        if (market) { return market.name }
        return null
    }

    render() {
        return (
            this.availableMarkets().length > 1 && (
                <ButtonGroup key="markets">
                    <DropdownButton
                        bsStyle="primary"
                        title={this.title()}
                        id="markets"
                        onSelect={(event: any) => { this.selectMarket(event as string) }}
                    >
                        {
                            this.availableMarkets().map(marketKey => {
                                return <MenuItem key={marketKey} eventKey={marketKey}>{this.marketName(marketKey) || "-"}</MenuItem>
                            })
                        }
                    </DropdownButton>
                </ButtonGroup>
            )
        )
    }
}
