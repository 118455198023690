import React from "react"
import { Panel, Radio } from "react-bootstrap"

interface MinimumRequirementsSelectorProps {
    type: RequirementsType
    typeChanged: (type: RequirementsType) => void
}

export type RequirementsType = "none" | "count" | "amount"

export function MinimumRequirementsSelector(props: MinimumRequirementsSelectorProps) {
    return <Panel bsStyle="primary">
        <Panel.Heading>
            <Panel.Title>Minimum requirements</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
            <Radio
                name="requirementsSelector"
                checked={props.type === "none"}
                onChange={() => { props.typeChanged("none") }}
            >
                No requirements
            </Radio>
            <Radio
                name="requirementsSelector"
                checked={props.type === "count"}
                onChange={() => { props.typeChanged("count") }}
            >
                Minimum quantity of items
            </Radio>
            <Radio
                name="requirementsSelector"
                checked={props.type === "amount"}
                onChange={() => { props.typeChanged("amount") }}
            >
                Minimum basket amount
            </Radio>
        </Panel.Body>
    </Panel>
}

