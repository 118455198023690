import * as React from "react"
import { Button, Nav, Navbar, NavItem } from "react-bootstrap"
import { Prompt } from "react-router"

interface PageStateProps {
    children?: any,
    customMessage?: string
    typeName: string,
    loading: boolean,
    publishing?: boolean,
    dirty?: boolean
    submit_disabled?: boolean

    title?: string
    submit_title?: string
    submit_action?: () => void
    discard_action?: () => void
}

export class PageState extends React.Component<PageStateProps, {}> {
    renderNavbar() {
        return (
            <Navbar key="navbar_dirty" id="bootstrap-override" className="dirty" fixedTop={true} style={{ paddingLeft: "10px", paddingRight: "30px" }}>
                <Navbar.Header>
                    <Navbar.Text>
                        {this.props.title ?? "Unpublished changes"}
                    </Navbar.Text>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav pullRight={true}>
                        {this.props.discard_action ?
                            (
                                <NavItem>
                                    <Button bsStyle="danger" onClick={() => { this.props.discard_action?.() }}>Discard</Button>
                                </NavItem>
                            ) : null}
                        {this.props.submit_action ? (
                            <NavItem>
                                <Button disabled={this.props.submit_disabled ?? false} onClick={() => { this.props.submit_action?.() }}>{this.props.submit_title ?? "Publish"}</Button>
                            </NavItem>
                        ) : null}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
    render() {
        const scheme = document.querySelector("meta[name=\"theme-color\"]")
        const color = (this.props.dirty === true) ? "rgb(57,52,90)" : "white"
        scheme?.setAttribute("content", color)

        if (this.props.customMessage) {
            return <h1 key="custom">{this.props.customMessage}...</h1>
        } else if (this.props.loading) {
            return <h1 key="loading">Loading {this.props.typeName}...</h1>
        } else if (this.props.publishing || false) {
            return <h1 key="publishing">Publishing {this.props.typeName}...</h1>
        } else if (this.props.dirty === true) {
            return [
                this.renderNavbar(),
                (
                    <Prompt
                        key="prompt"
                        when={this.props.dirty ?? false}
                        message={location =>
                            `You have unsaved ${this.props.typeName} changes. Do you wish to navigate away and discard changes?`
                        }
                    />
                ),
                <div key="content">{this.props.children}</div>
            ]
        } else {
            return <div key="content">{this.props.children}</div>
        }
    }
}
