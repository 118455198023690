import React, { useState } from "react"
import { Button, DropdownButton, FormGroup, MenuItem, Panel, Radio } from "react-bootstrap"
import { LanguageCode } from "../../../helpers/L10n"
import { ProductObserver } from "../../../helpers/productObserver"
import { TagObserver } from "../../../helpers/tagObserver"
import { AttributeObserver } from "../../../helpers/attributeObserver"
import { Attribute, Product, Tag } from "../../../models/Product"
import { Thumbnail } from "../../../components/Thumbnail"
import { StripedTable } from "../../../components/StripedTable"
import _ from "lodash"

interface ProductElementProps {
    product?: Product
    remove: (key: string) => void
}

function ProductElement(props: ProductElementProps) {
    if (!props.product) {
        return <span />
    }
    return <tr><td className="narrow"><Thumbnail src={props.product.image_url} /></td><td>{props.product.localizedName(LanguageCode.da)}<Button className="pull-right" bsStyle="link" onClick={() => { props.remove(props.product!.id) }}><DeleteButton /></Button></td></tr>
}

interface TagElementProps {
    tag?: Tag
    remove: (key: string) => void
}
function TagElement(props: TagElementProps) {
    if (!props.tag) {
        return <span />
    }
    return <tr><td>{props.tag.name.localized(LanguageCode.da)}<Button className="pull-right" bsStyle="link" onClick={() => { props.remove(props.tag!.tag) }}><DeleteButton /></Button></td></tr>
}

export function DeleteButton() {
    return <span role="img" aria-label="delete button">✖️</span>
}

interface AttributeElementProps {
    attribute?: Attribute
    option: string
    remove: (key: string) => void
}
function AttributeElement(props: AttributeElementProps) {
    if (!props.attribute) {
        return <span />
    }
    const option = (props.attribute!.type.options ?? {})[props.option]
    return <tr><td>{props.attribute.name.localized(LanguageCode.da)}: {option.name.localized(LanguageCode.da)}<Button style={{ float: "right" }} bsStyle="link" onClick={() => { props.remove(props.attribute!.id) }}><DeleteButton /></Button></td></tr>
}

interface SelectAttributeOptionProps {
    attribute?: Attribute
    optionSelected: (attributeId: string, optionId: string) => void
}

function SelectAttributeOption(props: SelectAttributeOptionProps) {
    const options = (props.attribute!.type.options ?? {})
    return <DropdownButton title={`Select option for ${props.attribute!.name.localized(LanguageCode.da)}`} id="a">
        {Object.keys(options).map(key => {
            const option = options[key]

            return <MenuItem key={option.id} onSelect={() => {
                props.optionSelected(props.attribute!.id, key)
            }} eventKey={option.id}>{option.name.localized(LanguageCode.da)}</MenuItem>
        })}
    </DropdownButton>
}

interface AppliesToSelectorProps {
    appliesTo: AppliesTo
    appliesToChanged: (appliesTo: AppliesTo) => void
    productObserver: ProductObserver
    tagsObserver: TagObserver
    attributesObserver: AttributeObserver
    validation: boolean
    bundle?: boolean
}

export interface AppliesTo {
    type: AppliesToType
    products: string[]
    tags: string[]
    attributes: AttributeSelection[]
}

interface AttributeSelection {
    attributeId: string
    optionId: string
}

export type AppliesToType = "all" | "products" | "tags" | "attributes"


export function AppliesToSelectorPanel(props: AppliesToSelectorProps) {
    return <Panel bsStyle="primary">
        <Panel.Heading>
            <Panel.Title>Applies to</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
            <AppliesToSelector validation={props.validation} appliesTo={props.appliesTo} appliesToChanged={props.appliesToChanged} productObserver={props.productObserver} tagsObserver={props.tagsObserver} attributesObserver={props.attributesObserver} />
        </Panel.Body>
    </Panel>
}

export function AppliesToSelector(props: AppliesToSelectorProps) {
    const [selectAttributeOption, setSelectAttributeOption] = useState<string | undefined>(undefined)

    function setProducts(value: string[]) {
        if (_.isEqual(value, props.appliesTo.products)) {
            return
        }
        const clone = _.clone(props.appliesTo)
        clone.products = value
        props.appliesToChanged(clone)
    }

    function setType(type: AppliesToType) {
        if (type === props.appliesTo.type) {
            return
        }
        const clone = _.cloneDeep(props.appliesTo)
        clone.type = type
        props.appliesToChanged(clone)
    }

    function setTags(tags: string[]) {
        if (_.isEqual(tags, props.appliesTo.tags)) {
            return
        }
        const clone = _.cloneDeep(props.appliesTo)
        clone.tags = tags
        props.appliesToChanged(clone)
    }

    function setAttributes(attributes: AttributeSelection[]) {
        if (_.isEqual(attributes, props.appliesTo.attributes)) {
            return
        }
        const clone = _.cloneDeep(props.appliesTo)
        clone.attributes = attributes
        props.appliesToChanged(clone)
    }

    return <FormGroup
        style={{ marginLeft: "0px", marginRight: "0px" }}
        validationState={props.validation ? null : "error"}
    >

        <div>
            {props.bundle !== true && <Radio
                name="appliesToSelector"
                checked={props.appliesTo.type === "all"}
                onChange={() => { setType("all") }}
            >
                All products
            </Radio>
            }
            <Radio
                name="appliesToSelector"
                checked={props.appliesTo.type === "products"}
                onChange={() => { setType("products") }}
            >
                Specific products
            </Radio>
            <Radio
                name="appliesToSelector"
                checked={props.appliesTo.type === "tags"}
                onChange={() => { setType("tags") }}
            >
                Products with specific tags
            </Radio>
            <Radio
                name="appliesToSelector"
                checked={props.appliesTo.type === "attributes"}
                onChange={() => { setType("attributes") }}
            >
                Products with specific attribute options
            </Radio>
        </div>

        {props.appliesTo.type !== "all" && <hr />}
        {props.appliesTo.type === "products" &&
            <div>
                {props.appliesTo.products.length > 0 &&
                    <StripedTable>
                        <thead><tr><td colSpan={2}>
                            {props.bundle === true ? "Bundle must contain some of the products below" : "Apply discount for any of the products below"}
                        </td></tr></thead>
                        <tbody>
                            {props.appliesTo.products.map(key => {
                                return <ProductElement key={key} remove={key => {
                                    const clone = props.appliesTo.products.filter(element => { return element !== key })
                                    setProducts(clone)
                                }} product={(props.productObserver.productsDict ?? {})[key]} />
                            })}
                        </tbody>
                    </StripedTable>
                }
                <DropdownButton title="Select one or more products" id="a">
                    {(props.productObserver.productsArray ?? []).map(product => {
                        if (props.appliesTo.products.includes(product.id)) {
                            return undefined
                        }
                        return <MenuItem key={product.id} onSelect={() => {
                            const clone = [...props.appliesTo.products]
                            clone.push(product.id)
                            setProducts(clone)
                        }} eventKey={product.id}>{product.localizedName(LanguageCode.da)}</MenuItem>
                    })}
                </DropdownButton>
            </div>
        }
        {props.appliesTo.type === "tags" &&
            <div>
                {props.appliesTo.tags.length > 0 &&
                    <StripedTable>
                        <thead><tr><td>
                            {props.bundle === true ? "Bundle must contain products that has any of the tags below" : "Apply discount if the product has any of the tags below"}
                        </td></tr></thead>
                        <tbody>
                            {props.appliesTo.tags.map(key => {
                                return <TagElement key={key} remove={key => {
                                    const clone = props.appliesTo.tags.filter(element => { return element !== key })
                                    setTags(clone)
                                }} tag={(props.tagsObserver.tagsDict ?? {})[key]} />
                            })}
                        </tbody>
                    </StripedTable>
                }
                <DropdownButton title="Select one or more tags" id="a">
                    {(props.tagsObserver.tagsArray ?? []).map(tag => {
                        if (props.appliesTo.tags.includes(tag.tag)) {
                            return undefined
                        }

                        return <MenuItem key={tag.tag} onSelect={() => {
                            const clone = [...props.appliesTo.tags]
                            clone.push(tag.tag)
                            setTags(clone)
                        }} eventKey={tag.tag}>{tag.name.localized(LanguageCode.da)}</MenuItem>
                    })}
                </DropdownButton>
            </div>
        }
        {props.appliesTo.type === "attributes" &&
            <div>
                {props.appliesTo.attributes.length > 0 &&
                    <StripedTable>
                        <thead><tr><td>
                            {props.bundle === true ? "Bundle must contain products that has the specified value for any of the attributes below" : "Apply discount if the product has the specified value for any of the attributes below"}
                        </td></tr></thead>
                        <tbody>
                            {props.appliesTo.attributes.map(key => {
                                return <AttributeElement key={key.attributeId} remove={key => {
                                    const clone = props.appliesTo.attributes.filter(element => { return element.attributeId !== key })
                                    setAttributes(clone)
                                }} attribute={(props.attributesObserver.attributesDict ?? {})[key.attributeId]} option={key.optionId} />
                            })}
                        </tbody>
                    </StripedTable>
                }
                {selectAttributeOption !== undefined ?
                    <SelectAttributeOption attribute={(props.attributesObserver.attributesDict ?? [])[selectAttributeOption]} optionSelected={(attributeId, optionId) => {
                        const clone = [...props.appliesTo.attributes]
                        clone.push({ attributeId: attributeId, optionId: optionId })
                        setAttributes(clone)
                        setSelectAttributeOption(undefined)
                    }} /> :
                    <DropdownButton title="Select one or more option attributes" id="a">
                        {(props.attributesObserver.attributesArray ?? []).map(attribute => {
                            // if (selectedAttributes.find(entry => { return entry.attributeId === attribute.id }) !== undefined) {
                            //     return undefined
                            // }
                            if (attribute.type.options === undefined) {
                                return undefined
                            }

                            return <MenuItem key={attribute.id} onSelect={() => {
                                setSelectAttributeOption(attribute.id)
                            }} eventKey={attribute.id}>{attribute.name.localized(LanguageCode.da)}</MenuItem>
                        })}
                    </DropdownButton>
                }
            </div>
        }
    </FormGroup>
}

