//
//  GroupSelection.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 13/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import firebase from "firebase/compat"
import { ref } from "../config/constants"
import { forEach, head, remove } from "lodash"
import { Col, ControlLabel, FormGroup, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { ProductGroup } from "../models/Product"
import { LanguageCode } from "../helpers/L10n"

interface GroupSelectionProps {
    account: string
    selectedGroup: string | null
    currentLanguage: LanguageCode | null
    onChange: (group: ProductGroup | null) => void
}

interface GroupSelectionState {
    groups: ProductGroup[]
    selectedGroup: string | null
}

export class GroupSelection extends React.Component<GroupSelectionProps, GroupSelectionState> {

    // Properties

    initialState: GroupSelectionState = {
        selectedGroup: null,
        groups: []
    }

    // Component

    constructor(props: GroupSelectionProps) {
        super(props)

        this.state = this.initialState
    }

    componentWillReceiveProps(nextProps: GroupSelectionProps) {
        this.setState({ selectedGroup: nextProps.selectedGroup || null })
    }

    async componentDidMount() {
        this.setState({ selectedGroup: this.props.selectedGroup || null })

        const snapshot = await this.groupsRef().once("value")

        if (!snapshot || (snapshot && !snapshot.exists())) {
            this.setState({ groups: [] })
            return
        }

        const groups: ProductGroup[] = []
        forEach(snapshot.val(), (value) => {
            groups.push(new ProductGroup(value))
        })

        const sorted = groups.sort((lhs, rhs) => {
            return lhs.name.localized(this.props.currentLanguage) > rhs.name.localized(this.props.currentLanguage) ? -1 : 1
        })

        this.setState({
            groups: sorted
        })

    }

    componentWillUnmount() {
        this.groupsRef().off()
    }

    render() {
        return (
            <section>
                {
                    this.state.groups ? (
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>Group</Col>
                            <Col sm={10}>
                                <ToggleButtonGroup
                                    type="checkbox"
                                    value={this.state.selectedGroup}
                                    onChange={(data: any) => { this.onChange(data) }}
                                >
                                    {
                                        this.state.groups.map((group) => {
                                            return <ToggleButton key={group.group} value={group.group}>{group.name.localized(this.props.currentLanguage) || group.name.value}</ToggleButton>
                                        })
                                    }
                                </ToggleButtonGroup>
                            </Col>

                        </FormGroup>
                    ) : <div>Loading..</div>
                }
            </section>
        )
    }

    // Helpers

    onChange(data: any) {
        // Remove already selected group from array of strings
        const strings: string[] = data
        if (this.state.selectedGroup !== null) {
            remove(strings, (val) => { return val === this.state.selectedGroup })
        }

        // ...which in turn guarantees that we only have one string left in the array
        const identifier = head(strings) as string
        let group: ProductGroup | null = null

        // ..for which we lookup the corresponding product group
        forEach(this.state.groups, (value) => {
            if (value.group === identifier) {
                group = value
            }
        })

        // ..and hey presto!
        this.props.onChange(group)
    }

    groupsRef(): firebase.database.Reference {
        return ref().child(`v1/accounts/${this.props.account}`).child("inventory/product_groups")
    }
}
