import * as React from "react"
import {
    Col,
    ControlLabel,
    Form,
    FormGroup,
} from "react-bootstrap"
import ImageDropControl from "../../ImageDropControl"
import { ModuleProps } from "../Configuration/ModuleConfiguration"

export class SecondScreenBasketModule extends React.Component<ModuleProps, {}> {

    constructor(props: ModuleProps) {
        super(props)
        this.state = {
        }
    }

    // Helpers

    logoValidate = (width: number, height: number) => {
        return width <= 2732 && height <= 2048
    }

    setSecondScreenEmptyLogo = (url: string | null) => {
        this.props.updateConfiguration(data => {
            data.empty_basket_logo_url = url
        })
    }

    // Component

    render() {
        const account = this.props.role.account_id
        const secondScreenBasketEmptyLogoUrl = this.props.moduleData?.empty_basket_logo_url

        return (
            <Form horizontal={true}>
                <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>Empty basket logo</Col>
                    <Col sm={10}>
                        <ImageDropControl
                            fileName="empty_basket_logo"
                            filePath={account + "/configuration/modules/second_screen_basket/"}
                            imageURL={secondScreenBasketEmptyLogoUrl}
                            dropTitle="Max size 2732×2048 2MB"
                            validateSize={this.logoValidate}
                            deleteAction={() => { this.setSecondScreenEmptyLogo(null) }}
                            uploadAction={(url) => { this.setSecondScreenEmptyLogo(url) }}
                        />
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}
