import * as React from "react"
import FrontPageSectionsViewModel from "./FrontPageSectionsViewModel"
import {
    Button,
    Modal,
    Panel
} from "react-bootstrap"
import {
    DragDropContext,
    Draggable,
    Droppable
} from "react-beautiful-dnd"
// import { LanguageCode } from "../../../helpers/L10n"
import { PageState } from "../../PageState"
import { Role } from "../../../config/role"
import { StripedTable } from "../../StripedTable"
import { allSectionTypes, FrontPageSectionType, FrontPageSection, sectionTypeName, sectionTypeDescription } from "./FrontPageSectionModels"
import { FrontPageSectionEdit } from "./FrontPageSectionEdit"
import _ from "lodash"

// these css styles are a feeble attempt to make the table rows not look too bad while being dragged....

const narrowStyle = {
    width: "10%",
    textAlign: "center" as "center"
}

const nameStyle = {
    width: "80%",
    textAlign: "left" as "left"
}

interface FrontPageSectionsPanelProps {
    role: Role
}

interface FrontPageSectionsPanelState {
    dirty: boolean
    loaded: boolean
    sections: FrontPageSection[]
    publishing: boolean
    editedSectionIndex?: number
    addSection: boolean
}

export class FrontPageSectionsPanel extends React.Component<FrontPageSectionsPanelProps, FrontPageSectionsPanelState> {

    private viewModel: FrontPageSectionsViewModel

    constructor(props: FrontPageSectionsPanelProps) {
        super(props)
        this.viewModel = new FrontPageSectionsViewModel(props.role.account_id)
        this.viewModel.sectionsUpdated = this.sectionsUpdated.bind(this)
        this.state = {
            dirty: false,
            loaded: false,
            sections: [],
            publishing: false,
            addSection: false
        }
    }

    // Signals from vm

    sectionsUpdated = (sections: FrontPageSection[]) => {
        this.setState({
            loaded: true,
            sections: sections
        })
    }

    // Signals to vm

    onRemove = (index: number) => {
        const name = this.viewModel.nameOfSectionAtIndex(index)
        if (window.confirm(`Really remove the section "${name}"?`) === true) {
            this.viewModel.handleRemoveSection(index)
            this.setState({ dirty: true })
        }
    }

    onDragEnd = (result: any) => {
        const didChange = this.viewModel.handleSingleReordering(result)
        if (didChange) {
            this.setState({ dirty: true })
        }
    }

    onPublish = async () => {
        this.setState({ publishing: true })
        await this.viewModel.publish()
        this.setState({ dirty: false, publishing: false })
    }

    onEditComplete = (section?: FrontPageSection) => {
        const newState: any = { editedSectionIndex: undefined }
        if (!_.isNil(section) && !_.isNil(this.state.editedSectionIndex)) {
            this.viewModel.handleSectionUpdate(this.state.editedSectionIndex, section)
            newState.dirty = true
        }
        this.setState(newState)
    }

    // Component

    componentDidMount() {
        this.viewModel.start()
    }

    componentWillUnmount() {
        this.viewModel.stop()
    }

    addSectionType = (type: FrontPageSectionType) => {
        this.viewModel.handleSectionAdd(type)
        this.setState({ dirty: true, addSection: false })
    }

    render() {
        return (
            <PageState dirty={this.state.dirty} loading={!this.state.loaded} typeName="sections" publishing={this.state.publishing}>
                <Panel key="sections">
                    <Panel.Heading>
                        <Panel.Title>Sections</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <h4>Concept</h4>
                        <p>Sections on the configurable front page is a list of section elements. Each section can have a different type, for instance containing shortcuts, curated products or most popular products.</p>
                        <p>The sections can be mixed and matched, and you can add as many as you would like.</p>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(droppableProvided) => (
                                    <div ref={droppableProvided.innerRef}>
                                        <StripedTable>
                                            <thead>
                                                <tr>
                                                    <th style={narrowStyle}>Order</th>
                                                    <th style={nameStyle}>Name</th>
                                                    <th style={nameStyle}>Type</th>
                                                    <th style={narrowStyle}>Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.sections.map((section, index) => (
                                                    <Draggable key={`${section.id}`} draggableId={`${section.id}`} index={index}>
                                                        {(draggableProvided: any) => (
                                                            <tr
                                                                ref={draggableProvided.innerRef}
                                                                key={`${section.id}`}
                                                                {...draggableProvided.draggableProps}
                                                                {...draggableProvided.dragHandleProps}
                                                                onClick={() => {
                                                                    this.setState({ editedSectionIndex: index })
                                                                }
                                                                }
                                                            >
                                                                <td style={narrowStyle}>{index + 1}</td>
                                                                <td style={nameStyle}>
                                                                    {section.title()}
                                                                </td>
                                                                <td style={nameStyle}>
                                                                    {sectionTypeName(section.type)}
                                                                </td>
                                                                <td style={narrowStyle}>
                                                                    <Button
                                                                        bsStyle="danger"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation()
                                                                            this.onRemove(index)
                                                                        }}
                                                                    >
                                                                        X
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </tbody>
                                        </StripedTable>
                                        {droppableProvided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <Button onClick={() => { this.setState({ addSection: true }) }}>Add section</Button>
                    </Panel.Body>
                    <Panel.Footer>
                        <Button onClick={this.onPublish} disabled={!this.state.dirty}>Publish</Button>
                    </Panel.Footer>
                </Panel>
                {!_.isNil(this.state.editedSectionIndex) ?
                    (
                        <FrontPageSectionEdit
                            role={this.props.role}
                            section={_.cloneDeep(this.state.sections[this.state.editedSectionIndex])}
                            done={this.onEditComplete}
                        />
                    )
                    : null}

                {this.state.addSection ?
                    (
                        <Modal.Dialog>
                            <Modal.Header>
                                <Modal.Title>
                                    <span>Add section</span>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <StripedTable>
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th style={nameStyle}>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allSectionTypes.map((type, index) => (
                                            <tr key={index} onClick={() => this.addSectionType(type)}>
                                                <td>
                                                    {sectionTypeName(type)}
                                                </td>
                                                <td style={nameStyle}>
                                                    {sectionTypeDescription(type)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </StripedTable>
                            </Modal.Body >

                            <Modal.Footer>
                                <Button onClick={() => this.setState({ addSection: false })}>Cancel</Button>
                            </Modal.Footer>

                        </Modal.Dialog >
                    )
                    : null}
            </PageState>
        )
    }
}
