import * as React from "react"
import { FormGroup, Col, FormControl, ControlLabel, HelpBlock } from "react-bootstrap"
import { isNil } from "lodash"
import firebase from "firebase/compat"

export interface ValidatingIdEntryControlState {
    newOverrideId: string | null
    idInUse: boolean
}

export interface ValidatingIdEntryControlProps {
    collectionRef: firebase.database.Reference
    isNew: boolean
    typeName: string
    identifierSource: string | null
    existingIdentifier: string
    handleIdChange: (id: string, valid: boolean) => void
    invalidIdentifiers?: string[]
    showExistingIdentifier?: boolean
}

export class ValidatingIdEntryControl extends React.Component<ValidatingIdEntryControlProps, ValidatingIdEntryControlState> {
    constructor(props: ValidatingIdEntryControlProps) {
        super(props)
        this.state = {
            newOverrideId: null,
            idInUse: false
        }
    }

    async componentWillReceiveProps(nextProps: ValidatingIdEntryControlProps) {
        if (nextProps.identifierSource !== this.props.identifierSource) {
            await this.testIdentifierClash(nextProps)
            const id = this.newIdentifier(nextProps)
            this.props.handleIdChange(id, !this.state.idInUse)
        }
    }

    newIdentifier(props: ValidatingIdEntryControlProps) {
        if (!props.isNew) {
            return props.existingIdentifier
        }
        if (!isNil(this.state.newOverrideId)) {
            return this.state.newOverrideId.trim()
        } else {
            return this.generatedIdentifier(props).trim()
        }
    }

    generatedIdentifier(props: ValidatingIdEntryControlProps) {
        if (!props.identifierSource) {
            return ""
        }
        return props.identifierSource
            .toLowerCase()
            .replace(/ø/g, "oe")
            .replace(/æ/g, "ae")
            .replace(/å/g, "aa")
            .replace(/\W/g, "_") || ""
    }

    async testIdentifierClash(props: ValidatingIdEntryControlProps) {
        if (!this.props.isNew) {
            return
        }
        this.setState({ idInUse: false })
        const idToTest = this.newIdentifier(props)
        if (idToTest.length === 0) {
            return
        }
        if ((this.props.invalidIdentifiers ?? []).includes(idToTest)) {
            this.setState({ idInUse: true })
            return
        }
        const itemRef = this.props.collectionRef.child(idToTest)
        const snap = await itemRef.once("value")
        // if value has changed while we were fetching, don't do anything
        if (this.newIdentifier(props) !== idToTest) {
            return
        }
        if (snap.exists()) {
            this.setState({ idInUse: true })
        }
    }

    handleIdChange(event: any) {
        const target = event.target
        let value = target.value

        let overrideId: string | null = null

        // * is also included since it's used in some composite keys when appending product id and variant id. Stats and stock count use it.
        const invalidCharacters = [".", "[", "]", "/", "#", "$", "*"]
        for (const character of invalidCharacters) {
            value = value.replace(new RegExp(`\\${character}`, "g"), "_")
        }

        if (value === this.generatedIdentifier(this.props)) {
            overrideId = null
        } else {
            overrideId = value
        }

        this.setState({ newOverrideId: overrideId }, async () => {
            await this.testIdentifierClash(this.props)
            this.props.handleIdChange(this.newIdentifier(this.props), !this.state.idInUse)
        })
    }

    renderNew() {
        return (
            <FormGroup validationState={this.state.idInUse ? "error" : null}>
                <Col componentClass={ControlLabel} sm={2}>Identifier</Col>
                <Col sm={10}>
                    <FormControl
                        type="text"
                        name={this.props.typeName}
                        value={this.newIdentifier(this.props)}
                        placeholder={`Enter ${this.props.typeName} identifier`}
                        onChange={event => { this.handleIdChange(event) }}
                        autoComplete="off"
                    />
                    {this.state.idInUse ? <HelpBlock>Identifier '{this.newIdentifier(this.props)}' is already in use or invalid</HelpBlock> : []}
                </Col>
            </FormGroup>
        )
    }

    renderExisting() {
        return (
            <FormGroup>
                <Col componentClass={ControlLabel} sm={2}>Identifier</Col>
                <Col sm={10}>
                    <FormControl
                        type="text"
                        disabled={true}
                        name={this.props.typeName}
                        value={this.newIdentifier(this.props)}
                        placeholder={`Enter ${this.props.typeName} identifier`}
                        autoComplete="off"
                    />
                </Col>
            </FormGroup>
        )
    }

    render() {
        if (this.props.isNew) {
            return this.renderNew()
        } else if (this.props.showExistingIdentifier === true) {
            return this.renderExisting()
        } else {
            return null
        }
    }
}
