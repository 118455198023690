import {
    L10nString,
    LanguageCode
    } from "./L10n"
import {
    Product,
    Variant
    } from "../models/Product"
import { StockCountProductInfo } from "../models/StockCountModels"

export const initializingProductName = "...initializing..."

export function productName(product: Product, variant: Variant | null, language: LanguageCode | null): string {
    let name
    if (product.name && variant && variant.name) {
        name = product.name.localized(language) + " · " + variant.name.localized(language)
    } else if (variant && variant.name) {
        name = variant.name.localized(language)
    } else if (product.name) {
        name = product.name.localized(language)
    } else {
        name = ""
    }
    const dimensionValues: string[] = []
    if (product.dimensions !== undefined) {
        product.dimensions.forEach(dimension => {
            if (variant && variant.dimension_values && variant.dimension_values[dimension.id]) {
                const valueId = variant.dimension_values[dimension.id]
                dimension.values.forEach(value => {
                    if (valueId === value.id) {
                        dimensionValues.push(value.name.localized(language))
                    }
                })
            }
        })
        if (dimensionValues.length > 0) {
            name += " · " + dimensionValues.join(", ")
        }
    }
    return name
}

export function soldProductName(lineItem: any, language: LanguageCode | null): string {
    let name: string
    if (lineItem.name) {
        const l10n = new L10nString(lineItem.name)
        name = l10n.localized(language)
    } else {
        name = ""
    }
    if (lineItem.variant_name) {
        const l10n = new L10nString(lineItem.variant_name)
        const variantName = l10n.localized(language)
        if (name.length === 0) {
            name = variantName
        } else {
            name = name + " · " + variantName
        }
    }

    const dimensionValues: string[] = []
    if (lineItem.dimensions !== undefined) {
        lineItem.dimensions.forEach((dimension: any) => {
            if (dimension.value && dimension.value.name) {
                const l10n = new L10nString(dimension.value.name)
                dimensionValues.push(l10n.localized(language))
            }
        })
        if (dimensionValues.length > 0) {
            name += " · " + dimensionValues.join(", ")
        }
    }
    return name
}

export function countedProductName(productInfo: StockCountProductInfo, disconnected: boolean, language: LanguageCode | null, includeVariantName?: boolean): string {
    const includeVariant = includeVariantName !== undefined ? includeVariantName : true

    let name
    if (productInfo.deleted) {
        name = (productInfo.productName || new L10nString("-")).localized(language)
    } else if (productInfo.productName && productInfo.variantName && includeVariant) {
        name = productInfo.productName.localized(language) + " · " + productInfo.variantName.localized(language)
    } else if (productInfo.variantName && includeVariant) {
        name = productInfo.variantName.localized(language)
    } else if (productInfo.productName) {
        name = productInfo.productName.localized(language)
    } else {
        name = initializingProductName
    }

    if (includeVariant) {
        const dimensionValues: string[] = []
        if (productInfo.dimensions !== undefined) {
            productInfo.dimensions.forEach(dimension => {
                dimensionValues.push(dimension.value.name.localized(language))
            })
            if (dimensionValues.length > 0) {
                name += " · " + dimensionValues.join(", ")
            }
        }
    }

    if (disconnected) {
        name += " (Product will be disconnected from stock)"
    } else if (productInfo.deleted) {
        name += " (Product has been deleted)"
    }

    return name
}
