import * as _ from "lodash"
import * as Auth from "../../../helpers/auth"
import * as React from "react"
import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Modal,
    Row
    } from "react-bootstrap"
import { countedProductName } from "../../../helpers/productName"
import { LanguageCode } from "../../../helpers/L10n"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import { StockCountLineItem } from "../../../models/StockCountModels"
import { stripEmptyValues } from "../../../helpers/stripEmptyValues"
 
interface StockCountAdjustModalProps {
    lineId: string
    role: Role
    stockLocation: string
    stockCountId: string
    completed: (didAdjust: boolean) => void
}

interface StockCountAdjustModalState {
    lineItem?: StockCountLineItem
    loaded: boolean
    newStockValue: number
    publishing: boolean
    user?: any 
}

export class StockCountAdjustModal extends React.Component<StockCountAdjustModalProps, StockCountAdjustModalState> {

    // Constructor

    constructor(props: StockCountAdjustModalProps) {
        super(props)

        this.state = {
            loaded: false,
            newStockValue: 0,
            publishing: false
        }
    }

    // Methods

    async adjustButtonClicked() {
        if (!this.state.lineItem) {
            return
        }
        const lineItem: StockCountLineItem = this.state.lineItem

        const adjustment = this.state.newStockValue - (lineItem.counted || 0)
        await this.createEvent("stock_count_adjustment", adjustment)
    }

    async removeFromStockButtonClicked() {
        await this.createEvent("stock_count_disconnect")
    }

    async createEvent(type: "stock_count_adjustment" | "stock_count_disconnect", adjustment?: number) {
        if (!this.state.lineItem || !this.state.user) {
            return
        }

        const lineItem: StockCountLineItem = this.state.lineItem
        const user: { email: string, uid: string } = this.state.user

        this.setState({ publishing: true })
        
        const source = {
            device_id: "_portal",
            session_id: "_portal",
            stock_count_id: this.props.stockCountId,
            user_name: user.email,
            user_uid: user.uid
        }

        const product = lineItem.product.json()

        let stockCountAndProduct = `${this.props.stockCountId}*${lineItem.product.productId}`
        if (!_.isNil(lineItem.product.variantId)) {
            stockCountAndProduct += lineItem.product.variantId
        }
        const indexes = {
            stock_count_and_device: `${this.props.stockCountId}*_portal`,
            stock_count_and_product: stockCountAndProduct
        }

        const json: any = {
            indexes: indexes,
            product: product,
            source: source,
            timestamp: new Date().getTime() / 1000,
            type: type
        }
        if (!_.isNil(adjustment)) {
            json.adjustment = adjustment
        }

        stripEmptyValues(json)

        const path = `v1/accounts/${this.props.role.account_id}/stock_locations/${this.props.stockLocation}/inventory/stock_counts/events`
        await ref().child(path)
            .push()
            .set(json)
            .catch((error) => {
                console.log(error)
            })

        this.props.completed(true)
    }

    cancelButtonClicked() {
        this.props.completed(false)
    }

    async loadLine(): Promise<StockCountLineItem> {        
        const path = `v1/accounts/${this.props.role.account_id}/stock_locations/${this.props.stockLocation}/inventory/stock_counts/counts/${this.props.stockCountId}/lines/${this.props.lineId}`
        const snapshot = await ref().child(path).once("value")
        if (!snapshot.exists()) {
            throw new Error("Line is missing")
        }

        const json = snapshot.val()
        if (!json.product) {
            throw new Error("Product info is missing")
        }

        return new StockCountLineItem(json)
    }

    async loadUser(): Promise<any> {
        const uid = Auth.userId()
        if (!uid) {
            throw new Error("No authorized user")
        }

        const userPath = `v1/accounts/${this.props.role.account_id}/users/${uid}`
        const userSnapshot = await ref().child(userPath).once("value")
        if (!userSnapshot.exists()) {
            throw new Error("User info is missing")
        }

        return userSnapshot.val()
    }

    newStockValueChanged(value: number) {
        this.setState({ newStockValue: value })
    }

    // Component

    async componentDidMount() {
        const lineItem = await this.loadLine().catch((error) => { console.error(error) })
        const user = await this.loadUser().catch((error) => { console.error(error) })
        if (!lineItem) {
            alert("Line could not be read from database")
            this.props.completed(false)
            return
        }
        if (!user) {
            alert("User info could not be read from database")
            this.props.completed(false)
            return
        }
        this.setState({ loaded: true, lineItem: lineItem, newStockValue: lineItem.counted || 0, user: user })
    }

    render() {
        const productName = this.state.lineItem ? countedProductName(this.state.lineItem.product, this.state.lineItem.disconnect ?? false,  LanguageCode.da, true) : ""
        return (
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>
                        Adjust counted value
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PageState loading={!this.state.loaded} publishing={this.state.publishing} typeName="line">
                        <FormGroup>
                            <Row>
                                <Col componentClass={ControlLabel} sm={6}>Product</Col>
                                <Col componentClass={ControlLabel} sm={6} className="text-right">{productName}</Col>
                            </Row>
                            <Row>
                                <Col componentClass={ControlLabel} sm={6}>Count</Col>
                                <Col sm={6} className="text-right">
                                    <FormControl
                                        type="number"
                                        name="new_stock_value"
                                        value={this.state.newStockValue}
                                        placeholder="Enter shop name"
                                        onChange={(event: any) => {this.newStockValueChanged(Number(event.target.value))}}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </PageState>
                </Modal.Body>
                <Modal.Footer>
                    {
                        (this.state.lineItem?.product?.deleted === true) &&
                        <Button bsStyle="danger" onClick={async () => { await this.removeFromStockButtonClicked() }}>Remove from stock</Button>
                    }
                    <Button onClick={async () => { await this.adjustButtonClicked() }}>Adjust</Button>
                    <Button onClick={() => { this.cancelButtonClicked() }}>Cancel</Button>
                </Modal.Footer>
            </Modal.Dialog>
        )
    }
}