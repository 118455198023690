//
//  L10n.ts
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 04/06/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import { Dictionary, isNil, isString, pick } from "lodash"

export enum LanguageCode {
    da = "da",
    en = "en",
    nb = "nb"
}

export interface Language {
    readonly languageCode: LanguageCode
    readonly name: L10nString
}

function trimObj(obj: any) {
    if (!Array.isArray(obj) && typeof obj !== "object") return obj
    return Object.keys(obj).reduce(function (acc, key) {
        if (!obj[key] && obj[key] !== 0) {
            return acc
        }

        if (typeof obj[key] === "string") {
            acc[key.trim()] = obj[key].trim()
        } else if (typeof obj[key] === "object") {
            acc[key.trim()] = trimObj(obj[key])
        } else {
            acc[key.trim()] = obj[key]
        }
        return acc
    }, Array.isArray(obj) ? [] : {})
}

export class L10nString {
    value: string | Dictionary<string>

    constructor(value: string | Dictionary<string>) {
        if (isNil(value)) {
            this.value = ""
        } else if (isString(value)) {
            this.value = value
        } else {
            this.value = pick(value as Dictionary<string>, Object.keys(LanguageCode))
            // If no supported languages are available, fall back to first value as
            // an unlocalized value.
            if (Object.keys(this.value).length === 0 && Object.keys(value).length > 0) {
                this.value = value[Object.keys(value)[0]]
            }
        }
    }

    // We trim all text on serialization to JSON. We believe that this is the right thing to do.
    json(): any {
        let value: any
        if (isString(this.value)) {
            value = this.value.trim()
        } else {
            value = trimObj(pick(this.value, Object.keys(LanguageCode)))
        }
        return value
    }

    localized(to: LanguageCode | null): string {
        const languageCodeString = to || ""
        switch (typeof this.value) {
            case "string":
                return this.value as string
            case "object":
                const dict = this.value as Dictionary<string>
                return dict[languageCodeString] ?? dict["en"] ?? dict[Object.keys(dict)[0]]
            default:
                return ""
        }
    }

    hasLocalizationFor(language: LanguageCode | null): boolean {
        if (isNil(language)) {
            return true
        }

        switch (typeof this.value) {
            case "string":
                return false
            case "object":
                const dict = this.value as Dictionary<string>
                return dict[language] ? true : false
            default:
                return false
        }
    }

    localizations(): LanguageCode[] {
        switch (typeof this.value) {
            case "string":
                return []
            case "object":
                const dict = this.value as Dictionary<string>
                const localizations: LanguageCode[] = Object.keys(dict).map(key => { return LanguageCode[key] })
                return localizations
            default:
                return []
        }
    }

    // Mutating - Removes the localized value, but keeps the localization intact (ie. it does not try to go from localized to unlocalized representation)
    removeLocalizedValue(language: LanguageCode) {
        switch (typeof this.value) {
            case "string":
                break
            case "object":
                const dict = this.value as Dictionary<string>
                if (dict[language]) {
                    delete dict[language]
                }

                this.value = dict
                break
            default:
                break
        }
    }

    // Mutating - removes the localization entirely - and collapses from localized to unlocalized if we are deleting the last localization
    removeLocalization(language: LanguageCode) {
        switch (typeof this.value) {
            case "string":
                break
            case "object":
                const dict = this.value as Dictionary<string>
                if (dict[language]) {
                    // if we're deleting the last language we just return that value instead of an empty object which is invalid
                    if (Object.keys(dict).length === 1) {
                        this.value = dict[language]
                        break
                    }
                    delete dict[language]
                }

                this.value = dict
                break
            default:
                break
        }
    }

    // Mutating - sets a localized value
    setLocalizedValue(value: string, language: LanguageCode) {
            switch (typeof this.value) {
                case "string":
                    const newValue: Dictionary<string> = {}
                    newValue[language] = value
                    this.value = newValue
                    break
                case "object":
                    const dict = this.value as Dictionary<string>
                    dict[language] = value
                    this.value = dict
                    break
                default:
                    break
            }
        }

    // Mutating - converts unlocalized to localized
    localizeTo(language: LanguageCode) {
        switch (typeof this.value) {
            case "string":
                const newValue: Dictionary<string> = { [language]: this.value as string }
                this.value = newValue
                break
            case "object":
                break
            default:
                break
        }
    }

    hasEmptyLocalizations(): boolean {
        switch (typeof this.value) {
            case "string":
                return (this.value as string).length === 0
            case "object":
                const dict = this.value as Dictionary<string>
                for (const key in dict) {
                    const val = dict[key]
                    if (val.length === 0) { return true }
                }
                return false
            default:
                return true
        }
    }
}

// TODO: Probably remove this!
export function localizedProperty(object: any, property: any, toLanguage: LanguageCode) {
    if (typeof object[property] === "object") {
        const l10nstring = object[property]
        return l10nstring[toLanguage] || l10nstring[LanguageCode.en] || l10nstring[Object.keys(l10nstring)[0]]
    }

    return object[property]
}
