import * as _ from "lodash"
import * as React from "react"
import {
    Col,
    ControlLabel,
    FormControl,
    FormGroup
} from "react-bootstrap"
import { ModuleProps } from "../Configuration/ModuleConfiguration"

export class GiftReceiptsModule extends React.Component<ModuleProps, {}> {

    render() {
        const config = this.props.moduleData
        const header: any = config["header"] ?? ""
        const footer: any = config["footer"] ?? ""
        if (!this.props.moduleData.enabled) {
            return null
        }

        return (
            <FormGroup>
                <Col componentClass={ControlLabel} sm={2}>Gift receipt setup</Col>
                <Col sm={10}>
                    {"Header for the gift receipt"}
                    <FormControl
                        type="text"
                        name="header"
                        value={header}
                        placeholder="Please enter the header for the gift receipt"
                        onChange={async (event) => {
                            const target = event.target
                            const value = (target as any).value
                            if (_.isNil(value)) {
                                delete this.props.moduleData?.header
                            } else {
                                this.props.moduleData.header = value
                            }
                            this.props.updateConfiguration(existing => {
                                return config
                            })
                        }}
                    />
                    <br />
                    {"Footer for the gift receipt"}
                    <FormControl
                        type="text"
                        name="footer"
                        value={footer}
                        placeholder="Please enter the footer for the gift receipt"
                        onChange={async (event) => {
                            const target = event.target
                            const value = (target as any).value
                            if (_.isNil(value)) {
                                delete this.props.moduleData?.footer
                            } else {
                                this.props.moduleData.footer = value
                            }
                            this.props.updateConfiguration(existing => {
                                return config
                            })
                        }}
                    />
                </Col>
            </FormGroup>
        )
    }
}
