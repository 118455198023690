import * as React from "react"
import { ref, firestore } from "../../config/constants"
import { Panel, Button, DropdownButton, MenuItem, Modal, FormGroup, Col, FormControl, ControlLabel, Alert, Form, Checkbox } from "react-bootstrap"
import { RouteComponentProps, withRouter } from "react-router"
import * as _ from "lodash"
import { StripedTable } from "../StripedTable"
import { PageState } from "../PageState"
import { EmailValidation } from "../../helpers/validation"
import { currentUser } from "../../helpers/auth"
import firebase from "firebase/compat"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { Cashier } from "../../models/Cashier"
import { ascendingByCashierFullName } from "./CashierList/helpers"

function listWithElementRemoved<T>(element: T, list: T[]): T[] {
    const listCopy = _.cloneDeep(list)
    const index = listCopy.indexOf(element)
    if (index > -1) {
        listCopy.splice(index, 1)
    }
    return listCopy
}

function listWithElementAdded<T>(element: T, list: T[]): T[] {
    const listCopy = _.cloneDeep(list)
    const index = listCopy.indexOf(element)
    if (index < 0) {
        listCopy.push(element)
    }
    return listCopy
}

interface AccountOwnersPanelProps {
    other_users: string[]
    account_owners: string[]
    removeAccountOwner(uid: string): void
    inviteAccountOwner(uid: string): void
    formattedUser(uid: string): string
    isCurrentUser: (uid: string) => boolean
    editUser: (uid: string) => void
}

class AccountOwnersPanel extends React.Component<AccountOwnersPanelProps, {}> {
    render() {
        return (
            <Panel>
                <Panel.Heading>Account Owners</Panel.Heading>
                <Panel.Body>
                    <DropdownButton bsStyle="success" title="Add user" key="1" id="dropdown-basic-1" onSelect={(value: any, e?: any) => this.props.inviteAccountOwner(value)} >
                        {this.props.other_users.map(uid => {
                            return (
                                <MenuItem key={uid} eventKey={uid}>
                                    {this.props.formattedUser(uid)}
                                </MenuItem>
                            )
                        })}
                        {!_.isEmpty(this.props.other_users) && <MenuItem divider={true} />}

                        <MenuItem eventKey={kInviteUID}>Invite new user</MenuItem>
                    </DropdownButton>
                </Panel.Body>
                <StripedTable>
                    <tbody>
                        {this.props.account_owners.map(uid => {
                            return (
                                <tr key={uid} onClick={() => { this.props.editUser(uid) }}>
                                    <td>{this.props.formattedUser(uid)}</td>
                                    {!this.props.isCurrentUser(uid) && <td className="narrow"><ConfirmDeleteButton message="Are you sure you wish to disable account rights for this user?" onDelete={() => { this.props.removeAccountOwner(uid) }} /></td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </StripedTable>
            </Panel>
        )
    }
}

interface ShopOwnersPanelProps {
    other_users: string[]
    shops: any[]
    shop_owners: { [id: string]: string[] }
    removeShopOwner(shopKey: string, uid: string): void
    inviteShopOwner(shopKey: string, uid: string): void
    formattedUser(uid: string): string
    isCurrentUser(uid: string): boolean
    editUser(uid: string, shopId?: string): void
}

class ShopOwnersPanel extends React.Component<ShopOwnersPanelProps, {}> {
    render() {
        return this.props.shops.map(shop => {
            return (
                <Panel key={shop.key} >
                    <Panel.Heading>{shop.name + ", shop owners"}</Panel.Heading>
                    <Panel.Body>
                        <DropdownButton bsStyle="success" title="Add user" id="a" onSelect={(value: any, e?: any) => this.props.inviteShopOwner(shop.key, value)}>
                            {
                                this.props.other_users.map(uid => {
                                    return (
                                        <MenuItem key={uid} eventKey={uid}>
                                            {this.props.formattedUser(uid)}
                                        </MenuItem>
                                    )
                                })
                            }

                            {!_.isEmpty(this.props.other_users) && <MenuItem divider={true} />}

                            <MenuItem eventKey={kInviteUID}>Invite new user</MenuItem>

                        </DropdownButton>
                    </Panel.Body>

                    <StripedTable>
                        <tbody>
                            {
                                this.props.shop_owners[shop.key] ? this.props.shop_owners[shop.key].map(uid => {
                                    return (
                                        <tr key={shop.key + "_" + uid} onClick={() => { this.props.editUser(uid, shop.key) }}>
                                            <td>{this.props.formattedUser(uid)}</td>
                                            {!this.props.isCurrentUser(uid) && <td className="narrow"><ConfirmDeleteButton message="Are you sure you wish to disable shop rights for this user?" onDelete={() => { this.props.removeShopOwner(shop.key, uid) }} /></td>}
                                        </tr>
                                    )
                                }) : <tr />
                            }
                        </tbody>
                    </StripedTable>
                </Panel>
            )
        })
    }
}

interface OtherUsersPanelProps {
    other_users: string[]
    deleteUser(uid: string): void
    formattedUser(uid: string): string
    isCurrentUser(uid: string): boolean
    editUser(uid: string): void
}

class OtherUsersPanel extends React.Component<OtherUsersPanelProps, {}> {
    render() {
        return (
            <Panel>
                <Panel.Heading>Users without account permissions</Panel.Heading>
                <StripedTable>
                    <tbody>
                        {
                            this.props.other_users.map(uid => {
                                return (
                                    <tr key={uid} onClick={() => { this.props.editUser(uid) }}>
                                        <td>{this.props.formattedUser(uid)}</td>
                                        {!this.props.isCurrentUser(uid) && <td className="narrow"><ConfirmDeleteButton message="Are you sure you wish to remove this user completely?" onDelete={() => this.props.deleteUser(uid)} /></td>}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </StripedTable>
            </Panel >
        )
    }
}

const kInviteUID = ".invite"

interface User {
    name?: string
    email: string
    disallow_backoffice_access: boolean
    cashiers?: _.Dictionary<string>
}

interface UserEditProps {
    editedShop?: string
    user: User
    uid: string
    role: any
    close: () => void
    updateUser: (uid: string, user: User, cashierId?: string) => void
}

interface UserEditState {
    name?: string
    email: string
    allowBackofficeAccess: boolean
    cashiers: Cashier[]
    cashier_role_id?: string
}

class UserEdit extends React.Component<UserEditProps, UserEditState> {
    constructor(props: UserEditProps) {
        super(props)
        const cashierId = props.editedShop ? props.user.cashiers?.[props.editedShop] : undefined
        this.state = {
            name: props.user.name,
            allowBackofficeAccess: !props.user.disallow_backoffice_access,
            email: props.user.email,
            cashier_role_id: cashierId,
            cashiers: []
        }
    }

    private get isInvitation(): boolean {
        return this.props.uid === kInviteUID
    }

    private get shopKey(): string | undefined {
        return this.props.editedShop
    }

    private get accountKey(): string {
        return this.props.role.account_id
    }

    private get cashiersPath(): string | undefined {
        if (this.shopKey === undefined) { return undefined }
        return `v1/accounts/${this.accountKey}/shops/${this.shopKey}/cashiers`
    }

    componentDidMount() {
        this.setupObservers()
    }

    componentWillUnmount() {
        this.teardownObservers()
    }

    setupObservers() {
        if (this.cashiersPath === undefined) {
            return
        }
        // Load cashiers
        ref().child(this.cashiersPath).on("value", snapshot => {
            if (!snapshot || !snapshot.exists()) {
                return
            }

            const cashiersMap = snapshot.val()

            let cashiers: Cashier[] = []
            for (const key in cashiersMap) {
                const cashier = Cashier.fromJSON(cashiersMap[key])
                if (!_.isNull(cashier)) {
                    cashiers.push(cashier)
                }
            }

            cashiers = cashiers.sort(ascendingByCashierFullName)

            this.setState({ cashiers: cashiers })
        })

    }

    teardownObservers() {
        if (this.cashiersPath === undefined) {
            return
        }
        ref().child(this.cashiersPath).off()
    }

    handleEditUserNameChange = (event: any) => {
        const target = event.target
        const value = target.value

        this.setState({ name: value })
    }

    handleEmailChange = (event: any) => {
        const target = event.target
        const value = target.value

        this.setState({ email: value })
    }

    handleEditUserAllowBackofficeAccessToggle = () => {
        this.setState({ allowBackofficeAccess: !this.state.allowBackofficeAccess })
    }

    handleCashierSelection(event: any) {
        if (event.target.value === "") {
            this.setState({ cashier_role_id: undefined })
        } else {
            this.setState({ cashier_role_id: event.target.value })
        }
    }

    updateUser() {
        this.props.updateUser(this.props.uid, {
            name: this.state.name,
            email: this.state.email,
            disallow_backoffice_access: !this.state.allowBackofficeAccess
        }, this.state.cashier_role_id)
    }

    isOkButtonEnabled = () => {
        if ((this.state.name ?? "").length === 0) {
            return false
        }
        if (!this.isInvitation) {
            return true
        }
        return EmailValidation.valid(this.state.email)
    }

    render() {
        return (
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>{this.isInvitation ? "Invite User" : "Edit User"}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form horizontal={true}>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>Name</Col>
                            <Col sm={10}>
                                <FormControl
                                    type="text"
                                    name="edit_user_name"
                                    value={this.state.name ?? ""}
                                    placeholder="Enter name"
                                    onChange={(event) => { this.handleEditUserNameChange(event) }}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>Email address</Col>
                            <Col sm={10}>
                                <FormControl
                                    type="email"
                                    name="edit_user_email"
                                    value={this.state.email}
                                    placeholder="Enter email"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    disabled={!this.isInvitation}
                                    onChange={(event) => { this.handleEmailChange(event) }}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>Backoffice</Col>
                            <Col sm={10}>
                                <Checkbox checked={this.state.allowBackofficeAccess} onChange={() => { this.handleEditUserAllowBackofficeAccessToggle() }}>Allow access</Checkbox>
                            </Col>
                        </FormGroup>

                        {this.state.cashiers.length > 0 &&
                            (
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>Fixed cashier</Col>
                                    <Col sm={10}>
                                        <FormControl
                                            componentClass="select"
                                            name="cashier"
                                            placeholder="Select cashier"
                                            value={this.state.cashier_role_id ?? ""}
                                            onChange={(event) => { this.handleCashierSelection(event) }}
                                        >
                                            <option key="-" value="">Don't use a fixed cashier for this user</option>

                                            {
                                                Object.keys(this.state.cashiers).map((key, index) => {
                                                    return <option key={index} value={this.state.cashiers[key].id}>{this.state.cashiers[key].displayName}</option>
                                                })
                                            }
                                        </FormControl>
                                    </Col>
                                </FormGroup>
                            )
                        }

                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => { this.props.close() }}>Cancel</Button>
                    <Button bsStyle="primary" disabled={!this.isOkButtonEnabled()} onClick={() => { this.updateUser() }}>{this.isInvitation ? "Invite" : "OK"}</Button>
                </Modal.Footer>

            </Modal.Dialog >
        )
    }
}

interface UserManagementProps extends RouteComponentProps<any> {
    role: any
}

interface UserManagementState {
    users: any,
    shops: any[],
    account_owners: string[],
    shop_owners: { [id: string]: string[] },
    showMissingAccountOwnerAlert: boolean,
    removed_account_owners: string[],
    removed_shop_owners: { [id: string]: string[] },
    added_account_owners: string[],
    added_shop_owners: { [id: string]: string[] },
    removed_users: string[],
    loading: boolean,
    publishing: boolean,
    editingUser?: string,
    editingUserShop?: string,
    updated_users: { [id: string]: any }
}

const initialState: UserManagementState = {
    users: {},
    shops: [],
    account_owners: [],
    shop_owners: {},
    showMissingAccountOwnerAlert: false,
    removed_account_owners: [],
    removed_shop_owners: {},
    added_account_owners: [],
    added_shop_owners: {},
    removed_users: [],
    updated_users: {},
    loading: false,
    publishing: false
}

class UserManagement extends React.Component<UserManagementProps, UserManagementState> {

    constructor(props: any) {
        super(props)
        this.state = initialState
    }

    formattedUser(uid: string): string {
        const user = this.state.users[uid]
        if (!user) {
            return uid
        }
        const noBackofficeAccess = user.disallow_backoffice_access
        let name: string = (user.name || user.email || uid)
        if (noBackofficeAccess) {
            name = name + " (no backoffice access)"
        }
        return name
    }

    others = () => {
        const allUIDs = Object.keys(this.state.users)
        for (const uid of this.state.account_owners) {
            const index = allUIDs.indexOf(uid)
            if (index > -1) {
                allUIDs.splice(index, 1)
            }
        }
        return allUIDs
    }

    editUser = (uid: string, shopId?: string) => {
        this.setState({ editingUser: uid, editingUserShop: shopId })
    }

    editedUser(): User | undefined {
        if (this.state.editingUser === undefined) {
            return
        }
        return this.state.users[this.state.editingUser] ?? { email: "" }
    }

    async componentDidMount() {
        await this.load()
    }

    // TODO: Refactor logic to shorter functions
    async load() {
        this.setState({ loading: true })

        const account = this.props.role.account_id
        const usersRef = ref().child(`v1/accounts/${account}/users`)
        const usersPromise = usersRef.once("value")

        const shopsRef = ref().child(`v1/accounts/${account}/shop_index`)
        const shopsPromise = shopsRef.once("value")

        const permissionsRef = firestore.collection(`accounts/${account}/permissions`)
        const permissionPromise = permissionsRef.get()

        const [usersSnap, shopsSnap, permissionSnap] = await Promise.all([usersPromise, shopsPromise, permissionPromise])

        const usersDict = usersSnap.val()
        const permissionArray = permissionSnap.docs
        if (usersDict && permissionArray) {
            for (const permissionDoc of permissionArray) {
                const permissions = permissionDoc.data()
                const uid = permissionDoc.id
                const user = usersDict[uid]
                if (user) {
                    user.disallow_backoffice_access = permissions.disallow_backoffice_access
                }
            }
        }
        if (usersDict) {
            this.setState({ users: usersDict })
        }

        if (shopsSnap.exists()) {
            const shopsDict = shopsSnap.val()
            const keys = Object.keys(shopsDict)
            const shops = keys
                .map(v => {
                    const shop = shopsDict[v]
                    shop.key = v
                    return shop
                })
                .filter(v => { return v.deactivated !== true })
                .sort((lhs: any, rhs: any) => { return lhs.name.localeCompare(rhs.name) })

            this.setState({ shops: shops })
        }

        const accountOwners: string[] = []
        const shopOwners: { [id: string]: string[] } = {}
        if (permissionArray) {
            for (const permissionDoc of permissionArray) {
                const uid = permissionDoc.id
                const user = permissionDoc.data()
                if (user.account_owner) {
                    accountOwners.push(uid)
                } else if (user.shop_owner) {
                    for (const shopKey of user.shops) {
                        const owners = shopOwners[shopKey] ?? []
                        owners.push(uid)
                        shopOwners[shopKey] = owners
                    }
                }
            }
        }
        this.setState({ loading: false, account_owners: accountOwners, shop_owners: shopOwners, added_account_owners: [], removed_account_owners: [], added_shop_owners: {}, removed_shop_owners: {}, removed_users: [], updated_users: {} })
    }

    removeAccountOwner = (uid: string) => {
        const accountOwners = listWithElementRemoved(uid, this.state.account_owners)
        const addedAccountOwners = listWithElementRemoved(uid, this.state.added_account_owners)
        const removedAccountOwners = listWithElementAdded(uid, this.state.removed_account_owners)

        this.setState({ account_owners: accountOwners, removed_account_owners: removedAccountOwners, added_account_owners: addedAccountOwners })
    }

    addAccountOwner = (uid: string) => {
        const accountOwners = listWithElementAdded(uid, this.state.account_owners)
        const addedAccountOwners = listWithElementAdded(uid, this.state.added_account_owners)
        const removedAccountOwners = listWithElementRemoved(uid, this.state.removed_account_owners)

        this.setState({ account_owners: accountOwners, removed_account_owners: removedAccountOwners, added_account_owners: addedAccountOwners })
    }

    removeShopOwner = (shopId: string, uid: string) => {
        const shopOwners = _.cloneDeep(this.state.shop_owners)
        shopOwners[shopId] = listWithElementRemoved(uid, shopOwners[shopId] || [])

        const addedShopOwners = _.cloneDeep(this.state.added_shop_owners)
        addedShopOwners[shopId] = listWithElementRemoved(uid, addedShopOwners[shopId] || [])

        const removedShopOwners = _.cloneDeep(this.state.removed_shop_owners)
        removedShopOwners[shopId] = listWithElementAdded(uid, removedShopOwners[shopId] || [])

        this.setState({ shop_owners: shopOwners, removed_shop_owners: removedShopOwners, added_shop_owners: addedShopOwners })
    }

    addShopOwner = (shopId: string, uid: string) => {
        const shopOwners = _.cloneDeep(this.state.shop_owners)
        shopOwners[shopId] = listWithElementAdded(uid, shopOwners[shopId] || [])

        const addedShopOwners = _.cloneDeep(this.state.added_shop_owners)
        addedShopOwners[shopId] = listWithElementAdded(uid, addedShopOwners[shopId] || [])

        const removedShopOwners = _.cloneDeep(this.state.removed_shop_owners)
        removedShopOwners[shopId] = listWithElementRemoved(uid, removedShopOwners[shopId] || [])

        this.setState({ shop_owners: shopOwners, removed_shop_owners: removedShopOwners, added_shop_owners: addedShopOwners })
    }

    closeModal = () => {
        this.setState({ editingUser: undefined, editingUserShop: undefined })
    }

    publish = async () => {
        if (this.state.account_owners.length < 1) {
            this.setState({ showMissingAccountOwnerAlert: true })
            return
        } else {
            this.setState({ showMissingAccountOwnerAlert: false })
        }
        this.setState({ publishing: true })

        const account = this.props.role.account_id
        const userUpdates: { [key: string]: any } = this.state.updated_users ?? {}

        const profilesRef = ref().child(`v1/profiles`)
        const permissionsRef = ref().child(`v1/accounts/${account}/permissions`)
        const fsPermissionsRef = firestore.collection(`accounts/${account}/permissions`)

        for (const uid of this.state.added_account_owners) {
            await permissionsRef.child(uid).child("account_owner").set(true)
            await fsPermissionsRef.doc(uid).set({ account_owner: true }, { merge: true })
            await profilesRef.child(uid).child("accounts").child(account).set(true)
        }
        for (const uid of this.state.removed_account_owners) {
            await permissionsRef.child(uid).child("account_owner").remove()
            await fsPermissionsRef.doc(uid).set({ account_owner: firebase.firestore.FieldValue.delete() }, { merge: true })
            await profilesRef.child(uid).child("accounts").child(account).remove()
        }

        // Removals from 'shared' must be done transactionally since we need to know
        // if user has access to any other shops.
        const sharedRemovals: string[] = []
        for (const shopId of Object.keys(this.state.removed_shop_owners)) {
            const owners = this.state.removed_shop_owners[shopId]
            for (const ouid of owners) {
                try {
                    await profilesRef.child(ouid).child("shops").child(account).child(shopId).remove()
                } catch (error) {
                    console.warn(`Error updating rtdb profile for shop for: ${ouid}`, error)
                }
                try {
                    await permissionsRef.child(ouid).child("shops").child(shopId).remove()
                } catch (error) {
                    console.warn(`Error updating rtdb permissions for shop for: ${ouid}`, error)
                }
                try {
                    await fsPermissionsRef.doc(ouid).set({ shops: firebase.firestore.FieldValue.arrayRemove(shopId) }, { merge: true })
                } catch (error) {
                    console.warn(`Error updating firestore permissions for shop for: ${ouid}`, error)
                }

                sharedRemovals.push(ouid)
            }
        }

        for (const shopId of Object.keys(this.state.added_shop_owners)) {
            const owners = this.state.added_shop_owners[shopId]
            for (const ouid of owners) {
                await profilesRef.child(ouid).child("shops").child(account).child(shopId).set(true)
                await fsPermissionsRef.doc(ouid).set({
                    shop_owner: true,
                    shops: firebase.firestore.FieldValue.arrayUnion(shopId)
                }, { merge: true })
                await permissionsRef.child(ouid).child("shop_owner").set(true)
                await permissionsRef.child(ouid).child("shops").child(shopId).set(true)

                const sidx = sharedRemovals.indexOf(ouid)
                if (sidx > -1) {
                    sharedRemovals.splice(sidx, 1)
                }
            }
        }

        for (const uid in userUpdates) {
            const user = userUpdates[uid]
            if (user.disallow_backoffice_access) {
                await permissionsRef.child(uid).child("disallow_backoffice_access").set(true)
                await fsPermissionsRef.doc(uid).set({ disallow_backoffice_access: true }, { merge: true })
            } else {
                await permissionsRef.child(uid).child("disallow_backoffice_access").remove()
                // Don't fail if it doesn't exist
                try {
                    await fsPermissionsRef.doc(uid).update({ disallow_backoffice_access: firebase.firestore.FieldValue.delete() })
                } catch (error) {
                    console.warn(`Error removing backoffice access from user: ${uid}`, error)
                }
            }
            delete user.disallow_backoffice_access
        }

        for (const uid of this.state.removed_users) {
            userUpdates[uid] = null
        }

        const usersRef = ref().child(`v1/accounts/${account}/users`)
        console.log("UPDATES", userUpdates)
        await usersRef.update(userUpdates)

        // Tricky part here is to only remove 'shared read' permission in case no
        // no other 'shop read' permissions for that user exists
        if (sharedRemovals.length > 0) {
            for (const uid of sharedRemovals) {
                await permissionsRef.child(uid).transaction((current: any) => {
                    if (_.isNil(current)) {
                        return current
                    }
                    if (current.shops === undefined) {
                        delete current.shop_owner
                        delete current.cashier
                    }
                    return current
                })
                await firestore.runTransaction(async (transaction) => {
                    const userRef = fsPermissionsRef.doc(uid)
                    const snapshot = await transaction.get(userRef)
                    if (snapshot.exists) {
                        const user = snapshot.data()!
                        if (Object.keys(user).length === 0) {
                            await transaction.delete(userRef)
                        } else if (user.shops === undefined) {
                            await transaction.update(userRef, {
                                shop_owner: firebase.firestore.FieldValue.delete(),
                                cashier: firebase.firestore.FieldValue.delete()
                            })
                        }
                    }
                })
            }
        }
        this.setState({ publishing: false })

        await this.load()
    }

    inviteAccountOwner = (uid: string) => {
        if (uid === kInviteUID) {
            this.setState({ editingUser: uid })
            // this.openModalForAccount()
        } else {
            this.addAccountOwner(uid)
        }
    }

    inviteShopOwner = (shopId: string, uid: string) => {
        if (uid === kInviteUID) {
            this.setState({ editingUser: uid, editingUserShop: shopId })
            // this.openModalForShop(shopId)
        } else {
            this.addShopOwner(shopId, uid)
        }
    }

    deleteUser = (uid: string) => {
        this.removeAccountOwner(uid)
        for (const shop of this.state.shops) {
            this.removeShopOwner(shop.key, uid)
        }
        const users = _.cloneDeep(this.state.users)
        delete users[uid]

        const removedUsers = listWithElementAdded(uid, this.state.removed_users)

        this.setState({ users: users, removed_users: removedUsers })
    }

    isCurrentUser = (uid: string) => {
        const user = currentUser()
        if (_.isNil(user)) {
            return false
        }

        return uid === user.uid
    }

    dirty() {
        return !(
            _.isEmpty(this.state.removed_account_owners) &&
            _.isEmpty(this.state.removed_shop_owners) &&
            _.isEmpty(this.state.removed_users) &&
            _.isEmpty(this.state.added_account_owners) &&
            _.isEmpty(this.state.added_shop_owners) &&
            _.isEmpty(this.state.updated_users)
        )
    }

    updateUser = async (uid: string, user: User, cashierId?: string) => {
        if (uid === kInviteUID) {
            const invitation = this.state.editingUserShop ? { shops: { [this.state.editingUserShop]: true } } : { account: true }
            await this.sendEmailInvitation(invitation, user, cashierId)
            return
        }
        const clone = _.cloneDeep(this.state.users)

        // NOTE: Only override updated properties - leave any unknown
        // properties alone.
        const updated = clone[uid] ?? {}
        updated.name = user.name
        updated.email = user.email
        if (user.disallow_backoffice_access) {
            updated.disallow_backoffice_access = true
        } else {
            delete updated.disallow_backoffice_access
        }
        const cashiers = updated.cashiers ?? {}
        if (this.state.editingUserShop !== undefined) {
            if (cashierId !== undefined) {
                cashiers[this.state.editingUserShop] = cashierId
            } else {
                delete cashiers[this.state.editingUserShop]
            }
        }
        updated.cashiers = cashiers
        clone[uid] = updated
        const updatedUsers = _.cloneDeep(this.state.updated_users)
        updatedUsers[uid] = updated
        this.setState({ users: clone, updated_users: updatedUsers })
        this.closeModal()
    }

    sendEmailInvitation = async (invitation: any, user: User, cashierId?: string) => {
        const inviteEmail = user.email
        const inviteName = user.name
        // const invitation = this.state.invitation

        const account = this.props.role.account_id
        const inviteQueueRef = ref().child(`v1/accounts/${account}/invites/queue`)

        invitation.to_name = inviteName
        invitation.to_email = inviteEmail.toLowerCase()
        invitation.from_name = "The Ka-ching Team"
        invitation.from_email = "noreply@ka-ching.dk"
        invitation.allow_backoffice_access = !(user.disallow_backoffice_access)
        if (cashierId !== undefined && this.state.editingUserShop !== undefined) {
            invitation.cashiers = { [this.state.editingUserShop]: cashierId }
        }

        console.info(invitation)

        await inviteQueueRef.push(invitation, (error: any) => { if (error) { console.log("Error writing invitation: " + error) } })

        this.closeModal()
    }

    missingAccountOwnerAlert() {
        return (
            <Alert bsStyle="danger">
                <h4>No account owners.</h4>
                <p>You need at least one account owner - otherwise you will lose all access to the admin pages.</p>
            </Alert>
        )
    }

    render() {
        return (
            <PageState
                loading={this.state.loading}
                publishing={this.state.publishing}
                dirty={this.dirty()}
                typeName="users"
                submit_action={async () => { await this.publish() }}
            >
                {this.state.editingUser && <UserEdit role={this.props.role} uid={this.state.editingUser} editedShop={this.state.editingUserShop} user={this.editedUser()!} close={() => { this.closeModal() }} updateUser={async (uid, user, cashierId) => { this.updateUser(uid, user, cashierId) }} />}

                {/* {this.state.invitation && this.invitationDialog()} */}

                {this.state.showMissingAccountOwnerAlert && this.missingAccountOwnerAlert()}

                <AccountOwnersPanel
                    other_users={this.others()}
                    formattedUser={uid => this.formattedUser(uid)}
                    account_owners={this.state.account_owners}
                    removeAccountOwner={this.removeAccountOwner}
                    inviteAccountOwner={this.inviteAccountOwner}
                    isCurrentUser={this.isCurrentUser}
                    editUser={this.editUser}
                />

                <ShopOwnersPanel
                    shops={this.state.shops}
                    other_users={this.others()}
                    formattedUser={uid => this.formattedUser(uid)}
                    shop_owners={this.state.shop_owners}
                    removeShopOwner={this.removeShopOwner}
                    inviteShopOwner={this.inviteShopOwner}
                    isCurrentUser={this.isCurrentUser}
                    editUser={this.editUser}
                />

                <OtherUsersPanel
                    other_users={this.others()}
                    formattedUser={uid => this.formattedUser(uid)}
                    deleteUser={this.deleteUser}
                    isCurrentUser={this.isCurrentUser}
                    editUser={this.editUser}
                />
            </PageState>
        )
    }
}

export default withRouter(UserManagement)
