import * as _ from "lodash"
import { Product } from "../models/Product"
import { ref } from "../config/constants"
import { sortLikeFirebaseOrderByKey } from "../helpers/sorting"
import firebase from "firebase/compat"

export class ProductPrices {
    costPrice?: number
    retailPrice?: number

    constructor(costPrice: number | undefined, retailPrice: number | undefined) {
        this.costPrice = costPrice
        this.retailPrice = retailPrice
    }
}

// The idea is to make a simple caching thing that loads all products.
// Account first and the shop products to make them "shadow" an account product with same id.
export class ProductCatalogService {

    private products?: _.Dictionary<Product>
    private count: number = 0

    async allProductsInCatalogue(accountId: string, shopId: string, progress: (loaded: number, done: boolean) => void): Promise<_.Dictionary<Product>> {
        if (this.products) {
            return this.products
        }

        this.count = 0

        const marketPath = `v1/accounts/${accountId}/shops/${shopId}/market`
        const marketSnapshot = await ref().child(marketPath).once("value")
        if (!marketSnapshot.exists() || typeof marketSnapshot.val() !== "string") {
            return {}
        }
        const marketId: string = marketSnapshot.val()

        const result: _.Dictionary<Product> = {}
        await this.loadProductsAtRef(ref().child(`/v1/accounts/${accountId}/inventory/products/pos/${marketId}`), result, progress)
        await this.loadProductsAtRef(ref().child(`/v1/accounts/${accountId}/shops/${shopId}/inventory/products`), result, progress)

        this.products = result

        return result
    }

    product(productId: string): Product | undefined {
        if (_.isNil(this.products)) {
            return undefined
        }
        return this.products[productId]
    }

    productPrices(productId: string, variantId?: string): ProductPrices {
        const product = (this.products || {})[productId]
        if (_.isNil(product)) {
            return new ProductPrices(undefined, undefined)
        }

        let variantCostPrice: number | undefined = undefined
        let variantRetailPrice: number | undefined = undefined
        if (!_.isNil(variantId)) {
            const variant = product.variant(variantId)
            if (!_.isNil(variant)) {
                const vcp = variant.costPriceAmount(null)
                if (typeof vcp === "number") {
                    variantCostPrice = vcp 
                }
                const vrp = variant.retailPriceAmount(null)
                if (typeof vrp === "number") {
                    variantRetailPrice = vrp 
                }
            }
        }

        const pcp = product.costPriceAmount(null)
        const productCostPrice = typeof pcp === "number" ? pcp : undefined
        const prp = product.retailPriceAmount(null)
        const productRetailPrice = typeof prp === "number" ? prp : undefined
        return new ProductPrices(variantCostPrice || productCostPrice, variantRetailPrice || productRetailPrice)
    }

    private async loadProductsAtRef(productRef: firebase.database.Reference, aggregate: _.Dictionary<Product>, progress: (loaded: number, done: boolean) => void): Promise<void> {
        const pageSize = 1000
        let lastProductKey: string | undefined
        let done = false

        do {
            let query = productRef.orderByKey().limitToFirst(pageSize + 1)
            if (lastProductKey) {
                query = query.startAt(lastProductKey)
            }
            const snapshot = await query.once("value")
            const products = snapshot.val() || {}
            const productKeys = Object.keys(products)
            
            for (const key in products) {
                const p = new Product(products[key])
                aggregate[p.id] = p
            }

            if (productKeys.length < pageSize) {
                done = true
            } else {
                const sortedKeys = sortLikeFirebaseOrderByKey(productKeys)
                lastProductKey = _.last(sortedKeys)
            }

            this.count += productKeys.length - 1
            progress(this.count, false)
        } while (!done)

        progress(this.count, true)
    }
}