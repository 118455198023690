import * as Auth from "../../../helpers/auth"
import * as React from "react" 

import { Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row, Label } from "react-bootstrap"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"

interface StockCountOpenModalProps {
    role: Role
    stockLocation: string
    openNew: (name: string) => void
    cancel: () => void
}

interface StockCountOpenModalState {
    loaded: boolean
    name: string
    email: string
}

export class StockCountOpenModal extends React.Component<StockCountOpenModalProps, StockCountOpenModalState> {

    // Constructor

    constructor(props: StockCountOpenModalProps) {
        super(props)
        
        this.state = {
            loaded: false,
            name: "",
            email: ""
        }
    }

    // Methods

    openButtonClicked() {
        this.props.openNew(this.state.name)
    }

    openButtonEnabled(): boolean {
        return this.state.name.length > 0
    }

    cancelButtonClicked() {
        this.props.cancel()
    }

    nameChanged(name: string) {
        this.setState({ name: name })
    }

    // Component

    async componentDidMount() {
        const uid = Auth.userId()
        if (!uid) {
            this.props.cancel()
            return
        }
        
        const userPath = `v1/accounts/${this.props.role.account_id}/users/${uid}`
        const userSnapshot = await ref().child(userPath).once("value")
        if (!userSnapshot.exists()) {
            this.props.cancel()
            return
        }

        const userJson = userSnapshot.val()
        this.setState({ email: userJson.email, loaded: true })
    }

    render() {
        return (
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>
                        New stock count
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PageState loading={!this.state.loaded} typeName="new stock count">
                        <FormGroup>
                            <Row>
                                <Col componentClass={ControlLabel} sm={12}><Label bsStyle="info">Info</Label> This may take a few minutes if you have thousands of products and variants</Col>
                            </Row>
                            <Row>
                                <Col componentClass={ControlLabel} sm={6}>{" "}</Col>
                            </Row>
                            <Row>
                                <Col componentClass={ControlLabel} sm={6}>Opened by</Col>
                                <Col componentClass={ControlLabel} sm={6} className="text-right">{this.state.email}</Col>
                            </Row>
                            <Row>
                                <Col componentClass={ControlLabel} sm={6}>{" "}</Col>
                            </Row>
                            <Row>
                                <Col componentClass={ControlLabel} sm={2}>Name</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        placeholder="Give this stock count a name"
                                        onChange={(event: any) => { this.nameChanged(event.target.value) }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </PageState>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { this.openButtonClicked() }} disabled={!this.openButtonEnabled()}>Open</Button>
                    <Button onClick={() => { this.cancelButtonClicked() }}>Cancel</Button>
                </Modal.Footer>
            </Modal.Dialog>
        )
    }
}