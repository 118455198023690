import * as React from "react"
import { FormControl } from "react-bootstrap"
import { L10nString, LanguageCode } from "../helpers/L10n"
import { cloneDeep, isNil } from "lodash"

interface L10nFormControlProps {
    language: LanguageCode | null
    l10n: L10nString | null
    onLocalizationChanged: (l10n: L10nString | null) => void
    type?: any
    componentClass?: any
    placeholder?: string
    style?: React.CSSProperties
}

interface L10nFormControlState {
    l10n: L10nString | null
    language: LanguageCode | null
}

export class L10nFormControl extends React.Component<L10nFormControlProps, L10nFormControlState> {
    constructor(props: L10nFormControlProps) {
        super(props)
        this.state = {
            language: props.language,
            l10n: props.l10n
        }
    }

    componentWillReceiveProps(nextProps: L10nFormControlProps) {

        this.setState({ l10n: nextProps.l10n }, () => {
            if (nextProps.language !== this.state.language) {
                this.setState({ language: nextProps.language })
            }
        })
    }

    removeValue() {
        // If value is already null, no changes
        if (isNil(this.state.l10n)) {
            return
        }

        // If language is null, then remove the entire l10n string
        if (isNil(this.state.language)) {
            this.setState({ l10n: null })
            this.props.onLocalizationChanged(null)
            return
        }

        // otherwise, remove a single localization
        const l10nCopy: L10nString = cloneDeep(this.state.l10n)
        l10nCopy.removeLocalizedValue(this.state.language)
        this.setState({ l10n: l10nCopy })
        this.props.onLocalizationChanged(l10nCopy)
    }

    addValue(value: string) {
        // If existing value is null, set a single value
        if (isNil(this.state.l10n)) {
            if (isNil(this.state.language)) {
                const newValue = new L10nString(value)
                this.setState({ l10n: newValue })
                this.props.onLocalizationChanged(newValue)
            } else {
                const language = this.state.language
                const newValue = new L10nString({ [language]: value })
                this.setState({ l10n: newValue })
                this.props.onLocalizationChanged(newValue)
            }
            return
        }

        // If language is null, then override with single value
        if (isNil(this.state.language)) {
            const newValue = new L10nString(value)
            this.setState({ l10n: newValue })
            this.props.onLocalizationChanged(newValue)
            return
        }

        // otherwise, add a single localization
        const l10nCopy: L10nString = cloneDeep(this.state.l10n)
        l10nCopy.setLocalizedValue(value, this.state.language)
        this.setState({ l10n: l10nCopy })
        this.props.onLocalizationChanged(l10nCopy)
    }

    handleInputChange = (event: any) => {
        const target = event.target
        const value = target.value as string

        if (value === "") {
            this.removeValue()
        } else {
            this.addValue(value)
        }
    }

    render() {
        return (
            <FormControl
                type={this.props.type || "text"}
                componentClass={this.props.componentClass}
                value={
                    this.state.l10n ? this.state.l10n.localized(this.state.language) : ""
                }
                style={this.props.style}
                placeholder={this.props.placeholder || ""}
                onChange={this.handleInputChange}
                autoComplete="off"
            />
        )
    }
}
