import * as _ from "lodash"
import * as React from "react"
import firebase from "firebase/compat"
import {
    Button,
    ButtonToolbar,
    FormControl,
    Modal,
    Pager,
    Panel,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap"
import {
    FormattedDate,
    FormattedRelative,
    FormattedTime
} from "react-intl"
import { PageState } from "../PageState"
import { Role } from "../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router"
import { StripedTable } from "../StripedTable"
import "react-dates/initialize" // tslint:disable-line
import { faCog } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { firestore, ref } from "../../config/constants"

const FileDownload = require("js-file-download")

interface GiftcardListProps extends RouteComponentProps<any> {
    role: Role
}

interface GiftcardListState {
    giftcards: any[]
    lastGiftcardId?: string
    firstGiftcardId?: string
    loaded: boolean
    lastPage?: boolean
    firstPage?: boolean
    errorMessage?: string,
    status?: string
    showConfig?: boolean
    exportRecipients?: string[]
    exportStatus?: string
    exportEnabled?: boolean
    exportLoading?: boolean
    exportEditing?: boolean
    exportRecipientEditIndex?: number
    allowGiftcardConsume: boolean
}

const limit = 25

interface ConfirmButtonProps {
    title: string
    message: string
    onAction(): void
}

export class ConfirmButton extends React.Component<ConfirmButtonProps, {}> {
    onClick() {
        if (window.confirm(this.props.message) === true) {
            this.props.onAction()
        }
    }

    render() {
        return <Button bsStyle="danger" onClick={(event) => { this.onClick(); event.stopPropagation() }}>{this.props.title}</Button>
    }
}

class GiftcardList extends React.Component<GiftcardListProps, GiftcardListState> {
    constructor(props: GiftcardListProps) {
        super(props)

        this.state = {
            giftcards: [],
            lastGiftcardId: undefined,
            loaded: false,
            allowGiftcardConsume: false
        }
    }

    async loadConfiguration() {
        const configSnap = await ref().child(`v1/accounts/${this.props.role.account_id}/configuration/giftcard_service_config`).get()
        this.setState({allowGiftcardConsume: configSnap.val()?.allow_giftcard_consume ?? false})
    }

    async componentDidMount() {
        await this.loadConfiguration()
        await this.loadGiftcards(this.earlierThan)
    }

    async componentWillReceiveProps(nextProps: GiftcardListProps) {
    }

    // Helpers

    private set earlierThan(key: string | undefined) {
        const params = new URLSearchParams(this.props.location.search)
        if (params.get("earlierThan") === key) {
            return
        }
        if (key) {
            params.set("earlierThan", key)
        } else {
            params.delete("earlierThan")
        }

        this.props.history.push(`?${params.toString()}`)
    }

    private get earlierThan(): string | undefined {
        const params = new URLSearchParams(this.props.location.search)
        const key = params.get("earlierThan")

        return key === null ? undefined : key
    }

    loadNext = async () => {
        await this.loadGiftcards(this.state.lastGiftcardId)
    }

    loadPrevious = async () => {
        await this.loadGiftcards(undefined, this.state.firstGiftcardId)
    }

    triggerExport = async () => {
        const args: any = {
            action: "giftcard-export",
            account: this.props.role.account_id,
            trigger_export: true
        }

        const client = firebase.functions().httpsCallable("clientApi")
        await client(args)
    }

    performExport = async () => {
        const status = this.state.status ?? "all"
        const args: any = {
            action: "giftcard-export",
            account: this.props.role.account_id,
            export: true,
            status: status
        }

        const client = firebase.functions().httpsCallable("clientApi")
        const result = await client(args)
        FileDownload(result.data, `${status}.csv`)
    }

    enableExport = async () => {
        const args: any = {
            action: "giftcard-export",
            account: this.props.role.account_id,
            enable: true,
            recipients: this.state.exportRecipients ?? [],
            status: this.state.exportStatus ?? "all"
        }

        const client = firebase.functions().httpsCallable("clientApi")
        const result = await client(args)
        this.setState({ exportEnabled: true, exportEditing: undefined, exportLoading: undefined, exportRecipients: result.data.recipients, exportStatus: result.data.export_status })
    }

    disableExport = async () => {
        const args: any = {
            action: "giftcard-export",
            account: this.props.role.account_id,
            disable: true
        }

        const client = firebase.functions().httpsCallable("clientApi")
        await client(args)
        this.setState({ exportEnabled: false, exportLoading: undefined, exportRecipients: undefined, exportStatus: undefined })
    }

    loadConfig = async () => {
        this.setState({ exportLoading: true })
        const args: any = {
            action: "giftcard-export",
            account: this.props.role.account_id
        }

        const client = firebase.functions().httpsCallable("clientApi")
        try {
            const result = await client(args)
            this.setState({ exportEnabled: true, exportLoading: undefined, exportRecipients: result.data.recipients, exportStatus: result.data.export_status, exportRecipientEditIndex: undefined })
        } catch {
            this.setState({ exportEnabled: false, exportLoading: undefined, exportRecipients: undefined, exportStatus: undefined, exportRecipientEditIndex: undefined })
        }
    }

    checkoutsRef() {
        const account = this.props.role.account_id
        return firestore.collection(`accounts/${account}/checkouts`)
    }

    activateGiftcard = async (code: string) => {
        const args: any = {
            action: "giftcard-activate",
            account: this.props.role.account_id,
            id: code
        }
        const client = firebase.functions().httpsCallable("clientApi")
        await client(args)
        await this.loadGiftcards(this.earlierThan)
    }

    consumeGiftcard = async (code: string, amount: number) => {
        const args: any = {
            action: "giftcard-pay",
            account: this.props.role.account_id,
            id: code,
            amount: amount
        }
        const client = firebase.functions().httpsCallable("clientApi")
        await client(args)
        await this.loadGiftcards(this.earlierThan)
    }

    loadGiftcards = async (earlierThan?: string, laterThan?: string) => {
        this.setState({ loaded: false })
        const args: any = {
            action: "giftcard-list",
            account: this.props.role.account_id,
            limit: limit
        }

        let firstPage = false
        if (!_.isNil(earlierThan)) {
            args.before = earlierThan
        } else if (!_.isNil(laterThan)) {
            args.after = laterThan
        } else {
            firstPage = true
        }

        if (!_.isNil(this.state.status) && this.state.status !== "all") {
            args.status = this.state.status
        }

        const client = firebase.functions().httpsCallable("clientApi")
        const result = await client(args)
        console.log(result)
        const giftcards: any[] = result.data
        let lastGiftcardId: string | undefined = undefined
        let firstGiftcardId: string | undefined = undefined
        if (giftcards.length > 0) {
            firstGiftcardId = giftcards[0].id
            if (!firstPage) {
                this.earlierThan = firstGiftcardId
            }
            lastGiftcardId = giftcards[giftcards.length - 1].id

            // Find all giftcards with 'created' state and check if we are allowed to reactivate.
            // We are allowed to reactivate if we have a non-voided sale including the giftcard
            for (const giftcard of giftcards) {
                if (giftcard.status !== "created") {
                    continue
                }
                const checkoutSnap = await this.checkoutsRef().where("index.giftcard_codes", "array-contains", giftcard.code).get()
                if (checkoutSnap.empty) {
                    continue
                }
                let nonVoidCheckout = false
                for (const doc of checkoutSnap.docs) {
                    if (doc.data().voided === false) {
                        nonVoidCheckout = true
                    }
                }
                if (nonVoidCheckout) {
                    giftcard.allowActivation = true
                }

            }
        } else {
            this.earlierThan = undefined
        }
        this.setState({ loaded: true, firstPage: firstPage, giftcards: result.data, firstGiftcardId: firstGiftcardId, lastGiftcardId: lastGiftcardId, lastPage: giftcards.length < limit })
    }

    setFilter(status: string) {
        this.setState({ status: status }, () => {
            this.loadGiftcards(undefined)
        })
    }

    renderPager() {
        return <Pager>
            <Pager.Item previous={true} disabled={!this.state.loaded || this.state.firstPage} onClick={async () => await this.loadPrevious()}>&larr; Newer</Pager.Item>
            <ToggleButtonGroup type="radio" name="options" value={this.state.status ?? "all"} onChange={async (value) => { this.setFilter(value) }}>
                <ToggleButton value="all">All</ToggleButton>
                <ToggleButton value="activated">Active</ToggleButton>
                <ToggleButton value="used">Used</ToggleButton>
            </ToggleButtonGroup>
            <Pager.Item next={true} disabled={!this.state.loaded || this.state.lastPage} onClick={async () => { this.loadNext() }}>Older &rarr;</Pager.Item>
        </Pager>
    }

    setExportFilter(status: string) {
        this.setState({ exportStatus: status })
    }

    get editedRecipientValue() {
        if (_.isNil(this.state.exportRecipientEditIndex)) {
            return ""
        }
        if (_.isNil(this.state.exportRecipients)) {
            return ""
        }
        if (this.state.exportRecipients.length <= this.state.exportRecipientEditIndex) {
            return ""
        }
        return this.state.exportRecipients[this.state.exportRecipientEditIndex]
    }

    updateEditedRecipient(value?: string) {
        if (_.isNil(this.state.exportRecipientEditIndex)) {
            return
        }
        if (_.isNil(this.state.exportRecipients)) {
            return
        }
        if (this.state.exportRecipients.length <= this.state.exportRecipientEditIndex) {
            return
        }
        const clone = _.cloneDeep(this.state.exportRecipients)
        clone[this.state.exportRecipientEditIndex] = value ?? ""
        this.setState({ exportRecipients: clone })
    }

    removeRecipient(index: number) {
        if (_.isNil(this.state.exportRecipients)) {
            return
        }
        if (this.state.exportRecipients.length <= index) {
            return
        }

        const clone = _.cloneDeep(this.state.exportRecipients)
        clone.splice(index, 1)
        this.setState({ exportRecipients: clone })
    }
    addRecipient() {
        const clone = _.cloneDeep(this.state.exportRecipients ?? [])
        clone.push("")
        this.setState({ exportRecipients: clone, exportRecipientEditIndex: clone.length - 1 })
    }

    private handleKeyPress(value: any) {
        if (value.charCode === 13) {
            this.setState({ exportRecipientEditIndex: undefined })
        }
    }

    get submitDisabled() {
        if (this.state.exportRecipients === undefined) {
            return true
        }
        if (this.state.exportRecipients.length === 0) {
            return true
        }
        if (this.state.exportRecipients.length === 1 && this.state.exportRecipients[0] === "") {
            return true
        }
        return false
    }

    renderExportConfigBody() {
        if (this.state.exportEditing) {
            return <span>
                <StripedTable>
                    <thead>
                        <tr><th>Recipients</th></tr>
                    </thead>
                    <tbody>
                        {this.state.exportRecipients && this.state.exportRecipients.map((r, index) => {
                            return <tr key={index}>
                                <td onClick={() => {
                                    if (this.state.exportRecipientEditIndex !== index) {
                                        this.setState({ exportRecipientEditIndex: index })
                                    }
                                }}
                                >
                                    {this.state.exportRecipientEditIndex === index ? <FormControl
                                        type="text"
                                        value={this.editedRecipientValue}
                                        placeholder="Enter email address"
                                        onChange={(e: any) => { this.updateEditedRecipient(e.target.value) }}
                                        onKeyPress={(value) => { this.handleKeyPress(value) }}
                                    /> : <span>{r}</span>}
                                </td>
                                <td className="narrow">
                                    <ConfirmDeleteButton
                                        message={`Remove recipient: ${r}`}
                                        onDelete={async () => { this.removeRecipient(index) }}
                                    />
                                </td>
                            </tr>
                        })}
                    </tbody>
                </StripedTable>
                <Button onClick={async () => { this.addRecipient() }}>Add recipient</Button>

                <span>
                    <br /><br />
                    <div>
                        Which giftcards do you wish to export?
                    </div>
                    <br />
                    <ToggleButtonGroup type="radio" name="options" value={this.state.exportStatus ?? "all"} onChange={async (value) => { this.setExportFilter(value) }}>
                        <ToggleButton value="all">All</ToggleButton>
                        <ToggleButton value="activated">Active</ToggleButton>
                        <ToggleButton value="used">Used</ToggleButton>
                    </ToggleButtonGroup>
                    <br /><br />
                    <Button bsStyle="danger" disabled={this.submitDisabled} onClick={async () => await this.enableExport()}>Submit</Button>
                </span>
                <br />
            </span>
        } else if (this.state.exportEnabled) {
            return <span>
                <div>
                    A daily export of <b>{this.state.exportStatus}</b> giftcards is currently configured to be received by:
                </div>

                <StripedTable>
                    <thead>
                        <tr><th>Recipients</th></tr>
                    </thead>
                    <tbody>
                        {this.state.exportRecipients && this.state.exportRecipients.map((r, index) => {
                            return <tr key={index}>
                                <td>{r}</td>
                            </tr>
                        })}
                    </tbody>
                </StripedTable>

                <ButtonToolbar>
                    <Button onClick={async () => await this.setState({ exportEditing: true })}>Edit configuration</Button>
                    <Button onClick={async () => await this.triggerExport()}>Trigger export</Button>
                    <Button bsStyle="danger" onClick={async () => await this.disableExport()}>Disable export</Button>
                </ButtonToolbar>
            </span>
        } else {
            return <span>
                Export not enabled
                <Button onClick={async () => await this.setState({ exportEditing: true, exportRecipients: [""], exportRecipientEditIndex: 0 })}>Enable export</Button>
            </span>
        }
    }

    renderModal() {
        return (
            <Modal show={this.state.showConfig} onHide={() => { /* */ }}>
                <Modal.Header>Giftcard export configuration</Modal.Header>
                <Modal.Body>
                    {this.state.exportLoading ? <span>Loading...</span> : this.renderExportConfigBody()}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { this.hideConfig() }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    async hideConfig() {
        this.setState({ showConfig: undefined, exportEnabled: undefined, exportRecipients: undefined, exportStatus: undefined })
    }

    async showConfig() {
        this.setState({ showConfig: true })
        await this.loadConfig()
    }

    render() {
        return (
            <PageState loading={false} typeName="giftcards">
                {this.state.loaded && <Button style={{ marginLeft: "10px" }} className="pull-right" onClick={async () => this.performExport()}>Export {this.state.status ?? "all"} giftcards</Button>}
                <Button className="pull-right" onClick={() => { this.showConfig() }}><FontAwesomeIcon icon={faCog} /></Button><br />
                {this.renderModal()}
                {this.renderPager()}
                <Panel>
                    <Panel.Heading>Giftcards</Panel.Heading>
                    <Panel.Body>
                        {this.state.loaded ?
                            <StripedTable>
                                <thead>
                                    <tr>
                                        <th>Date/Time</th>
                                        <th>Type</th>
                                        <th>Code</th>
                                        <th>Status</th>
                                        <th>Sent to</th>
                                        <th>Used date</th>
                                        <th style={{ textAlign: "right" }}>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.giftcards.map(listValue => {
                                        // console.log("GIFTCARD", listValue)
                                        return (
                                            <tr style={{ color: listValue.voided ? "#cccccc" : "black" }} key={listValue.id} >
                                                <td>
                                                    <FormattedTime
                                                        value={new Date(listValue.create_date * 1000)}
                                                        day="numeric"
                                                        month="long"
                                                        year="numeric"
                                                    /><br />
                                                    <i style={{ fontSize: "smaller" }}>(expires&nbsp;
                                                        <FormattedDate
                                                            value={new Date(listValue.expiry_date * 1000)}
                                                            day="numeric"
                                                            month="long"
                                                            year="numeric"
                                                        />)</i>
                                                </td>
                                                <td>{listValue.type === "voucher" ? "Voucher" : "Giftcard"}</td>
                                                <td><a href={`/sales?giftcard_code=${listValue.code}`}>{listValue.code}</a></td>
                                                <td>
                                                    {listValue.status}&nbsp;
                                                    {listValue.status === "created" && listValue.allowActivation === true && <Button onClick={async () => { this.activateGiftcard(listValue.code) }}>Activate</Button>}
                                                    {listValue.status === "activated" && this.state.allowGiftcardConsume && <ConfirmButton title="Consume" message="Are you certain that you want to mark this giftcard as consumed? The action cannot be undone." onAction={async () => { this.consumeGiftcard(listValue.code, listValue.amount) }}>Consume</ConfirmButton>}
                                                </td>
                                                <td>{listValue.email}</td>
                                                <td>
                                                    {!_.isNil(listValue.used_date) && <span>
                                                        <FormattedTime
                                                            value={new Date(listValue.used_date * 1000)}
                                                            day="numeric"
                                                            month="long"
                                                            year="numeric"
                                                            hour="numeric"
                                                            minute="numeric"
                                                        /><br /><i style={{ fontSize: "smaller" }}>(<FormattedRelative value={new Date(listValue.used_date * 1000)} />)</i></span>}
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    {`${listValue.amount} ${listValue.currency_code}`}
                                                    {!_.isNil(listValue.tax_type) && <i style={{ fontSize: "smaller" }}><br />(VAT incl.)</i>}
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </StripedTable>
                            : <div>Loading...</div>}
                    </Panel.Body>
                </Panel >
                {this.renderPager()}
            </PageState >
        )
    }
}

export default withRouter(GiftcardList)
