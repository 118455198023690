import firebase from "firebase/compat"
import _ from "lodash"
import * as React from "react"
import {
    Button,
    FormGroup,
    Glyphicon,
    Panel,
    ProgressBar
} from "react-bootstrap"
import { ChannelSelector } from "../../../ChannelSelector"
import { MarketSelector } from "../../../MarketSelector"
import { ShopifyConfiguration } from "./Models"

interface ProductImportPanelProps {
    accountId: string
    channels: _.Dictionary<boolean>
    markets: _.Dictionary<boolean>
    configuration: ShopifyConfiguration
    openShopifyPrivateApps: () => void
    updateChannels: (channels: string[]) => void
    updateMarkets: (markets: string[]) => void
}

interface ProductImportPanelState {
    importCount?: number
    importProgress?: number
    importTotal?: number
}

export class ProductImportPanel extends React.Component<ProductImportPanelProps, ProductImportPanelState> {

    constructor(props: ProductImportPanelProps) {
        super(props)
        this.state = {}
    }

    // Helpers

    async importProducts() {
        const triggerProductImport = firebase.functions().httpsCallable("triggerImport")
        this.setState({ importProgress: 0, importCount: undefined, importTotal: undefined })
        let imported: number = 0
        let total: number = 0
        let nextPageURL: string | undefined = undefined
        const markets: string[] = Object.keys(this.props.configuration.markets)
        if (markets.length === 0) {
            markets.push("dk")
        }
        const channels: string[] = Object.keys(this.props.configuration.channels)
        if (channels.length === 0) {
            channels.push("pos")
        }
        while (true) {
            const options: any = {
                account: this.props.accountId,
                integrationType: "shopify",
                integration: "shopify",
                channels: channels,
                markets: markets,
                elementType: "products"
            }
            if (nextPageURL) {
                options.nextPageURL = nextPageURL
            }
            const result = await triggerProductImport(options)
            if (result.data.total) {
                total = result.data.total
            }
            if (result.data.count) {
                imported += result.data.count
            }
            nextPageURL = result.data.next_page_url

            let progress = 0
            if (imported > 0 && total > 0) {
                progress = (100 * imported) / total
            }
            this.setState({ importProgress: progress, importCount: imported, importTotal: total })

            if (!nextPageURL) {
                break
            }
        }
    }

    productImportHelpText = (
        <div key="product_import_help_text">
            Follow these steps to perform a product import from Shopify to Ka-ching
            <ol>
                <li>
                    Click the "Open Shopify Configuration" button above to open Shopify settings. Click the Ka-ching private app in that page.
                </li>
                <li>
                    Make sure that Ka-ching has access to read the products in Shopify. The product import requires 'Read access' to 'Products, variants and collections'.
                </li>
                <li>
                    Remember to save any changes in Shopify.
                </li>
                {
                    Object.keys(this.props.channels).length > 1
                        ?
                        (
                            <li>
                                Use the channel selector below to select the channels you want the products to be available in.
                            </li>
                        )
                        :
                        null
                }
                {
                    Object.keys(this.props.markets).length > 1
                        ?
                        (
                            <li>
                                Use the channel selector below to select the markets you want the products to be available in.
                            </li>
                        )
                        :
                        null
                }
                <li>
                    Click 'Import products' to start importing your products.
                </li>
            </ol>
        </div>
    )

    importLabelText(): string {
        if (this.state.importCount !== undefined && this.state.importTotal !== undefined) {
            const label = `${this.state.importCount} / ${this.state.importTotal}`
            if (this.state.importCount >= this.state.importTotal) {
                return label + " Import complete!"
            }
            return label
        } else {
            return "Initiating import"
        }
    }

    // Component

    render() {
        return (
            <Panel key="product_import" defaultExpanded={false}>
                <Panel.Heading>
                    <Panel.Title toggle={true}>Product import</Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <Button onClick={() => { this.props.openShopifyPrivateApps() }}>Open Shopify Configuration <Glyphicon glyph="new-window" /></Button>
                        <br /> <br />
                        {this.productImportHelpText}
                        <FormGroup>
                            <ChannelSelector
                                selectedChannels={Object.keys(this.props.channels)}
                                onChange={channels => { this.props.updateChannels(channels) }}
                            />
                            <MarketSelector
                                typeName="Shopify integration"
                                showDeleteWarning={false}
                                selectedMarkets={Object.keys(this.props.markets)}
                                addMarket={market => {
                                    const markets = _.cloneDeep(this.props.markets)
                                    markets[market] = true
                                    this.props.updateMarkets(Object.keys(markets))
                                }}
                                removeMarket={market => {
                                    const markets = _.cloneDeep(this.props.markets)
                                    delete markets[market]
                                    this.props.updateMarkets(Object.keys(markets))
                                }}
                            />
                        </FormGroup>
                        <br /> <br />
                        <Button disabled={this.state.importProgress !== undefined} onClick={async () => { await this.importProducts() }}>Import products</Button>
                        <br /> <br />
                        {this.state.importProgress !== undefined ? <ProgressBar now={this.state.importProgress} label={this.importLabelText()} /> : null}
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        )
    }
}