import * as _ from "lodash"
import * as React from "react"

import { Button, Glyphicon, Panel } from "react-bootstrap"
import { shopifyAPIVersion, ShopifyConfiguration } from "./Models"

interface NewsletterSignupExportPanelProps {
    accountId: string
    configuration: ShopifyConfiguration
    enabled: boolean
    openShopifyPrivateApps: () => void
    updateDatabaseUpdates: (updates: _.Dictionary<any>) => void
    updateEnabled: (enabled: boolean) => void
    requestHeaders: () => any
}

interface NewsletterSignupExportPanelState {
    valid: boolean
}

export class NewsletterSignupExportPanel extends React.Component<NewsletterSignupExportPanelProps, NewsletterSignupExportPanelState> {

    constructor(props: NewsletterSignupExportPanelProps) {
        super(props)
        this.state = {
            valid: this.isConfigurationValid(props.configuration)
        }
    }

    // Helpers

    isConfigurationValid(configuration: ShopifyConfiguration) {
        return true
    }

    newsletterSignupExportHelpText = (
        <div key="newsletter_signup_export_help_text">
            Follow these steps to configure export of ecommerce sales from Ka-ching to Shopify
            <ol>
                <li>
                    Click the "Open Shopify Configuration" button above to open Shopify settings. Click the Ka-ching private app in that page.
                </li>
                <li>
                    Make sure that Ka-ching has access to create and update customers in Shopify. The ecommerce sales export requires 'Read and write' to 'Customers'.
                </li>
                <li>
                    Remember to save any changes in Shopify.
                </li>
                <li>
                    Toggle the Disabled/Enabled button below to enable the integration in Ka-ching.
                </li>
                <li>
                    Publish the Shopify configuration changes
                </li>
            </ol>
        </div>
    )

    enableButton = (enabled: boolean) => {
        return (
            <Button bsStyle={enabled ? "success" : "default"} active={enabled} onClick={() => { this.toggleEnabled() }}>
                {enabled ? "Enabled" : "Disabled"}
            </Button>
        )
    }

    toggleEnabled = () => {
        const enabled = !this.props.enabled
        this.props.updateEnabled(enabled)

        // update customer lookup config based on enabled status
        if (enabled) {
            const config = {
                delivery: {
                    parameters: {
                        headers: this.props.requestHeaders(),
                        method: "POST",
                        url: `https://${this.props.configuration.shopifyShopName}.myshopify.com/admin/api/${shopifyAPIVersion}/customers.json`
                    },
                    type: "web_hook"
                },
                name: "Shopify Newsletter Export",
                show_in_portal: false,
                transformation: {
                    parameters: {
                        api_key: this.props.configuration.shopifyAPIKey ?? null,
                        password: this.props.configuration.shopifyPassword ?? null,
                        shopify_id: this.props.configuration.shopifyShopName,
                        access_token: this.props.configuration.shopifyAccessToken ?? null
                    },
                    type: "shopify"
                },
                trigger: {
                    type: "realtime"
                }
            }

            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/export_integrations/newsletter/shopify`] = config
            this.props.updateDatabaseUpdates(dbUpdates)
        } else {
            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/export_integrations/newsletter/shopify`] = null
            this.props.updateDatabaseUpdates(dbUpdates)
        }
    }

    // Component

    componentWillReceiveProps(newProps: NewsletterSignupExportPanelProps) {
        this.setState({ valid: this.isConfigurationValid(newProps.configuration) })
    }

    render() {
        return (
            <div>
                <Panel key="newsletter_signup_export" defaultExpanded={false}>
                    <Panel.Heading>
                        <Panel.Title toggle={true}>Newsletter signup</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <Button onClick={() => { this.props.openShopifyPrivateApps() }}>Open Shopify Configuration <Glyphicon glyph="new-window" /></Button>
                            <br /> <br />
                            {this.newsletterSignupExportHelpText}
                            <br /> <br />
                            {
                                this.state.valid
                                    ?
                                    this.enableButton(this.state.valid && this.props.enabled)
                                    :
                                    <Button bsStyle="danger" disabled={true}>Invalid</Button>
                            }
                            <br /> <br />
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            </div>
        )
    }
}