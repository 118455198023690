//
//  ConfirmDeleteButton.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 31/05/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { Button } from "react-bootstrap"

interface ConfirmDeleteButtonProps {
    message: string
    onDelete(): void
}

export class ConfirmDeleteButton extends React.Component<ConfirmDeleteButtonProps, {}> {
    onClick() {
        if (window.confirm(this.props.message) === true) {
            this.props.onDelete()
        }
    }

    render() {
        return <Button bsStyle="danger" onClick={(event) => { this.onClick(); event.stopPropagation() }}>X</Button>
    }
}
