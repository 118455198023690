import * as React from "react"

import { Button, Panel, FormControl } from "react-bootstrap"
import { ref } from "../../../config/constants"
import { PageState } from "../../PageState"
import { Role } from "../../../config/role"

interface ContributionRatioConfigurationProps {
    role: Role
}

interface ContributionRatioConfigurationState {
    dirty: boolean
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
    ratio: number
}

export default class ContributionRatioConfiguration extends React.Component<ContributionRatioConfigurationProps, ContributionRatioConfigurationState> {
    constructor(props: ContributionRatioConfigurationProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            showSuccess: false,
            ratio: 0.1
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/kpi_thresholds`)
        const configSnap = await configRef.once("value")
        let ratio = 0.1
        if (configSnap.exists()) {
            ratio = configSnap.val().contribution_ratio
        }
        this.setState({ ratio: ratio, loaded: true })
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="contribution ratio">
                <Panel key="upper_panel" bsStyle={this.state.showSuccess ? "success" : "default"}>
                    <Panel.Heading>
                        <Panel.Title>{this.state.showSuccess ? "Contribution ratio published successsfully" : "Contribution ratio"}</Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <p>The <i>contribution ratio</i> of the items in the basket can be calculated in case the <i>cost price</i> of all items in the basket is known. The formula for calculating the ratio is:</p>
                        <p>First find the <i>base price</i> of all items. The <i>base price</i> is the price of the item after all discounts and taxes have been subtracted.</p>
                        <p>Find the <i>margin</i> of all items. The <i>margin</i> is the <i>base price</i> minus the <i>cost price</i>. In other words, the <i>margin</i> represents the earnings (or losses) from selling an item.</p>
                        <p>Finally, the <i>contribution ratio</i> can be calculated as the <i>margin</i> divided by the <i>base price</i>. The <i>contribution ratio</i> of an entire basket is naturally the sum of margins for all line items divided by the sum of base prices for all line items.</p>
                        <br />

                        <p>If you specify a <i>desired contribution ratio</i> - then the action button in the POS will indicate if this <i>contribution ratio</i> is met for the current basket. The color of the indicator line may be interpreted as follows:</p>
                        <ul>
                            <li><b>Green</b> The contribution ratio of the basket is greater than or equal to the desired ratio</li>
                            <li><b>Orange</b> The contribution ratio of the basket is less than the desired ratio, but the margin is still positive</li>
                            <li><b>Red</b> The margin is negative - in other words you are losing money for this sale. Consider lowering any manual discounts?</li>
                            <li><b>Gray</b> The margin cannot be calculated. One or more items in the basket do not have a specified <i>cost price</i></li>
                        </ul>
                        <br />
                        <h4>Please enter the desired contribution ratio in whole percentages.</h4>
                        <FormControl
                            type="number"
                            name="rate"
                            value={this.state.ratio * 100}
                            placeholder="Enter rate"
                            onChange={(e: any) => { this.handleChange(e.target.value) }}
                            autoComplete="off"
                        />
                    </Panel.Body>

                    <Panel.Footer>
                        <Button onClick={this.publish} disabled={!this.state.dirty}>Publish</Button>
                    </Panel.Footer>
                </Panel >
            </PageState>
        )
    }

    handleChange = (value: string) => {
        const numberValue = Number(value)
        this.setState({ ratio: numberValue / 100, dirty: true })
    }

    publish = async () => {
        const account = this.props.role.account_id
        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/kpi_thresholds`)

        this.setState({ publishing: true })
        await configRef
            .set({ contribution_ratio: this.state.ratio })
        this.setState({ showSuccess: true, publishing: false, dirty: false })
    }
}
