import * as Constants from "../../../config/constants"
import * as Models from "./StockListModels"

export enum StockActionTypes {
    FETCH_SHOP_PRODUCTS = "FETCH_SHOP_PRODUCTS",
    FETCH_SHOP_PRODUCTS_PENDING = "FETCH_SHOP_PRODUCTS_PENDING",
    FETCH_SHOP_PRODUCTS_FULFILLED = "FETCH_SHOP_PRODUCTS_FULFILLED",
    FETCH_SHOP_PRODUCTS_REJECTED = "FETCH_SHOP_PRODUCTS_REJECTED",
    FETCH_ACCOUNT_PRODUCTS = "FETCH_ACCOUNT_PRODUCTS",
    FETCH_ACCOUNT_PRODUCTS_PENDING = "FETCH_ACCOUNT_PRODUCTS_PENDING",
    FETCH_ACCOUNT_PRODUCTS_FULFILLED = "FETCH_ACCOUNT_PRODUCTS_FULFILLED",
    FETCH_ACCOUNT_PRODUCTS_REJECTED = "FETCH_ACCOUNT_PRODUCTS_REJECTED",
    FETCH_MARKET = "FETCH_MARKET",
    FETCH_MARKET_PENDING = "FETCH_MARKET_PENDING",
    FETCH_MARKET_FULFILLED = "FETCH_MARKET_FULFILLED",
    FETCH_MARKET_REJECTED = "FETCH_MARKET_REJECTED",
    STOCK_DATA_CHANGED = "STOCK_DATA_CHANGED"
}

export function stockDataChanged(stockItems: _.Dictionary<Models.StockDBEntry>) {
    return {
        type: StockActionTypes.STOCK_DATA_CHANGED,
        payload: stockItems
    }
}

export function fetchShopProductsAction(accountId: string, shopId: string) {
    const ref = Constants.ref().child(`v1/accounts/${accountId}/shops/${shopId}/inventory/products`)
    return {
        type: StockActionTypes.FETCH_SHOP_PRODUCTS,
        payload: ref.once("value")
    }
}

export function fetchAccountProductsAction(accountId: string, marketId: string) {
    const ref = Constants.ref().child(`v1/accounts/${accountId}/inventory/products/pos/${marketId}`)
    return {
        type: StockActionTypes.FETCH_ACCOUNT_PRODUCTS,
        payload: ref.once("value")
    }
}

export function fetchMarketAction(accountId: string, shopId: string, completion: (marketId: string) => void) {
    const shopsRef = Constants.ref().child(`v1/accounts/${accountId}/shops/${shopId}/market`)
    const stockLocationsRef = Constants.ref().child(`v1/accounts/${accountId}/stock_locations/${shopId}/market`)
    const marketsRef = Constants.ref().child(`v1/accounts/${accountId}/shops/${shopId}/market`)
    const fallbackMarket = "dk"
    return {
        type: StockActionTypes.FETCH_MARKET,
        payload: shopsRef.once("value").then((snapshot) => {
            if (snapshot && snapshot.exists()) {
                return snapshot.val() as string
            } else {
                return stockLocationsRef.once("value").then((locationSnapshot) => {
                    if (locationSnapshot && locationSnapshot.exists()) {
                        return locationSnapshot.val() as string
                    } else {
                        // TODO: We have no market for pure stock locations, but we would still like to load all products
                        // for a specific market. Non-shop stock locations probably ought to have a market too.
                        return marketsRef.once("value").then((marketsSnap) => {
                            if (marketsSnap && marketsSnap.exists()) {
                                const markets = Object.keys(marketsSnap.val())
                                if (markets.length > 0) {
                                    return markets[0]
                                }
                            }
                            return fallbackMarket
                        })
                    }
                })
            }
        }).then(market => {
            console.info("MARKET", market)
            completion(market)
        })
    }
}