import * as _ from "lodash"
import * as React from "react"
import {
    Col,
    ControlLabel,
    DropdownButton,
    FormGroup,
    MenuItem
    } from "react-bootstrap"
import { ConfirmDeleteButton } from "../../ConfirmDeleteButton"
import { StripedTable } from "../../StripedTable"
import { ExportIntegrationFilter } from "./ExportIntegrationEdit"

interface ExportIntegrationFilterSelectionProps {
    availableFilters: _.Dictionary<ExportIntegrationFilter>
    selectedFilters: _.Dictionary<ExportIntegrationFilter>
    onChange: (filters: _.Dictionary<ExportIntegrationFilter>) => void
}

interface ExportIntegrationFilterSelectionState {
    selectableFilters: _.Dictionary<ExportIntegrationFilter>
    selectedFilters: _.Dictionary<ExportIntegrationFilter>
}

export class ExportIntegrationFilterSelection extends React.Component<ExportIntegrationFilterSelectionProps, ExportIntegrationFilterSelectionState> {
    constructor(props: ExportIntegrationFilterSelectionProps) {
        super(props)

        this.state = this.stateFromProps(props)
    }

    componentWillReceiveProps(props: ExportIntegrationFilterSelectionProps) {
        this.setState(this.stateFromProps(props))
    }

    stateFromProps(props: ExportIntegrationFilterSelectionProps): ExportIntegrationFilterSelectionState {
        const selectableFilters: _.Dictionary<ExportIntegrationFilter> = {}
        for (const path in props.availableFilters) {
            if (_.isNil(props.selectedFilters[path])) {
                selectableFilters[path] = props.availableFilters[path]
            }
        }

        return {
            selectableFilters: selectableFilters,
            selectedFilters: props.selectedFilters
        }
    }

    removeFilter(path: string) {
        const selected = _.cloneDeep(this.state.selectedFilters)
        delete selected[path]
        this.props.onChange(selected)
    }

    addFilter(path: string) {
        const selected = _.cloneDeep(this.state.selectedFilters)
        selected[path] = this.state.selectableFilters[path]
        this.props.onChange(selected)
    }

    render() {
        return (
            <section>
                {
                    (
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>Filters</Col>
                            <Col sm={10}>
                                {
                                    Object.keys(this.state.selectedFilters).length > 0 
                                    ? 
                                    (
                                        <StripedTable>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Object.keys(this.state.selectedFilters)
                                                        .map((path) => {
                                                            const filter = this.state.selectedFilters[path]
                                                            return (
                                                                <tr key={path}>
                                                                    <td>{filter.name}</td>
                                                                    <td className="narrow">
                                                                        <ConfirmDeleteButton
                                                                            message={`Really remove filter: ${filter.name}?`}
                                                                            onDelete={() => {
                                                                                this.removeFilter(path)
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                            </tbody>
                                        </StripedTable>
                                    ) 
                                    : 
                                    null
                                }

                                {
                                    Object.keys(this.state.selectableFilters).length > 0
                                    ? 
                                    (
                                        <DropdownButton
                                            bsStyle="default"
                                            title="Add filter"
                                            id="dropdown-add-filter"
                                            onSelect={(path: any) => {
                                                this.addFilter(path)
                                            }}
                                        >
                                            {
                                                Object.keys(this.state.selectableFilters)
                                                    .map((path) => {
                                                        const filter = this.state.selectableFilters[path]
                                                        return <MenuItem key={path} eventKey={path}>{filter.name}</MenuItem>
                                                    })
                                            }
                                        </DropdownButton>
                                    ) 
                                    : 
                                    null
                                }

                            </Col>

                        </FormGroup>
                    )
                }
            </section>
        )
    }
}
