import * as React from "react"
import { Role } from "../../../config/role"
import { ref } from "../../../config/constants"
import { Form, FormGroup, Col, ControlLabel, FormControl, Button, Alert, Modal, DropdownButton, MenuItem, Checkbox } from "react-bootstrap"
import { ValidatingIdEntryControl } from "../../ValidatingIdEntryControl"
import { StripedTable } from "../../StripedTable"
import { RuntimeIntegrationType, integrationName, allowsParameters, runtimeIntegrationIdentification, RuntimeIntegrationIdentification } from "./RuntimeIntegrations"
import * as url from "url"
import * as _ from "lodash"
import firebase from "firebase/compat"

interface RuntimeIntegrationEditProps {
    role: Role
    integrationType: RuntimeIntegrationType
    editComplete: () => (void)
    existingIntegration?: any
    existingIntegrationId?: string
    additionalElements?: AdditionalElement[]
    staticElements?: AdditionalElement[]
}

export enum AdditionalElementType {
    text,
    list,
    bool,
    currencyAmount
}

export interface AdditionalElement {
    type: AdditionalElementType,
    propertyName: string
    name: string
    placeholder: string
}

enum KeyValueType {
    header, parameter
}

enum HTTPMethod {
    POST = "POST",
    GET = "GET",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH"
}

interface RuntimeIntegrationEditState {
    addIntegrationName?: string
    addIntegrationId?: string
    addIntegrationEndpoint?: string
    addIntegrationHeaders?: any
    addIntegrationKey?: string
    addIntegrationValue?: string
    addIntegrationParameters?: any
    addAnother?: KeyValueType
    errorDescription?: string
    isNew: boolean
    httpMethod: HTTPMethod
    integration: any
    staticReply?: boolean
    addStaticElement?: number
}

export class RuntimeIntegrationEdit extends React.Component<RuntimeIntegrationEditProps, RuntimeIntegrationEditState> {
    constructor(props: RuntimeIntegrationEditProps) {
        super(props)

        let isNew: boolean
        if (this.props.existingIntegration === undefined) {
            isNew = true
        } else {
            isNew = false
        }
        const integration = this.props.existingIntegration || {}

        this.state = {
            addIntegrationName: integration.name,
            addIntegrationId: this.props.existingIntegrationId,
            addIntegrationEndpoint: (integration.request || {}).url,
            addIntegrationHeaders: (integration.request || {}).headers,
            addIntegrationParameters: (integration.request || {}).parameters,
            httpMethod: (integration.request || {}).method || HTTPMethod.POST,
            isNew: isNew,
            integration: integration,
            // staticReply: integration.static === undefined ? undefined : true
        }
    }

    getAdditionalElementValue(element: AdditionalElement): string {
        const integration = this.state.integration
        const value = integration[element.propertyName]
        if (value === undefined) { return "" }
        switch (element.type) {
            case AdditionalElementType.text: {
                if (_.isString(value)) { return value } else { return "" }
            }
            case AdditionalElementType.list: {
                if (_.isArray(value)) { return value.join(", ") } else { return "" }
            }
            case AdditionalElementType.bool: {
                if (_.isBoolean(value)) { return value ? "true" : "false" } else { return "false" }
            }
            case AdditionalElementType.currencyAmount: {
                if (_.isNumber(value)) { return `${value}` } else { return "0" }
            }
        }
        return ""
    }

    setAdditionalElementValue(element: AdditionalElement, value: string) {
        const integration = _.cloneDeep(this.state.integration)
        switch (element.type) {
            case AdditionalElementType.text:
                if (value === "") {
                    delete integration[element.propertyName]
                } else {
                    integration[element.propertyName] = value
                }
                break
            case AdditionalElementType.list:
                const array = value.split(",").map(elm => { return elm.trim() })
                if (array.length === 1 && array[0] === "") {
                    delete integration[element.propertyName]
                } else {
                    integration[element.propertyName] = array
                }
                break
            case AdditionalElementType.bool:
                integration[element.propertyName] = (value === "true")
                break
            case AdditionalElementType.currencyAmount:
                integration[element.propertyName] = parseFloat(value)
                break
        }
        this.setState({ integration: integration })
    }

    getStaticElementValue(element: AdditionalElement, index: number): string {
        const staticElementArray = this.state.integration.static || []
        const staticElement = staticElementArray[index] || {}
        const value = staticElement[element.propertyName]
        if (value === undefined) { return "" }
        switch (element.type) {
            case AdditionalElementType.text: {
                if (_.isString(value)) { return value } else { return "" }
            }
            case AdditionalElementType.list: {
                if (_.isArray(value)) { return value.join(", ") } else { return "" }
            }
            case AdditionalElementType.bool: {
                if (_.isBoolean(value)) { return value ? "true" : "false" } else { return "false" }
            }
            case AdditionalElementType.currencyAmount: {
                if (_.isNumber(value)) { return `${value}` } else { return "0" }
            }
        }
        return ""
    }

    setStaticElementValue(element: AdditionalElement, index: number, value: string) {
        const integration = _.cloneDeep(this.state.integration)
        const staticElementArray = integration.static || []
        const staticElement = staticElementArray[index] || {}

        switch (element.type) {
            case AdditionalElementType.text:
                if (value === "") {
                    delete staticElement[element.propertyName]
                } else {
                    staticElement[element.propertyName] = value
                }
                break
            case AdditionalElementType.list:
                const array = value.split(",").map(elm => { return elm.trim() })
                if (array.length === 1 && array[0] === "") {
                    delete staticElement[element.propertyName]
                } else {
                    staticElement[element.propertyName] = array
                }
                break
            case AdditionalElementType.bool:
                staticElement[element.propertyName] = (value === "true")
                break
            case AdditionalElementType.currencyAmount:
                integration[element.propertyName] = parseFloat(value)
                break
        }
        if (staticElementArray.length <= index) {
            staticElementArray.push(staticElement)
        }
        integration.static = staticElementArray

        this.setState({ integration: integration })
    }

    skipEndpointConfiguration(): boolean {
        if (this.state.integration.is_local === true) {
            return true
        }
        if (this.state.staticReply === true) {
            return true
        }
        return false
    }

    integrationRef(): firebase.database.Reference {
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        return accountRef.child(`configuration/${this.props.integrationType}`)
    }

    addIntegrationEnabled(): boolean {
        const id = this.state.addIntegrationId
        const name = this.state.addIntegrationName
        const endpoint = this.state.addIntegrationEndpoint
        for (const param of [id, name]) {
            if (param === undefined || param.length === 0) {
                return false
            }
        }

        if (this.skipEndpointConfiguration()) {
            return true
        }

        if (endpoint === undefined || endpoint.length === 0) {
            return false
        }

        const parsedURL = url.parse(endpoint || "")
        if (!(parsedURL.slashes === true)) { return false }
        if ((parsedURL.host || "").length === 0) { return false }
        if (parsedURL.protocol !== "https:" && parsedURL.protocol !== "http:") {
            return false
        }
        return true
    }

    async addIntegration() {
        const id = this.state.addIntegrationId
        const name = this.state.addIntegrationName
        const integrationType = this.props.integrationType
        const endpoint = this.state.addIntegrationEndpoint
        if (id === undefined) { return }
        if (name === undefined) { return }
        if (endpoint === undefined && !this.skipEndpointConfiguration()) { return }
        this.setState({ errorDescription: undefined })
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)

        const existingIntegrationRef = accountRef.child(`configuration/${integrationType}/${id}`)
        if (this.state.isNew) {
            if ((await existingIntegrationRef.once("value")).exists()) {
                const errorDescription = `Integration with type ${integrationType} and id ${id} already exists`
                console.error(errorDescription)
                this.setState({ errorDescription: errorDescription })
                return
            }
        }

        const headers = this.state.addIntegrationHeaders || {}
        const parameters = this.state.addIntegrationParameters || {}

        const priority: any = (runtimeIntegrationIdentification(this.props.integrationType) === RuntimeIntegrationIdentification.byPriority) ? 1 : {}

        // Update integration without overwriting paramaters that this editor is not aware of
        const integrationParams: any = _.cloneDeep(this.state.integration)
        integrationParams.name = name
        integrationParams.id = id
        integrationParams.priority = priority
        integrationParams.request = { url: endpoint || null, method: this.state.httpMethod, headers: headers, parameters: parameters }
        if (integrationParams.transformation === undefined) {
            integrationParams.transformation = { type: "identity" }
        }
        await existingIntegrationRef.set(integrationParams)

        this.props.editComplete()
    }

    render() {
        return (
            <Modal show={true} onHide={() => { this.props.editComplete() }} >
                <Modal.Header closeButton={true}>
                    <Modal.Title>{this.state.isNew ? "Add new integration" : "Edit integration"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.errorDescription ? <Alert bsStyle="warning">{this.state.errorDescription}</Alert> : null}
                    Please configure the {integrationName(this.props.integrationType).toLowerCase()} integration.
                    <br /><br />
                    <Form horizontal={true} onSubmit={e => e.preventDefault()}>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>Integration name</Col>
                            <Col sm={10}>
                                <FormControl
                                    type="text"
                                    name="integrationname"
                                    value={this.state.addIntegrationName || ""}
                                    placeholder="Enter integration name"
                                    onChange={(event: any) => {
                                        this.setState({ addIntegrationName: event.target.value })
                                    }}
                                    autoComplete="off"
                                />
                            </Col>
                        </FormGroup>
                        <ValidatingIdEntryControl
                            collectionRef={this.integrationRef()}
                            isNew={this.state.isNew}
                            typeName="export integration"
                            identifierSource={this.state.addIntegrationName || ""}
                            existingIdentifier={this.props.existingIntegrationId || ""}
                            handleIdChange={(id, valid) => {
                                this.setState({ addIntegrationId: id })
                            }}
                        />

                        {/* {(this.props.staticElements !== undefined) ?
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Static reply</Col>
                                <Col sm={10}>
                                    <Checkbox
                                        checked={this.state.staticReply === true}
                                        onChange={(event: any) => {
                                            this.setState({ staticReply: event.target.checked ? true : undefined })
                                        }}
                                    >
                                        Check this if you wish to configure a static reply instead of requesting from an external service.
                                    </Checkbox>
                                </Col>
                            </FormGroup> : undefined} */}

                        {(this.props.additionalElements || []).map(element => {
                            if (element.type === AdditionalElementType.bool) {
                                return (
                                    <FormGroup key={element.propertyName}>
                                        <Col componentClass={ControlLabel} sm={2}>{element.name}</Col>
                                        <Col sm={10}>
                                            <Checkbox
                                                checked={this.getAdditionalElementValue(element) === "true"}
                                                onChange={(event: any) => {
                                                    this.setAdditionalElementValue(element, event.target.checked ? "true" : "false")
                                                }}
                                            >
                                                {element.placeholder}
                                            </Checkbox>
                                        </Col>
                                    </FormGroup>
                                )
                            } else {
                                return (
                                    <FormGroup key={element.propertyName}>
                                        <Col componentClass={ControlLabel} sm={2}>{element.name}</Col>
                                        <Col sm={10}>
                                            <FormControl
                                                type="text"
                                                name={element.propertyName}
                                                value={this.getAdditionalElementValue(element)}
                                                placeholder={element.placeholder}
                                                onChange={(event: any) => {
                                                    this.setAdditionalElementValue(element, event.target.value)
                                                }}
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </FormGroup>
                                )
                            }
                        })}

                        {/* {this.state.staticReply === true ?
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Static reply</Col>
                                <Col sm={10}>
                                    <StripedTable>
                                        <thead>
                                            <tr>
                                                {(this.props.staticElements || []).map(element => {
                                                    return <th key={element.propertyName}>{element.name}</th>
                                                })}
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.integration.static || []).map((staticEntry: any, index: number) => {
                                                return <tr
                                                    key={`row-${index}`}
                                                    onClick={() => {
                                                        if (this.state.addStaticElement) {
                                                            // Already editing
                                                            return
                                                        }

                                                        this.setState({
                                                            addStaticElement: index
                                                        })
                                                    }}
                                                >
                                                    {(this.props.staticElements || []).map(element => {
                                                        return <td key={element.propertyName}>{this.getStaticElementValue(element, index)}</td>
                                                    })}

                                                    <td key="_delete" className="narrow">
                                                        <Button
                                                            bsStyle="danger"
                                                            onClick={(event) => {
                                                                event.stopPropagation()
                                                                const integration = _.cloneDeep(this.state.integration)
                                                                const staticItems: any[] = integration.static || []
                                                                staticItems.splice(index, 1)
                                                                this.setState({ integration: integration })
                                                            }}
                                                        >X
                                                        </Button>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </StripedTable>
                                </Col>
                            </FormGroup> : null
                        }

                        {this.state.staticReply === true && this.state.addStaticElement === undefined ? <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>&nbsp;</Col>
                            <Col sm={10}>
                                <Button onClick={() => { this.setState({ addStaticElement: (this.state.integration.static || []).length }) }}>Add a static element</Button>
                            </Col>
                        </FormGroup> : undefined}

                        {this.state.addStaticElement !== undefined ? <div>
                            {(this.props.staticElements || []).map(element => {
                                if (element.type === AdditionalElementType.bool) {
                                    return (
                                        <FormGroup key={element.propertyName}>
                                            <Col componentClass={ControlLabel} sm={2}>{element.name}</Col>
                                            <Col sm={10}>
                                                <Checkbox
                                                    checked={this.getStaticElementValue(element, this.state.addStaticElement!) === "true"}
                                                    onChange={(event: any) => {
                                                        this.setStaticElementValue(element, this.state.addStaticElement!, event.target.checked ? "true" : "false")
                                                    }}
                                                >
                                                    {element.placeholder}
                                                </Checkbox>
                                            </Col>
                                        </FormGroup>
                                    )
                                } else {
                                    return (
                                        <FormGroup key={element.propertyName}>
                                            <Col componentClass={ControlLabel} sm={2}>{element.name}</Col>
                                            <Col sm={10}>
                                                <FormControl
                                                    type="text"
                                                    name={element.propertyName}
                                                    value={this.getStaticElementValue(element, this.state.addStaticElement!)}
                                                    placeholder={element.placeholder}
                                                    onChange={(event: any) => {
                                                        this.setStaticElementValue(element, this.state.addStaticElement!, event.target.value)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                        </FormGroup>
                                    )
                                }
                            })}

                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>&nbsp;</Col>
                                <Col sm={10}>
                                    <Button
                                        onClick={() => {
                                            this.setState({ addStaticElement: undefined })
                                        }}
                                    >
                                        Done
                                    </Button>
                                </Col>
                            </FormGroup>
                        </div> : undefined} */}

                        {this.skipEndpointConfiguration() ? undefined : (
                            <div>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>Endpoint</Col>
                                    <Col sm={10}>
                                        <FormControl
                                            type="text"
                                            name="endpoint"
                                            value={this.state.addIntegrationEndpoint || ""}
                                            placeholder="Enter webhook endpoint (an http/https URL)"
                                            onChange={(event: any) => {
                                                this.setState({ addIntegrationEndpoint: event.target.value })
                                            }}
                                            autoComplete="off"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>HTTP Method</Col>
                                    <Col sm={10}>
                                        <DropdownButton
                                            title={this.state.httpMethod}
                                            id="httpmethod"
                                            onSelect={(event: any) => {
                                                this.setState({ httpMethod: event as HTTPMethod })
                                            }}
                                        >
                                            {
                                                [HTTPMethod.GET, HTTPMethod.POST, HTTPMethod.PUT, HTTPMethod.DELETE, HTTPMethod.PATCH].map(method => {
                                                    return <MenuItem key={method} eventKey={method}>{method}</MenuItem>
                                                })
                                            }
                                        </DropdownButton>
                                    </Col>
                                </FormGroup>

                                {
                                    this.state.addIntegrationHeaders ? (
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2}>HTTP request headers</Col>
                                            <Col sm={10}>
                                                <StripedTable>
                                                    <thead>
                                                        <tr>
                                                            <th>Header name</th>
                                                            <th>Header value</th>
                                                            <th>Delete</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(this.state.addIntegrationHeaders || {}).map((headerName: string) => {
                                                            const value = (this.state.addIntegrationHeaders || {})[headerName] || ""

                                                            return (
                                                                <tr
                                                                    key={headerName}
                                                                    onClick={() => {
                                                                        if (this.state.addAnother) {
                                                                            // Already editing
                                                                            return
                                                                        }
                                                                        const headers = this.state.addIntegrationHeaders || {}
                                                                        delete headers[headerName]
                                                                        this.setState({
                                                                            addIntegrationHeaders: headers,
                                                                            addAnother: KeyValueType.header,
                                                                            addIntegrationKey: headerName,
                                                                            addIntegrationValue: value
                                                                        })
                                                                    }}
                                                                >
                                                                    <td>{headerName}</td>
                                                                    <td style={{ wordBreak: "break-all" }}>{value}</td>
                                                                    <td className="narrow">
                                                                        <Button
                                                                            bsStyle="danger"
                                                                            onClick={(event) => {
                                                                                event.stopPropagation()
                                                                                const headers = this.state.addIntegrationHeaders || {}
                                                                                delete headers[headerName]
                                                                                this.setState({ addIntegrationHeaders: headers })
                                                                            }}
                                                                        >X
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </StripedTable>
                                            </Col>
                                        </FormGroup>
                                    ) : null
                                }

                                {
                                    this.state.addIntegrationParameters ? (
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2}>Query parameters</Col>
                                            <Col sm={10}>
                                                <StripedTable>
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Value</th>
                                                            <th>Delete</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(this.state.addIntegrationParameters || {}).map((headerName: string) => {
                                                            const value = (this.state.addIntegrationParameters || {})[headerName] || ""

                                                            return (
                                                                <tr
                                                                    key={headerName}
                                                                    onClick={() => {
                                                                        if (this.state.addAnother) {
                                                                            // Already editing
                                                                            return
                                                                        }
                                                                        const headers = this.state.addIntegrationParameters || {}
                                                                        delete headers[headerName]
                                                                        this.setState({
                                                                            addIntegrationParameters: headers,
                                                                            addAnother: KeyValueType.parameter,
                                                                            addIntegrationKey: headerName,
                                                                            addIntegrationValue: value
                                                                        })
                                                                    }}
                                                                >
                                                                    <td>{headerName}</td>
                                                                    <td>{value}</td>
                                                                    <td className="narrow">
                                                                        <Button
                                                                            bsStyle="danger"
                                                                            onClick={(event) => {
                                                                                event.stopPropagation()
                                                                                const headers = this.state.addIntegrationParameters || {}
                                                                                delete headers[headerName]
                                                                                this.setState({ addIntegrationParameters: headers })
                                                                            }}
                                                                        >X
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </StripedTable>
                                            </Col>
                                        </FormGroup>
                                    ) : null
                                }

                                {this.state.addAnother !== undefined ? (
                                    <div>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2}>{(this.state.addAnother === KeyValueType.header) ? "Header name" : "Name"}</Col>
                                            <Col sm={10}>
                                                <FormControl
                                                    type="text"
                                                    name="headername"
                                                    value={this.state.addIntegrationKey || ""}
                                                    placeholder={(this.state.addAnother === KeyValueType.header) ? "Enter header name" : "Enter name"}
                                                    onChange={(event: any) => {
                                                        this.setState({ addIntegrationKey: event.target.value })
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2}>{(this.state.addAnother === KeyValueType.header) ? "Header value" : "Value"}</Col>
                                            <Col sm={10}>
                                                <FormControl
                                                    type="text"
                                                    name="headervalue"
                                                    value={this.state.addIntegrationValue || ""}
                                                    placeholder={(this.state.addAnother === KeyValueType.header) ? "Enter header value" : "Enter value"}
                                                    onChange={(event: any) => {
                                                        this.setState({ addIntegrationValue: event.target.value })
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2}>&nbsp;</Col>
                                            <Col sm={10}>
                                                <Button
                                                    onClick={() => {
                                                        let name = this.state.addIntegrationKey
                                                        const value = this.state.addIntegrationValue
                                                        if (name === undefined || name.length === 0 || value === undefined || value.length === 0) {
                                                            return
                                                        }
                                                        const headers = ((this.state.addAnother === KeyValueType.header) ? this.state.addIntegrationHeaders : this.state.addIntegrationParameters) || {}
                                                        name = _.trim(name, ":")
                                                        headers[name] = value
                                                        if (this.state.addAnother === KeyValueType.header) {
                                                            this.setState({
                                                                addAnother: undefined,
                                                                addIntegrationHeaders: headers,
                                                                addIntegrationKey: undefined,
                                                                addIntegrationValue: undefined
                                                            })
                                                        } else {
                                                            this.setState({
                                                                addAnother: undefined,
                                                                addIntegrationParameters: headers,
                                                                addIntegrationKey: undefined,
                                                                addIntegrationValue: undefined
                                                            })
                                                        }
                                                    }}
                                                >
                                                    Done
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                ) : (
                                        <div>
                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2}>&nbsp;</Col>
                                                <Col sm={10}>
                                                    <Button onClick={() => { this.setState({ addAnother: KeyValueType.header }) }}>Add HTTP header</Button>
                                                </Col>
                                            </FormGroup>
                                            {
                                                allowsParameters(this.props.integrationType) ? (
                                                    <FormGroup>
                                                        <Col componentClass={ControlLabel} sm={2}>&nbsp;</Col>
                                                        <Col sm={10}>
                                                            <Button onClick={() => { this.setState({ addAnother: KeyValueType.parameter }) }}>Add query parameter</Button>
                                                        </Col>
                                                    </FormGroup>
                                                ) : undefined
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        )}

                    </Form>
                    <Button bsStyle="success" onClick={async () => { await this.addIntegration() }} disabled={!this.addIntegrationEnabled()}>{this.state.isNew ? "Add integration" : "Update integration"}</Button>

                </Modal.Body>
            </Modal>
        )
    }
}
