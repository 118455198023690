import * as React from "react"
import { Button, Modal, FormGroup, Col, FormControl, ControlLabel, Table, Form } from "react-bootstrap"
import { LanguagePicker } from "../LanguagePicker"
import { L10nFormControl } from "../L10nFormControl"
import { LanguageCode, L10nString } from "../../helpers/L10n"
import { Dimension, DimensionValue } from "../../models/Product"
import * as _ from "lodash"
import ImageDropControl from "../ImageDropControl"
import { Role } from "../../config/role"

const pushid = require("pushid")
const uuidv4 = require("uuid/v4")

interface DimensionEditProps {
    role: Role,
    productId: string
    editedDimension: Dimension | null
    setLanguage: (language: LanguageCode | null) => void
    currentLanguage: () => LanguageCode | null
    resolveLanguages: () => LanguageCode[]
    cancelDialog: () => any
    publishDialog: (dimension: Dimension) => void
}

interface DimensionEditState {
    dimension: Dimension
    dirty: boolean
}

export class DimensionEdit extends React.Component<DimensionEditProps, DimensionEditState> {

    constructor(props: DimensionEditProps) {
        super(props)

        this.state = {
            dimension: this.props.editedDimension || new Dimension({ id: uuidv4().toUpperCase() as string, values: [], name: "" }),
            dirty: false,
        }
    }

    cancel = () => {
        //TODO: remove uploaded image if needed
        this.props.cancelDialog()
    }

    publish = () => {
        this.props.publishDialog(this.state.dimension)
    }

    resolveLanguages(): LanguageCode[] {
        return this.props.resolveLanguages()
    }

    productId(): string {
        return this.props.productId
    }

    setLanguage = (language: LanguageCode | null) => {
        this.props.setLanguage(language)
    }

    currentLanguage = (): LanguageCode | null => {
        return this.props.currentLanguage()
    }

    onDimensionLocalizationChanged = (name: string, l10n: L10nString | null) => {
        this.editDimension(dimension => {
            if (!l10n || l10n.value === "") {
                // NOTE: None of the dimension L10nString fields are optional
                // so we can't delete them
                dimension[name] = new L10nString("")
            } else {
                dimension[name] = l10n
            }
        })
    }

    setDimensionImage(index: number, url: string | null) {
        this.editDimension(dimension => {
            const dimensionValue = dimension.values[index]
            dimensionValue.image_url = url || undefined

            dimension.values[index] = dimensionValue
        })
    }

    isPublishEnabled = () => {        
        if (this.state.dirty === false) {
            return false
        }
        if (this.state.dimension.name === undefined || _.isEqual(this.state.dimension.name, new L10nString(""))) {
            return false
        }
        if (this.state.dimension.values.length === 0) {
            return false
        }
        for (const value of this.state.dimension.values) {
            if (value.name === undefined || _.isEqual(value.name, new L10nString(""))) {
                return false
            }
        }
        return true
    }

    // Helpers

    editDimension(closure: ((dimension: Dimension) => any | null | undefined)) {
        const dimension = _.cloneDeep(this.state.dimension)

        closure(dimension)

        this.setState({ dimension: dimension, dirty: true })
    }

    // Component

    render() {
        return (
            <Modal show={true} onHide={() => { /* */ }}>
                <Modal.Header>
                    <Modal.Title>
                        <span>Edit Dimension</span>
                        <span
                            style={{
                                float: "right"
                            }}
                        >
                            <LanguagePicker
                                typeName="dimension"
                                resolveLanguages={() => { return this.resolveLanguages() }}
                                initialLanguage={this.currentLanguage()}
                                hideRemove={true}
                                onChange={this.setLanguage}
                            />
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form horizontal={true} onSubmit={(e: any) => e.preventDefault()}>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={1}>Name</Col>
                            <Col sm={11}>
                                <L10nFormControl
                                    l10n={this.state.dimension.name}
                                    language={this.currentLanguage()}
                                    onLocalizationChanged={l10n => { this.onDimensionLocalizationChanged("name", l10n) }}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Col sm={12}>
                                <Table striped={true} bordered={true} condensed={true} style={{ cursor: "pointer" }} hover={true}>
                                    <thead>
                                        <tr>
                                            <th>Value</th>
                                            <th>Color</th>
                                            <th>Image</th>
                                            <th>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.dimension.values.map((value, index) => {
                                                return <tr key={index}>
                                                    <td style={{ "verticalAlign": "middle" }}>
                                                        <L10nFormControl
                                                            l10n={value.name}
                                                            language={this.currentLanguage()}
                                                            onLocalizationChanged={(l10n) => {
                                                                this.editDimension(dimension => {
                                                                    const dimensionValue = dimension.values[index]
                                                                    dimensionValue.name = l10n || new L10nString("")
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                    <td style={{ "verticalAlign": "middle" }}>
                                                        <FormControl
                                                            name="color"
                                                            value={value.color || ""}
                                                            placeholder="Enter a color in the form #RRGGBB"
                                                            onChange={(event: any) => {
                                                                const v = event.target.value

                                                                this.editDimension(dimension => {
                                                                    const dimensionValue = dimension.values[index]

                                                                    if (v === "" || v === "undefined") {
                                                                        delete dimensionValue.color
                                                                    } else {
                                                                        dimensionValue.color = v
                                                                    }
                                                                })
                                                            }}
                                                        /> </td>
                                                    <td style={{ "verticalAlign": "middle" }}>
                                                        <ImageDropControl
                                                            fileName={pushid()}
                                                            filePath={this.props.role.account_id + "/public/products/" + this.productId() + "/images/"}
                                                            imageURL={value.image_url}
                                                            dropTitle="Max size: 2048×2048"
                                                            validateSize={(width: number, height: number) => {
                                                                return width <= 2048 && height <= 2048
                                                            }}
                                                            isPublic={true}
                                                            deleteAction={() => { this.setDimensionImage(index, null) }}
                                                            uploadAction={(url) => { this.setDimensionImage(index, url) }}
                                                        />
                                                    </td>

                                                    <td style={{ "verticalAlign": "middle", textAlign: "center", "width": "1%" }}>
                                                        <Button
                                                            bsStyle="danger"
                                                            onClick={(event: any) => {
                                                                event.stopPropagation()

                                                                this.editDimension(dimension => {
                                                                    dimension.values.splice(index, 1)
                                                                })
                                                            }}
                                                        >
                                                            X
                                                        </Button>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <Button
                                    bsStyle="success"
                                    onClick={() => {
                                        this.editDimension(dimension => {
                                            const values: DimensionValue[] = dimension.values || []
                                            values.push(new DimensionValue({ id: uuidv4().toUpperCase(), name: "" }))
                                            dimension.values = values
                                        })
                                    }}
                                >
                                    Add Value
                                </Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={this.cancel}>Cancel</Button>
                    <Button onClick={this.publish} disabled={!this.isPublishEnabled()}>Done</Button>
                </Modal.Footer>

            </Modal>
        )
    }
}