import * as React from "react"
import { firestore, ref } from "../../config/constants"
import { Panel, Button, Modal } from "react-bootstrap"
import { withRouter, RouteComponentProps } from "react-router"
import { PageState } from "../PageState"
import { Role } from "../../config/role"
import { StripedTable } from "../StripedTable"
import _ from "lodash"

interface ShopListProps extends RouteComponentProps<any> {
    role: Role
    deselectShop(key: string): void
    selectShop(key: string, accountId: string, is_shop: boolean): void
}

interface ShopListState {
    loaded: boolean
    showAlert: boolean
    shops: any[]
    stockLocations: any[]
    currentShop: string | null
    showStock: boolean
    ecomStockLocation?: string
}

class ShopList extends React.Component<ShopListProps, ShopListState> {
    shopObservation: any
    stockLocationObservation: any
    stockModuleObservation: any
    ecomObservation: any

    constructor(props: ShopListProps) {
        super(props)
        this.state = {
            currentShop: null,
            shops: [],
            stockLocations: [],
            showAlert: false,
            loaded: false,
            showStock: false
        }
    }

    newShop = () => {
        const path = `/shop/new/edit`
        this.props.history.push(path)
    }

    newStockLocation = () => {
        const path = `/stock_location/new/edit`
        this.props.history.push(path)
    }

    showShop = (key: string, isShop: boolean) => {
        this.props.selectShop(key, this.props.role.account_id, isShop)
    }

    async deactivateCurrentLocation() {
        const account = this.props.role.account_id
        const key = this.state.currentShop

        if (key !== null && key !== undefined) {
            this.props.deselectShop(key)
            const locationSnapshot = await firestore.doc(`accounts/${account}/stock_locations/${key}`).get()
            if (locationSnapshot.data()?.type === "shop") {
                await ref()
                    .child(`/v1/accounts/${account}/shop_index/${key}/deactivated`)
                    .set(true)
                await firestore
                    .doc(`accounts/${account}/shops/${key}`)
                    .update({ deactivated: true })
            }
            await ref()
                .child(`/v1/accounts/${account}/stock_location_index/${key}/deactivated`)
                .set(true)
            await firestore
                .doc(`accounts/${account}/stock_locations/${key}`)
                .update({ deactivated: true })
        }
        this.setState({ showAlert: false })
    }

    shopsRef() {
        const account = this.props.role.account_id
        const shopsRef = ref().child(`v1/accounts/${account}/shop_index`)
        return shopsRef
    }

    stockLocationsRef() {
        const account = this.props.role.account_id
        const shopsRef = ref().child(`v1/accounts/${account}/stock_location_index`)
        return shopsRef
    }

    stockModuleEnabledRef() {
        const account = this.props.role.account_id
        const stockModuleRef = ref().child(`v1/accounts/${account}/configuration/modules/stock/enabled`)
        return stockModuleRef
    }

    ecomModuleRef() {
        const account = this.props.role.account_id
        const ecomModuleRef = ref().child(`v1/accounts/${account}/configuration/ecom_integrations`)
        return ecomModuleRef
    }

    componentDidMount() {
        this.setState({ loaded: false })

        this.stockModuleObservation = this.stockModuleEnabledRef()
            .on("value", snapshot => {
                if (snapshot && snapshot.exists() && snapshot.val() === true) {
                    this.setState({ showStock: true })
                }
            })

        this.ecomObservation = this.ecomModuleRef()
            .on("value", snapshot => {
                if (!snapshot || !snapshot.exists()) {
                    this.setState({ ecomStockLocation: undefined })
                    return
                }
                const integrationDict = snapshot.val()
                const keys = Object.keys(integrationDict)
                if (keys.length === 1) {
                    const currentIntegration = integrationDict[keys[0]]
                    this.setState({ ecomStockLocation: currentIntegration.stock_location })
                }
            })

        this.stockLocationObservation = this.stockLocationsRef()
            .on("value", snapshot => {
                if (!snapshot || !snapshot.exists()) { return }
                const locationsDict = snapshot.val()
                const keys = Object.keys(locationsDict)
                const locations = keys
                    .map(v => {
                        const location = locationsDict[v]
                        location.key = v
                        return location
                    })
                    .filter(v => { return v.deactivated !== true })
                    .sort((lhs: any, rhs: any) => {
                        const lhsName: string = lhs.name || ""
                        const rhsName: string = rhs.name || ""
                        return lhsName.localeCompare(rhsName)
                    })

                this.setState({ stockLocations: locations, loaded: true })
            })

        this.shopObservation = this.shopsRef()
            .on("value", snapshot => {
                if (!snapshot || !snapshot.exists()) { return }
                const shopsDict = snapshot.val()
                const keys = Object.keys(shopsDict)
                const shops = keys
                    .map(v => {
                        const shop = shopsDict[v]
                        shop.key = v
                        return shop
                    })
                    .filter(v => { return v.deactivated !== true })
                    .sort((lhs: any, rhs: any) => {
                        const lhsName: string = lhs.name || ""
                        const rhsName: string = rhs.name || ""
                        return lhsName.localeCompare(rhsName)
                    })

                this.setState({ shops: shops, loaded: true })
            })
    }

    componentWillUnmount() {
        this.shopsRef().off("value", this.shopObservation)
        this.stockLocationsRef().off("value", this.stockLocationObservation)
        this.stockModuleEnabledRef().off("value", this.stockModuleObservation)
        this.ecomModuleRef().off("value", this.ecomObservation)
    }

    otherLocations(): any[] {
        const allLocations = _.cloneDeep(this.state.stockLocations)
        for (const shop of this.state.shops) {
            _.remove(allLocations, location => { return location.key === shop.key })
        }
        return allLocations
    }

    showOtherLocations(): boolean {
        return this.state.showStock
    }

    async unsetEcomIntegration() {
        await this.ecomModuleRef().remove()
    }

    async setEcomIntegration(stockLocation: string) {
        await this.ecomModuleRef().set({
            ecom: {
                id: "ecom",
                is_local: true,
                name: "Online",
                stock_location: stockLocation
            }
        })
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="shops">
                <Panel key="a">
                    <Panel.Body>
                        <Button onClick={this.newShop}>New shop</Button>
                    </Panel.Body>
                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.shops.map(listValue => {
                                return (
                                    <tr key={listValue.key} onClick={() => this.showShop(listValue.key, true)}>
                                        <td>{listValue.name}</td>
                                        <td className="narrow">
                                            <Button bsStyle="danger" onClick={(event) => { this.setState({ showAlert: true, currentShop: listValue.key }); event.stopPropagation() }}>X</Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </StripedTable>

                    <Panel.Body>
                        <Button onClick={this.newShop}>New shop</Button>
                    </Panel.Body>
                </Panel>
                {
                    this.showOtherLocations() ? (
                        <Panel key="b">
                            <Panel.Body>
                                <Button onClick={this.newStockLocation}>New stock location</Button>
                            </Panel.Body>
                            <StripedTable>
                                <thead>
                                    <tr>
                                        <th>Other stock locations</th>
                                        <th>Set as default</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.otherLocations().map(listValue => {
                                        return (
                                            <tr key={listValue.key} onClick={() => this.showShop(listValue.key, false)}>
                                                <td>{listValue.name ?? listValue.key}</td>
                                                <td className="narrow">
                                                    {
                                                        this.state.ecomStockLocation === listValue.key ? (
                                                            <Button bsStyle="danger" onClick={(event) => { this.unsetEcomIntegration(); event.stopPropagation() }}>Unset</Button>
                                                        ) :
                                                            (
                                                                <Button bsStyle="info" onClick={(event) => { this.setEcomIntegration(listValue.key); event.stopPropagation() }}>Set</Button>
                                                            )
                                                    }
                                                </td>
                                                <td className="narrow">
                                                    <Button bsStyle="danger" onClick={(event) => { this.setState({ showAlert: true, currentShop: listValue.key }); event.stopPropagation() }}>X</Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </StripedTable>
                            <Panel.Body>
                                <Button onClick={this.newStockLocation}>New stock location</Button>
                            </Panel.Body>
                        </Panel>
                    ) : null
                }

                {
                    this.state.showAlert ? (
                        <Modal.Dialog key="b">
                            <Modal.Header>
                                <Modal.Title>Remove location</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to remove this location?</Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => { this.setState({ showAlert: false }) }}>Cancel</Button>
                                <Button bsStyle="danger" onClick={async () => { await this.deactivateCurrentLocation() }}>Yes</Button>
                            </Modal.Footer>
                        </Modal.Dialog>
                    ) : null
                }
            </PageState>
        )
    }
}

export default withRouter(ShopList)
