//
//  GroupEdit.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 13/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { ref } from "../../config/constants"
import { Button, Col, ControlLabel, Form, FormGroup, Panel, Alert, Row, FormControl } from "react-bootstrap"
import { withRouter, RouteComponentProps } from "react-router"
import { PageState } from "../PageState"
import { ProductGroup } from "../../models/Product"
import { L10nFormControl } from "../L10nFormControl"
import { LanguageCode, L10nString } from "../../helpers/L10n"
import { Role } from "../../config/role"
import { LanguagePicker } from "../LanguagePicker"
import { cloneDeep } from "lodash"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { publish } from "../../helpers/ModelPublisher"

interface GroupEditProps extends RouteComponentProps<any> {
    currentLanguage?: LanguageCode
    role: Role
}

interface GroupEditState {
    group: ProductGroup
    identifier: string
    loaded: boolean
    dirty: boolean
    publishing: boolean
    currentLanguage: LanguageCode | null
    error: string | null
}

class GroupEdit extends React.Component<GroupEditProps, GroupEditState> {

    // Properties

    initialState: GroupEditState = {
        group: new ProductGroup({
            group: "",
            name: ""
        }),
        identifier: "",
        currentLanguage: this.props.currentLanguage || null,
        loaded: false,
        dirty: false,
        publishing: false,
        error: null
    }

    // Component

    constructor(props: GroupEditProps) {
        super(props)

        this.state = this.initialState
    }

    componentWillUnmount() {
        const key = this.groupKey()
        this.groupsRef().child(key).off()
    }

    async componentDidMount() {
        this.setState({ loaded: false })

        if (!this.isNewGroup()) {
            const snapshot = await this.groupsRef()
                .child(this.groupKey())
                .once("value")

            const group = new ProductGroup(snapshot.val())
            this.setState({ group: group, identifier: group.group, loaded: true })
        } else {
            this.setState({ loaded: true })
        }
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="group"
            >
                <Form horizontal={true}>
                    <FormGroup>
                        <Col smOffset={8} sm={4} className="text-right">
                            <LanguagePicker
                                typeName="group"
                                initialLanguage={this.state.currentLanguage}
                                resolveLanguages={this.resolveLanguages}
                                onChange={this.setLanguage}
                                onRemove={this.removeLanguage}
                            />
                        </Col>
                    </FormGroup>

                    <Panel>
                        <Panel.Heading>{this.isNewGroup() ? "Create new group" : `Edit group '${this.state.group.name.localized(this.state.currentLanguage)}'`}</Panel.Heading>
                        <Panel.Body>
                            <span key="a">
                                <FormGroup>
                                    <Row>
                                        <Col componentClass={ControlLabel} sm={2}>Name</Col>
                                        <Col sm={10}>
                                            <L10nFormControl
                                                l10n={this.state.group.name}
                                                placeholder="Enter localized name"
                                                language={this.state.currentLanguage}
                                                onLocalizationChanged={l10n => { this.handleInputChange(l10n) }}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        !this.isNewGroup()
                                            ? (
                                                <Row>
                                                    <Col componentClass={ControlLabel} sm={2}>Identifier</Col>
                                                    <Col sm={10}>
                                                        <FormControl.Static>{this.state.group.group}</FormControl.Static>
                                                    </Col>
                                                </Row>
                                            ) :
                                            null
                                    }
                                </FormGroup>
                            </span>
                            <ValidatingIdEntryControl
                                collectionRef={this.groupsRef()}
                                isNew={this.isNewGroup()}
                                typeName="group"
                                identifierSource={this.state.group.name.localized(this.state.currentLanguage)}
                                existingIdentifier={this.state.identifier}
                                handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                            />
                        </Panel.Body>
                        <Panel.Footer><Button onClick={() => this.publish()} disabled={!this.isPublishEnabled()}>Publish</Button></Panel.Footer>
                    </Panel>
                </Form>
                {this.state.error ? (
                    <Alert bsStyle="danger">
                        <strong>Error publishing group:</strong> {this.state.error}
                    </Alert>
                ) : []}
            </PageState>
        )
    }

    // Product group

    async publish() {
        const json = this.state.group.json()
        this.setState({ publishing: true })

        try {
            await publish(json, "group", this.state.identifier, this.isNewGroup(), this.groupsRef())
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.pop()
    }

    // Language

    resolveLanguages = (): LanguageCode[] => {
        return this.state.group.name.localizations()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })
    }

    removeLanguage = (language: LanguageCode) => {
        const group = cloneDeep(this.state.group)
        group.name.removeLocalization(language)
        this.setState({ group: group, dirty: true, currentLanguage: null })
    }

    // Handlers

    handleInputChange = (l10n: L10nString | null) => {
        const group = cloneDeep(this.state.group)
        group.name = l10n || new L10nString("")
        this.setState({ group: group, dirty: true })
    }

    handleIdChange(identifier: string) {
        this.setState({ dirty: true, error: null, identifier: identifier })
    }

    // Helpers

    pop() {
        const path = "/groups"
        this.props.history.push(path)
    }

    groupKey() {
        return this.props.match.params.groupKey
    }

    isNewGroup() {
        return this.groupKey() === "new"
    }

    isPublishEnabled() {
        if (!this.state.dirty) {
            return false
        }
        if (this.state.group.name.hasEmptyLocalizations()) {
            return false
        }

        return true
    }

    groupsRef() {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/product_groups")
    }
}

export default withRouter(GroupEdit)
