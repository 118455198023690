import React from "react"
import { Form } from "react-bootstrap"
import { AttributeObserver } from "../../../../helpers/attributeObserver";
import { ProductObserver } from "../../../../helpers/productObserver";
import { TagObserver } from "../../../../helpers/tagObserver";
import { Market } from "../../../../models/MarketModels";
import { CustomerCondition, CustomerConditionSelector } from "../CustomerConditionSelector";
import { DiscountSelector, DiscountType } from "../DiscountSelector";
import { BundleStair } from "../BundleStair";
import { BundleRowData } from "../BundleStairStep";
import { Description } from "./BundleFormDescription";
import * as _ from "lodash"
import { DateComponents, RuleModel } from "../../../../models/RuleModels";
import { L10nString, LanguageCode } from "../../../../helpers/L10n";
import { BundleTemplate } from "./Model";
import { SharedPropertiesSelector } from "../SharedProperties";
import { AdvancedPropertiesSelector } from "../AdvancedProperties";
import { MarketAmount } from "../../../../models/MarketAmount";

export interface BundleFormProps {
    market: Market | null
    markets: string[]
    currentLanguage: () => LanguageCode | null
    productObserver: ProductObserver
    tagObserver: TagObserver
    attributeObserver: AttributeObserver
    customerAttributeObserver: AttributeObserver
    template: BundleTemplate
    onTemplateChanged: (template: RuleModel) => void
}

export function BundleForm(props: BundleFormProps) {

    function setDisplayName(name?: L10nString) {
        if (_.isEqual(props.template.display_name, name)) {
            return
        }
        const clone = _.clone(props.template)
        clone.display_name = name
        props.onTemplateChanged(clone)
    }

    function setDiscountType(type: DiscountType, percentage?: number, amount?: MarketAmount) {
        if (props.template.discountType === type && percentage === props.template.discountPercentage && amount === props.template.discountAmount) {
            return
        }
        const clone = _.clone(props.template)
        clone.discountType = type
        clone.discountPercentage = percentage
        clone.discountAmount = amount
        props.onTemplateChanged(clone)
    }

    function setSteps(steps: BundleRowData[]) {
        if (_.isEqual(props.template.steps, steps)) {
            return
        }
        const clone = _.clone(props.template)
        clone.steps = steps
        props.onTemplateChanged(clone)
    }

    function setCustomerCondition(condition: CustomerCondition) {
        if (_.isEqual(props.template.customerCondition, condition)) {
            return
        }
        const clone = _.clone(props.template)
        clone.customerCondition = condition
        props.onTemplateChanged(clone)
    }

    function setPriority(priority: number | undefined) {
        if (props.template.priority === priority) {
            return
        }
        const clone = _.clone(props.template)
        clone.priority = priority
        props.onTemplateChanged(clone)
    }

    function setStartDate(date?: DateComponents) {
        if (_.isEqual(props.template.start_date, date)) {
            return
        }
        const clone = _.clone(props.template)
        clone.start_date = date
        props.onTemplateChanged(clone)
    }

    function setEndDate(date?: DateComponents) {
        if (_.isEqual(props.template.end_date, date)) {
            return
        }
        const clone = _.clone(props.template)
        clone.end_date = date
        props.onTemplateChanged(clone)
    }

    function setContinueEvaluation(continueEvaluation?: boolean) {
        if (props.template.continue_evaluation === continueEvaluation) {
            return
        }
        const clone = _.clone(props.template)
        clone.continue_evaluation = continueEvaluation
        props.onTemplateChanged(clone)
    }

    return <Form horizontal={true} onSubmit={e => e.preventDefault()}>
        <SharedPropertiesSelector validation={props.template.validateShared()} currentLanguage={props.currentLanguage} displayName={props.template.display_name} priority={props.template.priority} updateSharedProps={(displayName, priority) => { setDisplayName(displayName); setPriority(priority) }} />
        <DiscountSelector markets={props.markets} validation={props.template.validateDiscount()} showDiscountValue={true} market={props.market ?? undefined} discountAmount={props.template.discountAmount} discountPercentage={props.template.discountPercentage} type={props.template.discountType} typeChanged={(type, percentage, amount) => { setDiscountType(type, percentage, amount) }} />
        <BundleStair productObserver={props.productObserver} tagsObserver={props.tagObserver} attributesObserver={props.attributeObserver} validation={props.template.validateSteps()} market={props.market} rows={props.template.steps} rowsChanged={(rows) => { setSteps(rows) }} />
        <CustomerConditionSelector validation={props.template.validateCustomerCondition()} attributesObserver={props.customerAttributeObserver} condition={props.template.customerCondition} conditionChanged={(condition) => { setCustomerCondition(condition) }} />
        <AdvancedPropertiesSelector continueEvaluation={props.template.continue_evaluation} startDate={props.template.start_date} endDate={props.template.end_date} updateAdvancedProps={(startDate, endDate, continueEvaluation) => { setStartDate(startDate); setEndDate(endDate); setContinueEvaluation(continueEvaluation) }} />
        <Description validation={props.template.valid([])} formProps={props} market={props.market} />
    </Form>
}


