import React, { useState } from "react";
import { Checkbox, Col, ControlLabel, FormGroup, Panel } from "react-bootstrap";
import * as _ from "lodash";
import { DateComponents } from "../../../models/RuleModels";
import moment from "moment";
import ReactDates, { DateRangePicker } from "react-dates";
import { momentFromDateComponents } from "./RuleTemplateForm";

interface AdvancedPropertiesProps {
    startDate?: DateComponents;
    endDate?: DateComponents;
    continueEvaluation?: boolean;
    updateAdvancedProps: (startDate?: DateComponents, endDate?: DateComponents, continueEvaluation?: boolean) => void;
}
export function AdvancedPropertiesSelector(props: AdvancedPropertiesProps) {
    const [focusedInput, setFocusedInput] = useState<ReactDates.FocusedInputShape | null>(null);

    return <Panel bsStyle="primary" defaultExpanded={false}>
        <Panel.Heading>
            <Panel.Title toggle={true}>More options</Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
            <Panel.Body>
                <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>Optional time limit</Col>
                    <Col sm={10}>
                        <DateRangePicker
                            startDateId="startDate"
                            endDateId="endDate"
                            minimumNights={0}
                            isOutsideRange={() => { return false; }}
                            startDate={props.startDate ? momentFromDateComponents(props.startDate) : null} // momentPropTypes.momentObj or null,
                            endDate={props.endDate ? momentFromDateComponents(props.endDate) : null} // momentPropTypes.momentObj or null,
                            onDatesChange={(selection: any) => {
                                const startDate: moment.Moment = selection.startDate;
                                const endDate: moment.Moment = selection.endDate;
                                let startComponents: DateComponents | undefined;
                                let endComponents: DateComponents | undefined;
                                if (!_.isNil(startDate)) {
                                    startComponents = {
                                        year: startDate.year(),
                                        month: startDate.month() + 1,
                                        day: startDate.date()
                                    };
                                }
                                if (!_.isNil(endDate)) {
                                    endComponents = {
                                        year: endDate.year(),
                                        month: endDate.month() + 1,
                                        day: endDate.date()
                                    };
                                }
                                props.updateAdvancedProps(startComponents, endComponents, props.continueEvaluation);
                            }} // PropTypes.func.isRequired,
                            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={input => setFocusedInput(input)} // PropTypes.func.isRequired,
                        />
                    </Col>
                </FormGroup>

                <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>Continue evaluation</Col>
                    <Col sm={10}>
                        <Checkbox
                            checked={(props.continueEvaluation ?? false)}
                            onChange={(e: any) => props.updateAdvancedProps(props.startDate, props.endDate, e.target.checked)}
                        >
                            Continue evaluation of other rules after this one applies? <br />
                            Note that this allows multiple discounts on the same items. <br />
                            Consider carefully if you really want this.
                        </Checkbox>
                    </Col>
                </FormGroup>

            </Panel.Body>
        </Panel.Collapse>
    </Panel>;
}
