import CopyToClipboard from "react-copy-to-clipboard"
import * as React from "react"
import {
    Alert,
    Button,
    FormControl,
    FormGroup,
    Glyphicon,
    InputGroup,
    Panel
} from "react-bootstrap"
import { ChannelSelector } from "../../../ChannelSelector"
import { MarketSelector } from "../../../MarketSelector"
import { shopifyAPIVersion, ShopifyConfiguration } from "./Models"
import { ToggleButton } from "../../../ToggleButton"
import _ from "lodash"
import { ref } from "../../../../config/constants"

interface ProductChangesPanelProps {
    accountId: string
    channels: _.Dictionary<boolean>
    markets: _.Dictionary<boolean>
    configuration: ShopifyConfiguration
    enabled: boolean
    openShopifyNotifications: () => void
    updateDatabaseUpdates: (updates: _.Dictionary<any>) => void
    updateEnabled: (enabled: boolean) => void
    updateChannels: (channels: string[]) => void
    updateMarkets: (markets: string[]) => void
}

interface ProductChangesPanelState {
    copied: boolean
    serviceUserUID?: string
}

export class ProductChangesPanel extends React.Component<ProductChangesPanelProps, ProductChangesPanelState> {

    constructor(props: ProductChangesPanelProps) {
        super(props)
        this.state = {
            copied: false
        }
    }

    async componentDidMount() {
        await this.load()
    }

    async load() {
        const accountRef = ref().child(`v1/accounts/${this.props.accountId}`)
        const serviceUserUID = (await accountRef.child("api_keys").child("service_user_uid").once("value")).val()
        this.setState({ serviceUserUID: serviceUserUID })
    }

    shopifyHelpText = (
        <div key="help_text">
            Follow these steps to configure the integration that synchronizes product changes from Shopify to Ka-ching:
            <ol>
                {
                    Object.keys(this.props.channels).length > 1 ? (
                        <li>
                            First use the channel selector below to select the channels you want the products to be available in.
                        </li>
                    ) : null
                }
                {
                    Object.keys(this.props.markets).length > 1 ? (
                        <li>
                            Use the market selector below to select the markets you want the products to be available in.
                        </li>
                    ) : null
                }
                <li>
                    Click the url to copy it to the clipboard.
                </li>
                <li>
                    Click the 'Open Shopify Notifications' button above to jump to this page. Scroll all the way to the bottom of the page.
                </li>
                <li>
                    Click 'Create Webhook' and select the 'Product creation' event. Choose "{shopifyAPIVersion}" as the "Webhook API version". Leave the format as 'JSON'. Select the URL field and paste the URL that you copied in step 2.
                </li>
                <li>
                    Repeat the previous step for 'Product deletion' and 'Product update' events.
                </li>
                <li>
                    Click below to enable the integration in Ka-ching.
                </li>
                {
                    Object.keys(this.props.channels).length > 1 ? (
                        <li>
                            IMPORTANT: If you change the channel selection you need to update the URLs on the Shopify Notifications page.
                        </li>
                    ) : null
                }
                {
                    Object.keys(this.props.markets).length > 1 ? (
                        <li>
                            IMPORTANT: If you change the markets selection you need to update the URLs on the Shopify Notifications page.
                        </li>
                    ) : null
                }
                <li>
                    IMPORTANT: If you disable this integation remember to remove the newly created webhooks in Shopify's Notification settings.
                </li>
            </ol>
        </div>
    )

    shopifyImportURL(): string {
        if (this.props.configuration.apiKey) {
            const channelsParameter = Object.keys(this.props.configuration.channels).join(",")
            const marketsParameter = Object.keys(this.props.configuration.markets).join(",")
            return `${process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE}/imports/product/?apikey=${this.props.configuration.apiKey}&integration=shopify&account=${this.props.accountId}&transform=shopify&channels=${channelsParameter}&markets=${marketsParameter}`
        }
        return ""
    }

    toggleEnabled = () => {
        const enabled = !this.props.enabled
        this.props.updateEnabled(enabled)

        const uid = this.state.serviceUserUID
        if (_.isNil(uid)) {
            console.error("No service user defined on system")
            return
        }

        // update active flag on api_keys based on enabled status
        if (enabled && uid) {
            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/api_keys/import_integrations/products/shopify/${this.props.configuration.apiKey}/metadata`] = { active: true, uid: uid }
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/import_integrations/products/shopify`] = { 
                name: "Shopify Product Import",
                shopify_id: this.props.configuration.shopifyShopName,
                access_token: this.props.configuration.shopifyAccessToken ?? null,
                api_key: this.props.configuration.shopifyAPIKey ?? null,
                password: this.props.configuration.shopifyPassword ?? null
            }
            this.props.updateDatabaseUpdates(dbUpdates)
        }
        if (!enabled && uid) {
            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/api_keys/import_integrations/products/shopify/${this.props.configuration.apiKey}/metadata`] = { active: false, uid: uid }
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/import_integrations/products/shopify`] = null
            this.props.updateDatabaseUpdates(dbUpdates)
        }
    }

    // Component

    render() {
        return (
            <Panel key="product_changes" defaultExpanded={false}>
                <Panel.Heading>
                    <Panel.Title toggle={true}>Product changes</Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <Button onClick={() => { this.props.openShopifyNotifications() }}>Open Shopify Notifications <Glyphicon glyph="new-window" /> </Button>
                        <br /> <br />
                        {this.shopifyHelpText}
                        <FormGroup>
                            <ChannelSelector
                                selectedChannels={Object.keys(this.props.channels)}
                                onChange={channels => { this.props.updateChannels(channels) }}
                            />
                            <MarketSelector
                                typeName="Shopify integration"
                                showDeleteWarning={false}
                                selectedMarkets={Object.keys(this.props.markets)}
                                addMarket={market => {
                                    const markets = _.cloneDeep(this.props.markets)
                                    markets[market] = true
                                    this.props.updateMarkets(Object.keys(markets))
                                }}
                                removeMarket={market => {
                                    const markets = _.cloneDeep(this.props.markets)
                                    delete markets[market]
                                    this.props.updateMarkets(Object.keys(markets))
                                }}
                            />
                        </FormGroup>
                        <br /> <br />
                        <CopyToClipboard text={this.shopifyImportURL()} onCopy={() => this.setState({ copied: true })}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroup.Addon>Webhook URL</InputGroup.Addon>
                                    <FormControl type="text" readOnly={true} value={this.shopifyImportURL()} />
                                    <InputGroup.Addon>
                                        <Glyphicon glyph="copy" />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </CopyToClipboard>
                        {
                            this.state.copied
                                ?
                                <Alert bsStyle="success"> Integration URL copied to clipboard. Now go to Shopify and configure your web hooks.</Alert>
                                :
                                null
                        }
                        <ToggleButton isEnabled={this.props.enabled} performToggle={() => { this.toggleEnabled() }} />
                        <br />
                        <br />
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        )
    }
}