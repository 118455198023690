import * as _ from "lodash"
import * as React from "react"
import * as rp from "request-promise"
import {
    Alert,
    Button,
    FormControl,
    FormGroup,
    Panel,
    ToggleButtonGroup,
    ToggleButton,
    Form,
    Col,
    ControlLabel
} from "react-bootstrap"
import { ConfirmDeleteButton } from "../../ConfirmDeleteButton"
import { LanguageCode } from "../../../helpers/L10n"
import { MarketAmount } from "../../../models/MarketAmount"
import {
    ModalPicker,
    ModalPickerElement
} from "../../ModalPicker"
import { PageState } from "../../PageState"
import {
    Product,
    Variant
} from "../../../models/Product"
import { ProductObserver } from "../../../helpers/productObserver"
import { Role } from "../../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom"
import { StripedTable } from "../../StripedTable"
import { v4 as uuid } from "uuid"
import { ref } from "../../../config/constants"

class ProductElement implements ModalPickerElement {
    id: string
    imageUrl?: string
    name: string
    price: MarketAmount
    productId: string
    variantId?: string

    constructor(product: Product, variant?: Variant) {
        this.productId = product.id
        if (_.isNil(variant)) {
            this.id = product.id
            this.imageUrl = product.image_url
            this.name = product.localizedName(LanguageCode.da) || ""
            this.price = product.sale_price || product.retail_price || new MarketAmount(100)
        } else {
            this.id = product.id + variant.id
            this.imageUrl = variant.image_url || product.image_url
            this.price = variant.sale_price || variant.retail_price || product.sale_price || product.retail_price || new MarketAmount(100)
            this.variantId = variant.id

            let variantName = product.localizedName(LanguageCode.da) || ""
            if (variantName.length > 0) {
                variantName += ", "
            }
            variantName += variant.localizedName(LanguageCode.da) || ""
            variantName += product.localizedDimensionValues(LanguageCode.da, variant) ? ", " + product.localizedDimensionValues(LanguageCode.da, variant) || "" : ""
            this.name = variantName
        }
    }
}

class Line {
    imageUrl?: string
    key: string
    name: string
    price: number
    productId: string
    quantity?: number
    variantId?: string

    constructor(imageUrl: string | undefined, name: string, price: number, productId: string, quantity: number, variantId?: string) {
        this.imageUrl = imageUrl
        this.key = uuid()
        this.name = name
        this.price = price
        this.productId = productId
        this.quantity = quantity
        this.variantId = variantId
    }

    toJSON(): any {
        const result: any = {
            name: this.name,
            price: this.price,
            product_identifier: this.productId,
            quantity: this.quantity ?? 1
        }
        if (!_.isNil(this.variantId)) {
            result.variant_identifier = this.variantId
        }
        if (!_.isNil(this.imageUrl)) {
            result.image_url = this.imageUrl
        }
        return result
    }
}

interface ReserveAndCollectDemoProps extends RouteComponentProps<any> {
    role: Role
    shop: string
}

interface ReserveAndCollectDemoState {
    apiKey?: string
    currency?: string
    errorMessage?: string
    integration?: string
    isPickerVisible: boolean
    lines: Line[]
    market?: string
    productsLoaded: boolean
    publishing: boolean
    successMessage?: string
    customer_identifier?: string
    customer_name?: string
    customer_email?: string
    customer_phone?: string
    customer_address_street?: string
    customer_address_zip?: string
    customer_address_city?: string
    mode: "click_and_collect" | "reserve_and_collect" | "ship_from_store"
}

class ReserveAndCollectDemo extends React.Component<ReserveAndCollectDemoProps, ReserveAndCollectDemoState> {

    // Properties

    productObserver: ProductObserver

    // Component

    constructor(props: ReserveAndCollectDemoProps) {
        super(props)

        this.state = {
            isPickerVisible: false,
            lines: [],
            productsLoaded: false,
            publishing: false,
            mode: "reserve_and_collect"
        }

        this.productObserver = new ProductObserver(this.props.role.account_id)
        this.productObserver.productsChangedCallback = () => {
            this.setState({ productsLoaded: true })
        }
    }

    // Component

    async componentDidMount() {
        this.productObserver.start()
        await this.loadMarketDetails()
        await this.loadApiKey()
    }

    componentWillUnmount() {
        this.productObserver.stop()
    }

    setMode(input: any) {
        this.setState({ mode: input })
    }

    updateValue(e: any) {
        const newValue = e.target.value
        const name = e.target.name
        const update: any = {}
        if (!_.isNil(newValue)) {
            update[name] = newValue
        }
        this.setState(update)
    }

    render() {
        const isLoading = !this.state.productsLoaded || this.state.market === undefined || this.state.currency === undefined || this.state.apiKey === undefined || this.state.integration === undefined
        const showPicker = () => { this.setState({ isPickerVisible: true }) }
        const cancelPicker = () => { this.setState({ isPickerVisible: false }) }

        if ((!_.isNil(this.state.apiKey) && this.state.apiKey.length === 0) || (!_.isNil(this.state.integration) && this.state.integration.length === 0)) {
            return <Alert bsStyle="warning">Please create an import integration for external orders and try again</Alert>
        }

        return (
            <PageState loading={isLoading} publishing={this.state.publishing} typeName="reservation data">
                {
                    this.state.isPickerVisible
                        ? (
                            <ModalPicker
                                elements={this.elementsForPicker()}
                                onCancel={cancelPicker}
                                onSelect={(element) => {
                                    const productElement: any = element
                                    this.pickerSelectedElement(productElement)
                                    cancelPicker()
                                }}
                                title="New line"
                            />
                        ) : null
                }
                <Panel>
                    <Panel.Heading>
                        <ToggleButtonGroup type="radio" name="options" value={this.state.mode} onChange={(value) => { this.setMode(value) }}>
                            <ToggleButton value="reserve_and_collect">Reserve &amp; Collect</ToggleButton>
                            <ToggleButton value="click_and_collect">Click &amp; Collect</ToggleButton>
                            <ToggleButton value="ship_from_store">Ship from Store</ToggleButton>
                        </ToggleButtonGroup>
                    </Panel.Heading>
                    <Panel.Body>
                        <h4>Customer info</h4>
                        <Form>
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Customer id</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="customer_identifier"
                                        value={this.state.customer_identifier ?? ""}
                                        placeholder="Customer id"
                                        onChange={(e: any) => {
                                            this.updateValue(e)
                                        }}
                                        autoComplete="off"
                                    />
                                </Col>
                                <Col componentClass={ControlLabel} sm={2}>Name</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="customer_name"
                                        value={this.state.customer_name ?? ""}
                                        placeholder="Name"
                                        onChange={(e: any) => {
                                            this.updateValue(e)
                                        }}
                                        autoComplete="off"
                                    />
                                </Col>
                                <Col componentClass={ControlLabel} sm={2}>Email</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="customer_email"
                                        value={this.state.customer_email ?? ""}
                                        placeholder="Email"
                                        onChange={(e: any) => {
                                            this.updateValue(e)
                                        }}
                                        autoComplete="off"
                                    />
                                </Col>
                                <Col componentClass={ControlLabel} sm={2}>Phone</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="customer_phone"
                                        value={this.state.customer_phone ?? ""}
                                        placeholder="Phone"
                                        onChange={(e: any) => {
                                            this.updateValue(e)
                                        }}
                                        autoComplete="off"
                                    />
                                </Col>
                            </FormGroup>
                            {
                                this.state.mode === "ship_from_store" ? (
                                    <div>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2}>Address</Col>
                                            <Col sm={10}>
                                                <FormControl
                                                    type="text"
                                                    name="customer_address_street"
                                                    value={this.state.customer_address_street ?? ""}
                                                    placeholder="Street"
                                                    onChange={(e: any) => {
                                                        this.updateValue(e)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2}>Zip code</Col>
                                            <Col sm={10}>
                                                < FormControl
                                                    type="text"
                                                    name="customer_address_zip"
                                                    value={this.state.customer_address_zip ?? ""}
                                                    placeholder="Zip code"
                                                    onChange={(e: any) => {
                                                        this.updateValue(e)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                            <Col componentClass={ControlLabel} sm={2}>City</Col>
                                            <Col sm={10}>
                                                <FormControl
                                                    type="text"
                                                    name="customer_address_city"
                                                    value={this.state.customer_address_city ?? ""}
                                                    placeholder="City"
                                                    onChange={(e: any) => {
                                                        this.updateValue(e)
                                                    }}
                                                    autoComplete="off"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </div>
                                ) : null
                            }
                        </Form>
                        <h4>Order lines</h4>
                        <StripedTable>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th className="narrow">Quantity</th>
                                    <th className="narrow">Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.lines.map(line => {
                                    return (
                                        <tr
                                            key={line.key}
                                        >
                                            <td>{line.name}</td>
                                            <td className="narrow">
                                                <FormGroup>
                                                    <FormControl
                                                        type="number"
                                                        name="quantity"
                                                        value={line.quantity ?? ""}
                                                        placeholder="Quantity"
                                                        onChange={(e: any) => {
                                                            let newValue: number | undefined = Number(e.target.value)
                                                            if (newValue <= 0) {
                                                                newValue = undefined
                                                            } else {
                                                                newValue = Math.round(newValue)
                                                            }
                                                            const index = this.state.lines.indexOf(line)
                                                            if (index >= 0) {
                                                                const lines = _.cloneDeep(this.state.lines)
                                                                const l = lines[index]
                                                                l.quantity = newValue
                                                                this.setState({ lines })
                                                            }
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <ConfirmDeleteButton
                                                    message={`Remove this line from the demo order?`}
                                                    onDelete={() => { this.removeLine(line.key) }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </StripedTable>
                        <Button onClick={showPicker}>Add line</Button>

                    </Panel.Body>
                    <Panel.Footer>
                        <Button onClick={this.submit} disabled={this.state.lines.length === 0}>Submit</Button>
                    </Panel.Footer>
                </Panel>
                {
                    this.state.successMessage
                        ?
                        <Alert bsStyle="success">{this.state.successMessage}</Alert>
                        :
                        null
                }
                {
                    this.state.errorMessage !== undefined
                        ?
                        <Alert bsStyle="danger">{this.state.errorMessage}</Alert>
                        :
                        null
                }
            </PageState >
        )
    }

    // Actions

    submit = async () => {
        if (!this.state.apiKey || !this.state.integration || !this.state.currency) {
            return
        }

        this.setState({ publishing: true })

        const order: any = {
            basket: {
                currency: this.state.currency,
                line_items: this.state.lines
            },
            order_identifier: uuid()
        }

        if (this.state.mode === "click_and_collect" || this.state.mode === "ship_from_store") {
            order["payment"] = {
                reference: uuid()
            }
        }
        const customer: any = {}

        for (const prop of [
            ["customer_identifier", "customer_identifier"],
            ["customer_name", "name"],
            ["customer_email", "email"],
            ["customer_phone", "phone_number"],
        ]) {
            const [propertyName, mappedName] = prop
            if (!_.isNil(this.state[propertyName]) && this.state[propertyName].length > 0) {
                customer[mappedName] = this.state[propertyName]
            }
        }
        if (Object.keys(customer).length > 0) {
            order["customer"] = customer
        }

        const address: any = {}

        for (const prop of [
            ["customer_address_street", "street"],
            ["customer_address_city", "city"],
            ["customer_address_zip", "postal_code"],
        ]) {
            const [propertyName, mappedName] = prop
            if (!_.isNil(this.state[propertyName]) && this.state[propertyName].length > 0) {
                address[mappedName] = this.state[propertyName]
            }
        }
        if (Object.keys(address).length > 0 && this.state.mode === "ship_from_store") {
            order["shipping"] = { address: address }
        }
        console.log("ORDER", JSON.stringify(order, null, 2))

        const body = {
            external_orders: [order]
        }

        const url = process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE + `/imports/external_orders?account=${this.props.role.account_id}&stock_location=${this.props.shop}&apikey=${this.state.apiKey}&integration=${this.state.integration}`
        const options = {
            body: body,
            json: true
        }

        try {
            const response = await rp.post(url, options)
            this.setState({
                successMessage: response.message,
                lines: [],
                mode: "reserve_and_collect",
                errorMessage: undefined,
                customer_identifier: undefined,
                customer_name: undefined,
                customer_email: undefined,
                customer_phone: undefined,
                customer_address_street: undefined,
                customer_address_city: undefined,
                customer_address_zip: undefined,
            })
        } catch (error: any) {
            this.setState({ errorMessage: error.message })
        }

        this.setState({ publishing: false })
    }

    removeLine = (key: string) => {
        const lines = this.state.lines.filter((line: Line) => {
            return line.key !== key
        })
        this.setState({ lines })
    }

    // Helpers

    pickerSelectedElement = (element: ProductElement) => {
        if (_.isNil(this.state.market)) {
            return
        }

        const price = element.price.amount(this.state.market) || 100
        const line = new Line(element.imageUrl, element.name, price, element.productId, 1, element.variantId)
        const lines = _.cloneDeep(this.state.lines)
        lines.push(line)

        this.setState({ lines })
    }

    elementsForPicker = (): ModalPickerElement[] => {
        const products = this.productObserver.productsArray || []
        const sellables: ProductElement[] = []
        for (const product of products) {
            if (product.variants) {
                for (const variant of product.variants) {
                    sellables.push(new ProductElement(product, variant))
                }
            } else {
                sellables.push(new ProductElement(product))
            }
        }
        return sellables
    }

    loadMarketDetails = async (): Promise<void> => {
        const marketIdSnapshot = await ref().child(`/v1/accounts/${this.props.role.account_id}/shops/${this.props.shop}/market`).once("value")
        if (!marketIdSnapshot.exists()) {
            return
        }
        const marketId = marketIdSnapshot.val()

        const marketSnapshot = await ref().child(`/v1/accounts/${this.props.role.account_id}/markets/${marketId}`).once("value")
        if (!marketSnapshot.exists()) {
            return
        }
        const market = marketSnapshot.val()
        this.setState({ currency: market.currency, market: marketId })
    }

    loadApiKey = async (): Promise<void> => {
        let apiKeyResult: string = ""
        let integrationKeyResult: string = ""

        const externalOrderImportsSnapshot = await ref().child(`/v1/accounts/${this.props.role.account_id}/api_keys/import_integrations/external_orders`).once("value")
        if (externalOrderImportsSnapshot.exists()) {
            const externalOrderIntegrations = externalOrderImportsSnapshot.val()
            for (const key in externalOrderIntegrations) {
                const integrationObject = externalOrderIntegrations[key]
                const apiKey = Object.keys(integrationObject)[0]
                const metadata = integrationObject[apiKey].metadata
                if (metadata.active === true) {
                    apiKeyResult = apiKey
                    integrationKeyResult = key
                    break
                }
            }
        }

        this.setState({ apiKey: apiKeyResult, integration: integrationKeyResult })
    }

}

export default withRouter(ReserveAndCollectDemo)
