import * as _ from "lodash"
import * as React from "react"
import { AddStockLocationModal } from "./AddStockLocationModal"
import { CustomerLookupPanel } from "./CustomerLookupPanel"
import {
    EcommerceIntegrationConfiguration,
    ShopifyConfiguration
} from "./Models"
import { EcommerceSalesExportPanel } from "./EcommerceSalesExportPanel"
import { getExistingApiKey } from "../../../../helpers/existingApiKey"
import { PageState } from "../../../PageState"
import { ProductChangesPanel } from "./ProductChangesPanel"
import { ProductImportPanel } from "./ProductImportPanel"
import { ref } from "../../../../config/constants"
import { Role } from "../../../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router"
import { StockEventsPanel } from "./StockEventsPanel"
import { StockImportPanel } from "./StockImportPanel"
import { StripedTable } from "../../../StripedTable"
import { ToggleButton } from "../../../ToggleButton"
import { v4 as uuid } from "uuid"
import {
    Button,
    Col,
    FormControl,
    FormGroup,
    Glyphicon,
    Grid,
    InputGroup,
    Panel,
    Row,
} from "react-bootstrap"
import { Globals } from "../../../../helpers/globals"
import { Market } from "../../../../models/MarketModels"
import { ShopSalesExportPanel } from "./ShopSalesExportPanel"
import { NewsletterSignupExportPanel } from "./NewsletterSignupExportPanel"

interface ShopifyModuleProps extends RouteComponentProps<any> {
    role: Role
}

interface ShopifyModuleState {
    configuration: ShopifyConfiguration
    databaseUpdates: _.Dictionary<any>
    dirty: boolean
    ecommerceIntegrations: _.Dictionary<EcommerceIntegrationConfiguration>
    loaded: boolean
    publishing: boolean
    showAddStockLocationModal: boolean
    useAccessToken: boolean
}

class ShopifyModule extends React.Component<ShopifyModuleProps, ShopifyModuleState> {

    constructor(props: ShopifyModuleProps) {
        super(props)
        this.state = {
            configuration: this.configurationStateFromDbValue({}, []),
            databaseUpdates: {},
            dirty: false,
            ecommerceIntegrations: {},
            loaded: false,
            publishing: false,
            showAddStockLocationModal: false,
            useAccessToken: false
        }
    }

    // Helpers

    configurationStateFromDbValue(config: any, markets: Market[]): ShopifyConfiguration {
        const marketDict: _.Dictionary<boolean> = {}
        for (const market of markets) {
            marketDict[market.id] = true
        }
        const result: ShopifyConfiguration = {
            channels: config.channels || { "pos": true },
            customerLookupEnabled: config.customer_lookup_enabled || false,
            customerSignupEnabled: config.customer_signup_enabled || false,
            markets: config.markets || marketDict,
            enabled: config.enabled || false,
            ecomIntegrationId: config.ecom_integration_id || "",
            ecommerceSalesExportEnabled: config.ecommerce_sales_export_enabled || false,
            shopSalesExportEnabled: config.shop_sales_export_enabled || false,
            newsletterSignupExportEnabled: config.newsletter_signup_export_enabled || false,
            locationIdMap: config.location_id_map || {},
            productChangesEnabled: config.product_changes_enabled || false,
            shopifyShopName: config.shopify_shop_name || "",
            shopifyAPIKey: config.shopify_api_key ?? undefined,
            shopifyPassword: config.shopify_password ?? undefined,
            shopifyAccessToken: config.access_token ?? undefined,
            stockChangesEnabled: config.stock_changes_enabled || false,
            taxType: config.tax_type || "vat"
        }
        if (config.api_key) {
            result.apiKey = config.api_key
        }
        return result
    }

    isShopifyEnabled(): boolean {
        const config = this.state.configuration
        if (!_.isNil(config.shopifyAPIKey) || !_.isNil(config.shopifyAccessToken)) {
            return true
        }
        return false
    }

    shortenedKey(existing?: string): string {
        // shortening is done to avoid getting into max url size for webhook urls in Shopify (255 chars)
        return (existing || uuid()).slice(-12)
    }

    openShopifyNotifications = () => {
        window.open(`https://${this.state.configuration.shopifyShopName}.myshopify.com/admin/settings/notifications`)
    }

    openShopifyPrivateApps = () => {
        window.open(`https://${this.state.configuration.shopifyShopName}.myshopify.com/admin/apps/private`)
    }

    async prepareKeys() {
        const serviceUserUID = (await ref().child(`v1/accounts/${this.props.role.account_id}/api_keys/service_user_uid`).once("value")).val()
        if (!serviceUserUID) {
            console.error("No service user UID found on the account")
            return
        }

        // look for existing key and create new if needed
        const existingAPIKey = await getExistingApiKey(this.props.role.account_id)
        const apiKey = this.state.configuration.apiKey || existingAPIKey || this.shortenedKey()

        const dbUpdates: _.Dictionary<any> = {}
        dbUpdates[`v1/accounts/${this.props.role.account_id}/api_keys/import_integrations/products/shopify/${apiKey}/metadata`] = { active: true, uid: serviceUserUID }
        dbUpdates[`v1/accounts/${this.props.role.account_id}/api_keys/import_integrations/stock/shopify/${apiKey}/metadata`] = { active: true, uid: serviceUserUID }

        // update configuration and db updates
        this.updateDatabaseUpdates(dbUpdates)
        this.updateApiKey(apiKey)
    }

    isEnabled(): boolean {
        return this.state.configuration.enabled
    }

    isPrepared(): boolean {
        return !_.isNil(this.state.configuration.apiKey)
    }

    shopifyFieldsNonEmpty(): boolean {
        if (this.state.useAccessToken) {
            return (this.state.configuration.shopifyAccessToken?.length ?? 0) > 0 &&
                this.state.configuration.shopifyShopName.length > 0
        } else {
            return (this.state.configuration.shopifyAPIKey?.length ?? 0) > 0 &&
                (this.state.configuration.shopifyPassword?.length ?? 0) > 0 &&
                this.state.configuration.shopifyShopName.length > 0
        }
    }

    configurationJSON(): any {
        const config: any = {}
        if (this.state.configuration.apiKey) {
            config.api_key = this.state.configuration.apiKey
        }
        config.channels = this.state.configuration.channels
        config.markets = this.state.configuration.markets
        config.customer_lookup_enabled = this.state.configuration.customerLookupEnabled
        config.customer_signup_enabled = this.state.configuration.customerSignupEnabled
        config.ecom_integration_id = this.state.configuration.ecomIntegrationId
        config.ecommerce_sales_export_enabled = this.state.configuration.ecommerceSalesExportEnabled
        config.shop_sales_export_enabled = this.state.configuration.shopSalesExportEnabled
        config.newsletter_signup_export_enabled = this.state.configuration.newsletterSignupExportEnabled
        config.enabled = this.state.configuration.enabled
        config.location_id_map = this.state.configuration.locationIdMap
        config.product_changes_enabled = this.state.configuration.productChangesEnabled
        if (this.state.configuration.shopifyAPIKey) {
            config.shopify_api_key = this.state.configuration.shopifyAPIKey
        }
        if (this.state.configuration.shopifyPassword) {
            config.shopify_password = this.state.configuration.shopifyPassword
        }
        if (this.state.configuration.shopifyAccessToken) {
            config.access_token = this.state.configuration.shopifyAccessToken
        }
        if (this.state.configuration.shopifyShopName) {
            config.shopify_shop_name = this.state.configuration.shopifyShopName
        }
        config.stock_changes_enabled = this.state.configuration.stockChangesEnabled
        config.tax_type = this.state.configuration.taxType
        return config
    }

    updateShopifyShopName(value: string) {
        this.update(config => {
            config.shopifyShopName = value
        })
    }

    updateShopifyAPIKey(value: string) {
        this.update(config => {
            config.shopifyAPIKey = value
        })
    }

    updateShopifyPassword(value: string) {
        this.update(config => {
            config.shopifyPassword = value
        })
    }

    updateShopifyAccessToken(value: string) {
        this.update(config => {
            config.shopifyAccessToken = value
        })
    }

    updateApiKey(apiKey: string) {
        this.update(config => {
            config.apiKey = apiKey
        })
    }

    toggleEnabled() {
        this.update(config => {
            config.enabled = !(config.enabled ?? false)
        })
    }

    toggleUseAccessToken() {
        this.setState({ useAccessToken: !this.state.useAccessToken })
    }

    updateChannels = (channels: string[]) => {
        let minOneElementChannels = Object.keys(this.state.configuration.channels)
        if (channels.length > 0) {
            minOneElementChannels = channels
        }

        const channelsObject: _.Dictionary<boolean> = {}
        minOneElementChannels.forEach((channelId: string) => {
            channelsObject[channelId] = true
        })
        this.update(config => {
            config.channels = channelsObject
        })
    }

    updateMarkets = (markets: string[]) => {
        let minOneElementMarkets = Object.keys(this.state.configuration.markets)
        if (markets.length > 0) {
            minOneElementMarkets = markets
        }

        const marketsObject: _.Dictionary<boolean> = {}
        minOneElementMarkets.forEach((marketId: string) => {
            marketsObject[marketId] = true
        })
        this.update(config => {
            config.markets = marketsObject
        })
    }

    updateProductChangesEnabled = (value: boolean) => {
        this.update(config => {
            config.productChangesEnabled = value
        })
    }

    updateCustomerLookupEnabled = (value: boolean) => {
        this.update(config => {
            config.customerLookupEnabled = value
        })
    }

    updateCustomerSignupEnabled = (value: boolean) => {
        this.update(config => {
            config.customerSignupEnabled = value
        })
    }

    updateEcommerceSalesExportEnabled = (value: boolean) => {
        this.update(config => {
            config.ecommerceSalesExportEnabled = value
        })
    }

    updateShopSalesExportEnabled = (value: boolean) => {
        this.update(config => {
            config.shopSalesExportEnabled = value
        })
    }

    updateNewsletterSignupExportEnabled = (value: boolean) => {
        this.update(config => {
            config.newsletterSignupExportEnabled = value
        })
    }

    update = (closure: (config: ShopifyConfiguration) => void) => {
        const configuration = _.cloneDeep(this.state.configuration)
        closure(configuration)
        this.setState({ configuration: configuration, dirty: true })
    }

    updateStockEventsEnabled = (value: boolean) => {
        this.update(config => {
            config.stockChangesEnabled = value
        })
    }

    updateDatabaseUpdates(updates: _.Dictionary<any>) {
        const dbUpdates = _.cloneDeep(this.state.databaseUpdates)
        for (const key in updates) {
            dbUpdates[key] = updates[key]
        }
        this.setState({ databaseUpdates: dbUpdates, dirty: true })
    }

    updateTaxType(value: string) {
        this.update(config => {
            config.taxType = value
        })
    }

    updateLocationIdMap(value: _.Dictionary<string>) {
        this.update(config => {
            config.locationIdMap = value
        })
    }

    updateEcommerceIntegrationId(value: string) {
        this.update(config => {
            config.ecomIntegrationId = value
        })
    }

    addStockLocation = (stockLocationId: string, shopifyLocationId: string) => {
        const locationIdMap = _.cloneDeep(this.state.configuration.locationIdMap)
        locationIdMap[stockLocationId] = shopifyLocationId
        this.updateLocationIdMap(locationIdMap)

        const stockLocationMap = Object.keys(locationIdMap).reduce((result, currentKey) => {
            result[locationIdMap[currentKey]] = currentKey
            return result
        }, {})
        const dbUpdates = _.cloneDeep(this.state.databaseUpdates)
        dbUpdates[`v1/accounts/${this.props.role.account_id}/configuration/import_integrations/stock/shopify/stock_location_map`] = stockLocationMap
        this.updateDatabaseUpdates(dbUpdates)

        this.hideAddStockLocationModal()
    }

    removeStockLocationId = (stockLocationId: string) => {
        const locationIdMap = _.cloneDeep(this.state.configuration.locationIdMap)
        delete locationIdMap[stockLocationId]
        this.updateLocationIdMap(locationIdMap)

        const stockLocationMap = Object.keys(locationIdMap).reduce((result, currentKey) => {
            result[locationIdMap[currentKey]] = currentKey
            return result
        }, {})
        const dbUpdates = _.cloneDeep(this.state.databaseUpdates)
        dbUpdates[`v1/accounts/${this.props.role.account_id}/configuration/import_integrations/stock/shopify/stock_location_map`] = stockLocationMap
        this.updateDatabaseUpdates(dbUpdates)
    }

    showAddStockLocationModal = () => {
        this.setState({ showAddStockLocationModal: true })
    }

    hideAddStockLocationModal = () => {
        this.setState({ showAddStockLocationModal: false })
    }

    publish = async () => {
        this.setState({ publishing: true })

        // module update
        const shopifyRef = ref().child(`v1/accounts/${this.props.role.account_id}/configuration/modules/shopify_integration`)
        await shopifyRef.set(this.configurationJSON())

        // other db updates
        await ref().update(this.state.databaseUpdates)

        this.setState({ dirty: false, publishing: false, databaseUpdates: {} })
    }

    // Component

    async componentDidMount() {
        const ecommerceIntegrationsSnapshot = await ref().child(`v1/accounts/${this.props.role.account_id}/configuration/ecom_integrations`).once("value")
        if (ecommerceIntegrationsSnapshot.exists()) {
            this.setState({ ecommerceIntegrations: ecommerceIntegrationsSnapshot.val() })
        }

        const configurationSnapshot = await ref().child(`v1/accounts/${this.props.role.account_id}/configuration/modules/shopify_integration`).once("value")
        const markets = await Globals.shared.getMarkets()
        const config = this.configurationStateFromDbValue(configurationSnapshot.val() || {}, markets)
        const useAccessToken = !_.isNil(config.shopifyAccessToken)
        // This is catching previously configured product import integrations and setting the productChangesEnabled to true if an integration is present
        const productImportIntegrationSnapshot = await ref().child(`v1/accounts/${this.props.role.account_id}/configuration/import_integrations/products/shopify`).once("value")
        if (productImportIntegrationSnapshot.exists() && config.productChangesEnabled === false) {
            this.updateProductChangesEnabled(true)
        }

        this.setState({
            configuration: config,
            loaded: true,
            useAccessToken: useAccessToken
        })
    }

    requestHeaders(): any {
        const headers: any = {}
        if (_.isString(this.state.configuration.shopifyAccessToken) && this.state.configuration.shopifyAccessToken !== "") {
            headers["X-Shopify-Access-Token"] = this.state.configuration.shopifyAccessToken
        } else {
            const base64 = Buffer.from(`${this.state.configuration.shopifyAPIKey}:${this.state.configuration.shopifyPassword}`).toString("base64")
            const basicAuthValue = `Basic ${base64}`
            headers["Authorization"] = basicAuthValue
        }

        return headers
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="Shopify configuration"
                submit_action={async () => { await this.publish() }}
            // discard_action={() => { this.pop() }}
            >
                <Panel>
                    <Panel.Heading>
                        Shopify configuration
                    </Panel.Heading>

                    <Panel.Body>
                        {
                            this.state.showAddStockLocationModal
                                ?
                                (
                                    <AddStockLocationModal
                                        addStockLocation={(stockLocationId, shopifyLocationId) => { this.addStockLocation(stockLocationId, shopifyLocationId) }}
                                        hideAddStockLocationModal={() => { this.hideAddStockLocationModal() }}
                                    />
                                )
                                :
                                null
                        }
                        <Grid>
                            <Row>
                                <Col xs={12} sm={10} md={8}>
                                    <ToggleButton isEnabled={this.state.configuration.enabled} performToggle={() => { this.toggleEnabled() }} />
                                    <br />
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={10} md={8}>
                                    {
                                        this.isEnabled() ? (
                                            <div>
                                                {
                                                    this.isPrepared() ? (
                                                        <div>
                                                            <br />
                                                            <h4>Connect</h4>
                                                            <p>
                                                                Connect your Shopify webshop to Ka-ching by filling in the 3 fields below. When that is done you can configure each aspect of the integration and perform initial imports.
                                                                <br />
                                                                <br />
                                                                Start by entering your Shopify shop name. When that is done, use the "Open Shopify configuration" button to go to Shopify settings to configure a private app for Ka-ching.
                                                            </p>
                                                            <FormGroup>
                                                                <InputGroup>
                                                                    <InputGroup.Addon>Shopify shop name</InputGroup.Addon>
                                                                    <FormControl
                                                                        style={{ textAlign: "right" }}
                                                                        type="text"
                                                                        value={this.state.configuration.shopifyShopName ?? ""}
                                                                        onChange={(e) => {
                                                                            this.updateShopifyShopName((e.target as any).value)
                                                                        }}
                                                                    />
                                                                    <InputGroup.Addon>.myshopify.com</InputGroup.Addon>
                                                                </InputGroup>
                                                                <br />
                                                                <Button disabled={this.state.configuration.shopifyShopName === ""} onClick={() => { window.open(`https://${this.state.configuration.shopifyShopName}.myshopify.com/admin/apps/private`) }}>Open Shopify configuration <Glyphicon glyph="new-window" /></Button>
                                                            </FormGroup>
                                                            <p>
                                                                If there's already an app called Ka-ching then open that. Otherwise create a new app with the name Ka-ching.
                                                                <br />
                                                                <br />
                                                                New Shopify apps use access tokens rather than API key and password.
                                                                <br />
                                                                Insert the access token or API key and password in the fields below. When that is done you're ready to configure each aspect of the integration and perform initial imports.
                                                            </p>
                                                            <FormGroup>
                                                                <ToggleButton enabledTitle="Use access token" disabledTitle="Use API key" isEnabled={this.state.useAccessToken} performToggle={() => { this.toggleUseAccessToken() }} />

                                                            </FormGroup>
                                                            {this.state.useAccessToken || <span>
                                                                <FormGroup>
                                                                    <InputGroup>
                                                                        <InputGroup.Addon>API key</InputGroup.Addon>
                                                                        <FormControl
                                                                            style={{ textAlign: "right" }}
                                                                            type="text"
                                                                            value={this.state.configuration.shopifyAPIKey}
                                                                            onChange={(e) => {
                                                                                this.updateShopifyAPIKey((e.target as any).value)
                                                                            }}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <InputGroup>
                                                                        <InputGroup.Addon>Password</InputGroup.Addon>
                                                                        <FormControl
                                                                            style={{ textAlign: "right" }}
                                                                            type="text"
                                                                            value={this.state.configuration.shopifyPassword}
                                                                            onChange={(e) => {
                                                                                this.updateShopifyPassword((e.target as any).value)
                                                                            }}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </span>}
                                                            {this.state.useAccessToken && <span>
                                                                <FormGroup>
                                                                    <InputGroup>
                                                                        <InputGroup.Addon>Access token</InputGroup.Addon>
                                                                        <FormControl
                                                                            style={{ textAlign: "right" }}
                                                                            type="text"
                                                                            value={this.state.configuration.shopifyAccessToken}
                                                                            onChange={(e) => {
                                                                                this.updateShopifyAccessToken((e.target as any).value)
                                                                            }}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </span>}

                                                            {
                                                                this.shopifyFieldsNonEmpty() ? (
                                                                    <div>
                                                                        <h4>Locations</h4>
                                                                        <p>
                                                                            Map your stock location ids in Ka-ching to location ids in Shopify. It's necessary so the integrations know where the different events take place in both systems.
                                                                            <br />
                                                                            <br />
                                                                            You can find a the stock location id of a Ka-ching shop by going to the shop edit page of that shop and copying the last path element in the URL of that page. If you use an ecommerce stock location you can find the id of it on the Configuration page in the "Ecommerce configuration" section.
                                                                            <br />
                                                                            <br />
                                                                            You can find your Shopify locations <a href={`https://${this.state.configuration.shopifyShopName}.myshopify.com/admin/settings/locations`}>here</a>. To find the ids open each location page individually and find the id in the URL of that page.
                                                                        </p>
                                                                        <StripedTable>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Ka-ching stock location id</th>
                                                                                    <th>Shopify location id</th>
                                                                                    <th>Remove</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    Object.keys(this.state.configuration.locationIdMap).map((key: string) => {
                                                                                        return (
                                                                                            <tr key={key} >
                                                                                                <td>
                                                                                                    {key}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {this.state.configuration.locationIdMap[key]}
                                                                                                </td>
                                                                                                <td className="narrow">
                                                                                                    <Button bsStyle="danger" onClick={(event) => { this.removeStockLocationId(key); event.stopPropagation() }}>X</Button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </StripedTable>
                                                                        <Button onClick={this.showAddStockLocationModal}>Add stock location</Button>
                                                                        <br /> <br />
                                                                        <h4>Integrations</h4>
                                                                        <ProductChangesPanel
                                                                            accountId={this.props.role.account_id}
                                                                            channels={this.state.configuration.channels}
                                                                            markets={this.state.configuration.markets}
                                                                            configuration={this.state.configuration}
                                                                            enabled={this.state.configuration.productChangesEnabled}
                                                                            openShopifyNotifications={() => { this.openShopifyNotifications() }}
                                                                            updateDatabaseUpdates={(updates: _.Dictionary<any>) => { this.updateDatabaseUpdates(updates) }}
                                                                            updateEnabled={(enabled: boolean) => { this.updateProductChangesEnabled(enabled) }}
                                                                            updateChannels={(channels: string[]) => { this.updateChannels(channels) }}
                                                                            updateMarkets={(markets: string[]) => { this.updateMarkets(markets) }}
                                                                        />
                                                                        <CustomerLookupPanel
                                                                            accountId={this.props.role.account_id}
                                                                            configuration={this.state.configuration}
                                                                            enabled={this.state.configuration.customerLookupEnabled}
                                                                            signupEnabled={this.state.configuration.customerSignupEnabled}
                                                                            openShopifyPrivateApps={() => { this.openShopifyPrivateApps() }}
                                                                            updateDatabaseUpdates={(updates: _.Dictionary<any>) => { this.updateDatabaseUpdates(updates) }}
                                                                            updateEnabled={(enabled: boolean) => { this.updateCustomerLookupEnabled(enabled) }}
                                                                            updateSignupEnabled={(enabled: boolean) => { this.updateCustomerSignupEnabled(enabled) }}
                                                                            requestHeaders={() => { return this.requestHeaders() }}
                                                                        />
                                                                        <EcommerceSalesExportPanel
                                                                            accountId={this.props.role.account_id}
                                                                            configuration={this.state.configuration}
                                                                            ecommerceIntegrations={this.state.ecommerceIntegrations}
                                                                            enabled={this.state.configuration.ecommerceSalesExportEnabled}
                                                                            openShopifyPrivateApps={() => { this.openShopifyPrivateApps() }}
                                                                            updateEcommerceIntegrationId={(id: string) => { this.updateEcommerceIntegrationId(id) }}
                                                                            updateDatabaseUpdates={(updates: _.Dictionary<any>) => { this.updateDatabaseUpdates(updates) }}
                                                                            updateEnabled={(enabled: boolean) => { this.updateEcommerceSalesExportEnabled(enabled) }}
                                                                            updateTaxType={(taxType: string) => { this.updateTaxType(taxType) }}
                                                                            requestHeaders={() => { return this.requestHeaders() }}
                                                                        />
                                                                        <ShopSalesExportPanel
                                                                            accountId={this.props.role.account_id}
                                                                            configuration={this.state.configuration}
                                                                            enabled={this.state.configuration.shopSalesExportEnabled}
                                                                            openShopifyPrivateApps={() => { this.openShopifyPrivateApps() }}
                                                                            updateDatabaseUpdates={(updates: _.Dictionary<any>) => { this.updateDatabaseUpdates(updates) }}
                                                                            updateEnabled={(enabled: boolean) => { this.updateShopSalesExportEnabled(enabled) }}
                                                                            updateTaxType={(taxType: string) => { this.updateTaxType(taxType) }}
                                                                            requestHeaders={() => { return this.requestHeaders() }}
                                                                        />
                                                                        <NewsletterSignupExportPanel
                                                                            accountId={this.props.role.account_id}
                                                                            configuration={this.state.configuration}
                                                                            enabled={this.state.configuration.newsletterSignupExportEnabled}
                                                                            openShopifyPrivateApps={() => { this.openShopifyPrivateApps() }}
                                                                            updateDatabaseUpdates={(updates: _.Dictionary<any>) => { this.updateDatabaseUpdates(updates) }}
                                                                            updateEnabled={(enabled: boolean) => { this.updateNewsletterSignupExportEnabled(enabled) }}
                                                                            requestHeaders={() => { return this.requestHeaders() }}
                                                                        />
                                                                        <StockEventsPanel
                                                                            accountId={this.props.role.account_id}
                                                                            configuration={this.state.configuration}
                                                                            enabled={this.state.configuration.stockChangesEnabled}
                                                                            openShopifyNotifications={() => { this.openShopifyNotifications() }}
                                                                            openShopifyPrivateApps={() => { this.openShopifyPrivateApps() }}
                                                                            updateDatabaseUpdates={(updates: _.Dictionary<any>) => { this.updateDatabaseUpdates(updates) }}
                                                                            updateEnabled={(enabled: boolean) => { this.updateStockEventsEnabled(enabled) }}
                                                                            requestHeaders={() => { return this.requestHeaders() }}
                                                                        />
                                                                        <h4>Imports</h4>
                                                                        <ProductImportPanel
                                                                            accountId={this.props.role.account_id}
                                                                            channels={this.state.configuration.channels}
                                                                            markets={this.state.configuration.markets}
                                                                            configuration={this.state.configuration}
                                                                            openShopifyPrivateApps={() => { this.openShopifyPrivateApps() }}
                                                                            updateChannels={(channels: string[]) => { this.updateChannels(channels) }}
                                                                            updateMarkets={(markets: string[]) => { this.updateMarkets(markets) }}
                                                                        />
                                                                        <StockImportPanel
                                                                            accountId={this.props.role.account_id}
                                                                            configuration={this.state.configuration}
                                                                            openShopifyPrivateApps={() => { this.openShopifyPrivateApps() }}
                                                                        />
                                                                    </div>
                                                                ) : null
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <br />
                                                            <p>Click the prepare button below to create the necessary keys in Ka-ching.</p>
                                                            <br />
                                                            <Button onClick={async () => { await this.prepareKeys() }}>Prepare</Button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : null
                                    }
                                </Col>
                            </Row>
                        </Grid >
                    </Panel.Body>
                    <Panel.Footer>
                        <Button onClick={this.publish} disabled={!this.state.dirty}>Publish</Button>
                    </Panel.Footer>
                </Panel>
            </PageState>
        )
    }
}

export default withRouter(ShopifyModule)
