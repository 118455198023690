import * as https from "https"
import * as React from "react"
import {
    auth,
    logout,
    signInWithApple,
    signInWithMicrosoft
} from "../helpers/auth"
import { parse as parseParams } from "query-string"
import { firestore, ref, selectRTDBInstance } from "../config/constants"
import {
    RouteComponentProps,
    withRouter
} from "react-router"
import firebase from "firebase/compat"

function setErrorMsg(error: Error) {
    return {
        registerError: error.message,
        loading: false
    }
}

export interface RegisterProps extends RouteComponentProps<any> {
    resolveRole(userId: String): Promise<void>
}

async function verifyInvite(account: string, token: string) {
    return new Promise<void>((resolve, reject) => {
        const url = process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE + `/verifyPendingInvite?account_id=${encodeURIComponent(account)}&token=${encodeURIComponent(token)}`
        const request = https.get(url, (response) => {
            switch (response.statusCode) {
                case 204:
                    resolve()
                    break
                case 410:
                    reject({ "message": `Invite with token ${token} has expired` })
                    break
                default:
                    reject({ "message": `No pending invite with token ${token} found` })
                    break
            }
        })
        request.on("error", (error: Error) => {
            reject(error)
        })
    })
}

type SignUpMethods = "email" | "apple" | "microsoft"

interface RegisterState {
    loading: boolean
    existingEmail?: string
    email?: string
    token?: string
    password?: string
    registerError: string | null
    allowedMethods: SignUpMethods[]
}

class Register extends React.Component<RegisterProps, RegisterState> {
    constructor(props: RegisterProps) {
        super(props)
        this.state = {
            registerError: null,
            loading: false,
            allowedMethods: ["email", "apple", "microsoft"]
        }
    }

    async componentDidMount() {
        await logout()

        this.extractQueryParams()
    }

    extractQueryParams = () => {
        const parsed = parseParams(this.props.location.search)
        let emailAddress = parsed["email"]
        let token = parsed["token"]
        let signUpMethods = parsed["methods"]

        const state: any = {}

        if (emailAddress) {
            if (typeof emailAddress !== "string") {
                emailAddress = emailAddress[0]
            }

            state["existingEmail"] = decodeURIComponent(emailAddress)
        }
        if (token) {
            if (typeof token !== "string") {
                token = token[0]
            }
            state["token"] = token
        }

        if (signUpMethods && typeof signUpMethods !== "string") {
            signUpMethods = signUpMethods[0]
        }

        if (signUpMethods && typeof signUpMethods === "string") {
            const methods: SignUpMethods[] = []
            for (const method of signUpMethods.split(",")) {
                if (method === "email") {
                    methods.push("email")
                } else if (method === "apple") {
                    methods.push("apple")
                } else if (method === "microsoft") {
                    methods.push("microsoft")
                }
            }
            state["allowedMethods"] = methods
        } else {
            state["allowedMethods"] = ["email", "apple", "microsoft"]
        }

        this.setState(state)
    }

    signUpWithMicrosoft = async (e: any) => {
        e.preventDefault()
        await this.signIn("microsoft")
    }

    signUpWithApple = async (e: any) => {
        e.preventDefault()
        await this.signIn("apple")
    }

    signIn = async (type: SignUpMethods) => {
        const accountParsed = parseParams(this.props.location.search)["account_id"]
        let account = ""
        if (typeof accountParsed === "string") {
            account = accountParsed
        } else if (typeof accountParsed === "object" && accountParsed !== null) {
            account = accountParsed[0]
        }

        if (typeof account === "undefined" || account === null || account === "") {
            this.setState(setErrorMsg(new Error("Missing account id in URL")))
            return
        }

        const accountSnap: firebase.firestore.DocumentSnapshot | undefined = await firestore.doc(`accounts/${account}`).get().catch(() => { return undefined })
        const accountData = accountSnap?.data() ?? {}
        selectRTDBInstance(accountData.rtdb_instance, accountData.region)

        const email = this.state.email || this.state.existingEmail
        const password = this.state.password
        const token = this.state.token

        try {
            if (type === "email") {
                if (!email) { throw new Error("Missing e-mail address") }
                if (!password) { throw new Error("Missing password") }
            }
            if (!token) { throw new Error("Missing token") }
        } catch (error: any) {
            this.setState(setErrorMsg(error))
            return
        }

        this.setState({ loading: true })

        try {
            await verifyInvite(account, token)

            let credential: firebase.auth.UserCredential | undefined
            if (type === "email") {
                credential = await auth(email!, password!)
            } else if (type === "microsoft") {
                credential = await signInWithMicrosoft()
            } else if (type === "apple") {
                credential = await signInWithApple()
            }
            if (!credential) {
                throw new Error("Internal error when creating user. Please try again.")
            }
            const user = credential.user
            if (user === null) {
                throw new Error("Internal error when creating user. Please try again.")
            }

            // Wait until the invitation is accepted by awaiting the existence
            // of the account id in either 'accounts' or 'shops' for the profile.
            this.awaitAcceptance(user, account)

            // Then kindly accept the invitation
            await ref().child("v1/accounts/" + account + "/invites/accept/" + user.uid + "/" + token).set(true)
        } catch (error: any) {
            this.setState(setErrorMsg(error))
        }
    }

    handleSubmit = async (e: any) => {
        e.preventDefault()
        await this.signIn("email")
    }

    private awaitAcceptance(user: firebase.User, acceptAccountId: string) {
        const refs = [
            ref().child(`v1/profiles/${user.uid}/accounts/${acceptAccountId}`),
            ref().child(`v1/profiles/${user.uid}/shops/${acceptAccountId}`)
        ]

        const callback = async (snapshot: firebase.database.DataSnapshot | null) => {
            if (!snapshot || !snapshot.exists()) {
                return
            }
            for (const childRef of refs) {
                childRef.off()
            }
            await this.props.resolveRole(user.uid)

            this.props.history.push("/")
        }

        // First start listening
        for (const childRef of refs) {
            childRef.on("value", callback)
        }
    }

    render() {
        return (
            <div className="col-sm-6 col-sm-offset-3">
                <h1>Sign up</h1>
                {
                    this.state.loading
                        ?
                        <div> Creating user, please wait... </div>
                        : (
                            <div>
                                { this.state.allowedMethods.includes("email") ? (
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label>Email</label>
                                            {
                                                this.state.existingEmail
                                                    ?
                                                    <input className="form-control" placeholder="Email" value={this.state.existingEmail} disabled={true} />
                                                    :
                                                    (
                                                        <input
                                                            className="form-control"
                                                            placeholder="Email"
                                                            value={this.state.email || ""}
                                                            onChange={(e: any) => { this.setState({ email: e.target.value }) }}
                                                            type="email"
                                                            autoComplete="off"
                                                            autoCorrect="off"
                                                            autoCapitalize="off"
                                                            spellCheck="false"
                                                        />
                                                    )
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" className="form-control" placeholder="Password" value={this.state.password || ""} onChange={(e: any) => { this.setState({ password: e.target.value }) }} />
                                        </div>
                                        <button type="submit" className="btn btn-primary">Sign up</button>
                                    </form>
                                )
                                    : null
                                }

                                { (this.state.allowedMethods.includes("email") && this.state.allowedMethods.length > 1) ?
                                    (
                                        <div>
                                            <br />
                                        --- or ---
                                            <br />
                                        </div>
                                    )
                                    : null
                                }

                                { this.state.allowedMethods.includes("apple") ?
                                    (
                                        <div>
                                            <br />
                                            <button className="btn btn-default" onClick={this.signUpWithApple} style={{ width: "300px", background: "#000000", color: "#FFFFFF", textShadow: "inherit", fontSize: "19px", padding: "0px", border: "0px" }}><img alt="Apple logo" src="/backoffice/apple.svg" style={{ height: "44px" }} /> Sign up with Apple&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                                            <br />
                                        </div>
                                    ) : null}

                                { this.state.allowedMethods.includes("microsoft") ? (
                                    <div>
                                        <br />
                                        <button className="btn btn-default" onClick={this.signUpWithMicrosoft} style={{ width: "300px", background: "#000000", color: "#FFFFFF", textShadow: "inherit", fontSize: "19px", padding: "0px", border: "0px", height: "44px" }}><img alt="Microsoft logo" src="/backoffice/microsoft.svg" style={{ height: "20px" }} />&nbsp;&nbsp;&nbsp;Sign up with Microsoft</button>
                                        <br />
                                    </div>
                                ) : null}

                                {
                                    this.state.registerError && (
                                        <div>
                                            <br />
                                            <br />
                                            <div className="alert alert-danger" role="alert">
                                                <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true" />
                                                <span className="sr-only">Error:</span>
                                            &nbsp;{this.state.registerError}
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        )
                }
            </div>
        )
    }
}

export default withRouter(Register)
