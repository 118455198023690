import firebase from "firebase/compat"
import * as React from "react"

import { Button, Glyphicon, Panel, ProgressBar } from "react-bootstrap"
import { ShopifyConfiguration } from "./Models"

interface StockImportPanelProps {
    accountId: string
    configuration: ShopifyConfiguration
    openShopifyPrivateApps: () => void
}

interface StockImportPanelState {
    importCount?: number
    importProgress?: number
    importTotal?: number
}

export class StockImportPanel extends React.Component<StockImportPanelProps, StockImportPanelState> {
    
    constructor(props: StockImportPanelProps) {
        super(props)
        this.state = {}
    }

    // Helpers

    stockImportHelpText = (
        <div key="stock_import_help_text">
            Follow these steps to perform a stock import from Shopify to Ka-ching
            <ol>
                <li>
                    Click the "Open Shopify Configuration" button above to open Shopify settings. Click the Ka-ching private app in that page.
                </li>
                <li>
                    Make sure that Ka-ching has access to read the stock values in Shopify. The stock import requires 'Read and write' to 'Inventory'.
                </li>
                <li>
                    Remember to save any changes in Shopify.
                </li>
                <li>
                    Click 'Import stock values' to start the import.
                </li>
            </ol>
        </div>
    )

    importLabelText(): string {
        if (this.state.importCount !== undefined && this.state.importTotal !== undefined) {
            const label = `${this.state.importCount} / ${this.state.importTotal}`
            if (this.state.importCount >= this.state.importTotal) {
                return label + " Import complete!"
            }
            return label
        } else {
            return "Initiating import"
        }
    }

    importStockValues = async () => {
        const triggerStockImport = firebase.functions().httpsCallable("triggerImport", { timeout: 540000000 })
        this.setState({ importProgress: 0, importCount: undefined, importTotal: undefined })
        let imported: number = 0
        let total: number = 0
        let nextPageURL: string | undefined = undefined
        while (true) {
            const options: any = {
                account: this.props.accountId,
                integrationType: "shopify",
                integration: "shopify",
                elementType: "stock"
            }
            if (nextPageURL) {
                options.nextPageURL = nextPageURL
            }
            const result = await triggerStockImport(options)
            if (result.data.total) {
                total = result.data.total
            }
            if (result.data.count) {
                imported += result.data.count
            }
            nextPageURL = result.data.next_page_url

            let progress = 0
            if (imported > 0 && total > 0) {
                progress = (100 * imported) / total
            }
            this.setState({ importProgress: progress, importCount: imported, importTotal: total })

            if (!nextPageURL) {
                break
            }
        }
    }

    // Component

    render() {
        return (
            <Panel key="stock_import" defaultExpanded={false}>
                <Panel.Heading>
                    <Panel.Title toggle={true}>Stock import</Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <Button onClick={() => { this.props.openShopifyPrivateApps() }}>Open Shopify Configuration <Glyphicon glyph="new-window" /></Button>
                        <br/> <br/>
                        {this.stockImportHelpText}
                        <br/> <br/>
                        <Button disabled={this.state.importProgress !== undefined} onClick={async () => { await this.importStockValues() }}>Import stock values</Button>
                        <br/> <br/>
                        {this.state.importProgress !== undefined ? <ProgressBar now={this.state.importProgress} label={this.importLabelText()} /> : null}
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        )
    }
}