import * as React from "react"

import { Button, Panel, FormControl } from "react-bootstrap"
import { ref } from "../../../config/constants"
import { PageState } from "../../PageState"
import { Role } from "../../../config/role"

interface ReceiptBarcodeConfigurationProps {
    role: Role
}

interface ReceiptBarcodeConfigurationState {
    barcodeType: string
    dirty: boolean
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
}

export default class ReceiptBarcodeConfiguration extends React.Component<ReceiptBarcodeConfigurationProps, ReceiptBarcodeConfigurationState> {
    constructor(props: ReceiptBarcodeConfigurationProps) {
        super(props)

        this.state = {
            barcodeType: "qr",
            dirty: false,
            loaded: false,
            publishing: false,
            showSuccess: false
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/receipt/barcode_type`)
        const configSnap = await configRef.once("value")
        let barcodeType = this.state.barcodeType
        if (configSnap.exists()) {
            barcodeType = configSnap.val()
        }
        this.setState({ barcodeType: barcodeType, loaded: true })
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="contribution ratio">
                <Panel key="upper_panel" bsStyle={this.state.showSuccess ? "success" : "default"}>
                    <Panel.Heading>
                        <Panel.Title>{this.state.showSuccess ? "Receipt barcode type updated successsfully" : "Receipt barcode type"}</Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <h4>Please select the desired barcode format for the barcode that's printed on receipts for quick identification of a sale or offer.</h4>
                        <FormControl
                            componentClass="select"
                            name="barcode_type"
                            value={this.state.barcodeType}
                            onChange={(e: any) => {this.barcodeTypeChanged(e.target.value)}}
                        >
                            <option value="qr">QR</option>
                            <option value="code128">Code 128</option>
                        </FormControl>
                    </Panel.Body>

                    <Panel.Footer>
                        <Button onClick={this.publish} disabled={!this.state.dirty}>Publish</Button>
                    </Panel.Footer>
                </Panel >
            </PageState>
        )
    }

    barcodeTypeChanged = (value: string) => {
         this.setState({ barcodeType: value, dirty: true })
    }

    publish = async () => {
        const account = this.props.role.account_id
        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/receipt/`)
        console.log("publish", this.state.barcodeType)
        this.setState({ publishing: true })
        await configRef.set({barcode_type: this.state.barcodeType})
        this.setState({ showSuccess: true, publishing: false, dirty: false })
    }
}
