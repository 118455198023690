import * as _ from "lodash"
import * as React from "react"
import {
    Alert,
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Panel,
    Row
    } from "react-bootstrap"
import { EcomIntegration } from "../../../models/EcomIntegration"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import firebase from "firebase/compat"

interface EcommerceConfigurationProps {
    role: Role
}

interface EcommerceConfigurationState {
    dirty: boolean
    ecomIntegration?: EcomIntegration
    loaded: boolean
    moreThanOneIntegrations: boolean
    publishing: boolean
    showSuccess: boolean
}

export class EcommerceConfiguration extends React.Component<EcommerceConfigurationProps, EcommerceConfigurationState> {

    constructor(props: EcommerceConfigurationProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            moreThanOneIntegrations: false,
            publishing: false,
            showSuccess: false
        }
    }

    // Helpers

    ecomIntegrationsRef = (): firebase.database.Reference => {
        const account = this.props.role.account_id
        return ref().child(`v1/accounts/${account}/configuration/ecom_integrations`)
    }

    stockLocationIndexRef = (): firebase.database.Reference => {
        const account = this.props.role.account_id
        return ref().child(`v1/accounts/${account}/stock_location_index`)
    }

    createAndPublishDefaultConfiguration = async () => {
        this.setState({ publishing: true })

        const stockLocationIndexEntryRef = this.stockLocationIndexRef().child("ecom")
        const stockLocationIndexEntrySnap = await stockLocationIndexEntryRef.once("value")
        if (!stockLocationIndexEntrySnap.exists()) {
            await stockLocationIndexEntryRef.set({
                type: "ecom"
            })
        }

        const defaultConfiguration: EcomIntegration = {
            id: "default",
            name: "Online",
            stock_location: "ecom"
        }

        this.setState({ ecomIntegration: defaultConfiguration }, async () => {
            if (_.isNil(this.state.ecomIntegration)) {
                return
            }

            const configRef = this.ecomIntegrationsRef().child(`${this.state.ecomIntegration.id}`)

            this.setState({ publishing: true })
    
            await configRef.set(this.state.ecomIntegration)
    
            this.setState({ showSuccess: true, publishing: false, dirty: false })
        })
    }

    removeEcomConfiguration = async () => {
        if (_.isNil(this.state.ecomIntegration)) {
            return
        }

        const reply = window.confirm(`Really delete ecommerce configuration?`)
        if (reply === true) {
            const configRef = this.ecomIntegrationsRef().child(`${this.state.ecomIntegration.id}`)

            this.setState({ publishing: true })
    
            await configRef.remove()
    
            this.setState({ showSuccess: true, publishing: false, dirty: false, ecomIntegration: undefined })
        }
    }

    // Component

    async componentDidMount() {
        const configSnap = await this.ecomIntegrationsRef().once("value")

        const state: any = {
            loaded: true
        }
        if (configSnap.exists()) {
            const config = configSnap.val() 
            const keys = Object.keys(config)
            state.ecomIntegration = config[keys[0]]
            if (keys.length > 1) {
                state.moreThanOneIntegrations = true
            }
        }

        this.setState(state)
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="ecommerce configuration">
                <Panel key="upper_panel" bsStyle={this.state.showSuccess ? "success" : "default"}>
                    <Panel.Heading>
                        <Panel.Title>{this.state.showSuccess ? "Ecommerce configuration published successsfully" : "Ecommerce configuration"}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {
                            this.state.moreThanOneIntegrations
                            ?
                            <Alert bsStyle="warning">You have more than 1 ecommerce configuration in the database. Editing more than 1 configuration is not supported in the Backoffice. Please reach out to Ka-ching support if you need to change your ecommerce configuration.</Alert>
                            :
                            null
                        }
                        {
                            !this.state.moreThanOneIntegrations && _.isNil(this.state.ecomIntegration)
                            ?
                            <p>Ecommerce not configured. Click setup the ecommerce stock location on your account.</p>
                            :
                            null
                        }
                        {
                            !this.state.moreThanOneIntegrations && !_.isNil(this.state.ecomIntegration)
                            ?
                            (
                                <div key="a">
                                    <FormGroup>
                                        <Row>
                                            <Col componentClass={ControlLabel} sm={2}>Name</Col>
                                            <Col sm={10}>
                                                <FormControl.Static>{this.state.ecomIntegration!.name}</FormControl.Static>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col componentClass={ControlLabel} sm={2}>Identifier</Col>
                                            <Col sm={10}>
                                                <FormControl.Static>{this.state.ecomIntegration!.stock_location}</FormControl.Static>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </div>
                            )
                            :
                            null
                        }
                    </Panel.Body>
                    <Panel.Footer>
                        {
                            !this.state.moreThanOneIntegrations
                            ?
                            (
                                _.isNil(this.state.ecomIntegration)
                                ?
                                <Button onClick={this.createAndPublishDefaultConfiguration}>Create</Button>
                                :
                                <Button onClick={this.removeEcomConfiguration} bsStyle={"danger"}>Remove</Button>
                            )
                            :
                            null
                        }
                    </Panel.Footer>
                </Panel >
            </PageState>
        )
    }
}
