import * as _ from "lodash"

export interface ShopifyConfiguration {
    apiKey?: string
    customerLookupEnabled: boolean
    customerSignupEnabled: boolean
    ecommerceSalesExportEnabled: boolean
    shopSalesExportEnabled: boolean
    newsletterSignupExportEnabled: boolean
    ecomIntegrationId: string
    enabled: boolean
    channels: _.Dictionary<boolean>
    markets: _.Dictionary<boolean>
    locationIdMap: _.Dictionary<string>
    productChangesEnabled: boolean
    shopifyAccessToken?: string
    shopifyShopName: string
    shopifyAPIKey?: string
    shopifyPassword?: string
    stockChangesEnabled: boolean
    taxType: string
}

export interface EcommerceIntegrationConfiguration {
    id: string
    name: string
    stock_location?: string
}

export const shopifyAPIVersion = "2022-04"