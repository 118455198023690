//
//  index.tsx
//  Versions
//
//  Created by Flemming Pedersen on 17/01/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//
import * as React from "react"
import * as ReactDOM from "react-dom"
import { App } from "./App"

ReactDOM.render(
    <App />,
    document.getElementById("root")
)