import * as React from "react"
import * as ReactRouter from "react-router"
import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    InputGroup,
    Pager,
    Panel,
    Row,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap"
import { PageState } from "../../PageState"
import { Role } from "../../../config/role"
import { StockCountAdjustModal } from "./StockCountAdjustModal"
import { StockCountCurrentViewModel } from "./StockCountCurrentViewModel"
import {
    StockCountDevice,
    StockCountLine,
    StockCountLineState
} from "../../../models/StockCountModels"
import { StockCountDeviceEventListModal } from "./StockCountDeviceEventsListModal"
import { StockCountFilterType } from "../../../services/StockCountLinesQueryService"
import { StockCountLineEventListModal } from "./StockCountLineEventListModal"
import { StripedTable } from "../../StripedTable"

const FileDownload = require("js-file-download")

interface StockCountCurrentProps extends ReactRouter.RouteComponentProps<any> {
    role: Role
    stockLocation: string
}

interface StockCountCurrentState {
    adjustModalLine?: StockCountLine
    deviceEventsModalDevice?: StockCountDevice
    devices: StockCountDevice[]
    lineEventsModalLine?: StockCountLine
    lines: StockCountLine[]
    loaded: boolean
    name: string
    publishingMessage?: string
    searchButtonDisabled: boolean
    stockCountId: string
}

class StockCountCurrent extends React.Component<StockCountCurrentProps, StockCountCurrentState> {

    // Props

    viewModel: StockCountCurrentViewModel

    // Constructor

    constructor(props: StockCountCurrentProps) {
        super(props)

        const stockCountId = this.props.match.params.stockCountId

        this.viewModel = new StockCountCurrentViewModel(props.role.account_id, props.stockLocation, stockCountId)
        this.setupViewModelCallbacks(stockCountId)

        this.state = {
            devices: [],
            lines: [],
            loaded: false,
            name: "",
            searchButtonDisabled: true,
            stockCountId: stockCountId
        }
    }

    // Helpers

    reportMessage(loaded: number, aggregated: number): string {
        let result = "Generating report - "
        if (aggregated) {
            result += `created ${aggregated} lines`
        } else {
            result += `loaded ${loaded} products`
        }
        return result
    }

    allCSVButtonClicked() {
        this.createCSVReport(StockCountFilterType.ALL)
    }

    diffOnlyCSVReportClicked() {
        this.createCSVReport(StockCountFilterType.DIFF_ONLY)
    }

    notCountedCSVReportClicked() {
        this.createCSVReport(StockCountFilterType.NOT_COUNTED)
    }

    createCSVReport(type: StockCountFilterType) {
        this.setState({ publishingMessage: this.reportMessage(0, 0) })
        this.viewModel.createCSVReport(type, (loaded: number, aggregated: number) => {
            this.setState({ publishingMessage: this.reportMessage(loaded, aggregated) })
        })
    }

    cancelStockCountClicked() {
        const message = "Warning!\n\nThis action will cancel the stock count and it will be closed without having any effect on the stock.\n\nThis can not be reversed."
        if (window.confirm(message)) {
            this.setState({ publishingMessage: "Cancelling stock count" })
            this.viewModel.cancelStockCount()
        }
    }

    closeStockCountClicked() {
        const activeDevices = this.state.devices.filter((device: StockCountDevice) => {
            return device.active && device.id !== StockCountCurrentViewModel.backofficeDeviceId
        })
        if (activeDevices.length > 0) {
            const warning = `Warning\n\nActive mobile devices present. Please make sure that all devices have completed counting and are fully synchronized. Are you sure you want to proceed?`
            if (!window.confirm(warning)) {
                return
            }
        }

        const message = "Warning\n\nYou should only do this when the store is closed.\n\nThis action will close the stock count and adjust all stock values of the products that have been counted.\n\nThis can not be reversed."
        if (window.confirm(message)) {
            this.setState({ publishingMessage: "Publishing stock count" })
            this.viewModel.closeStockCount()
        }
    }

    closeSearch() {
        this.viewModel.closeSearch()
        this.viewModel.observeCurrentStockCountLines()
        this.setState({ loaded: false })
    }

    filterValueChanged(data: any) {
        if (this.viewModel.filterValueSelected(data)) {
            this.setState({ loaded: false })
        }
    }

    handleAdjustClick(line: StockCountLine) {
        if (!line.isMutable) {
            return
        }
        this.setState({ adjustModalLine: line })
    }

    handleLineClick(line: StockCountLine) {
        this.setState({ lineEventsModalLine: line })
    }

    handleKeyPress(value: any) {
        if (value.charCode === 13 && !this.viewModel.isSearchButtonDisabled()) {
            this.performSearch()
        }
    }

    handleDeviceLineClick(device: StockCountDevice) {
        this.setState({ deviceEventsModalDevice: device })
    }

    loadPrevious() {
        this.viewModel.observePreviousStockCountLines()
    }

    loadNext() {
        this.viewModel.observeNextStockCountLines()
    }

    performInitialLoad() {
        this.viewModel.observeNextStockCountLines()
        this.viewModel.startLoadOfName()
    }

    performSearch() {
        this.setState({ loaded: false })
        this.viewModel.performSearch()
    }

    searchTextChanged(text: string) {
        this.viewModel.searchTextChanged(text)
        this.setState({ searchButtonDisabled: this.viewModel.isSearchButtonDisabled() })
    }

    disconnectDeletedProductsFromStock() {
        const message = "Warning!\n\nThis action will disconnect all deleted products from stock.\n\nThis can not be reversed."
        if (window.confirm(message)) {
            this.viewModel.disconnectDeleted()
            this.setState({ publishingMessage: "Disconnecting deleted products from stock" })
        }
    }

    setUncountedToZeroClicked() {
        const message = "Warning!\n\nThis action will register 0 as counted for all lines without a count registered.\n\nThis can not be reversed."
        if (window.confirm(message)) {
            this.viewModel.setUncountedToZero()
            this.setState({ publishingMessage: "Setting all uncounted products to a count of 0" })
        }
    }

    setupViewModelCallbacks(stockCountId: string) {
        this.viewModel.cancelStockCountCompleted = (errorMessage) => {
            if (errorMessage) {
                alert(errorMessage)
                this.setState({ publishingMessage: undefined })
                return
            }
            const path = `/stock_location/${this.props.stockLocation}/stock_count/past/${stockCountId}`
            this.props.history.push(path)
        }
        this.viewModel.closeStockCountCompleted = this.viewModel.cancelStockCountCompleted
        this.viewModel.csvReportCreationCompleted = (report, fileName, errorMessage) => {
            if (errorMessage) {
                alert(errorMessage)
                this.setState({ publishingMessage: undefined })
                return
            }

            this.setState({ publishingMessage: undefined })
            if (report) {
                FileDownload(report, `${fileName}.csv`)
            }
        }
        this.viewModel.stockCountLinesChanged = (lines) => {
            this.setState({ lines: lines, loaded: true })
        }
        this.viewModel.nameLoaded = (name) => {
            this.setState({ name: name })
        }
        this.viewModel.setUncountedToZeroCompleted = (errorMessage) => {
            this.setState({ publishingMessage: undefined })
            if (errorMessage) {
                alert(errorMessage)
            }
        }
        this.viewModel.setDisconnectDeletedCompleted = (errorMessage) => {
            this.setState({ publishingMessage: undefined })
            if (errorMessage) {
                alert(errorMessage)
            }
        }
        this.viewModel.searchCompleted = (lines, errorMessage) => {
            if (!lines || errorMessage) {
                alert(errorMessage)
                this.setState({ loaded: true })
                return
            }

            this.setState({ lines: lines, loaded: true })
        }
        this.viewModel.devicesChanged = (devices: StockCountDevice[]) => {
            this.setState({ devices: devices })
        }
    }

    // Component

    componentDidMount() {
        this.performInitialLoad()
        this.viewModel.observeDevices()
    }

    componentWillUnmount() {
        this.viewModel.stopObserving()
    }

    render() {
        let header = this.state.name
        if (this.viewModel.isShowingSearchResults()) {
            header += " - SEARCH RESULTS"
        }
        return (
            <PageState loading={false} publishing={false} customMessage={this.state.publishingMessage} typeName="stock count">
                <Panel>
                    <Panel.Heading>
                        <Panel.Title componentClass="h3">{header}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {
                            this.viewModel.isShowingSearchResults()
                                ?
                                (
                                    <FormGroup onSubmit={(value) => this.handleKeyPress(value)}>
                                        <Row>
                                            <Col componentClass={ControlLabel} sm={4}>Lines matching:</Col>
                                            <Col sm={8} className="text-right">
                                                <FormControl.Static>{this.viewModel.searchTextValue()}</FormControl.Static>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col componentClass={ControlLabel} sm={6}>{" "}</Col>
                                        </Row>
                                        <Row>
                                            <Col componentClass={ControlLabel} sm={6}>Search results are not live updating.</Col>
                                            <Col sm={6} className="text-right">
                                                <Button onClick={() => { this.closeSearch() }}>
                                                    Close search results
                                                </Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                )
                                :
                                (
                                    <div>
                                        <FormGroup onSubmit={(value) => this.handleKeyPress(value)}>
                                            <Row>
                                                <Col componentClass={ControlLabel} sm={4}>Search</Col>
                                                <Col sm={8} className="text-right">
                                                    <InputGroup>
                                                        <FormControl
                                                            type="text"
                                                            name="search_text"
                                                            value={this.viewModel.searchTextValue()}
                                                            placeholder="Search for product by name, barcode or id"
                                                            onChange={(event: any) => { this.searchTextChanged(event.target.value) }}
                                                            onKeyPress={(value) => { this.handleKeyPress(value) }}
                                                        />
                                                        <InputGroup.Button>
                                                            <Button onClick={() => { this.performSearch() }} disabled={this.state.searchButtonDisabled}>
                                                                Search
                                                            </Button>
                                                        </InputGroup.Button>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col componentClass={ControlLabel} sm={6}>{" "}</Col>
                                            </Row>
                                            <Row>
                                                <Col componentClass={ControlLabel} sm={6}>CSV reports</Col>
                                                <Col sm={6} className="text-right">
                                                    <Button onClick={() => { this.allCSVButtonClicked() }} >
                                                        All
                                                    </Button>
                                                    &nbsp;
                                                    <Button onClick={() => { this.diffOnlyCSVReportClicked() }} >
                                                        Difference only
                                                    </Button>
                                                    &nbsp;
                                                    <Button onClick={() => { this.notCountedCSVReportClicked() }} >
                                                        Not counted
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col componentClass={ControlLabel} sm={6}>{" "}</Col>
                                            </Row>
                                            <Row>
                                                <Col componentClass={ControlLabel} sm={6}>Filters</Col>
                                                <Col sm={6} className="text-right">
                                                    <ToggleButtonGroup
                                                        type="checkbox"
                                                        value={[this.viewModel.filterValue()]}
                                                        onChange={(data: any) => { this.filterValueChanged(data) }}
                                                    >
                                                        <ToggleButton key={StockCountFilterType.ALL} value={StockCountFilterType.ALL}>All</ToggleButton>
                                                        <ToggleButton key={StockCountFilterType.DIFF_ONLY} value={StockCountFilterType.DIFF_ONLY}>Difference only</ToggleButton>
                                                        <ToggleButton key={StockCountFilterType.NOT_COUNTED} value={StockCountFilterType.NOT_COUNTED}>Not counted</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col componentClass={ControlLabel} sm={6}>{" "}</Col>
                                            </Row>
                                            <Row>
                                                <Col componentClass={ControlLabel} sm={6}>Actions</Col>
                                                <Col sm={6} className="text-right">
                                                    <Button bsStyle="warning" onClick={() => { this.disconnectDeletedProductsFromStock() }} >
                                                        Disconnect deleted
                                                    </Button>
                                                    &nbsp;
                                                    <Button bsStyle="warning" onClick={() => { this.setUncountedToZeroClicked() }} >
                                                        Set uncounted to 0
                                                    </Button>
                                                    &nbsp;
                                                    <Button bsStyle="danger" onClick={() => { this.cancelStockCountClicked() }} >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;
                                                    <Button bsStyle="success" onClick={() => { this.closeStockCountClicked() }} >
                                                        Approve
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <StripedTable>
                                            <thead>
                                                <tr>
                                                    <th>Devices</th>
                                                    <th className="narrow">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.devices.map((device: StockCountDevice) => {
                                                        const style: React.CSSProperties = device.active ? { color: "#499f48" } : {}
                                                        return (
                                                            <tr key={device.id} style={style} onClick={(event: any) => { this.handleDeviceLineClick(device) }}>
                                                                <td>{device.name || device.name}</td>
                                                                <td>{device.active ? "Active" : "Inactive"}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </StripedTable>
                                    </div>
                                )
                        }
                    </Panel.Body>
                    {
                        this.state.loaded
                            ?
                            (
                                <div>
                                    <StripedTable>
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Product</th>
                                                <th>Barcode</th>
                                                <th>Count</th>
                                                <th>Expected</th>
                                                <th>Difference</th>
                                                <th>Adjust</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.lines.map(line => {
                                                let lineProps: React.CSSProperties = {}
                                                switch (line.state) {
                                                    case StockCountLineState.loaded:
                                                        break
                                                    case StockCountLineState.initializing:
                                                        lineProps = { color: "#CCCCCC" }
                                                        break
                                                    case StockCountLineState.deleted:
                                                        lineProps = { color: "#FF0000" }
                                                        break
                                                    case StockCountLineState.disconnect:
                                                        lineProps = { color: "#883300" }
                                                        break
                                                }
                                                const result = (
                                                    <tr style={lineProps} key={line.productId + "*" + (line.variantId || "")} onClick={(event) => { this.handleLineClick(line) }}>
                                                        {
                                                            line.variantId
                                                                ?
                                                                <td className="narrow">{line.variantId}</td>
                                                                :
                                                                <td className="narrow">{line.productId}</td>
                                                        }
                                                        <td>{line.name}</td>
                                                        <td className="narrow">{line.barcode || ""}</td>
                                                        <td className="narrow">{typeof line.count === "number" ? line.count : ""}</td>
                                                        <td className="narrow">{typeof line.expected === "number" ? line.expected : ""}</td>
                                                        <td className="narrow">{typeof line.diff === "number" ? line.diff : ""}</td>
                                                        <td className="narrow">
                                                            <Button onClick={(event) => { this.handleAdjustClick(line); event.stopPropagation() }}>Adjust</Button>
                                                        </td>
                                                    </tr>
                                                )
                                                return result
                                            })}
                                        </tbody>
                                    </StripedTable>
                                </div>
                            )
                            :
                            null
                    }
                </Panel>
                {
                    this.viewModel.isShowingSearchResults()
                        ?
                        null
                        :
                        (
                            <Pager>
                                <Pager.Item previous={true} onClick={() => { this.loadPrevious() }} disabled={this.viewModel.isPreviousDisabled()}>&larr; Previous Page</Pager.Item>
                                <Pager.Item next={true} onClick={() => { this.loadNext() }} disabled={this.viewModel.isNextDisabled()}>Next Page &rarr;</Pager.Item>
                            </Pager>
                        )
                }
                {
                    this.state.adjustModalLine ? (
                        <StockCountAdjustModal
                            lineId={this.state.adjustModalLine.variantId ? `${this.state.adjustModalLine.productId}*${this.state.adjustModalLine.variantId}` : this.state.adjustModalLine.productId}
                            role={this.props.role}
                            stockLocation={this.props.stockLocation}
                            stockCountId={this.state.stockCountId}
                            completed={(didAjust: boolean) => {
                                this.setState({ adjustModalLine: undefined })
                                if (didAjust && this.viewModel.isShowingSearchResults()) {
                                    // since there's a delay when before the adjustment makes it to the line,
                                    // I'm using a little nasty delay and then hope that it's done before
                                    // doing the search again
                                    this.setState({ loaded: false })
                                    new Promise((resolve) => setTimeout(resolve, 1000))
                                        .then(() => {
                                            this.performSearch()
                                        })
                                        .catch((error) => {
                                            console.error(error.message)
                                        })
                                }
                            }}
                        />
                    ) : null
                }
                {
                    this.state.lineEventsModalLine
                        ?
                        (
                            <StockCountLineEventListModal
                                completed={() => { this.setState({ lineEventsModalLine: undefined }) }}
                                productId={this.state.lineEventsModalLine.productId}
                                productName={this.state.lineEventsModalLine.name}
                                role={this.props.role}
                                stockLocation={this.props.stockLocation}
                                stockCountId={this.state.stockCountId}
                                variantId={this.state.lineEventsModalLine.variantId}
                            />
                        )
                        :
                        null
                }
                {
                    this.state.deviceEventsModalDevice
                        ?
                        (
                            <StockCountDeviceEventListModal
                                completed={() => { this.setState({ deviceEventsModalDevice: undefined }) }}
                                deviceId={this.state.deviceEventsModalDevice.id}
                                deviceName={this.state.deviceEventsModalDevice.name}
                                role={this.props.role}
                                stockLocation={this.props.stockLocation}
                                stockCountId={this.state.stockCountId}
                            />
                        )
                        :
                        null
                }
            </PageState>
        )
    }
}

export default ReactRouter.withRouter(StockCountCurrent)
