import React from "react"
import { ControlLabel, Form, FormControl, FormGroup, HelpBlock, Panel } from "react-bootstrap"
import { AttributeObserver } from "../../../../helpers/attributeObserver";
import { ProductObserver } from "../../../../helpers/productObserver";
import { TagObserver } from "../../../../helpers/tagObserver";
import { Market } from "../../../../models/MarketModels";
import { AppliesTo, AppliesToSelectorPanel } from "../AppliesToSelector";
import { CustomerCondition, CustomerConditionSelector } from "../CustomerConditionSelector";
import { DiscountSelector, DiscountType } from "../DiscountSelector";
import { Description } from "./BuyXGetYFormDescription";
import * as _ from "lodash"
import { DateComponents, RuleModel } from "../../../../models/RuleModels";
import { L10nString, LanguageCode } from "../../../../helpers/L10n";
import { BuyXGetYTemplate } from "./Model";
import { SharedPropertiesSelector } from "../SharedProperties";
import { AdvancedPropertiesSelector } from "../AdvancedProperties";
import { MarketAmount } from "../../../../models/MarketAmount";

export interface BuyXGetYFormProps {
    market: Market | null
    markets: string[]
    currentLanguage: () => LanguageCode | null
    productObserver: ProductObserver
    tagObserver: TagObserver
    attributeObserver: AttributeObserver
    customerAttributeObserver: AttributeObserver
    template: BuyXGetYTemplate
    onTemplateChanged: (template: RuleModel) => void
}

export function BuyXGetYForm(props: BuyXGetYFormProps) {

    function setDisplayName(name?: L10nString) {
        if (_.isEqual(props.template.display_name, name)) {
            return
        }
        const clone = _.clone(props.template)
        clone.display_name = name
        props.onTemplateChanged(clone)
    }

    function setDiscountType(type: DiscountType, percentage?: number, amount?: MarketAmount) {
        if (props.template.discountType === type && percentage === props.template.discountPercentage && amount === props.template.discountAmount) {
            return
        }
        const clone = _.clone(props.template)
        clone.discountType = type
        clone.discountPercentage = percentage
        clone.discountAmount = amount
        props.onTemplateChanged(clone)
    }

    function setAppliesTo(appliesTo: AppliesTo) {
        if (_.isEqual(props.template.appliesTo, appliesTo)) {
            return
        }
        const clone = _.clone(props.template)
        clone.appliesTo = appliesTo
        props.onTemplateChanged(clone)
    }

    function setCustomerCondition(condition: CustomerCondition) {
        if (_.isEqual(props.template.customerCondition, condition)) {
            return
        }
        const clone = _.clone(props.template)
        clone.customerCondition = condition
        props.onTemplateChanged(clone)
    }

    function setPriority(priority: number | undefined) {
        if (props.template.priority === priority) {
            return
        }
        const clone = _.clone(props.template)
        clone.priority = priority
        props.onTemplateChanged(clone)
    }

    function setStartDate(date?: DateComponents) {
        if (_.isEqual(props.template.start_date, date)) {
            return
        }
        const clone = _.clone(props.template)
        clone.start_date = date
        props.onTemplateChanged(clone)
    }

    function setEndDate(date?: DateComponents) {
        if (_.isEqual(props.template.end_date, date)) {
            return
        }
        const clone = _.clone(props.template)
        clone.end_date = date
        props.onTemplateChanged(clone)
    }

    function setContinueEvaluation(continueEvaluation?: boolean) {
        if (props.template.continue_evaluation === continueEvaluation) {
            return
        }
        const clone = _.clone(props.template)
        clone.continue_evaluation = continueEvaluation
        props.onTemplateChanged(clone)
    }

    function setXAndY(triggerCount?: number, applicationCount?: number) {
        if (props.template.triggerCount === triggerCount && props.template.applicationCount === applicationCount) {
            return
        }
        const clone = _.clone(props.template)
        clone.triggerCount = triggerCount
        clone.applicationCount = applicationCount
        props.onTemplateChanged(clone)
    }

    return <Form horizontal={true} onSubmit={e => e.preventDefault()}>
        <SharedPropertiesSelector validation={props.template.validateShared()} currentLanguage={props.currentLanguage} displayName={props.template.display_name} priority={props.template.priority} updateSharedProps={(displayName, priority) => { setDisplayName(displayName); setPriority(priority) }} />
        <BuyXGetYPanel validation={props.template.validateXAndY()} triggerCount={props.template.triggerCount} applicationCount={props.template.applicationCount} onChange={(triggerCount, applicationCount) => { setXAndY(triggerCount, applicationCount) }} />
        <DiscountSelector markets={props.markets} validation={props.template.validateDiscount()} showDiscountValue={true} market={props.market ?? undefined} discountAmount={props.template.discountAmount} discountPercentage={props.template.discountPercentage} type={props.template.discountType} typeChanged={(type, percentage, amount) => { setDiscountType(type, percentage, amount) }} />
        <AppliesToSelectorPanel validation={props.template.validateAppliesTo(props.template.appliesTo)} productObserver={props.productObserver} tagsObserver={props.tagObserver} attributesObserver={props.attributeObserver} appliesTo={props.template.appliesTo} appliesToChanged={(appliesTo) => { setAppliesTo(appliesTo) }} />
        <CustomerConditionSelector validation={props.template.validateCustomerCondition()} attributesObserver={props.customerAttributeObserver} condition={props.template.customerCondition} conditionChanged={(condition) => { setCustomerCondition(condition) }} />
        <AdvancedPropertiesSelector continueEvaluation={props.template.continue_evaluation} startDate={props.template.start_date} endDate={props.template.end_date} updateAdvancedProps={(startDate, endDate, continueEvaluation) => { setStartDate(startDate); setEndDate(endDate); setContinueEvaluation(continueEvaluation) }} />
        <Description validation={props.template.valid([])} formProps={props} customerCondition={props.template.customerCondition} market={props.market} discountType={props.template.discountType} appliesTo={props.template.appliesTo} />
    </Form>
}

interface BuyXGetYPanelProps {
    triggerCount?: number
    applicationCount?: number
    onChange: (triggerCount?: number, applicationCount?: number) => void
    validation: boolean
}

export function BuyXGetYPanel(props: BuyXGetYPanelProps) {
    return <Panel bsStyle="primary">
        <Panel.Heading>
            <Panel.Title>Buy X get Y</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
            <HelpBlock>When buying X of the matching products apply a discount to the Y cheapest</HelpBlock>
            <FormGroup style={{ marginLeft: "0px", marginRight: "0px" }}
                validationState={props.validation ? null : "error"}
            >
                <ControlLabel>Trigger count (X)</ControlLabel>
                <FormControl
                    type="number"
                    name="triggerCount"
                    min={0}
                    step={10}
                    value={props.triggerCount ?? ""}
                    placeholder="Enter trigger count (X)"
                    onChange={(e: any) => { props.onChange(e.target.value === "" ? undefined : Number(e.target.value), props.applicationCount); }} />
                <ControlLabel>Application count (Y)</ControlLabel>
                <FormControl
                    type="number"
                    name="applicationCount"
                    min={0}
                    step={10}
                    value={props.applicationCount ?? ""}
                    placeholder="Enter application count (Y)"
                    onChange={(e: any) => { props.onChange(props.triggerCount, e.target.value === "" ? undefined : Number(e.target.value)); }} />
            </FormGroup>
        </Panel.Body>
    </Panel>
}