import * as React from "react"
import { Button, Col, Form, FormGroup, Modal, Table } from "react-bootstrap"
import * as _ from "lodash"
import { Role } from "../../config/role"
import ImageDropControl from "../ImageDropControl"
import { ProductAsset, imageAssetMimeTypeFromFile } from "../../models/ProductAsset"

const pushid = require("pushid")

interface ProductGalleryEditProps {
    assets: () => ProductAsset[]
    role: Role
    productId: string
    closeDialog: () => any
    updateState: (update: any) => any
}

export class ProductGalleryEdit extends React.Component<ProductGalleryEditProps, any> {

    // BG: Couldn't find a way to reset the dropzone component.
    // Incremented on succesfull image upload to make react think that we have a new dropzone component.
    dropzoneComponentKey = 1

    closeDialog = () => {
        this.props.closeDialog()
    }

    addImageAsset(url: string, file: any) {
        const mimeType = imageAssetMimeTypeFromFile(file)
        if (_.isNil(url) || _.isNil(mimeType)) {
            return
        }

        const asset = new ProductAsset(url, mimeType)

        const assets = _.cloneDeep(this.props.assets())
        assets.push(asset)

        this.dropzoneComponentKey += 1
        this.props.updateState(assets)
    }

    removeImageAsset(index: number) {
        const assets = _.cloneDeep(this.props.assets())
        assets.splice(index, 1)
        this.props.updateState(assets)
    }

    // React component

    render() {
        return (
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>
                        <span>Edit image assets</span>
                    </Modal.Title>
                </Modal.Header>

                <Form horizontal={true} onSubmit={(e: any) => e.preventDefault()}>
                    <FormGroup>
                        <Col sm={12}>
                            <Table striped={true} bordered={true} condensed={true} style={{ cursor: "pointer" }} hover={true}>
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.assets().filter((asset) => { return asset.isImageAsset() }).map((asset: ProductAsset, index: any) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ "verticalAlign": "middle" }}>
                                                        <img key="img" src={asset.url} alt="" height="150" />
                                                    </td>
                                                    <td style={{ "verticalAlign": "middle", textAlign: "center", "width": "1%" }}>
                                                        <Button
                                                            bsStyle="danger"
                                                            key={index}
                                                            onClick={(event: any) => {
                                                                this.removeImageAsset(index)
                                                                event.stopPropagation()
                                                            }}
                                                        >
                                                            X
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    <tr>
                                        <td style={{ "verticalAlign": "middle" }}>
                                            <ImageDropControl
                                                key={this.dropzoneComponentKey}
                                                fileName={pushid()}
                                                filePath={this.props.role.account_id + "/public/products/" + this.props.productId + "/assets/"}
                                                dropTitle="Add new image (Max size: 2048×2048)"
                                                validateSize={(width: number, height: number) => {
                                                    return width <= 2048 && height <= 2048
                                                }}
                                                isPublic={true}
                                                deleteAction={() => { /* dummy */ }}
                                                uploadAction={(url, file) => { this.addImageAsset(url, file) }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </FormGroup>
                </Form>

                <Modal.Footer>
                    <Button onClick={this.closeDialog}>Done</Button>
                </Modal.Footer>

            </Modal.Dialog>
        )
    }
}
