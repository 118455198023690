import * as React from "react"
import { Facet } from "../../../models/Facet"
import {
    Button,
    Panel
} from "react-bootstrap"
import { ConfirmDeleteButton } from "../../ConfirmDeleteButton"
import { forEach } from "lodash"
import { LanguageCode } from "../../../helpers/L10n"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom"
import { StripedTable } from "../../StripedTable"

interface FacetListProps extends RouteComponentProps<any> {
    role: Role
}

interface FacetListState {
    facets: Facet[]
    currentLanguage: LanguageCode | null
    loaded: boolean
}

class FacetList extends React.Component<FacetListProps, FacetListState> {

    // Properties

    initialState: FacetListState = {
        facets: [],
        currentLanguage: LanguageCode.en,
        loaded: false
    }

    // Component

    constructor(props: FacetListProps) {
        super(props)

        this.state = this.initialState
    }

    componentDidMount() {
        this.setState({ loaded: false })

        this.loadState()
    }

    componentWillUnmount() {
        this.facetsRef().off()
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="Facets">
                <Panel>
                    <Panel.Heading>
                        <Button onClick={this.newFacet}>New facet</Button>
                    </Panel.Heading>
                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.facets.map(facet => {
                                    return (
                                        <tr key={facet.id} onClick={() => { this.editAttribute(facet.id) }}>
                                            <td>{facet.name!.localized(this.state.currentLanguage) || facet.name!.value}</td>
                                            <td className="narrow">
                                                <ConfirmDeleteButton
                                                    message={`Really delete ${facet.name!.localized(this.state.currentLanguage)} facet?`}
                                                    onDelete={async () => { await this.removeAttribute(facet.id) }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </StripedTable>
                    <Panel.Footer>
                        <Button onClick={this.newFacet}>New facet</Button>
                    </Panel.Footer>
                </Panel>
            </PageState>
        )
    }

    // State

    loadState() {
        this.facetsRef()
            .on("value", snapshot => {
                if (!snapshot || (snapshot && !snapshot.exists())) {
                    this.setState({ facets: [], loaded: true })
                    return
                }

                const attributes: Facet[] = []
                forEach(snapshot.val(), (value) => {
                    const attribute = new Facet(value)
                    attributes.push(attribute)
                })

                const sorted = attributes.sort((lhs, rhs) => {
                    return lhs.name!.localized(this.state.currentLanguage) < rhs.name!.localized(this.state.currentLanguage) ? -1 : 1
                })

                this.setState({
                    facets: sorted,
                    loaded: true
                })
            })
    }

    // Attribute

    newFacet = () => {
        this.props.history.push("/facet/new")
    }

    editAttribute = (key: string) => {
        this.props.history.push(`/facet/${key}`)
    }

    async removeAttribute(key: string) {
        await this.facetsRef().child(key).set(null)
    }

    // Helpers

    facetsRef() {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/facets")
    }
}

export default withRouter(FacetList)