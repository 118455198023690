import * as _ from "lodash"
import * as React from "react"
import * as RuleModels from "../../../models/RuleModels"
import moment from "moment"
import Numeral from "numeral"
import {
    Button,
    Checkbox,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup
} from "react-bootstrap"
import { ConfirmDeleteButton } from "../../ConfirmDeleteButton"
import { DateRangePicker } from "react-dates"
import { DecimalValueFormControl } from "../../DecimalValueFormControl"
import { DeleteButton } from "../../DeleteButton"
import { L10nFormControl } from "../../L10nFormControl"
import {
    L10nString,
    LanguageCode
} from "../../../helpers/L10n"
import { Market } from "../../../models/MarketModels"
import { MarketAmount } from "../../../models/MarketAmount"
import { MarketAmountFormControl } from "../../MarketAmountFormControl"
import {
    ModalPicker,
    ModalPickerElement
} from "../../ModalPicker"
import { Product } from "../../../models/Product"
import { ProductObserver } from "../../../helpers/productObserver"
import { Role } from "../../../config/role"
import { StripedTable } from "../../StripedTable"
import { TagObserver } from "../../../helpers/tagObserver"

class ProductElement implements ModalPickerElement {
    id: string
    name: string

    constructor(product: Product) {
        this.id = product.id
        this.name = product.localizedName(LanguageCode.da) || ""
    }
}

interface RuleTemplateFormProps {
    role: Role
    customerLookupEnabled: boolean
    template: RuleModels.LegacyRuleModel
    productObserver: ProductObserver
    tagsObserver: TagObserver
    currentMarket: Market | null
    selectedMarkets: string[]
    onTemplateChanged: (template: RuleModels.RuleModel) => void
    currentLanguage: () => LanguageCode | null
    resolvedCurrency: () => string
}

interface RuleTemplateFormState {
    template: RuleModels.LegacyRuleModel
    isPickerVisible: boolean
    focusedInput?: any
    startDate: moment.Moment | null
    endDate: moment.Moment | null
}

export function momentFromDateComponents(comps: RuleModels.DateComponents): moment.Moment {
    const momentString = `${comps.year}-${comps.month}-${comps.day}`
    return moment(momentString, "YYYY-M-D")
}

export class RuleTemplateFormComponent extends React.Component<RuleTemplateFormProps, RuleTemplateFormState> {

    // Lifecycle

    constructor(props: RuleTemplateFormProps) {
        super(props)

        const template = props.template
        let startDate: moment.Moment | null = null
        if (template.start_date) {
            startDate = momentFromDateComponents(template.start_date)
        }
        let endDate: moment.Moment | null = null
        if (template.end_date) {
            endDate = momentFromDateComponents(template.end_date)
        }

        template.setMarkets(props.selectedMarkets)
        this.state = {
            template: template,
            isPickerVisible: false,
            startDate: startDate,
            endDate: endDate
        }
    }

    componentWillReceiveProps(nextProps: RuleTemplateFormProps) {
        this.state.template.setMarkets(nextProps.selectedMarkets)
    }

    // Component

    render() {
        const showPicker = () => { this.setState({ isPickerVisible: true }) }
        const cancelPicker = () => { this.setState({ isPickerVisible: false }) }
        const explanation: JSX.Element | null = this.explanation()

        return (
            <section>
                {
                    this.state.isPickerVisible ? (
                        <ModalPicker
                            elements={this.elementsForPicker()}
                            onCancel={cancelPicker}
                            onSelect={(element) => {
                                this.pickerSelectedElement(element)
                                cancelPicker()
                            }}
                            title="New rule"
                        />
                    ) : null
                }

                <Form horizontal={true} onSubmit={e => e.preventDefault()}>

                    <FormGroup
                        validationState={this.state.template.validateField("name") ? null : "error"}
                    >
                        <Col componentClass={ControlLabel} sm={2}>Name</Col>
                        <Col sm={10}>
                            <FormControl
                                type="text"
                                name="name"
                                value={this.state.template.name || ""}
                                placeholder="Enter name"
                                onChange={this.nameChanged}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup
                        validationState={this.state.template.validateField("display_name") ? null : "error"}
                    >
                        <Col componentClass={ControlLabel} sm={2}>Display name</Col>
                        <Col sm={10}>
                            <L10nFormControl
                                l10n={this.state.template.display_name || null}
                                language={this.props.currentLanguage()}
                                onLocalizationChanged={l10n => { this.displayNameChanged(l10n) }}
                                placeholder="Enter name as it should be shown on receipt"
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup
                        validationState={this.state.template.validateField("priority") ? null : "error"}
                    >
                        <Col componentClass={ControlLabel} sm={2}>Priority</Col>
                        <Col sm={10}>
                            <FormControl
                                type="number"
                                name="priority"
                                min={0}
                                step={10}
                                value={this.state.template.priority || ""}
                                placeholder="Enter priority"
                                onChange={this.priorityChanged}
                            />
                        </Col>
                    </FormGroup>

                    <br />
                    <br />

                    {this.state.template.containsField("count") ? (
                        <FormGroup
                            validationState={this.state.template.validateField("count") ? null : "error"}
                        >
                            <Col componentClass={ControlLabel} sm={2}>Count</Col>
                            <Col sm={10}>
                                <FormControl
                                    type="number"
                                    name="count"
                                    min={0}
                                    step={1}
                                    value={this.state.template.valueForField("count") || ""}
                                    placeholder="Enter count"
                                    onChange={this.countChanged}
                                />
                            </Col>
                        </FormGroup>
                    ) : null
                    }

                    {this.state.template.containsField("percentage") ? (
                        <FormGroup
                            validationState={this.state.template.validateField("percentage") ? null : "error"}
                        >
                            <Col componentClass={ControlLabel} sm={2}>Percentage</Col>
                            <Col sm={10}>
                                <DecimalValueFormControl
                                    value={this.state.template.valueForField("percentage") ? Math.round(this.state.template.valueForField("percentage") * 1000) / 10 : 0}
                                    onValueChanged={this.percentageChanged}
                                    decimals={1}
                                    prefixSymbol="%"
                                    placeholder="Enter percentage"
                                />
                            </Col>
                        </FormGroup>
                    ) : null
                    }

                    {this.state.template.containsField("percentage_stair") ? (
                        <FormGroup
                            validationState={this.state.template.validateField("percentage_stair") ? null : "error"}
                        >
                            <Col componentClass={ControlLabel} sm={2}>Percentage stair</Col>
                            <Col sm={10}>
                                <StripedTable>
                                    <tbody>
                                        {(this.state.template.percentage_stair || []).map((step, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>When buying:</td>
                                                    <td>
                                                        <FormControl
                                                            type="number"
                                                            name="count"
                                                            min={0}
                                                            step={1}
                                                            value={step.count || ""}
                                                            placeholder="Count"
                                                            onChange={(event: any) => {
                                                                const count = Number(event.target.value)
                                                                this.percentageStepChanged(count, step.percentage, index)
                                                            }}
                                                        />
                                                    </td>
                                                    <td>the discount is:</td>
                                                    <td>
                                                        <DecimalValueFormControl
                                                            value={step.percentage ? Math.round(step.percentage * 1000) / 10 : 0}
                                                            onValueChanged={value => {
                                                                const percentage = Number(value) / 100
                                                                this.percentageStepChanged(step.count, percentage || undefined, index)
                                                            }}
                                                            decimals={1}
                                                            prefixSymbol="%"
                                                            placeholder="Enter percentage"
                                                        />
                                                    </td>
                                                    <td>
                                                        <ConfirmDeleteButton
                                                            message={`Really delete step?`}
                                                            onDelete={() => {
                                                                this.removePercentageStep(index)
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {(this.state.template.percentage_stair || []).length === 0 ? <tr><td>Please add some steps to the stair</td></tr> : null}
                                    </tbody>
                                </StripedTable>
                                <Button onClick={() => { this.addPercentageStep() }}>Add step</Button>
                            </Col>
                        </FormGroup>
                    ) : null
                    }

                    {this.state.template.containsField("percentage_basket_total_stair") ? (
                        <FormGroup
                            validationState={this.state.template.validateField("percentage_basket_total_stair", this.props.selectedMarkets) ? null : "error"}
                        >
                            <Col componentClass={ControlLabel} sm={2}>Percentage stair based on basket total</Col>
                            <Col sm={10}>
                                <StripedTable>
                                    <tbody>
                                        {(this.state.template.percentage_basket_total_stair || []).map((step, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>When the basket total exceeds:</td>
                                                    <td>
                                                        <MarketAmountFormControl
                                                            allMarkets={this.props.selectedMarkets}
                                                            amount={step.amount ?? null}
                                                            market={this.props.currentMarket}
                                                            onAmountChanged={amount => {
                                                                this.percentageBasketTotalStepChanged(amount, step.percentage, index)
                                                            }}
                                                            placeholder="Enter basket total"
                                                            currency={this.props.resolvedCurrency()}
                                                        />
                                                    </td>
                                                    <td>the discount is:</td>
                                                    <td>
                                                        <DecimalValueFormControl
                                                            value={step.percentage ? Math.round(step.percentage * 1000) / 10 : 0}
                                                            onValueChanged={value => {
                                                                const percentage = Number(value) / 100
                                                                this.percentageBasketTotalStepChanged(step.amount || null, percentage || undefined, index)
                                                            }}
                                                            decimals={1}
                                                            prefixSymbol="%"
                                                            placeholder="Enter percentage"
                                                        />
                                                    </td>
                                                    <td>
                                                        <ConfirmDeleteButton
                                                            message={`Really delete step?`}
                                                            onDelete={() => {
                                                                this.removePercentageBasketTotalStep(index)
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {(this.state.template.percentage_basket_total_stair || []).length === 0 ? <tr><td>Please add some steps to the stair</td></tr> : null}
                                    </tbody>
                                </StripedTable>
                                <Button onClick={() => { this.addPercentageBasketTotalStep() }}>Add step</Button>
                            </Col>
                        </FormGroup>
                    ) : null
                    }

                    {this.state.template.containsField("new_price") ? (
                        <FormGroup validationState={this.state.template.validateField("new_price", this.props.selectedMarkets) ? null : "error"}>
                            <Col componentClass={ControlLabel} sm={2}>New price</Col>
                            <Col sm={10}>
                                <MarketAmountFormControl
                                    allMarkets={this.props.selectedMarkets}
                                    amount={this.state.template.valueForField("new_price") ?? null}
                                    market={this.props.currentMarket}
                                    onAmountChanged={amount => {
                                        this.newPriceChanged(amount)
                                    }}
                                    placeholder="Enter new price"
                                    currency={this.props.resolvedCurrency()}
                                />
                            </Col>
                        </FormGroup>
                    ) : null
                    }

                    {this.state.template.containsField("amount_condition") ? (
                        <FormGroup validationState={this.state.template.validateField("amount_condition", this.props.selectedMarkets) ? null : "error"}>
                            <Col componentClass={ControlLabel} sm={2}>Total</Col>
                            <Col sm={10}>
                                <MarketAmountFormControl
                                    allMarkets={this.props.selectedMarkets}
                                    amount={this.state.template.valueForField("amount_condition") ?? null}
                                    market={this.props.currentMarket}
                                    onAmountChanged={amount => {
                                        this.amountConditionChanged(amount)
                                    }}
                                    placeholder="Enter amount"
                                    currency={this.props.resolvedCurrency()}
                                />
                            </Col>
                        </FormGroup>
                    ) : null
                    }

                    {this.state.template.containsField("new_price_stair") ? (
                        <FormGroup validationState={this.state.template.validateField("new_price_stair", this.props.selectedMarkets) ? null : "error"} >
                            <Col componentClass={ControlLabel} sm={2}>Price stair</Col>
                            <Col sm={10}>
                                <StripedTable>
                                    <tbody>
                                        {(this.state.template.new_price_stair || []).map((step, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        When buying:
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            type="number"
                                                            name="count"
                                                            min={0}
                                                            step={1}
                                                            value={step.count || ""}
                                                            placeholder="Count"
                                                            onChange={(event: any) => {
                                                                const count = Number(event.target.value)
                                                                this.newPriceStepChanged(count, step.new_price, index)
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        the price is:
                                                    </td>
                                                    <td>
                                                        <MarketAmountFormControl
                                                            allMarkets={this.props.selectedMarkets}
                                                            amount={step.new_price ?? null}
                                                            market={this.props.currentMarket}
                                                            onAmountChanged={amount => {
                                                                this.newPriceStepChanged(step.count, amount ?? undefined, index)
                                                            }}
                                                            placeholder="Enter new price"
                                                            currency={this.props.resolvedCurrency()}
                                                        />
                                                    </td>
                                                    <td>
                                                        <ConfirmDeleteButton
                                                            message={`Really delete step?`}
                                                            onDelete={() => {
                                                                this.removeNewPriceStep(index)
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {(this.state.template.new_price_stair || []).length === 0 ? <tr><td>Please add some steps to the stair</td></tr> : null}
                                    </tbody>
                                </StripedTable>
                                <Button onClick={() => { this.addNewPriceStep() }}>Add step</Button>
                            </Col>
                        </FormGroup>
                    ) : null
                    }

                    {this.state.template.containsField("amount_stair") ? (
                        <FormGroup validationState={this.state.template.validateField("amount_stair", this.props.selectedMarkets) ? null : "error"} >
                            <Col componentClass={ControlLabel} sm={2}>Amount off stair</Col>
                            <Col sm={10}>
                                <StripedTable>
                                    <tbody>
                                        {(this.state.template.amount_stair || []).map((step, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        When buying:
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            type="number"
                                                            name="count"
                                                            min={0}
                                                            step={1}
                                                            value={step.count || ""}
                                                            placeholder="Count"
                                                            onChange={(event: any) => {
                                                                const count = Number(event.target.value)
                                                                this.amountStepChanged(count, step.amount, index)
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        the price is reduced by:
                                                    </td>
                                                    <td>
                                                        <MarketAmountFormControl
                                                            allMarkets={this.props.selectedMarkets}
                                                            amount={step.amount ?? null}
                                                            market={this.props.currentMarket}
                                                            onAmountChanged={amount => {
                                                                this.amountStepChanged(step.count, amount ?? undefined, index)
                                                            }}
                                                            placeholder="Enter amount off"
                                                            currency={this.props.resolvedCurrency()}
                                                        />
                                                    </td>
                                                    <td>
                                                        <ConfirmDeleteButton
                                                            message={`Really delete step?`}
                                                            onDelete={() => {
                                                                this.removeAmountStep(index)
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {(this.state.template.amount_stair || []).length === 0 ? <tr><td>Please add some steps to the stair</td></tr> : null}
                                    </tbody>
                                </StripedTable>
                                <Button onClick={() => { this.addAmountStep() }}>Add step</Button>
                            </Col>
                        </FormGroup>
                    ) : null
                    }

                    {this.state.template.containsField("only_if_cheaper") ? (
                        <FormGroup validationState={this.state.template.validateField("only_if_cheaper") ? null : "error"} >
                            <Col componentClass={ControlLabel} sm={2}>Only if cheaper</Col>
                            <Col sm={10}>
                                <Checkbox
                                    checked={(this.state.template.valueForField("only_if_cheaper") || false)}
                                    onChange={this.onlyIfCheaperChanged}
                                >
                                    Only use new price if it is cheaper than the original?
                                </Checkbox>
                            </Col>
                        </FormGroup>
                    ) : null
                    }

                    {this.state.template.containsField("product_id") ? (
                        this.state.template.valueForField("product_id") ? (
                            <div key="product_id">
                                <FormGroup validationState={this.state.template.validateField("product_id") ? null : "error"} >
                                    <Col componentClass={ControlLabel} sm={2}>Product</Col>
                                    <Col sm={10}>
                                        {this.productNameForId(this.state.template.valueForField("product_id"))}
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2} />
                                    <Col sm={10}>
                                        <Button onClick={showPicker}>Change product</Button>
                                    </Col>
                                </FormGroup>
                            </div>
                        ) : (
                            <FormGroup validationState={this.state.template.validateField("product_id") ? null : "error"} >
                                <Col componentClass={ControlLabel} sm={2}>Product</Col>
                                <Col sm={10}>
                                    <Button onClick={showPicker}>Select product</Button>
                                </Col>
                            </FormGroup>
                        )
                    ) : null
                    }

                    {this.state.template.containsField("tag") ? (
                        this.state.template.valueForField("tag") ? (
                            <div key="tag">
                                <FormGroup
                                    validationState={this.state.template.validateField("tag") ? null : "error"}
                                >
                                    <Col componentClass={ControlLabel} sm={2}>Tag</Col>
                                    <Col sm={10}>
                                        {this.tagNameForId(this.state.template.valueForField("tag"))}
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2} />
                                    <Col sm={10}>
                                        <Button onClick={showPicker}>Change tag</Button>
                                    </Col>
                                </FormGroup>
                            </div>
                        ) : (
                            <FormGroup validationState={this.state.template.validateField("tag") ? null : "error"} >
                                <Col componentClass={ControlLabel} sm={2}>Tag</Col>
                                <Col sm={10}>
                                    <Button onClick={showPicker}>Select tag</Button>
                                </Col>
                            </FormGroup>
                        )
                    ) : null
                    }

                    {this.state.template.containsField("product_ids") ? (
                        this.state.template.valueForField("product_ids") ? (
                            <div key="product_ids">
                                <FormGroup validationState={this.state.template.validateField("product_ids") ? null : "error"} >
                                    <Col componentClass={ControlLabel} sm={2}>Products</Col>
                                    <Col sm={10}>
                                        <StripedTable>
                                            <tbody>
                                                {
                                                    (this.state.template.valueForField("product_ids") || []).map((productId: string, index: number) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{this.productNameForId(productId)}</td>
                                                                <td className="narrow"><DeleteButton onDelete={() => { this.removeProduct(productId) }} /></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </StripedTable>
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2} />
                                    <Col sm={10}>
                                        <Button onClick={showPicker}>Add product</Button>
                                    </Col>
                                </FormGroup>
                            </div>
                        ) : (
                            <FormGroup validationState={this.state.template.validateField("product_ids") ? null : "error"} >
                                <Col componentClass={ControlLabel} sm={2}>Product</Col>
                                <Col sm={10}>
                                    <Button onClick={showPicker}>Add product</Button>
                                </Col>
                            </FormGroup>
                        )
                    ) : null
                    }

                    {
                        this.props.customerLookupEnabled ? (
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Membership</Col>
                                <Col sm={10}>
                                    <Checkbox
                                        checked={(this.state.template.members_only || false)}
                                        onChange={this.membershipChanged}
                                    >
                                        Apply to members only?
                                    </Checkbox>
                                </Col>
                            </FormGroup>
                        ) : null
                    }

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>Optional time limit</Col>
                        <Col sm={10}>
                            <DateRangePicker
                                startDateId="startDate"
                                endDateId="endDate"
                                minimumNights={0}
                                isOutsideRange={() => { return false }}
                                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                onDatesChange={(selection: any) => {
                                    const startDate: moment.Moment = selection.startDate
                                    const endDate: moment.Moment = selection.endDate
                                    this.updateTemplate(template => {
                                        if (!_.isNil(startDate)) {
                                            template.start_date = {
                                                year: startDate.year(),
                                                month: startDate.month() + 1,
                                                day: startDate.date()
                                            }
                                        } else {
                                            delete template.start_date
                                        }
                                        if (!_.isNil(endDate)) {
                                            template.end_date = {
                                                year: endDate.year(),
                                                month: endDate.month() + 1,
                                                day: endDate.date()
                                            }
                                        } else {
                                            delete template.end_date
                                        }
                                    })
                                    this.setState({ startDate, endDate }
                                    )
                                }} // PropTypes.func.isRequired,
                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={(focusedInput: any) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                            />
                        </Col>
                    </FormGroup>

                    {RuleModels.typeToRequiresContinueEvaluation[this.state.template.templateType] !== true ? (
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>Advanced</Col>
                            <Col sm={10}>
                                <Checkbox
                                    checked={(this.state.template.continue_evaluation || false)}
                                    onChange={this.continueEvaluationChanged}
                                >
                                    Continue evaluation of other rules after this one applies? <br />
                                    Note that this allows multiple discounts on the same items. <br />
                                    Consider carefully if you really want this.
                                </Checkbox>
                                {
                                    RuleModels.showShowBasketScopeCheckbox(this.state.template.templateType)
                                        ?
                                        (
                                            <Checkbox
                                                checked={(this.state.template.scope === "basket")}
                                                onChange={this.scopeChanged}
                                            >
                                                Basket level application? <br />
                                                Enabling this will cause the following in Ka-ching POS: <br />
                                                - The application will happen after all line based discounts have been applied. <br />
                                                - Ka-ching POS will show the discount at basket level and not on the line where it's actually applied.
                                            </Checkbox>
                                        )
                                        :
                                        null
                                }
                            </Col>
                        </FormGroup>
                    ) : null
                    }

                    {
                        explanation ? (
                            <FormGroup>
                                <Col componentClass={ControlLabel} sm={2}>Explanation</Col>
                                <Col sm={10}>{explanation}</Col>
                            </FormGroup>
                        ) : null
                    }

                </Form>

            </section >
        )
    }

    // Methods

    elementsForPicker = (): ModalPickerElement[] => {
        if (this.state.template.containsField("product_id") || this.state.template.containsField("product_ids")) {
            if (!this.props.productObserver.productsArray) {
                return []
            }
            return this.props.productObserver.productsArray.map((product: Product) => {
                return new ProductElement(product)
            })
        } else if (this.state.template.containsField("tag")) {
            if (!this.props.tagsObserver.tagsArray) {
                return []
            }
            return this.props.tagsObserver.tagsArray.map(tag => {
                return { id: tag.tag, name: tag.name.localized(LanguageCode.da) }
            })
        }
        return []
    }

    productNameForId = (id: string): string | null => {
        if (!this.props.productObserver.productsDict) {
            return null
        }
        const product = this.props.productObserver.productsDict[id]
        return product?.localizedName(LanguageCode.da)
    }

    tagNameForId = (id: string): string | null => {
        if (!this.props.tagsObserver.tagsDict) {
            return id
        }
        const tag = this.props.tagsObserver.tagsDict[id]
        return tag?.name.localized(LanguageCode.da) ?? id
    }

    nameChanged = (event: any) => {
        this.updateTemplate(template => {
            const value = event.target.value
            template.name = value === "" ? null : value
        })
    }

    displayNameChanged = (l10n: L10nString | null) => {
        this.updateTemplate(template => {
            template.display_name = l10n || undefined
        })
    }

    countChanged = (event: any) => {
        this.updateTemplate(template => {
            const value = event.target.value
            template["count"] = Number(value)
        })
    }

    percentageChanged = (value: number | null) => {
        this.updateTemplate(template => {
            template["percentage"] = Number(value) / 100
        })
    }

    newPriceStepChanged = (count: number | undefined, amount: MarketAmount | undefined, index: number) => {
        this.updateTemplate(template => {
            const steps = template.new_price_stair || []
            const step = steps[index]
            if (!_.isNil(count)) {
                step.count = count
            } else {
                delete step.count
            }
            if (!_.isNil(amount)) {
                step.new_price = amount
            } else {
                delete step.new_price
            }
        })
    }

    removeNewPriceStep = (index: number) => {
        this.updateTemplate(template => {
            const steps = template.new_price_stair || []
            steps.splice(index, 1)
        })
    }

    addNewPriceStep = () => {
        this.updateTemplate(template => {
            const steps = template.new_price_stair || []
            const step = new RuleModels.NewPriceStairStep()
            steps.push(step)
            template.new_price_stair = steps
        })
    }

    amountStepChanged = (count: number | undefined, amount: MarketAmount | undefined, index: number) => {
        this.updateTemplate(template => {
            const steps = template.amount_stair || []
            const step = steps[index]
            if (!_.isNil(count)) {
                step.count = count
            } else {
                delete step.count
            }
            if (!_.isNil(amount)) {
                step.amount = amount
            } else {
                delete step.amount
            }
        })
    }

    removeAmountStep = (index: number) => {
        this.updateTemplate(template => {
            const steps = template.amount_stair || []
            steps.splice(index, 1)
        })
    }

    addAmountStep = () => {
        this.updateTemplate(template => {
            const steps = template.amount_stair || []
            const step = new RuleModels.AmountStairStep()
            steps.push(step)
            template.amount_stair = steps
        })
    }

    percentageStepChanged = (count: number | undefined, percentage: number | undefined, index: number) => {
        this.updateTemplate(template => {
            const steps = template.percentage_stair || []
            const step = steps[index]
            if (!_.isNil(count)) {
                step.count = count
            } else {
                delete step.count
            }
            if (!_.isNil(percentage)) {
                step.percentage = percentage
            } else {
                delete step.percentage
            }
        })
    }

    removePercentageStep = (index: number) => {
        this.updateTemplate(template => {
            const steps = template.percentage_stair || []
            steps.splice(index, 1)
        })
    }

    addPercentageStep = () => {
        this.updateTemplate(template => {
            const steps = template.percentage_stair || []
            const step = new RuleModels.PercentageStairStep()
            steps.push(step)
            template.percentage_stair = steps
        })
    }

    percentageBasketTotalStepChanged = (amount: MarketAmount | null, percentage: number | undefined, index: number) => {
        this.updateTemplate(template => {
            const steps = template.percentage_basket_total_stair || []
            const step = steps[index]
            if (!_.isNil(amount)) {
                step.amount = amount
            } else {
                delete step.amount
            }
            if (!_.isNil(percentage)) {
                step.percentage = percentage
            } else {
                delete step.percentage
            }
        })
    }

    removePercentageBasketTotalStep = (index: number) => {
        this.updateTemplate(template => {
            const steps = template.percentage_basket_total_stair || []
            steps.splice(index, 1)
        })
    }

    addPercentageBasketTotalStep = () => {
        this.updateTemplate(template => {
            const steps = template.percentage_basket_total_stair || []
            const step = new RuleModels.PercentageBasketTotalStairStep()
            steps.push(step)
            template.percentage_basket_total_stair = steps
        })
    }

    newPriceChanged = (amount: MarketAmount | null) => {
        this.updateTemplate(template => {
            if (amount === null) {
                delete template["new_price"]
            } else {
                template["new_price"] = amount
            }
        })
    }

    amountConditionChanged = (amount: MarketAmount | null) => {
        this.updateTemplate(template => {
            if (amount === null) {
                delete template["amount_condition"]
            } else {
                template["amount_condition"] = amount
            }
        })
    }

    membershipChanged = (event: any) => {
        this.updateTemplate(template => {
            template.members_only = event.target.checked
        })
    }

    continueEvaluationChanged = (event: any) => {
        this.updateTemplate(template => {
            template.continue_evaluation = event.target.checked
        })
    }

    scopeChanged = (event: any) => {
        this.updateTemplate(template => {
            template.scope = event.target.checked ? "basket" : undefined
        })
    }

    onlyIfCheaperChanged = (event: any) => {
        this.updateTemplate(template => {
            template.only_if_cheaper = event.target.checked
        })
    }

    priorityChanged = (event: any) => {
        this.updateTemplate(template => {
            const value = event.target.value
            template.priority = Number(value)
        })
    }

    pickerSelectedElement = (element: ModalPickerElement) => {
        this.updateTemplate(template => {
            if (template.containsField("product_id")) {
                template["product_id"] = element.id
            } else if (template.containsField("product_ids")) {
                const productIds = template.valueForField("product_ids") || []
                productIds.push(element.id)
                template["product_ids"] = productIds
            } else if (template.containsField("tag")) {
                template["tag"] = element.id
            }
        })
    }

    removeProduct = (productId: string) => {
        const safeProductIds: string[] = this.state.template.valueForField("product_ids") || []
        const index = safeProductIds.indexOf(productId)
        if (typeof index === "number") {
            safeProductIds.splice(index, 1)
        }
        this.updateTemplate(template => {
            template["product_ids"] = safeProductIds
        })
    }

    updateTemplate(closure: ((template: RuleModels.LegacyRuleModel) => void)) {
        const template = _.cloneDeep(this.state.template)
        closure(template)
        this.setState({ template: template })
        this.props.onTemplateChanged(template)
    }

    explanation = (): JSX.Element | null => {
        const fields: RuleModels.RuleProperty[] = ["count", "percentage", "new_price", "product_id", "tag", "amount_condition"]
        for (const field of fields) {
            if (this.state.template.containsField(field) && _.isNil(this.state.template.valueForField(field))) {
                return null
            }
        }

        let conditionString: string = ""
        if (this.state.template.containsField("count")) {
            conditionString = `When ${this.state.template.valueForField("count")} or more items`
        } else if (this.state.template.containsField("amount_condition")) {
            const marketId: string | null = this.props.currentMarket ? this.props.currentMarket.id : null
            conditionString = `When basket total (excluding shipping line items) is ${Numeral(this.state.template.valueForField("amount_condition").amount(marketId)).format("0,0.00")} or more, the shipping is free.`
        } else {
            conditionString = "When items"
        }
        let matchString: string = ""
        const descriptionStrings: string[] = []
        if (this.state.template.containsField("product_id")) {
            const productName = this.productNameForId(this.state.template.valueForField("product_id"))
            matchString = `of ${productName} are added to the basket, all ${productName} items receive`
        } else if (this.state.template.containsField("tag")) {
            const tagName = this.tagNameForId(this.state.template.valueForField("tag"))
            matchString = `that contain the tag '${tagName}' are added to the basket, these items receive`
        } else if (this.state.template.containsField("product_ids")) {
            const productIds = this.state.template.product_ids
            if (_.isNil(productIds)) {
                return null
            }
            const productNames = productIds.map(productId => {
                return this.productNameForId(productId) || productId
            })
            matchString = `in the list: [${productNames.join(", ")}] are added to the basket, all items receive`
        }
        let discountString: string = ""
        if (this.state.template.containsField("percentage")) {
            const percentage = Numeral(this.state.template.valueForField("percentage")).multiply(100).format("0.0")
            discountString = `a discount of ${percentage} percent.`
        } else if (this.state.template.containsField("new_price")) {
            const marketId: string | null = this.props.currentMarket ? this.props.currentMarket.id : null
            const newPrice = Numeral(this.state.template.valueForField("new_price").amount(marketId)).format("0,0.00")
            discountString = `a new price of ${newPrice}.`
        } else if (this.state.template.containsField("new_price_stair")) {
            const marketId: string | null = this.props.currentMarket ? this.props.currentMarket.id : null
            discountString = `a new price based on the item quantity.`
            for (const step of (this.state.template.new_price_stair || [])) {
                if (!_.isNil(step.new_price) && !_.isNil(step.count)) {
                    const newPrice = Numeral(step.new_price.amount(marketId)).format("0,0.00")
                    descriptionStrings.push(`A quantity of ${step.count} or more will result in a price of ${newPrice}.`)
                }
            }
        } else if (this.state.template.containsField("amount_stair")) {
            const marketId: string | null = this.props.currentMarket ? this.props.currentMarket.id : null
            discountString = `an amount off based on the item quantity.`
            for (const step of (this.state.template.amount_stair || [])) {
                if (!_.isNil(step.amount) && !_.isNil(step.count)) {
                    const newPrice = Numeral(step.amount.amount(marketId)).format("0,0.00")
                    descriptionStrings.push(`A quantity of ${step.count} or more will result in a price reduction of ${newPrice}.`)
                }
            }
        } else if (this.state.template.containsField("percentage_stair")) {
            discountString = `a percentage discount based on the item quantity.`
            for (const step of (this.state.template.percentage_stair || [])) {
                if (!_.isNil(step.percentage) && !_.isNil(step.count)) {
                    const percentage = Numeral(step.percentage).multiply(100).format("0.0")
                    descriptionStrings.push(`A quantity of ${step.count} or more will result in a discount of ${percentage} percent.`)
                }
            }
        } else if (this.state.template.containsField("percentage_basket_total_stair")) {
            discountString = `a percentage discount based on the basket total.`
            const marketId: string | null = this.props.currentMarket ? this.props.currentMarket.id : null
            for (const step of (this.state.template.percentage_basket_total_stair || [])) {
                if (!_.isNil(step.percentage) && !_.isNil(step.amount)) {
                    const percentage = Numeral(step.percentage).multiply(100).format("0.0")
                    descriptionStrings.push(`A total of ${Numeral(step.amount.amount(marketId)).format("0,0.00")} or more will result in a discount of ${percentage} percent.`)
                }
            }
        }
        if (this.state.template.only_if_cheaper === true) {
            descriptionStrings.push("The new price will only be applied if it is lower than the original product price.")
        }

        descriptionStrings.unshift(`${conditionString} ${matchString} ${discountString}`)

        const membersOnly = this.state.template.members_only || false
        if (membersOnly) {
            descriptionStrings.push("This discount only applies when a customer is added to the sale.")
        }
        descriptionStrings.push("")

        if (!_.isNil(this.state.template.start_date) && !_.isNil(this.state.template.end_date)) {
            const from = momentFromDateComponents(this.state.template.start_date).format("MMMM Do, YYYY")
            const to = momentFromDateComponents(this.state.template.end_date).format("MMMM Do, YYYY")
            descriptionStrings.push(`The discount runs from ${from} to ${to} - including both dates`)
        } else if (!_.isNil(this.state.template.start_date)) {
            const from = momentFromDateComponents(this.state.template.start_date).format("MMMM Do, YYYY")
            descriptionStrings.push(`The discount runs from ${from} and continues to run until it is deleted`)
        } else if (!_.isNil(this.state.template.end_date)) {
            const to = momentFromDateComponents(this.state.template.end_date).format("MMMM Do, YYYY")
            descriptionStrings.push(`The discount runs to the end of ${to}`)
        } else {
            descriptionStrings.push(`The discount has no time limits and runs until it is deleted.`)
        }

        const today = moment().startOf("day").format("YYYY-MM-DD")
        let expired = false
        let upcoming = false
        if (!_.isNil(this.state.template.end_date)) {
            const endDate = momentFromDateComponents(this.state.template.end_date).format("YYYY-MM-DD")
            if (today > endDate) {
                expired = true
            }
        }
        if (!_.isNil(this.state.template.start_date)) {
            const startDate = momentFromDateComponents(this.state.template.start_date).format("YYYY-MM-DD")
            if (today < startDate) {
                upcoming = true
            }
        }

        const lines = descriptionStrings.map((value, index) => { return <p key={index}>{value}</p> })
        if (upcoming) {
            lines.push(<p key="a"><b>NOTE: This discount is not yet active!</b></p>)
        }
        if (expired) {
            lines.push(<p key="b"><b>NOTE: This discount has expired!</b></p>)
        }
        const continueEvaluation = this.state.template.continue_evaluation || false
        if (continueEvaluation) {
            lines.push(<p key="c"><b>NOTE: After this discount has applied, the line items receiving discounts are legible for other discounts.</b></p>)
        }

        return <span>{lines}</span>
    }
}
