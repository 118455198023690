//
//  RuleEdit.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 28/05/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import { Col, Form, FormGroup, Panel } from "react-bootstrap"
import { LanguagePicker } from "../../LanguagePicker"
import { RuleTemplateFormComponent } from "./RuleTemplateForm"
import { ProductObserver } from "../../../helpers/productObserver"
import { ref } from "../../../config/constants"
import { withRouter, RouteComponentProps } from "react-router"
import * as _ from "lodash"
import * as Models from "../../../models/RuleModels"
import * as React from "react"
import { PageState } from "../../PageState"
import { ChannelSelector } from "../../ChannelSelector"
import { MarketSelector } from "../../MarketSelector"
import { LanguageCode } from "../../../helpers/L10n"
import { Globals } from "../../../helpers/globals"
import { TagObserver } from "../../../helpers/tagObserver"
import { CurrentMarketPicker } from "../../CurrentMarketPicker"
import { Market } from "../../../models/MarketModels"
import { CustomizableForm } from "./Customizable/CustomizableForm"
import { CustomizableTemplate } from "./Customizable/Model"
import { BundleForm } from "./Bundle/BundleForm"
import { BundleTemplate } from "./Bundle/Model"
import { AttributeObserver } from "../../../helpers/attributeObserver"
import { BuyXGetYTemplate } from "./BuyXGetY/Model"
import { BuyXGetYForm } from "./BuyXGetY/BuyXGetYForm"

interface RuleEditProps extends RouteComponentProps<any> {
    role: any
}

interface RuleEditState {
    key?: string
    type: Models.TemplateType
    metadata: Models.Metadata
    markets: Market[]
    currentMarket: Market | null
    template?: Models.RuleModel
    currentLanguage?: LanguageCode
    dirty: boolean
    ruleAndMetadataLoaded: boolean
    productsLoaded: boolean
    tagsLoaded: boolean
    attributesLoaded: boolean
    customerAttributesLoaded: boolean
    publishing: boolean
    customerLookupEnabled: boolean
    selectedMarkets: string[]
}

class RuleEdit extends React.Component<RuleEditProps, RuleEditState> {

    // Properties

    productObserver: ProductObserver
    tagObserver: TagObserver
    attributesObserver: AttributeObserver
    customerAttributesObserver: AttributeObserver

    // Lifecycle

    constructor(props: RuleEditProps) {
        super(props)

        this.productObserver = new ProductObserver(this.props.role.account_id)
        this.productObserver.productsChangedCallback = () => {
            this.setState({ productsLoaded: true })
        }
        this.tagObserver = new TagObserver(this.props.role.account_id)
        this.tagObserver.tagsChangedCallback = () => {
            this.setState({ tagsLoaded: true })
        }
        this.attributesObserver = new AttributeObserver(this.props.role.account_id)
        this.attributesObserver.attributesChangedCallback = () => {
            this.setState({ attributesLoaded: true })
        }

        this.customerAttributesObserver = new AttributeObserver(this.props.role.account_id, "customer_attributes")
        this.customerAttributesObserver.attributesChangedCallback = () => {
            this.setState({ customerAttributesLoaded: true })
        }

        const key = this.props.match.params.ruleKey !== "new" ? this.props.match.params.ruleKey : undefined
        const type = this.extractTypeFromQueryParameter()

        this.state = {
            key: key,
            type: type,
            metadata: new Models.Metadata({}, {}),
            currentLanguage: undefined,
            dirty: false,
            ruleAndMetadataLoaded: false,
            productsLoaded: false,
            tagsLoaded: false,
            publishing: false,
            customerLookupEnabled: false,
            markets: [],
            currentMarket: null,
            selectedMarkets: [],
            attributesLoaded: false,
            customerAttributesLoaded: false
        }
    }

    // Component

    async componentDidMount() {
        this.productObserver.start()
        this.tagObserver.start()
        this.attributesObserver.start()
        this.customerAttributesObserver.start()
        await this.load()
    }

    componentWillUnmount() {
        this.productObserver.stop()
        this.tagObserver.stop()
        this.attributesObserver.stop()
        this.customerAttributesObserver.stop()
    }

    embedInPanel(): boolean {
        return (this.state.type !== Models.TemplateType.Customizable) &&
            (this.state.type !== Models.TemplateType.Bundle)
    }

    render() {
        return (
            <PageState loading={!this.state.productsLoaded || !this.state.ruleAndMetadataLoaded || !this.state.tagsLoaded || !this.state.attributesLoaded || !this.state.customerAttributesLoaded} typeName="rule data" publishing={this.state.publishing} dirty={this.state.dirty} submit_action={() => {
                this.publish()
            }} discard_action={() => {
                (this.props.history as any).goBack()
            }} submit_disabled={!this.state.dirty || !this.state.template || !this.state.template.valid(this.state.selectedMarkets)}>

                <Form onSubmit={e => e.preventDefault()} horizontal={true}>

                    <FormGroup>
                        <Col smOffset={6} sm={6} className="text-right">
                            <CurrentMarketPicker
                                role={this.props.role}
                                currentMarket={this.state.currentMarket}
                                resolveMarkets={() => { return Object.keys(this.state.metadata?.markets ?? {}) }}
                                onChange={market => { this.handleCurrentMarketChange(market) }}
                            />
                            <LanguagePicker
                                typeName="rule"
                                initialLanguage={null}
                                resolveLanguages={() => { return this.resolveLanguages() }}
                                onChange={(language) => { this.setLanguage(language) }}
                                onRemove={(language) => { this.removeLanguage(language) }}
                            />
                        </Col>
                    </FormGroup>
                </Form>

                {this.embedInPanel() ?
                    <Panel>
                        <Panel.Heading>{!this.state.key ? `New discount rule - ${Models.typeToNameMap[this.state.type]}` : `Editing rule - ${Models.typeToNameMap[this.state.type]}`}</Panel.Heading>
                        <Panel.Body>
                            {this.renderBody()}
                        </Panel.Body>
                    </Panel>
                    : this.renderBody()}
            </PageState>
        )
    }

    renderBody() {
        return <div>
            {this.ruleTemplateForm()}

            < br />
            <br />

            <Form onSubmit={e => e.preventDefault()} horizontal={true}>

                <ChannelSelector
                    selectedChannels={Object.keys(this.state.metadata?.channels ?? {})}
                    onChange={this.handleChannelsChange}
                />
                <MarketSelector
                    typeName="rule"
                    selectedMarkets={Object.keys(this.state.metadata?.markets ?? {})}
                    addMarket={this.addMarket}
                    removeMarket={this.removeMarket}
                    showDeleteWarning={false}
                />
            </Form>
        </div>
    }

    // Methods

    async load() {
        const accountId = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${accountId}`)

        let ruleSnapshot: any
        if (this.state.key) {
            ruleSnapshot = await accountRef.child(`/inventory/campaign_template_repo/${this.state.key}`).once("value")
        }

        const markets = await Globals.shared.getMarkets()
        const channels = await Globals.shared.getChannels()
        this.setState({ markets: markets })

        const stateChange: any = { ruleAndMetadataLoaded: true, metadata: new Models.Metadata({}, {}) }
        if (markets.length === 1) {
            stateChange.metadata.markets = { [markets[0].id]: true }
        }
        if (channels.length === 1) {
            stateChange.metadata.channels = { [channels[0].id]: true }
        }

        const customerLookupEnabled = (await accountRef.child("configuration/modules/customer_lookup/enabled").once("value")).val() ? true : false
        stateChange.customerLookupEnabled = customerLookupEnabled

        if (ruleSnapshot && ruleSnapshot.exists()) {
            const rule = ruleSnapshot.val()
            const metadata = rule.metadata || new Models.Metadata({}, {})
            if (markets.length === 1) {
                metadata.markets = { [markets[0].id]: true }
            }
            if (channels.length === 1) {
                metadata.channels = { [channels[0].id]: true }
            }

            const type = this.extractTypeFromRuleTemplateObject(rule)
            if (type) {
                stateChange.metadata = metadata
                if (type === Models.TemplateType.Customizable) {
                    const template = new CustomizableTemplate(rule[type])
                    stateChange.template = template
                } else if (type === Models.TemplateType.Bundle) {
                    const template = new BundleTemplate(rule[type])
                    stateChange.template = template
                } else if (type === Models.TemplateType.BuyXGetY) {
                    const template = new BuyXGetYTemplate(rule[type])
                    stateChange.template = template
                } else {
                    const template = new Models.LegacyRuleModel(type, rule[type])
                    stateChange.template = template
                }
                stateChange.type = type
            }
        }

        const selectedMarkets: string[] = Object.keys(stateChange.metadata?.markets ?? {})
        stateChange.selectedMarkets = selectedMarkets
        stateChange.currentMarket = this.updateCurrentMarket(selectedMarkets)
        this.setState(stateChange)
    }

    extractTypeFromQueryParameter(): Models.TemplateType {
        const val = this.props.location.search.slice(5) //parsing shortcut
        const availableAsStrings: string[] = Models.allTemplateTypes
        const index = availableAsStrings.indexOf(val)
        return index > -1 ? Models.allTemplateTypes[index] : Models.allTemplateTypes[0]
    }

    extractTypeFromRuleTemplateObject(object: Object): Models.TemplateType | null {
        for (const type of Models.allTemplateTypes) {
            if (!_.isNil(object[type])) {
                return type
            }
        }
        return null
    }

    createRuleTemplateFromType(type: Models.TemplateType): Models.RuleModel {
        if (type === Models.TemplateType.Customizable) {
            return new CustomizableTemplate({})
        } else if (type === Models.TemplateType.Bundle) {
            return new BundleTemplate({})
        } else if (type === Models.TemplateType.BuyXGetY) {
            return new BuyXGetYTemplate({})
        } else {
            return new Models.LegacyRuleModel(type, undefined)
        }
    }

    handleChannelsChange = (data: any) => {
        const channels: _.Dictionary<boolean> = {}

        for (const channel of data) {
            channels[channel] = true
        }

        const metadata = _.cloneDeep(this.state.metadata)
        metadata.channels = channels

        this.setState({ metadata: metadata, dirty: true })
    }

    addMarket = (market: string) => {
        const metadata = _.cloneDeep(this.state.metadata)
        const markets = metadata.markets ?? {}
        markets[market] = true
        metadata.markets = markets

        const selectedMarkets = Object.keys(metadata.markets)
        const currentMarket = this.updateCurrentMarket(selectedMarkets)
        this.setState({ metadata: metadata, currentMarket: currentMarket, selectedMarkets: selectedMarkets, dirty: true })
    }

    removeMarket = (market: string) => {
        const metadata = _.cloneDeep(this.state.metadata)
        const markets = metadata.markets || {}
        delete markets[market]
        metadata.markets = markets
        const selectedMarkets = Object.keys(metadata.markets)
        const currentMarket = this.updateCurrentMarket(selectedMarkets)
        this.setState({ metadata: metadata, currentMarket: currentMarket, selectedMarkets: selectedMarkets, dirty: true })
    }

    publish = async () => {
        const type = this.state.type
        const template = this.state.template
        if (!type || !template) {
            return
        }

        const rule = {
            metadata: this.state.metadata,
            [type]: template.json()
        }

        this.setState({ publishing: true })

        const accountId = this.props.role.account_id
        let key = this.state.key
        if (key === undefined) {
            const aref = ref()
                .child(`v1/accounts/${accountId}/inventory/campaign_template_repo`)
                .push()
            key = aref.key || undefined
        }

        if (key !== undefined) {
            rule[type].id = key
            await ref()
                .child(`v1/accounts/${accountId}/inventory/campaign_template_repo/${key}`)
                .set(rule)
        }

        this.props.history.push("/rules")
    }

    ruleTemplateForm() {
        const template = this.state.template ?? this.createRuleTemplateFromType(this.state.type)
        if (Models.useLegacyEditor(this.state.type)) {
            return (
                <RuleTemplateFormComponent
                    role={this.props.role}
                    template={_.cloneDeep(template as Models.LegacyRuleModel)}
                    productObserver={this.productObserver}
                    tagsObserver={this.tagObserver}
                    currentMarket={this.resolvedMarket()}
                    selectedMarkets={this.state.selectedMarkets}
                    onTemplateChanged={(t) => {
                        this.setState({ template: t, dirty: true })
                    }}
                    currentLanguage={() => {
                        return this.state.currentLanguage || null
                    }}
                    resolvedCurrency={() => {
                        return this.resolvedCurrency()
                    }}
                    customerLookupEnabled={this.state.customerLookupEnabled}
                />
            )
        } else if (this.state.type === Models.TemplateType.Customizable) {
            return (<CustomizableForm markets={Object.keys(this.state.metadata?.markets ?? {})} currentLanguage={() => { return this.state.currentLanguage || null }} template={template as CustomizableTemplate} onTemplateChanged={(t) => { this.setState({ template: t, dirty: true }) }} productObserver={this.productObserver} tagObserver={this.tagObserver} attributeObserver={this.attributesObserver} customerAttributeObserver={this.customerAttributesObserver} market={this.resolvedMarket()} />)
        } else if (this.state.type === Models.TemplateType.Bundle) {
            return (<BundleForm markets={Object.keys(this.state.metadata?.markets ?? {})} currentLanguage={() => { return this.state.currentLanguage || null }} template={template as BundleTemplate} onTemplateChanged={(t) => { this.setState({ template: t, dirty: true }) }} productObserver={this.productObserver} tagObserver={this.tagObserver} attributeObserver={this.attributesObserver} customerAttributeObserver={this.customerAttributesObserver} market={this.resolvedMarket()} />)
        } else if (this.state.type === Models.TemplateType.BuyXGetY) {
            return (<BuyXGetYForm markets={Object.keys(this.state.metadata?.markets ?? {})} currentLanguage={() => { return this.state.currentLanguage || null }} template={template as BuyXGetYTemplate} onTemplateChanged={(t) => { this.setState({ template: t, dirty: true }) }} productObserver={this.productObserver} tagObserver={this.tagObserver} attributeObserver={this.attributesObserver} customerAttributeObserver={this.customerAttributesObserver} market={this.resolvedMarket()} />)
            // } else if (this.state.type === Models.TemplateType.Shipping) {
            //     return (<div>Shipping</div>)
        } else {
            return (<div>Unknown template type: {this.state.type}</div>)
        }
    }

    resolveLanguages(): LanguageCode[] {
        if (!this.state || !this.state.template) {
            return []
        }
        const localizations = new Set<LanguageCode>()
        if (this.state.template.display_name) {
            this.state.template.display_name.localizations().forEach(language => {
                localizations.add(language)
            })
        }
        return Array.from(localizations).sort()
    }

    setLanguage(language: LanguageCode | null) {
        this.setState({ currentLanguage: language || undefined })

        if (_.isNil(language)) { return }
        if (_.isNil(this.state.template)) { return }

        const languages = this.resolveLanguages()
        if (!languages.includes(language)) {
            const template = _.cloneDeep(this.state.template)
            if (!_.isNil(template.display_name)) {
                template.display_name.localizeTo(language)
                this.setState({ template: template })
            }
        }
    }

    removeLanguage(language: LanguageCode | null) {
        if (!language) { return }
        const template = this.state.template
        if (template && template.display_name) {
            template.display_name.removeLocalization(language)
        }
        this.setState({ template: template, currentLanguage: undefined, dirty: true })
    }

    updateCurrentMarket(selectedMarkets: string[]): Market | null {
        let currentMarket = this.state.currentMarket
        let currentMarketKey = currentMarket?.id ?? null

        // Don't set an explicit market when only one (or none) is available
        if (selectedMarkets.length <= 1) {
            return null
        }

        if (currentMarketKey && !selectedMarkets.includes[currentMarketKey]) {
            currentMarketKey = null
        }

        if (currentMarketKey === null) {
            currentMarketKey = selectedMarkets[0]
        }
        currentMarket = this.state.markets.find(market => { return (market.id === currentMarketKey) }) || null
        return currentMarket
    }

    resolvedCurrency(): string {
        const market = this.resolvedMarket()
        return market?.currency ?? ""
    }

    resolvedMarket(): Market | null {
        if (this.state.currentMarket) {
            return this.state.currentMarket
        }
        if (this.state.markets.length === 1) {
            return this.state.markets[0]
        }
        const selectedMarketKeys = Object.keys(this.state.metadata?.markets ?? {})
        if (selectedMarketKeys.length === 1) {
            return this.state.markets.find(market => { return market.id === selectedMarketKeys[0] }) || null
        }
        return null
    }
    handleCurrentMarketChange(market: Market | null) {
        this.setState({ currentMarket: market })
    }
}

export default withRouter(RuleEdit)
