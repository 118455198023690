import * as React from "react"
import * as ReactRouter from "react-router"

import { FormattedTime } from "react-intl"
import { PageState } from "../../PageState"
import { Button, Pager, Panel } from "react-bootstrap"
import { Role } from "../../../config/role"
import { StockCountListItem } from "./StockCountListModels"
import { StockCountListViewModel } from "./StockCountListViewModel"
import { StockCountOpenModal } from "./StockCountOpenModal"
import { StripedTable } from "../../StripedTable"

interface StockCountListProps extends ReactRouter.RouteComponentProps<any> {
    role: Role
    stockLocation: string
}

interface StockCountListState {
    current?: StockCountListItem
    loaded: boolean
    past: StockCountListItem[],
    publishingMessage?: string
    showOpenNewModal: boolean
}

class StockCountList extends React.Component<StockCountListProps, StockCountListState> {

    // Props

    viewModel: StockCountListViewModel

    // Constructor

    constructor(props: StockCountListProps) {
        super(props)

        this.viewModel = new StockCountListViewModel(props.role.account_id, props.stockLocation)
        this.viewModel.didCompleteInitialLoad = (past, current) => {
            this.setState({ past: past, current: current, loaded: true })
        }
        this.viewModel.didLoadPastStockCounts = (past) => {
            this.setState({ past: past, loaded: true })
        }
        this.viewModel.didCreateNewStockCount = (stockCountId, errorMessage) => {
            if (!stockCountId && errorMessage) {
                alert(errorMessage)
                this.setState({ publishingMessage: undefined })
                return
            }

            if (stockCountId) {
                const path = `/stock_location/${this.props.stockLocation}/stock_count/current/${stockCountId}`
                this.props.history.push(path)
            }
        }

        this.state = {
            loaded: false,
            past: [],
            publishingMessage: undefined,
            showOpenNewModal: false
        }
    }

    // Methods

    openNewClicked() {
        this.setState({ showOpenNewModal: true })
    }

    openNewStockCountAndRedirect(name: string) {
        this.viewModel.createNewStockCount(name)
        this.setState({ showOpenNewModal: false, publishingMessage: "Opening a new Stock Count" })
    }

    loadPrevious() {
        this.viewModel.startLoadOfPreviousPastStockCounts()
        this.setState({ loaded: false })
    }

    loadNext() {
        this.viewModel.startLoadOfNextPastStockCounts()
        this.setState({ loaded: false })
    }

    showCurrentStockCount(id?: string) {
        if (!id) {
            return
        }

        const path = `/stock_location/${this.props.stockLocation}/stock_count/current/${id}`
        this.props.history.push(path)
    }

    showPastStockCount(id: string) {
        const path = `/stock_location/${this.props.stockLocation}/stock_count/past/${id}`
        this.props.history.push(path)
    }

    // Component

    componentDidMount() {
        this.viewModel.startInitialLoad()
    }

    render() {
        return (
            <PageState loading={!(this.state.loaded || false)} publishing={false} customMessage={this.state.publishingMessage} typeName="stock counts">
                <Panel>
                    <Panel.Heading>
                        <Panel.Title componentClass="h3">Open stock count</Panel.Title>
                    </Panel.Heading>
                    {
                        this.state.current ? (
                            <StripedTable>
                                <thead>
                                    <tr>
                                        {/* <th>Id</th> */}
                                        <th>Name</th>
                                        <th>Opened by</th>
                                        <th>Opened at</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={this.state.current.id} onClick={() => this.showCurrentStockCount((this.state.current || { id: undefined }).id)}>
                                        {/* <td>{this.state.current.id}</td> */}
                                        <td>{this.state.current.name}</td>
                                        <td>{this.state.current.openendBy}</td>
                                        <td>
                                            <FormattedTime
                                                value={new Date(this.state.current.openedAt * 1000)}
                                                day="numeric"
                                                month="long"
                                                year="numeric"
                                                hour="numeric"
                                                minute="numeric"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </StripedTable>
                        ) : (
                                <Panel.Body>
                                    <Button onClick={() => { this.openNewClicked() }}>Open stock count</Button>
                                </Panel.Body>
                            )
                    }
                </Panel>
                <hr />
                <Panel>
                    <Panel.Heading>
                        <Panel.Title componentClass="h3">Past stock counts</Panel.Title>
                    </Panel.Heading>
                    <StripedTable>
                        <thead>
                            <tr>
                                {/* <th>Id</th> */}
                                <th>Name</th>
                                <th>Opened by</th>
                                <th>Opened at</th>
                                <th>Closed by</th>
                                <th>Closed at</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.past.map(listItem => {
                                let lineProps: React.CSSProperties = {}
                                if (listItem.cancelled || false) {
                                    lineProps = { color: "#CCCCCC" }
                                }
                                const result = (
                                    <tr style={lineProps} key={listItem.id} onClick={() => this.showPastStockCount(listItem.id)}>
                                        {/* <td>{listItem.id}</td> */}
                                        <td>{listItem.name + ((listItem.cancelled || false) ? " (cancelled)" : "")}</td>
                                        <td>{listItem.openendBy}</td>
                                        <td>
                                            <FormattedTime
                                                value={new Date(listItem.openedAt * 1000)}
                                                day="numeric"
                                                month="long"
                                                year="numeric"
                                                hour="numeric"
                                                minute="numeric"
                                            />
                                        </td>
                                        <td>{listItem.closedBy}</td>
                                        <td>
                                            <FormattedTime
                                                value={new Date((listItem.closedAt || 0) * 1000)}
                                                day="numeric"
                                                month="long"
                                                year="numeric"
                                                hour="numeric"
                                                minute="numeric"
                                            />
                                        </td>
                                    </tr>
                                )
                                return result
                            })}
                        </tbody>
                    </StripedTable>
                </Panel>
                <Pager>
                    <Pager.Item previous={true} onClick={() => { this.loadPrevious() }} disabled={this.viewModel.isPreviousDisabled()}>&larr; Previous Page</Pager.Item>
                    <Pager.Item next={true} onClick={() => { this.loadNext() }} disabled={this.viewModel.isNextDisabled()}>Next Page &rarr;</Pager.Item>
                </Pager>
                {
                    this.state.showOpenNewModal ? (
                        <StockCountOpenModal
                            stockLocation={this.props.stockLocation}
                            role={this.props.role}
                            openNew={(name: string) => { this.openNewStockCountAndRedirect(name) }}
                            cancel={() => { this.setState({ showOpenNewModal: false }) }}
                        />
                    ) : null
                }
            </PageState>
        )
    }
}

export default ReactRouter.withRouter(StockCountList)