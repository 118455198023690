import * as React from "react"

import { Role } from "../../../config/role"
import { DropdownButton, MenuItem, Button, Panel, Modal } from "react-bootstrap"
import { ref } from "../../../config/constants"
import { StripedTable } from "../../../components/StripedTable"
import { RouteComponentProps, withRouter } from "react-router"
import { ExportIntegrationEdit, ExportIntegrationFilter } from "./ExportIntegrationEdit"

interface ExportIntegrationsProps extends RouteComponentProps<any> {
    role: Role
}

interface ExportIntegrationDeleteRequest {
    type: string
    id: string
}

interface ExportIntegrationsState {
    editIntegrationId?: string
    addIntegrationType?: ExportIntegrationType
    configurations?: any
    deleteRequest?: ExportIntegrationDeleteRequest
}

export enum ExportIntegrationType {
    newsletter = "newsletter",
    products = "products",
    registerCloseStatements = "register_close_statements",
    sales = "sales",
    stockEvents = "stock_events",
    externalOrderEvents = "external_order_events",
    checkouts = "checkouts",
    outgoingOrders = "outgoing_orders"
}

export function exportIntegrationName(type: ExportIntegrationType): string {
    switch (type) {
        case ExportIntegrationType.products:
            return "Product change"
        case ExportIntegrationType.newsletter:
            return "Newsletter signup request"
        case ExportIntegrationType.registerCloseStatements:
            return "Register close statement"
        case ExportIntegrationType.sales:
            return "Sale"
        case ExportIntegrationType.stockEvents:
            return "Stock event"
        case ExportIntegrationType.externalOrderEvents:
            return "External order event"
        case ExportIntegrationType.checkouts:
            return "Checkout"
        case ExportIntegrationType.outgoingOrders:
            return "Outgoing order"
    }
}

class ExportIntegrations extends React.Component<ExportIntegrationsProps, ExportIntegrationsState> {
    constructor(props: ExportIntegrationsProps) {
        super(props)

        this.state = {
            addIntegrationType: undefined
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const integrationConfigurationRef = accountRef.child("configuration/export_integrations")
        const integrationConfigurations = (await integrationConfigurationRef.once("value")).val()
        this.setState({ configurations: integrationConfigurations })

        integrationConfigurationRef.on("value", snap => {
            this.setState({ configurations: snap.val() })
        })
    }

    componentWillUnmount() {
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const integrationConfigurationRef = accountRef.child("configuration/export_integrations")
        integrationConfigurationRef.off()
    }

    availableIntegrations(): ExportIntegrationType[] {
        return [
            ExportIntegrationType.sales,
            ExportIntegrationType.registerCloseStatements,
            ExportIntegrationType.stockEvents,
            ExportIntegrationType.products,
            ExportIntegrationType.newsletter,
            ExportIntegrationType.externalOrderEvents,
            ExportIntegrationType.checkouts,
            ExportIntegrationType.outgoingOrders
        ]
    }

    filtersForIntegrationType(type: ExportIntegrationType): _.Dictionary<ExportIntegrationFilter> | undefined {
        switch (type) {
            case ExportIntegrationType.stockEvents:
                const stockCountResetFilter = ExportIntegrationFilter.stockCountResetFilter()
                return { [stockCountResetFilter.path]: stockCountResetFilter }
            default:
                return undefined
        }
    }

    async deleteIntegration(deleteRequest?: ExportIntegrationDeleteRequest) {
        if (deleteRequest === undefined) { return }
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const integrationRef = accountRef.child("configuration/export_integrations").child(deleteRequest.type).child(deleteRequest.id)
        await integrationRef.child("deactivated").set(true)

        this.setState({ deleteRequest: undefined })
    }

    async reactivate(integrationId: string, integrationType: string) {
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const integrationRef = accountRef.child("configuration/export_integrations").child(integrationType).child(integrationId)
        await integrationRef.child("deactivated").remove()
    }

    renderExistingIntegrations() {
        const configurations = this.state.configurations || {}
        return (
            <div>
                {
                    Object.keys(configurations).map((key: string) => {
                        const integrations = configurations[key]
                        return (
                            <Panel key={key}>
                                <Panel.Heading>
                                    <Panel.Title>{exportIntegrationName(key as ExportIntegrationType)} web hooks</Panel.Title>
                                </Panel.Heading>

                                <Panel.Body>
                                    <StripedTable>
                                        <thead>
                                            <tr>
                                                <th>Web hook id</th>
                                                <th>Web hook name</th>
                                                <th>Endpoint</th>
                                                <th>Edit</th>
                                                <th>Deactivate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(integrations).map((integrationKey: string) => {
                                                    const integration = integrations[integrationKey]
                                                    const integrationName = integration.name || "-"
                                                    const deactivated = integration.deactivated || false
                                                    const showEdit = (integration.delivery || {}).type === "web_hook"
                                                    let endpoint = "-"
                                                    if ((integration.delivery || {}).type === "web_hook") {
                                                        endpoint = ((integration.delivery || {}).parameters || {}).url
                                                    } else if ((integration.delivery || {}).type === "email") {
                                                        const recipients: string[] = ((integration.delivery || {}).parameters || {}).recipients || []
                                                        endpoint = `(E-mail to: ${recipients.join(", ")})`
                                                    }

                                                    return (
                                                        <tr
                                                            key={integrationKey}
                                                            onClick={() => {
                                                                if (deactivated) { return }
                                                                this.props.history.push(`/export_integration/${key}/${integrationKey}`)
                                                            }}
                                                        >
                                                            <td style={{ color: deactivated ? "#BBBBBB" : "#333333" }}>{integrationKey}</td>
                                                            <td style={{ color: deactivated ? "#BBBBBB" : "#333333" }}>{integrationName}</td>
                                                            <td style={{ color: deactivated ? "#BBBBBB" : "#333333" }}>{endpoint}</td>
                                                            <td className="narrow">
                                                                {
                                                                    showEdit ?
                                                                        <Button disabled={deactivated} bsStyle="success" onClick={(event) => { event.stopPropagation(); this.setState({ editIntegrationId: integrationKey, addIntegrationType: key as ExportIntegrationType }) }}>Edit</Button>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td className="narrow">
                                                                {deactivated ?
                                                                    <Button bsStyle="warning" onClick={async (event) => { event.stopPropagation(); await this.reactivate(integrationKey, key) }}>Reactivate</Button>
                                                                    :
                                                                    <Button bsStyle="danger" onClick={(event) => { event.stopPropagation(); this.setState({ deleteRequest: { id: integrationKey, type: key } }) }}>X</Button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </StripedTable>
                                </Panel.Body>
                            </Panel>
                        )
                    })
                }
            </div>
        )
    }

    editedIntegration(): any | undefined {
        const integrationType = this.state.addIntegrationType
        const integrationId = this.state.editIntegrationId
        const configurations = this.state.configurations
        if (integrationType === undefined) { return }
        if (integrationId === undefined) { return }
        if (configurations === undefined) { return }
        return (configurations[integrationType] || {})[integrationId]
    }

    render() {
        return (
            <div>
                {
                    this.state.addIntegrationType ? (
                        <ExportIntegrationEdit
                            role={this.props.role}
                            integrationType={this.state.addIntegrationType!}
                            existingIntegrationId={this.state.editIntegrationId}
                            existingIntegration={this.editedIntegration()}
                            editComplete={() => {
                                this.setState({ addIntegrationType: undefined, editIntegrationId: undefined })
                            }}
                            availableKnownFilters={this.filtersForIntegrationType(this.state.addIntegrationType)}
                        />
                    ) : (
                        <DropdownButton
                            bsStyle="primary"
                            title="Add web hook"
                            id="add_integration"
                            onSelect={(event: any) => {
                                this.setState({ addIntegrationType: event as ExportIntegrationType })
                            }}
                        >
                            {
                                this.availableIntegrations().map(integrationKey => {
                                    return <MenuItem key={integrationKey} eventKey={integrationKey}>Add {exportIntegrationName(integrationKey).toLowerCase() || "-"} integration</MenuItem>
                                })
                            }
                        </DropdownButton>
                    )
                }

                {
                    this.state.deleteRequest ? (
                        <Modal.Dialog key="b">
                            <Modal.Header>
                                <Modal.Title>Deactivate web hook</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>Are you certain that you wish to deactivate the web hook?</Modal.Body>

                            <Modal.Footer>
                                <Button onClick={() => { this.setState({ deleteRequest: undefined }) }}>Cancel</Button>
                                <Button bsStyle="danger" onClick={async () => { await this.deleteIntegration(this.state.deleteRequest) }}>Deactivate</Button>
                            </Modal.Footer>
                        </Modal.Dialog>
                    ) : null
                }

                <br /><br /><br />

                {this.renderExistingIntegrations()}

            </div>
        )
    }
}

export default withRouter(ExportIntegrations)
