import * as _ from "lodash"
import * as React from "react"
import {
    Button,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup
} from "react-bootstrap"
import { ContainerDepositRule } from "./Models"
import { L10nFormControl } from "../../L10nFormControl"
import {
    L10nString,
    LanguageCode
} from "../../../helpers/L10n"
import { Market } from "../../../models/MarketModels"
import { MarketAmount } from "../../../models/MarketAmount"
import { MarketAmountFormControl } from "../../MarketAmountFormControl"
import {
    ModalPicker,
    ModalPickerElement
} from "../../ModalPicker"
import { ProductGroup, Tag } from "../../../models/Product"

const noneProductGroupIdSpecialValue = "__none"
const noneProductGroupDisplayName = "Ingen valgt"

interface ContainerDepositRuleEditProps {
    currentLanguage?: LanguageCode
    currentMarket: Market | null
    markets: string[]
    currency: string
    role: any
    rule: ContainerDepositRule
    tagsArray: Tag[]
    tagsDict: _.Dictionary<Tag>
    productGroupsArray: ProductGroup[]
    productGroupsDict: _.Dictionary<ProductGroup>
    updateRule: (closure: (rule: ContainerDepositRule) => void) => void
}

interface ContainerDepositEditState {
    isProductGroupPickerVisible: boolean
    isTagPickerVisible: boolean

}

export class ContainerDepositRuleEdit extends React.Component<ContainerDepositRuleEditProps, ContainerDepositEditState> {

    // Constructor

    constructor(props: ContainerDepositRuleEditProps) {
        super(props)

        this.state = {
            isProductGroupPickerVisible: false,
            isTagPickerVisible: false
        }
    }

    // Helpers

    nameChanged = (event: any) => {
        this.props.updateRule(rule => {
            const value = event.target.value
            rule.name = value === "" ? null : value
        })
    }

    displayNameChanged = (l10n: L10nString | null) => {
        this.props.updateRule(rule => {
            rule.displayName = l10n || new L10nString("")
        })
    }

    priceChanged = (amount: MarketAmount | null) => {
        this.props.updateRule(rule => {
            rule.price = amount ?? undefined
        })
    }

    pickerSelectedTagElement = (element: ModalPickerElement) => {
        this.props.updateRule(rule => {
            rule.tag = element.id
        })
    }

    pickerSelectedProductGroupElement = (element: ModalPickerElement) => {
        this.props.updateRule(rule => {
            if (element.id === noneProductGroupIdSpecialValue) {
                rule.productGroup = undefined
            } else {
                rule.productGroup = element.id
            }
        })
    }

    tagsForPicker = (): ModalPickerElement[] => {
        if (!this.props.tagsArray) {
            return []
        }
        return this.props.tagsArray.map(tag => {
            return { id: tag.tag, name: tag.name.localized(LanguageCode.da) }
        })
    }

    productGroupsForPicker = (): ModalPickerElement[] => {
        if (!this.props.productGroupsArray) {
            return []
        }
        const result: ModalPickerElement[] = []
        result.push({ id: noneProductGroupIdSpecialValue, name: noneProductGroupDisplayName })

        const elements = this.props.productGroupsArray.map(group => {
            return { id: group.group, name: group.name.localized(LanguageCode.da) }
        })
        return result.concat(elements)
    }

    tagNameForId = (id: string): string | null => {
        if (!this.props.tagsDict) {
            return id
        }
        const tag = this.props.tagsDict[id]
        let result = id
        if (tag) {
            result = tag.name.localized(LanguageCode.da)
        }
        return result
    }

    productGroupNameForId = (id: string | undefined): string | null => {
        if (_.isNil(id)) {
            return null
        }
        if (!this.props.productGroupsDict) {
            return id
        }
        const group = this.props.productGroupsDict[id]
        let result = id
        if (group) {
            result = group.name.localized(LanguageCode.da)
        }
        return result
    }

    // Component

    render() {
        const rule = this.props.rule
        const showTagPicker = () => { this.setState({ isTagPickerVisible: true }) }
        const cancelTagPicker = () => { this.setState({ isTagPickerVisible: false }) }
        const showProductGroupPricker = () => { this.setState({ isProductGroupPickerVisible: true }) }
        const cancelProductGroupPicker = () => { this.setState({ isProductGroupPickerVisible: false }) }
        return (
            <div>
                <Form onSubmit={e => e.preventDefault()} horizontal={true}>
                    {
                        this.state.isTagPickerVisible ? (
                            <ModalPicker
                                elements={this.tagsForPicker()}
                                onCancel={cancelTagPicker}
                                onSelect={(element) => {
                                    this.pickerSelectedTagElement(element)
                                    cancelTagPicker()
                                }}
                                title="New rule"
                            />
                        ) : null
                    }
                    {
                        this.state.isProductGroupPickerVisible ? (
                            <ModalPicker
                                elements={this.productGroupsForPicker()}
                                onCancel={cancelProductGroupPicker}
                                onSelect={(element) => {
                                    this.pickerSelectedProductGroupElement(element)
                                    cancelProductGroupPicker()
                                }}
                                title="New rule"
                            />
                        ) : null
                    }
                    <FormGroup
                        validationState={rule.nameValid() ? null : "error"}
                    >
                        <Col componentClass={ControlLabel} sm={2}>Name</Col>
                        <Col sm={10}>
                            <FormControl
                                type="text"
                                name="name"
                                value={rule.name || ""}
                                placeholder="Enter name"
                                onChange={this.nameChanged}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup
                        validationState={rule.displayNameValid() ? null : "error"}
                    >
                        <Col componentClass={ControlLabel} sm={2}>Display name</Col>
                        <Col sm={10}>
                            <L10nFormControl
                                l10n={rule.displayName || null}
                                language={this.props.currentLanguage || null}
                                onLocalizationChanged={l10n => { this.displayNameChanged(l10n) }}
                                placeholder="Enter name as it should be shown on receipt"
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup
                        validationState={rule.priceValid() ? null : "error"}
                    >
                        <Col componentClass={ControlLabel} sm={2}>Price</Col>
                        <Col sm={10}>
                            <MarketAmountFormControl
                                allMarkets={this.props.markets}
                                amount={rule.price ?? null}
                                market={this.props.currentMarket}
                                onAmountChanged={amount => {
                                    this.priceChanged(amount)
                                }}
                                placeholder="Enter price"
                                currency={this.props.currency}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>{""}</Col>
                        <Col sm={10}>
                            <p>Trigger: select the tag that will trigger this rule. When a product with that tag is added to the basket, a product named "{this.props.rule.displayName ? this.props.rule.displayName.localized(LanguageCode.da) : ""}" will be added to the basket</p>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>Trigger tag</Col>
                        <Col sm={10}>
                            {
                                !_.isNil(rule.tag)
                                    ?
                                    this.tagNameForId(rule.tag)
                                    :
                                    ""
                            }
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2} />
                        <Col sm={10}>
                            <Button onClick={showTagPicker}>Change tag</Button>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>{""}</Col>
                        <Col sm={10}>
                            <p>Optional: select product group for the container deposit product to get the sale values aggregated in the register close statement</p>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>Product group</Col>
                        <Col sm={10}>
                            {this.productGroupNameForId(rule.productGroup)}
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2} />
                        <Col sm={10}>
                            <Button onClick={showProductGroupPricker}>Change product group</Button>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        )
    }
}