import * as React from "react"

import { Role } from "../../../config/role"
import { DropdownButton, MenuItem, FormControl, Button, Panel, Modal, InputGroup, Glyphicon, Alert } from "react-bootstrap"
import { ref } from "../../../config/constants"
import { StripedTable } from "../../../components/StripedTable"
import CopyToClipboard from "react-copy-to-clipboard"
import { ImportIntegrationEdit } from "./ImportIntegrationEdit"
import { withRouter, RouteComponentProps } from "react-router"
import firebase from "firebase/compat"

interface ImportIntegrationsProps extends RouteComponentProps<any> {
    role: Role
}

interface ImportIntegrationDeleteRequest {
    type: string
    id: string
}

interface ImportIntegrationsState {
    addIntegrationType?: ImportIntegrationType
    editIntegrationId?: string
    existingIntegrations?: any
    configurations?: any
    deleteRequest?: ImportIntegrationDeleteRequest
    copied: boolean
}

export enum ImportIntegrationType {
    products = "products",
    discountCampaigns = "discount_campaigns",
    recommendations = "recommendations",
    stock = "stock",
    tags = "tags",
    productGroups = "product_groups",
    attributes = "attributes",
    customerAttributes = "customer_attributes",
    attributeGroups = "attribute_groups",
    folders = "folders",
    tabs = "tabs",
    recommendationCategories = "recommendation_categories",
    facets = "facets",
    productRules = "product_rules",
    incomingOrders = "external_orders",
    expenses = "expenses",
    productAssets = "product_assets",
    configurableFrontPage = "configurable_front_page",
    customers = "customers",
    outgoingOrderStateChanges = "outgoing_order_state_changes"
}

export function getIntegrationName(type: ImportIntegrationType): string {
    switch (type) {
        case ImportIntegrationType.products:
            return "Product"
        case ImportIntegrationType.productAssets:
            return "Product assets"
        case ImportIntegrationType.discountCampaigns:
            return "Discount campaign"
        case ImportIntegrationType.recommendations:
            return "Recommendation"
        case ImportIntegrationType.stock:
            return "Stock"
        case ImportIntegrationType.tags:
            return "Tag"
        case ImportIntegrationType.attributes:
            return "Product Attribute"
        case ImportIntegrationType.customerAttributes:
            return "Customer Attribute"
        case ImportIntegrationType.attributeGroups:
            return "Product Attribute group"
        case ImportIntegrationType.productGroups:
            return "Product group"
        case ImportIntegrationType.folders:
            return "Folders"
        case ImportIntegrationType.tabs:
            return "Tabs"
        case ImportIntegrationType.recommendationCategories:
            return "Recommendation categories"
        case ImportIntegrationType.facets:
            return "Facets"
        case ImportIntegrationType.productRules:
            return "Product rules"
        case ImportIntegrationType.incomingOrders:
            return "Incoming orders"
        case ImportIntegrationType.outgoingOrderStateChanges:
            return "Outgoing order state changes"
        case ImportIntegrationType.expenses:
            return "Expenses"
        case ImportIntegrationType.configurableFrontPage:
            return "Configurable front page"
        case ImportIntegrationType.customers:
            return "Customer"
    }
}

class ImportIntegrations extends React.Component<ImportIntegrationsProps, ImportIntegrationsState> {
    constructor(props: ImportIntegrationsProps) {
        super(props)

        this.state = {
            addIntegrationType: undefined,
            copied: false
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const importIntegrationKeysRef = accountRef.child("api_keys").child("import_integrations")
        const integrationTypesSnap = await importIntegrationKeysRef.once("value")
        const integrationTypes = integrationTypesSnap.val()
        const integrationConfigurationRef = accountRef.child("configuration/import_integrations")
        const integrationConfigurations = (await integrationConfigurationRef.once("value")).val()
        this.setState({ existingIntegrations: integrationTypes, configurations: integrationConfigurations })

        importIntegrationKeysRef.on("value", snap => {
            this.setState({ existingIntegrations: snap.val() })
        })

        integrationConfigurationRef.on("value", snap => {
            this.setState({ configurations: snap.val() })
        })

    }

    componentWillUnmount() {
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const importIntegrationKeysRef = accountRef.child("api_keys").child("import_integrations")
        const integrationConfigurationRef = accountRef.child("configuration/import_integrations")
        importIntegrationKeysRef.off()
        integrationConfigurationRef.off()
    }

    availableIntegrations(): ImportIntegrationType[] {
        return [
            ImportIntegrationType.products,
            ImportIntegrationType.discountCampaigns,
            ImportIntegrationType.recommendations,
            ImportIntegrationType.stock,
            ImportIntegrationType.tags,
            ImportIntegrationType.productGroups,
            ImportIntegrationType.attributes,
            ImportIntegrationType.attributeGroups,
            ImportIntegrationType.folders,
            ImportIntegrationType.tabs,
            ImportIntegrationType.recommendationCategories,
            ImportIntegrationType.facets,
            ImportIntegrationType.productRules,
            ImportIntegrationType.incomingOrders,
            ImportIntegrationType.outgoingOrderStateChanges,
            ImportIntegrationType.expenses,
            ImportIntegrationType.productAssets,
            ImportIntegrationType.configurableFrontPage,
            ImportIntegrationType.customers,
            ImportIntegrationType.customerAttributes,
        ]
    }

    async deleteIntegration(deleteRequest?: ImportIntegrationDeleteRequest) {
        if (deleteRequest === undefined) { return }
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const integrationRef = accountRef.child("configuration/import_integrations").child(deleteRequest.type).child(deleteRequest.id)
        await integrationRef.child("deactivated").set(true)

        const apiKeysRef = accountRef.child("api_keys/import_integrations").child(deleteRequest.type).child(deleteRequest.id)
        const integration = ((this.state.existingIntegrations || {})[deleteRequest.type] || {})[deleteRequest.id]
        for (const apiKey in integration) {
            const apiKeyRef = apiKeysRef.child(apiKey).child("metadata")
            await apiKeyRef.child("active").set(false)
        }

        this.setState({ deleteRequest: undefined })
    }

    integrationRef(integrationType: string): firebase.database.Reference {
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        return accountRef.child(`api_keys/import_integrations/${integrationType}`)
    }

    async reactivate(integrationId: string, integrationType: string) {
        const account = this.props.role.account_id
        const accountRef = ref().child(`v1/accounts/${account}`)
        const integrationRef = accountRef.child("configuration/import_integrations").child(integrationType).child(integrationId)
        await integrationRef.child("deactivated").remove()
        const apiKeysRef = accountRef.child("api_keys/import_integrations").child(integrationType).child(integrationId)
        const integration = ((this.state.existingIntegrations || {})[integrationType] || {})[integrationId]
        for (const apiKey in integration) {
            const apiKeyRef = apiKeysRef.child(apiKey).child("metadata")
            await apiKeyRef.child("active").set(true)
        }
    }

    renderExistingIntegrations() {
        const account = this.props.role.account_id
        const base = `${process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE}/imports`

        const integrationTypes = this.state.existingIntegrations
        if (!integrationTypes) { return null }
        const configurations = this.state.configurations || {}
        return (
            <div>
                {
                    Object.keys(integrationTypes).map((key: string) => {
                        const integrations = integrationTypes[key]
                        const integrationTypesConfig = configurations[key] || {}
                        return (
                            <Panel key={key}>
                                <Panel.Heading>
                                    <Panel.Title>{getIntegrationName(key as ImportIntegrationType)} import integrations</Panel.Title>
                                </Panel.Heading>

                                <Panel.Body>
                                    <StripedTable>
                                        <thead>
                                            <tr>
                                                <th>Integration Id</th>
                                                <th>Integration Name</th>
                                                <th>Endpoint</th>
                                                <th>Edit</th>
                                                <th>Deactivate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(integrations).map((integrationKey: string) => {
                                                const integration = integrations[integrationKey]
                                                const integrationConfig = integrationTypesConfig[integrationKey] || {}
                                                const integrationName = integrationConfig.name || "-"
                                                const deactivated = integrationConfig.deactivated || false

                                                // We are hiding the fact that multiple keys may exist for the same integration
                                                const firstAPIKey = Object.keys(integration)[0]
                                                const importUrl = `${base}/${key}?account=${account}&apikey=${firstAPIKey}&integration=${integrationKey}`

                                                return (
                                                    <tr
                                                        key={integrationKey}
                                                        onClick={() => {
                                                            if (deactivated) { return }
                                                            this.props.history.push(`/import_integration/${key}/${integrationKey}`)
                                                        }}
                                                    >
                                                        <td style={{ color: deactivated ? "#BBBBBB" : "#333333" }}>{integrationKey}</td>
                                                        <td style={{ color: deactivated ? "#BBBBBB" : "#333333" }}>{integrationName}</td>
                                                        <td onClick={(event) => { event.stopPropagation() }}>
                                                            <CopyToClipboard
                                                                text={importUrl}
                                                                onCopy={() => { this.setState({ copied: true }); setTimeout(() => { this.setState({ copied: false }) }, 5000) }}
                                                            >
                                                                <InputGroup style={{ width: "100%" }}>
                                                                    <FormControl type="text" readOnly={true} value={importUrl} />
                                                                    <InputGroup.Addon>
                                                                        <Glyphicon glyph="copy" />
                                                                    </InputGroup.Addon>
                                                                </InputGroup>
                                                            </CopyToClipboard>

                                                        </td>
                                                        <td className="narrow"><Button disabled={deactivated} bsStyle="success" onClick={(event) => { event.stopPropagation(); this.setState({ editIntegrationId: integrationKey, addIntegrationType: key as ImportIntegrationType }) }}>Edit</Button></td>
                                                        <td className="narrow">
                                                            {deactivated ?
                                                                <Button bsStyle="warning" onClick={async (event) => { event.stopPropagation(); await this.reactivate(integrationKey, key) }}>Reactivate</Button>
                                                                :
                                                                <Button bsStyle="danger" onClick={(event) => { event.stopPropagation(); this.setState({ deleteRequest: { id: integrationKey, type: key } }) }}>X</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </StripedTable>
                                </Panel.Body>
                            </Panel>
                        )
                    })
                }
            </div>
        )
    }

    editedIntegration(): any | undefined {
        const integrationType = this.state.addIntegrationType
        const integrationId = this.state.editIntegrationId
        const configurations = this.state.configurations
        if (integrationType === undefined) { return }
        if (integrationId === undefined) { return }
        if (configurations === undefined) { return }
        return (configurations[integrationType] || {})[integrationId] || {}
    }

    render() {
        return (
            <div>
                {this.state.copied ? <Alert bsStyle="success">Integration URL copied to clipboard.</Alert> : null}
                <br /><br />
                {this.state.addIntegrationType ? (
                    <ImportIntegrationEdit
                        role={this.props.role}
                        integrationType={this.state.addIntegrationType}
                        existingIntegrationId={this.state.editIntegrationId}
                        existingIntegration={this.editedIntegration()}
                        editComplete={() => { this.setState({ addIntegrationType: undefined, editIntegrationId: undefined }) }}
                    />
                )
                    :
                    (
                        <DropdownButton
                            bsStyle="primary"
                            title="Add import integration"
                            id="add_integration"
                            onSelect={(event: any) => {
                                this.setState({ addIntegrationType: event as ImportIntegrationType })
                            }}
                        >
                            {
                                this.availableIntegrations().map(integrationKey => {
                                    return <MenuItem key={integrationKey} eventKey={integrationKey}>Add {getIntegrationName(integrationKey).toLowerCase() || "-"} integration</MenuItem>
                                })
                            }
                        </DropdownButton>
                    )
                }

                {this.state.deleteRequest ? (
                    <Modal.Dialog key="b">
                        <Modal.Header>
                            <Modal.Title>Deactivate integration</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>Are you certain that you wish to deactivate the integration?</Modal.Body>

                        <Modal.Footer>
                            <Button onClick={() => { this.setState({ deleteRequest: undefined }) }}>Cancel</Button>
                            <Button bsStyle="danger" onClick={async () => { await this.deleteIntegration(this.state.deleteRequest) }}>Deactivate</Button>
                        </Modal.Footer>
                    </Modal.Dialog>
                ) : null}

                <br /><br /><br />
                {this.renderExistingIntegrations()}

            </div>
        )
    }
}

export default withRouter(ImportIntegrations)
