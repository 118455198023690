import * as _ from "lodash"
import moment from "moment"
import Numeral from "numeral"
import { L10nString, LanguageCode } from "../helpers/L10n"
import { Product } from "../models/Product"
import {
    ProductCatalogService,
    ProductPrices
} from "./ProductCatalogService"
import { productName } from "../helpers/productName"
import { firestore, ref } from "../config/constants"
import { sortLikeFirebaseOrderByKey } from "../helpers/sorting"
import firebase from "firebase/compat"
/* tslint:disable */
import "firebase/firestore"
/* tslint:enable */

// picked pretty arbitrarily but it should hold that it's not too much memory and not too slow for stock counts in the area of 10.000 lines
const fetchLimit = 500

export class StockReportLine {
    private barcode?: string
    private count?: number
    private name?: string
    private productId: string
    private variantId?: string
    private costPrice?: number
    private retailPrice?: number
    private productGroup?: string

    constructor(
        barcode: string | undefined,
        count: number | undefined,
        name: string | undefined,
        productId: string,
        variantId: string | undefined,
        prices: ProductPrices,
        productGroup: string | undefined
    ) {
        this.barcode = barcode
        this.count = count
        this.name = name
        this.productId = productId
        this.variantId = variantId
        this.costPrice = prices.costPrice
        this.retailPrice = prices.retailPrice
        this.productGroup = productGroup
    }

    csvLine(fieldDelimiter: string): string {
        const formattedValues = this.formattedValues()
        const result = formattedValues.join(fieldDelimiter)
        return result
    }

    // BG: Trying to circumvent a weird error when deploying - from https://stackoverflow.com/questions/63822198/cryptic-error-when-deploying-typescript-react-app-to-heroku
    // private formattedValues(): [string, string, string, string, string, string, string, string] {
    private formattedValues() {
        const decimalSeparator = ","
        const variantId = `"${this.variantId ?? ""}"`
        const productId = `"${this.productId}"`
        const id = `"${this.variantId ?? this.productId}"`
        const name = `"${this.name ?? ""}"`
        const barcode = `"${this.barcode ?? ""}"`
        const count = `${this.count ?? ""}`
        const costPrice = `${!_.isNil(this.costPrice) ? this.formatPriceValue(this.costPrice, decimalSeparator) : ""}`
        const costPriceCount = `${!_.isNil(this.costPrice) && !_.isNil(this.count) && this.count >= 0 ? this.formatPriceValue(this.costPrice * this.count, decimalSeparator) : ""}`
        const retailPrice = `${!_.isNil(this.retailPrice) ? this.formatPriceValue(this.retailPrice, decimalSeparator) : ""}`
        const retailPriceCount = `${!_.isNil(this.retailPrice) && !_.isNil(this.count) && this.count >= 0 ? this.formatPriceValue(this.retailPrice * this.count, decimalSeparator) : ""}`
        const productGroup = _.isNil(this.productGroup) ? `""` : `"${this.productGroup}"`
        return [productId, variantId, id, name, barcode, count, costPrice, costPriceCount, retailPrice, retailPriceCount, productGroup]
    }

    private formatPriceValue(price: number, decimalSeparator: string): string {
        const value = Numeral(price).format("0.00")
        if (decimalSeparator !== ".") {
            return value.replace(".", decimalSeparator)
        } else {
            return value
        }
    }
}

function csvString(input: string | undefined) {
    return `"${input ?? ""}"`
}

export class StockReportBuilder {

    // Props

    private accountId: string
    private shopId: string
    private productCatalogService: ProductCatalogService
    private productsLoaded: number = 0

    // Cosntructor

    constructor(accountId: string, shopId: string, productCatalogService: ProductCatalogService) {
        this.accountId = accountId
        this.shopId = shopId
        this.productCatalogService = productCatalogService
    }

    // Public methos

    // BG: Trying to circumvent a weird error when deploying - from https://stackoverflow.com/questions/63822198/cryptic-error-when-deploying-typescript-react-app-to-heroku
    // async buildStockReport(progress: (loaded: number, aggregated: number) => void): Promise<[string, string]> {
    async buildStockReport(progress: (loaded: number, aggregated: number) => void): Promise<string[]> {
        await this.productCatalogService.allProductsInCatalogue(this.accountId, this.shopId, (loaded) => {
            this.productsLoaded = loaded
            progress(loaded, 0)
        })
        const fieldDelimiter = ";"
        const reportModels = await this.buildReportModels((created: number) => { progress(this.productsLoaded, created) })
        const reportLines = reportModels.map((model) => {
            return model.csvLine(fieldDelimiter)
        })
        const fileName = await this.buildDocumentName()
        const headerLine = ["Product id", "Variant id", "Id / Article number", "Name", "Barcode", "Stock", "Cost price per item", "Cost price total (count)", "Retail price per item", "Retail price total (count)", "Product group"].join(fieldDelimiter)
        let lines: string[] = [headerLine]
        lines = lines.concat(reportLines)
        return [lines.join("\n"), fileName]
    }

    async buildStockMoveReport(type: "all" | "removal" | "received" | "sale" | "return", progress: (loaded: number, aggregated: number) => void): Promise<string[]> {
        const fileName = await this.buildDocumentName()

        const market = await this.getMarket()

        const snapshot = await this.getStockEvents(type)
        const fieldDelimiter = ";"
        const header = [
            csvString("Product Id"),
            csvString("Variant Id"),
            csvString("Name"),
            csvString("Barcode"),
            csvString("Adjustment"),
            csvString("Type")
        ].join(fieldDelimiter)

        const productCache: any = {}

        let lines: string[] = [header]
        for (const doc of snapshot.docs) {
            const data = doc.data()
            let { name, barcode } = await this.getProductInfo(productCache, data, market)

            lines.push([
                csvString(data.product_id),
                csvString(data.variant_id),
                csvString(name),
                csvString(barcode),
                data.adjustment,
                csvString(data.type)
            ].join(fieldDelimiter))
        }
        return [lines.join("\n"), fileName]
    }

    private async getMarket() {
        const marketSnap = await ref().child(`v1/accounts/${this.accountId}/shops/${this.shopId}/market`).get()
        const market = marketSnap.val() ?? "dk"
        return market
    }

    private async getStockEvents(type: string) {
        const now = moment()
        const beginningOfDay = now.startOf("day")

        const stockEvents = firestore.collection(`accounts/${this.accountId}/stock_locations/${this.shopId}/stock_events`)
        const timestamp = new firebase.firestore.Timestamp(beginningOfDay.unix(), 0)
        let query: firebase.firestore.Query<firebase.firestore.DocumentData> = stockEvents
        if (type !== "all") {
            query = query.where("type", "==", type)
        }
        query = query.orderBy("timestamp").startAt(timestamp)
        const snap = await query.get()
        return snap
    }

    private async getProductInfo(productCache: any, data: firebase.firestore.DocumentData, market: any) {
        let product = productCache[data.product_id]
        if (product === undefined) {
            const productSnap = await ref().child(`v1/accounts/${this.accountId}/inventory/products/pos/${market}/${data.product_id}`).get()
            product = productSnap.val()
            productCache[data.product_id] = productSnap.val()
        }
        const productName = new L10nString(product.name ?? "-")
        let name = productName.localized(LanguageCode.da)
        let barcode = product.barcode
        if (!_.isNil(data.variant_id)) {
            const variant = product.variants.find((v: any) => { return v.id === data.variant_id })
            if (!_.isNil(variant)) {
                if (!_.isNil(variant.name)) {
                    name = new L10nString(variant.name).localized(LanguageCode.da)
                }
                if (!_.isNil(variant.barcode)) {
                    barcode = variant.barcode
                }
            }
        }
        return { name, barcode }
    }

    async buildDocumentName(): Promise<string> {
        const shopNameSnapshot = await ref().child(`v1/accounts/${this.accountId}/shop_index/${this.shopId}/name`).once("value")
        const shopName = shopNameSnapshot.exists() ? shopNameSnapshot.val() : undefined

        const now = moment().format("MMMM Do YYYY, H_mm_ss")
        let result = "Stock report - "
        if (shopName) {
            result += shopName
            result += ", "
        }
        result += now
        return result
    }

    // Private methods

    private async buildReportModels(progress: (created: number) => void): Promise<StockReportLine[]> {
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock`

        const result: StockReportLine[] = []
        let count = 0
        let done = false
        let fromLineItemId: string | undefined = undefined
        while (!done) {
            let query = ref().child(path).limitToFirst(fetchLimit + 1).orderByKey()
            if (fromLineItemId) {
                query = query.startAt(fromLineItemId)
            }

            const snapshot = await query.once("value")
            if (!snapshot.exists()) {
                done = true
                continue
            }

            const stockValues = snapshot.val() || {}
            const keys = Object.keys(stockValues)
            const batchKeys = _.take(keys, fetchLimit)
            for (const productId of batchKeys) {
                const product = this.productCatalogService.product(productId)
                const stockValue = stockValues[productId]
                if (typeof stockValue === "number") {
                    result.push(this.lineFrom(product, productId, undefined, stockValue))
                } else if (typeof stockValue === "object") {
                    for (const variantId in stockValue) {
                        const variantValue = stockValue[variantId]
                        result.push(this.lineFrom(product, productId, variantId, variantValue))
                    }
                } else {
                    console.info(`Stock value is not number or object: ${JSON.stringify(stockValue)}`)
                    continue
                }
            }

            count += batchKeys.length
            progress(count)

            const sortedKeys = sortLikeFirebaseOrderByKey(batchKeys)
            const lastId = _.last(sortedKeys)
            if (!lastId) {
                done = true
                continue
            } else {
                fromLineItemId = lastId
            }

            if (keys.length < fetchLimit + 1) {
                done = true
            }
        }
        return result
    }

    private lineFrom(product: Product | undefined, productId: string, variantId: string | undefined, value: number): StockReportLine {
        let barcode: string | undefined = undefined
        if (!_.isNil(product)) {
            if (!_.isNil(variantId) && !_.isNil(product.variant(variantId))) {
                barcode = product.variant(variantId)?.barcode ?? product.barcode
            } else {
                barcode = product.barcode
            }
        }
        const variant = variantId && product ? product.variant(variantId) : null
        const name = product ? productName(product, variant, LanguageCode.da) : "PRODUCT MISSING"
        const prices = this.productCatalogService.productPrices(productId, variantId)
        const productGroup = product ? product.product_group : undefined
        const line = new StockReportLine(barcode, value, name, productId, variantId, prices, productGroup)
        return line
    }
}