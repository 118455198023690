import * as _ from "lodash"
import { AttributeTypeKey } from "./Product"
import { L10nString } from "../helpers/L10n"

export enum FacetTypeKey {
    ATTRIBUTE = "attribute",
    PRICE = "price",
    TAGS = "tags"
}

export class TagsFacet {
    json(): any {
        return { tags: true }
    }

    isValid(): boolean {
        return true
    }
}

export enum PriceFacetConfig {
    AUTOMATIC = "Automatic intervals",
    MANUAL = "Manual intervals",
}

export class PriceFacet {
    intervalCount: number
    intervals?: number[]
    facetConfig: PriceFacetConfig

    constructor(json: any) {
        this.intervalCount = json.interval_count
        this.facetConfig = _.isNil(json.intervals) ? PriceFacetConfig.AUTOMATIC : PriceFacetConfig.MANUAL
        this.intervals = json.intervals
    }

    json(): any {
        var object = {
            interval_count: _.isUndefined(this.intervals) ? this.intervalCount : this.intervals!.length + 1,
            intervals: _.isUndefined(this.intervals) ? null : this.intervals!
        }
        return object
    }

    isValid(): boolean {
        return this.intervalCount > 1 && this.areIntervalsAscending()
    }

    areIntervalsAscending(): boolean {
        if (_.isNil(this.intervals)) {
            return true
        }
        else {
            var isAscending = true
            for (var index = 0; index < this.intervals.length; index++) {
                if (index + 1 === this.intervals.length) { break }
                isAscending = this.intervals[index] < this.intervals[index + 1]
                if (!isAscending) { break }
            }
            return isAscending
        }
    }
}

export class AttributeFacet {
    id: string
    type: AttributeFacetType 

    constructor(json: any) {
        this.id = json.id
        this.type = new AttributeFacetType(json.type)
    }

    json(): any {
        return {
            id: this.id,
            type: this.type.json()
        }
    }

    isValid(): boolean {
        return !_.isNil(this.id) && this.id.length > 0 && this.type.isValid()
    }
}

export class AttributeFacetType {
    number?: NumberAttributeFacet
    options?: OptionsAttributeFacet

    constructor(json: any) {
        if (json.number) {
            this.number = new NumberAttributeFacet(json.number)
        } else if (json.options) {
            this.options = new OptionsAttributeFacet()
        }
    }

    json(): any {
        if (this.number) {
            return {
                number: this.number.json()
            }
        } else if (this.options) {
            return {
                options: this.options.json()
            }
        }
    }

    typeKey(): AttributeTypeKey {
        if (this.options) {
            return AttributeTypeKey.OPTIONS
        } else if (this.number) {
            return AttributeTypeKey.NUMBER
        }
        throw new Error("Invalid attribute")
    }

    isValid(): boolean {
        if (this.number) {
            return this.number.isValid()
        } else if (this.options) {
            return this.options.isValid()
        }
        return false
    }
}

export class NumberAttributeFacet {
    intervalCount: number
    max: number
    min: number

    constructor(json: any) {
        this.intervalCount = json.interval_count
        this.max = json.max
        this.min = json.min
    }

    json(): any {
        return {
            interval_count: this.intervalCount,
            max: this.max,
            min: this.min
        }
    }

    isValid(): boolean {
        if (_.isNil(this.intervalCount) || _.isNil(this.min) || _.isNil(this.max)) {
            return false
        }
        return this.intervalCount > 1 && this.min < this.max
    }
}

export class OptionsAttributeFacet {
    json() {
        return {
            options: true
        }
    }

    isValid(): boolean {
        return true
    }
}

export class FacetType {
    attribute?: AttributeFacet
    price?: PriceFacet
    tags?: TagsFacet

    constructor(json: any) {
        if (json.attribute) {
            this.attribute = new AttributeFacet(json.attribute)
        } else if (json.price) {
            this.price = new PriceFacet(json.price)
        } else if (json.tags) {
            this.tags = new TagsFacet()
        }
    }

    json(): any {
        if (this.attribute) {
            return {
                attribute: this.attribute.json()
            }
        } else if (this.price) {
            return {
                price: this.price.json()
            }
        } else if (this.tags) {
            return {
                tags: this.tags.json()
            }
        }
    }

    typeKey(): FacetTypeKey {
        if (this.attribute) {
            return FacetTypeKey.ATTRIBUTE
        } else if (this.price) {
            return FacetTypeKey.PRICE
        } else if (this.tags) {
            return FacetTypeKey.TAGS
        }
        throw new Error("Invalid facet")
    }

    isValid(): boolean {
        if (this.attribute) {
            return this.attribute.isValid()
        } else if (this.price) {
            return this.price.isValid()
        } else if (this.tags) {
            return this.tags.isValid()
        }
        return false
    }
}

export class Facet {
    id: string
    name?: L10nString
    type?: FacetType

    constructor(json: any) {
        if (!json) {
            this.id = ""
            return
        }

        this.id = json.id
        this.name = new L10nString(json.name)
        this.type = new FacetType(json.type)
    }

    json(): any {
        const result: any = {
            id: this.id,
            name: this.name ? this.name.json() : undefined
        }
        if (this.type) {
            result.type = this.type.json()
        }
        return result
    }

    typeKey(): FacetTypeKey {
        return !_.isNil(this.type) ? this.type.typeKey() : FacetTypeKey.ATTRIBUTE 
    }

    isValid(): boolean {
        const idValid = !_.isNil(this.id) && this.id.length > 0
        const nameValid = !_.isNil(this.name)
        const typeValid = _.isNil(this.type) ? false : this.type.isValid()
        return idValid && nameValid && typeValid
    }
}