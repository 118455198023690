import * as React from "react"

import { Button, Panel, FormControl } from "react-bootstrap"
import { ref } from "../../../config/constants"
import { PageState } from "../../PageState"
import { Role } from "../../../config/role"
import { isNil } from "lodash"
import { ChromePicker } from "react-color"
import { default as reactCSS } from "reactcss"

interface Color {
    hex: string
}

interface ImageDisplayStyleConfigurationProps {
    role: Role
}

interface ImageDisplayStyleConfigurationState {
    displayBackgroundColorPicker: boolean,
    imageDisplayStyle: ImageDisplayStyle
    dirty: boolean
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
}

enum ImageDisplayStyleMode {
    scaleToFit = "scale_to_fit",
    scaleToFill = "scale_to_fill",
    automatic = "automatic",
}

class ImageDisplayStyle {

    static defaultBackgroundColor: string = "#ffffff"
    static defaultMode: ImageDisplayStyleMode = ImageDisplayStyleMode.scaleToFill

    static fromJSON(json: any): ImageDisplayStyle {
        if (isNil(json)) {
            return ImageDisplayStyle.default
        }

        const mode = json.mode === ImageDisplayStyleMode.automatic || json.mode === ImageDisplayStyleMode.scaleToFill || json.mode === ImageDisplayStyleMode.scaleToFit ? json.mode : ImageDisplayStyle.defaultMode
        const backgroundColor = json.background_color || ImageDisplayStyle.defaultBackgroundColor

        return new ImageDisplayStyle(mode, backgroundColor)
    }

    static get default(): ImageDisplayStyle {
        return new ImageDisplayStyle(ImageDisplayStyle.defaultMode, ImageDisplayStyle.defaultBackgroundColor)
    }

    mode: ImageDisplayStyleMode
    backgroundColor: string

    constructor(mode: ImageDisplayStyleMode, backgroundColor: string) {
        this.mode = mode
        this.backgroundColor = backgroundColor
    }

    toJSON(): any {
        return {
            background_color: this.backgroundColor,
            mode: this.mode
        }
    }
}

export default class ImageDisplayStyleConfiguration extends React.Component<ImageDisplayStyleConfigurationProps, ImageDisplayStyleConfigurationState> {
    constructor(props: ImageDisplayStyleConfigurationProps) {
        super(props)

        this.state = {
            displayBackgroundColorPicker: false,
            imageDisplayStyle: ImageDisplayStyle.default,
            dirty: false,
            loaded: false,
            publishing: false,
            showSuccess: false
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/image_display_style`)
        const configSnap = await configRef.once("value")

        const imageDisplayStyle = ImageDisplayStyle.fromJSON(configSnap.val())

        this.setState({ imageDisplayStyle: imageDisplayStyle, loaded: true })
    }

    render() {
        const styles = reactCSS({
            "default": {
                backgroundColor: {
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: `${this.state.imageDisplayStyle.backgroundColor}`,
                },
                swatch: {
                    padding: "5px",
                    background: "#fff",
                    borderRadius: "1px",
                    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                    display: "inline-block",
                    cursor: "pointer",
                },
                popover: {
                    position: "absolute",
                    zIndex: "2",
                },
                cover: {
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                }
            },
        })

        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="image display style">
                <Panel key="upper_panel" bsStyle={this.state.showSuccess ? "success" : "default"}>
                    <Panel.Heading>
                        <Panel.Title>{this.state.showSuccess ? "Image display style updated successsfully" : "Image display style"}</Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <h4>Image display scaling mode</h4>
                        <p>Scaling mode used when displaying images in the produdct grid in the POS.</p>
                        <ul>
                            <li><b>Automatic</b> Samples the image and tries to scale with a best effort approach.</li>
                            <li><b>Scale to fill</b> Stretches and/or scales the image as needed to fill the desired space.</li>
                            <li><b>Scale to fit</b> Scales the image until the biggest side fits flush with the target area. Empty space is applied to the top/bottom or the sides, never both.</li>
                        </ul>
                        <FormControl
                            componentClass="select"
                            name="image_display_style"
                            value={this.state.imageDisplayStyle.mode}
                            onChange={(e: any) => { this.imageDisplayStyleChanged(e.target.value) }}
                        >
                            <option value={ImageDisplayStyleMode.automatic}>Automatic</option>
                            <option value={ImageDisplayStyleMode.scaleToFill}>Scale to fill</option>
                            <option value={ImageDisplayStyleMode.scaleToFit}>Scale to fit</option>
                        </FormControl>
                        <br />
                        <h4>Background color</h4>
                        <p>Background color used for filling out the top/bottom or sides when scaling the image.</p>
                        <div>
                            <div style={styles.swatch} onClick={this.handleBackgroundColorClick}>
                                <div style={styles.backgroundColor} />
                            </div>
                            {
                                this.state.displayBackgroundColorPicker ? (
                                    <div style={styles.popover as any}>
                                        <div style={styles.cover as any} onClick={this.handleColorPickerClose} />
                                        <ChromePicker disableAlpha={true} color={this.state.imageDisplayStyle.backgroundColor} onChange={this.backgroundColorChanged} />
                                    </div>
                                ) : null
                            }
                        </div>
                    </Panel.Body>

                    <Panel.Footer>
                        <Button onClick={this.publish} disabled={!this.state.dirty}>Publish</Button>
                    </Panel.Footer>
                </Panel >
            </PageState>
        )
    }

    imageDisplayStyleChanged = (value: ImageDisplayStyleMode) => {
        const newStyle = new ImageDisplayStyle(value, this.state.imageDisplayStyle.backgroundColor)
        this.setState({ imageDisplayStyle: newStyle, dirty: true })
    }

    backgroundColorChanged = (color: Color) => {
        const newStyle = new ImageDisplayStyle(this.state.imageDisplayStyle.mode, color.hex)
        this.setState({ imageDisplayStyle: newStyle, dirty: true })
    }

    handleColorPickerClose = () => {
        this.setColorPickerState()
    }

    setColorPickerState = (keyColor = false, backgroundColor = false, secondScreenForegroundColor = false, secondScreenBackgroundColor = false, secondScreenKeyColor = false) => {
        this.setState({
            displayBackgroundColorPicker: backgroundColor,
            dirty: true
        })
    }

    handleBackgroundColorClick = () => {
        this.setColorPickerState(false, !this.state.displayBackgroundColorPicker)
    }

    publish = async () => {
        const account = this.props.role.account_id
        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/image_display_style`)
        this.setState({ publishing: true })
        await configRef.set(this.state.imageDisplayStyle.toJSON())
        this.setState({ showSuccess: true, publishing: false, dirty: false })
    }
}
