import * as _ from "lodash"
import * as React from "react"
import {
    Button,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Grid,
    Row
} from "react-bootstrap"
import { ref } from "../../../config/constants"
import { ModuleProps } from "../Configuration/ModuleConfiguration"

interface NewsletterModuleState {
    api_key?: string
    list_id?: string
}

export class NewsletterModule extends React.Component<ModuleProps, NewsletterModuleState> {

    constructor(props: ModuleProps) {
        super(props)
        this.state = {
            api_key: props.moduleData.api_key,
            list_id: props.moduleData.list_id
        }
    }

    newsletterConfigured(): boolean {
        const config = this.props.moduleData
        if (!config) {
            return false
        }
        if (config.api_key && config.list_id) {
            return true
        }
        return false
    }

    async configureNewsletterIntegration() {
        const listId = this.state.list_id
        const apiKey = this.state.api_key
        if (!listId) { return }
        if (!apiKey) { return }

        // You can read about the 'data center' component of the API url here:
        // https://developer.mailchimp.com/documentation/mailchimp/guides/get-started-with-mailchimp-api-3/#resources
        const components = apiKey.split("-")
        if (components.length !== 2) {
            return
        }
        const dataCenter = components[1]

        // Create integration
        const integrationRef = ref().child(`v1/accounts/${this.props.role.account_id}/configuration/export_integrations/newsletter/mailchimp`)
        await integrationRef.set({
            name: "MailChimp",
            delivery: {
                type: "web_hook",
                parameters: {
                    headers: {
                        Authorization: `apikey ${apiKey}`,
                        "Content-Type": "application/json"
                    },
                    method: "POST",
                    url: `https://${dataCenter}.api.mailchimp.com/3.0/lists/${listId}/members`
                }
            },
            transformation: {
                parameters: {
                    email_address: "{email}",
                    status: "pending"
                },
                type: "simplejson"
            },
            trigger: {
                type: "realtime"
            }
        })

        this.props.updateConfiguration(config => {
            config.api_key = apiKey
            config.list_id = listId
        })
    }

    isEnabled(): boolean {
        return this.props.moduleData["enabled"] ?? false
    }

    newsletterHelpText = (
        <div key="a">
            <br />
            It is easy to configure the a Newsletter Signup integration to MailChimp. Other Newsletter services will follow:
            <br />
            <ol>
                <li>
                    <label>IMPORTANT</label><br />
                    Since Ka-ching only collects the email address from the customer, the MailChimp list used here mu be able to receive signups where the only piece of information is the email address.
                </li>
                <li>
                    Create an API key in MailChimp under 'Account / Extras / API keys' (<a href="https://us16.admin.mailchimp.com/account/api/" target="_blank" rel="noopener noreferrer">https://us16.admin.mailchimp.com/account/api/</a>)
                </li>
                <li>
                    Copy and paste the newly created API key below.
                </li>
                <li>
                    Find the 'Audience ID' for the Audience that you wish the customers to be added to.<br />
                    This can be found under 'Audience / Manage Audience / Settings / Audience name and defaults' <br />
                    The audience id will be listed in red letters.
                </li>
                <li>
                    Copy and paste the Audience ID into the field below
                </li>
                <li>
                    Tap the 'Configure MailChimp Integration' button below. That's it! :-)
                </li>
            </ol>
        </div>
    )

    async removeConfiguration() {
        if (!window.confirm("Are you sure you wish to delete the configuration and start over?")) { return }
        const integrationRef = ref().child(`v1/accounts/${this.props.role.account_id}/configuration/export_integrations/newsletter/mailchimp`)
        await integrationRef.remove()
        this.setState({ api_key: undefined, list_id: undefined })
        this.props.updateConfiguration(config => {
            delete config["api_key"]
            delete config["list_id"]
            config.enabled = true
        })
    }

    handleInputChange = (event: any) => {
        const target = event.target
        const value = target.value
        const name = target.name

        if (name === "api_key") {
            this.setState({ api_key: value })
        } else if (name === "list_id") {
            this.setState({ list_id: value })
        }
    }

    configureButtonEnabled() {
        if (_.isNil(this.state.api_key)) { return false }
        if (_.isNil(this.state.list_id)) { return false }
        const components = this.state.api_key.split("-")
        if (components.length !== 2) { return false }
        return true
    }

    render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12} sm={10} md={8}>
                        {
                            this.isEnabled() ? (
                                <div>
                                    {this.newsletterHelpText}
                                    <br /><br />
                                    {this.newsletterConfigured() ? (
                                        <div>
                                            <Button bsStyle="danger" onClick={async () => { await this.removeConfiguration() }}>Remove configuration and start over</Button>
                                        </div>
                                    ) : (
                                            <Form horizontal={true}>
                                                <FormGroup>
                                                    <Col componentClass={ControlLabel} sm={2}>Account key</Col>
                                                    <Col sm={10}>
                                                        <FormControl
                                                            type="text"
                                                            name="api_key"
                                                            value={this.state.api_key || ""}
                                                            placeholder="Please enter your MailChimp 'account key'"
                                                            onChange={this.handleInputChange}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Col componentClass={ControlLabel} sm={2}>Audience ID</Col>
                                                    <Col sm={10}>
                                                        <FormControl
                                                            type="text"
                                                            name="list_id"
                                                            value={this.state.list_id || ""}
                                                            placeholder="Please enter the 'Audience ID' of the MailChimp audience"
                                                            onChange={this.handleInputChange}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <Button disabled={!this.configureButtonEnabled()} onClick={async () => { await this.configureNewsletterIntegration() }}>Configure MailChimp Integration</Button>
                                            </Form>
                                        )}
                                </div>
                            ) : null
                        }
                    </Col>
                </Row>
            </Grid >
        )
    }
}
