export class StockCountListItem {
    closedAt?: number
    closedBy?: string
    id: string
    name: string
    openedAt: number
    openendBy: string
    cancelled?: boolean

    constructor(closedAt: number | undefined, closedBy: string | undefined, id: string, name: string, openedAt: number, openedBy: string, cancelled: boolean | undefined) {
        this.closedAt = closedAt
        this.closedBy = closedBy
        this.id = id
        this.name = name
        this.openedAt = openedAt
        this.openendBy = openedBy
        this.cancelled = cancelled
    }
}