import * as _ from "lodash"
import * as React from "react"
import {
    Alert,
    Button,
    Checkbox,
    Col,
    ControlLabel,
    DropdownButton,
    Form,
    FormControl,
    FormGroup,
    MenuItem,
    Panel,
    Row
} from "react-bootstrap"
import {
    Attribute,
    AttributeOption,
    AttributeType,
    AttributeTypeKey
} from "../../models/Product"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import firebase from "firebase/compat"
import { L10nFormControl } from "../L10nFormControl"
import {
    L10nString,
    LanguageCode
} from "../../helpers/L10n"
import { LanguagePicker } from "../LanguagePicker"
import { PageState } from "../PageState"
import { publish } from "../../helpers/ModelPublisher"
import { ref } from "../../config/constants"
import { Role } from "../../config/role"
import {
    RouteComponentProps,
    withRouter
} from "react-router"
import { StripedTable } from "../StripedTable"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { AttributeMode } from "./AttributeList"

const typeToNameMap: _.Dictionary<string> = {
    number: "Number",
    options: "Options",
    text: "Text"
}

interface AttributeEditProps extends RouteComponentProps<any> {
    currentLanguage?: LanguageCode
    role: Role
    mode: AttributeMode
}

interface AttributeEditState {
    attribute: Attribute
    identifier: string
    loaded: boolean
    dirty: boolean
    publishing: boolean
    currentLanguage: LanguageCode | null
    error: string | null
    editingOption?: boolean
    editingOptionId?: string
    editingOptionName?: L10nString
    attributeTypeEditingCache: _.Dictionary<AttributeType>
}

class AttributeEdit extends React.Component<AttributeEditProps, AttributeEditState> {
    constructor(props: AttributeEditProps) {
        super(props)
        const optionsType = new AttributeType({ options: {} })
        const numberType = new AttributeType({ number: { suffix: "", scale: 0 } })
        const textType = new AttributeType({ text: { text: "" } })
        const attributeTypeEditingCache = {
            options: optionsType,
            number: numberType,
            text: textType
        }
        this.state = {
            attribute: new Attribute({
                id: "",
                name: "",
                type: optionsType
            }),
            identifier: "",
            currentLanguage: props.currentLanguage || null,
            loaded: false,
            dirty: false,
            publishing: false,
            error: null,
            attributeTypeEditingCache: attributeTypeEditingCache
        }
    }

    pop() {
        const path = this.props.mode === "product" ? `/attributes` : `/customer_attributes`
        this.props.history.push(path)
    }

    attributeKey() {
        return this.props.match.params.attributeKey
    }

    isNewAttribute() {
        return this.attributeKey() === "new"
    }

    isPublishEnabled() {
        if (!this.state.dirty) {
            return false
        }
        if (this.state.attribute.name.hasEmptyLocalizations()) {
            return false
        }

        let result = true
        switch (this.state.attribute.typeKey()) {
            case AttributeTypeKey.NUMBER:
                break

            case AttributeTypeKey.OPTIONS:
                const options = this.state.attribute.type.options
                // We need at least one option
                if (!options) {
                    result = false
                } else if (Object.keys(options).length === 0) {
                    result = false
                } else {
                    for (const optionKey of Object.keys(options)) {
                        const option = options[optionKey]
                        if (option.name.hasEmptyLocalizations()) {
                            result = false
                        }
                    }
                }
                break

            case AttributeTypeKey.TEXT:
                break
        }

        return result
    }

    attributesRef(): firebase.database.Reference {
        if (this.props.mode === "product") {
            return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/attributes")
        } else {
            return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/customer_attributes")
        }
    }

    async publish() {
        const json = this.state.attribute.json()
        this.setState({ publishing: true })

        try {
            await publish(json, "id", this.state.identifier, this.isNewAttribute(), this.attributesRef())
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.pop()
    }

    async componentDidMount() {
        this.setState({ loaded: false })

        if (!this.isNewAttribute()) {
            const snapshot = await this.attributesRef()
                .child(this.attributeKey())
                .once("value")
            const attribute = new Attribute(snapshot.val())
            const attributeTypeEditingCache = _.cloneDeep(this.state.attributeTypeEditingCache)
            attributeTypeEditingCache[attribute.typeKey()] = _.cloneDeep(attribute.type)
            this.setState({ attribute: attribute, identifier: attribute.id, attributeTypeEditingCache: attributeTypeEditingCache, loaded: true })
        } else {
            this.setState({ loaded: true })
        }
    }

    resolveLanguages = (): LanguageCode[] => {
        return this.state.attribute.name.localizations()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })
    }

    removeLanguage = (language: LanguageCode) => {
        const attribute = _.cloneDeep(this.state.attribute)
        attribute.name.removeLocalization(language)
        this.setState({ attribute: attribute, dirty: true, currentLanguage: null })
    }

    handleInputChange = (l10n: L10nString | null) => {
        const attribute = _.cloneDeep(this.state.attribute)
        attribute.name = l10n || new L10nString("")
        this.setState({ attribute: attribute, dirty: true })
    }

    handleIdChange(identifier: string) {
        const attribute = _.cloneDeep(this.state.attribute)
        attribute.id = identifier
        this.setState({ dirty: true, error: null, identifier: identifier, attribute: attribute })
    }

    handlePreserveOnLineItemChanged(e: any) {
        const attribute = _.cloneDeep(this.state.attribute)
        attribute.preserveOnLineItem = e.target.checked
        this.setState({ dirty: true, error: null, attribute: attribute })
    }

    handleTypeChange(type: string) {
        if (type === this.state.attribute.typeKey()) {
            return
        }

        const cachedAttributeType = this.state.attributeTypeEditingCache[type]
        if (!cachedAttributeType) {
            return
        }

        const attributeTypeEditingCache = _.cloneDeep(this.state.attributeTypeEditingCache)
        attributeTypeEditingCache[this.state.attribute.typeKey()] = _.cloneDeep(this.state.attribute.type)
        const newAttribute = _.cloneDeep(this.state.attribute)
        newAttribute.type = cachedAttributeType

        this.setState({ attributeTypeEditingCache: attributeTypeEditingCache, attribute: newAttribute, dirty: true })
    }

    editOption(key: string) {
        if (this.state.editingOption) {
            // Already editing
            return
        }
        // Remove the option so that a duplicate is not created when editing the id
        this.removeOption(key)
        const option = (this.state.attribute.type.options || {})[key]
        this.setState({ editingOption: true, editingOptionId: key, editingOptionName: option.name })
    }

    removeOption(key: string) {
        const attribute = _.cloneDeep(this.state.attribute)
        delete attribute.type.options![key]
        this.setState({ attribute: attribute, dirty: true })
    }

    descriptionForAttributeTypeKey(key: AttributeTypeKey): JSX.Element {
        let result = <br />
        switch (key) {
            case AttributeTypeKey.NUMBER:
                result = (
                    <p>
                        With this type you can define number as the value for the attribute on the product.
                        <br />
                        You can specify a localized suffix here if needed. Fx. meters if the attribute is a length attribute as well as the number of decimals used.
                    </p>
                )
                break

            case AttributeTypeKey.OPTIONS:
                result = (
                    <p>
                        With this type you can define a set of options that the define all possible values for an attribute.
                        <br />
                        For instance you might define 'Pinot Noir' and 'Cabernet Sauvignon' for a 'Grape' attribute.
                    </p>
                )
                break

            case AttributeTypeKey.TEXT:
                result = (
                    <p>
                        With this type you can specify some text for the attribute on the product.
                    </p>
                )
                break
        }
        return result
    }

    renderType(): JSX.Element {
        switch (this.state.attribute.typeKey()) {
            case AttributeTypeKey.NUMBER:
                return this.renderNumber()

            case AttributeTypeKey.OPTIONS:
                return this.renderOptions()

            case AttributeTypeKey.TEXT:
                return this.renderText()
        }
    }

    renderText(): JSX.Element {
        return <br />
    }

    renderNumber(): JSX.Element {
        const number = this.state.attribute.type.number!
        return (
            <div>
                <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>Suffix</Col>
                    <Col sm={10}>
                        <L10nFormControl
                            l10n={number.suffix}
                            placeholder="Enter localized suffix"
                            language={this.state.currentLanguage}
                            onLocalizationChanged={l10n => {
                                const newAttribute = _.cloneDeep(this.state.attribute)
                                newAttribute.type.number!.suffix = l10n || new L10nString("")
                                this.setState({ attribute: newAttribute, dirty: true })
                            }}
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>Number of decimals</Col>
                    <Col sm={10}>
                        <DropdownButton
                            title={`${number.scale}`}
                            key="attribute_type"
                            id={`dropdown-basic-options`}
                        >
                            {
                                Object.values(["0", "1", "2", "3"]).map((key: string) => {
                                    return (
                                        <MenuItem
                                            key={key}
                                            onClick={(event) => {
                                                const newValue = Number(key)
                                                const newAttribute = _.cloneDeep(this.state.attribute)
                                                newAttribute.type.number!.scale = newValue
                                                this.setState({ attribute: newAttribute, dirty: true })

                                                event.stopPropagation()
                                            }}
                                        >
                                            {key}
                                        </MenuItem>
                                    )
                                })
                            }
                        </DropdownButton>
                    </Col>
                </FormGroup>
            </div>
        )
    }

    renderOptions(): JSX.Element {
        const options = this.state.attribute.type.options!
        return (
            <div>
                <Row>
                    <Col componentClass={ControlLabel} sm={2}>Options</Col>
                    <Col sm={10}>
                        <StripedTable>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(options).map(optionKey => {
                                        const option = options[optionKey]
                                        return (
                                            <tr key={optionKey} onClick={() => { this.editOption(optionKey) }}>
                                                <td>{option.id}</td>
                                                <td>{option.name.localized(this.state.currentLanguage) || option.name.value}</td>
                                                <td className="narrow">
                                                    <ConfirmDeleteButton
                                                        message={`Really delete ${option.name.localized(this.state.currentLanguage)} option?`}
                                                        onDelete={() => {
                                                            this.removeOption(optionKey)
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </StripedTable>
                    </Col>
                </Row>

                {this.state.editingOption ? (
                    <div>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>Option identifier</Col>
                            <Col sm={10}>
                                <FormControl
                                    type="text"
                                    name="optionid"
                                    value={this.state.editingOptionId || ""}
                                    placeholder="Enter option identifier"
                                    onChange={(event: any) => {
                                        this.setState({ editingOptionId: event.target.value })
                                    }}
                                    autoComplete="off"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>Option name</Col>
                            <Col sm={10}>
                                <L10nFormControl
                                    l10n={this.state.editingOptionName || null}
                                    placeholder="Enter localized name"
                                    language={this.state.currentLanguage}
                                    onLocalizationChanged={l10n => { this.setState({ editingOptionName: l10n || undefined }) }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>&nbsp;</Col>
                            <Col sm={10}>
                                <Button
                                    onClick={() => {
                                        const name = this.state.editingOptionName
                                        let id = this.state.editingOptionId
                                        if (name === undefined || id === undefined || id.length === 0) {
                                            return
                                        }
                                        const attribute: Attribute = _.cloneDeep(this.state.attribute)
                                        const attrOptions = attribute.type.options || {}
                                        id = _.trim(id, ":")
                                        const option = new AttributeOption({ name: {} })
                                        option.id = id
                                        option.name = name
                                        attrOptions[id] = option
                                        attribute.type.options = attrOptions
                                        this.setState({
                                            editingOption: undefined,
                                            editingOptionId: undefined,
                                            editingOptionName: undefined,
                                            attribute: attribute,
                                            dirty: true
                                        })
                                    }}
                                >
                                    Done
                                </Button>
                            </Col>
                        </FormGroup>
                    </div>
                ) : (
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2}>&nbsp;</Col>
                        <Col sm={10}>
                            <Button onClick={() => { this.setState({ editingOption: true }) }}>Add option</Button>
                        </Col>
                    </FormGroup>
                )
                }
            </div>
        )
    }

    newTypeName() {
        if (this.props.mode === "product") {
            return "Create product attribute"
        } else {
            return "Create customer attribute"
        }
    }

    editTypeName() {
        const name = this.state.attribute.name.localized(this.state.currentLanguage)
        if (this.props.mode === "product") {
            return `Edit product attribute: ${name}`
        } else {
            return `Edit customer attribute: ${name}`
        }
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="attribute"
            >
                <Form horizontal={true}>

                    <FormGroup>
                        <Col smOffset={8} sm={4} className="text-right">
                            <LanguagePicker
                                typeName="attribute"
                                initialLanguage={this.state.currentLanguage}
                                resolveLanguages={this.resolveLanguages}
                                onChange={this.setLanguage}
                                onRemove={this.removeLanguage}
                            />
                        </Col>
                    </FormGroup>

                    <Panel>
                        <Panel.Heading>{this.isNewAttribute() ? this.newTypeName() : this.editTypeName() }</Panel.Heading>
                        <Panel.Body>
                            <span key="a">
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>Name</Col>
                                    <Col sm={10}>
                                        <L10nFormControl
                                            l10n={this.state.attribute.name}
                                            placeholder="Enter localized name"
                                            language={this.state.currentLanguage}
                                            onLocalizationChanged={l10n => { this.handleInputChange(l10n) }}
                                        />
                                    </Col>
                                </FormGroup>
                                {
                                    !this.isNewAttribute()
                                        ? (
                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2}>Identifier</Col>
                                                <Col sm={10}>
                                                    <FormControl.Static>{this.state.attribute.id}</FormControl.Static>
                                                </Col>
                                            </FormGroup>
                                        ) : (
                                            <ValidatingIdEntryControl
                                                collectionRef={this.attributesRef()}
                                                isNew={this.isNewAttribute()}
                                                typeName="attribute"
                                                identifierSource={this.state.attribute.name.localized(this.state.currentLanguage)}
                                                existingIdentifier={this.state.identifier}
                                                handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                                            />
                                        )
                                }
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={2}>Type</Col>
                                    <Col sm={10}>
                                        <DropdownButton
                                            title={typeToNameMap[this.state.attribute.typeKey()]}
                                            key="attribute_type"
                                            id={`dropdown-basic-options`}
                                        >
                                            {
                                                Object.keys(typeToNameMap).map((key: string) => {
                                                    return (
                                                        <MenuItem
                                                            key={key}
                                                            onClick={(event) => { this.handleTypeChange(key); event.stopPropagation() }}
                                                        >
                                                            {typeToNameMap[key]}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </DropdownButton>
                                        <div>
                                            <br />
                                            {this.descriptionForAttributeTypeKey(this.state.attribute.typeKey())}
                                        </div>

                                    </Col>
                                </FormGroup>

                                {this.renderType()}

                                {this.props.mode === "product" &&
                                    <FormGroup>
                                        <Col componentClass={ControlLabel} sm={2}>Preserve on line items</Col>
                                        <Col sm={10}>
                                            <Checkbox
                                                checked={this.state.attribute.preserveOnLineItem}
                                                onChange={e => this.handlePreserveOnLineItemChanged(e)}
                                            >
                                                Carry attribute information to line items
                                            </Checkbox>
                                        </Col>
                                    </FormGroup>
                                }
                            </span>
                        </Panel.Body>
                        <Panel.Footer><Button onClick={() => this.publish()} disabled={!this.isPublishEnabled()}>Publish</Button></Panel.Footer>
                    </Panel>
                </Form>
                {
                    this.state.error ? (
                        <Alert bsStyle="danger">
                            <strong>Error publishing attribute:</strong>
                            {this.state.error}
                        </Alert>
                    ) : []
                }
            </PageState>
        )
    }
}

export default withRouter(AttributeEdit)
