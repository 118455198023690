import * as _ from "lodash"
import * as React from "react"
import {
    DropdownButton,
    MenuItem
} from "react-bootstrap"
import { ModuleProps } from "../Configuration/ModuleConfiguration"

interface FiscalRulesModuleState {
    countries: _.Dictionary<string>
}

export class FiscalRulesModule extends React.Component<ModuleProps, FiscalRulesModuleState> {

    constructor(props: ModuleProps) {
        super(props)
        this.state = {
            countries: {
                none: "None",
                se: "Sweden"
            }
        }
    }

    // Helpers

    countrySelected(country: string) {
        const config: any = { enabled: this.props.moduleData.enabled }
        if (country !== "none") {
            config.country = country
        }
        this.props.updateConfiguration(existing => {
            return config
        })
    }

    isEnabled(): boolean {
        return this.props.moduleData["enabled"] ?? false
    }

    renderSweden(): JSX.Element | null {
        // Change along the way if we find out that we need some config associated with it
        return null
    }

    renderCountrySpecificSettings(countryCode: string): JSX.Element | null {
        switch (countryCode) {
            case "se":
                return this.renderSweden()
        }
        return null
    }

    // Component

    render() {
        const countryKey = this.props.moduleData.country
        return (
            <div>
                {
                    this.isEnabled()
                        ?
                        (
                            <div>
                                <DropdownButton
                                    bsStyle="default"
                                    title={_.isNil(countryKey) ? "Choose country" : this.state.countries[countryKey]}
                                    id="dropdown-country"
                                    onSelect={(selectedCountry: any) => {
                                        this.countrySelected(selectedCountry)
                                    }}
                                >
                                    {
                                        Object.keys(this.state.countries).sort().map((countryCode) => {
                                            const countryName = this.state.countries[countryCode]
                                            return <MenuItem key={countryCode} eventKey={countryCode}>{countryName}</MenuItem>
                                        })
                                    }
                                </DropdownButton>
                                {this.renderCountrySpecificSettings(this.props.moduleData.country)}
                            </div>
                        )
                        :
                        null
                }
            </div>
        )
    }
}
