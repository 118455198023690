import * as React from "react"
import {
    Attribute,
    AttributeOption,
    AttributeTypeKey,
    AttributeValue
} from "../../models/Product"
import {
    clamp,
    cloneDeep,
    Dictionary,
    isNil,
} from "lodash"
import {
    Col,
    ControlLabel,
    DropdownButton,
    FormControl,
    FormGroup,
    MenuItem,
    InputGroup
} from "react-bootstrap"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { L10nFormControl } from "../L10nFormControl"
import {
    L10nString,
    LanguageCode
} from "../../helpers/L10n"
import { ref } from "../../config/constants"
import { StripedTable } from "../StripedTable"

interface AttributeSelectionProps {
    account: string
    selectedAttributes: Dictionary<AttributeValue>
    currentLanguage: LanguageCode | null
    onChange: (attributes: Dictionary<AttributeValue>) => void
}

interface AttributeSelectionState {
    attributes: Dictionary<Attribute>
    selectedAttributes: Dictionary<AttributeValue>
}

export class AttributeSelection extends React.Component<AttributeSelectionProps, AttributeSelectionState> {
    constructor(props: AttributeSelectionProps) {
        super(props)

        this.state = {
            selectedAttributes: {},
            attributes: {}
        }
    }

    onChange(data: any) {
        const tags = {}
        for (const key in data) {
            tags[data[key]] = true
        }

        this.props.onChange(tags)
    }

    componentWillReceiveProps(nextProps: AttributeSelectionProps) {
        this.setState({ selectedAttributes: nextProps.selectedAttributes || {} })
    }

    async componentDidMount() {
        this.setState({ selectedAttributes: this.props.selectedAttributes || {} })

        const account = this.props.account
        const attributesRef = ref().child(`v1/accounts/${account}/inventory/attributes`)
        const snapshot = await attributesRef.once("value")
        if (!snapshot.exists()) {
            this.setState({ attributes: {} })
            return
        }

        const rawAttributes = snapshot.val()
        const attributes: Dictionary<Attribute> = {}
        for (const key in rawAttributes) {
            if (rawAttributes.hasOwnProperty(key)) {
                attributes[key] = new Attribute(rawAttributes[key])
            }
        }

        this.setState({ attributes: attributes })
    }

    componentWillUnmount() {
        const account = this.props.account
        const tagRef = ref().child(`v1/accounts/${account}/inventory/attributes`)
        tagRef.off()
    }

    removeAttribute(attributeKey: string) {
        const selected = cloneDeep(this.state.selectedAttributes)
        delete selected[attributeKey]
        this.props.onChange(selected)
    }

    setAttribute(attributeKey: string, value: AttributeValue) {
        const selected = cloneDeep(this.state.selectedAttributes)
        selected[attributeKey] = value
        this.props.onChange(selected)
    }

    addAttribute(attributeKey: string, defaultValue: AttributeValue) {
        const selected = cloneDeep(this.state.selectedAttributes)
        selected[attributeKey] = defaultValue
        this.props.onChange(selected)
    }

    unusedAttributes() {
        const attributeKeys = Object.keys(this.state.attributes)
        const selected = Object.keys(this.state.selectedAttributes)
        const diff = attributeKeys.filter(x => !selected.includes(x))
        return diff
    }

    renderNumberValue(value: AttributeValue, selectedAttribute: string, attribute: Attribute): JSX.Element {
        const scale = clamp(attribute.type.number!.scale, 0, 3)
        const step = 1 / Math.pow(10, scale)

        let numberValue: number | undefined = isNil(value.number) ? undefined : value.numberValue()
        if (numberValue === undefined || isNaN(numberValue)) {
            numberValue = undefined
        }
        return (
            <td>
                <InputGroup>
                    <FormControl
                        type="number"
                        name="value"
                        step={step}
                        value={numberValue ?? ""}
                        placeholder="Enter value"
                        onChange={(e: any) => {
                            const newValue = Number(e.target.value)
                            const newAttr = new AttributeValue(undefined)
                            if (!isNil(newValue) && e.target.value !== "") {
                                newAttr.number = newValue
                            }
                            this.setAttribute(selectedAttribute, newAttr)
                        }}
                        onBlur={(e: any) => {
                            const fixedStringValue = value.numberValue().toFixed(scale)
                            const newAttr = new AttributeValue(undefined)
                            newAttr.number = Number(fixedStringValue)
                            this.setAttribute(selectedAttribute, newAttr)
                        }}
                        autoComplete="off"
                    />
                    <InputGroup.Addon>{attribute.type.number!.suffix.localized(this.props.currentLanguage)}</InputGroup.Addon>
                </InputGroup>
            </td>
        )
    }

    renderOptionsValue(options: Dictionary<AttributeOption>, value: AttributeValue, selectedAttribute: string): JSX.Element {
        const stringValue: string = value.stringValue()
        const selectedOption = options[stringValue]
        let displayValue = stringValue
        if (!isNil(selectedOption)) {
            displayValue = selectedOption.name.localized(this.props.currentLanguage || null)
        }
        return (
            <td>
                <DropdownButton
                    bsStyle="default"
                    title={displayValue}
                    id="dropdown-attribute-value"
                    onSelect={(selectedValue: any) => {
                        const newAttr = new AttributeValue(undefined)
                        newAttr.string = selectedValue
                        this.setAttribute(selectedAttribute, newAttr)
                    }}
                >
                    {Object.keys(options).map((optionKey) => {
                        const option = options[optionKey]
                        return <MenuItem key={optionKey} eventKey={optionKey}>{option.name.localized(this.props.currentLanguage || null)}</MenuItem>
                    })}
                </DropdownButton>
            </td>
        )
    }

    renderTextValue(value: AttributeValue, selectedAttribute: string): JSX.Element {
        const l10nString = value.localizedValue()
        return (
            <td>
                <L10nFormControl
                    l10n={l10nString}
                    type="textarea"
                    componentClass="textarea"
                    language={this.props.currentLanguage || null}
                    style={{ resize: "vertical" }}
                    onLocalizationChanged={l10n => {
                        if (isNil(l10n)) {
                            const newAttr = new AttributeValue(undefined)
                            newAttr.string = ""
                            this.setAttribute(selectedAttribute, newAttr)
                        } else {
                            const newAttr = new AttributeValue(undefined)
                            newAttr.localized = l10n
                            this.setAttribute(selectedAttribute, newAttr)
                        }
                    }}
                />
            </td>
        )
    }

    render() {
        return (
            <section>
                {
                    this.state.attributes
                        ? (
                            <FormGroup>

                                <Col componentClass={ControlLabel} sm={2}>Attributes</Col>
                                <Col sm={10}>
                                    {
                                        Object.keys(this.state.selectedAttributes).length > 0 ? (
                                            <StripedTable>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Value</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        Object.keys(this.state.selectedAttributes).map((selectedAttribute) => {
                                                            const value = this.state.selectedAttributes[selectedAttribute]
                                                            if (isNil(value)) {
                                                                return null
                                                            }

                                                            const attribute = this.state.attributes[selectedAttribute]
                                                            let attributeName = selectedAttribute
                                                            if (!isNil(attribute)) {
                                                                attributeName = attribute.name.localized(this.props.currentLanguage || null)
                                                            }
                                                            let type: AttributeTypeKey | undefined = undefined
                                                            if (!isNil(attribute)) {
                                                                type = attribute.typeKey()
                                                            }
                                                            if (!isNil(attribute) && !isNil(type)) {
                                                                let renderedValue: JSX.Element = <br />
                                                                switch (type) {
                                                                    case AttributeTypeKey.NUMBER:
                                                                        renderedValue = this.renderNumberValue(value, selectedAttribute, attribute)
                                                                        break

                                                                    case AttributeTypeKey.OPTIONS:
                                                                        renderedValue = this.renderOptionsValue(attribute.type.options!, value, selectedAttribute)
                                                                        break

                                                                    case AttributeTypeKey.TEXT:
                                                                        renderedValue = this.renderTextValue(value, selectedAttribute)
                                                                        break
                                                                }

                                                                return (
                                                                    <tr key={selectedAttribute}>
                                                                        <td>{attributeName}</td>
                                                                        {renderedValue}
                                                                        <td className="narrow">
                                                                            <ConfirmDeleteButton
                                                                                message={`Really delete ${attributeName} attribute?`}
                                                                                onDelete={() => {
                                                                                    this.removeAttribute(selectedAttribute)
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            } else {
                                                                return (
                                                                    <tr key={selectedAttribute}>
                                                                        <td>{attributeName} (undefined)</td>
                                                                        <td>{value.stringValue()}</td>
                                                                        <td className="narrow">
                                                                            <ConfirmDeleteButton
                                                                                message={`Really delete ${attributeName} attribute?`}
                                                                                onDelete={() => {
                                                                                    this.removeAttribute(selectedAttribute)
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            </StripedTable>
                                        ) : null
                                    }

                                    {
                                        this.unusedAttributes().length > 0 ? (
                                            <DropdownButton
                                                bsStyle="default"
                                                title="Add attribute"
                                                id="dropdown-add-attribute"
                                                onSelect={(selectedAttribute: any) => {
                                                    const attribute = this.state.attributes[selectedAttribute]
                                                    const defaultValue = new AttributeValue(undefined)
                                                    switch (attribute.typeKey()) {
                                                        case AttributeTypeKey.NUMBER:
                                                            defaultValue.number = 0
                                                            break

                                                        case AttributeTypeKey.TEXT:
                                                            defaultValue.localized = new L10nString("")
                                                            break

                                                        case AttributeTypeKey.OPTIONS:
                                                            defaultValue.string = ""
                                                            break

                                                        default:
                                                            break
                                                    }
                                                    this.addAttribute(selectedAttribute, defaultValue)
                                                }}
                                            >
                                                {

                                                    this.unusedAttributes().map((attributeKey) => {
                                                        const attribute = this.state.attributes[attributeKey]
                                                        return <MenuItem key={attributeKey} eventKey={attributeKey}>{attribute.name.localized(this.props.currentLanguage || null)}</MenuItem>
                                                    })}
                                            </DropdownButton>

                                        ) : null
                                    }

                                </Col>

                            </FormGroup>
                        ) : <div>Loading..</div>
                }
            </section>
        )
    }
}
