import * as _ from "lodash"
import { countedProductName } from "../helpers/productName"
import { LanguageCode } from "../helpers/L10n"
import { StockCountLineItem, StockCountLine, StockCountLineState } from "../models/StockCountModels"

export function buildStockCountLines(lineItems: StockCountLineItem[], pageLimit: number): StockCountLine[] {
    const nestedLines: _.Dictionary<StockCountLine[]> = {}
    _.take(lineItems, pageLimit).forEach((lineItem) => {
        const disconnect = lineItem.disconnect ?? false
        const name = countedProductName(lineItem.product, disconnect, LanguageCode.da, true)
        const loaded = (lineItem.product.productName !== undefined)
        const deleted = (lineItem.product.deleted || false)
        const state: StockCountLineState = (disconnect ? StockCountLineState.disconnect : (deleted ? StockCountLineState.deleted : (loaded ? StockCountLineState.loaded : StockCountLineState.initializing)))
        const line = new StockCountLine(lineItem.product.barcode, lineItem.counted, lineItem.diff, lineItem.expected, state !== StockCountLineState.initializing, name, lineItem.product.productId, lineItem.product.variantId, state)
        let list = nestedLines[lineItem.product.productId]
        if (!list) {
            list = []
        }
        list.push(line)
        nestedLines[lineItem.product.productId] = list
    })

    return _.flatMap(nestedLines)
}
