import * as React from "react"
import { Globals } from "../helpers/globals"
import { Language, LanguageCode } from "../helpers/L10n"
import { Button, ButtonGroup, DropdownButton, MenuItem, Modal } from "react-bootstrap"
import { Dictionary, isNil } from "lodash"

export function localize(l10nString: any, language: any) {
    if (!l10nString) { return "" }
    if (typeof (l10nString) !== "object") {
        return l10nString
    }
    return l10nString[language] || l10nString["en"] || l10nString[Object.keys(l10nString)[0]]
}

interface LanguagePickerProps {
    resolveLanguages: () => LanguageCode[]
    initialLanguage: LanguageCode | null
    hideRemove?: any
    onChange?: (language: LanguageCode | null) => void
    onRemove?: (language: LanguageCode) => void
    typeName: string
}

interface LanguagePickerState {
    currentLanguage: LanguageCode | null
    languages: Dictionary<Language>
    localizedLanguages: LanguageCode[]
    showAlert: boolean
    hideRemove: boolean
}

export class LanguagePicker extends React.Component<LanguagePickerProps, LanguagePickerState> {
    _mounted: boolean = false
    constructor(props: LanguagePickerProps) {
        super(props)

        this.state = {
            currentLanguage: props.initialLanguage || null,
            languages: {},
            localizedLanguages: [],
            showAlert: false,
            hideRemove: props.hideRemove || false
        }
    }

    unlocalized = (): LanguageCode[] => {
        const unloc: LanguageCode[] = []
        for (const key in this.state.languages) {
            const languageCode: LanguageCode = LanguageCode[key]
            if (this.state.localizedLanguages.includes(languageCode)) { continue }
            unloc.push(languageCode)
        }
        return unloc.sort()
    }

    updateLanguage(props: LanguagePickerProps) {
        const localizedLanguages = props.resolveLanguages()
        const initialLanguage = props.initialLanguage
        const currentLanguage = this.state.currentLanguage
        if (!isNil(initialLanguage)) {
            this.selectLanguage(initialLanguage)
        } else if (localizedLanguages.length > 0 && (isNil(currentLanguage) || !localizedLanguages.includes(currentLanguage))) {
            this.selectLanguage(localizedLanguages[0])
        } else if (localizedLanguages.length === 0 && !isNil(currentLanguage)) {
            this.selectLanguage(null)
        }
        this.setState({ localizedLanguages: localizedLanguages })
    }

    componentWillReceiveProps(nextProps: LanguagePickerProps) {
        this.updateLanguage(nextProps)
    }

    async componentDidMount() {
        this._mounted = true
        const languages = await Globals.shared.getLanguages()
        if (!this._mounted) {
            return
        }
        this.updateLanguage(this.props)
        this.setState({ languages: languages })
    }
    componentWillUnmount() {
        this._mounted = false
    }

    selectLanguage = (language: LanguageCode | null) => {
        if (language === this.state.currentLanguage) { return }
        this.setState({ currentLanguage: language })
        if (this.props.onChange) {
            this.props.onChange(language)
        }
    }

    removeLanguage = (language: LanguageCode | null) => {
        if (!language) { return }
        if (this.props.onRemove) {
            this.props.onRemove(language)
        }
    }

    title(): string {
        const defaultTitle = "Add Localizations"
        if (!this.state.languages) {
            return defaultTitle
        }
        if (this.state.currentLanguage === null) {
            return defaultTitle
        }
        const language = this.state.currentLanguage
        if (this.state.languages[language]) {
            return "Language: " + this.state.languages[language].name.localized(LanguageCode.en)
        }
        return defaultTitle
    }

    render() {
        return (
            [
                (
                    <ButtonGroup key="a">
                        <DropdownButton
                            bsStyle="primary"
                            title={this.title()}
                            id="localize"
                            onSelect={(event: any) => { this.selectLanguage(LanguageCode[event as string]) }}
                        >
                            <MenuItem header={true}>Localized</MenuItem>
                            {
                                this.state.localizedLanguages.map(language => {
                                    if (this.state.languages && this.state.languages[language]) {
                                        return <MenuItem key={language} eventKey={language}>{this.state.languages[language].name.localized(LanguageCode.en)}</MenuItem>
                                    }
                                    return null
                                })
                            }
                            <MenuItem divider={true} />
                            <MenuItem header={true}>Not Localized</MenuItem>
                            {
                                this.unlocalized().map(language => {
                                    if (this.state.languages && this.state.languages[language]) {
                                        return <MenuItem key={language} eventKey={language}>{this.state.languages[language].name.localized(LanguageCode.en)}</MenuItem>
                                    }
                                    return null
                                })
                            }
                        </DropdownButton>
                        {
                            (this.state.currentLanguage && !this.state.hideRemove)
                                ?
                                <Button bsStyle="primary" onClick={() => { this.setState({ showAlert: true }) }}>X</Button>
                                :
                                null
                        }
                    </ButtonGroup>
                ),
                (
                    this.state.showAlert ? (
                        <Modal.Dialog key="b">
                            <Modal.Header>
                                <Modal.Title>Remove Localization</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>The localized information will be deleted for this {this.props.typeName} only.</Modal.Body>

                            <Modal.Footer>
                                <Button onClick={() => { this.setState({ showAlert: false }) }}>Cancel</Button>
                                <Button bsStyle="danger" onClick={() => { this.removeLanguage(this.state.currentLanguage); this.setState({ showAlert: false }) }}>Remove Localization</Button>
                            </Modal.Footer>
                        </Modal.Dialog>
                    ) : null
                )
            ]
        )
    }
}
